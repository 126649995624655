import Vue from 'vue';
import Vuex from 'vuex';
import {
	consultMemoList,
	consultHistoryList,
	insertConsultMemo,
	insertCustomer,
	updateCustomer,
	consultCreditDetail,
} from '@/api/sales-mgmt/SalesMgmtApi';
import { getConsultNoticeTopList } from '@/api/sales-mgmt/ConsultNoticeApi';

Vue.use(Vuex);

const SalesMgmtModule = {
	namespaced: true,
	state: {
		totalCnt: 0,
		noticeListDialog: false,
		settingDialog: false,
		filterData: {
			perPageCnt: 20,
			pageNo: 1,
		},
		noticeList: [],
		topNotice: [],
		applConsultTodoDto: {
			toDoContents: null,
			reservationDateTime: null,
			notiYn: 'N',
			time: null,
		},
		applConsultMemoDto: {
			memoContents: null,
		},
		creditTodoDetail: null,
	},
	mutations: {
		initTopNoticeList(state) {
			state.topNotice = [];
		},
		setTopNoticeList(state, result) {
			if (result.data.data.consultNoticeImportantTopList.length > 0) {
				for (let i in result.data.data.consultNoticeImportantTopList) {
					state.topNotice.push(
						result.data.data.consultNoticeImportantTopList[i],
					);
				}
			}
			if (result.data.data.consultNoticeDailyTopList.length > 0) {
				for (let i in result.data.data.consultNoticeDailyTopList) {
					state.topNotice.push(result.data.data.consultNoticeDailyTopList[i]);
				}
			}
		},
	},
	actions: {
		async getConsultMemoList({ commit, dispatch }, data) {
			try {
				const result = await consultMemoList(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.data.data.length > 0) {
						result.data.data.map(obj => {
							if (obj.memoContents.length > 0) {
								obj.memoContents = obj.memoContents
									.replace(/&amp;/g, '&')
									.replace(/&lt;/g, '<')
									.replace(/&gt;/g, '>')
									.replace(/&quot;/g, '"');
							}
							return obj;
						});
					}
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultHistoryList({ commit }, data) {
			try {
				const result = await consultHistoryList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultNoticeTopList({ commit }, data) {
			try {
				commit('initTopNoticeList');
				const result = await getConsultNoticeTopList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setTopNoticeList', result);
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultMemo({ commit }, data) {
			try {
				const result = await insertConsultMemo(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateConsultMemo({ commit }, data) {
			try {
				const result = await insertConsultMemo(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 상담을 통한 신청서 등록
		async insertApplicationFromConsult({ commit }, data) {
			try {
				const result = await insertCustomer(data);
				if (result && '0000' === result.data.resultCode) {
					return result;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 신청서 수정후 상담DB 수정
		async updateApplicationFromConsult({ commit }, data) {
			try {
				const result = await updateCustomer(data);
				if (result && '0000' === result.data.resultCode) {
					return result;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 신용조회, TO-DO 상세 조회
		async consultCreditDetail({ commit }, data) {
			try {
				const result = await consultCreditDetail(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default SalesMgmtModule;
