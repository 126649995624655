<template>
	<div class="loginWrap">
		<!--		<div class="whiteBox"></div>-->
		<div style="z-index: 200; position: relative; top: 240px">
			<div class="login">
				<h2 class="LogoImg"></h2>
				<div class="loginBox">
					<form class="loginInputBox clearfix" @keyup.enter="submit">
						<div class="idBox borderRadi3Px h55 w350">
							<input
								class="subFontS padW20 outLineNone"
								type="text"
								v-model.trim="username"
								placeholder="아이디"
							/>
						</div>
						<div class="pwBox mt10 borderRadi3Px h55 w350">
							<input
								class="subFontS padW20 outLineNone"
								type="password"
								v-model.trim="password"
								placeholder="비밀번호"
								@input="resetErrorMsg"
								@keypress="capslockFnc"
								autocomplete="off"
							/>
						</div>
					</form>
					<div class="loginBtnBox">
						<button
							style="background: #000"
							class="loginBtn w350 h55 borderRadi3Px backColorBlue2 boldWt lh30"
							@click="submit"
						>
							로그인
						</button>
					</div>
					<div class="loginBoxBottom"></div>
					<div class="registerBox borderReset">
						<p class="font-red">{{ notiMsg }}</p>
						<p class="font-red">{{ errorMsg }}</p>
					</div>
					<div>
						<!--						<span class="fakeLine3">
							<router-link class="subFontS12 mainBlack mr30" to="/sign-up"
								>아이디 찾기</router-link
							>
						</span>
						<span class="fakeLine3">
							<router-link class="subFontS12 mainBlack mr30" to="/sign-up"
								>비밀번호 찾기</router-link
							>
						</span>-->
						<router-link class="subFontS12" to="/sign-up">지점가입</router-link>
					</div>
				</div>
			</div>
		</div>
		<div id="copyRight">
			<div class="copyRightCont disFx">
				<div class="copyLogoCont"></div>
				<div class="ml30">
					<!--					<div class="disFx footer-btn">-->
					<!--						<a @click="openDialog(1)">이용약관</a>-->
					<!--						<a @click="openDialog(2)">개인정보 처리방침</a>-->
					<!--						<a @click="openDialog(3)">전자금융거래 이용약관</a>-->
					<!--					</div>-->
					<div class="subFontS11 mainBlack3 mt15 lh20">
						<div>
							<span>주식회사 폰씨티 | 사업자등록번호 : 501-88-01494</span>
							<!--							<span>-->
							<!--								주식회사 대마 | 사업자등록번호 : 264-81-51441 | 입금계좌 :-->
							<!--								국민은행 285301-00-042046 주식회사 대마-->
							<!--							</span>-->
						</div>
						<!--						<div>-->
						<!--							<span>-->
						<!--								대표자 : 이은화 | 주소 : 경기도 시흥시 서울대학로 278번길 19-14-->
						<!--								배곧프라자 706호 | 평일 09시 ~ 18시-->
						<!--							</span>-->
						<!--						</div>-->
						<!--						<div class="boldWt">-->
						<!--							<span>-->
						<!--								연락처 : 1833-9413 | FAX: 070-8680-9413| E-mail:cs@daemacorp.co.kr-->
						<!--							</span>-->
						<!--						</div>-->
					</div>
					<div class="boldWt subFontS11 mt15">
						<!--						Copyright © Daemacorp. All rights reserved-->
						Copyright © 폰씨티. All rights reserved
					</div>
				</div>
			</div>
		</div>
		<TOSForExistingMemberPop
			v-if="TOSForExistingMemberDialog"
			:formData="memberData"
			:loginData="loginData"
			@clearMemberData="clearMemberData"
			@allTermsAgreed="allTermsAgreed"
		></TOSForExistingMemberPop>
	</div>
</template>

<script>
import { loginSubmit } from '../../api/member/login';
import { nullValidation } from '../../common/common';
import bus from '@/common/bus';
import TOSForExistingMemberPop from '@/components/popup/TOSForExistingMemberPop';

export default {
	components: { TOSForExistingMemberPop },
	data() {
		return {
			username: '',
			password: '',
			errorMsg: '',
			notiMsg: null,
			memberData: {},
			loginData: {},
		};
	},
	computed: {
		sct() {
			return this.$store.state.cookies.sct;
		},
		pu() {
			return this.$store.state.cookies.pu;
		},
		ss() {
			return this.$store.state.cookies.ss; // [KT-CB] 신조개통점 권한 예외처리
		},
		cr() {
			return this.$store.state.cookies.cr; // [KT-CB] 신조개통점 권한 예외처리
		},
		sg() {
			return this.$store.state.cookies.sg; // 영업관리 마이그레이션 이후 영업점 로그인시 영업관리 > 고객DB 렌딩
		},
		TOSForExistingMemberDialog: {
			get() {
				return this.$store.state.TOSForExistingMemberDialog;
			},
			set(newValue) {
				this.$store.state.TOSForExistingMemberDialog = newValue;
			},
		},
	},
	methods: {
		resetErrorMsg() {
			if (this.errorMsg) {
				this.errorMsg = '';
			}
		},
		async submit() {
			try {
				const data = {
					username: this.username,
					password: this.password,
				};
				if (!nullValidation(data)) {
					return;
				}
				const result = await loginSubmit(data);
				if (result.data.resultCode === '0000') {
					await this.$store.dispatch('LOGIN', result.data.data);
					// document.getElementById('app').style.display = 'none';
					if (this.pu === 'N') {
						await this.$router.push('/change-password');
						// document.getElementById('app').style.display = 'initial';
					} else {
						document.querySelector('.loginWrap').classList.add('successAni');
						await this.timeout(1100);
						if (this.sct === 'CWS' || this.sct === 'CS') {
							// [KT-CB] 신조개통점 권한 예외처리 START
							if (this.ss === '140' || this.ss === '141') {
								await this.$router.push('/credit-check'); // [KT-CB]
							} else {
								if (this.sg === 'StoreGrade_S') {
									await this.$router.push('/customer-mgmt/customer-db');
								} else {
									await this.$router.push('/sell-status'); // default
								}
								document.getElementById('app').style.display = 'initial';
							}
							// [KT-CB] 신조개통점 권한 예외처리 END
						} else if (this.sct === 'CW') {
							await this.$router.push('/device/in-stock-regi');
						} else {
							await this.$router.push('/dashboard');
						}
						// history.pushState(null, null, location.href);
						// window.onpopstate = function () {
						// 	history.go(1);
						// };
					}
				} else if (result.data.resultCode === '9999') {
					this.errorMsg = '아이디 또는 비밀번호가 일치하지 않습니다.';
				} else if (result.data.resultCode === '9003') {
					this.errorMsg = '관리점 담당자에게 승인되지 않은 사용자입니다.';
				} else if (result.data.resultCode === '9009') {
					this.loginData = data;
					this.memberData = result.data.data;
					this.$store.state.TOSForExistingMemberDialog = true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		clearMemberData() {
			this.memberData = {};
		},
		async allTermsAgreed() {
			await this.submit();
		},
		capslockFnc(e) {
			let keyCode = 0;
			let shiftKey = false;
			keyCode = e.keyCode;
			shiftKey = e.shiftKey;
			if (
				(keyCode >= 65 && keyCode <= 90 && !shiftKey) ||
				(keyCode >= 97 && keyCode <= 122 && shiftKey)
			) {
				this.notiMsg = 'CAPSLOCK 버튼이 눌러져 있습니다.';
			} else {
				this.notiMsg = null;
			}
		},
		timeout(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},
		openDialog(type) {
			bus.$emit('open:terms', type);
		},
	},
	created() {},
};
</script>

<style>
.LogoImg {
	margin: 0 auto;
	width: 300px;
	height: 50px;
	background-image: url('../../assets/img/SKLMNO.png');
	transition: transform 0.9s ease;
}

.login h2 {
	font-style: italic;
	font-size: 50px;
	color: #003a53;
	font-weight: bold;
}
.signUpCont a {
	color: #fff;
	padding: 5px 50px;
	background-color: #003a53;
}
#copyRight {
	position: fixed;
	background-color: #f6f6f6;
	height: 230px;
	top: 605px;
}
.copyRightCont {
	width: 660px;
	margin: 30px auto;
	height: 110px;
}
.copyLogoCont {
	width: 100px;
	height: 17px;
	margin: auto 0;
	background-image: url('../../assets/img/SKLMNO_gray1.png');
}
.successAni .loginBox,
.successAni #copyRight {
	opacity: 0;
}
.successAni .LogoImg {
	transform: translateY(150px);
}
.footer-btn a {
	color: #000 !important;
	font-size: 12px;
	background: #cbcbcb;
	padding: 0 12px;
	line-height: 24px;
	border-radius: 5px;
	margin-right: 8px;
}
.footer-btn a:last-of-type {
	margin-right: 0;
}
</style>
