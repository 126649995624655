import { instance, instanceAuth } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const insertData = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/insertApplicationRight`,
		data,
	);
	return response;
};

const upDateData = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationRight`,
		data,
	);
	return response;
};

const getApplicationRightDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getApplicationRightDetail${param}`,
	);
	return response;
};

const fileUpload = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationAttachMgmtAndMemo`,
		data,
	);
	return response;
};

// 파일 다운로드
const fileDownload = async data => {
	const response = await instanceAuth.get(
		`/download/file/${data.attachType}/${data.pId}/${data.attachId}`,
	);
	return response;
};

// 신청서 파일 삭제
const deleteApplicationAttachAndMemo = async data => {
	const response = await instance.post(
		`
		ApplicationManagement/ApplicationMgmt/deleteApplicationAttachMgmtAndMemo`,
		data,
	);
	return response;
};

// 파일 리스트 조회
const getApplicationAttachList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/ApplicationManagement/ApplicationMgmt/getApplicationAttachMgmtList${param}`,
	);
	return response;
};

const getList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getRightExecutePage${param}`,
	);
	return response;
};

export {
	getList,
	insertData,
	upDateData,
	fileUpload,
	getApplicationRightDetail,
	fileDownload,
	getApplicationAttachList,
	deleteApplicationAttachAndMemo,
};
