<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="1300" class="terms">
				<v-card class="borderRadiReset">
					<v-card-title
						style="position: sticky; top: 0; z-index: 1"
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">{{ termsTitle }}</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>
					<v-card-text class="pad30">
						<div
							@scroll="scrollBottomChk"
							class="disFx justify-center terms-editor-notice w100P narrowScroll1"
						>
							<div class="pad5 ql-container ql-snow w100P">
								<div class="ql-editor" v-html="termsContents"></div>
							</div>
						</div>
						<div class="text-center mt-6">
							<v-btn
								class="backColorBlue3 mainWhite ml10 btnShadow2 w120"
								@click="agreeTerms"
								:disabled="!scrolledToBottom"
								>동의</v-btn
							>
						</div>
						<vue-editor style="display: none"></vue-editor>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor-table';

export default {
	name: 'TermsCheckPop',
	components: {
		VueEditor,
	},
	props: {
		termsTitle: { default: null, type: String, required: true },
		termsType: { default: null, type: String, required: true },
		termsContents: { default: '', type: String, required: true },
		termsChecked: { default: false, type: Boolean, required: true },
		readMode: { default: false, type: Boolean },
	},
	data: () => ({
		formData: {},
		scrolledToBottom: false,
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.termsCheckDialog;
			},
			set(newValue) {
				this.$store.state.termsCheckDialog = newValue;
			},
		},
	},
	methods: {
		agreeTerms() {
			this.$emit('agreeTerms', this.termsType);
			this.dialog = false;
		},
		scrollBottomChk() {
			let scrollTop = document.querySelector('.terms-editor-notice').scrollTop;
			let clientHeight = document.querySelector(
				'.terms-editor-notice',
			).clientHeight;
			let scrollHeight = document.querySelector(
				'.terms-editor-notice',
			).scrollHeight;
			if (scrollTop + clientHeight + 10 >= scrollHeight) {
				this.scrolledToBottom = true;
			}
		},
		noScrollThenAbleBtn() {
			let clientHeight = document.querySelector(
				'.terms-editor-notice',
			).clientHeight;
			let scrollHeight = document.querySelector(
				'.terms-editor-notice',
			).scrollHeight;
			if (scrollHeight) {
				if (clientHeight === scrollHeight) {
					this.scrolledToBottom = true;
				} else {
					if (this.termsChecked === true) {
						this.scrolledToBottom = this.readMode;
					}
				}
			} else {
				this.scrolledToBottom = false;
			}
		},
	},
	mounted() {
		this.noScrollThenAbleBtn();
	},
	created() {
		this.scrolledToBottom = false;
	},
};
</script>

<style>
.terms-editor-notice {
	min-height: 300px;
	max-height: 580px;
	overflow-y: auto;
}
.ql-editor {
	font-size: 14px !important;
}
.ql-snow .ql-editor h4 {
	font-size: 0.8em !important;
	line-height: 1.7;
}
.ql-editor li {
	font-size: 0.9em !important;
	line-height: 1.7;
}
</style>
