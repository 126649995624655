import Vue from 'vue';
import Vuex from 'vuex';

import {
	insertMallMember,
	getMallMemberList,
	getMallMemberDetail,
	updateMallMember,
	deleteMallMember,
} from "../../../api/mall_mgmt/MallMemberMgmtApi";

Vue.use(Vuex);

const MallProductMgmtModule = {
	namespaced: true,
	state: {
		filterInitChk: false,
		filterData: {},
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		memberList: [],
	},
	mutations: {
		setTermsTypeList(state, result) {
			state.mallTermsTypeList = result.mallJoinTermsType || [];
			state.salesTermsTypeList = result.storesTermsType || [];
		},
		setMemberListInit(state) {
			state.memberList = [];
		},
		setMemberList(state, result) {
			state.memberList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
	},
	actions: {
		async insertMallMember({ commit }, data) {
			try {
				const result = await insertMallMember(data);
				if (result && result.data.resultCode === '0000') {
					alert('저장되었습니다.');
					return true;
				} else if (result && result.data.resultCode !== '0000') {
					alert(result.data.resultMsg);
					return false;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallMemberList({ commit }, data) {
			try {
				commit('setMemberListInit');
				const result = await getMallMemberList(data);
				if (result) {
					commit('setMemberList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallMemberDetail({ commit }, data) {
			try {
				const result = await getMallMemberDetail(data);
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallMember({ commit }, data) {
			try {
				const result = await updateMallMember(data);
				if (result && result.data.resultCode === '0000') {
					alert('수정되었습니다.');
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteMallMember({ commit }, data) {
			try {
				const result = await deleteMallMember(data);
				if (result && result.data.resultCode === '0000') {
					alert('삭제되었습니다.');
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default MallProductMgmtModule;
