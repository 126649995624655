<template>
	<div>
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="600">
				<v-card class="borderRadiReset infoPopup">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">템플릿 등록</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>

					<v-card-text class="pad30">
						<p class="mainRed mj0">* 필수입력입니다</p>

						<div class="new_popTable1">
							<table class="w100P">
								<tr>
									<td class="w120">
										템플릿 제목<span class="redfont">*</span>
									</td>
									<td>
										<input
											type="text"
											class="lh30 borderContColor3 borderRadi3Px w250 padW10"
											placeholder="템플릿 제목을 입력해 주세요."
											ref="templateTitle"
											v-model="templateTitle"
										/>
									</td>
								</tr>
							</table>

							<div class="disFx justify-end mt10">
								<v-btn
									class="backColorMint1 mainWhite borderReset w80"
									text
									outlined
									@click="templateSubmit"
								>
									등록
								</v-btn>
								<v-btn
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
									text
									@click="dialog = !dialog"
									outlined
								>
									취소
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import bus from '@/common/bus';

export default {
	name: 'ApplTemplatePop',
	data: () => ({
		templateTitle: '',
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.templateDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.templateDialog = newValue;
			},
		},
		AppFormCreFlag() {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		formData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.formData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.formData = newValue;
			},
		},
		memoData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.memoData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.memoData = newValue;
			},
		},
		templateDialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.templateDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.templateDialog = newValue;
			},
		},
	},
	methods: {
		async templateSubmit() {
			if(this.templateTitle.length < 1) {
				alert('템플릿 제목을 입력해주세요.');
				this.$refs['templateTitle'].focus();
				return false;			}
			if (confirm('저장하시겠습니까?')) {
				if (this.formData.payment.paymentType === 'EXIST_SAME') {
					// @ts-ignore
					this.formData.payment = {};
					// @ts-ignore
					this.formData.payment = { paymentType: 'EXIST_SAME' };
				}
				if (this.AppFormCreFlag == undefined) {
					this.formData.basic.consultTaskStatus = null; // 상담업무상태
					this.formData.basic.openingTaskStatus = null; // 개통업무상태
					this.formData.basic.logisticsTaskStatus = null; // 물류업무상태
				}
				if (this.formData.join.existTelecomCodeId === 1) {
					this.formData.join.existTelecomCodeId = this.thriftyPhone;
				}
				if (this.formData.basic.priorityTargetYn === true) {
					this.formData.basic.priorityTargetYn = 'Y';
				} else {
					this.formData.basic.priorityTargetYn = 'N';
				}

				if(this.formData.customer.bizNum1 || this.formData.customer.bizNum2 || this.formData.customer.bizNum3) {
					this.formData.customer.bizNum = this.formData.customer.bizNum1 + this.formData.customer.bizNum2 + this.formData.customer.bizNum3
				} else {
					this.formData.customer.bizNum = null
				}

				let formData = this.formData;
				let memoData = { memo: this.memoData };
				if (
						(this.memoData.memoContents !== null, this.memoData.category !== '')
				) {
					Object.assign(formData, memoData);
				}

				let paramObj = {
					templateTitle: this.templateTitle,
					templateContents: formData
				}
				await this.$store.dispatch(
						'ApplicationFormCreationModule/insertApplicationTemplate', paramObj
				);
				await this.getApplicationTemplateList();
				this.templateDialog = false
				// bus.$emit('applReset');
			}
		},
		async getApplicationTemplateList() {
			await this.$store.dispatch(
					'ApplicationFormCreationModule/getApplicationTemplateList'
			);
		},
	}
};
</script>

<style scoped></style>
