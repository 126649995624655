<template>
	<div>
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="600" @keydown.esc="returnTargetCancel">
				<v-card class="borderRadiReset infoPopup" v-click-outside="returnTargetCancel">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">반품처 선택</h2>
						<span class="closeBtn1 cur_p" @click="returnTargetCancel"></span>
					</v-card-title>

					<v-card-text class="pad30">
						<p class="mj0">반품 수령지</p>

						<div class="new_popTable1">
							<table class="w100P">
								<tr>
									<td class="w130">
										반품처<span class="redfont">*</span>
									</td>
									<td>
										<div class="radioStylePop  ml20 floatL">
											<input
													type="radio"
													id="returnTarget1"
													value="SALES_STORE"
													name="returnTarget"
													v-model.trim="formData.basic.returnStoreType"
											/>
											<label for="returnTarget1">영업점({{ formData.basic.saleStoreIdString }})</label>
										</div>

										<div class="radioStylePop  ml30 floatL">
											<input
													type="radio"
													id="returnTarget2"
													value="MATCHING_STORE"
													name="returnTarget"
													v-model.trim="formData.basic.returnStoreType"
											/>
											<label for="returnTarget2">대리점</label>
										</div>
									</td>
								</tr>
							</table>

							<div class="disFx justify-end mt10">
								<v-btn
									class="backColorMint1 mainWhite borderReset w80"
									text
									outlined
									@click="returnTargetSubmit"
								>
									저장
								</v-btn>
								<v-btn
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
									text
									@click="returnTargetCancel"
									outlined
								>
									취소
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import bus from '@/common/bus';

export default {
	name: 'ReturnTargetPop',
	props: {
		workStatusData: {type: Object, required: true},
		formData: {type: Object, required: true},
	},
	data: () => ({
		templateTitle: '',
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.returnDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.returnDialog = newValue;
			},
		},
	},
	methods: {
		async returnTargetSubmit() {
			if(!this.formData.basic.returnStoreType || this.formData.basic.returnStoreType === '') {
				alert('반품처를 선택해주세요.');
				return false;
			}
				this.$emit('changeWorkStatusFnc', this.workStatusData);
				// this.dialog = false
		},
		async returnTargetCancel() {
			delete this.formData.basic.returnStoreType;
			this.formData.basic[this.workStatusData.name] = this.workStatusData.beforeValue;
			this.dialog = false;
		}
	},
	async created() {
	},
};
</script>

<style scoped></style>
