import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const ConsultMemoModule = {
	namespaced: true,
	state: {
		formData: {
			category: '',
		},
		inputValue: '',
		memoList: [],
		tabValue: 'ALL',
		applId: 0,
	},
	mutations: {
		setList(state, result) {
			let arrTempResult = [];
			if (result) {
				for (let i = 0; i < result.length; i++) {
					if (result[i].memoContents) {
						arrTempResult.push(result[i]);
					}
				}
			}
			state.memoList = arrTempResult || [];
		},
	},
	actions: {
		async addList({ commit }, data) {
			try {
				const result = await insertMemo(data);
				if (result.data.resultCode === '0000') {
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMemoList({ commit }, data) {
			try {
				const result = await getList(data);
				if (result) {
					commit('setList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default ConsultMemoModule;
