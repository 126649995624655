<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" :width="popWidth">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 disFx justify-lg-space-between"
						style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">{{ title }}</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad10">
						<template v-if="title === '기기 선택'">
							<goods-select></goods-select>
						</template>
						<template v-if="title === '요금제 선택'">
							<charge-select></charge-select>
						</template>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import GoodsSelect from './pop-template/GoodsSelect.vue';
import ChargeSelect from './pop-template/ChargeSelect.vue';

export default {
	name: 'AmtSelectPop',
	components: {
		GoodsSelect,
		ChargeSelect,
	},
	props: ['title'],
	data: () => ({
		dataWidth: 500,
	}),
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.amtSelectDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.amtSelectDialog = newValue;
			},
		},
		popWidth() {
			if (this.title === '기기 선택') {
				return 1050;
			} else if (this.title === '요금제 선택') {
				return 960;
			} else {
				return 700;
			}
		},
	},
	methods: {},
};
</script>

<style scoped></style>
