import Vue from 'vue';
import Vuex from 'vuex';

import {
	getSellRuleFromGoodsIdSellRuleId,
	getMallProductList,
	updateMallMargin,
	updateMallConfig,
	getMallConfig,
	getStoreGoodsGroupList,
	insertSellGoods,
	updateSellGoods,
	getSellGoodsGroupInfo,
	deleteSellGoods,
	getSellGoodsList,
	getSellGoodsDetail,
	insertSellGoodsTemplate,
	getSellGoodsTemplateList,
	updateSellGoodsUseYn,
	updateSellGoodsTemplate,
} from '../../../api/mall_mgmt/MallProductMgmtApi.js';
import {
	getTelecomChargeList,
	getOpeningStoreSelectList,
} from '@/api/common/common';
import { getOpeningStoreList } from '@/api/supply-chain-mgmt/application-form-creation/ApplicationFromApi';
import { getGroupList } from '@/api/common-mgmt/goods-mgmt/MobilePhoneGroupListApi';
Vue.use(Vuex);

const MallProductMgmtModule = {
	namespaced: true,
	state: {
		ruleTableItems: [], // 정책 테이블 item
		ruleTablePolicyListTemp: {}, // 판매정책 조회한 목록 저장소
		optionTableDialog: false, // 옵션
		ruleSelectPopDialog: false, // 판매상품 정책 팝업
		ruleSelectedItem: [], // 판매 정책 선택 item
		allCheckedBox: null,
		checkListIdArray: [],
		resetData: false,
		mallConfigDto: {},
		mallMarginDto: {
			mallMarginId: 0,
			sktDvcChange: 0,
			sktNumChange: 0,
			sktNew: 0,
			ktDvcChange: 0,
			ktNumChange: 0,
			ktNew: 0,
			lgDvcChange: 0,
			lgNumChange: 0,
			lgNew: 0,
		},
		filterData: {},
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		productList: [],
		templateList: [],
		codeList: {
			sktChargeList: [],
			ktChargeList: [],
			lguChargeList: [],
			openingStoreList: [],
			goodsGroupList: [],
			// optionTable
			sktOptionTableList: [],
			ktOptionTableList: [],
			lguOptionTableList: [],
			goodsGroupitem: [],
		},
		SellGoodsRequestDto: {
			mainInfoDto: {
				autoRegYn: 'N',
				sellGoodsMainInfoId: null, // 판매상품 메인정보 아아디
				beforeReserveYn: 'N', // 사전 예약 여부
				priorityTargetYn: 'N', // 우선 대상 여부
				goodsGroupId: null, // 상품그룹 아이디
				networkCodeId: 14, // 통신망 코드 아이디
				sktTelecomUseYn: null, // SKT 통신사 사용 여부
				ktTelecomUseYn: null, // KT 통신사 사용 여부
				lguTelecomUseYn: null, // LGU 통신사 사용 여부
				etcTelecomUseYn: null, // 기타 통신사 사용여부
				makerName: null, // 제조사
			},
			subInfoDto: {
				// 용량,색상
				sktGoodsOptionId: [], // list 상품옵션아이디
				ktGoodsOptionId: [], // list 상품옵션아이디
				lguGoodsOptionId: [], // list 상품옵션아이디
				// 할부 기간 타입
				sktInstlPeriodType: ['CASH', 'MONTH_12', 'MONTH_24'], // list 할부 기간 타입
				ktInstlPeriodType: ['CASH', 'MONTH_12', 'MONTH_24'], // list 할부 기간 타입
				lguInstlPeriodType: ['CASH', 'MONTH_12', 'MONTH_24'], // list 할부 기간 타입
				// 기본 요금제 id
				sktBasicChargeId: null, // 기본요금제아이디
				ktBasicChargeId: null, // 기본요금제아이디
				lguBasicChargeId: null, // 기본요금제아이디
				etcBasicChargeId: null, // 기본요금제아이디
				// 상품 상세
				sktGoodsDetail: null, // 상품상세
				ktGoodsDetail: null, // 상품상세
				lguGoodsDetail: null, // 상품상세
			},
			sellGoodsStoreMarginInfoDto: {
				sktDvcChange: 0,
				sktNew: 0,
				sktNumChange: 0,
				ktDvcChange: 0,
				ktNew: 0,
				ktNumChange: 0,
				lgDvcChange: 0,
				lgNew: 0,
				lgNumChange: 0,
				regiDateTime: '',
				saleStoreId: 0,
				// sellGoodsMainInfoId: 0,
			},
			goodsRuleList: [],
		},
	},
	mutations: {
		setMallMargin(state, result) {
			state.mallMarginDto = result;
		},
		setMallConfig(state, result) {
			state.mallConfigDto = result;
		},
		setMallProductList(state, result) {
			state.preductList = result;
		},
		setOpeningStoreList(state, result) {
			state.codeList.openingStoreList = [];
			state.codeList.openingStoreList = result;
		},
		setStoreGoodsGroupList(state, result) {
			state.codeList.goodsGroupList = [];
			state.codeList.goodsGroupList = result;
		},
		setSellGoodsGroupInfo(state, result) {
			state.codeList.sktOptionTableList = [];
			state.codeList.ktOptionTableList = [];
			state.codeList.lguOptionTableList = [];

			state.codeList.sktOptionTableList = result.sktSellGoodsGroupInfoList;
			state.codeList.ktOptionTableList = result.ktSellGoodsGroupInfoList;
			state.codeList.lguOptionTableList = result.lguSellGoodsGroupInfoList;
			state.SellGoodsRequestDto.mainInfoDto.makerName = result.makerName || '';
			state.SellGoodsRequestDto.mainInfoDto.networkCodeId =
				result.networkCodeId;
			state.optionTableDialog = true;
		},
		setGoodsGroupList(state, result) {
			state.codeList.goodsGroupitem = result.data.data.resultList;
		},
		setOpeningStoreSelectList(state, result) {
			state.codeList.sktFilterItem = [];
			state.codeList.ktFilterItem = [];
			state.codeList.lguFilterItem = [];
			result.data.data.forEach(item => {
				if (item.telecom === 5) {
					state.codeList.sktFilterItem.push(item);
				} else if (item.telecom === 6) {
					state.codeList.ktFilterItem.push(item);
				} else if (item.telecom === 7) {
					state.codeList.lguFilterItem.push(item);
				} else {
					return false;
				}
			});
		},

		setSktTelecomChargeList(state, result) {
			state.codeList.sktChargeList = [];
			state.codeList.sktChargeList = result;
		},
		setKtTelecomChargeList(state, result) {
			state.codeList.ktChargeList = [];
			state.codeList.ktChargeList = result;
		},
		setLguTelecomChargeList(state, result) {
			state.codeList.lguChargeList = [];
			state.codeList.lguChargeList = result;
		},
		setSellGoodsList(state, result) {
			state.productList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setSellGoodsTemplateList(state, result) {
			state.templateList = result.resultList;
		},
	},
	actions: {
		// 상품 아이디, 정책 아이디로 요금리스트 공시정보 조회
		async getSellRuleFromGoodsIdSellRuleId({ commit }, data) {
			const result = await getSellRuleFromGoodsIdSellRuleId(data);
			if (result) {
				return result.data.data;
			}
		},
		// 개통점조회
		async getOpeningStoreList({ commit }, data) {
			try {
				const result = await getOpeningStoreList(data);
				if (result) {
					commit('setOpeningStoreList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallMargin({ commit }, data) {
			// 쇼핑몰 특별할인금 저장
			try {
				const result = await updateMallMargin(data);
				if (result && '0000' === result.data.resultCode) {
					alert('저장되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallConfig({ commit }, data) {
			// 쇼핑몰 특별할인금 적용여부 조회
			try {
				const result = await getMallConfig(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setMallConfig', result.data.data);
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallConfig({ commit }, data) {
			// 쇼핑몰 특별할인금 적용여부
			try {
				const result = await updateMallConfig(data);
				if (result && '0000' === result.data.resultCode) {
					alert('적용되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallProductList({ commit }, data) {
			// 쇼핑몰 판매상품 관리 조회
			try {
				const result = await getMallProductList(data);
				if (result) {
					console.log(result);
					// 리스트 콜
					// commit('setMallProductList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreGoodsGroupList({ commit }, data) {
			// 쇼핑몰 판매상품 관리 조회
			try {
				const result = await getStoreGoodsGroupList(data);
				if (result) {
					commit('setStoreGoodsGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 요금제 셀렉트 목록 조회
		async getTelecomChargeList({ commit }, data) {
			const result = await getTelecomChargeList(data);
			if (result) {
				if (data === 5) {
					commit('setSktTelecomChargeList', result.data.data);
				} else if (data === 6) {
					commit('setKtTelecomChargeList', result.data.data);
				} else if (data === 7) {
					commit('setLguTelecomChargeList', result.data.data);
				}
				return result;
			}
		},
		// 판매상품 정보 등록
		async insertSellGoods({ commit }, data) {
			try {
				const result = await insertSellGoods(data);
				if (result && '0000' === result.data.resultCode) {
					alert('등록되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateSellGoods({ commit }, data) {
			try {
				const result = await updateSellGoods(data);
				if (result && '0000' === result.data.resultCode) {
					alert('저장되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 상품 그룹으로 상품정보 조회
		async getSellGoodsGroupInfo({ commit }, data) {
			const result = await getSellGoodsGroupInfo(data);
			if (result) {
				commit('setSellGoodsGroupInfo', result.data.data);
				return result;
			}
		},
		async getGoodsGroupList({ commit }, data) {
			try {
				const result = await getGroupList(data);
				if (result) {
					commit('setGoodsGroupList', result);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getOpeningStoreSelectList({ commit }, data) {
			const result = await getOpeningStoreSelectList(data);
			if (result) {
				commit('setOpeningStoreSelectList', result);
			}
		},
		// 판매상품 목록 조회
		async getSellGoodsList({ commit }, data) {
			const result = await getSellGoodsList(data);
			if (result) {
				commit('setSellGoodsList', result.data.data);
			}
		},
		// 판매상품 상세 조회
		async getSellGoodsDetail({ commit }, data) {
			const result = await getSellGoodsDetail(data);
			if (result) {
				return result.data.data;
			}
		},
		// 판매상품 양식 조회
		async getSellGoodsTemplateList({ commit }, data) {
			const result = await getSellGoodsTemplateList(data);
			if (result) {
				commit('setSellGoodsTemplateList', result.data.data);
			}
		},

		// 판매 상품 양식 삭제
		async deleteSellGoods({ commit }, data) {
			try {
				const result = await deleteSellGoods(data);
				if (result && '0000' === result.data.resultCode) {
					alert('삭제되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 판매상품 양식 등록
		async insertSellGoodsTemplate({ commit }, data) {
			try {
				const result = await insertSellGoodsTemplate(data);
				if (result && '0000' === result.data.resultCode) {
					alert('저장되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 판매상품 양식 수정
		async updateSellGoodsTemplate({ commit }, data) {
			try {
				const result = await updateSellGoodsTemplate(data);
				if (result && '0000' === result.data.resultCode) {
					alert('수정되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 판매 상품 판매유무
		async updateSellGoodsUseYn({ commit }, data) {
			try {
				const result = await updateSellGoodsUseYn(data);
				if (result && '0000' === result.data.resultCode) {
					alert('수정되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default MallProductMgmtModule;
