import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/SalesManagement/ConsultMgmt/';

// 상담DB 목록 조회
const consultList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultList${param}`);
	return response;
};

// 상담배정 목록 조회
const getCounselorList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultAssignList${param}`);
	return response;
};
// 상담배정 목록 조회
const getConsultCounselorStatus = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getConsultCounselorStatus${param}`,
	);
	return response;
};
// 고객상담 설정 조회
const consultSettingList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultSettingList${param}`);
	return response;
};
// TO-DO 리스트 조회
const consultTodoList = async data => {
	if (data.param) data.param = jsonToQueryString(data.param);
	const response = await instance.get(
		`${domain}consultTodoList/${data.consultType}${data.param}`,
	);
	return response;
};
// TO-DO 그룹카운트 조회
const consultTodoGroupCnt = async data => {
	if (data.param) data.param = jsonToQueryString(data.param);
	const response = await instance.get(
		`${domain}consultTodoGroupCnt/${data.consultType}${data.param}`,
	);
	return response;
};
// 선택삭제
const deleteConsult = async data => {
	const response = await instance.post(`${domain}deleteConsult`, data);
	return response;
};
// 상담원배정
const insertConsultAssign = async data => {
	const response = await instance.post(
		`${domain}insertConsultAssign/CONSULT`,
		data,
	);
	return response;
};
// 상담원 자동배정 등록
const updateConsultCounselorStatus = async data => {
	const response = await instance.post(
		`${domain}updateConsultCounselorStatus`,
		data,
	);
	return response;
};
// 상담원 자동배정 등록
const insertConsultCounselor = async data => {
	const response = await instance.post(`${domain}insertConsultCounselor`, data);
	return response;
};
// 고객상담 설정 등록
const insertConsultSetting = async data => {
	const response = await instance.post(`${domain}insertConsultSetting`, data);
	return response;
};
// 고객상담 설정 수정
const updateConsultSetting = async data => {
	const response = await instance.post(`${domain}updateConsultSetting`, data);
	return response;
};
// 고객상담 설정 삭제
const deleteConsultSetting = async data => {
	const response = await instance.post(`${domain}deleteConsultSetting`, data);
	return response;
};
// 상담DB 1차,2차 등록한 상담원 목록
const consultMemberList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultMemberList/${param}`);
	return response;
};
// 상담DB 1차,2차 TO-DO 등록한 상담원 목록
const consultTodoMemberList = async data => {
	let consultType = '';
	let param = '';
	if (data.consultType) {
		consultType = data.consultType;
	}
	if (data.param) param = jsonToQueryString(data.param);
	const response = await instance.get(
		`${domain}consultTodoMemberList/${consultType}${param}`,
	);
	return response;
};

export {
	consultList,
	getCounselorList,
	getConsultCounselorStatus,
	insertConsultAssign,
	consultTodoList,
	consultTodoGroupCnt,
	deleteConsult,
	insertConsultSetting,
	updateConsultSetting,
	insertConsultCounselor,
	deleteConsultSetting,
	consultSettingList,
	consultMemberList,
	consultTodoMemberList,
	updateConsultCounselorStatus,
};
