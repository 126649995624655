import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/MallMember/';

// 몰 회원 관리 등록
const insertMallMember = async data => {
	const response = await instance.post(`${domain}insertMallMember`, data);
	return response;
};

// 몰 회원 관리 목록 조회
const getMallMemberList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallMemberList${param}`);
	return response;
};

// 몰 회원 관리 상세조회
const getMallMemberDetail = async data => {
	const response = await instance.get(`${domain}getMallMemberDetail/${data}`);
	return response;
};

// 몰 회원 관리 수정
const updateMallMember = async data => {
	const response = await instance.post(`${domain}updateMallMember`, data);
	return response;
};

// 몰 회원 관리 삭제
const deleteMallMember = async data => {
	const response = await instance.post(`${domain}deleteMallMember`, data);
	return response;
};

export {
	insertMallMember,
	getMallMemberList,
	getMallMemberDetail,
	updateMallMember,
	deleteMallMember,
};
