<template>
	<div class="text-center">
		<v-dialog v-model.trim="dialog" width="1100">
			<v-card class="borderRadiReset infoPopup">
				<v-card-title
					class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
				>
					<h2 class="mainWhite mainFontS boldWt">매칭 관리</h2>
					<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
				</v-card-title>

				<v-card-text>
					<div class="titleCont" style="display: block !important">
						<h2 class="subTitle mainFontS mb10">매칭 대기 및 중복 목록</h2>

						<div class="new-mainCont posR disFx borderRadi3Px borderContColor1">
							<div class="new-filterCont posA">
								<button id="initBtn1" @click="dataReset" class="mainBlue1">
									필터 초기화
								</button>
								<label for="initBtn1"></label>
							</div>
							<div class="filterLeftCont" @keyup.enter="submitSearchForm">
								<div class="inStockLeftRowCont disFx h40 mb10">
									<div class="w100P">
										<v-select
											attach
											label="통신사"
											:items="codeList.telecom"
											item-text="codeNm"
											item-value="codeSeq"
											outlined
											@change="selectedTelecomList($event)"
											v-model.trim="searchForm.telecom"
										></v-select>
									</div>
									<div class="w100P ml10">
										<v-select
											attach
											label="통신망"
											:items="codeList.network"
											item-text="codeNm"
											item-value="codeSeq"
											outlined
											@change="selectedNetworkList($event)"
											v-model.trim="searchForm.network"
										></v-select>
									</div>
								</div>
							</div>
							<div class="inStockButtonCont ml40 disFx flexDColC h40">
								<button
									class="backColorMint1 mainWhite w100P disIn borderRadi3Px h90"
									@click="submitSearchForm"
								>
									조회
								</button>
							</div>
						</div>
						<div class="disFx justify-end mt10 mb10">
							<v-btn
								class="backColorMint1 mainWhite borderReset w80"
								text
								outlined
								@click="applyMatchStatusFnc"
							>
								매칭완료
							</v-btn>
							<v-btn
								class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
								text
								@click="dialog = !dialog"
								outlined
							>
								닫기
							</v-btn>
						</div>
					</div>
					<div class="newTableStyle mt10">
						<table class="w100P">
							<thead>
								<tr>
									<th class="w50">그룹</th>
									<th class="w50">선택</th>
									<th>상품명</th>
									<th>통신망</th>
									<th>통신사</th>
									<th>사용여부</th>
									<th>매칭 여부</th>
									<th>스마트초이스 여부</th>
									<th>등록일</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in getMatchListData" :key="item.index">
									<td>
										<div class="checkStyleCont1">
											<input
												:id="`checkId1${item.chargeId}`"
												class="checkStyle"
												type="checkbox"
												:value="item.chargeId"
												v-model.trim="formData.checkValue"
											/>
											<label :for="`checkId1${item.chargeId}`"></label>
										</div>
									</td>
									<td>
										<div class="radioStyleTable w20 ml20 posHand2">
											<input
												type="radio"
												:id="`radio1Btn${item.chargeId}`"
												name="useGoodsId"
												:value="item.chargeId"
												v-model.trim="formData.radioValue"
											/>
											<label :for="`radio1Btn${item.chargeId}`"></label>
										</div>
									</td>
									<td>{{ item.chargeName }}</td>
									<td>{{ item.networkName }}</td>
									<td>{{ item.telecomName }}</td>
									<td>{{ item.useYn }}</td>
									<td>{{ item.matchingYn }}</td>
									<td v-if="item.originKey === 'S'">Y</td>
									<td v-else>N</td>
									<td>
										{{ item.regiDateTime | moment('YYYY-MM-DD HH:mm:ss') }}
									</td>
								</tr>
							</tbody>
						</table>

						<div class="disFx justify-end mt10">
							<v-btn
								class="backColorMint1 mainWhite borderReset w80"
								text
								outlined
								@click="applyMatchStatusFnc"
							>
								매칭완료
							</v-btn>
							<v-btn
								class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
								text
								@click="dialog = !dialog"
								outlined
							>
								닫기
							</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {
	applyMatchStatus,
	getMatchList,
} from '../../../../api/common-mgmt/charge-mgmt/ChargeMgmtApi.js';
import { commonCodeListFnc } from '../../../../common/common.js';

export default {
	data: () => ({
		getMatchListData: [],
		searchForm: {
			telecom: '0',
			network: '0',
		},
		codeList: {
			telecom: [],
			network: [],
		},
		formData: {
			checkValue: [],
			radioValue: 0,
		},
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.ChargeListModule.matchingDialog;
			},
			set(newValue) {
				this.$store.state.ChargeListModule.matchingDialog = newValue;
			},
		},
		getListData: {
			get() {
				return this.$store.state.ChargeListModule.getListData;
			},
			set(newValue) {
				this.$store.state.ChargeListModule.getListData = newValue;
			},
		},
		filterData: {
			get() {
				return this.$store.state.ChargeListModule.filterData;
			},
			set(newValue) {
				this.$store.state.ChargeListModule.filterData = newValue;
			},
		},
	},
	methods: {
		async getListFnc(data) {
			this.getListData = [];
			await this.$store.dispatch('ChargeListModule/getList', data);
		},
		async getMatchListFnc(data) {
			try {
				this.getMatchListData = [];
				const result = await getMatchList(data);
				if (result) {
					this.getMatchListData = result.data.data.resultList;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async selectedTelecomList(val) {
			let data = {
				telecom: val,
				network: this.searchForm.network,
			};
			await this.getMatchListFnc(data);
		},
		async selectedNetworkList(val) {
			let data = {
				telecom: this.searchForm.telecom,
				network: val,
			};
			await this.getMatchListFnc(data);
		},
		async submitSearchForm() {
			await this.getMatchListFnc(this.searchForm);
		},
		dataReset() {
			this.searchForm.telecom = '0';
			this.searchForm.network = '0';
		},
		async applyMatchStatusFnc() {
			if (!confirm('매칭하시겠습니까?')) {
				return;
			}
			try {
				const data = {
					groupChargeId: this.formData.checkValue,
					useChargeId: this.formData.radioValue,
				};
				const result = await applyMatchStatus(data);
				if (result) {
					alert('매칭 완료되었습니다.');
					this.dialog = !this.dialog;
					await this.getListFnc(this.filterData);
				}
			} catch (e) {
				alert('오류가 발생하였습니다.');
			}
		},
		originKeyText(key) {
			console.log(key);
		},
		async commonCodeListFnc() {
			let data = {
				code: ['TELECOM', 'NETWORK'],
			};
			const commonCodeList = await commonCodeListFnc(data);
			this.codeList.telecom = commonCodeList.codeList.TELECOM;
			this.codeList.network = commonCodeList.codeList.NETWORK;
			this.codeList.telecom.unshift({ codeNm: '전체', codeSeq: '0' });
			this.codeList.network.unshift({ codeNm: '전체', codeSeq: '0' });
		},
	},
	created() {
		this.getMatchListFnc();
		this.commonCodeListFnc();
	},
};
</script>
<style scoped>
.filterCont {
	margin-right: 80px;
}
.subTitle {
	color: #003a53;
	font-weight: bold;
	padding-bottom: 5px;
}
.btnCont {
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
}
</style>
