import Vue from 'vue';
import Vuex from 'vuex';
import {
	getDeviceInfo,
	getReturnStockList,
	insertReturnStock,
	insertReturnStockExcel,
	insertReturnStockExcelException,
	getReturnStockWaitList,
	deleteReturnStockWait,
	getReturnDevice,
	returnStoreWaitCheck,
	insertReturnStockWait,
	updateReturnStockWait,
} from '../../../../api/warehouse-mgmt/device-mgmt/MoveStockRtngudApi';
import { commonCodeListFnc } from '../../../../common/common';
import {
	getRetrieveDeviceHistory,
	getRetrieveDeviceStatusInfo,
	selectCodeList,
} from '../../../../api/common/common';
import bus from '@/common/bus';
import { commonCodeEnumList } from '@/api/warehouse-mgmt/device-mgmt/NormalInStockApi';

Vue.use(Vuex);
const MoveStockRtngudModule = {
	namespaced: true,
	state: {
		applicationDto: 1,
		overlapDevicePop: false,
		infoDialog: false,
		regiDialog: false,
		excelUploadDialog: false,
		totalCnt: 0,
		deviceInfo: {},
		deviceInfoChk: false,
		moveStockList: [],
		deviceHistory: [],
		deviceStatusDetail: [],
		allCheckedBoxView: '',
		allCheckedBoxInput: '',
		checkListIdArrayView: [],
		checkListIdArrayInput: [],
		checkListIdArray: [],
		filterData: {
			telecom: '',
			nextStockIds: '',
			provId: '',
			openingHistoryStoreIds: '',
			maker: '',
			goodsName: '전체',
			capacity: '전체',
			colorName: '전체',
			goodsType: '',
			barcode: '',
			perPageCnt: 20,
		},
		filterDataReturn: {
			barcode: '',
			skipYn: 'N',
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		codeList: {
			telecom: [],
			prov: [],
			maker: [],
			stock: [],
			deviceList: [],
		},
		enumCodeList: {},
		applicationDtoList: [],
		returnStockList: [],
		returnStockWaitList: {
			resultReturnStockWait: [],
			resultReturnSotckWaitGroup: [],
		},
		quickOpeningDialog: false,
	},
	getters: {},
	mutations: {
		returnStockListDataSet(state, result) {
			state.moveStockList = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setCommonCodeList(state, data) {
			state.codeList.telecom = data.codeList.TELECOM;
			state.codeList.maker = data.codeList.MAKER;
			state.codeList.prov = data.provList;
		},
		closeDialog(state, name) {
			if (name === 'infoDialog') {
				state.infoDialog = false;
			} else if (name === 'regiDialog') state.regiDialog = false;
		},
		filterInit(state) {
			for (let data in state.filterData) {
				state.filterData[data] = '';
			}
			state.filterData.perPageCnt = 20;
			state.filterData.goodsName = '전체';
			state.filterData.capacity = '전체';
			state.filterData.colorName = '전체';
		},
		setDeviceInfo(state, data) {
			state.applicationDto = null;
			state.deviceInfoChk = true;
			state.deviceInfo = data.data.data;
			if (data.data.data.applicationDto.applId) {
				state.applicationDto = data.data.data.applicationDto;
			}
		},
		setDeviceHistory(state, data) {
			state.deviceHistory = data.data.data;
		},
		setUsimTypeItems(state, data) {
			state.enumCodeList.goodsTypeItems = [];
			state.enumCodeList.goodsTypeItems = data.data.data.GoodsType;
			state.enumCodeList.goodsTypeItems.unshift({ name: '전체', value: '' });
		},
		setDeviceStatusInfo(state, data) {
			state.deviceStatusDetail = [];
			state.deviceStatusDetail = data.data.data;
		},
		selectListSet(state, data) {
			state.codeList.stock = data.data.data;
			state.filterData.stockIds = '';
		},
		setReturnStockWaitList(state, data) {
			state.returnStockWaitList = data.data.data;
		},
		setReturnDevice(state, data) {
			let findIdx = state.returnStockList.findIndex(object => {
				return object.rawBarcode === state.filterDataReturn.barcode;
			});
			if (findIdx === -1) {
				data.data.data.rawBarcode = state.filterDataReturn.barcode;
				data.data.data.barcodeType = data.barcodeType;
				data.data.data.searchDvcId = data.data.data.join.dvcId;
				state.returnStockList.unshift(data.data.data);
			} else {
				alert('중복된 조회정보가 있습니다.');
			}
			state.filterDataReturn.barcode = '';
		},
		setQuickOpeningResoutFormData(state, data) {
			// 동일신청서가 있는지 필터
			let tmpReturnStockList = state.returnStockList.slice();
			let filterAppl = tmpReturnStockList.filter(object => {
				return object.basic.applId === data.basic.applId;
			});
			let dvcArr = [];
			filterAppl.forEach(dvcItem => {
				dvcArr.push(dvcItem.searchDvcId)
			})
			if(filterAppl.length > 1) { // 동일신청서가 있는경우
				// 신청서 모두 업데이트
				state.returnStockList.map(item => {
					if(dvcArr.includes(item.searchDvcId)) {
						const rawBarcode = item.rawBarcode;
						const barcodeType = item.barcodeType;
						const searchDvcId = item.searchDvcId;
						data.rawBarcode = rawBarcode;
						data.barcodeType = barcodeType;
						data.searchDvcId = searchDvcId;
						let applData = {...data};
						Object.assign(item, applData);
						item.rawBarcode = rawBarcode
						item.barcodeType = barcodeType
						item.searchDvcId = searchDvcId
					}
				})
			} else {
				let findIdx = state.returnStockList.findIndex(object => {
					return object.basic.applId === data.basic.applId;
				});
				if (findIdx !== -1) {
					const rawBarcode = state.returnStockList[findIdx].rawBarcode;
					const barcodeType = state.returnStockList[findIdx].barcodeType;
					const searchDvcId = state.returnStockList[findIdx].searchDvcId;
					state.returnStockList[findIdx] = data;
					state.returnStockList[findIdx].rawBarcode = rawBarcode;
					state.returnStockList[findIdx].barcodeType = barcodeType;
					state.returnStockList[findIdx].searchDvcId = searchDvcId;
				}
			}
		},
	},
	actions: {
		async getCommonCodeList({ commit }, data) {
			try {
				let data = { code: ['TELECOM', 'MAKER'], initData: ['provList'] };
				const result = await commonCodeListFnc(data);
				if (result) {
					commit('setCommonCodeList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertReturnStock({ commit }, formData) {
			try {
				const result = await insertReturnStock(formData);
				if (result.data.status === 200) {
					if (result.data.data.length > 0) {
						alert(`${result.data.data.length}개 등록 실패`);
						return result.data.data;
					} else {
						alert('등록되었습니다.');
						commit('closeDialog', 'regiDialog');
						return [];
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
				}
			} catch (e) {
				console.log(e);
				alert(`알 수 없는 오류가 발생했습니다.`);
			}
		},
		async getReturnStockList({ commit }, data) {
			try {
				if (data && data.goodsName === '전체') {
					data.goodsName = '';
				}
				if (data && data.capacity === '전체') {
					data.capacity = '';
				}
				if (data && data.colorName === '전체') {
					data.colorName = '';
				}
				let result = await getReturnStockList(data);
				if (result) {
					commit('returnStockListDataSet', result);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
					if (data && data.capacity === '') {
						data.capacity = '전체';
					}
					if (data && data.colorName === '') {
						data.colorName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getDeviceInfo({ commit }, data) {
			try {
				let result = await getDeviceInfo(data);
				if (result.data.resultCode === '0000') {
					// 성공
					commit('setDeviceInfo', result);
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceHistory(data);
				if (result) {
					commit('setDeviceHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertReturnStockExcel({ commit }, data) {
			try {
				let result = await insertReturnStockExcel(data);
				if (result) {
					return result.data;
				}
			} catch (e) {
				alert('등록실패했습니다.');
				bus.$emit('end:spinner');
				console.log(e);
			}
		},
		async insertReturnStockExcelException({ commit }, data) {
			try {
				let result = await insertReturnStockExcelException(data);
				if (result) {
					const url = window.URL.createObjectURL(
						new Blob([result.data], { type: result.headers['content-type'] }),
					);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `업로드실패.xlsx`);
					document.body.appendChild(link);
					link.click();
				}
			} catch (e) {
				bus.$emit('end:spinner');
				console.log(e);
			}
		},
		async commonCodeEnumList({ commit }) {
			let data = { initEnumData: ['GoodsType'] };
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setUsimTypeItems', result);
			}
		},
		async getRetrieveDeviceStatusInfo({ commit }, data) {
			const result = await getRetrieveDeviceStatusInfo(data);
			if (result) {
				commit('setDeviceStatusInfo', result);
			}
		},
		// 셀렉트 코드리스트콜
		async selectCodeList({ commit }, data) {
			const result = await selectCodeList(data);
			if (result) {
				commit('selectListSet', result);
			}
		},
		// 반품기기 조회(renewal)
		async getReturnDevice({ commit }, data) {
			const result = await getReturnDevice(data);
			if (result.data.status === 200) {
				if (
					result.data.resultCode === '9224' ||
					result.data.resultCode === '9225'
				) {
					// 컨펌
					return result.data;
				} else if (result.data.resultCode !== '0000') {
					// alert
					alert(result.data.resultMsg);
					return false;
				} else {
					result.barcodeType = data.barcodeType;
					commit('setReturnDevice', result);
				}
			}
		},
		// 반품 대기 등록(renewal)
		async insertReturnStockWait({ commit }, data) {
			try {
				let result = await insertReturnStockWait(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert('알 수 없는 오류가 발생했습니다.');
						return false;
					} else {
						alert('저장되었습니다.');
						return true;
					}
				}
			} catch (e) {
				alert('삭제실패했습니다.');
				bus.$emit('end:spinner');
				console.log(e);
			}
		},
		// 반품 대기 수정(renewal)
		async updateReturnStockWait({ commit }, data) {
			try {
				let result = await updateReturnStockWait(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert('알 수 없는 오류가 발생했습니다.');
						return false;
					} else {
						alert('수정되었습니다.');
						return true;
					}
				}
			} catch (e) {
				alert('삭제실패했습니다.');
				bus.$emit('end:spinner');
				console.log(e);
			}
		},
		// 반품가능여부 체크(renewal)
		async returnStoreWaitCheck({ commit }, data) {
			try {
				let result = await returnStoreWaitCheck(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				alert('삭제실패했습니다.');
				bus.$emit('end:spinner');
				console.log(e);
			}
		},
		// 반품 대기목록 조회(renewal)
		async getReturnStockWaitList({ commit }, data) {
			const result = await getReturnStockWaitList(data);
			if (result) {
				commit('setReturnStockWaitList', result);
			}
		},
		// 반품 대기삭제(renewal)
		async deleteReturnStockWait({ commit }, data) {
			try {
				let result = await deleteReturnStockWait(data);
				if (result) {
					return result.data;
				}
			} catch (e) {
				alert('삭제실패했습니다.');
				bus.$emit('end:spinner');
				console.log(e);
			}
		},
		// 반품 처리(renewal)
		async submitReturnStock({ commit }, data) {
			try {
				const result = await insertReturnStock(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
						return false;
					} else {
						alert('등록되었습니다.');
						return true;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
				}
			} catch (e) {
				console.log(e);
				alert(`알 수 없는 오류가 발생했습니다.`);
			}
		},
		// 반품이관 - 빠른개통 수정시 데이터 set (renewal)
		async getQuickOpeningResoutFormData({ commit }, data) {
			if (data) {
				commit('setQuickOpeningResoutFormData', data);
			}
		},
	},
};

export default MoveStockRtngudModule;
