import Vue from 'vue';
import Vuex from 'vuex';
import {
	commonCodeEnumList,
	getDeviceProvInfo,
	getDeviceStock,
	getMoveAndTrnsList,
	getTransStoreList,
} from '../../../../../api/common/common.js';
import { commonCodeListFnc } from '../../../../../common/common';
import {
	innerStockList,
	selectStatusList,
	insertStockMove,
} from '../../../../../api/warehouse-mgmt/device-mgmt/StockMoveTransfer/StockMoveApi';
import {
	insertSellMove,
	insertWaitMoveStock,
	getWaitMoveStockList,
	deleteWaitMoveStock,
	insertAllWaitMoveStock,
	getWaitOutStockList,
	insertWaitOutStock,
	insertAllWaitOutStock,
	deleteWaitOutStock,
	updateWaitMoveStock,
	updateWaitOutStock,
} from '../../../../../api/warehouse-mgmt/device-mgmt/StockMoveTransfer/SalesMoveApi';

Vue.use(Vuex);

const StockMoveFilterCommonModules = {
	namespaced: true,
	state: {
		stockMoveSaleStatus: 'Stock_Move', // 이동유형
		stockMoveTransferStatus: 'Return_Transfer', // 이동유형
		stockMoveSaleItems: [
			{ name: '재고이동', value: 'Stock_Move' },
			{ name: '판매이동', value: 'Sale_Move' },
		], // 이동유형 Enum
		stockMoveTransferItems: [
			{ name: '반품이관', value: 'Return_Transfer' },
			{ name: '판매이관', value: 'Sale_Transfer' },
			{ name: '재고이관', value: 'Stock_Transfer' },
			{ name: '불량이관(착하)', value: 'Faulty_Transfer' },
		], // 이관유형 Enum
		checkListIdArray: [],
		allCheckedBox: null,
		totalCnt: 0,
		moveTotalCnt: 0,
		formData: {
			provId: '',
			provName: '',
			transStoreId: '',
			nextStockId: '',
			barcode: '',
			deliveryType: 'PS',
			courier: '',
			invoiceNo: '',
			deliveryMemo: '',
			selDvcId: '',
			cusName: '',
			cusPhone: '',
			cusPhone1: '',
			cusPhone2: '',
			cusPhone3: '',
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		movePagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		codeList: {
			transItems: [],
			holdItems: [],
			provItems: [],
			courierItems: [],
			moveItems: [],
			installmentsPeriodTypeItems: [], // 할부유형
			usimPaymentTypeItems: [], // 유심방식
		},
		optionList: [],
		getListData: [],
		getMoveListData: [],
		moveWaitGroupList: [],
		moveWaitList: [],
		trnsWaitGroupList: [],
		trnsWaitList: [],
	},
	getters: {},
	mutations: {
		filterInit(state) {
			state.formData.holdPlc = '';
			state.formData.nextStockId = '';
			state.formData.barcode = '';
			state.formData.deliveryType = 'PS';
			state.formData.courier = '';
			state.formData.invoiceNo = '';
			state.formData.deliveryMemo = '';
			state.formData.provId = '';
			state.formData.provName = '';
			state.formData.transStoreId = '';
		},
		transItemsSet(state, result) {
			state.codeList.transItems = [];
			state.codeList.transItems = result.data.data;
		},
		holdItemsSet(state, result) {
			state.codeList.holdItems = result.data.data;
			state.formData.holdPlc = state.codeList.holdItems.stockName;
		},
		provItemsSet(state, result) {
			state.codeList.provItems = result.data.data;
			state.formData.provId = state.codeList.provItems.provId;
			state.formData.provName = state.codeList.provItems.provName;
		},
		setCommonCodeList(state, result) {
			state.codeList.courierItems = result.codeList.COURIER;
		},
		moveStockItemSet(state, result) {
			state.codeList.moveItems = result.data.data;
		},
		getListSet(state, result) {
			state.getListData = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		getMoveListSet(state, result) {
			state.getMoveListData = result.data.data.resultList;
			state.moveTotalCnt = result.data.data.totalCnt;
			state.movePagingData.perPageCnt = result.data.data.perPageCnt;
			state.movePagingData.pageNo = result.data.data.pageNo;
			state.movePagingData.pageNumList = result.data.data.pageNumList;
			state.movePagingData.pageLastNo = result.data.data.pageLastNo;
			state.movePagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setStatusList(state, result) {
			if (result.data.data.DeliveryType) {
				result.data.data.DeliveryType.splice(0, 1);
			}
			state.optionList = result.data.data;
		},
		// 이동 대기리스트콜
		setWaitMoveStockList(state, result) {
			state.moveWaitList = [];
			state.moveWaitGroupList = [];
			state.moveWaitList = result.moveStockWaitList || [];
			state.moveWaitGroupList = result.moveStockWaitGroupList || [];
		},
		// 이관 대기리스트콜
		setWaitOutStockList(state, result) {
			state.trnsWaitList = [];
			state.trnsWaitGroupList = [];
			state.trnsWaitList = result.outStockWaitList || [];
			state.trnsWaitGroupList = result.outStockWaitGroupList || [];
		},
		setCommonCodeEnumList(state, result) {
			state.codeList.installmentsPeriodTypeItems =
				result.InstallmentsPeriodType || []; // 할부기간
			state.codeList.usimPaymentTypeItems = result.UsimPaymentType || []; // 유심
		},
	},
	actions: {
		async getTransStoreList({ commit }, data) {
			const result = await getTransStoreList(data);
			if (result) {
				commit('transItemsSet', result);
			}
		},
		async getDeviceStock({ commit }, data) {
			try {
				if (data.barcode === '') {
					alert('기기 일련번호 를 입력해 주세요.');
					return;
				}
				const result = await getDeviceStock(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
						return;
					} else {
						commit('holdItemsSet', result);
					}
				}
			} catch (e) {
				alert('알 수 없는 오류가 발생했습니다.');
			}
		},
		async getDeviceProvInfo({ commit }, data) {
			if (data.barcode === '') {
				return;
			}
			const result = await getDeviceProvInfo(data);
			if (result) {
				commit('provItemsSet', result);
			}
		},
		async commonCodeList({ commit }) {
			try {
				let data = { code: ['COURIER'] };
				const result = await commonCodeListFnc(data);
				if (result) {
					commit('setCommonCodeList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStatusList({ commit }) {
			try {
				let data = {
					initEnumData: ['DeliveryType'],
				};
				const result = await selectStatusList(data);
				if (result) {
					commit('setStatusList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getList({ commit }, data) {
			const result = await getMoveAndTrnsList(data);
			if (result) {
				if (data.title === 'STOCK_MOVE' || data.title === 'SELL_MOVE') {
					commit('getMoveListSet', result);
				} else {
					commit('getListSet', result);
				}
			}
		},
		async moveStockItems({ commit }, data) {
			const result = await innerStockList(data);
			if (result) {
				commit('moveStockItemSet', result);
			}
		},
		async insertStockMove({ commit }, data) {
			const result = await insertStockMove(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('등록되었습니다.');
					}
				}
				return result;
			}
		},
		async insertSellMove({ commit }, data) {
			const result = await insertSellMove(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('등록되었습니다.');
					}
				}
				return result;
			}
		},

		// 신규 api
		// 이동대기등록
		async insertWaitMoveStock({ commit }, data) {
			const result = await insertWaitMoveStock(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('등록되었습니다.');
					}
				}
				return result;
			}
		},

		// 이동대기 리스트콜
		async getWaitMoveStockList({ commit }, data) {
			const result = await getWaitMoveStockList(data);
			if (result) {
				commit('setWaitMoveStockList', result.data.data);
			}
		},

		// 이관대기 리스트콜
		async getWaitOutStockList({ commit }, data) {
			const result = await getWaitOutStockList(data);
			if (result) {
				commit('setWaitOutStockList', result.data.data);
			}
		},

		// 이동 대기 삭제
		async deleteWaitMoveStock({ commit }, data) {
			const result = await deleteWaitMoveStock(data);
			if (result) {
				return true;
			}
		},

		// 전체 이동처리
		async insertAllWaitMoveStock({ commit }, data) {
			const result = await insertAllWaitMoveStock(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('전체 이동 처리되었습니다.');
					}
				}
				return result;
			}
		},
		// 이관대기 등록
		async insertWaitOutStock({ commit }, data) {
			const result = await insertWaitOutStock(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('등록되었습니다.');
					}
				}
				return result;
			}
		},

		// 전체 이관처리
		async insertAllWaitOutStock({ commit }, data) {
			const result = await insertAllWaitOutStock(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('전체 이관 처리되었습니다.');
					}
				}
				return result;
			}
		},

		// 이관 대기 삭제
		async deleteWaitOutStock({ commit }, data) {
			const result = await deleteWaitOutStock(data);
			if (result) {
				return true;
			}
		},

		// 이관 대기 수정
		async updateWaitMoveStock({ commit }, data) {
			const result = await updateWaitMoveStock(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('수정되었습니다.');
					}
				}
				return result;
			}
		},

		// 이관 대기 수정
		async updateWaitOutStock({ commit }, data) {
			const result = await updateWaitOutStock(data);
			if (result) {
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						alert('수정되었습니다.');
					}
				}
				return result;
			}
		},
		async commonCodeEnumList({ commit }) {
			try {
				let data = {
					initEnumData: [
						'InstallmentsPeriodType', // 할부기간
						'UsimPaymentType', // 유심
					],
				};
				const result = await commonCodeEnumList(data);
				if (result) {
					commit('setCommonCodeEnumList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default StockMoveFilterCommonModules;
