import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/MallTerms/';
const domainSales = '/MallManagement/SalesTermsMgmt/';
// 몰 사용자 회원가입 약관 등록
const insertMallTerms = async data => {
	const response = await instance.post(`${domain}insertMallTerms`, data);
	return response;
};

// 입점 약관 등록
const insertStoresTerms = async data => {
	const response = await instance.post(`${domainSales}insertStoresTerms`, data);
	return response;
};

// 몰 사용자 회원가입 약관 조회
const getMallTermsList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallTermsList${param}`);
	return response;
};

// 몰 사용자 회원가입 약관 조회
const getMallTermsHistoryList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallTermsHistoryList${param}`);
	return response;
};

// 몰 사용자 회원가입 약관 상세조회
const getMallTermsDetail = async data => {
	const response = await instance.get(`${domain}getMallTermsDetail/${data}`);
	return response;
};

// 입점 약관 목록 조회
const getStoresTermsList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getStoresTermsList${param}`);
	return response;
};


// 입점 약관 이력 목록 조회
const getStoresTermsHistoryList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getStoresTermsHistoryList${param}`);
	return response;
};

// 입점 약관 목록 상세 조회
const getStoresTermsDetail = async data => {
	const response = await instance.get(`${domainSales}getStoresTermsDetail/${data}`);
	return response;
};

export {
	insertMallTerms,
	insertStoresTerms,
	getMallTermsList,
	getMallTermsHistoryList,
	getMallTermsDetail,
	getStoresTermsList,
	getStoresTermsHistoryList,
	getStoresTermsDetail,
};
