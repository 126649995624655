<template>
	<div class="ml30" style="width: 528px">
		<div
			class="backColorWhite borderRadi5Px borderContColor1 mt40"
			style="
				padding: 10px 20px 20px 20px;
				position: sticky;
				top: 50px;
				margin-top: 45px;
			"
		>
			<total-save-amt-data></total-save-amt-data>
			<charge-board-data></charge-board-data>
		</div>
	</div>
</template>

<script>
import totalSaveAmtData from './save-section/totalSaveAmtData.vue';
import chargeBoardData from './save-section/chargeBoardData.vue';

export default {
	name: 'CalculatorSaveSection',
	components: {
		totalSaveAmtData,
		chargeBoardData,
	},
	methods: {
		copyFnc() {
			console.log('copy');
		},
	},
};
</script>

<style scoped></style>
