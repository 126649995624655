import { instance } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = '/TechInquiryNoticeManagement/TechInquiryNotiMgmt/';

// 공지사항 목록 조회
const getTechInquiryNoticeList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getTechInquiryNoticeList${param}`);
	return response;
};

// 공지사항 cnt
const getTechInquiryNoticeGroupCnt = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getTechInquiryNoticeGroupCnt${param}`);
	return response;
};

// 공지사항 파일 조회
const getTechInquiryNoticeAttachList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getTechInquiryNoticeAttachList${param}`,
	);
	return response;
};

// 공지사항 등록
const insertTechInquiryNotice = async data => {
	const response = await instance.post(`${domain}insertTechInquiryNotice`, data);
	return response;
};

// 공지사항 수정
const updateTechInquiryNotice = async data => {
	const response = await instance.post(`${domain}updateTechInquiryNotice`, data);
	return response;
};

// 공지사항 파일추가
const uploadTechInquiryNoticeAttach = async data => {
	const response = await instance.post(`${domain}uploadTechInquiryNoticeAttach`, data);
	return response;
};

// 공지사항 삭제
const deleteTechInquiryNotice = async data => {
	const response = await instance.post(`${domain}deleteTechInquiryNotice`, data);
	return response;
};

// 공지사항 관련 파일 삭제
const deleteTechInquiryNoticeAttach = async data => {
	const response = await instance.post(`${domain}deleteTechInquiryNoticeAttach`, data);
	return response;
};

// 시스템 공지 조회수 증가
const updateReadCnt = async data => {
	const response = await instance.post(`${domain}updateReadCnt`, data);
	return response;
};

// 시스템 중요공지 조회
const getTopImportantTechInquiryNotice = async data => {
	const response = await instance.get(`${domain}getTopImportantTechInquiryNotice`);
	return response;
};

// 기술문의 댓글 조회
const getTechInquiryCommentList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getTechInquiryCommentList${param}`);
	return response;
};

// 기술문의 댓글 등록
const insertTechInquiryComment = async data => {
	const response = await instance.post(`${domain}insertTechInquiryComment`, data);
	return response;
};

// 기술문의 댓글 삭제
const deleteTechInquiryComment = async data => {
	const response = await instance.post(`${domain}deleteTechInquiryComment`, data);
	return response;
};

export {
	getTechInquiryNoticeList,
	getTechInquiryNoticeGroupCnt,
	getTechInquiryNoticeAttachList,
	insertTechInquiryNotice,
	updateTechInquiryNotice,
	uploadTechInquiryNoticeAttach,
	deleteTechInquiryNotice,
	deleteTechInquiryNoticeAttach,
	updateReadCnt,
	getTopImportantTechInquiryNotice,
	getTechInquiryCommentList,
	insertTechInquiryComment,
	deleteTechInquiryComment,
};
