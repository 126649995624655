<template>
	<div class="todoDrawer" :class="{ closeRnb: !activeRnb ,zIndexM1: activeRnbIndex !== 2 }">
		<a id="RnbBtn2" @click="toggleRnb" v-if="ss !== '140' && ss !== '141'">
			<img src="../../assets/img/icon_todo_w.svg" />
			<!-- todo 갯수가 0초과 100미만일 경우-->
			<span v-if="todoRnbCnt > 0 && todoRnbCnt < 100" class="todoCount">
				{{ todoRnbCnt }}
			</span>
			<!-- 100개 이상일 경우 99+ 표시 svg -->
			<em class="maxCnt" v-if="todoRnbCnt >= 100"></em>
		</a>
		<div
			class="todoDrawer"
			:class="{ closeRnb: !activeRnb, noEvent: activeRnbIndex !== 2 }"
			style="background: transparent"
		>
			<!-- [KT-CB] 신조개통점 권한 예외처리 -->
			<div v-if="activeRnbIndex === 2" class="mt-13" style="padding: 16px">
				<dashboard-todo parent="rnb"></dashboard-todo>
			</div>
		</div>
	</div>
</template>

<script>
import DashboardTodo from '@/components/dashboard/DashboardTodo';
export default {
	name: 'TodoDrawer',
	components: { DashboardTodo },
	computed: {
		todoRnbCnt() {
			return this.$store.state.TodoModule.todoRnbCnt;
		},
		activeRnb: {
			get() {
				return this.$store.state.NoticeModule.activeRnb;
			},
			set(newValue) {
				this.$store.state.NoticeModule.activeRnb = newValue;
			},
		},
		activeRnbIndex: {
			get() {
				return this.$store.state.NoticeModule.activeRnbIndex;
			},
			set(newValue) {
				this.$store.state.NoticeModule.activeRnbIndex = newValue;
			},
		},
		todoActive: {
			get() {
				return this.$store.state.TodoModule.activeRnb;
			},
			set(newValue) {
				this.$store.state.TodoModule.activeRnb = newValue;
			},
		},
		todoRnbList: {
			get() {
				return this.$store.state.TodoModule.todoRnbList;
			},
			set(newValue) {
				this.$store.state.TodoModule.todoRnbList = newValue;
			},
		},
		ss() {
			return this.$store.state.cookies.ss; // [KT-CB] 신조개통점 권한 예외처리
		},
	},
	methods: {
		outSideFnc() {
			this.activeRnb = false;
		},
		async toggleRnb() {
			if (!this.activeRnb) {
				this.activeRnb = true;
				// await this.getNotiList();
				this.activeRnbIndex = 2;
			} else {
				if (this.activeRnbIndex === 2) {
					this.activeRnb = false;
				} else {
					// await this.getNotiList();
					this.activeRnbIndex = 2;
				}
			}
		},
	},
};
</script>

<style scoped>
.todoDrawer {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	width: 370px;
	height: 100vh;
	box-shadow: 0px 0px 7px 1px #bebebe;
	z-index: 37;
	transition: all 0.3s;
}
.closeRnb {
	right: -370px;
	transition: all 0.3s;
}
.todoDrawer #RnbBtn2 {
	display: block;
	position: absolute;
	width: 42px;
	height: 37px;
	background: #007ef2;
	border-radius: 100% 0 0 100%;
	top: 94px;
	left: -42px;
	transition: all 0.2s;
	transform-origin: right;
}
.todoDrawer #RnbBtn2 img {
	position: absolute;
	top: 9px;
	left: 10px;
	width: 22px;
	height: 22px;
}
.noticeDrawer #RnbBtn2:hover {
	left: -45px;
	width: 45px;
}
.todoDrawer #RnbBtn2:hover .todoCount,
.todoDrawer #RnbBtn2:hover .maxCnt {
	right: 4px;
}
.todoDrawer #RnbBtn2 .maxCnt {
	position: absolute;
	display: flex;
	width: 18px;
	height: 20px;
	background: url('../../assets/img/maxCnt.svg');
	top: 0;
	right: 2px;
	z-index: 14;
	transition: all 0.2s;
}
.todoDrawer #RnbBtn2 .todoCount {
	display: inline-block;
	text-align: center;
	position: absolute;
	min-width: 18px;
	height: 18px;
	top: 2px;
	right: 2px;
	border-radius: 3em;
	padding: 0.1em;
	font-size: 0.7em;
	line-height: 1.4em;
	background: #ff7a00;
	color: #fff;
	z-index: 16;
	transition: all 0.2s;
}
.noEvent {
	pointer-events: none;
}
.zIndexM1 {
	z-index: -1;
}
</style>
