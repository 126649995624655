import { instance, instanceAuth, instanceNS } from '../../index.js';
import { appCommonFormData } from '../../../store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import { jsonToQueryString } from '../../../common/common.js';
import { filterData } from '@/store/interface/supply-chain-mgmt/sell-status/sellStatusInterface';

const insertData = async (data: appCommonFormData) => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/insertApplication`,
		data,
	);
	return response;
};

// 신청서 업데이트
const updateApplication = async (data: appCommonFormData) => {
	const response = await instance.post(
		`
			ApplicationManagement/ApplicationMgmt/updateApplication`,
		data,
	);
	return response;
};

// 신청서 파일 삭제
const deleteApplicationAttachAndMemo = async (data: any) => {
	const response = await instance.post(
		`
		ApplicationManagement/ApplicationMgmt/deleteApplicationAttachAndMemo`,
		data,
	);
	return response;
};

// 신청서 상세조회
const getApplicationDetail = async (data: string) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`SellCurrentManagement/SellCurrentMgmt/getApplicationDetail${param}`,
	);
	return response;
};

const updateApplicationTaskStatus = async (data: any) => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationTaskStatus`,
		data,
	);
	return response;
};

// 영업점 등록자 호출
const getSaleStoreMemberList = async (data: number) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`Commgmt/Search/getSaleStoreMemberList${param}`,
	);
	return response;
};

// 파일 업로드
const fileUpload = async (data: any) => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationAttachAndMemo`,
		data,
	);
	return response;
};
// 파일 다운로드
const fileDownload = async (data: any) => {
	const response = await instanceAuth.get(
		`/download/file/${data.attachType}/${data.pId}/${data.attachId}`,
	);
	return response;
};

// 파일 리스트 조회
const getApplicationAttachList = async (data: any) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/ApplicationManagement/ApplicationMgmt/getApplicationAttachList${param}`,
	);
	return response;
};

// 하위 영업점 + 내 관리점
const getSaleStoreList = async (data: any) => {
	const response = await instance.get(`Commgmt/Search/getSaleStoreList`, data);
	return response;
};

// 영업점 선택에 따른 개통점 리스트 전달
const getOpeningStoreList = async (data: any) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`Commgmt/Search/getOpeningStoreList${param}`,
	);
	return response;
};

// 상품 빈도수 높은 기기목록조회
const getMostGoodsSelectList = async (data: any) => {
	const response = await instance.get(
		`Wms/Search/getMostGoodsSelectList/${data}`,
	);
	return response;
};

// 상품 빈도수 높은 요금제 목록조회
const getTelecomMostChargeList = async (data: any) => {
	const response = await instance.get(
		`Commgmt/Search/getTelecomMostChargeList/${data}`,
	);
	return response;
};

// 중복 접수 신청서 여부 CHK
const checkDuplicationApplication = async (data: any) => {
	const response = await instanceNS.post(
		`/ApplicationManagement/ApplicationMgmt/checkDuplicationApplication`,
		data,
	);
	return response;
};

// 신청서 템플릿 목록조회
const getApplicationTemplateList = async (data: string) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getApplicationTemplateList${param}`,
	);
	return response;
};

// 신청서 템플릿 등록
const insertApplicationTemplate = async (data: any) => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/insertApplicationTemplate`,
		data,
	);
	return response;
};

// 신청서 템플릿 수정
const updateApplicationTemplate = async (data: any) => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationTemplate`,
		data,
	);
	return response;
};

// 신청서 템플릿 삭제
const deleteApplicationTemplate = async (data: any) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/deleteApplicationTemplate${param}`,
	);
	return response;
};
const getSelOrgnztList = async (data: any) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/OrganizationManagement/OrganizationMgmt/getSelOrgnztList${param}`,
	);
	return response;
};

const getApplicationAlarm = async (data: any) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getApplicationAlarm${param}`,
	);
	return response;
};

const updateApplicationAlarm = async (data: any) => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationAlarm`,
		data,
	);
	return response;
};

// 만료일 초과 신청서 존재시 메세지 발송
const sendMessageDeadLineApplication = async () => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/sendMessageDeadLineApplication`,
	);
	return response;
};

// 바코드로 영업점 이동 된 기기/유심 조회
const cwsCheckMoveDeviceFromBarcode = async (data: any) => {
	const response = await instance.post(
		`/Wms/Search/cwsCheckMoveDeviceFromBarcode`,
		data,
	);
	return response;
};

const getApplicationCreditDetail = async (data: any) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/applicationCreditDetail${param}`,
	);
	return response;
};


export {
	insertData,
	fileUpload,
	getSaleStoreList,
	getOpeningStoreList,
	updateApplication,
	getSaleStoreMemberList,
	getApplicationAttachList,
	deleteApplicationAttachAndMemo,
	updateApplicationTaskStatus,
	getApplicationDetail,
	fileDownload,
	getMostGoodsSelectList,
	getTelecomMostChargeList,
	checkDuplicationApplication,
	getApplicationTemplateList,
	insertApplicationTemplate,
	deleteApplicationTemplate,
	updateApplicationTemplate,
	getSelOrgnztList,
	getApplicationAlarm,
	updateApplicationAlarm,
	sendMessageDeadLineApplication,
	cwsCheckMoveDeviceFromBarcode,
	getApplicationCreditDetail,
};
