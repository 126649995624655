import Vue from 'vue';
import Vuex from 'vuex';
import {
	getNotiList,
	getNotiSettingDetail,
	getNotiCategoryList,
	getGroupNoReadCount,
	getNotiRnbCnt,
	insertNotiSetting,
	updateNotiReceiver,
	hideNotiReceiver,
} from '../../api/common/rnb/notice';

Vue.use(Vuex);

const NoticeModule = {
	namespaced: true,
	state: {
		activeRnb: false,
		activeRnbIndex: 0,
		notiCntList: [],
		notiPageListCnt: { totalCnt: 0, perPageCnt: 0, pageNo: 0 },
		notiRnbCnt: 0,
		notiRnbList: [],
		notiRnbResultList: [],
		notiPopCnt: 0,
		notiPopList: [],
		/*notice click시 열리는 팝업 관리*/
		complainInfoPop: false,
		salesConsultPop: false,
		salesApplPop: false,
	},
	mutations: {
		setNotiRnbCnt(state, result) {
			state.notiRnbCnt = result;
		},
		setNotiRnbList(state, result) {
			state.notiRnbList = result.data.resultList;
		},
		setNotiRnbItemToRead(state, notiId) {
			if (state.notiRnbList.find(item => item.notiId === notiId)) {
				state.notiRnbList.find(item => item.notiId === notiId).readYn = 'Y';
			}
		},
		initNotiRnbCnt(state) {
			state.notiRnbCnt = 0;
		},
		initNotiRnbList(state) {
			state.notiRnbList = [];
		},
		togglePopup(state, dialogName) {
			if (dialogName === 'complainInfoPop') {
				state.complainInfoPop = !state.complainInfoPop;
			}
		},
		setNotiPageListCnt(state, result) {
			state.notiPageListCnt.totalCnt = result.data.totalCnt;
			state.notiPageListCnt.perPageCnt = result.data.perPageCnt;
			state.notiPageListCnt.pageNo = result.data.pageNo;
		},
		focusRnbByIdx(state, index) {
			let targetIdx = state.notiRnbResultList.findIndex(
				notice => notice.notiId === state.notiPopList[index].notiId,
			);
			let target;
			if (targetIdx >= 0) {
				state.activeRnb = true;
				target = document.getElementById(`notiRnbList${targetIdx}`).children[0]
					.classList;
				if (!target.contains('targetNotice')) {
					target.add('targetNotice');
					setTimeout(function () {
						target.remove('targetNotice');
					}, 1800);
				}
			} else {
				state.activeRnb = true;
				target = document.getElementById(`noticeNoticeUl`).children;
				let notiType = state.notiPopList[index].notiType;
				let emergencyChk =
					state.notiPopList[index].emergencyYn === 'Y' ? true : false;
				for (let i = 0; i < target.length; i++) {
					// 1. 읽기처리가 안되어있는것 / 2. 푸시 긴급유무와 목록의 긴급유무 비교 / 3. 푸시 타입과 목록 타입 비교[민원/공지...]
					if (
						!target[i].children[0].classList.contains('read') &&
						target[i].classList.contains('emergencyNoti') === emergencyChk &&
						target[i].children[0].children[0].classList.contains(notiType)
					) {
						target[i].children[0].classList.add('targetNotice');
						setTimeout(function () {
							target[i].children[0].classList.remove('targetNotice');
						}, 1800);
					}
				}
			}
			state.notiPopList.splice(index, 1);
		},
	},
	actions: {
		async getNotiList({ commit }, data) {
			try {
				const result = await getNotiList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNotiPageListCnt', result.data);
					return result.data.data;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getNotiSettingDetail({ commit }, data) {
			try {
				const result = await getNotiSettingDetail(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getNotiCategoryList({ commit }, data) {
			try {
				const result = await getNotiCategoryList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getGroupNoReadCount({ commit }, data) {
			try {
				const result = await getGroupNoReadCount(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getNotiRnbCnt({ commit }, data) {
			try {
				commit('initNotiRnbCnt');
				const result = await getNotiRnbCnt(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNotiRnbCnt', result.data.data);
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getNotiRnbList({ commit }, data) {
			try {
				const result = await getNotiList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNotiRnbList', result.data);
					return result.data.data;
				} else {
					commit('initNotiRnbList');
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertNotiSetting({ commit }, data) {
			try {
				const result = await insertNotiSetting(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateNotiReceiver({ commit }, data) {
			try {
				const result = await updateNotiReceiver(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async hideNotiReceiver({ commit }, data) {
			try {
				const result = await hideNotiReceiver(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default NoticeModule;
