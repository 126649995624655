import { render, staticRenderFns } from "./NoticePopupCollection.vue?vue&type=template&id=3e981812&scoped=true&"
import script from "./NoticePopupCollection.vue?vue&type=script&lang=js&"
export * from "./NoticePopupCollection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e981812",
  null
  
)

export default component.exports