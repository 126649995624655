<template>
	<div>
		<div class="textRight groupBtnCont lh28">
			<button
				:class="{ active_blue: tabValue === 'ALL' }"
				class="ml-5 disIN"
				@click="getMemoList('ALL')"
			>
				전체
			</button>
			<button
				:class="{ active_green: tabValue === 'GROUP' }"
				class="ml-5 disIN"
				@click="getMemoList('GROUP')"
			>
				그룹
			</button>
			<button
				:class="{ active_orange: tabValue === 'INDV' }"
				class="ml-5 disIN"
				@click="getMemoList('INDV')"
			>
				개인
			</button>
			<button
				:class="{ active_red: tabValue === 'CREDIT' }"
				class="ml-5 disIN"
				@click="getMemoList('CREDIT')"
			>
				신조
			</button>
		</div>
		<!--		<template v-if="memoType === 'consultMemo'">-->
		<!--			<div class="groupBtnCont lh20">-->
		<!--				<button-->
		<!--					:class="{ active_blue: tabValue === 'ALL' }"-->
		<!--					class="ml-3 disIN font-size-12"-->
		<!--					@click="getMemoList('ALL')"-->
		<!--				>-->
		<!--					전체(0)-->
		<!--				</button>-->
		<!--				<button-->
		<!--					:class="{ active_green: tabValue === 'GROUP' }"-->
		<!--					class="ml-3 disIN font-size-12"-->
		<!--					@click="getMemoList('GROUP')"-->
		<!--				>-->
		<!--					고객(0)-->
		<!--				</button>-->
		<!--				<button-->
		<!--					:class="{ active_orange: tabValue === 'INDV' }"-->
		<!--					class="ml-3 disIN font-size-12"-->
		<!--					@click="getMemoList('INDV')"-->
		<!--				>-->
		<!--					상담(0)-->
		<!--				</button>-->
		<!--			</div>-->
		<!--		</template>-->
	</div>
</template>

<script>
export default {
	name: 'memoTab',
	// props: {
	// 	memoType: { type: String, default: 'memo' },
	// },
	computed: {
		tabValue: {
			get() {
				// if (this.memoType === 'memo') {
				return this.$store.state.memoModule.tabValue;
				// } else if (this.memoType === 'consultMemo') {
				// 	return this.$store.state.ConsultMemoModule.tabValue;
				// }
			},
			set(newValue) {
				// if (this.memoType === 'memo') {
				this.$store.state.memoModule.tabValue = newValue;
				// } else if (this.memoType === 'consultMemo') {
				// 	this.$store.state.ConsultMemoModule.tabValue = newValue;
				// }
			},
		},
		applId() {
			return this.$store.state.memoModule.applId;
		},
	},
	methods: {
		async getMemoList(tab) {
			this.tabValue = tab;
			let data = {};
			// if (this.memoType === 'memo') {
			data.applId = this.applId;
			data.category = this.tabValue;
			// }
			await this.getList(data);
		},
		async getList(data) {
			// if (this.memoType === 'memo') {
			await this.$store.dispatch('memoModule/getMemoList', data);
			// } else if (this.memoType === 'consultMemo') {
			// 	console.log(`get 상담의 ${this.tabValue}메모`);
			// }
		},
		currentTabInit() {
			this.tabValue = 'ALL';
		},
	},
	created() {
		this.currentTabInit();
	},
};
</script>
<style scoped>
.groupBtnCont button {
	font-weight: 600;
	text-decoration: underline;
}
.groupBtnCont button:focus {
	outline: none;
}
.active_blue {
	color: #007ef2;
}
.active_green {
	color: #00aac1;
}
.active_orange {
	color: #ff7a00;
}
.active_red {
	color: red;
}
</style>
