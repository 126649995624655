<template>
	<div class="padW30">
		<div
			class="device_table_style1 mt15 narrowScroll posR"
			style="max-height: 500px !important; height: auto"
		>
			<table class="w100P">
				<tr
					v-if="codeList.chargeReductTypeItemList.length === 0"
					style="cursor: default"
				>
					<td class="txAnC" colspan="20">데이터가 없습니다.</td>
				</tr>
				<tr
					class="cur_p"
					:class="{ backColorBlue6: item.value === chargeReductType }"
					v-else
					v-for="(item, index) in codeList.chargeReductTypeItemList"
					:key="index"
					@click="reductSelectFnc(item)"
				>
					<td class="mainBlack">{{ item.name }}</td>
				</tr>
			</table>
		</div>
		<div class="disFx justify-end mt10 mb20">
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReductSelect',
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.discountDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.discountDialog = newValue;
			},
		},
	},
	data: () => ({
		chargeReductType: null,
	}),
	methods: {
		reductSelectFnc(item) {
			this.chargeReductType = item.value;
			this.insertFnc(this.chargeReductType);
		},
		insertFnc(item) {
			if (!item) {
				return alert('요금감면을 선택해 주세요.');
			}
			this.formData.chargeReductType = item;
			this.dialog = false;
		},
	},
	created() {
		this.chargeReductType = this.formData.chargeReductType;
	},
};
</script>

<style scoped></style>
