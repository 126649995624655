import bus from './bus';

//신청서 구독 Start
const applSub = (applId) => {
	let data = {
		desti: '/subscribe/application/main/',
		applId: applId,
		wsType: 'APPLICATION_MAIN',
	}
	bus.$emit('appl:socket', data);
}
const applRecipSub = (applId) => {
	let data = {
		desti: '/subscribe/application/recip/',
		applId: applId,
		wsType: 'APPLICATION_RECIP',
	}
	bus.$emit('appl:socket', data);
}
const applRightSub = (applId) => {
	let data = {
		desti: '/subscribe/application/right/',
		applId: applId,
		wsType: 'APPLICATION_RIGHT',
	}
	bus.$emit('appl:socket', data);
}
//신청서 구독 End

//신청서 구독 취소 Start
const applUnSub = (desti, applId) => {
	let data = {
		desti: desti,
		applId: applId,
	}
	bus.$emit('unsubAppl:socket', data);
}
//신청서 구독 취소 End

// 메세지 전달 Start (신청서의 경우 새로고침 메세지 전달)
const sendReload = (targetType) => {
	let desti = ''
	if (targetType === 'appl') {
		desti = '/subscribe/application/main/'
	} else if (targetType === 'recip') {
		desti = '/subscribe/application/recip/'
	} else if (targetType === 'right') {
		desti = '/subscribe/application/right/'
	}
	bus.$emit('send:socket', desti);
}
// 메세지 전달 End(신청서의 경우 새로고침 메세지 전달)

//Noti 구독 취소 Start
const notiUnSub = () => {
	bus.$emit('unsubNoti:socket');
}
//신청서 구독 취소 End

// 소켓 목록 조회 Start
const socketCheck = () => {
	bus.$emit('check:socket');
}
//소켓 목록 조회 End
export {
	applSub,
	applRecipSub,
	applRightSub,
	applUnSub,
	sendReload,
	notiUnSub,
	socketCheck,
};
