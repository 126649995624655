import {instance, instanceNS} from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '';

// 기기 선택시 기기 정보(사양, 이미지 등) 전달
const retrieveGoodsSpecInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instanceNS.get(
		`/Wms/Search/retrieveGoodsSpecInfo${param}`,
	);
	return response;
};
// 기기 + 요금 선택시 공시지원금 정보 전달
const getPubNotiInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`/Commgmt/Search/getPubNotiInfo${param}`);
	return response;
};

const getCardList = async data => {
	if (data.param) data.param = jsonToQueryString(data.param);
	const response = await instance.get(
		`/CardManagement/CardMgmt/getList/${data.cardType}${data.param}`,
	);
	return response;
};

/*// 판매정책 등록
const insertConsultPolicy = async data => {
	const response = await instance.post(
		`/SalesManagement/ConsultMgmt/insertConsultPolicy`,
		data,
	);
	return response;
};*/

export { retrieveGoodsSpecInfo, getPubNotiInfo, getCardList };
