import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/SalesManagement/ConsultMgmt/';

// 상담메모 조회
const consultMemoList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultMemoList${param}`);
	return response;
};

// 상담이력 조회
const consultHistoryList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultHistoryList${param}`);
	return response;
};

// 상담메모 등록
const insertConsultMemo = async data => {
	const response = await instance.post(`${domain}insertConsultMemo`, data);
	return response;
};

// 상담메모 수정
const updateConsultMemo = async data => {
	const response = await instance.post(`${domain}updateConsultMemo`, data);
	return response;
};

// 상담상세 조회
const consultDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}consultDetail${param}`);
	return response;
};

// 상담상태 조회
const consultStatusList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/SalesManagement/ConsultStatusMgmt/consultStatusList${param}`,
	);
	return response;
};

// 상담상태 등록
const insertConsultStatus = async data => {
	const response = await instance.post(
		`/SalesManagement/ConsultStatusMgmt/insertConsultStatus`,
		data,
	);
	return response;
};

// 상담을 통한 신청서 등록
const insertCustomer = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/insertCustomer`,
		data);
	return response;
};

// 신청서 수정후 상담DB 수정
const updateCustomer = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/updateCustomer`,
		data);
	return response;
};

// 신청서 수정후 상담DB 수정
const consultCreditDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/SalesManagement/ConsultMgmt/consultCreditDetail${param}`,
	);
	return response;
};



export {
	consultMemoList,
	consultHistoryList,
	insertConsultMemo,
	insertConsultStatus,
	updateConsultMemo,
	consultDetail,
	consultStatusList,
	insertCustomer,
	updateCustomer,
	consultCreditDetail,
};
