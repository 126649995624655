import { render, staticRenderFns } from "./GoodsSelect.vue?vue&type=template&id=230ba635&scoped=true&"
import script from "./GoodsSelect.vue?vue&type=script&lang=js&"
export * from "./GoodsSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230ba635",
  null
  
)

export default component.exports