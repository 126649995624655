import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {
	appCommonFormData,
	codeListData,
	dialogData,
	memoFormData,
} from '../../../interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import {
	commonCodeEnumList,
	commonCodeList,
	getCapacityList,
	getDeviceColorList,
	getGoodsSelectList,
	getPubNotiInfo,
	getTelecomAddServiceList,
	getTelecomChargeList,
	retrieveMemberListByRole,
} from '../../../../api/common/common.js';
import {
	deleteApplicationAttachAndMemo,
	fileUpload,
	getApplicationAttachList,
	getApplicationDetail,
	getOpeningStoreList,
	getSaleStoreList,
	getSaleStoreMemberList,
	insertData,
	updateApplication,
	updateApplicationTaskStatus,
	checkDuplicationApplication,
	getMostGoodsSelectList,
	getTelecomMostChargeList,
	getApplicationTemplateList,
	insertApplicationTemplate,
	updateApplicationTemplate,
	deleteApplicationTemplate,
	getSelOrgnztList,
	sendMessageDeadLineApplication,
	cwsCheckMoveDeviceFromBarcode,
	getApplicationCreditDetail,
} from '../../../../api/supply-chain-mgmt/application-form-creation/ApplicationFromApi';
import { getBlackDetailList } from '../../../../api/supply-chain-mgmt/black-list/BlackListApi';
import { copyObj } from '@/common/common.js';
import { getFromCookie } from '@/common/cookies.js';

Vue.use(Vuex);

interface State {
	formData: appCommonFormData; // 신청서데이터
	memoData: memoFormData;
	codeList: codeListData; // select Items
	openingStoreDialog: boolean; // 개통점 dialog
	customerDialog: boolean; // 고객정보 dialog
	dialogData: dialogData; // dialog Array
	openingStoreTelecom: number | null; // 개통점통신사
	AppFormCreFlag: undefined | number; // 신청서 구별 분기
	ApplExchangeFlag: any; // 교품신청서 구별 분기
	updateFormData: any; // 저장소 데이터 (변경값만 보내기위함)
	blackList: string;
	thriftyPhone: number;
	paymentCheckType: boolean;
	addServiceFormData: any;
	deviceChange: boolean;
	usimChange: boolean;
	AppFlag: any;
	crData: any;
	crListFlag: boolean;
	crCompleteFlag: boolean;
	ms: any;
	tabDialog: any;
	receiveChk: any;
	applTabType: string;
	excutionTabFlag: boolean;
	applCounselor: any;
	templateDialog: boolean;
	returnDialog: boolean;
	excFauDialog: boolean;
	checkMoveDeviceDialog: boolean;
	templateSeq: any;
	templateTitle: any;
	workStatusData: any;
	matchingDeviceType: any;
	matchingUsimType: any;
	consultSwitchType: boolean;
	delChargePayInfo: any;
}

const state: State = {
	ms: getFromCookie('ms') || null,
	tabDialog: {
		applTab: true,
		receiveTab: false,
		executionTab: false,
	},
	excutionTabFlag: false, // 권리실행 분기추가
	templateDialog: false,
	returnDialog: false,
	excFauDialog: false,
	checkMoveDeviceDialog: false, // 신청서등록시 매칭 유무 모달
	matchingDeviceType: null,
	matchingUsimType: null,
	crCompleteFlag: false,
	crListFlag: false,
	crData: [],
	AppFlag: null,
	paymentCheckType: false,
	thriftyPhone: 0,
	AppFormCreFlag: undefined,
	ApplExchangeFlag: false,
	openingStoreTelecom: 0,
	customerDialog: false,
	openingStoreDialog: false,
	deviceChange: false,
	usimChange: false,
	blackList: '',
	dialogData: {
		paymentAddrDialog: false,
		deliveryAddrDialog: false,
	},
	addServiceFormData: [],
	updateFormData: {},
	applTabType: '',
	receiveChk: false,
	formData: {
		complainCnt: 0,
		basic: {
			regPathTypeMsg: null,
			creditInquireYn: null,
			creditInquireId: null,
			applType: 'DVC_USIM', // 기기유심타입
			beforeReserveYn: 'N', //사전예약여부
			reserveNum: '', //예약번호
			openingDate: '', // 개통일자
			openingTime: '', // 개통시간
			cancelDate: '', // 철회일자
			cancelTime: '', // 철회시간
			exchangeDate: '', // 교품일자
			exchangeTime: '00:00', // 교품시간
			beforeOpeningType: null, // 선개통유무
			saleStoreId: 0, // 영업점
			openingStoreId: {
				openStoreCode: 0,
				openStoreTelecom: '',
				openStoreTelecomId: 0,
				parentHierarchy: '',
				parentSaleStoreId: 0,
			}, // 개통점
			applRegiUserId: null, // 신청서등록자
			applRegiUserIdString: null, // 신청서등록자이름
			applRegiUserOrgId: null, // 신청서등록자 조직
			applRegiOrgId: null, // 신청서 조직
			applRegiOrgIdString: null, // 신청서 조직이름
			consultTaskStatus: null, // 상담업무상태
			openingTaskStatus: null, // 개통업무상태
			logisticsTaskStatus: null, // 물류업무상태
			delYn: null, // 삭제여부
			docTargetYn: 'N', // 서류대상여부
			parentApplId: null, // 부모신청서아이디
			childApplId: null, // 자식신청서아이디
			priorityTargetYn: 'N', // 우선대상여부
			regiDatetime: null, // 등록일시
			/*regiUserId: null, // 등록유저아이디*/
			updDateTime: null, //수정일시
			/*updUserId: null, // 수정유저아이디*/
			parentHierarchy: null, // 상위계층
			parentSaleStoreId: null, // 상위 영업 관리점 아이디
			returnStoreType: null, // 반품 도착지
			retractYn: null, // 철회 여부
			faultYn: null, // 불량 취소 여부
			faultyReasonType: null, // 불량 취소 사유 타입
			faultReasonDesc: null, // 불량 취소 사유 메모
			forceUpdateLogisticsTaskStatus: null, // 물류상태 강제업데이트 필요시 (22.05.30 택배가 영업점 발송인경우 물류상태 -> 반품완료(영업점) 처리를 하기위해 _ 대마Only)
		},
		customer: {
			cusName: null, // 고객명
			cusRegiNumConcat: null, // 고객주민등록번호
			cusRegiNum1: null, // 고객주민등록번호
			cusRegiNum2: null, // 고객주민등록번호
			chargeReductType: null, // 요금감면
			cusPhone: null, // 휴대폰번호
			cusPhone1: null, // 휴대폰번호
			cusPhone2: null, // 휴대폰번호
			cusPhone3: null, // 휴대폰번호
			emgPhone: null, // 비상연락망
			emgPhone1: null, // 비상연락망
			emgPhone2: null, // 비상연락망
			emgPhone3: null, // 비상연락망
			cusEmail: null, // 이메일
			cusAddr: null, // 고객기본주소
			cusAddrDetail: null, // 고객상세주소
			cusZipCode: null, // 고객우편번호
			cusType: null, // 고객유형
			courtProctorName: null, // 법정대리인명
			courtProctorRegiNumConcat: null, // 법정대리인주민등록번호
			courtProctorRegiNum1: null, // 법정대리인주민등록번호
			courtProctorRegiNum2: null, // 법정대리인주민등록번호
			courtProctorPhone: null, // 법정대리인연락처
			courtProctorPhone1: null, // 법정대리인연락처
			courtProctorPhone2: null, // 법정대리인연락처
			courtProctorPhone3: null, // 법정대리인연락처
			courtProctorRelation: null, // 법정대리인관계
			bizName: null, // 사업자명
			bizNum1: null, // 사업자번호
			bizNum2: null, // 사업자번호
			bizNum3: null, // 사업자번호
			bizPhone: null, // 사업자연락처
			bizPhone1: null, // 사업자연락처
			bizPhone2: null, // 사업자연락처
			bizPhone3: null, // 사업자연락처
			bizRegiNumConcat: null, // 법인등록번호
			bizRegiNum1: null, // 법인등록번호
			bizRegiNum2: null, // 법인등록번호
			licenseAuthType: null, // 신분증진위
			licenseIssueDate: null, // 발급일자
			licenseExpiredDate: null, // 만료일자
			licenseIssueArea: null, // 만료일자
			licenseNum1: null, // 면허번호
			licenseNum2: null, // 면허번호
			licenseNum3: null, // 면허번호
			stayCode: null, // 체류코드
			ntnlCode: null, // 국가
		},
		payment: {
			paymentIdentifiedYn: 'Y',
			paymentType: null, // 납부방법
			billType: null, // 청구서종류
			bankCode: null, // 은행정보
			accountNum: null, // 계좌번호
			accountHolderName: null, // 예금주
			accountHolderBirth: null, // 생년월일
			accountHolderRelation: null, // 관계
			cardCompanyCode: null, // 카드정보
			cardNum1: null, // 카드번호
			cardNum2: null, // 카드번호
			cardNum3: null, // 카드번호
			cardNum4: null, // 카드번호
			cardHolderName: null, // 카드주
			cardHolderRegiNum1: null, // 주민등록번호
			cardHolderRegiNum2: null, // 주민등록번호
			cardValidityMm: null, // 유효기간MM
			cardValidityYy: null, // 유효기간YY
			giroZipCode: null, // 우편번호
			giroAddr: null, // 기본주소
			giroAddrDetail: null, // 상세주소
		},
		join: {
			openingPhone: null, // 개통 휴대폰번호
			openingPhone1: null, // 개통 휴대폰번호
			openingPhone2: null, // 개통 휴대폰번호
			openingPhone3: null, // 개통 휴대폰번호
			openingHopeNum: null, // 개통희망번호
			openingTelecomCodeId: null, // 개통통신사
			openingTelecomName: null, // 개통통신사
			existTelecomCodeId: null, // 기존 통신사
			joinType: null, // 가입유형
			numChangeAuthType: null, // 인증방식
			numChangeAuthTypeNum: null, // 인증번호
			goodsId: null, // 기기명
			dvcId: null, // 기기명
			usimDvcId: null, // 기기명
			capacity: null, // 용량
			color: null, // 색상
			modelName: null, // 모델네임
			deviceRawBarcode: null, // 기기일련번호
			usimPaymentType: null, // 유심
			usimType: 'NANO_USIM', // 유심
			usimRawBarcode: null, // 유심일련번호
			agreementType: 'CHOICE_AGRMN', // 약정유형
			agreementPeriodType: null, // 약정기간
			instlPeriodType: null, // 할부기간
			chargeId: null, // 요금제
			addServiceList: [], // 부가서비스리스트
			releaseAmt: 0, // 출고가
			releaseAmtDate: null, // 출고가일자
			pubNotiSupportAmt: 0, // 공시지원금
			extraSupportAmt: 0, // 추가지원금
			freeInstlAmt: 0, // 프리할부/선수납
			etcDiscountAmt: 0, // 기타할인금
			actualSellAmt: 0, // 실판매가
			instlPrincipalAmt: 0, // 실판매가
			monthDvcAmt: 0, // 월기기금액
			monthBasicAmt: 0, // 월기본료
			monthPaymentAmt: 0, // 월납부금액
			depositAmt: 0, // 입금받을금액
			depositYn: 'N', // 입금여부
			pubNotiId: 0, // 공시알림아이디
			deviceMatchingType: null, // 기기 매칭타입
			usimMatchingType: null, // 유심 매칭타입
			openingDate: null, // 개통일자
			cancelDate: null, // 철회일자
			networkId: null, // 통신망
			//c4
			cardDiscountInstallmentType: null,
			cardDiscountLumpSumType: null,
			//c4
		},
		delivery: {
			deliveryType: null, // 배송구분
			courierCodeId: null, // 택배사
			invoiceNum: null, // 송장번호
			deliveryZipCode: null, // 우편번호
			deliveryAddr: null, // 기본주소
			deliveryAddrDetail: null, // 상세주소
			couriorMatchingYn: null, // 운송장매칭여부
			deliveryCusName: null, // 실수령인
			deliveryCusPhone: null, // 실수령인 연락처
			deliveryCusPhone1: null, // 실수령인 연락처
			deliveryCusPhone2: null, // 실수령인 연락처
			deliveryCusPhone3: null, // 실수령인 연락처
		},
		etc: {
			giftEnclosedYn: null, // 사은품동봉여부
			giftList: [
				{
					giftName: null,
				},
			], // 사은품
			usedDvcReclaimYn: null, // 중고폰회수여부
			usedDvcModelName: null, // 중고폰모델명
			usedDvcCompe: null, // 중고보상
			attachList: [], // 파일추가
		},
		cnsltId: null,
		consultList: [
			{ consultUserId: null },
			{ consultUserId: null },
			{ consultUserId: null },
		],
	},
	memoData: {
		category: '', // 메모 카테고리
		memoContents: null, // 메모내용
	},
	codeList: {
		applTypeItems: [], // 기기유심
		chargeReductTypeItems: [], // 요금감면
		customerTypeItems: [], // 고객유형
		licenseAuthTypeItems: [], // 신분증진위
		stayCodeItems: [], // 체류코드
		driverAreaCodeItems: [], // 운전면허지역코드 (앞자리)
		billTypeItems: [], // 청구서종류
		bankCodeItems: [], // 은행정보
		cardCompanyCodeItems: [], // 카드정보
		joinTypeItems: [], // 가입유형
		numChangeAuthTypeItems: [], // 인증방식
		usimPaymentTypeItems: [], // 유심방식
		usimPaymentTypeItemsCopy: [], // 유심방식 복사본
		agreementPeriodTypeItems: [], // 약정기간
		installmentsPeriodTypeItems: [], // 할부기간
		courierCodeItems: [], // 택배사코드
		goodsItems: [], // 기기명
		goodsItemsCopy: [], // 기기명
		capacityItems: [], // 용량
		colorItems: [], // 색상
		saleStoreItems: [], // 영업점
		openingStoreItems: [], // 개통점
		telecomAddServiceItems: [], // 부가서비스
		telecomChargeItems: [], // 요금제
		telecomChargeItemsCopy: [], // 요금제
		existTelecomItems: [], // 기존 통신사
		thriftyPhoneItems: [], // 알뜰폰리스트
		storeMemberItems: [], // 등록자
		pubNotiInfoItems: [], // 요금제 조회
		nationalCodeItems: [], // 국가코드
		ConsultTaskStatusList: [], // 업무진행상태
		OpeningTaskStatusList: [], // 업무진행상태
		LogisticsTaskStatusList: [], // 업무진행상태
		detailList: [], // 블랙리스트
		counselorItems: [], // 상담원
		counselorItemsAll: [], // 상담원(삭제회원포함)
		DeliveryType: [], // 상담원
		networkCodeItems: [], // 통신망
		mostGoodsItems: [], // 인기기기명
		mostChargeItems: [], // 인기요금제
		rightGoodsItems: [], // 권리기기
		applicationTemplateList: [], // 신청서 템플릿 목록
		selOrganizationList: [], // 영업점 조직 목록
	},
	applCounselor: null, // ref 등록자
	templateSeq: 0,
	templateTitle: '',
	workStatusData: null,
	consultSwitchType: false,
	delChargePayInfo: null,
};

const getters = {};

const mutations = {
	setCommonCodeList(state: State, result: any) {
		const exTel = { codeNm: '알뜰폰', codeSeq: 1 };
		state.codeList.courierCodeItems = [];
		state.codeList.existTelecomItems = [];
		state.codeList.thriftyPhoneItems = [];
		state.codeList.courierCodeItems = result.codeList.COURIER || [];
		state.codeList.networkCodeItems = result.codeList.NETWORK || [];
		state.codeList.existTelecomItems =
			result.codeList.EXISTELECOM.splice(0, 4) || [];
		state.codeList.thriftyPhoneItems = result.codeList.EXISTELECOM;
		state.codeList.existTelecomItems.push(exTel);
	},
	setCommonCodeNewList(state: State, result: any) {
		state.codeList.applTypeItems = result.ApplType || []; // 기기유심 상태
		state.codeList.chargeReductTypeItems = result.ChargeReductType || []; // 요금감면
		state.codeList.customerTypeItems = result.CustomerType || []; // 고객유형
		state.codeList.licenseAuthTypeItems = result.LicenseAuthType || []; // 신분증진위
		state.codeList.stayCodeItems = result.StayCode || []; // 체류코드
		state.codeList.driverAreaCodeItems = result.DriverAreaCode || []; // 운전면허지역코드
		state.codeList.billTypeItems = result.BillType || []; // 청구서종류
		state.codeList.bankCodeItems = result.BankCode || []; // 은행정보
		state.codeList.cardCompanyCodeItems = result.CardCompanyCode || []; //카드정보
		state.codeList.joinTypeItems = result.JoinType || []; // 가입유형
		state.codeList.numChangeAuthTypeItems = result.NumChangeAuthType || []; // 인증방식
		state.codeList.usimPaymentTypeItems = result.UsimPaymentType || []; // 유심
		state.codeList.usimPaymentTypeItemsCopy = result.UsimPaymentType || []; // 유심
		result.AgreementPeriodType = result.AgreementPeriodType.filter(
            (obj: { value: string; }) => obj.value === 'MONTH_12' || obj.value === 'MONTH_24',
		);
		state.codeList.agreementPeriodTypeItems = result.AgreementPeriodType || []; // 약정기간
		state.codeList.installmentsPeriodTypeItems =
			result.InstallmentsPeriodType || []; // 할부기간
		state.codeList.nationalCodeItems = result.NationalCode || []; // 국가코드
		state.codeList.ConsultTaskStatusList = result.ConsultTaskStatus || [];
		state.codeList.OpeningTaskStatusList = result.OpeningTaskStatus || [];
		state.codeList.LogisticsTaskStatusList = result.LogisticsTaskStatus || [];
		if (result.DeliveryType && result.DeliveryType.length != 0) {
			result.DeliveryType.splice(0, 1);
			state.codeList.DeliveryType = result.DeliveryType || [];
		}
	},
	setGoodsSelectList(state: State, result: any) {
		state.codeList.goodsItems = [];
		state.codeList.goodsItemsCopy = [];
		state.codeList.rightGoodsItems = [];
		state.codeList.rightGoodsItems = result.data.data;
		// 모델명 추가
		// 받아온 기기의 useYn === 'Y' 이거나
		// useYn === 'N' 이지만 기존에 저장된 goodsId

		const goodsItems = [];
		for (let i = 0; i < result.data.data.length; i++) {
			if (
				result.data.data[i].useYn === 'Y' ||
				result.data.data[i].goodsId === state.formData.join.goodsId
			) {
				result.data.data[
					i
				].goodsName = `${result.data.data[i].goodsName} [${result.data.data[i].modelName}]`;
				goodsItems.push(result.data.data[i]);
			}
		}
		state.codeList.goodsItems = goodsItems;
		state.codeList.goodsItemsCopy = goodsItems;
	},
	setCapacitySelectList(state: State, result: any) {
		state.codeList.capacityItems = [];
		// 용량 중복 제거
		state.codeList.capacityItems = result.data.data;
	},
	setColorSelectList(state: State, result: any) {
		state.codeList.colorItems = [];
		state.codeList.colorItems = result.data.data;
	},
	setSaleStoreList(state: State, result: any) {
		state.codeList.saleStoreItems = [];
		state.codeList.saleStoreItems = result.data.data;
	},
	setOpeningStoreList(state: State, result: any) {
		state.codeList.openingStoreItems = [];
		state.codeList.openingStoreItems = result.data.data;
		if (!state.AppFlag) {
			if (
				!state.crData.creditInquireYn &&
				result.data.data &&
				result.data.data.length > 0
			) {
				state.formData.basic.openingStoreId = {
					openStoreCode: result.data.data[0].openStoreId,
					openStoreTelecom: result.data.data[0].telecomName,
					openStoreTelecomId: result.data.data[0].telecom,
					parentHierarchy: result.data.data[0].parentHierarchy,
					parentSaleStoreId: result.data.data[0].parentSaleStoreId,
				};
				state.formData.join.openingTelecomName =
					result.data.data[0].telecomName;
			}
			/* else if(state.templateSeq !== 0) {
				let searchOS = result.data.data.filter(
					// @ts-ignore
					i => i.openStoreId === state.formData.basic.openingStoreId
				)
				state.formData.basic.openingStoreId = {
					openStoreCode: searchOS[0].openStoreId,
					openStoreTelecom: searchOS[0].telecomName,
					openStoreTelecomId: searchOS[0].telecom,
					parentHierarchy: searchOS[0].parentHierarchy,
					parentSaleStoreId: searchOS[0].parentSaleStoreId,
				};
			}*/
		}

		if (state.formData.basic.applId) {
			// 삭제된 개통점이 있는경우 강제로 개통점을 추가
			// @ts-ignore
			const findOpenStore = state.codeList.openingStoreItems.find(
				(item: any) => item.openStoreId === state.formData.basic.openingStoreId,
			);
			if (!findOpenStore) {
				const setOpenStore = {
					// @ts-ignore
					openStoreName: state.formData.basic.openingStoreIdString,
					openStoreId: state.formData.basic.openingStoreId,
					telecomName: state.formData.join.openingTelecomName,
					telecom: state.formData.join.openingTelecomCodeId,
					parentHierarchy: state.formData.basic.parentHierarchy,
					parentSaleStoreId: state.formData.basic.parentSaleStoreId,
					parentSaleStoreName: '',
				};
				// @ts-ignore
				state.codeList.openingStoreItems.push(setOpenStore);
			}
		}
	},
	setTelecomAddServiceList(state: State, result: any) {
		state.codeList.telecomAddServiceItems = [];
		state.codeList.telecomAddServiceItems = result.data.data;
	},
	setTelecomChargeList(state: State, result: any) {
		state.delChargePayInfo = null;
		state.codeList.telecomChargeItems = [];
		let telecomChargeItems = [];
		telecomChargeItems = result.data.data.filter(
			// @ts-ignore
			i => i.useYn === 'Y' || i.chargeId === state.formData.join.chargeId,
		);

		// @ts-ignore
		const findOpenStore = telecomChargeItems.find(
			(item: any) => item.openStoreId === state.formData.join.chargeId,
		);
		let setCharge = null;
		let delChargePayInfo = null;
		if (state.formData.join.chargeId && !findOpenStore) {
			setCharge = {
				chargeId: state.formData.join.chargeId,
				// @ts-ignore
				chargeName: state.formData.join.chargeIdString,
				network: state.formData.join.networkId,
				delYn: 'Y',
			};
			delChargePayInfo = {
				pubNotiId: state.formData.join.pubNotiId, // 공시알림아이디
				releaseAmt: state.formData.join.releaseAmt, // 출고가
				actualSellAmt: state.formData.join.actualSellAmt, // 실판매가
				releaseAmtDate: state.formData.join.releaseAmtDate, // 출고가일자
				monthBasicAmt: state.formData.join.monthBasicAmt, // 월기본료
				pubNotiSupportAmt: state.formData.join.pubNotiSupportAmt, // 공시지원금
				monthDvcAmt: state.formData.join.monthDvcAmt, // 월기기금액
				monthPaymentAmt: state.formData.join.monthPaymentAmt, // 월납부금액
				instlPrincipalAmt: state.formData.join.instlPrincipalAmt, // 할부원금
			};
			state.delChargePayInfo = delChargePayInfo;
			telecomChargeItems.push(setCharge);
		}

		state.codeList.telecomChargeItems = telecomChargeItems;
		state.codeList.telecomChargeItemsCopy = telecomChargeItems;
		// 받아온 요금제의 useYn === 'Y' 이거나
		// useYn === 'N' 이지만 기존에 저장된 chargeId
		/*
		state.codeList.telecomChargeItems = result.data.data.filter(
			// @ts-ignore
			i => i.useYn === 'Y' || i.chargeId === state.formData.join.chargeId,
		);
		state.codeList.telecomChargeItemsCopy = result.data.data.filter(
			// @ts-ignore
			i => i.useYn === 'Y' || i.chargeId === state.formData.join.chargeId,
		);
		 */
	},
	setSaleStoreMemberList(state: State, result: any) {
		state.codeList.storeMemberItems = [];
		state.codeList.counselorItemsAll = [];
		state.codeList.storeMemberItems = result.data.data;
		if (
			(state.crData.creditInquireYn || state.formData.basic.creditInquireId) &&
			result.data.data
		) {
			state.codeList.counselorItems = result.data.data.slice();
			state.codeList.counselorItems.unshift({
				seq: null,
				name: '선택해주세요.',
			});
		}

		if (!state.AppFlag && state.templateSeq === 0) {
			// 22.03.04 신조요청자 - 담당자 지정 제거 && !state.crData.creditInquireYn
			// 최초작성 // 신용조회
			/*console.log(state.applCounselor.value)
			state.formData.basic.applRegiUserId = Number(state.ms)
			if (state.applCounselor.value === Number(state.ms)) // 로그인정보와
				state.formData.basic.applRegiUserId = Number(state.ms);
			else
				state.formData.basic.applRegiUserId = '';*/

			state.ms = getFromCookie('ms');
			state.formData.basic.applRegiUserId = '';
			for (let i = 0; i < result.data.data.length; i++) {
				if (result.data.data[i].seq === Number(state.ms)) {
					state.formData.basic.applRegiUserId = result.data.data[i].seq;
					break;
				}
			}
		}

		if (result.data.data) {
			// 삭제된 사용자 추가처리 Start
			let findUser = false;
			for (const [key, value] of Object.entries(
				state.codeList.storeMemberItems,
			)) {
				if (value.seq === state.formData.basic.applRegiUserId) {
					findUser = true;
				}
			}
			if (!findUser && state.formData.basic.applId) {
				// @ts-ignore
				state.codeList.storeMemberItems.push({
					seq: state.formData.basic.applRegiUserId,
					name: state.formData.basic.applRegiUserIdString,
					orgId: state.formData.basic.applRegiUserOrgId,
				});
			}
			// 삭제된 사용자 추가처리 End

			if (
				state.crData.creditInquireYn ||
				state.formData.basic.creditInquireId
			) {
				state.codeList.counselorItemsAll = result.data.data.slice();
				state.codeList.counselorItemsAll.unshift({
					seq: null,
					name: '선택해주세요.',
				});
			}
		}
	},
	setPubNotiInfo(state: State, result: any) {
		state.formData.join.pubNotiId = result.data.data.pubNotiId;
		state.formData.join.releaseAmt = result.data.data.releaseAmt;
		state.formData.join.actualSellAmt = result.data.data.releaseAmt;
		state.formData.join.releaseAmtDate = result.data.data.releaseDate;
		state.formData.join.monthBasicAmt = result.data.data.chargeAmt;
		if (state.formData.join.agreementType === 'PUBNOTI_SUPAMT') {
			state.formData.join.actualSellAmt =
				result.data.data.releaseAmt - result.data.data.supportAmt;
		} else {
			state.formData.join.actualSellAmt = result.data.data.releaseAmt;
		}
		if (state.formData.join.agreementType === 'CHOICE_AGRMN') {
			state.formData.join.pubNotiSupportAmt = 0;
			state.formData.join.monthBasicAmt =
				state.formData.join.monthBasicAmt -
				state.formData.join.monthBasicAmt * 0.25;
		} else {
			state.formData.join.pubNotiSupportAmt = result.data.data.supportAmt;
		}
	},
	setBlackList(state: State, result: any) {
		if (result.data.resultCode === '0000') {
			state.blackList = result.data.data[0].blackTypeMsg;
		} else {
			state.blackList = result.data.resultMsg;
		}
	},
	setInsertList(state: State, result: any) {
		state.formData.basic.applId = result.data.data;
		state.formData.customer.applId = result.data.data;
		state.formData.payment.applId = result.data.data;
		state.formData.join.applId = result.data.data;
		state.formData.delivery.applId = result.data.data;
		state.formData.etc.applId = result.data.data;
	},
	setMemberListByRole(state: State, result: any) {
		if (
			!state.crData.creditInquireYn &&
			!state.formData.basic.creditInquireId
		) {
			state.codeList.counselorItems = [];
			state.codeList.counselorItems = result.data.data;
			if (result.data.data) {
				state.codeList.counselorItems.unshift({
					seq: null,
					name: '선택해주세요.',
				});
				if (!state.AppFlag && state.templateSeq === null) {
					state.formData.basic.applRegiUserId = result.data.data[1].seq;
				}
			}
		}
	},
	setApplicationDetail(state: State, result: any) {
		if (result.data.data.customer) {
			if (!result.data.data.customer.chargeReductType) {
				result.data.data.customer.chargeReductType = 'NONE';
			}
		}
		// 권리실행분기
		state.excutionTabFlag = false;
		if (
			result.data.data.join.joinType === 'DVC_CHANGE' ||
			result.data.data.join.joinType === 'TRADE_NEW'
		) {
			state.excutionTabFlag = true;
		}

		state.blackList = '';
		if (result.data.data.customer.bizNum !== null) {
			const biz = result.data.data.customer.bizNum;
			const biz1 = biz.substr(0, 3);
			const biz2 = biz.substr(3, 2);
			const biz3 = biz.substr(5, 5);
			result.data.data.customer.bizNum1 = biz1;
			result.data.data.customer.bizNum2 = biz2;
			result.data.data.customer.bizNum3 = biz3;
		}
		state.addServiceFormData = [];
		if (result.data.data.join.openingTelecomCodeId === 5) {
			result.data.data.join.openingTelecomName = 'SKT';
		}
		if (result.data.data.join.openingTelecomCodeId === 6) {
			result.data.data.join.openingTelecomName = 'KT';
		}
		if (result.data.data.join.openingTelecomCodeId === 7) {
			result.data.data.join.openingTelecomName = 'LGU';
		}
		state.formData.consultList = result.data.data.consultList;
		if (state.formData.consultList.length <= 3) {
			const cnt = 3 - state.formData.consultList.length;
			for (let i = 0; i < cnt; i++) {
				state.formData.consultList.push({
					applConsultId: 0,
					applId: 0,
					consultUserId: 0,
				});
			}
		}

		// if (result.data.data.consultList.length === 0) {
		// 	for (let i = 0; i < result.data.data.consultList.length; i++) {
		// 		result.data.data.consultList[i] = {
		// 			applConsultId: null,
		// 			applId: null,
		// 			consultUserId: null,
		// 		};
		// 	}
		// }
		// for (let i = 0; i < 3; i++) {
		// 	if (!result.data.data.consultList[i]) {
		// 		result.data.data.consultList[i] = {
		// 			applConsultId: 0,
		// 			applId: 0,
		// 			consultUserId: 0,
		// 		};
		// 	}
		// }
		if (
			result.data.data.join.existTelecomCodeId === 41 ||
			result.data.data.join.existTelecomCodeId > 45
		) {
			state.thriftyPhone = result.data.data.join.existTelecomCodeId;
			result.data.data.join.existTelecomCodeId = 1;
		}
		if (result.data.data.payment.paymentType === 'EXIST_SAME') {
			state.paymentCheckType = true;
		} else {
			state.paymentCheckType = false;
		}
		if (result.data.data.etc.giftList.length === 0) {
			result.data.data.etc.giftList.push({ giftName: null });
		}
		if (!result.data.data.basic.openingTaskStatus) {
			result.data.data.basic.openingTaskStatus = null;
		}
		if (!result.data.data.basic.logisticsTaskStatus) {
			result.data.data.basic.logisticsTaskStatus = null;
		}
		if (!result.data.data.basic.consultTaskStatus) {
			result.data.data.basic.consultTaskStatus = null;
		}
		state.updateFormData = copyObj(result.data.data);
		//state.updateFormData.join.addServiceList = [{consultUserId:0}];
		state.formData = result.data.data;
		// 교품관련 신청서인지 구분
		state.ApplExchangeFlag = false; // 초기화
		if (result.data.data.basic.parentApplId) {
			state.ApplExchangeFlag = true;
		}
		// 템플릿 데이터 조회 시_watch
		if (result.applTempId) {
			state.templateSeq = result.applTempId;
			state.templateTitle = result.templateTitle;
		}
	},
	setApplicationTemplateList(state: State, result: any) {
		state.codeList.applicationTemplateList = [];
		state.codeList.applicationTemplateList = result.data.data.resultList;
	},
	setMostGoodsSelectList(state: State, result: any) {
		result = result.filter((item: any) => item.useYn === 'Y');
		state.codeList.mostGoodsItems = [];
		state.codeList.mostGoodsItems = result;
		state.codeList.mostGoodsItems.reverse();
	},
	setTelecomMostChargeList(state: State, result: any) {
		result = result.filter((item: any) => item.useYn === 'Y');
		state.codeList.mostChargeItems = [];
		state.codeList.mostChargeItems = result;
		state.codeList.mostChargeItems.reverse();
	},
	setSelOrgnztList(state: State, result: any) {
		state.codeList.selOrganizationList = [];
		state.codeList.selOrganizationList = result.data.data.orgnztList;

		// 삭제된 조직 추가처리 Start
		const searchOrg = state.codeList.selOrganizationList.filter(
			(item: any) => item.orgId === state.formData.basic.applRegiOrgId,
		);

		if (
			searchOrg.length === 0 &&
			state.formData.basic.applRegiOrgId !== null &&
			state.formData.basic.applRegiOrgId !== undefined
		) {
			// @ts-ignore
			state.codeList.selOrganizationList.push({
				orgId: state.formData.basic.applRegiOrgId,
				orgName: state.formData.basic.applRegiOrgIdString,
			});
		}
		// 삭제된 조직 추가처리 End

		if (
			state.formData.basic.applRegiOrgId === null ||
			state.formData.basic.applRegiOrgId === undefined
		) {
			// 신청서 최초 작성시 사용자의 조직정보가 없을시 선택처리
			let findRegiUserObj = null;
			for (let [key, valueItems] of Object.entries(state.codeList.storeMemberItems)) {
				let checkItem = false;
				for (let [itemName, itemValue] of Object.entries(valueItems)) {
					if(itemName === 'seq' && itemValue === state.formData.basic.applRegiUserId) {
						checkItem = true;
						break;
					}
				}
				if(checkItem) {
					findRegiUserObj = valueItems;
					break;
				}
			}
			if(findRegiUserObj) {
				state.formData.basic.applRegiOrgId = findRegiUserObj.orgId;
			} else {
				state.formData.basic.applRegiOrgId =
					state.codeList.selOrganizationList[0].orgId;
			}
		}
	},
	formDataInit(state: State) {
		state.AppFormCreFlag = undefined;
		state.formData = {
			complainCnt: 0,
			basic: {
				regPathTypeMsg: null,
				creditInquireYn: null,
				creditInquireId: null,
				applType: 'DVC_USIM', // 기기유심타입
				beforeReserveYn: 'N', //사전예약여부
				reserveNum: '', //예약번호
				openingDate: '', // 개통일자
				openingTime: '', // 개통시간
				cancelDate: '', // 철회일자
				cancelTime: '', // 철회시간
				exchangeDate: '', // 교품일자
				exchangeTime: '00:00', // 교품시간
				beforeOpeningType: null, // 선개통유무
				saleStoreId: 0, // 영업점
				openingStoreId: {
					openStoreCode: 0,
					openStoreTelecom: '',
					openStoreTelecomId: 0,
					parentHierarchy: '',
					parentSaleStoreId: 0,
				}, // 개통점
				applRegiUserId: null, // 신청서등록자
				applRegiUserIdString: null, // 신청서등록자이름
				applRegiUserOrgId: null, // 신청서등록자 조직
				applRegiOrgId: null, // 신청서 조직
				applRegiOrgIdString: null, // 신청서 조직이름
				consultTaskStatus: null, // 상담업무상태
				openingTaskStatus: null, // 개통업무상태
				logisticsTaskStatus: null, // 물류업무상태
				delYn: null, // 삭제여부
				docTargetYn: 'N', // 서류대상여부
				parentApplId: null, // 부모신청서아이디
				childApplId: null, // 자식신청서아이디
				priorityTargetYn: 'N', // 우선대상여부
				regiDatetime: null, // 등록일시
				/*regiUserId: null, // 등록유저아이디*/
				updDateTime: null, //수정일시
				/*updUserId: null, // 수정유저아이디*/
				parentHierarchy: null, // 상위계층
				parentSaleStoreId: null, // 상위 영업 관리점 아이디
				returnStoreType: null, // 반품 도착지
				retractYn: null, // 철회 여부
				faultYn: null, // 불량 취소 여부
				faultyReasonType: null, // 불량 취소 사유 타입
				faultReasonDesc: null, // 불량 취소 사유 메모
				forceUpdateLogisticsTaskStatus: null, // 물류상태 강제업데이트 필요시 (22.05.30 택배가 영업점 발송인경우 물류상태 -> 반품완료(영업점) 처리를 하기위해 _ 대마Only)
			},
			customer: {
				cusName: null, // 고객명
				cusRegiNumConcat: null, // 고객주민등록번호
				cusRegiNum1: null, // 고객주민등록번호
				cusRegiNum2: null, // 고객주민등록번호
				chargeReductType: null, // 요금감면
				cusPhone: null, // 휴대폰번호
				cusPhone1: null, // 휴대폰번호
				cusPhone2: null, // 휴대폰번호
				cusPhone3: null, // 휴대폰번호
				emgPhone: null, // 비상연락망
				emgPhone1: null, // 비상연락망
				emgPhone2: null, // 비상연락망
				emgPhone3: null, // 비상연락망
				cusEmail: null, // 이메일
				cusAddr: null, // 고객기본주소
				cusAddrDetail: null, // 고객상세주소
				cusZipCode: null, // 고객우편번호
				cusType: null, // 고객유형
				courtProctorName: null, // 법정대리인명
				courtProctorRegiNumConcat: null, // 법정대리인주민등록번호
				courtProctorRegiNum1: null, // 법정대리인주민등록번호
				courtProctorRegiNum2: null, // 법정대리인주민등록번호
				courtProctorPhone: null, // 법정대리인연락처
				courtProctorPhone1: null, // 법정대리인연락처
				courtProctorPhone2: null, // 법정대리인연락처
				courtProctorPhone3: null, // 법정대리인연락처
				courtProctorRelation: null, // 법정대리인관계
				bizName: null, // 사업자명
				bizNum1: null, // 사업자번호
				bizNum2: null, // 사업자번호
				bizNum3: null, // 사업자번호
				bizPhone: null, // 사업자연락처
				bizPhone1: null, // 사업자연락처
				bizPhone2: null, // 사업자연락처
				bizPhone3: null, // 사업자연락처
				bizRegiNumConcat: null, // 법인등록번호
				bizRegiNum1: null, // 법인등록번호
				bizRegiNum2: null, // 법인등록번호
				licenseAuthType: null, // 신분증진위
				licenseIssueDate: null, // 발급일자
				licenseExpiredDate: null, // 만료일자
				licenseIssueArea: null, // 만료일자
				licenseNum1: null, // 면허번호
				licenseNum2: null, // 면허번호
				licenseNum3: null, // 면허번호
				stayCode: null, // 체류코드
				ntnlCode: null, // 국가
			},
			payment: {
				paymentIdentifiedYn: 'Y',
				paymentType: null, // 납부방법
				billType: null, // 청구서종류
				bankCode: null, // 은행정보
				accountNum: null, // 계좌번호
				accountHolderName: null, // 예금주
				accountHolderBirth: null, // 생년월일
				accountHolderRelation: null, // 관계
				cardCompanyCode: null, // 카드정보
				cardNum1: null, // 카드번호
				cardNum2: null, // 카드번호
				cardNum3: null, // 카드번호
				cardNum4: null, // 카드번호
				cardHolderName: null, // 카드주
				cardHolderRegiNum1: null, // 주민등록번호
				cardHolderRegiNum2: null, // 주민등록번호
				cardValidityMm: null, // 유효기간MM
				cardValidityYy: null, // 유효기간YY
				giroZipCode: null, // 우편번호
				giroAddr: null, // 기본주소
				giroAddrDetail: null, // 상세주소
			},
			join: {
				openingPhone: null, // 개통 휴대폰번호
				openingPhone1: null, // 개통 휴대폰번호
				openingPhone2: null, // 개통 휴대폰번호
				openingPhone3: null, // 개통 휴대폰번호
				openingHopeNum: null, // 개통희망번호
				openingTelecomCodeId: null, // 개통통신사
				openingTelecomName: null, // 개통통신사
				existTelecomCodeId: null, // 기존 통신사
				joinType: null, // 가입유형
				numChangeAuthType: null, // 인증방식
				numChangeAuthTypeNum: null, // 인증번호
				goodsId: null, // 기기명
				dvcId: null, // 기기명
				usimDvcId: null, // 기기명
				capacity: null, // 용량
				color: null, // 색상
				modelName: null, // 모델네임
				deviceRawBarcode: null, // 기기일련번호
				usimPaymentType: null, // 유심
				usimType: 'NANO_USIM', // 유심
				usimRawBarcode: null, // 유심일련번호
				agreementType: 'CHOICE_AGRMN', // 약정유형
				agreementPeriodType: null, // 약정기간
				instlPeriodType: null, // 할부기간
				chargeId: null, // 요금제
				addServiceList: [], // 부가서비스리스트
				releaseAmt: 0, // 출고가
				releaseAmtDate: null, // 출고가일자
				pubNotiSupportAmt: 0, // 공시지원금
				extraSupportAmt: 0, // 추가지원금
				freeInstlAmt: 0, // 프리할부/선수납
				etcDiscountAmt: 0, // 기타할인금
				actualSellAmt: 0, // 실판매가
				instlPrincipalAmt: 0, // 실판매가
				monthDvcAmt: 0, // 월기기금액
				monthBasicAmt: 0, // 월기본료
				monthPaymentAmt: 0, // 월납부금액
				depositAmt: 0, // 입금받을금액
				depositYn: 'N', // 입금여부
				pubNotiId: 0, // 공시알림아이디
				deviceMatchingType: null, // 기기 매칭타입
				usimMatchingType: null, // 유심 매칭타입
				openingDate: null, // 개통일자
				cancelDate: null, // 철회일자
				networkId: null, // 통신망
				//c4
				cardDiscountInstallmentType: null,
				cardDiscountLumpSumType: null,
				//c4
			},
			delivery: {
				deliveryType: null, // 배송구분
				courierCodeId: null, // 택배사
				invoiceNum: null, // 송장번호
				deliveryZipCode: null, // 우편번호
				deliveryAddr: null, // 기본주소
				deliveryAddrDetail: null, // 상세주소
				couriorMatchingYn: null, // 운송장매칭여부
				deliveryCusName: null, // 실수령인
				deliveryCusPhone: null, // 실수령인 연락처
				deliveryCusPhone1: null, // 실수령인 연락처
				deliveryCusPhone2: null, // 실수령인 연락처
				deliveryCusPhone3: null, // 실수령인 연락처
			},
			etc: {
				giftEnclosedYn: null, // 사은품동봉여부
				giftList: [
					{
						giftName: null,
					},
				], // 사은품
				usedDvcReclaimYn: null, // 중고폰회수여부
				usedDvcModelName: null, // 중고폰모델명
				usedDvcCompe: null, // 중고보상
				attachList: [], // 파일추가
			},
			cnsltId: null,
			consultList: [
				{ consultUserId: null },
				{ consultUserId: null },
				{ consultUserId: null },
			],
		};
	},
};

const actions = {
	async getCommonCodeList({ commit }: ActionContext<State, State>) {
		try {
			/* 신규 ENUM 데이터 */
			const newEnum = {
				initEnumData: [
					/*'applType', // 기기,유심 일련*/
					'CustomerType', // 고객유형
					'LicenseAuthType', // 신분증진위
					'ChargeReductType', // 요금감면
					'DriverAreaCode', // 운전면허지역코드 (앞자리)
					'BillType', // 청구서종류
					'BankCode', // 은행정보
					'CardCompanyCode', // 카드정보
					'JoinType', // 가입유형
					'NumChangeAuthType', // 인증방식
					'UsimPaymentType', // 유심
					'AgreementPeriodType', // 약정기간
					'InstallmentsPeriodType', // 할부기간
					'StayCode', // 체류코드
					'NationalCode', // 국가코드
					'ConsultTaskStatus', // 진행상태(상담)
					'OpeningTaskStatus', // 진행상태(개통)
					'LogisticsTaskStatus', // 진행상태(물류)
					'DeliveryType', // 배송구분
				],
			};
			const result = await commonCodeEnumList(newEnum);
			if (result) {
				commit('setCommonCodeNewList', result.data.data);
			}
			/* 택배사 코드데이터 */
			const Enum = { code: ['COURIER', 'existelecom', 'NETWORK'] };
			const EnumResult = await commonCodeList(Enum);
			if (EnumResult) {
				commit('setCommonCodeList', EnumResult.data.data);
			}
		} catch (e) {
			console.log(e);
		}
	},
	// 기기명
	async getGoodsSelectList(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		try {
			// @ts-ignore
			if (!data || data.telecomId === null) {
				return;
			}
			const result = await getGoodsSelectList(data);
			if (result) {
				commit('setGoodsSelectList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getCapacitySelectList(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		try {
			const result = await getCapacityList(data);
			if (result) {
				if (result.data.resultMsg === '조회된 결과가 없습니다.') {
					if (state.AppFlag >= 28177 || !state.AppFlag) {
						alert(
							'기기검색 결과, 용량 데이터가 없습니다. 관리자를 통해 해당 기기옵션 용량을 등록 해 주세요.',
						);
					}
					return;
				}
				commit('setCapacitySelectList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getColorSelectList(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		try {
			const result = await getDeviceColorList(data);
			if (result) {
				commit('setColorSelectList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	// 부가서비스 조회
	async getTelecomAddServiceList(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		try {
			if (!data) {
				return;
			}
			const result = await getTelecomAddServiceList(data);
			if (result) {
				commit('setTelecomAddServiceList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	// 요금제조회
	async getTelecomChargeList(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		try {
			if (!data) {
				return;
			}
			const result = await getTelecomChargeList(data);
			if (result) {
				commit('setTelecomChargeList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async insertList(
		{ commit }: ActionContext<State, State>,
		data: appCommonFormData,
	) {
		try {
			const result = await insertData(data);
			if (result && result.data.resultCode === '0000') {
				commit('setInsertList', result);
				return true;
			} else if (result && result.data.resultCode === '9999') {
				alert(result.data.resultMsg);
			} else {
				console.log('알수없는 오류가 발생했습니다.');
				return false;
			}
		} catch (e) {
			console.log(e);
		}
	},
	async updateApplication(
		{ commit }: ActionContext<State, State>,
		data: appCommonFormData,
	) {
		try {
			const result = await updateApplication(data);
			if (result) {
				if (result.data.resultMsg === 'FAIL') {
					alert('신청서 수정에 실패했습니다.');
					return;
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
	async fileUpload({ commit }: ActionContext<State, State>, data: any) {
		try {
			const result = await fileUpload(data);
			if (result.data.status === 200) {
				if (result.data.resultCode !== '0000') {
					alert(result.data.resultMsg);
				} else {
					return true;
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
	// 파일 삭제
	async deleteApplicationAttachAndMemo(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await deleteApplicationAttachAndMemo(data);
			if (result.data.status === 200) {
				if (result.data.resultCode !== '0000') {
					alert(result.data.resultMsg);
				} else {
					return true;
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getApplicationAttachList(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getApplicationAttachList(data);
			if (result.data.status === 200) {
				if (result.data.resultCode !== '0000') {
					alert(result.data.resultMsg);
				} else {
					return result.data.data.resultList || [];
				}
			}
		} catch (e) {
			console.log(e);
		}
	},

	async getSaleStoreList({ commit }: ActionContext<State, State>, data: any) {
		try {
			const result = await getSaleStoreList(data);
			if (result) {
				commit('setSaleStoreList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getOpeningStoreList(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getOpeningStoreList(data);
			if (result) {
				commit('setOpeningStoreList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async openingTelecomList({ commit }: ActionContext<State, State>) {
		try {
			const data = { code: ['TELECOM'] };
			const result = await commonCodeList(data);
		} catch (e) {
			console.log(e);
		}
	},
	async getSaleStoreMemberList(
		{ commit }: ActionContext<State, State>,
		data: number,
	) {
		try {
			const result = await getSaleStoreMemberList(data);
			if (result) {
				commit('setSaleStoreMemberList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getPubNotiInfo({ commit }: ActionContext<State, State>, data: any) {
		try {
			const result = await getPubNotiInfo(data);
			if (result) {
				commit('setPubNotiInfo', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async updateApplicationTaskStatus(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await updateApplicationTaskStatus(data);
		} catch (e) {
			console.log(e);
		}
	},
	// 블랙리스트콜
	async getDetailList({ commit }: ActionContext<State, State>, data: any) {
		const result = await getBlackDetailList(data);
		if (result) {
			//commit('setBlackList', result);
			return result;
		}
	},
	// 중복 접수 신청서 CHK
	async checkDuplicationApplication(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		const result = await checkDuplicationApplication(data);
		if (result) {
			return result;
		}
	},
	// 상담원 조회
	async retrieveMemberListByRole(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		const result = await retrieveMemberListByRole(data);
		if (result) {
			commit('setMemberListByRole', result);
		}
	},
	// 신청서 상세 조회
	async getApplicationDetail(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		const result = await getApplicationDetail(data);
		if (result && result.data.resultCode === '0000') {
			commit('setApplicationDetail', result);
		}
	},
	// 빈도수 높은 기기명 조회
	async getMostGoodsSelectList(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		if (!data) {
			return;
		}
		const result = await getMostGoodsSelectList(data);
		if (result) {
			commit('setMostGoodsSelectList', result.data.data);
			return result.data.data;
		}
	},
	// 빈도수 높은 요금제 조회
	async getTelecomMostChargeList(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		if (!data) {
			return;
		}
		const result = await getTelecomMostChargeList(data);
		if (result) {
			commit('setTelecomMostChargeList', result.data.data);
			return result.data.data;
		}
	},
	async getApplicationTemplate(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		const tempList = state.codeList.applicationTemplateList.filter(
			// @ts-ignore
			i => i.applTempId === data,
		);
		const result_json = JSON.parse(
			tempList[0].templateContents.replace(/&quot;/g, '"').trim(),
		);
		const parseResult = {
			applTempId: tempList[0].applTempId,
			templateTitle: tempList[0].templateTitle,
			data: { data: result_json },
		};
		if (parseResult) {
			commit('setApplicationDetail', parseResult);
		}
	},
	async getApplicationTemplateList(
		{ commit }: ActionContext<State, State>,
		data: string,
	) {
		const result = await getApplicationTemplateList(data);
		if (result) {
			commit('setApplicationTemplateList', result);
		}
	},
	async insertApplicationTemplate(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		data.templateContents = JSON.stringify(data.templateContents);
		const result = await insertApplicationTemplate(data);
		if (result && result.data.resultCode === '0000') {
			alert('저장되었습니다.');
			return true;
		} else if (result && result.data.resultCode === '9999') {
			alert(result.data.resultMsg);
		} else {
			console.log('알수없는 오류가 발생했습니다.');
			return false;
		}
	},
	async updateApplicationTemplate(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		data.applTempId = state.templateSeq;
		data.templateContents = JSON.stringify(data.templateContents);
		const result = await updateApplicationTemplate(data);
		if (result && result.data.resultCode === '0000') {
			alert('수정되었습니다.');
			return true;
		} else if (result && result.data.resultCode === '9999') {
			alert(result.data.resultMsg);
		} else {
			console.log('알수없는 오류가 발생했습니다.');
			return false;
		}
	},
	async deleteApplicationTemplate(
		{ commit }: ActionContext<State, State>,
		data: Number,
	) {
		const result = await deleteApplicationTemplate(data);
		if (result && result.data.resultCode === '0000') {
			alert('삭제되었습니다.');
			return true;
		} else if (result && result.data.resultCode === '9999') {
			alert(result.data.resultMsg);
		} else {
			console.log('알수없는 오류가 발생했습니다.');
			return false;
		}
	},
	async getSelOrgnztListFnc(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getSelOrgnztList(data);
			if (result) {
				commit('setSelOrgnztList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	// 만료일 초과 신청서 존재시 메세지 발송
	async sendMessageDeadLineApplication() {
		try {
			const result = await sendMessageDeadLineApplication();
		} catch (e) {
			console.log(e);
		}
	},
	// 만료일 초과 신청서 존재시 메세지 발송
	async cwsCheckMoveDeviceFromBarcode(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await cwsCheckMoveDeviceFromBarcode(data);
			if (result.data.status === 200) {
				if (result.data.resultCode !== '0000') {
					alert(result.data.resultMsg);
				} else {
					return result.data.data;
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
	// formData Init
	async formDataInit({ commit }: ActionContext<State, State>) {
		commit('formDataInit');
	},
	async getApplicationCreditDetail(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getApplicationCreditDetail(data);
			if (result) {
				return result.data.data;
			}
		} catch (e) {
			console.log(e);
		}
	},
};

const ApplicationFormCreationModule = {
	namespaced: true,
	state: state,
	getters: getters,
	mutations: mutations,
	actions: actions,
};

export default ApplicationFormCreationModule;
