\
<template>
	<div>
		<book-mark path="/charge-mgmt/chargelist"></book-mark>
		<charge-list-filter></charge-list-filter>
		<charge-list-list></charge-list-list>
	</div>
</template>

<script>
import BookMark from '../../../components/BookMark';
import ChargeListFilter from './ChargeList/ChargeListFilter.vue';
import ChargeListList from './ChargeList/ChargeListList.vue';

export default {
	components: {
		BookMark,
		ChargeListFilter,
		ChargeListList,
	},
};
</script>
