<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" :width="popWidth" >
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 disFx justify-lg-space-between"
						style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">{{ title }}</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad10">
						<template v-if="title === '변경 요금제 선택'">
							<change-charge-select></change-charge-select>
						</template>
						<template v-if="title === '변경 가능일자 선택'">
							<change-date-select></change-date-select>
						</template>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import ChangeChargeSelect from './pop-template/ChangeChargeSelect.vue';
import ChangeDateSelect from './pop-template/ChangeDateSelect.vue';

export default {
	name: 'chargeBoardPop',
	components: {
		ChangeChargeSelect,
		ChangeDateSelect,
	},
	props: ['title'],
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chargeBoardPopDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chargeBoardPopDialog = newValue;
			},
		},
		popWidth() {
			if (this.title === '변경 요금제 선택') {
				return 950;
			} else if (this.title === '변경 가능일자 선택') {
				return 270;
			} else {
				return 800;
			}
		},
	},
};
</script>

<style scoped>
</style>
