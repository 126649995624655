<template>
	<div>
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="423" @keydown.esc="returnCancel">
				<v-card class="borderRadiReset infoPopup" v-click-outside="returnCancel">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">반품 확인</h2>
						<span class="closeBtn1 cur_p" @click="returnCancel"></span>
					</v-card-title>

					<v-card-text class="pad16">
						<div class="fw500 font-black font-size-14"
								 v-if="this.submitType === 'NONE'">
							아래와 같은 정보로 {{ storeName }}에 "<span class="mainOrange">반품완료</span>" 되었습니까?
							<br/>"아니오"를 선택하면 해당 신청서로 이동됩니다.
							<br/><br/>
							고객명 : {{ formData.userName }}<br/>
							번호 : {{ formData.userPhone.replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ) }}<br/>
							주소 : {{ formData.cusAddr }}
							<br/><br/>
							기기  : {{ formData.goodsName }}<br/>
							색상 : {{ formData.color }}<br/>
							용량 : {{ formData.capacity }}<br/>
							일련번호 : {{ formData.deviceRawBarcode }}<br/>
							유심 : {{ formData.usimRawBarcode }}
						</div>
						<div class="fw700 font-black font-size-13"
								 v-if="this.submitType === 'CHECK'">
							확인 시 신청서 물류상태가 "<span class="mainOrange">반품완료(영업점)</span>"으로 처리됩니다.<br/>
							해당 기기는 {{ storeName }}에서 관리하게 됩니다.
						</div>

						<div class="new_popTable1">
							<div class="disFx justify-end mt10">
								<v-btn
									class="backColorGray1 mainWhite borderReset w80"
									text
									outlined
									@click="returnSubmit"
								>
									예
								</v-btn>
								<v-btn
									class="backColorBlue3 mainWhite boldWt borderReset w80 ml16"
									text
									@click="returnCancel(submitType)"
									outlined
								>
									아니오
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LogisticsTaskReturnPop',
	props: {
		formData: {type: Object, required: true},
	},
	data: () => ({
		templateTitle: '',
		submitType: 'NONE',
	}),
	computed: {
		storeName() {
			return this.$store.state.cookies.sn;
		},
		dialog: {
			get() {
				return this.$store.state.TodoModule.logisticsTaskReturnDialog;
			},
			set(newValue) {
				this.$store.state.TodoModule.logisticsTaskReturnDialog = newValue;
			},
		},
	},
	methods: {
		async returnSubmit() {
			if(this.submitType === 'NONE') {
				// 반품 재확인
				this.submitType = 'CHECK';
			} else if (this.submitType === 'CHECK') {
				// api 전달
				let obj = {
					basic: {
						applId: this.formData.applId,
					}
				}
				let result = await this.$store.dispatch(
						'TodoModule/updateStoreReturn', obj,
				);
				if(result) {
					alert('반품완료 처리되었습니다.')
					let refreshObj = {
						path: this.$route.path,
					}
					this.$store.dispatch(
							'TodoModule/getLogisticsTaskList', refreshObj,
					);
					this.dialog = false;
				}
			}
		},
		async returnCancel(submitType) {
			if(submitType === 'NONE') {
				this.formData.dialogTypeText = '상세';
				this.formData.consultType = 'CUSTOMER';
				this.$emit('openApplPop', this.formData)
			}
			this.dialog = false;
		}
	},
	async created() {
		this.submitType = 'NONE';
	},
};
</script>

<style scoped>
.ml16 {margin-left: 16px}
</style>
