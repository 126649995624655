import Vue from 'vue';
import Vuex from 'vuex';
import {
	getTelecomChargeList,
	getGoodsSelectList,
	getTelecomAddServiceList,
} from '@/api/common/common';
import { getOpeningStoreList } from '@/api/supply-chain-mgmt/application-form-creation/ApplicationFromApi';
import {
	getSellRule,
	insertSellRule,
	getSellRuleList,
	getSellRuleListFromSellGoods,
	isPresentOpeningStoreSellRule,
	insertSellRuleGroupMargin,
	updateSellRuleGroupMargin,
	deleteSellRuleGroupMargin,
	updateSellRuleGroupStore,
	getSellRuleGroupMarginList,
	getOpenStoreSellRuleGroupMarginList,
	getSellRuleGroupStoreList,
	getSellRuleTemplateList,
	insertSellRuleTemplate,
	deleteSellRuleTemplate,
	setOpenStoreSellRuleGroupInfo,
	updateSellRuleTemplate,
	updateSellRule,
	deleteSellRule,
	updateSellRuleDeployYn,
	updateSellRuleUseYn,
	getSellRuleChargeRangeList,
	getSellRuleGroupMarginInfo,
	insertSellRuleGroupMarginSheet,
	setSellRuleOpenStore,
	getSellRuleOpenStoreList,
	getOpenStorePrevSellRule,
	insertSellRuleMaxSup,
	getSellRuleMaxSup,
} from '@/api/mall_mgmt/MallJudgeMgmtApi.js';

Vue.use(Vuex);

const MallJudgeMgmtModule = {
	namespaced: true,
	state: {
		formDataLeng1: 0,
		formDataLeng2: 0,
		basicSheetValue: 0,
		popSellRuleGroupId: null,
		groupMarginJson1: {},
		groupRuleDetailJson1: {},
		groupMarginJson2: {},
		groupRuleDetailJson2: {},
		joinType: '공시지원',
		openFormValiDate: {
			spreadIdx: null,
			formIdx: null,
		},
		ruleGroupMgmtDialog: false,
		groupSpreadDto: {
			groupSpreadMaginList: [],
		},
		chargeGroupDataGet: false,
		chargeGroupDialog: false,
		chargeGroupList: [],
		ruleAmtFormDialog: false,
		deployYn: null,
		ruleSetDialog: false,
		ruleTemplateList: [],
		dateData: null,
		dateTime: null,
		ruleRegiPopTxt: null,
		openingMarginFlag: false,
		sellRuleGroupData: [],
		ruleOpeningStoreList: [],
		resetData: false,
		ruleList: [],
		dateResetData: false,
		ruleGroupDto: {
			groupName: null,
			sellRuleId: null,
			sellRuleGroupMarginId: null,
		},
		checkListMarginIdArray: [],
		popPagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		ruleRegiPopDialog: false,
		spreadDialog1: true,
		spreadDialog2: false,
		discountTypePub1: false,
		discountTypePub2: false,
		discountTypeChoice1: false,
		discountTypeChoice2: false,
		selectedTelecom: null,
		spreadData1: [],
		spreadData2: [],
		spreadData3: [],
		spreadData4: [],
		theadStatusMemo1: [
			{ name: '번호이동', value: true, jointype: 'NC' },
			{ name: '기기변경', value: true, jointype: 'DC' },
			{ name: '신규', value: true, jointype: 'NEW' },
		],
		theadStatusMemo2: [
			{ name: '번호이동', value: true, jointype: 'NC' },
			{ name: '기기변경', value: true, jointype: 'DC' },
			{ name: '신규', value: true, jointype: 'NEW' },
		],
		theadStatus1: [
			{ name: '번호이동', value: true, jointype: 'NC' },
			{ name: '기기변경', value: true, jointype: 'DC' },
			{ name: '신규', value: true, jointype: 'NEW' },
		],
		theadStatus2: [
			{ name: '번호이동', value: true, jointype: 'NC' },
			{ name: '기기변경', value: true, jointype: 'DC' },
			{ name: '신규', value: true, jointype: 'NEW' },
		],
		sellRuleGroupAddRuleList: [
			{
				sellRuleGroupAddRuleId: null, // 판매정책 그룹 추가 정책
				sellRuleGroupMarginId: null, // 판매정책 그룹 마진 아이디
				addRuleType: null, // 추가정책 타입
				addRuleTitle: null, // 추가정책명
				applyIds: [], // 적용 항목 아이디
				applyStartDateTime: null, // 적용 시작일지
				applyEndDateTime: null, // 적용 종료일시
				joinTypeRange: [], // 가입 유형 범위
				agreementPeriodTypeList: [], // 약정기간 유형범위
				applyGoodsIds: [], // 적용 상품 아이디
				applySellRuleChargeIds: [], // 요금제 구간 아이디
				addRuleAmt: 0, // 추가정책 금액
				subFlagYn: 'N', // 세부 상태
				addInfo: null, // 부가정보
				delYn: 'N', // 삭제여부
			},
		],
		classifyDialog: false,
		filterData: {},
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		codeList: {
			openingStoreList: [],
			goodsList: [],
			chargeList: [],
			chargeList2: [],
			fiveChargeList: [],
			fourChargeList: [],
			fiveChargeList2: [],
			fourChargeList2: [],
			addServiceList: [],
		},
		summitData: {
			reserveDateTime: null, // 예약 일시
			ruleTitle: null, // 정책 제목
			addRuleContents: null, // 추가 정책 내용
			ruleDetailJson1: null, // 정책 상세 JSON1
			ruleDetailJson2: null, // 정책 상세 JSON1
			openingStoreId: null, // 개통 관리점 아이디
			sellRuleId: null, // 등록된 정책 ID
			deployYn: 'N', // 판매정책배포여부
			regiSaleStoreId: null, // 정책등록 대리점
			applyChargeInfoList: null, // 요금제 구간
		},
		applyChargeInfoDto: {
			// 요금제 구간 생성
			chargeDto1: {
				fiveChargeList: [],
				fourChargeList: [],
			},
			chargeDto2: {
				fiveChargeList: [],
				fourChargeList: [],
			},
		},
		chargeGroupDto: {
			table1: [],
			table2: [],
			totalTable1: {
				fiveChargeList: [],
				fourChargeList: [],
			},
		},
		/*applyChargeInfoList: {
			discountType: 'PUBNOTI_SUPAMT',
			chargeRangeName: null,
			arithOperatorType: null,
			chargeId: null,
			applyChargeIds: [1565, 1353, 1354],
		},*/
		goodsFormat: {
			goodsId: null,
			goodsRuleList: [
				{
					goodsRule: {
						fiveChargeId: null,
						fiveRangeName: null,
						fourChargeId: null,
						fourRangeName: null,
						arithOperatorType: 'EQUALS_UP',
						supAmt: {},
					},
				},
				{
					goodsRule: {
						fiveChargeId: null,
						fiveRangeName: null,
						fourChargeId: null,
						fourRangeName: null,
						arithOperatorType: 'EQUALS_UP',
						supAmt: {},
					},
				},
				{
					goodsRule: {
						fiveChargeId: null,
						fiveRangeName: null,
						fourChargeId: null,
						fourRangeName: null,
						arithOperatorType: 'EQUALS_UP',
						supAmt: {},
					},
				},
				{
					goodsRule: {
						fiveChargeId: null,
						fiveRangeName: null,
						fourChargeId: null,
						fourRangeName: null,
						arithOperatorType: 'EQUALS_DOWN',
						supAmt: {},
					},
				},
				{
					goodsRule: {
						fiveChargeId: null,
						fiveRangeName: null,
						fourChargeId: null,
						fourRangeName: null,
						arithOperatorType: 'EQUALS',
						supAmt: {},
					},
				},
			],
		},
		formData1: {
			discountType: ['PUBNOTI_SUPAMT'],
			ruleInfoList: [
				{
					goodsId: null,
					goodsRuleList: [
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_UP',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_UP',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_UP',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_DOWN',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS',
								supAmt: {},
							},
						},
					],
				},
			],
		},
		formData2: {
			discountType: ['CHOICE_AGRMN'],
			ruleInfoList: [
				{
					goodsId: null,
					goodsRuleList: [
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_UP',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_UP',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_UP',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS_DOWN',
								supAmt: {},
							},
						},
						{
							goodsRule: {
								fiveChargeId: null,
								fiveRangeName: null,
								fourChargeId: null,
								fourRangeName: null,
								arithOperatorType: 'EQUALS',
								supAmt: {},
							},
						},
					],
				},
			],
		},
		sellRuleOpeningStoreDialog: false, // 판매상품 정책 팝업
		sellRuleMaxSubDialog: false, // 정책 최대 지원금 설정 팝업
	},
	mutations: {
		setOpeningStoreList(state, result) {
			state.codeList.openingStoreList = [];
			state.codeList.openingStoreList = result;
		},
		goodsItemsSet(state, result) {
			state.codeList.goodsList = [];
			state.codeList.goodsList = result;
		},
		setTelecomChargeList(state, result) {
			state.summitData.applyChargeInfoList = result.applyChargeInfoList;
			let fiveCharge = result.baseChargeList.filter(
				item =>
					item.network === 14 &&
					(item.chargeId !== 1472 ||
						item.chargeId !== 1473 ||
						item.chargeId !== 1474),
			);
			let fourCharge = result.baseChargeList.filter(
				item => item.network === 15,
			);
			state.codeList.fiveChargeList = [];
			state.codeList.fourChargeList = [];
			state.codeList.fiveChargeList2 = [];
			state.codeList.fourChargeList2 = [];
			state.codeList.chargeList = [];
			state.codeList.chargeList2 = [];
			state.codeList.fiveChargeList = fiveCharge;
			state.codeList.fourChargeList = fourCharge;
			state.codeList.fiveChargeList2 = JSON.parse(JSON.stringify(fiveCharge));
			state.codeList.fourChargeList2 = JSON.parse(JSON.stringify(fourCharge));
			state.codeList.chargeList = [...fiveCharge, ...fourCharge];
			state.codeList.chargeList2 = JSON.parse(
				JSON.stringify([...fiveCharge, ...fourCharge]),
			);
		},
		setSellRuleList(state, result) {
			state.ruleList = result.resultList || [];
			state.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setSellRuleGroupMarginList(state, result) {
			state.sellRuleGroupData = [];
			state.sellRuleGroupData = result;
		},
		resetSellRuleGroupMarginList(state) {
			state.sellRuleGroupData = [];
		},
		setSellRuleGroupStoreList(state, result) {
			state.ruleOpeningStoreList = [];
			state.ruleOpeningStoreList = result;
		},
		setOpenStoreSellRuleGroupMarginList(state) {
			state.sellRuleGroupData = [];
		},
		setSellRuleTemplateList(state, result) {
			state.ruleTemplateList = [];
			state.ruleTemplateList = result.resultList;
		},
		setSellRule(state, result) {
			state.formDataLeng1 = 0;
			state.formDataLeng2 = 0;
			let parseArr1 = JSON.parse(
				result.ruleDetailJson1.replace(/&quot;/g, '"'),
			);
			state.formDataLeng1 = parseArr1.ruleInfoList.length;
			if (result.ruleDetailJson2) {
				let parseArr2 = JSON.parse(
					result.ruleDetailJson2.replace(/&quot;/g, '"'),
				);
				state.formDataLeng2 = parseArr2.ruleInfoList.length;
			}
		},
		setSellRuleGroupMarginInfo(state, result) {},
		setTelecomAddServiceList(state, result) {
			state.codeList.addServiceList = result;
		},
	},
	actions: {
		async getOpeningStoreList({ commit }, data) {
			try {
				const result = await getOpeningStoreList(data);
				if (result) {
					commit('setOpeningStoreList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 상품 셀렉트 목록 조회
		async getGoodsSelectList({ commit }, data) {
			const result = await getGoodsSelectList(data);
			if (result) {
				commit('goodsItemsSet', result.data.data);
			}
		},
		/*// 요금제 셀렉트 목록 조회
		async getTelecomChargeList({ commit }, data) {
			const result = await getTelecomChargeList(data);
			if (result) {
				commit('setTelecomChargeList', result.data.data);
			}
		},*/

		// 요금제 셀렉트 목록 조회
		async getSellRuleChargeRangeList({ commit }, data) {
			const result = await getSellRuleChargeRangeList(data);
			if (result) {
				commit('setTelecomChargeList', result.data.data);
				return result;
			}
		},
		// 부가서비스 조회
		async getTelecomAddServiceList({ commit }, data) {
			const result = await getTelecomAddServiceList(data);
			if (result) {
				commit('setTelecomAddServiceList', result.data.data);
			}
		},

		async getSellRuleList({ commit }, data) {
			const result = await getSellRuleList(data);
			if (result && '0000' === result.data.resultCode) {
				commit('setSellRuleList', result.data.data);
				return result.data.data;
			}
		},
		async getSellRuleListFromSellGoods({ commit }, data) {
			const result = await getSellRuleListFromSellGoods(data);
			if (result && '0000' === result.data.resultCode) {
				commit('setSellRuleList', result.data.data);
				return result.data.data;
			}
		},
		async isPresentOpeningStoreSellRule({ commit }, data) {
			const result = await isPresentOpeningStoreSellRule(data);
			if (result && '0000' === result.data.resultCode) {
				return true;
			} else if (result && '4402' === result.data.resultCode) {
				return result.data.resultMsg;
			} else if (result && '4403' === result.data.resultCode) {
				return result.data.resultMsg;
			} else if (result && '4406' === result.data.resultCode) {
				return '기존에 배포하신 이력이 확인됩니다.<br/>' +
					'신규 정책이 등록됩니다(상태 : 미사용/미배포)';
			}
		},
		// 판매정책 기본정보/추가정책 저장
		async insertSellRule({ commit }, data) {
			const result = await insertSellRule(data);
			if (result && '0000' === result.data.resultCode) {
				alert('등록되었습니다.');
				return result.data.data;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매정책 신규 등록
		async insertSellRuleGroupMargin({ commit }, data) {
			const result = await insertSellRuleGroupMargin(data);
			if (result && '0000' === result.data.resultCode) {
				alert('등록되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매 정책 수정
		async updateSellRule({ commit }, data) {
			const result = await updateSellRule(data);
			if (result && '0000' === result.data.resultCode) {
				alert('저장되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매 정책 삭제
		async deleteSellRule({ commit }, data) {
			const result = await deleteSellRule(data);
			if (result && '0000' === result.data.resultCode) {
				alert('삭제되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매정책 마진 수정
		async updateSellRuleGroupMargin({ commit }, data) {
			const result = await updateSellRuleGroupMargin(data);
			if (result && '0000' === result.data.resultCode) {
				alert('수정되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매정책 마진분류 삭제
		async deleteSellRuleGroupMargin({ commit }, data) {
			const result = await deleteSellRuleGroupMargin(data);
			if (result && '0000' === result.data.resultCode) {
				alert('삭제되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},

		// 판매정책 영업점별 등급 부여
		async updateSellRuleGroupStore({ commit }, data) {
			const result = await updateSellRuleGroupStore(data);
			if (result && '0000' === result.data.resultCode) {
				alert('적용되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 개통점의 등록된 그룹마진 리스트 조회
		async getSellRuleGroupMarginList({ commit }, data) {
			const result = await getSellRuleGroupMarginList(data);
			if (result && '0000' === result.data.resultCode) {
				commit('setSellRuleGroupMarginList', result.data.data);
				return result;
			} else {
				commit('resetSellRuleGroupMarginList');
				return false;
			}
		},
		// 개통점의 등록된 그룹마진 리스트 조회
		async getSellRuleGroupStoreList({ commit }, data) {
			const result = await getSellRuleGroupStoreList(data);
			if (result && '0000' === result.data.resultCode) {
				commit('setSellRuleGroupStoreList', result.data.data);
			} else {
				return false;
			}
		},
		async getOpenStoreSellRuleGroupMarginList({ commit }, data) {
			const result = await getOpenStoreSellRuleGroupMarginList(data);
			if (result && '0000' === result.data.resultCode) {
				return true;
			}
		},
		// 정책양식조회
		async getSellRuleTemplateList({ commit }, data) {
			const result = await getSellRuleTemplateList(data);
			if (result) {
				commit('setSellRuleTemplateList', result.data.data);
			}
		},
		// 정책양식조회
		async getSellRule({ commit }, data) {
			const result = await getSellRule(data);
			if (result) {
				commit('setSellRule', result.data.data);
				return result;
			}
		},
		// 정책 양식 저장
		async insertSellRuleTemplate({ commit }, data) {
			const result = await insertSellRuleTemplate(data);
			if (result && '0000' === result.data.resultCode) {
				alert('저장되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 정책 양식 수정
		async updateSellRuleTemplate({ commit }, data) {
			const result = await updateSellRuleTemplate(data);
			if (result && '0000' === result.data.resultCode) {
				alert('수정되었습니다.');
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 정책 양식 삭제
		async deleteSellRuleTemplate({ commit }, data) {
			const result = await deleteSellRuleTemplate(data);
			if (result && '0000' === result.data.resultCode) {
				alert('삭제되었습니다.');
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 그룹 세팅
		async setOpenStoreSellRuleGroupInfo({ commit }, data) {
			const result = await setOpenStoreSellRuleGroupInfo(data);
			if (result && '0000' === result.data.resultCode) {
				alert('적용되었습니다.');
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매정책 배포 유무
		async updateSellRuleDeployYn({ commit }, data) {
			const result = await updateSellRuleDeployYn(data);
			if (result && '0000' === result.data.resultCode) {
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매정책 사용여부 수정
		async updateSellRuleUseYn({ commit }, data) {
			const result = await updateSellRuleUseYn(data);
			if (result && '0000' === result.data.resultCode) {
				alert('변경되었습니다.');
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 분류별 추가 지급하도록 설정한 마진을 조회한다
		async getSellRuleGroupMarginInfo({ commit }, data) {
			const result = await getSellRuleGroupMarginInfo(data);
			if (result) {
				commit('setSellRuleGroupMarginInfo', result.data.data);
				return result;
			}
		},
		// 정책 양식 저장
		async insertSellRuleGroupMarginSheet({ commit }, data) {
			const result = await insertSellRuleGroupMarginSheet(data);
			if (result && '0000' === result.data.resultCode) {
				alert('저장되었습니다.');
				return result;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매 정책 통신사별 개통점 설정
		async setSellRuleOpenStore({ commit }, data) {
			const result = await setSellRuleOpenStore(data);
			if (result && '0000' === result.data.resultCode) {
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매 정책 통신사별 개통점 조회
		async getSellRuleOpenStoreList({ commit }, data) {
			const result = await getSellRuleOpenStoreList(data);
			if (result) {
				return result;
			}
		},
		// 사용여부 조회
		async getOpenStorePrevSellRule({ commit }, data) {
			const result = await getOpenStorePrevSellRule(data);
			if (result) {
				return result;
			}
		},
		// 판매 정책 최대 지원금 설정
		async insertSellRuleMaxSup({ commit }, data) {
			const result = await insertSellRuleMaxSup(data);
			if (result && '0000' === result.data.resultCode) {
				return true;
			} else {
				alert(result.data.resultMsg);
			}
		},
		// 판매 정책 최대 지원금 조회
		async getSellRuleMaxSup({ commit }, data) {
			const result = await getSellRuleMaxSup(data);
			if (result) {
				return result.data;
			}
		},
	},
};

export default MallJudgeMgmtModule;
