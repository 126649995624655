function lnb(data, st, sg, roleVal, cr, sm, mq, process, ca) {
	let menu = [];
	if (st === '22f353197e9b0c1cb58a11da8de7776a' || (sm && roleVal === 'M')) {
		// sa 그룹이거나 입점신청or완료 된 판매점
		menu.push({
			title: '쇼핑몰관리',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-organization',
			},
			mallDomainChk: true,
			hideToggle: true,
			child: [],
		});
		if (st === '22f353197e9b0c1cb58a11da8de7776a') {
			/*menu[menu.findIndex(obj => obj.title === '쇼핑몰관리')].child.push({
				title: '의뢰 게시판',
				href: '/producing-mgmt',
				alias: ['/producing-mgmt/!*'],
			});*/
			menu[menu.findIndex((obj) => obj.title === '쇼핑몰관리')].child.push({
				title: '환경 설정',
				href: '/mall-setting',
				alias: ['/mall-setting/*'],
			});
		} else if (sm === 'ACCEPT') {
			menu[menu.findIndex((obj) => obj.title === '쇼핑몰관리')].child.push({
				title: '상품관리',
				href: '/product-mgmt',
				alias: [
					'/product-mgmt/mall-page-mgmt',
					'/product-mgmt/mall-product-mgmt',
					'/product-mgmt/mall-product-form*',
					'/product-mgmt/mall-judge-mgmt',
					'/product-mgmt/mall-judge-form*',
					'/mall-product-regi',
					'/mall-judge-regi',
				],
			});
			menu[menu.findIndex((obj) => obj.title === '쇼핑몰관리')].child.push({
				title: '구매 고객 관리',
				href: '/mall-member-mgmt',
				alias: [
					'/mall-member-mgmt/mall-member-list',
					'/mall-member-mgmt/mall-member-detail*',
					'/mall-member-mgmt/mall-review-list',
					'/mall-member-mgmt/mall-review-regi',
					'/mall-member-mgmt/mall-review-detail*',
				],
			});
			/*menu[menu.findIndex(obj => obj.title === '쇼핑몰관리')].child.push({
				title: '의뢰 게시판',
				href: '/producing-mgmt/req-main',
				alias: ['/producing-mgmt/!*'],
			});*/
			menu[menu.findIndex((obj) => obj.title === '쇼핑몰관리')].child.push({
				title: '환경 설정',
				href: '/mall-setting',
				alias: [
					'/mall-setting/mall-event-mgmt',
					'/mall-setting/mall-event-regi',
					'/mall-setting/mall-event-detail?mallEventId=*',
				],
			});
		}
	}
	if (
		(data === 'CW' || data === 'CWS') &&
		cr !== '4785c867cdf74b4bd9a903bbb3e5d619' &&
		cr !== null
	) {
		menu.push({
			title: '영업관리',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-organization',
			},
			child: [
				{
					title: '신용조회 요청',
					href: '/credit-check-request',
				},
			],
		});
		if (ca === 'Y') {
			menu[menu.findIndex((obj) => obj.title === '영업관리')].child.push({
				title: '상담관리',
				href: '/consult-mgmt',
				alias: ['/consult-mgmt/customer-consult', '/consult-mgmt/consult-db'],
			});
		}
		if (
			cr ===
				'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4' ||
			cr === 'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd'
		) {
			menu[menu.findIndex((obj) => obj.title === '영업관리')].child.push({
				title: '고객관리',
				href: '/customer-mgmt',
				alias: [
					'/customer-mgmt/customer-db',
					'/customer-mgmt/export-db',
					'/customer-mgmt/retention-mgmt',
					'/customer-mgmt/charge-calculator',
					'/customer-mgmt/black-list',
					'/customer-mgmt/signature-mgmt',
				],
			});
		}

		if (
			cr === '7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4'
		) {
			menu[menu.findIndex((obj) => obj.title === '영업관리')].child.push({
				title: '환경 설정',
				href: '/env-settings',
				alias: [
					'/env-settings/consult-notice',
					'/env-settings/consult-setting',
				],
			});
		}
		menu[menu.findIndex((obj) => obj.title === '영업관리')].child.push({
			title: '영업통계',
			href: '/sale-stats',
			alias: ['/sale-stats'],
		});
	}
	if (data === 'CW' || data === 'CWS') {
		// menu.push({
		// 	title: '재고관리',
		// 	icon: {
		// 		element: 'span',
		// 		class: 'lnb-icon icon-inventory',
		// 	},
		// 	child: [
		// 		{
		// 			title: '입고 현황',
		// 			href: '/device',
		// 			alias: [
		// 				'/device/in-stock-regi',
		// 				'/device/in-stock-status',
		// 				'/device/return-stock-status',
		// 				'/device/in-stock-history',
		// 			],
		// 		},
		// 		{
		// 			title: '이동/이관 현황',
		// 			href: '/stock-move-transfer',
		// 			alias: [
		// 				'/stock-move-transfer/sales-move',
		// 				'/stock-move-transfer/stock-move-sale',
		// 				'/stock-move-transfer/stock-move-transfer',
		// 				'/stock-move-transfer/move-status',
		// 				'/stock-move-transfer/sales-transfer',
		// 				'/stock-move-transfer/rtn-transfer',
		// 				'/stock-move-transfer/move-history',
		// 			],
		// 		},
		// 		{
		// 			title: '재고 현황',
		// 			href: '/stock-status',
		// 			alias: ['/stock-status/device-status', '/stock-status/stock-stats'],
		// 		},
		// 		{
		// 			title: '관리 기기 현황',
		// 			href: '/mgmt-target',
		// 			alias: [
		// 				'/mgmt-target/mgmt-target-device',
		// 				'/mgmt-target/mediation-release',
		// 				// '/mgmt-target/open-store-device-status',
		// 				// '/mgmt-target/longtime-stock',
		// 				// '/mgmt-target/faulty-device-status',
		// 			],
		// 		},
		// 		{
		// 			// wms + cws
		// 			title: '업체관리',
		// 			href: '/co-mgmt',
		// 			alias: ['/co-mgmt/provider-mgmt', '/co-mgmt/hold-store-mgmt'],
		// 		},
		// 	],
		// });
		menu.push({
			title: '재고관리',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-inventory',
			},
			child: [
				{
					title: '입고 현황',
					href: '/device',
					alias: [
						'/device/in-stock-regi',
						'/device/in-stock-status',
						'/device/return-stock-status',
						'/device/in-stock-history',
					],
				},
				{
					title: '이동/이관 현황',
					href: '/stock-move-transfer',
					alias: [
						'/stock-move-transfer/sales-move',
						'/stock-move-transfer/stock-move-sale',
						'/stock-move-transfer/stock-move-transfer',
						'/stock-move-transfer/move-status',
						'/stock-move-transfer/sales-transfer',
						'/stock-move-transfer/rtn-transfer',
						'/stock-move-transfer/move-history',
					],
				},
				{
					title: '재고 현황',
					href: '/stock-status',
					alias: [
						'/stock-status/device-status',
						'/stock-status/stock-stats',
						'/stock-status/stock-stats',
						'/stock-status/mediation-release',
					],
				},
				{
					// wms + cws
					title: '업체관리',
					href: '/co-mgmt',
					alias: ['/co-mgmt/provider-mgmt', '/co-mgmt/hold-store-mgmt'],
				},
			],
		});
		if (data === 'CWS') {
			if (sg === 'StoreGrade_M') {
				menu[menu.findIndex((obj) => obj.title === '재고관리')].child.splice(
					2,
					0,
					{
						title: '신청서 매칭',
						href: '/matching',
						alias: [
							'/matching/application-matching',
							'/matching/auto-matching',
						],
					},
				);
			}
			menu[menu.findIndex((obj) => obj.title === '재고관리')].child.splice(
				menu[menu.findIndex((obj) => obj.title === '재고관리')].child.length -
					1,
				0,
				{
					title: '권리실행',
					href: '/right-execution',
				},
			);
		}
	}
	if (data === 'CS' || (data === 'CWS' && sg === 'StoreGrade_M')) {
		menu.push({
			title: '판매관리',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-sell',
			},
			child: [
				{
					title: '신용조회 결과',
					href: '/credit-check',
				},
				// {
				// 	title: '신청서작성',
				// 	href: '/application-form-creation',
				// },
				{
					title: '판매현황',
					href: '/sell-status',
					alias: [
						'/application-form-creation?applId=*',
						'/sell-status/receive-request',
						'/sell-status/black-list',
						'/sell-status/signature-mgmt',
						'/sell-status/retention-mgmt',
					],
				},
			],
		});
		// SCT = CS 일때 권리실행이 판매관리탭에 노출, CWS 일때 권리실행이 기기관리에 노출

		if (data === 'CS') {
			menu[menu.findIndex((obj) => obj.title === '판매관리')].child.splice(
				2,
				0,
				{
					title: '권리실행',
					href: '/right-execution',
				},
			);
		}
	}

	if (data === 'CWS') {
		menu.push({
			title: '정산관리',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-bond',
			},
			child: [
				{
					title: '채권관리',
					href: '/bond-mgmt',
					alias: [
						'/bond-mgmt/bond-status*',
						'/bond-mgmt/month-summary',
						'/bond-mgmt/compe-status',
					],
				},
			],
		});
	}
	if (data === 'CS' || data === 'CWS') {
		menu.push({
			title: '커뮤니티',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-community',
			},
			child: [
				{
					title: '민원처리',
					href: '/complain-hndlg',
				},
				{
					title: '고객센터',
					href: '/cs-mgmt',
					alias: ['/cs-mgmt/noticelist', '/cs-mgmt/qalist'],
				},
			],
		});
	}
	if (mq !== '15430b7150af5273ad3b58461a1836b7') {
		menu.push(
			{
				title: '권한설정',
				icon: {
					element: 'span',
					class: 'lnb-icon icon-business',
				},
				child: [
					{
						title: '사업자관리',
						href: '/bsnm-mgmt',
						alias: [
							'/bsnm-mgmt/openingstore-mgmt',
							'/bsnm-mgmt/salestore-mgmt',
							'/bsnm-mgmt/openingstorebcnc-matching',
							'/bsnm-mgmt/openingstoreuser-matching',
						],
					},
					{
						title: '조직관리',
						href: '/orgnzt-mgmt',
						alias: [
							'/orgnzt-mgmt/organization-mgmt',
							'/orgnzt-mgmt/userrole-mgmt',
						],
					},
				],
			},
			{
				title: '관리설정',
				icon: {
					element: 'span',
					class: 'lnb-icon icon-addition',
				},
				child: [
					{
						title: '상품관리',
						href: '/goods-mgmt',
						alias: [
							'/goods-mgmt/mobilephonelist',
							'/goods-mgmt/mobilephone-requestlist',
							'/goods-mgmt/mobilephone-grouplist',
						],
					},
					{
						title: '요금관리',
						href: '/charge-mgmt',
						alias: [
							'/charge-mgmt/chargelist',
							'/charge-mgmt/chargerequest-list',
						],
					},
					{
						title: '부가서비스관리',
						href: '/additionservice-mgmt',
						alias: [
							'/additionservice-mgmt/additionservice-list',
							'/additionservice-mgmt/additionservicerequest-list',
						],
					},
				],
			},
		);
		if (roleVal === 'A') {
			const idx = menu.findIndex((arr) => arr.title === '관리설정');
			menu[idx].child.unshift({
				title: '시스템 관리',
				href: '/system-mgmt',
				alias: [
					'/system-mgmt/terms-mgmt',
					'/system-mgmt/terms-regi*',
					'/system-mgmt/terms-detail*',
				],
			});
			menu[idx].child.push({
				title: '공시지원금관리',
				href: '/pnsupportmoney-mgmt',
				alias: [
					'/pnsupportmoney-mgmt/pnsupportmoney',
					'/pnsupportmoney-mgmt/pnsupportmoneydata',
				],
			});
		}
	}
	if (
		st === '3b14c9d61a1ce5782698616844edc9ee' ||
		st === '22f353197e9b0c1cb58a11da8de7776a'
	) {
		menu.push({
			title: '통계관리',
			icon: {
				element: 'span',
				class: 'lnb-icon icon-statistics',
			},
			href: '/stats-mgmt',
		});
	}
	return menu;
}

module.exports = lnb;
