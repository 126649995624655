<template>
	<div class="padW30">
		<div
			class="device_table_style1 mt15 narrowScroll posR"
			style="max-height: 500px !important; height: auto; margin-bottom: 15px"
		>
			<table class="w100P">
				<tr style="position: sticky; top: 0">
					<th>카드명</th>
					<th>할인 금액</th>
				</tr>
				<tr class="cur_p" @click="cardResetFnc">
					<td class="mainBlack">미적용</td>
					<td class="txAnC">0원</td>
				</tr>
				<tr
					@click="cardPopFnc(item)"
					class="cur_p"
					v-if="codeList.discountCardItemList.length > 0"
					v-for="(item, index) in codeList.discountCardItemList"
					:key="index"
				>
					<td class="mainBlack">{{ item.cardName }}</td>
					<td class="txAnC">
						{{
							item.cardDiscountList.length > 0
								? `월 ${String(item.cardDiscountList[0].disMoney).replace(
										/\B(?=(\d{3})+(?!\d))/g,
										',',
								  )}원 ~`
								: ''
						}}
					</td>
				</tr>
				<tr v-else style="cursor: default">
					<td class="txAnC" colspan="20">데이터가 없습니다.</td>
				</tr>
			</table>
		</div>
		<!--		<div class="disFx justify-end mt10 mb20">
			<button
				class="backColorBlue2 mainWhite padW30 lh40 borderRadi3Px font-size-16"
				style="font-weight: bolder"
			>
				적용
			</button>
		</div>-->

		<pre-card-pop v-if="prePopDialog"></pre-card-pop>
	</div>
</template>

<script>
import PreCardPop from './card-Pop/PreCardPop.vue';

export default {
	name: 'PreDiscountSelect',
	components: {
		PreCardPop,
	},
	computed: {
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.discountDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.discountDialog = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		prePopDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.prePopDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.prePopDialog = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		cardItem: {
			get() {
				return this.$store.state.ChargeCalculatorModule.cardItem;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.cardItem = newValue;
			},
		},
		preInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preInsertDto = newValue;
			},
		},
	},
	methods: {
		async getCardList() {
			let param = {
				cardType: 'PRE_DISCOUNT',
				telecom_id: this.formData.telecomId,
			};
			await this.$store.dispatch('ChargeCalculatorModule/getCardList', param);
		},
		cardPopFnc(item) {
			this.prePopDialog = true;
			this.cardItem = item;
		},
		cardResetFnc() {
			this.preInsertDto.cardFormData = null;
			this.preInsertDto.cardItem = null;
			this.formData.preCardAmt = 0;
			this.dialog = false;
		},
	},
	async created() {
		await this.getCardList();
	},
};
</script>

<style scoped></style>
