import Vue from 'vue';
import Vuex from 'vuex';
import {
	commonCodeEnumList,
	commonCodeList,
	getCapacityList,
	getDeviceColorList,
	getGoodsSelectList,
	getOpeningStoreSelectList,
	getTelecomChargeList,
	getChargeList,
} from '@/api/common/common';
import {
	insertConsult,
	updateConsult,
	getTodoList,
	getMainList,
	checkDuplicationConsult,
	consultTodoGroupCnt,
	consultGroupCnt,
	deleteConsultTodo,
	authConsultStatus,
	consultStatusList,
	insertConsultTodo,
	consultTodoStatusGroup,
	consultStatusGroupList,
	insertCreditConsult,
	getStoreConsultSettingList,
} from '@/api/sales-mgmt/CustomerConsultApi';
import { getBlackDetailList } from '@/api/supply-chain-mgmt/black-list/BlackListApi';
import { consultDetail } from '@/api/sales-mgmt/SalesMgmtApi';
import Login from '@/views/member/Login';

Vue.use(Vuex);

const CustomerConsultModule = {
	namespaced: true,
	state: {
		excelPopDialog: false,
		creditInquireChkDetail: 'N',
		allCheckedBox: '',
		checkListTodoIdArray: [],
		resetData: false,
		todoResetData: false,
		consultDialog: false,
		applDialog: false,
		todoTotalCnt: 0,
		filterData: {
			dateTimeOrder: '',
			consultCountOrder: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		todoFilterData: {
			dateTimeOrder: '',
		},
		formDataDetail: {
			consultCustomerDto: {
				cusName: null,
				cusPhone1: null,
				cusPhone2: null,
				cusPhone3: null,
				cusRegiNum1: null,
				cusRegiNum2: null,
				bizName: null,
				bizNum: null,
				bizPhone: null,
				bizPhone1: null,
				bizPhone2: null,
				bizPhone3: null,
				bizRegiNum1: null,
				bizRegiNum2: null,
				courtProctorName: null,
				courtProctorPhone1: null,
				courtProctorPhone2: null,
				courtProctorPhone3: null,
				courtProctorRegiNum1: null,
				courtProctorRegiNum2: null,
				cusAddr: null,
				cusAddrDetail: null,
				licenseExpiredDate: null,
				licenseIssueArea: null,
				licenseIssueDate: null,
				licenseNum1: null,
				licenseNum2: null,
				licenseNum3: null,
				ntnlCode: null,
				stayCode: null,
				regiDatetime: null,
				firstConsultName: null,
				firstConsultStatus: null,
				firstConsultStatusMsg: null,
				orgName: null,
				storeName: null,
				regiUserName: null,
				regiMemberName: null,
				existTelecomCodeId: null,
				existGoodsId: null,
				existChargeId: null,
				openingTelecomCodeId: null,
			},
			consultTodoDto: {
				toDoContents: null,
				reservationDateTime: null,
				notiYn: 'N',
			},
			consultMemoDto: {
				memoContents: null,
			},
			consultStatus: null,
			existTelecomCodeId: '',
			openingStoreId: null,
			joinType: null,
			cusType: null,
			creditInquireDto: {},
			creditInquireResponseDto: {},
		},
		consultTodoDto: {
			toDoContents: null,
			reservationDateTime: null,
			notiYn: 'N',
		},
		formData: {
			consultCustomerDto: {
				cusName: null,
				cusPhone1: null,
				cusPhone2: null,
				cusPhone3: null,
				cusRegiNum1: null,
				cusRegiNum2: null,
				bizName: null,
				bizNum: null,
				bizPhone: null,
				bizPhone1: null,
				bizPhone2: null,
				bizPhone3: null,
				bizRegiNum1: null,
				bizRegiNum2: null,
				courtProctorName: null,
				courtProctorPhone1: null,
				courtProctorPhone2: null,
				courtProctorPhone3: null,
				courtProctorRegiNum1: null,
				courtProctorRegiNum2: null,
				cusAddr: null,
				cusAddrDetail: null,
				licenseExpiredDate: null,
				licenseIssueArea: null,
				licenseIssueDate: null,
				licenseNum1: null,
				licenseNum2: null,
				licenseNum3: null,
				ntnlCode: null,
				stayCode: null,
				regiDatetime: null,
				firstConsultName: null,
				firstConsultStatus: null,
				firstConsultStatusMsg: null,
				orgName: null,
				storeName: null,
				regiUserName: null,
				regiMemberName: null,
				existTelecomCodeId: null,
				existGoodsId: null,
				existChargeId: null,
			},
			consultTodoDto: {
				toDoContents: null,
				reservationDateTime: null,
				notiYn: 'N',
			},
			consultMemoDto: {
				memoContents: null,
			},
			consultStatus: 'NEW_RESERVATION',
			existTelecomCodeId: '',
			openingStoreId: null,
			joinType: null,
			cusType: null,
			creditInquireDto: {},
			creditInquireResponseDto: {},
			creditInquireChk: 'N',
			skipProc: 'N',
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		codeList: {
			InstallmentsPeriodType: [],
			ChargeReductType: [],
			filteredExistTelList: [],
			customerTypeItems: [],
			joinTypeItems: [],
			procStatusItems: [],
			searchTypeItems: [],
			nationalItems: [],
			stayCodeItems: [],
			existTelecomList: [],
			openStoreList: [],
			copyOpenStoreList: [],
			chargeList: [],
			deviceList: [],
			hopeDeviceList: [],
			capacityList: [],
			colorList: [],
			openingTaskStatusList: [],
			consultStatus: [
				{ name: '신규(예약)', value: 'NEW_RESERVATION' },
				{ name: '가망/2차', value: 'PROSPECT_SECOND' },
				{ name: '신조요청', value: 'CREDIT_REQUEST' },
				{ name: '거절/불가', value: 'REJECT_IMPOSSIBLE' },
				{ name: '블랙리스트', value: 'BLACKLIST' },
				/*{ name: '신조완료', value: 'CREDIT_COMPLETE' },*/
			],
			consultStatusDetail: [],
			todoFirstStatusList: [],
			todoSecondStatusList: [],
			firstStatusList: [],
			secondStatusList: [],
			settingTelecomList: [],
		},
		cusSelectArr: {
			selectTelecom: 0,
			parent_hierarchy: null,
		},
		cusSelectArrDetail: {
			selectTelecom: 0,
			parent_hierarchy: null,
		},
		todoList: [],
		mainList: [],
		// memoTabValue: 'ALL',
		historyList: [],
		duplCnt: '0',
		blackCnt: '0',
		todoTitleCntDto: [],
		mainTitleCntDto: [],
		consultDisableType: false,
		applInsertTempId: null,
	},
	mutations: {
		setTodoList(state, result) {
			state.todoList = result || [];
		},
		setMainList(state, result) {
			state.mainList = result.resultList || [];
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setCommonCodeList(state, result) {
			// 필터용 기존 통신사
			let fullData = [...result.EXISTELECOM];
			state.codeList.filteredExistTelList = fullData || [];
			// 기존 통신사
			const exTel = { codeNm: '알뜰폰', codeSeq: '1' };
			state.codeList.existTelecomList = result.EXISTELECOM.splice(0, 4) || [];
			state.codeList.thriftyPhoneItems = result.EXISTELECOM || [];
			state.codeList.existTelecomList.push(exTel);
		},
		setTelecomChargeList(state, result) {
			state.codeList.chargeList = result || [];
		},
		setCommonEnumList(state, result) {
			state.codeList.openingTaskStatusList = result.data.data.OpeningTaskStatus;
			state.codeList.customerTypeItems = result.data.data.CustomerType;
			state.codeList.joinTypeItems = result.data.data.CreditJoinType;
			state.codeList.procStatusItems = result.data.data.CreditProcStatus;
			state.codeList.searchTypeItems = result.data.data.CreditSearchType;
			state.codeList.nationalItems = result.data.data.NationalCode;
			state.codeList.stayCodeItems = result.data.data.StayCode;
			state.codeList.InstallmentsPeriodType =
				result.data.data.InstallmentsPeriodType;
			state.codeList.ChargeReductType = result.data.data.ChargeReductType;

			state.codeList.openingTaskStatusList.unshift({
				name: '개통요청(전체)',
				value: 'OPENING_REQ_ALL',
			});
			if (
				state.codeList.openingTaskStatusList !== null ||
				state.codeList.openingTaskStatusList !== undefined
			) {
				state.codeList.openingTaskStatusList.map(item => {
					item.name =
						item.name !== null ? item.name.replace('(개통)', '') : null;
					return item;
				});
				/*this.statusList.OpeningTaskStatus.splice(2, 0, { divider: true });*/
			}
		},
		setOpeningStoreSelectList(state, result) {
			result.map(item => {
				let telName = '';
				switch (item.telecom) {
					case 5: // SKT
						telName = '[SKT] ';
						break;
					case 6: // KT
						telName = '[KT] ';
						break;
					case 7: // LGU
						telName = '[LGU] ';
						break;
					default:
						telName = '';
				}
				item.openStoreName = telName + item.openStoreName;
			});
			state.codeList.copyOpenStoreList = result || [];
			state.codeList.openStoreList = result || [];
		},
		setGoodsSelectList(state, result) {
			result = result.filter(i => i.useYn === 'Y');
			result = result.filter(i => i.goodsType === 'DVC');
			state.codeList.deviceList = result || [];
		},
		setHopeGoodsSelectList(state, result) {
			result = result.filter(i => i.useYn === 'Y');
			result = result.filter(i => i.goodsType === 'DVC');
			state.codeList.hopeDeviceList = result || [];
		},
		setCapacityList(state, result) {
			state.codeList.capacityList = result || [];
		},
		setDeviceColorList(state, result) {
			state.codeList.colorList = result || [];
		},
		setCheckDuplicationConsult(state, result) {
			state.duplCnt = String(result ? result : '0');
		},
		setBlackDetailList(state, result) {
			state.blackCnt = String(result ? result.length : '0');
		},
		setTodoStatusCntList(state, result) {
			state.todoTitleCntDto = result;
		},
		setConsultGroupCnt(state, result) {
			let second = result.consultSecondGroupList.find(
				i => !i.secondConsultStatus,
			);
			if (second) {
				let item = result.consultSecondGroupList.pop();
				result.consultSecondGroupList.unshift(item);
				second.secondConsultStatus = 0;
				second.secondConsultStatusMsg = '미진행';
			}
			state.mainTitleCntDto = result;
		},
		setConsultDetail(state, result) {
			// result.consultDto = Object.assign(result.consultDto, result.consultCustomerDto)
			state.formDataDetail = result.consultDto || [];
			state.formDataDetail.consultCustomerDto = result.consultCustomerDto || [];
			state.formDataDetail.creditInquireDto = result.creditInquireDto || null;

			if (result.consultTodoDto) {
				if (result.consultTodoDto.reservationDateTime !== null) {
					result.consultTodoDto.time = this._vm
						.$moment(result.consultTodoDto.reservationDateTime)
						.format('HH:mm');
					result.consultTodoDto.reservationDateTime = this._vm
						.$moment(result.consultTodoDto.reservationDateTime)
						.format('YYYY-MM-DD');
				}
			}
			state.consultTodoDto = result.consultTodoDto || [];

			// 신조결과 Parse
			if (result.creditInquireResponseDto) {
				result.creditInquireResponseDto.creditInquireResult = JSON.parse(
					result.creditInquireResponseDto.creditInquireResult.replace(
						/&quot;/g,
						'"',
					),
				);
			}
			state.formDataDetail.creditInquireResponseDto =
				result.creditInquireResponseDto || null;
		},
		setAuthConsultStatus(state, result) {
			/*state.codeList.consultStatus =
				result.consultantDtoList[0].processTypeDtoList;*/
		},
		setConsultStatusList(state, result) {
			state.codeList.consultStatus =
				result.consultantDtoList[0].processTypeDtoList;
		},
		setConsultStatusListDetail(state, result) {
			let creChk = false;
			state.codeList.consultStatusDetail = [];
			result.consultantDtoList[0].processTypeDtoList.forEach(itemPro => {
				state.codeList.consultStatusDetail.push({
					header: '[' + itemPro.consultProcessTypeMsg + ']',
				});
				itemPro.consultStatusDtoList.forEach(itemSta => {
					itemSta.statusName =
						'[' + itemSta.consultProcessTypeMsg + '] ' + itemSta.statusName;
					state.codeList.consultStatusDetail.push(itemSta);
					if (
						state.formDataDetail.firstConsultStatus === itemSta.cnsltStatusId
					) {
						creChk = true;
					}
				});
			});
			if (!creChk) {
				if (
					state.formDataDetail.cnsltId &&
					state.formDataDetail.secondConsultName !== null &&
					state.formDataDetail.secondConsultStatusId === null
				) {
					state.codeList.consultStatusDetail.push({
						cnsltStatusId: null,
						statusName: '2차 미진행',
						disabled: true,
					});
				} else if (
					state.formDataDetail.cnsltId &&
					state.formDataDetail.secondConsultName !== null
				) {
					let secondConsultProcessTypeMsg = '';
					if (state.formDataDetail.secondConsultProcessTypeMsg !== null) {
						secondConsultProcessTypeMsg =
							'[' + state.formDataDetail.secondConsultProcessTypeMsg + '] ';
					}
					state.codeList.consultStatusDetail.push({
						cnsltStatusId: state.formDataDetail.secondConsultStatusId,
						statusName:
							secondConsultProcessTypeMsg +
							state.formDataDetail.secondConsultStatusName,
						disabled: true,
					});
				} else if (
					state.formDataDetail.cnsltId &&
					state.formDataDetail.secondConsultName === null &&
					state.formDataDetail.secondConsultStatusId === null
				) {
					let firstConsultProcessTypeMsg = '';
					if (state.formDataDetail.firstConsultProcessTypeMsg !== null) {
						firstConsultProcessTypeMsg =
							'[' + state.formDataDetail.firstConsultProcessTypeMsg + '] ';
					}
					state.codeList.consultStatusDetail.push({
						cnsltStatusId: state.formDataDetail.firstConsultStatusId,
						statusName:
							firstConsultProcessTypeMsg +
							state.formDataDetail.firstConsultStatusName,
						disabled: true,
					});
					state.formDataDetail.consultStatus;
				} else if (
					state.formDataDetail.cnsltId &&
					state.formDataDetail.secondConsultName === null &&
					state.formDataDetail.firstConsultName !== null
				) {
					let firstConsultProcessTypeMsg = '';
					if (state.formDataDetail.firstConsultProcessTypeMsg !== null) {
						firstConsultProcessTypeMsg =
							'[' + state.formDataDetail.firstConsultProcessTypeMsg + '] ';
					}
					state.codeList.consultStatusDetail.push({
						cnsltStatusId: state.formDataDetail.firstConsultStatusId,
						statusName:
							firstConsultProcessTypeMsg +
							state.formDataDetail.firstConsultStatusName,
						disabled: true,
					});
				}
			}
		},
		setConsultTodoStatusGroup(state, result) {
			let first =
				result.consultTodoFirstTodoStatusList.forEach(item => {
					item.statusName =
						'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
				}) || [];
			let second =
				result.consultTodoSecondTodoStatusList.forEach(item => {
					item.statusName =
						'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
				}) || [];

			state.codeList.todoFirstStatusList =
				result.consultTodoFirstTodoStatusList || [];
			state.codeList.todoSecondStatusList =
				result.consultTodoSecondTodoStatusList.filter(i => i.cnsltStatusId);
		},
		setConsultStatusGroupList(state, result) {
			let first = result.consultFirstStatusList.forEach(item => {
				item.statusName =
					'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
			});
			let second = result.consultSecondStatusList.forEach(item => {
				item.statusName =
					'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
			});
			state.codeList.firstStatusList = result.consultFirstStatusList || [];
			state.codeList.secondStatusList = result.consultSecondStatusList.filter(
				i => i.cnsltStatusId,
			);
		},
		setStoreConsultSettingList(state, result) {
			state.codeList.settingTelecomList = [];
			result.consultSettingDtoList.forEach(item => {
				let telecomObj = {};
				if (!item.openingStoreName) {
					item.openingStoreName = '';
				}
				switch (item.openingTelecomCodeId) {
					case 5: // SKT
						telecomObj.openStoreName = '[SKT] ' + item.openingStoreName;
						break;
					case 6: // KT
						telecomObj.openStoreName = '[KT] ' + item.openingStoreName;
						break;
					case 7: // LGU
						telecomObj.openStoreName = '[LGU] ' + item.openingStoreName;
						break;
					default:
						telecomObj.openStoreName = '';
				}
				telecomObj.value = item.openingTelecomCodeId;
				telecomObj.openStoreId = item.openingStoreId;
				telecomObj.parentHierarchy = item.parentHierarchy;
				telecomObj.defaultYn = item.defaultYn;
				state.codeList.settingTelecomList.push(telecomObj);
			});
		},
	},
	actions: {
		async getTodoList({ commit }, data) {
			try {
				let param = {
					consultType: 'CONSULT',
					param: data,
				};
				let result = await getTodoList(param);
				if (result) {
					commit('setTodoList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoGroupCnt({ commit }, data) {
			try {
				let param = {
					consultType: 'CONSULT',
					param: data,
				};
				let result = await consultTodoGroupCnt(param);
				if (result) {
					commit('setTodoStatusCntList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultGroupCnt({ commit }, data) {
			try {
				let paramData = {};
				if (data) {
					paramData = {
						srhStartDate: data.srhStartDate || '',
						srhEndDate: data.srhEndDate || '',
					};
				}
				let result = await consultGroupCnt(paramData);
				if (result) {
					commit('setConsultGroupCnt', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMainList({ commit }, data) {
			try {
				let result = await getMainList(data);
				if (result) {
					commit('setMainList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async commonCodeList({ commit }) {
			const Enum = { code: ['existelecom'] };
			let result = await commonCodeList(Enum);
			if (result) {
				commit('setCommonCodeList', result.data.data.codeList);
			}
		},
		async commonCodeEnumList({ commit }) {
			const data = {
				initEnumData: [
					'OpeningTaskStatus',
					'CreditJoinType',
					'CustomerType',
					'CreditOwnType',
					'CreditReqType',
					'CreditProcStatus',
					'CreditHistoryStatus',
					'CreditSearchType',
					'NationalCode',
					'StayCode',
					'InstallmentsPeriodType',
					'ChargeReductType',
				],
			};
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setCommonEnumList', result);
			}
		},
		async getTelecomChargeList({ commit }, data) {
			try {
				const result = await getTelecomChargeList(data);
				if (result) {
					commit('setTelecomChargeList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getOpeningStoreSelectList({ commit }, data) {
			try {
				let param = { storeId: data };
				const result = await getOpeningStoreSelectList(param);
				if (result) {
					commit('setOpeningStoreSelectList', result.data.data);
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getGoodsSelectList({ commit }, data) {
			try {
				const result = await getGoodsSelectList(data);
				if (result) {
					commit('setGoodsSelectList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getHopeGoodsSelectList({ commit }, data) {
			try {
				const result = await getGoodsSelectList(data);
				if (result) {
					commit('setHopeGoodsSelectList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getCapacityList({ commit }, data) {
			try {
				const result = await getCapacityList(data);
				if (result && result.data.resultCode === '0000') {
					commit('setCapacityList', result.data.data);
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getDeviceColorList({ commit }, data) {
			try {
				const result = await getDeviceColorList(data);
				if (result) {
					commit('setDeviceColorList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsult({ commit }, data) {
			try {
				const result = await insertConsult(data);
				if (result.data.status === 200) {
					if (
						result.data.resultCode !== '0000' &&
						result.data.resultCode !== '5500'
					) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return result.data;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateConsult({ commit }, data) {
			try {
				const result = await updateConsult(data);
				if (result.data.status === 200) {
					if (
						result.data.resultCode !== '0000' &&
						result.data.resultCode !== '5500'
					) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return result.data;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async checkDuplicationConsult({ commit }, data) {
			try {
				const result = await checkDuplicationConsult(data);
				if (result) {
					commit('setCheckDuplicationConsult', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBlackDetailList({ commit }, data) {
			try {
				for (let item in data) {
					if (!data[item]) {
						data[item] = '';
					}
				}
				const result = await getBlackDetailList(data);
				if (result) {
					commit('setBlackDetailList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteConsultTodo({ commit }, data) {
			try {
				const result = await deleteConsultTodo(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultDetail({ commit }, data) {
			try {
				const result = await consultDetail(data);
				commit('setConsultDetail', result.data.data);
			} catch (e) {
				console.log(e);
			}
		},
		async authConsultStatus({ commit }, data) {
			try {
				const result = await authConsultStatus(data);
				if (result) {
					commit('setAuthConsultStatus', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultStatusList({ commit }) {
			try {
				const data = {
					consultantType: 'CONSULT_FIRST',
				};
				const result = await consultStatusList(data);
				if (result) {
					commit('setConsultStatusList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultStatusListDetail({ commit }, data) {
			try {
				const result = await consultStatusList(data);
				if (result) {
					commit('setConsultStatusListDetail', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultTodo({ commit }, data) {
			try {
				const result = await insertConsultTodo(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoStatusGroup({ commit }, param) {
			try {
				let data = {
					consultType: 'CONSULT',
					param: param,
				};
				const result = await consultTodoStatusGroup(data);
				if (result) {
					commit('setConsultTodoStatusGroup', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultStatusGroupList({ commit }, data) {
			try {
				const result = await consultStatusGroupList(data);
				if (result) {
					commit('setConsultStatusGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 상담 신용조회 요청
		async insertCreditConsult({ commit }, data) {
			try {
				const result = await insertCreditConsult(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 고객상담 통신사/개통점 목록
		async getStoreConsultSettingList({ commit }, data) {
			try {
				const result = await getStoreConsultSettingList(data);
				if (result) {
					commit('setStoreConsultSettingList', result.data.data);
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 고객상담 통신사/개통점 목록 - 상담 신규등록
		async getStoreConsultSettingListRegiDetail({ commit }, data) {
			try {
				const result = await getStoreConsultSettingList(data);
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default CustomerConsultModule;
