
import Vue from 'vue';
import {
	appCommonFormData,
	basicFormData,
	customerFormData,
	deliveryFormData,
	joinFormData,
	paymentFormData,
} from '../../../store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import addService from '../../../components/addService.vue';
import deviceEnumMixin from '../../../common/deviceEnumMixin.js';
import numberSplit from '../../../common/numberSplit';
import matchingInput from '../../../components/matchingInput.vue';
//https://phoenixwong.github.io/vue2-timepicker/
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import { objectClone } from '../../../common/common';
import NumRepMixin from '../../../common/NumRepMixin.js';
import focusMixin from '../../../common/focusMixin.js';

interface dataType {
	prevApplId: number;
	phoneNumDialog: boolean;
	Interest: number;
	menu1: boolean;
	menu2: boolean;
	menu3: boolean;
	instData: number;
	textDialog: boolean;
	calcFlag: boolean;
}

export default Vue.extend({
	name: 'JoinInformation',
	mixins: [deviceEnumMixin, numberSplit, NumRepMixin, focusMixin],
	components: { addService, matchingInput, VueTimepicker },
	data: (): dataType => ({
		prevApplId: 28177,
		phoneNumDialog: false,
		Interest: 0.059, //이자 - 이자 변경시 이거 수정하면됩니다.
		menu1: false,
		menu2: false,
		menu3: false,
		instData: 0,
		textDialog: false,
		calcFlag: false,
	}),
	computed: {
		AppFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFlag = newValue;
			},
		},
		storeVal(): string {
			return this.$store.state.cookies.sg;
		},
		AppFormCreFlag(): string {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		deviceChange: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.deviceChange;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.deviceChange = newValue;
			},
		},
		usimChange: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.usimChange;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.usimChange = newValue;
			},
		},
		AppFormCreFlagChk(): boolean {
			return !!this.AppFormCreFlag;
		},
		addServiceFormData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule
					.addServiceFormData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.addServiceFormData =
					newValue;
			},
		},
		sct(): string {
			return this.$store.state.cookies.sct;
		},
		joinData: {
			get(): joinFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.join;
			},
			set(newValue: joinFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.join =
					newValue;
			},
		},
		basicData: {
			get(): basicFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.basic;
			},
			set(newValue: basicFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.basic =
					newValue;
			},
		},
		thriftyPhone: {
			get(): number {
				return this.$store.state.ApplicationFormCreationModule.thriftyPhone;
			},
			set(newValue: number) {
				this.$store.state.ApplicationFormCreationModule.thriftyPhone = newValue;
			},
		},
		customerData: {
			get(): customerFormData {
				return this.$store.state.ApplicationFormCreationModule.formData
					.customer;
			},
			set(newValue: customerFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.customer =
					newValue;
			},
		},
		deliveryData: {
			get(): deliveryFormData {
				return this.$store.state.ApplicationFormCreationModule.formData
					.delivery;
			},
			set(newValue: deliveryFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.delivery =
					newValue;
			},
		},
		paymentCheckType: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.paymentCheckType;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.paymentCheckType =
					newValue;
			},
		},
		openingStoreData(): number {
			return this.$store.state.ApplicationFormCreationModule.formData.basic
				.openingStoreId;
		},
		codeListItems(): any {
			return this.$store.state.ApplicationFormCreationModule.codeList;
		},
		callingPlanItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.callingPlanItems;
		},
		deviceItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.deviceItems;
		},
		joinTypeItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.joinTypeItems;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.joinTypeItems =
					newValue;
			},
		},
		networkCodeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.networkCodeItems;
		},
		numChangeAuthTypeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.numChangeAuthTypeItems;
		},
		usimPaymentTypeItems: {
			get(): Array<Object> {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.usimPaymentTypeItems;
			},
			set(newValue: Array<Object>) {
				this.$store.state.ApplicationFormCreationModule.codeList.usimPaymentTypeItems =
					newValue;
			},
		},
		usimPaymentTypeItemsCopy: {
			get(): Array<Object> {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.usimPaymentTypeItemsCopy;
			},
			set(newValue: Array<Object>) {
				this.$store.state.ApplicationFormCreationModule.codeList.usimPaymentTypeItemsCopy =
					newValue;
			},
		},
		agreementPeriodTypeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.agreementPeriodTypeItems;
		},
		installmentsPeriodTypeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.installmentsPeriodTypeItems;
		},
		formData: {
			get(): appCommonFormData {
				return this.$store.state.ApplicationFormCreationModule.formData;
			},
			set(newValue: appCommonFormData) {
				this.$store.state.ApplicationFormCreationModule.formData = newValue;
			},
		},
		paymentData: {
			get(): paymentFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.payment;
			},
			set(newValue: paymentFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.payment =
					newValue;
			},
		},
		goodsItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.goodsItems;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.goodsItems =
					newValue;
			},
		},
		goodsItemsCopy: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.goodsItemsCopy;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.goodsItemsCopy =
					newValue;
			},
		},
		capacityItems: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.capacityItems;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.capacityItems =
					newValue;
			},
		},
		colorItems: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.colorItems;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.colorItems =
					newValue;
			},
		},
		telecomAddServiceItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.telecomAddServiceItems;
		},
		telecomChargeItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.telecomChargeItems;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.telecomChargeItems =
					newValue;
			},
		},
		telecomChargeItemsCopy: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.telecomChargeItemsCopy;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.telecomChargeItemsCopy =
					newValue;
			},
		},
		existTelecomItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.existTelecomItems;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.existTelecomItems =
					newValue;
			},
		},
		thriftyPhoneItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.thriftyPhoneItems;
		},
		ApplExchangeFlag(): Object {
			return this.$store.state.ApplicationFormCreationModule.ApplExchangeFlag;
		},
		templateSeq(): any {
			return this.$store.state.ApplicationFormCreationModule.templateSeq;
		},
		joinType(): any {
			return this.$store.state.ApplicationFormCreationModule.formData.join
				.joinType;
		},
		delChargePayInfo(): any {
			return this.$store.state.ApplicationFormCreationModule.delChargePayInfo;
		},
	},
	methods: {
		clearFnc() {
			this.basicData.openingDate = '';
		},
		openingPhoneNumFnc(value: string) {
			this.joinData.openingPhone1 = value.substring(0, 3);
			this.joinData.openingPhone2 = value.substring(3, 7);
			this.joinData.openingPhone3 = value.substring(7, 11);
		},
		resetDate(data: string) {
			if (data === 'openingDate') {
				this.basicData.openingDate = '';
			}
			if (data === 'cancelDate') {
				this.basicData.cancelDate = '';
			}
		},
		numChangeAuthTypeFnc() {
			if (this.joinData.numChangeAuthType === 'GIRO') {
				this.joinData.numChangeAuthTypeNum = null;
			}
			this.joinData.numChangeAuthTypeNum = null;
		},
		chargeIdFnc() {
			if (this.joinData.goodsId === null) {
				alert('기기를 검색해주세요.');
				return;
			}
		},
		exTelFnc() {
			if (this.joinData.joinType === 'NEW') {
				this.joinData.openingHopeNum = '';
			}
			let exTel = this.joinData.existTelecomCodeId;
			if (exTel === 42 || exTel === 43 || exTel === 44 || exTel === 45) {
				this.thriftyPhone = 0;
			}
			if (
				(this.joinData.openingTelecomName === 'SKT' && exTel === 42) ||
				(this.joinData.openingTelecomName === 'KT' && exTel === 43) ||
				(this.joinData.openingTelecomName === 'LGU' && exTel === 44)
			) {
				this.joinData.joinType = 'DVC_CHANGE';
			} else if (exTel === 45) {
				this.joinData.joinType = 'NEW';
				this.paymentCheckType = false;
				this.paymentData.paymentType = 'ACC_TRNS';
				this.basicData.applType = 'DVC_USIM';
				this.usimPaymentTypeCheck();
			} else {
				switch (this.joinData.existTelecomCodeId) {
					case 42:
						this.joinData.joinType = 'NUM_CHANGE_S';
						break;
					case 43:
						this.joinData.joinType = 'NUM_CHANGE_K';
						break;
					case 44:
						this.joinData.joinType = 'NUM_CHANGE_L';
						break;
					case 1:
						this.joinData.joinType = 'NUM_CHANGE_V';
						break;
				}
				this.paymentCheckType = false;
				this.paymentData.paymentType = 'ACC_TRNS';
				this.basicData.applType = 'DVC_USIM';
				this.usimPaymentTypeCheck();
			}
			this.joinData.numChangeAuthType = null;
			this.joinData.numChangeAuthTypeNum = null;
		},
		async agreementPeriodTypeFnc() {
			if (this.joinData.agreementType !== 'CHOICE_AGRMN') {
				this.joinData.pubNotiSupportAmt = 0;
			}
			if (this.joinData.goodsId === null || this.joinData.chargeId === null) {
				return;
			}
			let data = {
				chargeId: this.joinData.chargeId,
				goodsId: this.joinData.goodsId,
			};
			await this.getPubNotiInfo(data);
			await this.actualSellAmtFnc();
		},
		async telecomChargeItemsData(value: null | number) {
			this.joinData.releaseAmt = 0;
			this.joinData.releaseAmtDate = null;
			this.joinData.pubNotiSupportAmt = 0;
			this.joinData.monthBasicAmt = 0;
			this.joinData.chargeId = value;
			if (this.joinData.chargeId) {
				let data = {
					chargeId: this.joinData.chargeId,
					goodsId: this.joinData.goodsId,
				};
				let findCharge = this.telecomChargeItems.find(
					(item: any) => item.chargeId === this.joinData.chargeId,
				);
				if (findCharge.delYn === 'Y') {
					this.joinData.pubNotiId = this.delChargePayInfo.pubNotiId;
					this.joinData.releaseAmt = this.delChargePayInfo.releaseAmt;
					this.joinData.actualSellAmt = this.delChargePayInfo.actualSellAmt;
					this.joinData.releaseAmtDate = this.delChargePayInfo.releaseAmtDate;
					this.joinData.monthBasicAmt = this.delChargePayInfo.monthBasicAmt;
					this.joinData.pubNotiSupportAmt =
						this.delChargePayInfo.pubNotiSupportAmt;
					this.joinData.monthDvcAmt = this.delChargePayInfo.monthDvcAmt;
					this.joinData.monthPaymentAmt = this.delChargePayInfo.monthPaymentAmt;
					this.joinData.instlPrincipalAmt =
						this.delChargePayInfo.instlPrincipalAmt;
				} else {
					await this.getPubNotiInfo(data);
					if (
						this.joinData.instlPeriodType &&
						this.joinData.agreementPeriodType
					) {
						await this.actualSellAmtFnc();
						await this.monthDvcAmtFnc();
						await this.monthPaymentAmtFnc();
					}
				}
			}
		},
		phoneNumFnc() {
			// @ts-ignore
			if (this.phoneNumDialog && this.customerData.cusPhone) {
				// @ts-ignore
				this.joinData.openingPhone = this.customerData.cusPhone;
				this.joinData.openingPhone1 = this.customerData.cusPhone1;
				this.joinData.openingPhone2 = this.customerData.cusPhone2;
				this.joinData.openingPhone3 = this.customerData.cusPhone3;
			} else {
				// @ts-ignore
				this.joinData.openingPhone = '';
				this.joinData.openingPhone1 = '';
				this.joinData.openingPhone2 = '';
				this.joinData.openingPhone3 = '';
			}
		},
		alertFnc() {
			if (this.openingStoreData === 0) {
				return alert('영업점 및 개통점을 선택해주세요.');
			}
		},
		resetJoinTypeFnc() {
			this.joinData.numChangeAuthType = null; // 인증방식
			this.joinData.numChangeAuthTypeNum = null; // 인증번호

			if (
				this.joinType &&
				(this.joinType === 'NEW' ||
					this.joinType === 'NUM_CHANGE_S' ||
					this.joinType === 'NUM_CHANGE_K' ||
					this.joinType === 'NUM_CHANGE_L' ||
					this.joinType === 'NUM_CHANGE_V')
			) {
				this.basicData.applType = 'DVC_USIM';
			}
		},
		async getPubNotiInfo(data: any) {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getPubNotiInfo',
				data,
			);
		},
		// 실판매가 공식 = 출고가 - 공시지원금 - 추가지원금 - 프리할부/선수납 - 기타할부금
		actualSellAmtFnc() {
			this.joinData.releaseAmt = Number(this.joinData.releaseAmt);
			if (this.joinData.agreementType === 'PUBNOTI_SUPAMT') {
				if (
					this.joinData.pubNotiSupportAmt * 0.15 <
					this.joinData.extraSupportAmt
				) {
					alert('추가지원금은 공시지원금액의 15%를 초과 할 수 없습니다.');
					this.joinData.extraSupportAmt = 0;
				}
			}
			if (this.joinData.instlPeriodType) {
				this.joinData.actualSellAmt =
					this.joinData.releaseAmt -
					this.joinData.pubNotiSupportAmt -
					this.joinData.extraSupportAmt -
					this.joinData.freeInstlAmt -
					Number(this.joinData.etcDiscountAmt);
				if (!this.calcFlag) {
					// 할부기간변경시 실판매가 처리 분기
					this.monthDvcAmtFnc();
				} else {
					this.calcFlag = false;
				}
			}
		},
		// 월기기금액 공식 = (실판매가*(이자/12)*(1+(이자/12))^이자)/((1+(이자/12))^할부기간-1)
		monthDvcAmtFnc() {
			if (
				this.joinData.chargeId === 1472 ||
				this.joinData.chargeId === 1473 ||
				this.joinData.chargeId === 1474
			) {
				return;
			}
			if (this.joinData.instlPeriodType === 'CASH') {
				this.joinData.freeInstlAmt = 0;
			}
			this.textDialog = true;
			let join = this.joinData;
			let Int12 = this.Interest / 12;
			let insP = this.joinData.instlPeriodType;
			if (insP === 'CASH') {
				join.monthDvcAmt = 0;
				this.instData = 0;
				this.textDialog = false;
				this.joinData.instlPrincipalAmt = 0;
			} else {
				this.joinData.instlPrincipalAmt =
					this.joinData.releaseAmt -
					this.joinData.pubNotiSupportAmt -
					this.joinData.extraSupportAmt -
					this.joinData.freeInstlAmt -
					Number(this.joinData.etcDiscountAmt);
			}
			if (insP !== 'CASH') {
				if (this.joinData.instlPeriodType === 'MONTH_6') insP = 6;
				if (this.joinData.instlPeriodType === 'MONTH_12') insP = 12;
				if (this.joinData.instlPeriodType === 'MONTH_24') insP = 24;
				if (this.joinData.instlPeriodType === 'MONTH_30') insP = 30;
				if (this.joinData.instlPeriodType === 'MONTH_36') insP = 36;
				if (this.joinData.instlPeriodType === 'MONTH_48') insP = 48;
				if (this.joinData.instlPeriodType === 'MONTH_6') this.instData = 6;
				if (this.joinData.instlPeriodType === 'MONTH_12') this.instData = 12;
				if (this.joinData.instlPeriodType === 'MONTH_24') this.instData = 24;
				if (this.joinData.instlPeriodType === 'MONTH_30') this.instData = 30;
				if (this.joinData.instlPeriodType === 'MONTH_36') this.instData = 36;
				if (this.joinData.instlPeriodType === 'MONTH_48') this.instData = 48;
				let frontNum = join.actualSellAmt * Int12 * Math.pow(1 + Int12, insP);
				join.monthDvcAmt = Math.round(
					frontNum / (Math.pow(1 + Int12, insP) - 1),
				);
			}
			this.monthPaymentAmtFnc();
		},
		monthPaymentAmtFnc() {
			this.joinData.monthPaymentAmt =
				Number(this.joinData.monthDvcAmt) + Number(this.joinData.monthBasicAmt);
			// 부가서비스가 있을시 월 납부금액 공식
			if (this.joinData.addServiceList.length !== 0) {
				if (this.joinData.monthPaymentAmt !== 0) {
					for (let i = 0; i < this.joinData.addServiceList.length; i++) {
						this.joinData.monthPaymentAmt =
							this.joinData.monthPaymentAmt +
							this.joinData.addServiceList[i].addSvcCharge;
					}
				}
			}

			this.calcFlag = true; // 할부기간 변경시 실판매가 처리 분기
			this.actualSellAmtFnc();
		},
		resetPriceFnc() {
			this.joinData.releaseAmt = 0; // 출고가
			this.joinData.releaseAmtDate = null; // 출고가일자
			this.joinData.pubNotiSupportAmt = 0; // 공시지원금
			this.joinData.extraSupportAmt = 0; // 추가지원금
			this.joinData.freeInstlAmt = 0; // 프리할부/선수납
			this.joinData.etcDiscountAmt = 0; // 기타할인금
			this.joinData.actualSellAmt = 0; // 실판매가
			this.joinData.instlPrincipalAmt = 0; // 할부원금
			this.joinData.monthDvcAmt = 0; // 월기기금액
			this.joinData.monthBasicAmt = 0; // 월기본료
			this.joinData.monthPaymentAmt = 0; // 월납부금액
			this.joinData.depositAmt = 0; // 입금받을금액
			this.joinData.chargeId = null; // 요금제
		},
		setNewAddSvcList(itemList: any) {
			for (let i = 0; i < this.joinData.addServiceList.length; i++) {
				if (this.joinData.addServiceList[i].addSvcId === itemList.addSvcId) {
					alert('이미 포함 되어 있는 항목입니다.');
					return;
				}
			}
			this.joinData.addServiceList.push(itemList);
			if (this.joinData.monthPaymentAmt !== 0) {
				this.joinData.monthPaymentAmt =
					this.joinData.monthPaymentAmt + itemList.addSvcCharge;
			}
		},
		minusAddSvcList(item: any) {
			this.addServiceFormData.push(
				this.joinData.addServiceList.splice(item.index, 1),
			);
			if (this.joinData.monthPaymentAmt !== 0) {
				this.joinData.monthPaymentAmt =
					this.joinData.monthPaymentAmt - item.item.addSvcCharge;
			}
		},
		applTypeFnc(e: any, value: string) {
			this.basicData.applType = value;
			this.usimPaymentTypeCheck();
			if (this.basicData.applType === 'DVC') {
				this.joinData.usimRawBarcode = null;
				this.joinData.usimType = 'NANO_USIM';
			} else if (this.basicData.applType === 'USIM') {
				this.joinData.deviceRawBarcode = null;
				this.joinData.usimType = 'NANO_USIM';
			} else {
				this.joinData.usimType = 'NANO_USIM';
			}

			this.usimPaymentTypeItemsSort(); // 유심 지불 방식 목록 정렬
		},
		deviceChangeFnc(value: boolean) {
			this.deviceChange = value;
		},
		usimChangeFnc(value: boolean) {
			this.usimChange = value;
		},
		async networkChangeFnc() {
			let goodsItemsCopy = [];
			let telecomChargeItemsCopy = [];
			if (this.joinData.networkId === null) {
				this.goodsItems = objectClone(this.goodsItemsCopy);
				if (this.joinData.goodsId === null) {
					this.telecomChargeItems = objectClone(this.telecomChargeItemsCopy);
				} else {
					let netId = this.goodsItems.filter(
						(i: any) => i.goodsId === this.joinData.goodsId,
					);
					if (netId.length !== 0) {
						let telChargeItems = this.telecomChargeItemsCopy.filter(
							(i: any) =>
								i.network === netId[0].network ||
								i.chargeId === 1472 ||
								i.chargeId === 1473 ||
								i.chargeId === 1474,
						);
						this.telecomChargeItems = telChargeItems;
					}
				}
			} else {
				let goodsChk = false;
				let chargeChk = false;
				goodsItemsCopy = this.goodsItemsCopy
					.filter(
						(i: any) =>
							i.network === this.joinData.networkId && i.goodsType === 'DVC',
					)
					.map((i: any) => {
						// checked 처리
						if (i.goodsId === this.joinData.goodsId) {
							goodsChk = true;
						}
						return i;
					});
				telecomChargeItemsCopy = this.telecomChargeItemsCopy
					.filter(
						(i: any) =>
							i.network === this.joinData.networkId ||
							i.chargeId === 1472 ||
							i.chargeId === 1473 ||
							i.chargeId === 1474,
					)
					.map((i: any) => {
						// checked 처리
						if (i.chargeId === this.joinData.chargeId) {
							chargeChk = true;
						}
						return i;
					});
				this.goodsItems = goodsItemsCopy;
				this.telecomChargeItems = telecomChargeItemsCopy;
				// filter 한 데이터에 기존데이터가 없을시 reset
				if (!goodsChk) {
					this.joinData.goodsId = null;
					this.joinData.capacity = null;
					this.capacityItems = [];
					this.joinData.color = null;
					this.colorItems = [];
				}
				// filter 한 데이터에 기존데이터가 없을시 reset
				if (!chargeChk) {
					this.resetPriceFnc();
				}
			}

			if (this.joinData.networkId === null) {
				// 인기기종
				for (let j = 0; j < this.codeListItems.mostGoodsItems.length; j++) {
					this.codeListItems.mostGoodsItems[j].mostType = 'Y';
					this.goodsItems.unshift(this.codeListItems.mostGoodsItems[j]);
				}

				// 인기요금제
				let selGoodsNetFilter = this.goodsItemsCopy.filter(
					(i: any) => i.goodsId === this.joinData.goodsId,
				);
				let selGoodsNet =
					selGoodsNetFilter.length === 0 ? null : selGoodsNetFilter[0].network;
				let mostChargeItems = this.codeListItems.mostChargeItems.filter(
					(i: any) =>
						selGoodsNet !== null
							? selGoodsNet === i.network
							: this.joinData.networkId === i.network,
				);

				for (let j = 0; j < mostChargeItems.length; j++) {
					mostChargeItems[j].mostType = 'Y';
					this.telecomChargeItems.unshift(mostChargeItems[j]);
				}
			} else {
				// 인기기종
				let mostGoodsItem = this.codeListItems.mostGoodsItems.filter(
					(i: any) => this.joinData.networkId === i.network,
				);
				if (mostGoodsItem.length !== 0) {
					for (let j = 0; j < mostGoodsItem.length; j++) {
						mostGoodsItem[j].mostType = 'Y';
						this.goodsItems.unshift(mostGoodsItem[j]);
					}
				}
				// 인기요금제
				let mostChargeItems = this.codeListItems.mostChargeItems.filter(
					(i: any) => this.joinData.networkId === i.network,
				);
				for (let j = 0; j < mostChargeItems.length; j++) {
					mostChargeItems[j].mostType = 'Y';
					this.telecomChargeItems.unshift(mostChargeItems[j]);
				}
			}
			this.usimPaymentTypeCheck();
		},
		usimPaymentTypeCheck() {
			let goodsNetwork = null;
			// etcNetwork 18,19,20,21,22,23,25
			let etcNetwork = [18, 19, 20, 21, 22, 23, 25];
			if (this.joinData.goodsId !== null && this.goodsItems.length > 0) {
				goodsNetwork = this.goodsItems.find(
					(item: any) => item.goodsId === this.joinData.goodsId,
				).network;
			} else if (etcNetwork.includes(Number(this.joinData.networkId))) {
				goodsNetwork = this.joinData.networkId;
			}
			let resetYN = true;

			let value = this.joinData.usimPaymentType;
			if (
				this.basicData.applType === 'DVC' &&
				etcNetwork.includes(goodsNetwork) &&
				(value === 'EXIST_USIM' ||
					value === 'PRE_PAY' ||
					value === 'POST_PAY' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기인데 ETC 네트워크 유형인경우 [기존유심, 타사유심선납, 타사유심후납, 선납, 후납]
				resetYN = false;
			} else if (
				this.basicData.applType === 'DVC' &&
				(value === 'EXIST_USIM' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기인경우 [기존유심, 타사유심선납, 타사유심후납]
				resetYN = false;
			} else if (
				this.basicData.applType === 'DVC_USIM' &&
				(value === 'PRE_PAY' ||
					value === 'POST_PAY' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기+유심인경우 [선납, 후납, 타사유심선납, 타사유심후납]
				resetYN = false;
			} else if (
				this.basicData.applType === 'USIM' &&
				(value === 'USIM_ONLY_PRE_PAY' || value === 'USIM_ONLY_POST_PAY')
			) {
				// 유심인경우 [유심만 발송 선납, 유심만 발송 후납]
				resetYN = false;
			}
			if (resetYN) {
				this.joinData.usimPaymentType = null;
			}
		},
		async getMostGoodsSelectList() {
			// 빈도수 높은 기기명 조회
			let data = this.joinData.openingTelecomCodeId;
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getMostGoodsSelectList',
				data,
			);
		},
		async getTelecomMostChargeList() {
			// 빈도수 높은 요금제 조회
			let data = this.joinData.openingTelecomCodeId;
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getTelecomMostChargeList',
				data,
			);
		},
		timepickerFnc(value: any, type: string) {
			if (typeof value === 'string') {
				// @ts-ignore
				this.basicData[type] = value;
			} else {
				// @ts-ignore
				this.basicData[type] = `${value.HH}:${value.mm}`;
			}
		},
		amtReplaceFnc() {
			// 앞자리수 0일때 제거공식
			let amt = document.getElementsByClassName('amt');
			for (let i = 0; i < amt.length; i++) {
				//@ts-ignore
				if (amt[i].value.length >= 2) {
					//@ts-ignore
					if (amt[i].value.charAt(0) === '0') {
						//@ts-ignore
						amt[i].value = amt[i].value.slice(1);
					}
				}
			}
		},
		numChangeTxtFnc() {
			if (this.joinTypeItems.length !== 0) {
				if (this.joinData.joinType === 'NUM_CHANGE') {
					switch (this.joinData.existTelecomCodeId) {
						case 42:
							this.joinTypeItems[2].name = '번호이동(S)';
							break;
						case 43:
							this.joinTypeItems[2].name = '번호이동(K)';
							break;
						case 44:
							this.joinTypeItems[2].name = '번호이동(L)';
							break;
						case 1:
							this.joinTypeItems[2].name = '번호이동(V)';
							break;
					}
				}
			}
		},
		changeMostColor(item: any) {
			switch (item.mostType) {
				case 'Y':
					return 'mostItemColor';
					break;
				default:
					return '';
					break;
			}
		},
		deviceDisabled() {
			if (
				this.basicData.logisticsTaskStatus === 'DLVR_PEND' ||
				this.basicData.logisticsTaskStatus === 'DLVR_READY' ||
				this.basicData.logisticsTaskStatus === 'DLVR_PREV_PROG' ||
				this.basicData.logisticsTaskStatus === 'DLVR_PROG' ||
				this.basicData.logisticsTaskStatus === 'DLVR_PREV_CANCEL' ||
				this.basicData.logisticsTaskStatus === 'DLVR_COMPL' ||
				this.basicData.logisticsTaskStatus === 'RETURN_DMND' ||
				this.basicData.logisticsTaskStatus === 'RETURN_COMPL' ||
				this.basicData.logisticsTaskStatus === 'RETURN_COMPL_RESELL' ||
				(this.basicData.logisticsTaskStatus === 'DLVR_NONE' &&
					this.basicData.openingTaskStatus === 'OPENING_PROG') ||
				(this.basicData.logisticsTaskStatus === 'DLVR_NONE' &&
					this.basicData.openingTaskStatus === 'BFR_AGREE_WAIT') ||
				(this.basicData.logisticsTaskStatus === 'DLVR_NONE' &&
					this.basicData.openingTaskStatus === 'BFR_AGREE_PROG') ||
				(this.basicData.logisticsTaskStatus === 'DLVR_NONE' &&
					this.basicData.openingTaskStatus === 'BFR_AGREE_COMPL') ||
				(this.basicData.logisticsTaskStatus === 'DLVR_NONE' &&
					this.basicData.openingTaskStatus === 'OPENING_COMPL') ||
				(this.basicData.saleStoreId === 0 &&
					this.basicData.openingStoreId === 0)
			) {
				return true;
			}
		},
		usimPaymentTypeFilterClassFnc(value: any) {
			let goodsNetwork = null;
			// etcNetwork 18,19,20,21,22,23,25
			let etcNetwork = [18, 19, 20, 21, 22, 23, 25];
			if (this.joinData.goodsId !== null && this.goodsItems.length > 0) {
				let findGoodsNetwork = this.goodsItems.find(
					(item: any) => item.goodsId === this.joinData.goodsId,
				);
				if (findGoodsNetwork) {
					goodsNetwork = findGoodsNetwork.network;
				}
			} else if (etcNetwork.includes(Number(this.joinData.networkId))) {
				goodsNetwork = this.joinData.networkId;
			}
			if (
				this.basicData.applType === 'DVC' &&
				etcNetwork.includes(goodsNetwork) &&
				(value === 'EXIST_USIM' ||
					value === 'PRE_PAY' ||
					value === 'POST_PAY' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기인데 ETC 네트워크 유형인경우 [기존유심, 타사유심선납, 타사유심후납, 선납, 후납]
				return true;
			} else if (
				this.basicData.applType === 'DVC' &&
				(value === 'EXIST_USIM' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기인경우 [기존유심, 타사유심선납, 타사유심후납]
				return '';
			} else if (
				this.basicData.applType === 'DVC_USIM' &&
				(value === 'PRE_PAY' ||
					value === 'POST_PAY' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기+유심인경우 [선납, 후납, 타사유심선납, 타사유심후납]
				return '';
			} else if (
				this.basicData.applType === 'USIM' &&
				(value === 'USIM_ONLY_PRE_PAY' || value === 'USIM_ONLY_POST_PAY')
			) {
				// 유심인경우 [유심만 발송 선납, 유심만 발송 후납]
				return '';
			} else {
				return 'backColorGray1';
			}
		},
		usimPaymentTypeFilterFnc(value: any, beforeData: any) {
			const tempData = beforeData;

			let goodsNetwork = null;
			// etcNetwork 18,19,20,21,22,23,25
			let etcNetwork = [18, 19, 20, 21, 22, 23, 25];
			if (this.joinData.goodsId !== null && this.goodsItems.length > 0) {
				goodsNetwork = this.goodsItems.find(
					(item: any) => item.goodsId === this.joinData.goodsId,
				).network;
			} else if (etcNetwork.includes(Number(this.joinData.networkId))) {
				goodsNetwork = this.joinData.networkId;
			}

			if (
				this.basicData.applType === 'DVC' &&
				etcNetwork.includes(goodsNetwork) &&
				(value === 'EXIST_USIM' ||
					value === 'PRE_PAY' ||
					value === 'POST_PAY' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기인데 ETC 네트워크 유형인경우 [기존유심, 타사유심선납, 타사유심후납, 선납, 후납]
				return true;
			} else if (
				this.basicData.applType === 'DVC' &&
				(value === 'EXIST_USIM' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기인경우 [기존유심, 타사유심선납, 타사유심후납]
				return true;
			} else if (
				this.basicData.applType === 'DVC_USIM' &&
				(value === 'PRE_PAY' ||
					value === 'POST_PAY' ||
					value === 'OTHER_PRE_PAY' ||
					value === 'OTHER_POST_PAY')
			) {
				// 기기+유심인경우 [선납, 후납, 타사유심선납, 타사유심후납]
				return true;
			} else if (
				this.basicData.applType === 'USIM' &&
				(value === 'USIM_ONLY_PRE_PAY' || value === 'USIM_ONLY_POST_PAY')
			) {
				// 유심인경우 [유심만 발송 선납, 유심만 발송 후납]
				return true;
			} else {
				let appTypeTxt = '';
				switch (this.basicData.applType) {
					case 'DVC':
						appTypeTxt = '기기';
						break;
					case 'DVC_USIM':
						appTypeTxt = '기기+유심';
						break;
					case 'USIM':
						appTypeTxt = '유심';
						break;
				}
				alert('개통종류가 ' + appTypeTxt + '인 경우에는 선택 불가합니다.');
				this.$nextTick(() => (this.joinData.usimPaymentType = tempData));
				return false;
			}
		},
		usimPaymentTypeItemsSort() {
			let applType = this.basicData.applType;
			// 유심지불 목록 정렬
			const copyUsimPaymentTypeItems = this.usimPaymentTypeItemsCopy;
			let tempUsimPaymentTypeItemsY = Array<Object>();
			let tempUsimPaymentTypeItemsN = Array<Object>();
			copyUsimPaymentTypeItems.forEach(function (item: any) {
				if (
					applType === 'DVC' &&
					(item.value === 'EXIST_USIM' ||
						item.value === 'OTHER_PRE_PAY' ||
						item.value === 'OTHER_POST_PAY')
				) {
					tempUsimPaymentTypeItemsY.push(item);
				} else if (
					applType === 'DVC_USIM' &&
					(item.value === 'PRE_PAY' ||
						item.value === 'POST_PAY' ||
						item.value === 'OTHER_PRE_PAY' ||
						item.value === 'OTHER_POST_PAY')
				) {
					tempUsimPaymentTypeItemsY.push(item);
				} else if (
					applType === 'USIM' &&
					(item.value === 'USIM_ONLY_PRE_PAY' ||
						item.value === 'USIM_ONLY_POST_PAY')
				) {
					tempUsimPaymentTypeItemsY.push(item);
				} else {
					tempUsimPaymentTypeItemsN.push(item);
				}
			});
			this.usimPaymentTypeItems = [];
			this.usimPaymentTypeItems = [
				...tempUsimPaymentTypeItemsY,
				...tempUsimPaymentTypeItemsN,
			];
		},
	},
	async created() {
		this.thriftyPhone = 0;
	},
	updated() {
		if (this.AppFormCreFlag) {
			if (
				this.goodsItems === this.goodsItemsCopy &&
				this.telecomChargeItems === this.telecomChargeItemsCopy
			) {
				this.networkChangeFnc();
			}
		}
		if (!this.joinData.releaseAmt) {
			this.joinData.releaseAmt = 0;
		}
		if (!this.joinData.pubNotiSupportAmt) {
			this.joinData.pubNotiSupportAmt = 0;
		}
		if (!this.joinData.extraSupportAmt) {
			this.joinData.extraSupportAmt = 0;
		}
		if (!this.joinData.freeInstlAmt) {
			this.joinData.freeInstlAmt = 0;
		}
		if (!this.joinData.etcDiscountAmt) {
			this.joinData.etcDiscountAmt = 0;
		}
		this.amtReplaceFnc();
	},
});
