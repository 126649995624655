import { instance, instanceAuth } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const insertData = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/insertApplicationRecip`,
		data,
	);
	return response;
};

const insertRecipCard = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/insertRecipCard`,
		data,
	);
	return response;
};

const getApplicationRecipDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getApplicationRecipDetail${param}`,
	);
	return response;
};

const getRecipCardList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getRecipCardList${param}`,
	);
	return response;
};

const updateRecipCard = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateRecipCard`,
		data,
	);
	return response;
};

const updateApplicationRecip = async data => {
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/updateApplicationRecip`,
		data,
	);
	return response;
};

const deleteRecipCard = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.post(
		`ApplicationManagement/ApplicationMgmt/deleteRecipCard${param}`,
	);
	return response;
};

const getList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`ApplicationManagement/ApplicationMgmt/getApplicationRecipList${param}`,
	);
	return response;
};

export {
	insertData,
	getApplicationRecipDetail,
	getList,
	insertRecipCard,
	getRecipCardList,
	updateRecipCard,
	updateApplicationRecip,
	deleteRecipCard,
};
