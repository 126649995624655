import Vue from 'vue';
import Vuex from 'vuex';
import {
	getMadiationOutPage,
	getMadiationOutReqPage,
	getMadiationOutGroupPage,
} from '../../../../api/warehouse-mgmt/stock-mgmt/MediationReleaseApi';
import { getRetrieveDeviceHistory } from '../../../../api/common/common';

Vue.use(Vuex);

const MediationReleaseModule = {
	namespaced: true,
	state: {
		detailDialog: false,
		resetData: false,
		totalCnt: 0,
		listData: [],
		detailData: [],
		deviceHistory: [],
		filterData: {
			telecom: '',
			inStockStartRegiDate: '',
			inStockEndRegiDate: '',
			makerId: '',
			barcode: '',
			openingHistYn: '',
			eosYn: '',
			inStockStatus: '',
			productFaultyYn: '',
			statusStr: '',
			orderInStockRegiDate: '',
			orderOrderDate: '',
			orderMoveDate: '',
			listInStockStatus: '',
			listProductFaultyYn: '',
			listPrevOpeningDeviceYn: '',
			listBarcode: '',
			perPageCnt: 20,
			pageNo: 1,
			stockTypes: [],
			judgementStatuses: [],
			provIds: [],
			stockIds: [],
			telecoms: [],
			makers: [],
			goodsIds: [],
			capacitys: [],
			colorNames: [],
			unLockYn: [],
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		mediationOutList: [],
		mediationOutReqList: [],
		listGroupCntData: [
			{
				stockStatStrMsg: '이동재고',
				dvcOpeningCnt: 0,
				openingCnt: 0,
				dvcFaultyCnt: 0,
			},
			{
				stockStatStrMsg: '매장재고',
				dvcOpeningCnt: 0,
				openingCnt: 0,
				dvcFaultyCnt: 0,
			},
		],
	},
	mutations: {
		filterInit(state) {
			for (let data in state.filterData) {
				if (
					data !== 'orderOrderDate' &&
					data !== 'orderInStockRegiDate' &&
					data !== 'orderMoveDate'
				)
					state.filterData[data] = '';
			}
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
		subFilterInit(state, initOrder) {
			if (!initOrder) {
				state.filterData.orderOrderDate = '';
				state.filterData.orderInStockRegiDate = '';
				state.filterData.orderMoveDate = '';
			}
			state.filterData.listInStockStatus = '';
			state.filterData.listProductFaultyYn = '';
			state.filterData.listPrevOpeningDeviceYn = '';
			state.filterData.listBarcode = '';
			state.filterData.orderOrderDate = '';
			state.filterData.orderInStockRegiDate = '';
			state.filterData.orderMoveDate = '';
			let arr = [
				'inStockTypes',
				'stockTypes',
				'judgementStatuses',
				'provIds',
				'stockIds',
				'telecoms',
				'makers',
				'goodsIds',
				'capacitys',
				'colorNames',
				'unLockYn',
			];
			for (let i in arr) {
				state.filterData[arr[i]] = [];
			}
		},
		initMediationOutList(state) {
			state.mediationOutList = [];
			state.totalCnt = 0;
		},
		initMediationOutReqList(state) {
			state.mediationOutReqList = [];
		},
		initListGroupData(state) {
			state.listGroupCntData[0].dvcOpeningCnt = 0;
			state.listGroupCntData[0].openingCnt = 0;
			state.listGroupCntData[0].dvcFaultyCnt = 0;
			state.listGroupCntData[1].dvcOpeningCnt = 0;
			state.listGroupCntData[1].openingCnt = 0;
			state.listGroupCntData[1].dvcFaultyCnt = 0;
		},
		setDeviceHistory(state, result) {
			state.detailHistory = [];
			state.detailHistory = result.data.data;
		},
		setMediationOutList(state, result) {
			state.mediationOutList = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setMediationOutReqList(state, result) {
			state.mediationOutReqList = result.data.data;
		},
		setListGroupData(state, result) {
			let stockIdx = result.data.data.mediationOutGroupCnts.findIndex(
				obj => obj.stockStatStrMsg === '매장재고',
			);
			let moveIdx = result.data.data.mediationOutGroupCnts.findIndex(
				obj => obj.stockStatStrMsg === '이동재고',
			);
			if (moveIdx > -1) {
				state.listGroupCntData[moveIdx].dvcOpeningCnt =
					result.data.data.mediationOutGroupCnts[moveIdx].dvcOpeningCnt || 0;
				state.listGroupCntData[moveIdx].openingCnt =
					result.data.data.mediationOutGroupCnts[moveIdx].openingCnt || 0;
				state.listGroupCntData[moveIdx].dvcFaultyCnt =
					result.data.data.mediationOutGroupCnts[moveIdx].dvcFaultyCnt || 0;
			}
			if (stockIdx > -1) {
				state.listGroupCntData[stockIdx].dvcOpeningCnt =
					result.data.data.mediationOutGroupCnts[stockIdx].dvcOpeningCnt || 0;
				state.listGroupCntData[stockIdx].openingCnt =
					result.data.data.mediationOutGroupCnts[stockIdx].openingCnt || 0;
				state.listGroupCntData[stockIdx].dvcFaultyCnt =
					result.data.data.mediationOutGroupCnts[stockIdx].dvcFaultyCnt || 0;
			}
		},
	},
	actions: {
		async getMadiationOutPage({ commit }, data) {
			commit('initMediationOutList');
			try {
				let result = await getMadiationOutPage(data);
				if (result) {
					commit('setMediationOutList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMadiationOutReqPage({ commit }, data) {
			commit('initMediationOutReqList');
			try {
				let result = await getMadiationOutReqPage(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setMediationOutReqList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMadiationOutGroupPage({ commit }, data) {
			try {
				let result = await getMadiationOutGroupPage(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setListGroupData', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceHistory(data);
				if (result) {
					commit('setDeviceHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default MediationReleaseModule;
