import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/';
const domainMargin = '/MallManagement/MallMarginMgmt/';
const domainConfig = '/MallManagement/MallConfigMgmt/';
const domainGoods = '/GoodsManagement/goodsGroupMgmt/';
const domainSell = '/MallManagement/SellGoodsMgmt/';

// 상품 아이디, 정책 아이디로 요금리스트 공시정보 조회
const getSellRuleFromGoodsIdSellRuleId = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainSell}getSellRuleFromGoodsIdSellRuleId${param}`,
	);
	return response;
};

// 쇼핑몰 특별할인금 등록
const updateMallMargin = async data => {
	const response = await instance.post(`${domainMargin}updateMallMargin`, data);
	return response;
};

// 쇼핑몰 특별할인금 적용여부 조회
const getMallConfig = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainConfig}getMallConfig${param}`);
	return response;
};

// 쇼핑몰 특별할인금 적용여부
const updateMallConfig = async data => {
	const response = await instance.post(`${domainConfig}updateMallConfig`, data);
	return response;
};

// 쇼핑몰 판매 상품 관리 조회
const getMallProductList = async data => {
	const response = await instance.get(`${domain}MallProduct`, data);
	return response;
};

// 상품 그룹 목록 조회
const getStoreGoodsGroupList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainSell}getSellRuleGoodsGroupList${param}`,
	);
	return response;
};

// 판매상품 정보를 등록/수정한다.
const insertSellGoods = async data => {
	const response = await instance.post(`${domainSell}insertSellGoods`, data);
	return response;
};

// 판매상품 정보를 등록/수정한다.
const updateSellGoods = async data => {
	const response = await instance.post(`${domainSell}updateSellGoods`, data);
	return response;
};

// 상품 그룹으로 상품정보 조회
const getSellGoodsGroupInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainSell}getSellGoodsGroupInfo${param}`,
	);
	return response;
};

// 판매상품 양식을 삭제한다.
const deleteSellGoods = async data => {
	const response = await instance.post(`${domainSell}deleteSellGoods`, data);
	return response;
};

// 판매상품 목록 조회
const getSellGoodsList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSell}getSellGoodsList${param}`);
	return response;
};

// 판매상품 상세 조회
const getSellGoodsDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainSell}getSellGoodsDetail${param}`,
	);
	return response;
};

// 판매 상품 템플릿 등록
const insertSellGoodsTemplate = async data => {
	const response = await instance.post(
		`${domainSell}insertSellGoodsTemplate`,
		data,
	);
	return response;
};

// 판매 상품 템플릿 수정
const updateSellGoodsTemplate = async data => {
	const response = await instance.post(
		`${domainSell}updateSellGoodsTemplate`,
		data,
	);
	return response;
};

// 판매 상품 양식 조회
const getSellGoodsTemplateList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainSell}getSellGoodsTemplateList${param}`,
	);
	return response;
};

// 판매 상품 판매/미판매 처리
const updateSellGoodsUseYn = async data => {
	const response = await instance.post(
		`${domainSell}updateSellGoodsUseYn`,
		data,
	);
	return response;
};

export {
	getSellRuleFromGoodsIdSellRuleId,
	getMallProductList,
	updateMallMargin,
	updateMallConfig,
	getMallConfig,
	getStoreGoodsGroupList,
	insertSellGoods,
	updateSellGoods,
	getSellGoodsGroupInfo,
	deleteSellGoods,
	getSellGoodsList,
	getSellGoodsDetail,
	insertSellGoodsTemplate,
	getSellGoodsTemplateList,
	updateSellGoodsUseYn,
	updateSellGoodsTemplate,
};
