<template>
	<div class="executionTableCont1">
		<div class="disFx">
			<h2 class="mr30 lh36">완료여부<span class="redfont">*</span></h2>
			<div class="radioStyle h36 lh36 ml20">
				<input
					type="radio"
					id="completeType1"
					name="completeType1"
					value="RIGHT_DMND"
					@change="changeDateFnc"
					v-model.trim="formData.rightProcStatus"
				/><label class="ml-1 cur_p" for="completeType1">요청</label>
			</div>
			<div class="radioStyle h36 lh36 ml30">
				<input
					type="radio"
					id="completeType2"
					name="completeType1"
					value="RIGHT_PROG"
					@change="changeDateFnc"
					v-model.trim="formData.rightProcStatus"
				/><label class="ml-1 cur_p" for="completeType2">진행중</label>
			</div>
			<div class="radioStyle h36 lh36 ml30">
				<input
					type="radio"
					id="completeType3"
					name="completeType1"
					value="RIGHT_COMPL"
					@change="changeDateFnc"
					v-model.trim="formData.rightProcStatus"
				/><label class="ml-1 cur_p" for="completeType3">완료</label>
			</div>
			<div class="radioStyle h36 lh36 ml30">
				<input
					type="radio"
					id="completeType4"
					name="completeType1"
					value="RIGHT_REJECT"
					@change="changeDateFnc"
					v-model.trim="formData.rightProcStatus"
				/><label class="ml-1 cur_p" for="completeType4">반려</label>
			</div>
			<span
				class="datePickerStyle1 w140 font-size-12 ml10"
				v-if="
					formData.rightProcStatus === 'RIGHT_COMPL' ||
					formData.rightProcStatus === 'RIGHT_REJECT'
				"
			>
				<template v-if="formData.rightProcStatus === 'RIGHT_COMPL'">
					<v-menu
						v-model.trim="menu1"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						:attach="true"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								class="w220"
								style="font-size: 12px"
								outlined
								readonly
								v-bind="attrs"
								v-on="on"
								append-icon="mdi-calendar"
								v-model.trim="formData.complDate"
							></v-text-field>
						</template>
						<v-date-picker
							@input="menu1 = false"
							locale="ko-KR"
							v-model.trim="formData.complDate"
						></v-date-picker>
					</v-menu>
				</template>
				<template v-if="formData.rightProcStatus === 'RIGHT_REJECT'">
					<v-menu
						v-model.trim="menu1"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						:attach="true"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								class="w220"
								style="font-size: 12px"
								outlined
								readonly
								v-bind="attrs"
								v-on="on"
								append-icon="mdi-calendar"
								v-model.trim="formData.rejectDate"
							></v-text-field>
						</template>
						<v-date-picker
							@input="menu1 = false"
							locale="ko-KR"
							v-model.trim="formData.rejectDate"
						></v-date-picker>
					</v-menu>
				</template>
			</span>
		</div>
		<div class="mt20">
			<h2>권리실행 기본정보</h2>
			<div class="new_popTable1 mt15">
				<table class="w100P">
					<tr>
						<td class="w140">권리실행 등록일<br />(개통일)</td>
						<td colspan="3">
							<div class="disFx">
								<input
									class="borderRadi3Px borderContColor3 w220 h36 padW10 disGray"
									v-model.trim="formData.openingDate"
									disabled
									type="text"
								/>
								<h3 class="lh36 redfont ml20">
									<template
										v-if="
											formData.restDays &&
											String(formData.restDays).includes('-')
										"
									>
										<span class="font-size-18"
											><span>{{ formData.restDays }}</span
											>일</span
										>
										(개통후 {{ formData.restDays }}일 이내 반납이 이루어져야
										합니다)
									</template>
									<template
										v-if="
											formData.restDays &&
											!String(formData.restDays).includes('-')
										"
									>
										<span class="font-size-18"><span></span></span> (개통후
										-14일 이내 반납이 이루어져야 합니다)
									</template>
								</h3>
							</div>
						</td>
						<td class="w140">권리실행 연장<span class="redfont">*</span></td>
						<td class="w240">
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="extension1"
										name="extension1"
										value="N"
										v-model.trim="formData.delayYn"
										@change="delayPeriodFnc"
										:disabled="!formData.restDays"
									/><label class="ml-1 cur_p" for="extension1">미연장</label>
								</div>
								<div class="radioStyle h36 lh36 ml30" @click="restDaysFnc">
									<input
										type="radio"
										id="extension2"
										name="extension1"
										value="Y"
										v-model.trim="formData.delayYn"
										@change="delayPeriodFnc"
										:disabled="!formData.restDays"
									/><label class="ml-1 cur_p" for="extension2">연장</label>
								</div>
								<template v-if="formData.delayYn === 'Y'">
									<input
										class="borderRadi3Px borderContColor3 h36 padW10 ml10 w50"
										type="text"
										v-model.trim="formData.delayPeriod"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										maxlength="2"
										:disabled="!formData.restDays"
									/>
									<span class="lh36 ml5">일</span>
								</template>
							</div>
						</td>
					</tr>
					<tr>
						<td>권리실행 기기<span class="redfont">*</span></td>
						<td class="w240">
							<div class="datePickerStyle1 dvcFontBox">
								<v-autocomplete
									class="dvcFontBox"
									style="font-size: 12px; width: 220px"
									:items="goodsItems"
									item-text="goodsName"
									item-value="goodsId"
									v-model.trim="formData.goodsId"
									outlined
									:attach="true"
									placeholder="검색해주세요."
									@change="getCapacityList(formData.goodsId)"
								></v-autocomplete>
							</div>
						</td>
						<td class="w140">용량<span class="redfont">*</span></td>
						<td class="w240">
							<select
								class="borderRadi3Px borderContColor3 h36 padW10 w220"
								v-model.trim="formData.capacity"
							>
								<option v-if="codeList.capacity.length === 0">미선택</option>
								<option
									v-for="item in codeList.capacity"
									v-text="item.capacity"
									:value="item.capacity"
									:key="item.id"
								></option>
							</select>
						</td>
						<td>기기일련번호</td>
						<td class="w240">
							<input
								class="borderRadi3Px borderContColor3 w220 h36 padW10"
								v-model.trim="formData.deviceRawBarcode"
								type="text"
							/>
						</td>
					</tr>
					<tr>
						<td>보상예상금</td>
						<td>
							<div class="costStyle1">
								<input
									class="borderRadi3Px borderContColor3 w220 h36 padW10"
									v-model.trim="formData.expectRewardAmt"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									type="text"
									@input="amtFnc"
								/>
								<span>원</span>
							</div>
						</td>
						<td>예상권리실행금</td>
						<td>
							<div class="costStyle1">
								<input
									class="borderRadi3Px borderContColor3 w220 h36 padW10"
									v-model.trim="formData.expectRightAmt"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									type="text"
									@input="amtFnc"
								/>
								<span>원</span>
							</div>
						</td>
						<td>예상차액</td>
						<td>
							<div class="costStyle1">
								<input
									class="borderRadi3Px borderContColor3 w220 h36 padW10 disGray"
									v-model.trim="formData.expectDiffAmt"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									type="text"
									disabled
								/>
								<span>원</span>
							</div>
						</td>
					</tr>
					<tr>
						<td>고객혜택선택</td>
						<td colspan="5">
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="benefit1"
										name="benefit1"
										value="DVC_DISCOUNT"
										v-model.trim="formData.customerCompeChoice"
									/><label class="ml-1 cur_p" for="benefit1">단말기할인</label>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="benefit2"
										name="benefit1"
										value="CHARGE_DISCOUNT"
										v-model.trim="formData.customerCompeChoice"
									/><label class="ml-1 cur_p" for="benefit2">요금할인</label>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="mt20">
			<h2>1차검수</h2>
			<div class="new_popTable1 mt15">
				<table class="w100P">
					<tr>
						<td rowspan="4" style="width: 126px">
							검수여부<span class="redfont">*</span>
						</td>
						<td>
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="progStatus1"
										name="progStatus1"
										value="NONE_PROG"
										v-model.trim="formData.acceptanceProcStatus"
										@change="acceptanceProcStatusFnc"
									/><label class="ml-1 cur_p" for="progStatus1">미진행</label>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="progStatus2"
										name="progStatus1"
										value="PROG_ACCEPT_Y"
										v-model.trim="formData.acceptanceProcStatus"
										@change="acceptanceProcStatusFnc"
									/><label class="ml-1 cur_p" for="progStatus2"
										>진행(검수)</label
									>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="progStatus3"
										name="progStatus1"
										value="PROG_ACCEPT_N"
										v-model.trim="formData.acceptanceProcStatus"
										@change="acceptanceProcStatusFnc"
									/><label class="ml-1 cur_p" for="progStatus3"
										>진행(미검수)</label
									>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="progStatus4"
										name="progStatus1"
										value="PROG_IMPOSSIBLE"
										v-model.trim="formData.acceptanceProcStatus"
										@change="acceptanceProcStatusFnc"
									/><label class="ml-1 cur_p" for="progStatus4">진행불가</label>
								</div>
							</div>
						</td>
					</tr>
					<tr v-if="formData.acceptanceProcStatus === 'PROG_ACCEPT_Y'">
						<td class="backColorWhite">
							<div class="disFx">
								<span class="lh36 w80">외부상태 확인</span>
								<div class="disFx ml20">
									<div class="radioStyle h36 lh36 ml20">
										<input
											type="radio"
											id="external1"
											name="external1"
											value="NORMAL"
											v-model.trim="formData.extrrStatusCheck"
											@change="extrrFnc"
										/><label class="ml-1 cur_p" for="external1">정상</label>
									</div>
									<div class="radioStyle h36 lh36 ml40">
										<input
											type="radio"
											id="external2"
											name="external1"
											value="DAMAGE"
											@change="extrrFnc"
											v-model.trim="formData.extrrStatusCheck"
										/><label class="ml-1 cur_p" for="external2">파손</label>
									</div>
									<input
										class="borderRadi3Px borderContColor3 w220 h36 padW10 ml20"
										placeholder="메모를 입력해주세요."
										type="text"
										v-model.trim="formData.extrrStatusCheckMemo"
										:disabled="formData.extrrStatusCheck !== 'DAMAGE'"
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr v-if="formData.acceptanceProcStatus === 'PROG_ACCEPT_Y'">
						<td class="backColorWhite">
							<div class="disFx">
								<span class="lh36 w80">작동 확인</span>
								<div class="disFx ml20">
									<div class="radioStyle h36 lh36 ml20">
										<input
											type="radio"
											id="operation1"
											name="operation1"
											value="NORMAL"
											v-model.trim="formData.executeCheck"
											@change="executeFnc"
										/><label class="ml-1 cur_p" for="operation1">정상</label>
									</div>
									<div class="radioStyle h36 lh36 ml40">
										<input
											type="radio"
											id="operation2"
											name="operation1"
											value="ERROR"
											v-model.trim="formData.executeCheck"
											@change="executeFnc"
										/><label class="ml-1 cur_p" for="operation2">오류</label>
									</div>
									<input
										class="borderRadi3Px borderContColor3 w220 h36 padW10 ml20"
										placeholder="메모를 입력해주세요."
										type="text"
										v-model.trim="formData.executeCheckMemo"
										:disabled="formData.executeCheck !== 'ERROR'"
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr v-if="formData.acceptanceProcStatus === 'PROG_ACCEPT_Y'">
						<td class="backColorWhite posR">
							<button
								class="backColorBlue3 mainWhite padW10 lh30 borderRadi3Px"
								style="position: absolute; left: 100px"
								@click="cameraDialog = true"
							>
								카메라
							</button>
							<RightExeFileUpload
								:applId="Number(applId)"
								:propsFileList="formData.attachList"
								:modifyState="true"
								@fileValueFnc="fileValueFnc"
							></RightExeFileUpload>
						</td>
					</tr>
					<tr v-if="formData.acceptanceProcStatus === 'PROG_IMPOSSIBLE'">
						<td class="backColorWhite">
							<textarea
								class="newMemo w100P"
								placeholder="사유를 입력해주세요"
							></textarea>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div
			class="mt20"
			v-if="
				formData.acceptanceProcStatus !== 'NONE_PROG' &&
				formData.acceptanceProcStatus !== 'PROG_IMPOSSIBLE'
			"
		>
			<h2>반납여부</h2>
			<div class="new_popTable1 mt15">
				<table class="w100P">
					<tr>
						<td rowspan="4" style="width: 126px">
							반납여부 확인<span class="redfont">*</span>
						</td>
						<td>
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="recall1"
										name="recall1"
										value="HEAD_OFFICE"
										v-model.trim="formData.rightReturnType"
										@change="rightReturnTypeFnc"
									/><label class="ml-1 cur_p" for="recall1">본사</label>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="recall2"
										name="recall1"
										value="SELF_RETURN"
										v-model.trim="formData.rightReturnType"
										@change="rightReturnTypeFnc"
									/><label class="ml-1 cur_p" for="recall2">자가반납</label>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td class="backColorWhite">
							<div class="disFx">
								<template v-if="formData.rightReturnType === 'HEAD_OFFICE'">
									<div class="disFx">
										<span class="lh36 disIn w50">회수일자</span>
										<span class="datePickerStyle1 w140 font-size-12 ml10">
											<v-menu
												v-model.trim="menu2"
												:close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												:attach="true"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														class="w220"
														style="font-size: 12px"
														outlined
														readonly
														v-bind="attrs"
														v-on="on"
														append-icon="mdi-calendar"
														v-model.trim="formData.reclaimDate"
													></v-text-field>
												</template>
												<v-date-picker
													@input="menu2 = false"
													locale="ko-KR"
													v-model.trim="formData.reclaimDate"
												></v-date-picker>
											</v-menu>
										</span>
									</div>
								</template>
								<div
									class="disFx"
									:class="{ ml30: formData.rightReturnType === 'HEAD_OFFICE' }"
								>
									<span class="lh36 w50">택배사</span>
									<select
										class="borderRadi3Px borderContColor3 h36 padW10 w170 ml10"
										v-model.trim="formData.courierCodeId"
									>
										<option value="">택배사를 선택해주세요.</option>
										<option
											v-for="item in codeList.COURIER"
											v-text="item.codeNm"
											:value="item.codeSeq"
											:key="item.codeNm"
										></option>
									</select>
									<input
										class="borderRadi3Px borderContColor3 w170 h36 padW10 ml5"
										placeholder="운송장번호"
										v-model.trim="formData.invoiceNum"
										type="text"
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr v-if="formData.rightReturnType === 'HEAD_OFFICE'">
						<td class="backColorWhite">
							<div class="disFx">
								<div>
									<span class="lh36 w50 disIn">담당자명</span
									><input
										class="borderRadi3Px borderContColor3 w140 h36 padW10 ml10"
										v-model.trim="formData.chargerName"
										type="text"
									/>
								</div>
								<div class="ml30">
									<span class="lh36 disIn w50">연락처</span
									><input
										class="borderRadi3Px borderContColor3 w170 h36 padW10 ml10"
										v-model.trim="formData.chargerPhone"
										type="text"
										@input="
											phoneNumSplitFnc(
												$event.target.value,
												'chargerPhone',
												'formData',
											)
										"
										maxlength="11"
									/>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div
			class="mt20"
			v-if="
				formData.acceptanceProcStatus !== 'NONE_PROG' &&
				formData.acceptanceProcStatus !== 'PROG_IMPOSSIBLE'
			"
		>
			<h2>검수여부</h2>
			<div class="new_popTable1 mt15">
				<table class="w100P">
					<tr>
						<td style="width: 126px">
							검수진행상황<span class="redfont">*</span>
						</td>
						<td>
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="ACCEPTANCE2"
										name="ACCEPTANCE1"
										value="N"
										v-model.trim="formData.acceptanceYn"
									/><label class="ml-1 cur_p" for="ACCEPTANCE2">미검수</label>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="ACCEPTANCE1"
										name="ACCEPTANCE1"
										value="Y"
										v-model.trim="formData.acceptanceYn"
									/><label class="ml-1 cur_p" for="ACCEPTANCE1">검수</label>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>최종권리실행금</td>
						<td>
							<div class="costStyle1 w200">
								<input
									class="borderRadi3Px borderContColor3 w200 h36 padW10"
									v-model.trim="formData.lastRightAmt"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									@input="amtFnc"
									type="text"
								/>
								<span>원</span>
							</div>
						</td>
					</tr>
					<tr>
						<td>최종차액</td>
						<td>
							<div class="costStyle1 w200">
								<input
									class="borderRadi3Px borderContColor3 w200 h36 padW10 disGray"
									v-model.trim="formData.lastDiffAmt"
									type="text"
									disabled
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								/>
								<span>원</span>
							</div>
							<textarea
								class="h100P newMemo w100P mt5"
								cols="60"
								rows="3"
								placeholder="사유를 입력해 주세요"
								v-model.trim="formData.lastReason"
							></textarea>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="disFx justify-end mt20" v-if="!rightExeFlag">
			<button
				class="backColorBlue2 mainWhite borderRadi3Px w120 lh40"
				@click="insertData"
			>
				저장
			</button>
			<button
				class="backColorGray5 mainWhite boldWt borderRadi3Px w120 lh40 ml10"
				@click="formDataInit"
			>
				취소
			</button>
		</div>
		<div class="disFx justify-end mt20" v-if="rightExeFlag">
			<button
				class="backColorBlue2 mainWhite borderRadi3Px w120 lh40"
				@click="upDateData"
			>
				수정
			</button>
			<button
				class="backColorGray5 mainWhite boldWt borderRadi3Px w120 lh40 ml10"
				@click="getApplicationRightDetail"
			>
				취소
			</button>
		</div>
		<RightExeWebCam v-if="cameraDialog"></RightExeWebCam>
	</div>
</template>

<script>
import NumberSplit from '../../../common/numberSplit.js';
import RightExeFileUpload from '../../../components/RightExeFileUpload.vue';
import RightExeWebCam from '@/views/supply-chain-mgmt/appl-tab-mgmt/RightExeWebCam';
import { htmlParse, nullValidation2 } from '@/common/common';
import bus from '@/common/bus';
import { applRightSub, sendReload } from '@/common/socketUtil';

export default {
	name: 'RightExecutionTable',
	mixins: [NumberSplit],
	components: {
		RightExeFileUpload,
		RightExeWebCam,
	},
	computed: {
		cameraDialog: {
			get() {
				return this.$store.state.RightExecutionModule.cameraDialog;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.cameraDialog = newValue;
			},
		},
		rightExeFlag: {
			get() {
				return this.$store.state.RightExecutionModule.rightExeFlag;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.rightExeFlag = newValue;
			},
		},
		applId() {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		openStoreTelecomId() {
			return this.$store.state.ApplicationFormCreationModule.formData.basic
				.openingStoreId.openStoreTelecomId;
		},
		formData: {
			get() {
				return this.$store.state.RightExecutionModule.formData;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.RightExecutionModule.codeListItems;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.codeListItems = newValue;
			},
		},
		goodsItems: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.rightGoodsItems;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.codeList.rightGoodsItems = newValue;
			},
		},
		applOpeningDate() {
			return this.$store.state.ApplicationFormCreationModule.formData.basic
				.openingDate;
		},
		propsFileList: {
			get() {
				return this.$store.state.RightExecutionModule.propsFileList;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.propsFileList = newValue;
			},
		},
		fileData: {
			get() {
				return this.$store.state.RightExecutionModule.fileData;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.fileData = newValue;
			},
		},
	},
	data: () => ({
		menu1: false,
		menu2: false,
	}),
	methods: {
		restDaysFnc() {
			alert('권리실행 등록일이 등록된 경우 연장할수있습니다.');
		},
		validationRuleFnc() {
			if (!nullValidation2(this.formData, ['goodsId', 'capacity'])) {
				return true;
			}
			if (this.formData.delayYn === 'Y') {
				if (!nullValidation2(this.formData, ['delayPeriod'])) {
					return true;
				}
			}
			if (
				this.formData.acceptanceProcStatus === 'NONE_PROG' &&
				this.formData.acceptanceProcStatus === 'PROG_IMPOSSIBLE'
			) {
				if (!nullValidation2(this.formData, ['expectRewardAmt'])) {
					return true;
				}
			}
			if (
				this.formData.acceptanceProcStatus !== 'NONE_PROG' &&
				this.formData.acceptanceProcStatus !== 'PROG_IMPOSSIBLE'
			) {
				if (this.formData.rightReturnType === 'HEAD_OFFICE') {
					if (
						!nullValidation2(this.formData, [
							'reclaimDate',
							'courierCodeId',
							'invoiceNum',
							'chargerName',
							'chargerPhone',
							'chargerPhone1',
							'chargerPhone2',
							'chargerPhone3',
						])
					) {
						return true;
					}
				}
				if (this.formData.rightReturnType === 'SELF_RETURN') {
					if (
						!nullValidation2(this.formData, ['courierCodeId', 'invoiceNum'])
					) {
						return true;
					}
				}
				/*				if (!nullValidation2(this.formData, ['lastRightAmt'])) {
					return true;
				}*/
			}
		},
		acceptanceProcStatusFnc() {
			if (this.formData.acceptanceProcStatus === 'PROG_ACCEPT_Y') {
				this.formData.extrrStatusCheck = 'NORMAL';
				this.formData.executeCheck = 'NORMAL';
				this.formData.rightReturnType = 'HEAD_OFFICE';
				this.formData.courierCodeId = '';
				this.formData.invoiceNum = '';
				this.formData.reclaimDate = '';
				this.formData.chargerName = '';
				this.formData.chargerPhone = '';
				this.formData.chargerPhone1 = '';
				this.formData.chargerPhone2 = '';
				this.formData.chargerPhone3 = '';
				this.formData.chargerPhone3 = '';
				this.formData.acceptanceYn = 'N';
				this.formData.lastRightAmt = 0;
				this.formData.lastDiffAmt = 0;
				this.formData.lastReason = '';
			} else {
				this.formData.rightReturnType = 'HEAD_OFFICE';
				this.formData.reclaimDate = '';
				this.formData.courierCodeId = '';
				this.formData.invoiceNum = '';
				this.formData.extrrStatusCheck = '';
				this.formData.executeCheck = '';
				this.formData.extrrStatusCheckMemo = '';
				this.formData.executeCheckMemo = '';
				this.formData.chargerName = '';
				this.formData.chargerPhone = '';
				this.formData.chargerPhone1 = '';
				this.formData.chargerPhone2 = '';
				this.formData.chargerPhone3 = '';
				this.formData.acceptanceYn = 'N';
				this.formData.lastRightAmt = 0;
				this.formData.lastDiffAmt = 0;
				this.formData.lastReason = '';
			}
		},
		fileValueFnc(value) {
			this.formData.attachList = value;
		},
		delayPeriodFnc() {
			// 권리실행연장 change
			this.formData.delayPeriod = '';
		},
		rightReturnTypeFnc() {
			// 반납여부 change
			this.formData.reclaimDate = '';
			this.formData.chargerName = '';
			this.formData.chargerPhone = '';
			this.formData.chargerPhone1 = '';
			this.formData.chargerPhone2 = '';
			this.formData.chargerPhone3 = '';
		},
		extrrFnc() {
			// 외부상태확인 change
			this.formData.extrrStatusCheckMemo = '';
		},
		executeFnc() {
			// 작동확인 change
			this.formData.executeCheckMemo = '';
		},
		amtFnc() {
			this.formData.expectDiffAmt =
				this.formData.expectRewardAmt - this.formData.expectRightAmt;
			this.formData.lastDiffAmt =
				this.formData.expectRewardAmt - this.formData.lastRightAmt;
		},
		formDataInit() {
			// 폼데이터 초기화 - 금액관련데이터는 초기값 0처리, 이외 데이터 공백처리
			for (let data in this.formData) {
				this.formData[data] = '';
			}
			this.formData.capacity = '미선택';
			this.formData.applId = this.applId;
			this.formData.rightProcStatus = 'RIGHT_DMND';
			this.formData.rightReturnType = 'HEAD_OFFICE';
			this.formData.customerCompeChoice = 'DVC_DISCOUNT';
			this.formData.extrrStatusCheck = 'NORMAL';
			this.formData.executeCheck = 'NORMAL';
			this.formData.acceptanceProcStatus = 'NONE_PROG';
			this.formData.delayYn = 'N';
			this.formData.expectRewardAmt = 0;
			this.formData.expectRightAmt = 0;
			this.formData.expectDiffAmt = 0;
			this.formData.acceptanceYn = 'N';
			this.formData.lastRightAmt = 0;
			this.formData.lastDiffAmt = 0;
			this.formData.attachList = [];
			if (!this.applOpeningDate) {
				this.formData.openingDate = '';
			} else {
				this.formData.openingDate = String(this.applOpeningDate);
			}
		},
		changeDateFnc() {
			// 완료여부가 요청,진행중 상황에서는 완료여부 날짜 숨김처리
			// 완료여부가 완료,반려일시 날짜데이터 당일로 설정 및 데이터 수정가능
			this.formData.complDate = '';
			this.formData.rejectDate = '';
			if (this.formData.rightProcStatus === 'RIGHT_COMPL') {
				this.formData.complDate = this.getFormatDate(new Date());
			} else if (this.formData.rightProcStatus === 'RIGHT_REJECT') {
				this.formData.rejectDate = this.getFormatDate(new Date());
			}
		},
		getFormatDate(date) {
			// 날짜 계산기!
			let year = date.getFullYear(); //yyyy
			let month = 1 + date.getMonth(); //M
			month = month >= 10 ? month : '0' + month; //month 두자리로 저장
			let day = date.getDate(); //d
			day = day >= 10 ? day : '0' + day; //day 두자리로 저장
			return year + '-' + month + '-' + day;
		},
		async renderingFnc() {
			this.rightExeFlag = false; // 저장,수정 분기 디테일콜 mutation 참조
			await this.setItemListFnc();
			await this.getHistoryList();
			if (this.applId) {
				await this.getApplicationRightDetail();
			}
		},
		setItemListFnc() {
			this.$store.dispatch('RightExecutionModule/getCommonCodeList');
		},
		getCapacityList(data) {
			this.formData.capacity = '';
			this.$store.dispatch(
				'RightExecutionModule/getGoodsSelectCapacityList',
				data,
			);
		},
		async insertData() {
			// 등록
			if (this.validationRuleFnc()) return;
			if (confirm('저장하시겠습니까?')) {
				for (let data in this.formData) {
					if (this.formData[data] === '') {
						this.formData[data] = null;
					}
				}
				await this.$store.dispatch(
					'RightExecutionModule/insertData',
					this.formData,
				);
				await this.fileUploadFnc(null);
				await this.getApplicationRightDetail();
				await this.getApplicationAttachList();
				this.fileData = [];
			}
		},
		async upDateData() {
			// 수정
			if (this.validationRuleFnc()) return;
			if (confirm('수정하시겠습니까?')) {
				for (let data in this.formData) {
					if (this.formData[data] === '') {
						this.formData[data] = null;
					}
				}
				const result = await this.$store.dispatch(
					'RightExecutionModule/upDateData',
					this.formData,
				);
				await this.fileUploadFnc(result.data.data);
				let data = {
					applId: this.applId,
				};
				await this.$store.dispatch('TaskHistoryModule/getHistoryList', data);
				sendReload('right');
				await this.getApplicationRightDetail();
				await this.getApplicationAttachList();
				this.fileData = [];
			}
		},
		async reloadRight() {
			// 소켓 페이지 재조회
			await this.getApplicationRightDetail();
			await this.getApplicationAttachList();
			this.fileData = [];
		},
		async getApplicationRightDetail() {
			// 권리실행 디테일콜
			let data = {
				applId: this.applId,
			};
			await this.$store.dispatch(
				'RightExecutionModule/getApplicationRightDetail',
				data,
			);
			if (this.formData.goodsId) {
				await this.$store.dispatch(
					'RightExecutionModule/getGoodsSelectCapacityList',
					this.formData.goodsId,
				);
			}
		},
		async fileUploadFnc(data) {
			if (this.formData.attachList) {
				if (this.formData.attachList.length !== 0) {
					let formData = new FormData();
					formData.append('applId', this.applId);
					for (let i = 0; i < this.formData.attachList.length; i++) {
						if (this.formData.attachList[i].applAttachId) {
							return;
						}
						formData.append('attachFile', this.formData.attachList[i]);
						let fileHtml = []; // 파일 이력
						let fileObj = {
							title: '첨부파일',
							// @ts-ignore
							data: this.formData.attachList[i].name,
							changeData: '추가',
						};
						fileHtml.push(fileObj);
						let fileHtmlString = htmlParse(fileHtml);
						formData.append('memoContents', fileHtmlString);
						formData.append('attachType', 'RIGHT');
					}
					formData.append('taskMemoId', data);
					// 파일 이력
					await this.$store.dispatch(
						'RightExecutionModule/fileUpload',
						formData,
					);
				}
			}
		},
		async getHistoryList() {
			// 업무/수정 히스토리 콜
			let data = {
				applId: this.formData.applId,
			};
			await this.$store.dispatch('TaskHistoryModule/getHistoryList', data);
		},

		async getApplicationAttachList() {
			// 파일리스트 콜
			let data = { applId: this.applId, attachType: 'RIGHT' };
			if (this.applId) {
				const result = await this.$store.dispatch(
					'RightExecutionModule/getApplicationAttachList',
					data,
				);
				if (result) {
					this.propsFileList = result;
				}
			}
		},
	},
	async created() {
		await this.formDataInit();
		// 권리실행을 통해서 들어온 신청서 번호
		if (this.$route.query.applId !== undefined) {
			applRightSub(this.$route.query.applId);
		}
		bus.$on('reloadRight:appl', this.reloadRight);
	},
	beforeDestroy() {
		bus.$off('reloadRight:appl', this.reloadRight);
	},
	async mounted() {
		await this.renderingFnc();
	},
};
</script>

<style scoped>
.executionTableCont1 {
	background-color: #fff;
	width: 1200px;
	border-top: 2px solid #007ef2;
	border-bottom: 1px solid #eee;
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
	padding: 20px 30px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
</style>
