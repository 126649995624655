import { instance } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = '/SystemNoticeManagement/SysNotiMgmt/';

// 공지사항 목록 조회
const getSysNotiList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSysNoticeList${param}`);
	return response;
};

// 공지사항 상단 출력 1건 조회
const getTopSysNotice = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getTopSysNotice${param}`);
	return response;
};

// 공지사항 cnt
const getSysNoticeGroupCnt = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSysNoticeGroupCnt${param}`);
	return response;
};

// 공지사항 파일 조회
const getSysNoticeAttachList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getSysNoticeAttachList${param}`,
	);
	return response;
};

// 공지사항 등록
const insertSysNoti = async data => {
	const response = await instance.post(`${domain}insertSysNotice`, data);
	return response;
};

// 공지사항 수정
const updateSysNoti = async data => {
	const response = await instance.post(`${domain}updateSysNotice`, data);
	return response;
};

// 공지사항 파일추가
const uploadSysNoticeAttach = async data => {
	const response = await instance.post(`${domain}uploadSysNoticeAttach`, data);
	return response;
};

// 공지사항 삭제
const deleteSysNotice = async data => {
	const response = await instance.post(`${domain}deleteSysNotice`, data);
	return response;
};

// 공지사항 관련 파일 삭제
const deleteSysNoticeAttach = async data => {
	const response = await instance.post(`${domain}deleteSysNoticeAttach`, data);
	return response;
};

// 시스템 공지 조회수 증가
const updateReadCnt = async data => {
	const response = await instance.post(`${domain}updateReadCnt`, data);
	return response;
};

// 시스템 중요공지 조회
const getTopImportantSysNotice = async data => {
	const response = await instance.get(`${domain}getTopImportantSysNotice`);
	return response;
};

export {
	getSysNotiList,
	getTopSysNotice,
	getSysNoticeGroupCnt,
	getSysNoticeAttachList,
	insertSysNoti,
	updateSysNoti,
	uploadSysNoticeAttach,
	deleteSysNotice,
	deleteSysNoticeAttach,
	updateReadCnt,
	getTopImportantSysNotice,
};
