
import { getTelecomAddServiceListWithTitle } from '../api/common/common';
import Vue from 'vue';

interface dataType {
	Dialog: boolean;
	tagList: Array<String>;
	searchText: string;
	optionList: Array<Object>;
	selectedItem: any;
}

export default Vue.extend({
	name: 'addService',
	data: (): dataType => ({
		Dialog: false,
		// 태그 관련 리스트들 하드코딩해서 작업하기로 함.
		tagList: ['관리자를 통해 태그를 추가 해주세요.'],
		searchText: '',
		optionList: [],
		selectedItem: [],
	}),
	computed: {
		inputWidth() {
			return `width: ${this.width}px`;
		},
		joinData(): any {
			return this.$store.state.ApplicationFormCreationModule.formData.join
				.openingTelecomCodeId;
		},
	},
	props: {
		addServiceData: {
			type: Array,
		},
		data: { type: Object, required: true },
		telecomId: { type: Number },
		itemText: { type: String, default: 'name' },
		itemValue: { type: String, default: 'value' },
		width: {
			type: Number,
			default: 670,
		},
		border: {
			type: String,
		},
		disableYN: {
			type: Boolean,
		},
		items: {
			type: Array,
		},
		tableName: { type: String, default: '' },
	},
	methods: {
		focusInArea(tableName: any) {
			if (tableName) {
				this.$emit('focusInArea', tableName);
			}
		},
		focusOutArea(tableName: any) {
			if (tableName) {
				this.$emit('focusOutArea', tableName);
			}
		},
		inputFnc() {
			this.Dialog = !this.Dialog;
			this.getTelecomAddServiceListFnc(this.searchText);
		},
		closeDialog() {
			if (this.Dialog) {
				this.Dialog = !this.Dialog;
			}
		},
		// todo: 무엇을 같이 던져줘야 하는지 chk
		tagSearchFnc(keyword: string) {
			this.getTelecomAddServiceListFnc(keyword);
		},
		addItem(item: any) {
			this.selectedItem.push(item);
			this.$emit('setNewAddSvcList', item);
			this.Dialog = !this.Dialog;
		},
		subtractItem(item: any, index: number) {
			let data = { item, index };
			this.$emit('minusAddSvcList', data);
		},
		async getTelecomAddServiceListFnc(keyword: string) {
			if (this.telecomId) {
				let data = [this.telecomId, keyword];
				const result = await getTelecomAddServiceListWithTitle(data);
				if (result) {
					if (keyword) {
						this.optionList = result.data.data;
					}
					if (result.data.resultCode === '9000') {
						alert('부가서비스 조회결과가 없습니다. 관리자에게 문의해 주세요.');
					}
					this.optionList = result.data.data;
				}
			} else {
				alert('영업점을 선택해주세요.');
			}
		},
		setNewData(data: Array<Object>) {
			this.$emit('setNewAddSvcList', data);
		},
		initFnc() {
			this.selectedItem = this.addServiceData;
		},
		lineBreakFnc(str: any) {
			return String(str).replace(/(?:\r\n|\r|\n)/g, '</br>');
		},
		commaFnc(data: any) {
			if(data) {
				return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			} else {
				return 0;
			}
		},
	},
	created() {
		this.initFnc();
	},
});
