<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog persistent v-model.trim="dialog" width="840">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">회원정보 수정</h2>
						<span class="closeBtn1 cur_p" @click="closeDialog"></span>
					</v-card-title>
					<v-card-text class="pad30">
						<h2 class="mainFontS boldWt mainBlack mb10">지점 정보</h2>
						<div class="new_popTable1">
							<table class="w100P">
								<tr>
									<td>지점명</td>
									<td>{{ formData.storeName }}</td>
									<td>조직명</td>
									<td>{{ formData.orgName }}</td>
								</tr>
							</table>
						</div>
						<div class="disFx">
							<h2 class="mainFontS boldWt mainBlack mb10 mt-5">사용자 정보</h2>
							<p
								v-if="formData.userRole !== 'ROLE_ADMIN'"
								class="font-size-13 lh28 mt-4 ml-3"
							>
								({{
									formData.userRole === 'ROLE_MANAGER'
										? '사이트 매니저'
										: formData.userRole === 'ROLE_USER'
										? '일반 사용자'
										: ''
								}})
							</p>
						</div>
						<div class="new_popTable1">
							<table class="w100P">
								<tr>
									<td>계정명</td>
									<td>{{ formData.username }}</td>
									<td>사용자명</td>
									<td>
										<v-text-field
											class="input_custom1"
											outlined
											v-model.trim="formData.name"
										></v-text-field>
									</td>
								</tr>
								<tr>
									<td>연락처</td>
									<td>
										<div class="disFx">
											<v-text-field
												class="input_custom1 w20"
												outlined
												v-model.trim="formData.phone1"
												oninput="this.value = this.value.replace(/[^0-9]/g, '');"
												maxlength="3"
											></v-text-field>
											<span class="ml-1 mr-1 lh30">-</span>
											<v-text-field
												class="input_custom1 w40"
												outlined
												v-model.trim="formData.phone2"
												oninput="this.value = this.value.replace(/[^0-9]/g, '');"
												maxlength="4"
											></v-text-field>
											<span class="ml-1 mr-1 lh30">-</span>
											<v-text-field
												class="input_custom1 w40"
												outlined
												v-model.trim="formData.phone3"
												oninput="this.value = this.value.replace(/[^0-9]/g, '');"
												maxlength="4"
											></v-text-field>
										</div>
									</td>
									<td>이메일</td>
									<td>
										<v-text-field
											class="input_custom1"
											outlined
											v-model.trim="formData.email"
										></v-text-field>
									</td>
								</tr>
								<tr>
									<td>사용자 역할</td>
									<td>{{ formData.memberRoleTypeMsg }}</td>
									<td>영업관리 권한</td>
									<td>{{ consultRoleMsg(formData.consultRole) }}</td>
								</tr>
							</table>
						</div>
						<div class="mt-8 mb-4 font-black">
							<ul>
								<li>
									보다 편리한 서비스 제공을 위해, 가입회원의 정보확인과
									약관동의가 필요합니다.
								</li>
								<li>
									SKLMNO의 <b>이용약관 동의 후 로그인 및 사용이 가능</b>합니다.
								</li>
							</ul>
						</div>
						<terms-template
							@checkAllChecked="checkAllChecked"
							@getCheckedTermData="getCheckedTermData"
							:preAgreeIdList="preAgreeIdList"
						></terms-template>
						<div class="text-center mt-8">
							<v-btn
								class="backColorBlue3 mainWhite h26 ml10 btnShadow2 w80"
								@click="submit"
								>확인</v-btn
							>
							<v-btn
								class="backColorGray1 mainWhite borderRadi5Px w80 ml10"
								@click="closeDialog"
								>닫기</v-btn
							>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import TermsTemplate from '@/components/TermsTemplate';
import { updateMemberTerms } from '@/api/member/login';
import { toHtml } from '@/common/common';

export default {
	name: 'TOSForExistingMemberPop',
	props: {
		formData: { type: Object },
		loginData: { type: Object },
	},
	components: { TermsTemplate },
	data: () => ({
		terms_SALES_TERMS: false,
		terms_PRIVACY_POLICY: false,
		terms_ELECTRONIC_FINACIAL_PROVISION: false,
		terms_PRIVACY_USE_TERMS: false,
		terms_PRIVACY_OFFER_TERMS: false,
		smallDialog: false,
		termsTitle: '',
		termsType: '',
		termsContents: '',
		termsChecked: false,
		readMode: false,
		SALES_TERMS: '',
		PRIVACY_POLICY: '',
		ELECTRONIC_FINACIAL_PROVISION: '',
		PRIVACY_USE_TERMS: '',
		PRIVACY_OFFER_TERMS: '',
		agreeIdList: [],
		preAgreeIdList: [],
		allChecked: false,
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.TOSForExistingMemberDialog;
			},
			set(newValue) {
				this.$store.state.TOSForExistingMemberDialog = newValue;
			},
		},
		termsCheckDialog: {
			get() {
				return this.$store.state.termsCheckDialog;
			},
			set(newValue) {
				this.$store.state.termsCheckDialog = newValue;
			},
		},
	},
	methods: {
		closeDialog() {
			this.dialog = false;
			this.$emit('clearMemberData');
		},
		toHtml(text) {
			return toHtml(text);
		},
		consultRoleMsg(type) {
			if (type === 'ROLE_CONSULT_MANAGER') {
				return '매니저';
			} else if (type === 'ROLE_FIRST_CONSULT') {
				return '1차 상담원';
			} else if (type === 'ROLE_SECOND_CONSULT') {
				return '2차 상담원';
			} else {
				return '없음';
			}
		},
		async submit() {
			if (!this.allChecked) {
				alert('동의 처리되지 않은 약관이 있습니다.');
				return;
			}
			let data = {
				username: this.loginData.username,
				password: this.loginData.password,
				name: this.formData.name,
				email: this.formData.email,
				phone: this.formData.phone,
				phone1: this.formData.phone1,
				phone2: this.formData.phone2,
				phone3: this.formData.phone3,
				agreeIdList: this.agreeIdList,
			};
			let result = await updateMemberTerms(data);
			if (result && result.data.resultCode === '0000') {
				this.closeDialog();
				alert('약관 동의가 확인되었습니다.');
				this.$emit('allTermsAgreed');
			}
		},
		getCheckedTermData(data) {
			this.agreeIdList = data;
		},
		checkAllChecked(boolean) {
			this.allChecked = boolean;
		},
		setPreCheckedAgreeId() {
			this.preAgreeIdList = [];
			if (
				this.formData.storeAgreeIdList &&
				this.formData.storeAgreeIdList.length > 0
			) {
				this.preAgreeIdList = this.formData.storeAgreeIdList;
			}
		},
	},
	async created() {
		this.setPreCheckedAgreeId();
	},
};
</script>

<style scoped>
.new_popTable1 td:nth-of-type(1),
.new_popTable1 td:nth-of-type(3) {
	width: 120px;
	text-align: center;
}
.new_popTable1 td:nth-of-type(2),
.new_popTable1 td:nth-of-type(4) {
	width: 266px;
}
ul li {
	list-style: disc;
	margin-left: 16px;
}
.lookAtTermsBtn {
	text-decoration: underline;
	text-underline-offset: 2px;
}
</style>
