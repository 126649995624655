<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="740">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">중요공지</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>
					<v-card-text class="pad30 sysNotiCont">
						<div class="cur_p" @click="moveToNotiPop">
							<h2 class="notiPopTitle cur_p">
								{{ noticeData.sysNoticeTitle }}
							</h2>
							<div
								class="disFx justify-center editor-notice mb-4 narrowScroll1"
								style="max-height: 300%;"
							>
								<div class="ql-container ql-snow w100P">
									<div
										class="ql-editor"
										v-html="toHtml(noticeData.sysNoticeContents)"
									></div>
								</div>
							</div>
						</div>
						<div class="disFX flex-column" v-if="attachData.length > 0">
							<h3 class="mt5">첨부파일</h3>
							<file-upload2
								:readOnly="true"
								fileInputId="fileInput"
								:parentFileData="attachData"
								category="SystemNotice"
							></file-upload2>
						</div>
						<div class="disFx justify-space-between mt-6">
							<div>
								<div class="ml-5 checkStyleCont9">
									<input
										id="beforeReserve1"
										class="checkStyle"
										type="checkbox"
										v-model.trim="todayNoShow"
									/>
									<label
										for="beforeReserve1"
										class="pl-1 fw600"
										style="cursor: pointer"
									>
										오늘 하루 이 창 열지 않기
									</label>
								</div>
							</div>
							<div>
								<v-btn
									@click="closeDialog"
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
								>
									닫기
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>
<script>
import { toHtml } from '../../common/common';
import fileUpload2 from '@/components/FileUpload2';
import bus from '@/common/bus';

export default {
	name: 'NoticePop',
	props: {
		noticeData: { type: Object },
		attachData: {},
	},
	components: {
		fileUpload2,
	},
	data: () => ({ todayNoShow: false }),
	computed: {
		ms() {
			return this.$store.state.cookies.ms;
		},
		dialog: {
			get() {
				return this.$store.state.importantSysDialog;
			},
			set(newValue) {
				this.$store.state.importantSysDialog = newValue;
			},
		},
	},
	methods: {
		toHtml(text) {
			return toHtml(text);
		},
		moveToNotiPop() {
			if (window.location.pathname !== '/cs-mgmt/noticelist') {
				this.$router.push({
					name: 'csnoticelist',
					params: { sysNoticeId: this.noticeData.sysNoticeId },
				});
			} else {
				bus.$emit('openSysNotiDialog', this.noticeData.sysNoticeId);
			}
			this.dialog = false;
		},
		resizeTextArea() {
			let textarea = document.querySelector('.ql-editor');
			textarea.style.height = 'auto';
			let height = textarea.scrollHeight;
			textarea.style.height = `${height + 10}px`;
		},
		closeDialog() {
			if (this.todayNoShow) {
				this.setCookie(this.noticeData.sysNoticeId);
			}
			this.dialog = false;
		},
		setCookie(sysNoticeId) {
			let todayDate = new Date();
			todayDate.setHours(24, 0, 0, 0);
			document.cookie =
				`sysnoti_${escape(sysNoticeId)}_${escape(this.ms)}` +
				'=' +
				sysNoticeId +
				'; path=/; expires=' +
				todayDate.toGMTString() +
				';';
		},
	},
	created() {},
	mounted() {},
};
</script>

<style scoped>
.checkStyleCont9 {
	transform: translateY(6px);
}
.checkStyleCont9 label {
	line-height: 24px;
}
.notiPopTitle {
	font-size: 22px;
	color: #0b256b;
	margin-bottom: 20px;
}
.notiPopContents textarea {
	width: 100%;
	line-height: 22px;
	height: 300px;
	resize: none;
	cursor: pointer !important;
}
.notiPopContents textarea:focus {
	outline: none;
}
</style>
