import { instance } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = 'DeviceManagement/';

const getMadiationOutPage = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MgmtTargetDevice/getMadiationOutPage${param}`,
	);
	return response;
};
const getMadiationOutReqPage = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MgmtTargetDevice/getMadiationOutReqPage${param}`,
	);
	return response;
};
const getMadiationOutGroupPage = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MgmtTargetDevice/getMadiationOutGroupPage${param}`,
	);
	return response;
};

export {
	getMadiationOutPage,
	getMadiationOutReqPage,
	getMadiationOutGroupPage,
};
