import { instance } from '../../../index.js';
import { jsonToQueryString } from '@/common/common';

const domain = 'DeviceManagement/';

const insertSellMove = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/insertSellMove`,
		data,
	);
	return response;
};

const deleteList = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/deleteMoveStock`,
		data,
	);
	return response;
};

const getMoveAndTrnsList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`DeviceManagement/MoveStockMgmt/getMoveAndTrnsList/SELL_MOVE${param}`,
	);
	return response;
};

const updateSellMove = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/updateSellMove`,
		data,
	);
	return response;
};
// 신규 이동 등록, 리스트 콜
// 이동 대기 등록
const insertWaitMoveStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/insertWaitMoveStock`,
		data,
	);
	return response;
};

// 이동 대기 목록 조회
const getWaitMoveStockList = async data => {
	const response = await instance.get(
		`${domain}MoveStockMgmt/getWaitMoveStockList/${data}`,
	);
	return response;
};

// 이동 대기 삭제
const deleteWaitMoveStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/deleteWaitMoveStock`,
		data,
	);
	return response;
};

// 이동 대기 데이터 이동 처리
const insertAllWaitMoveStock = async data => {
	/*let param = '';
	if (data) param = jsonToQueryString(data);*/
	const response = await instance.post(
		`${domain}MoveStockMgmt/migrationMoveStockWaitDataToMoveStock/${data}`,
	);
	return response;
};

// 이관 대기 등록
const insertWaitOutStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/insertWaitOutStock`,
		data,
	);
	return response;
};

// 이관 대기 목록 조회
const getWaitOutStockList = async data => {
	const response = await instance.get(
		`${domain}MoveStockMgmt/getWaitOutStockList/${data}`,
	);
	return response;
};

// 이관 대기 데이터 이관 처리
const insertAllWaitOutStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/migrationOutStockWaitDataToOutStock/${data}`,
	);
	return response;
};

// 이동 대기 삭제
const deleteWaitOutStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/deleteWaitOutStock`,
		data,
	);
	return response;
};

//이동 대기 수정
const updateWaitMoveStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/updateWaitMoveStock`,
		data,
	);
	return response;
};

// 이관 대기 수정
const updateWaitOutStock = async data => {
	const response = await instance.post(
		`${domain}MoveStockMgmt/updateWaitOutStock`,
		data,
	);
	return response;
};

export {
	insertSellMove,
	deleteList,
	getMoveAndTrnsList,
	updateSellMove,
	insertWaitMoveStock,
	getWaitMoveStockList,
	deleteWaitMoveStock,
	insertAllWaitMoveStock,
	getWaitOutStockList,
	insertWaitOutStock,
	insertAllWaitOutStock,
	deleteWaitOutStock,
	updateWaitMoveStock,
	updateWaitOutStock,
};
