<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="1580">
				<v-card
					class="borderRadiReset consultApplication"
					style="background: rgb(246, 246, 246)"
				>
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
						style="top: 0; position: sticky; z-index: 2"
					>
						<div class="disFX">
							<h2 class="mainWhite mainFontS boldWt">신청서</h2>
							<button
								class="mainYellow boldWt borderRadi3Px w140 font-size-14 txUnL lh16"
								v-if="
									applId !== null &&
									moduleName === 'ConsultDbModule' &&
									((cr ===
										'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd' &&
										formData.basic.consultTaskStatus !== 'RCPT_PROG' &&
										customerMemberYn === 'Y') ||
										cr ===
											'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4')
								"
								@click="switchCustomerAppl"
							>
								고객DB 신청서 전환
							</button>
							<div
								class="mainRed applForm btnCont w200 ml5"
								style="font-weight: bold; line-height: 18px"
								v-if="tabDialog.applTab"
							>
								<h2
									:class="{
										txUnL:
											formData.basic.parentApplId || formData.basic.childApplId,
										cur_p:
											formData.basic.parentApplId || formData.basic.childApplId,
									}"
									@click="openChainAppl"
									v-if="formData.basic.retractYn === 'N'"
								>
									A/S기변 신청서입니다.↗
								</h2>
							</div>
						</div>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>
					<v-card-text
						class="disFX narrowScroll"
						style="height: 85vh; overflow-y: scroll"
					>
						<div class="narrowScroll">
							<template v-if="AppFormCreFlag">
								<div class="AppTabCont1 ml5 mt5" style="width: 1200px">
									<button
											:class="{
										noneLine1: tabDialog.applTab,
										falseBtn1: !tabDialog.applTab,
									}"
											@click="appTapFnc"
									>
										신청서
										<template v-if="!!formData.creditProcStatusMsg"
										>(신용조회-{{ formData.creditProcStatusMsg }})</template
										>
									</button>
									<button
											:class="{
										noneLine1: tabDialog.receiveTab,
										falseBtn1: !tabDialog.receiveTab,
									}"
											@click="receiveTapFnc"
									>
										수납요청
									</button>
									<!-- 권리실행 - 개통일시, 가입유형이 기변일때만 보인다. 기존 정책-->
									<!-- 권리실행 - 가입유형이 기변일때만 보인다. 22.11.09 -->
									<button
											:class="{
										disRouteBorderColor: !excutionTabFlag,
										disRouteColor1: !excutionTabFlag,
										noneLine1: tabDialog.executionTab,
										falseBtn1: !tabDialog.executionTab,
									}"
											@click="executionTapFnc"
									>
										권리실행
									</button>
								</div>
							</template>
							<application-form-creation-table
								v-if="tabDialog.applTab"
								:moduleName="moduleName"
								:cnsltId="cnsltId"
								:applId="applId"
								style="display: inline-block"
								ref="appliTbl"
							></application-form-creation-table>
							<receive-request-table
									v-if="tabDialog.receiveTab"
									style="display: inline-block"
									:class="{ disNONE: !tabDialog.receiveTab }"
							>
							</receive-request-table>
							<right-execution-table
									v-if="tabDialog.executionTab"
									style="display: inline-block"
									:class="{ disNONE: !tabDialog.executionTab }"
							></right-execution-table>
						</div>
						<div
							class="pl-4 w100P pr-4 mt15"
							style="background: rgb(246, 246, 246); height: 100%"
						>
							<div v-if="applId !== null && applId !== 0 && applSocketList" class="mb20">
								<h2 class="mainBlack2 disIN">
									현재 접속자({{ applSocketList.length }})
								</h2>
								<div id="memoArea" class="mt-1">
									<div
										style="color: gray; height: 75px; overflow: auto"
										class="borderRadi3Px borderContColor1 backColorWhite"
									>
										<ul
											class="font-size-12"
											style="
												list-style-type: disc;
												list-style-position: inside;
												padding-left: 15px !important;
											"
										>
											<li
												class="applTockenList"
												v-for="(item, index) in applSocketList"
												:key="index"
												:title="item.sn + '\n' + item.mn"
											>
												<span style="font-weight: bold; margin-left: -10px">{{
													item.mn
												}}</span>
												- {{ item.sn }}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<!--	신청서 템플릿	-->
							<template v-if="moduleName === 'CustomerDbModule' && (this.$route.path !== '/credit-check' && this.$route.path !== '/credit-check-request')">
								<ApplTemplate
									v-if="!AppFormCreFlag && this.cnsltId === 0"
									componentStyle="max-width: 300px; max-height: 90vh;"
								></ApplTemplate>
							</template>
							<!-- to_do -->
							<template v-if="moduleName === 'CustomerDbModule' && String(formData.basic.saleStoreId) === $store.state.cookies.ss && AppFormCreFlag && tabDialog.applTab">
								<div class="disFx lh28 mt10" style="padding: 3px 0">
									<div class="disFx">
										<h2 class="mainBlack2">To-Do 등록</h2>
										<div class="ml30 checkStyleCont4">
											<input
												class="checkStyle"
												type="checkbox"
												id="notiYnDetailAppl"
												true-value="Y"
												false-value="N"
												@change="todoNotiYnChk"
												v-model.trim="applConsultTodoDto.notiYn"
											/>
											<label
												for="notiYnDetailAppl"
												style="cursor: pointer; line-height: 28px"
											>
												예약
											</label>
										</div>
									</div>
								</div>
								<table style="width: 300px">
									<tr>
										<td style="">
											<div class="datePickerStyle1 disFx">
												<span class="" style="width: 150px; overflow: hidden">
													<v-menu
														v-model.trim="menu3"
														:close-on-content-click="false"
														:nudge-right="40"
														transition="scale-transition"
														offset-y
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																placeholder="YYYY-mm-dd"
																style="font-size: 13px; width: 150px"
																outlined
																readonly
																v-bind="attrs"
																v-on="on"
																append-icon="mdi-calendar"
																tabindex="-1"
																v-model.trim="
																	applConsultTodoDto.reservationDateTime
																"
																:disabled="applConsultTodoDto.notiYn === 'N'"
															></v-text-field>
														</template>
														<v-date-picker
															@input="menu3 = false"
															locale="ko-KR"
															v-model.trim="
																applConsultTodoDto.reservationDateTime
															"
															:disabled="applConsultTodoDto.notiYn === 'N'"
															:min="
																new Date(
																	Date.now() -
																		new Date().getTimezoneOffset() * 60000,
																)
																	.toISOString()
																	.substr(0, 10)
															"
														></v-date-picker>
													</v-menu>
												</span>
												<span class="ml10">
													<vue-timepicker
														v-model.trim="applConsultTodoDto.time"
														close-on-complete
														:disabled="applConsultTodoDto.notiYn === 'N'"
														:minute-interval="10"
													></vue-timepicker>
												</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 75% !important">
											<textarea
												class="font-size-12 borderContColor2 backColorWhite borderRadi3Px pd4 lh20 noResize narrowScroll"
												style="width: 100%; height: 70px"
												maxlength="500"
												placeholder="알림 내용"
												v-model.trim="applConsultTodoDto.toDoContents"
											></textarea>
										</td>
									</tr>
								</table>
							</template>
							<div
								v-if="quickOpen && formData.deadLineTargetYn === 'Y' && AppFormCreFlag"
								class="appEndCont w300 mb10 disIN"
							>
								<h2 class="mainBlack2 disIN">신청서 만료일</h2>
								<div class="">
									<div class="" style="height: 40px">
										<p
											class="subOrange1 font-size-13 mj0 pd0"
											style="width: 245px"
										>
											{{ formData.basic.deadLineDate }} ({{
												week[$moment(formData.basic.deadLineDate).days()]
											}})
											<b class="mainRed">( {{ getDiffDate() }}일 )</b>
										</p>
										<!--							<p class="font-size-13 mj0 pd0 fw500" style="line-height: 18px">-->
										<!--								신청일 :-->
										<!--								<span class="subOrange1 fw400">{{-->
										<!--									formData.basic.deadLineDate-->
										<!--								}}</span>-->
										<!--								<br />-->
										<!--								만료일 :-->
										<!--								<span class="subOrange1 fw400">{{-->
										<!--									formData.basic.deadLineDate-->
										<!--								}}</span>-->
										<!--							</p>-->
										<div
											class="btnCont floatL"
											v-if="
												$moment(new Date()).diff(
													$moment(formData.basic.deadLineDate),
													'days',
												) >=
												Math.ceil(
													$moment(formData.basic.regiDatetime).diff(
														$moment(formData.basic.deadLineDate),
														'days',
													) / 2,
												)
											"
										>
											<button
												v-if="
													$moment(new Date()).diff(
														$moment(formData.basic.deadLineDate),
														'days',
													) >=
														Math.ceil(
															$moment(formData.basic.regiDatetime).diff(
																$moment(formData.basic.deadLineDate),
																'days',
															) / 2,
														) &&
													((this.$store.state.cookies.ss ===
														String(formData.basic.saleStoreId) &&
														(this.$store.state.cookies.ms ===
															String(formData.basic.applRegiUserId) ||
															this.$store.state.cookies.role === 'M')) ||
														(this.$store.state.cookies.ss !==
															String(formData.basic.saleStoreId) &&
															this.$store.state.cookies.sg === 'StoreGrade_M'))
												"
												@click="extendApplDeadLineDate"
												class="backColorGreen cur_p"
											>
												신청서연장
											</button>
											<button
												@click="cancelApplReceipt"
												class="backColorRed ml5 cur_p"
											>
												접수취소
											</button>
										</div>
									</div>
								</div>
							</div>
							<template
								v-if="
									this.creditTodoDetail &&
									this.creditTodoDetail.creditInquireResponseDto !== null &&
									this.creditTodoDetail.creditInquireDto.creditProcStatus ===
										'COMPL'
								"
							>
								<div
									class="disFx justify-space-between w100P"
									style="padding-right: 5px"
								>
									<h2 class="mainBlack2 disIN">
										신용조회
										<template
											v-if="creditTodoDetail.creditInquireDto.memoContents"
										>
											<span
												class="mainBlue1 txUnL cur_p"
												@click="creView = 'Con'"
												>내용</span
											>
											/
											<span
												class="mainBlue1 txUnL cur_p"
												@click="creView = 'Memo'"
												>메모</span
											>
										</template>
										<template v-else> 내용 </template>
									</h2>
								</div>
								<div
									id="taskInfoArea"
									class="invisibleScroll w100P mt-1 borderRadi5Px borderContColor1 mb-1 bgWhite justify-space-between"
									style=""
								>
									<div
										class="disFX pd10 font-size-12"
										style="
											border-bottom: 1px solid #ddd;
											padding: 5px 2px 5px 2px;
											max-height: 300px;
										"
									>
										<div
											class="tempDevice1 ml5 narrowScroll"
											style="
												line-height: 12px;
												padding-right: 5px;
												overflow-y: auto;
												max-height: 300px;
											"
											:class="{
												disNONE: creView === 'Memo',
												w100P: creView !== 'Memo',
												creBoxLine: creView === 'All',
											}"
										>
											<div
												class="font-btn-color lh16"
												style="
													font-family: 'Noto Sans KR', sans-serif !important;
												"
												v-html="creditTodoDetail.creditInquireResponseDto"
											></div>
										</div>
										<div
											class="tempDevice1 ml5 narrowScroll"
											style="
												line-height: 12px;
												overflow-y: auto;
												max-height: 300px;
											"
											:class="{
												disNONE: creView === 'Con',
												w100P: creView !== 'Con',
											}"
										>
											<div
												class="font-btn-color lh16"
												style="
													font-family: 'Noto Sans KR', sans-serif !important;
												"
												v-html="creditTodoDetail.creditInquireDto.memoContents"
											></div>
										</div>
									</div>
								</div>
							</template>

							<div
								class="memoComp w100P font-size-12 posRe"
								style="position: sticky; overflow-y: hidden"
								v-if="applId !== null && applId !== 0"
							>
								<Memo
									:applId="applId"
									:maxHeight="210"
									:useScrollArrow="false"
									:quickOpen="quickOpen"
								></Memo>
								<div
									class="posFix narrowScroll sideMenuBarr font-btn-color"
									:class="{ posFix1: quickOpen }"
								>
									<TaskHistory :applId="applId"></TaskHistory>
								</div>
							</div>
							<div
								class="w300 mt20"
								style="min-width: 300px"
								v-if="this.cnsltId === 0 && String(formData.basic.saleStoreId) === $store.state.cookies.ss"
							>
								<div>
									<div class="w100P contentBoxGray">
										<div class="disFX justify-space-between">
											<h2 class="disIn mainBlack2">상담메모</h2>
										</div>
										<div class="mt-1">
											<div class="new-btnA1">
												<textarea
													id="memoContents"
													class="mt-1 pd4 narrowScroll borderContColor1 consult_new_memo backColorWhite"
													placeholder="메모 입력"
													maxlength="255"
													type="text"
													v-model.trim="applConsultMemoDto.memoContents"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<consult-memo
								v-if="dialogTypeText !== '신규등록' && cnsltId !== 0"
								:memoList="memoList"
								:moduleName="moduleName"
								:consultMemoType="consultMemoType"
								:cnsltId="cnsltId"
								:loadFromPopup="true"
								@getConsultMemoList="getConsultMemoList"
								@getList="getList"
							></consult-memo>
							<consult-history
								v-if="dialogTypeText !== '신규등록' && String(formData.basic.saleStoreId) === $store.state.cookies.ss"
								:historyList="historyList"
							></consult-history>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import ApplicationFormCreationTable from '../consult-appl/ConsultApplicationFormCreationTable.vue';
import ReceiveRequestTable from '../../supply-chain-mgmt/appl-tab-mgmt/ReceiveRequestTable.vue';
import RightExecutionTable from '../../supply-chain-mgmt/appl-tab-mgmt/RightExecutionTable.vue';
import ApplTemplate from '@/components/ApplTemplate';
import ConsultMemo from '../../../components/memo/ConsultMemo.vue';
import ConsultHistory from '../../../components/memo/ConsultHistory.vue';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker';
import Memo from '../../../components/Memo';
import TaskHistory from '../../../components/TaskHistory';
import bus from '@/common/bus';
import { applUnSub } from '../../../common/socketUtil';

let postApplId = '';

export default Vue.extend({
	name: 'ConsultApplPop',
	props: {
		moduleName: { type: String },
		dialogTypeText: { type: String, required: true },
		cnsltId: { type: Number, required: true },
		consultMemoType: { type: String },
		applId: { type: undefined },
	},
	components: {
		ApplicationFormCreationTable,
		ApplTemplate,
		ConsultMemo,
		ConsultHistory,
		VueTimepicker,
		Memo,
		TaskHistory,
		ReceiveRequestTable,
		RightExecutionTable,
	},
	data: () => ({
		menu1: false,
		menu2: false,
		menu3: false,
		memoList: [],
		quickOpen: true,
		week: ['일', '월', '화', '수', '목', '금', '토'],
		creView: 'All',
	}),
	computed: {
		module: {
			get() {
				return this.$store.state[this.moduleName];
			},
			set(newValue) {
				this.$store.state[this.moduleName] = newValue;
			},
		},
		dialog: {
			get() {
				return this.module.applDialog;
			},
			set(newValue) {
				this.module.applDialog = newValue;
			},
		},
		applConsultTodoDto: {
			get() {
				return this.$store.state.SalesMgmtModule.applConsultTodoDto;
			},
			set(newValue) {
				this.$store.state.SalesMgmtModule.applConsultTodoDto = newValue;
			},
		},
		creditTodoDetail: {
			get() {
				return this.$store.state.SalesMgmtModule.creditTodoDetail;
			},
			set(newValue) {
				this.$store.state.SalesMgmtModule.creditTodoDetail = newValue;
			},
		},
		applConsultMemoDto: {
			get() {
				return this.$store.state.SalesMgmtModule.applConsultMemoDto;
			},
			set(newValue) {
				this.$store.state.SalesMgmtModule.applConsultMemoDto = newValue;
			},
		},
		tabDialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.tabDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.tabDialog = newValue;
			},
		},
		historyList: {
			get() {
				return this.module.historyList;
			},
			set(newValue) {
				this.module.historyList = newValue;
			},
		},
		applSocketList() {
			return this.$store.state.applSocketList;
		},
		AppFormCreFlag: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.AppFormCreFlag =
					newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.formData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.formData = newValue;
			},
		},
		consultSwitchType: {
			get() {
				return this.$store.state.ApplicationFormCreationModule
					.consultSwitchType;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.consultSwitchType =
					newValue;
			},
		},
		customerMemberYn: {
			get() {
				return this.$store.state.CustomerConsultModule.formDataDetail
					.customerMemberYn;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.formDataDetail.customerMemberYn =
					newValue;
			},
		},
		cr() {
			return this.$store.state.cookies.cr;
		},
		crData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.crData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.crData = newValue;
			},
		},
		crListFlag: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.crListFlag;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.crListFlag = newValue;
			},
		},
		excutionTabFlag() {
			return this.$store.state.ApplicationFormCreationModule.excutionTabFlag;
		},
	},
	methods: {
		appTapFnc() {
			// 신청서작성버튼 활성화
			this.tabDialog.applTab = true;
			this.tabDialog.receiveTab = false;
			this.tabDialog.executionTab = false;
			if (this.$store.state.applTabType !== null) {
				applUnSub(this.$store.state.applTabType, postApplId);
			}
			this.$store.state.applTabType = '/subscribe/application/main/';
			this.$store.state.ApplicationFormCreationModule.receiveChk = true;
		},
		receiveTapFnc() {
			// 수납요청버튼 활성화
			applUnSub(this.$store.state.applTabType, postApplId);
			this.$store.state.applTabType = '/subscribe/application/recip/';
			this.tabDialog.applTab = false;
			this.tabDialog.receiveTab = true;
			this.tabDialog.executionTab = false;
			this.$store.state.ApplicationFormCreationModule.receiveChk = true;
		},
		executionTapFnc() {
			if (!this.excutionTabFlag) {
				switch (this.formData.join.openingTelecomCodeId) {
					case 5:
						alert('가입유형이 기기변경일때 권리실행 가능합니다.');
						break;
					case 6:
						alert('가입유형이 기기변경/전환신규 일때 권리실행 가능합니다.');
						break;
					case 7:
						alert('가입유형이 기기변경일때 권리실행 가능합니다.');
						break;
				}
				this.$store.state.ApplicationFormCreationModule.receiveChk = true;
				return;
			}
			// 권리실행버튼 활성화
			applUnSub(this.$store.state.applTabType, postApplId);
			this.$store.state.applTabType = '/subscribe/application/right/';
			this.tabDialog.applTab = false;
			this.tabDialog.receiveTab = false;
			this.tabDialog.executionTab = true;
		},
		async getConsultMemoList(data) {
			this.memoList = [];
			this.memoList = await this.$store.dispatch(
				'SalesMgmtModule/getConsultMemoList',
				data,
			);
		},
		async getConsultHistoryList(data) {
			this.historyList = [];
			this.historyList = await this.$store.dispatch(
				'SalesMgmtModule/getConsultHistoryList',
				data,
			);
		},
		async consultCreditDetail(data) {
			this.creditTodoDetail = await this.$store.dispatch(
				'SalesMgmtModule/consultCreditDetail',
				data,
			);

			// 신조 완료시 내용 표출 (고객DB_신청서 상세)
			if (this.creditTodoDetail && this.creditTodoDetail.creditInquireResponseDto !== null) {
				this.creditTodoDetail.creditInquireResponseDto =
					this.creditTodoDetail.creditInquireResponseDto.creditInquireResult =
						JSON.parse(
							this.creditTodoDetail.creditInquireResponseDto.creditInquireResult.replace(
								/&quot;/g,
								'"',
							),
						);

				let creTxt = '';
				for (
					let i = 0;
					i <
					Object.keys(this.creditTodoDetail.creditInquireResponseDto).length;
					i++
				) {
					let keyName = Object.keys(
						this.creditTodoDetail.creditInquireResponseDto,
					)[i];
					creTxt +=
						keyName +
						' : ' +
						this.creditTodoDetail.creditInquireResponseDto[keyName] +
						'<br>';
				}

				this.creditTodoDetail.creditInquireResponseDto = creTxt;
			}

			if (
				this.creditTodoDetail &&
				this.creditTodoDetail.creditInquireDto !== null &&
				this.creditTodoDetail.creditInquireDto.memoContents
			) {
				let memoContents =
					this.creditTodoDetail.creditInquireDto.memoContents.replace(
						/\n/g,
						'<br>',
					);
				this.creditTodoDetail.creditInquireDto.memoContents = memoContents;
			} else {
				this.creView = 'Con';
			}

			// To-Do 알림이 있을시 데이터 표출
			if (this.creditTodoDetail && this.creditTodoDetail.consultTodoDto !== null) {
				if (this.creditTodoDetail.consultTodoDto.reservationDateTime !== null) {
					this.creditTodoDetail.consultTodoDto.time = this.$moment(
						this.creditTodoDetail.consultTodoDto.reservationDateTime,
					).format('HH:mm');
					this.creditTodoDetail.consultTodoDto.reservationDateTime =
						this.$moment(
							this.creditTodoDetail.consultTodoDto.reservationDateTime,
						).format('YYYY-MM-DD');
					this.applConsultTodoDto = this.creditTodoDetail.consultTodoDto;
				}
			}
		},
		async getList(data) {
			this.$emit('getList', data);
		},
		async reloadConsult(data) {
			this.$emit('reloadConsult', data);
		},
		async todoNotiYnChk() {
			if (this.applConsultTodoDto.notiYn === 'N') {
				this.applConsultTodoDto = {
					toDoContents: null,
					reservationDateTime: null,
					notiYn: 'N',
					time: { HH: '', mm: '' },
					consultTodoType: 'CUSTOMER',
					cnsltId: this.cnsltId,
				};
			}
		},
		async extendApplDeadLineDate() {
			if (!confirm('신청서 연장하시겠습니까?')) {
				return;
			}
			let data = { applId: this.applId };
			let result = await this.$store.dispatch(
				'QuickOpeningPopModule/updateApplicationDeadLineDate',
				data,
			);
			if (result.data.resultCode === '0000') {
				this.$route.query.applId = this.applId;
				bus.$emit('reloadAppl:appl');
				this.modifyState = false;
				this.appTapFnc();
			}
		},
		cancelApplReceipt() {
			bus.$emit('cancelApplReceipt');
		},
		getDiffDate() {
			if (
				this.$moment(new Date()).diff(
					this.$moment(this.formData.basic.deadLineDate),
					'days',
				) === 0
			) {
				return '만료당';
			} else if (
				this.$moment(new Date()).diff(
					this.$moment(this.formData.basic.deadLineDate),
					'days',
				) > 0
			) {
				return (
					'+' +
					this.$moment(new Date()).diff(
						this.$moment(this.formData.basic.deadLineDate),
						'days',
					)
				);
			} else {
				return (
					this.$moment(new Date()).diff(
						this.$moment(this.formData.basic.deadLineDate),
						'days',
					) - 1
				);
			}
		},
		async openChainAppl() {
			let id = null;
			if (this.formData.basic.parentApplId) {
				id = this.formData.basic.parentApplId;
			} else if (this.formData.basic.childApplId) {
				id = this.formData.basic.childApplId;
			}
			if (!id) {
				return true;
			}
			this.AppFormCreFlag = id;
			this.$refs.appliTbl.applReload();
			// let route = this.$router.resolve({
			// 	path: '/application-form-creation?applId=' + id + '&',
			// });
			// window.open(route.href, '_blank');
		},
		async switchCustomerAppl() {
			this.consultSwitchType = true;
			this.dialog = !this.dialog;
			await this.$emit('switchCustomerAppl');
		},
		consultDefaultFnc(cnsltId) {
			this.getConsultMemoList({ cnsltId: cnsltId });
			this.getConsultHistoryList({ cnsltId: cnsltId });
			this.consultCreditDetail({ cnsltId: cnsltId });
//			this.cnsltId = cnsltId;
		},
	},
	async created() {
		this.applConsultTodoDto = {
			toDoContents: null,
			reservationDateTime: null,
			notiYn: 'N',
			time: null,
		};
		if (this.cnsltId !== 0) {
			await this.getConsultMemoList({ cnsltId: this.cnsltId });
			await this.getConsultHistoryList({ cnsltId: this.cnsltId });
			await this.consultCreditDetail({ cnsltId: this.cnsltId });
		} else {
			this.AppFormCreFlag = undefined;
		}
		if (this.applId) {
			this.AppFormCreFlag = this.applId;
			this.AppFlag = Number(this.applId);
		}
		this.$store.state.applTabType = null;
		this.applConsultMemoDto.memoContents = null;

		postApplId = this.applId;
		await this.appTapFnc();
		bus.$on('callConsultDefaultFnc', this.consultDefaultFnc);
	},
	mounted() {
		// 브라우저 종료시 컨펌 로직
		if (this.AppFormCreFlag) {
			window.addEventListener('beforeunload', event => {
				// 명세에 따라 preventDefault는 호출해야하며, 기본 동작을 방지합니다.
				// 표준에 따라 기본 동작 방지
				this.$route.query.applId = null;
				applUnSub('/subscribe/application/main/', postApplId);
			});
		}
	},
	beforeDestroy() {
		this.dialog = false;
		this.reloadConsult();
		this.crListFlag = false; // 22.03.23 신조상태값 초기화 etc 항목 상담원 readonly 이슈
		if (this.AppFormCreFlag) {
			if(!this.$store.state.ApplicationFormCreationModule.receiveChk) {
				this.$route.query.applId = null;
			}
			applUnSub(this.$store.state.applTabType, postApplId);
		}
		bus.$off('callConsultDefaultFnc', this.consultDefaultFnc);
	},
});
</script>

<style scoped>
.new-btnA1 textarea {
	/*resize: none;*/
	/*font-size: 12px;*/
	height: 108px;
	width: 100%;
	border-radius: 5px 0 0 5px;
}
.consult_new_memo {
	line-height: 18px;
	resize: none;
	font-size: 12px;
	outline: none;
}
.consult_memo {
	line-height: 18px;
	resize: none;
	font-size: 12px;
	outline: none;
}
.appEndCont .btnCont {
	display: flex;
	justify-content: flex-end;
}
.appEndCont .btnCont button {
	padding: 0 8px;
	height: 32px;
	color: #fff;
	border-radius: 3px;
	transform: translateY(-2px);
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
		0 1px 5px 0 rgb(0 0 0 / 12%);
	transition: all 0.25s ease;
}
.appEndCont .btnCont button.backColorGreen:hover {
	background: #04a859 !important;
}
.appEndCont .btnCont button.backColorRed:hover {
	background: #ee2900 !important;
}
.creBoxLine {
	border-right: 1px solid #000;
}
.falseBtn1 {
	background-color: #e1effc;
	border: 1px solid #b3cce2;
	border-bottom: 0px;
	font-weight: normal !important;
}
.receiveTableCont1 {
	border-top: 1px;
}
.executionTableCont1 {
	border-top: 1px;
}
</style>
