<template>
	<!--	<div>
		<div>
			<button @click="getStream">아프리카TV ON</button>
		</div>
		<video
			width="300"
			height="169"
			:srcObject.prop.camel="stream"
			autoplay
			ref="video"
		></video>
		<canvas ref="canvas" id="canvas" width="300" height="169"></canvas>
		<div>
			<button id="snap" @click="capture">사진찍기</button>
		</div>
		&lt;!&ndash;		<ul>
			<li v-for="(c, idx) in captures" :key="idx" class="disIn">
				<img :src="c" width="100" height="70" />
			</li>
		</ul>&ndash;&gt;
		<div>
			<button @click="transFile(captures)">가보자가보자 파일로 가보자</button>
		</div>
	</div>-->
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="400">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">카메라</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad30">
						<div>
							<div>
								<div class="disFx mb10">
									<h2 class="mainFontS boldWt mainBlack">웹캠 화면</h2>
									<button @click="getStream" class="ml10 backColorBlue1 mainBlue1 borderRadi3Px padW10">
										촬영 시작 버튼
									</button>
								</div>
								<video
									style="background-color: #000"
									width="340"
									height="194"
									:srcObject.prop.camel="stream"
									autoplay
									ref="video"
								></video>
							</div>
							<div>
								<div class="disFx mb10 mt20">
									<h2 class="mainFontS boldWt mainBlack">촬영 화면</h2>
									<button id="snap" @click="capture" class="ml10 backColorBlue1 mainBlue1 borderRadi3Px padW10">
										사진촬영
									</button>
								</div>
								<canvas
									ref="canvas"
									id="canvas"
									width="340"
									height="194"
									style="background-color: #000"
								></canvas>
							</div>
						</div>
						<div class="disFx justify-end mt10">
							<v-btn
								text
								class="backColorBlue3 mainWhite boldWt borderReset w80 ml10"
								@click="transFile(captures)"
							>
								파일저장
							</v-btn>
							<v-btn
								text
								class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
								@click="dialog = false"
							>
								닫기
							</v-btn>
						</div>
						<!--						<ul>
							<li v-for="(c, idx) in captures" :key="idx" class="disIn">
								<img :src="c" width="100" height="70" />
							</li>
						</ul>-->
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RightExeWebCam',
	computed: {
		dialog: {
			get() {
				return this.$store.state.RightExecutionModule.cameraDialog;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.cameraDialog = newValue;
			},
		},
		attachList: {
			get() {
				return this.$store.state.RightExecutionModule.formData.attachList;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.formData.attachList = newValue;
			},
		},
		temFileData: {
			get() {
				return this.$store.state.RightExecutionModule.fileData;
			},
			set(newValue) {
				this.$store.state.RightExecutionModule.fileData = newValue;
			},
		},
	},
	data() {
		return {
			stream: null,
			canvas: {},
			captures: [],
			FileData: [],
			itemDate: '',
		};
	},
	methods: {
		async getStream() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: {
						width: 700,
						height: 400,
					},
					audio: false,
				});
				this.stream = stream;
			} catch (e) {
				console.error(e.message);
			}
		},
		async capture() {
			this.canvas = this.$refs.canvas;
			this.video = this.$refs.video;
			// 비디오 캡쳐
			this.canvas.getContext('2d').drawImage(this.video, 0, 0, 340, 194);
			this.captures = [];
			await this.captures.push(this.canvas.toDataURL('image/png'));
		},
		transFile(uri) {
			this.getCurrentDate();
			if (this.captures.length === 0) {
				return alert('사진 촬영 후 저장 버튼을 눌러주세요');
			}
			let FileImg = this.dataURItoBlob(uri[0]);
			const myFile = new File([FileImg], `image${this.itemDate}.jpeg`, {
				type: FileImg.type,
			});
			if (confirm('저장하시겠습니까')) {
				this.FileData.push(myFile);
				this.attachList = myFile;
				this.temFileData.push(myFile);
				this.dialog = false;
			}
		},
		getCurrentDate() {
			let date = new Date();
			let year = date.getFullYear().toString();

			let month = date.getMonth() + 1;
			month = month < 10 ? '0' + month.toString() : month.toString();

			let day = date.getDate();
			day = day < 10 ? '0' + day.toString() : day.toString();

			let hour = date.getHours();
			hour = hour < 10 ? '0' + hour.toString() : hour.toString();

			let minites = date.getMinutes();
			minites = minites < 10 ? '0' + minites.toString() : minites.toString();

			let seconds = date.getSeconds();
			seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

			this.itemDate = year + month + day + hour + minites + seconds;

			return this.itemDate;
		},
		dataURItoBlob(dataURI) {
			// uri 를 file 로 변환
			let byteString;
			if (dataURI.split(',')[0].indexOf('base64') >= 0)
				byteString = atob(dataURI.split(',')[1]);
			else byteString = unescape(dataURI.split(',')[1]);

			let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			let ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], { type: mimeString });
		},
	},
	created() {
		this.getStream();
		this.captures = [];
	},
};
</script>

<style scoped></style>
