import Vue from 'vue';
import Vuex from 'vuex';
import {
	insertData,
	insertRecipCard,
	getApplicationRecipDetail,
	getRecipCardList,
	updateRecipCard,
	updateApplicationRecip,
	deleteRecipCard,
} from '../../../../api/supply-chain-mgmt/application-form-creation/ReceiveRequestApi.js';
import { sendReload } from '@/common/socketUtil';

Vue.use(Vuex);

const ReceiveRequestModule = {
	namespaced: true,
	state: {
		fileData: [],
		propsFileList: [],
		firstFlag: false,
		openingStoreRecipReqStatus: '',
		titleIdx: 0,
		listNum: 0,
		recipData: {},
		cardSaveData: [],
		formData: {
			applRecipId: null,
			applId: null,
			nonPaymentAmt: 0,
			instlAmt: 0,
			recipAmt: 0,
			recipPoint: 0,
			paymentType: 'CUSTOMER_INDV',
			adjustDdctMemo: '',
			cardHolderName: '',
			recipCardName: '',
			cardHolderDateBirth: '',
			bizNum: '',
			recipCardId: '',
			cardNum: '',
			cardNum1: '',
			cardNum2: '',
			cardNum3: '',
			cardNum4: '',
			cardValidityMm: '',
			cardValidityYy: '',
			cardPassword: '',
			attachList: [],
			storeRecipReqStatus: 'RECIP_REQ',
			openingStoreRecipReqStatus: 'RECIP_COMPL',
			recipComplDate: '',
			recipPendReason: '',
		},
	},
	mutations: {
		setApplicationRecipDetailList(state, result) {
			// 포인트 수납이 null 일때 0원으로 세팅
			if (result.data.resultCode === '9000') {
				return (state.firstFlag = true);
			}
			state.recipData = result.data.data.recip;
			if (state.listNum === 0) {
				state.listNum = result.data.data.prevRecipIdList;
				state.titleIdx = result.data.data.prevRecipIdList.length;
				state.openingStoreRecipReqStatus = String(
					result.data.data.recip.openingStoreRecipReqStatus,
				);
			}
			if (!result.data.data.recip.recipPoint) {
				result.data.data.recip.recipPoint = 0;
			}
		},
		setRecipCardList(state, result) {
			state.cardSaveData = result.data.data;
		},
	},
	actions: {
		async insertData({ commit }, data) {
			// 등록
			try {
				const result = await insertData(data);
				if (result.data.resultCode === '0000') {
					sendReload('appl');
					alert('저장되었습니다.');
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertRecipCard({ commit }, data) {
			// 등록
			try {
				const result = await insertRecipCard(data);
				if (result.data.resultCode === '0000') {
					alert('저장되었습니다.');
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getApplicationRecipDetail({ commit }, data) {
			// 디테일콜
			if (!data.applRecipId) {
				let applId = {
					applId: data.applId,
					applRecipId: 0,
				};
				let result = await getApplicationRecipDetail(applId);
				if (result) {
					commit('setApplicationRecipDetailList', result);
					return result.data.data;
				}
			} else {
				let applId = {
					applId: data.applId,
					applRecipId: data.applRecipId,
				};
				let result = await getApplicationRecipDetail(applId);
				if (result) {
					commit('setApplicationRecipDetailList', result);
					return result.data.data;
				}
			}
		},
		async getRecipCardList({ commit }, data) {
			const result = await getRecipCardList(data);
			if (result) {
				commit('setRecipCardList', result);
			}
		},
		async updateRecipCard({ commit }, data) {
			const result = await updateRecipCard(data);
		},
		async updateApplicationRecip({ commit }, data) {
			const result = await updateApplicationRecip(data);
			if (result) {
				return result.data.data;
			}
		},
		async deleteRecipCard({ commit }, data) {
			const result = await deleteRecipCard(data);
		},
	},
};

export default ReceiveRequestModule;
