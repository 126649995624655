import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import ChargeList from '../views/common-mgmt/charge-mgmt/ChargeList.vue';
import ChargeRequestList from '../views/common-mgmt/charge-mgmt/ChargeRequestList.vue';
import { deleteCookies, getFromCookie } from '@/common/cookies';
import { scrollMainToTop } from '@/common/common';

Vue.use(VueRouter);
export const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			// 로그인
			path: '/',
			redirect: '/dashboard',
		},
		{
			// 로그인
			path: '/login',
			component: () => import(`../views/member/Login`),
			meta: { auth: true },
		},
		{
			// 회원가입
			path: '/sign-up',
			component: () => import('../views/member/SignUp.vue'),
			meta: { auth: true },
		},
		{
			// 영업점 회원가입 (초대링크를 통한 회원가입)
			path: '/store/sign-up',
			component: () => import('../views/member/SignUp.vue'),
			meta: { auth: true },
		},
		{
			// 회원정보 수정
			path: '/change-user-info',
			component: () => import('../views/member/ChangeUserInfo.vue'),
		},
		{
			// 비밀번호 수정
			path: '/change-password',
			component: () => import('../views/member/ChangePassword.vue'),
		},
		{
			// 사용자 생성 (초대링크를 통한 회원가입)
			path: '/user/sign-up',
			component: () => import('../views/member/UserSignUp.vue'),
			meta: { auth: true },
		},
		{
			// 대시보드
			path: '/dashboard',
			component: () => import('../components/dashboard/Dashboard'),
		},
		// TDL
		{
			path: '/to-do',
			component: () => import('../components/noticeList/Notice.vue'),
		},
		{
			// 1.재고관리 > 입고현황
			path: '/device',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/InStockStatusMgmt.vue'),
			redirect: '/device/in-stock-regi',
			children: [
				{
					path: 'in-stock-regi',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockInStatusMgmt/InStockRegi.vue'
						),
				},
				{
					path: 'in-stock-status',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockInStatusMgmt/InStockStatus.vue'
						),
				},
				{
					path: 'return-stock-status',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockInStatusMgmt/ReturnStockStatus.vue'
						),
				},
				{
					path: 'in-stock-history',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockInStatusMgmt/InStockStatus.vue'
						),
				},
			],
		},
		{
			// 1.재고관리 > 이동/이관
			path: '/stock-move-transfer',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt.vue'),
			redirect: '/stock-move-transfer/stock-move-sale',
			children: [
				{
					path: 'stock-move-sale',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/StockMoveSale.vue'
						),
				},
				{
					path: 'stock-move-transfer',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/StockMoveTransfer.vue'
						),
				},
				{
					// 재고관리 > 이동/이관현황
					path: 'move-status',
					component: () =>
						import('../views/warehouse-mgmt/device-mgmt/MoveStatus.vue'),
				},
				{
					// 재고관리 > 이동/이관이력
					path: 'move-history',
					component: () =>
						import('../views/warehouse-mgmt/device-mgmt/MoveStatus.vue'),
				},
				/*{
					path: 'stock-transfer',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/StockTransfer.vue'
						),
				},
				{
					path: 'faulty-transfer',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/FaultyTransfer.vue'
						),
				},
				{
					path: 'sales-transfer',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/SalesTransfer.vue'
						),
				},
				{
					path: 'rtn-transfer',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/RtnTransfer.vue'
						),
				},
				{
					path: 'sales-move',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/StockMoveTransferMgmt/SalesMove.vue'
						),
				},*/
			],
		},
		{
			path: '/matching',
			component: () => import('../views/supply-chain-mgmt/MatchingMgmt.vue'),
			redirect: '/matching/application-matching',
			children: [
				{
					// 1.재고관리 > 신청서매칭 > 신청서매칭
					path: 'application-matching',
					component: () =>
						import(
							'../views/supply-chain-mgmt/application-matching/ApplicationMatching.vue'
						),
				},
				{
					// 1.재고관리 > 신청서매칭 > 자동매칭
					path: 'auto-matching',
					component: () =>
						import(
							'../views/supply-chain-mgmt/application-matching/AutoMatching.vue'
						),
				},
			],
		},
		{
			// 1.재고관리 > 재고현황
			path: '/stock-status',
			component: () =>
				import('../views/warehouse-mgmt/stock-mgmt/StockStatusMgmt.vue'),
			redirect: '/stock-status/stock-status',
			children: [
				// {
				// 	// 1.재고관리 > 재고현황
				// 	path: 'stock-status',
				// 	component: () =>
				// 		import('../views/warehouse-mgmt/stock-mgmt/StockStatus.vue'),
				// },
				{
					// 1_1.재고관리 > 재고현황(관리기기와 병합)
					path: 'stock-status',
					component: () =>
						import('../views/warehouse-mgmt/stock-mgmt/StockMgmtStatus.vue'),
				},
				// 1.재고관리 > 관리기기현황 > 조정출고
				{
					path: 'mediation-release',
					component: () =>
						import(
							'../views/warehouse-mgmt/stock-mgmt/MgmtTargetDevice/MediationRelease.vue'
						),
				},
				{
					// 2.재고관리 > 기기/유심현황
					path: 'device-status',
					component: () =>
						import('../views/warehouse-mgmt/device-mgmt/DeviceStatus.vue'),
				},
				{
					// 3.재고관리 > 보유처별 재고현황
					path: 'stock-stats',
					component: () =>
						import('../views/warehouse-mgmt/stock-mgmt/StockStats.vue'),
				},
			],
		},
		{
			path: '/mgmt-target',
			component: () =>
				import('../views/warehouse-mgmt/stock-mgmt/MgmtTargetDevice.vue'),
			redirect: '/mgmt-target/mgmt-target-device',
			children: [
				// 1.재고관리 > 관리기기현황 > 관리기기현황
				{
					path: 'mgmt-target-device',
					component: () =>
						import(
							'../views/warehouse-mgmt/stock-mgmt/MgmtTargetDevice/MgmtTargetDeviceMgmt.vue'
						),
				},
				// 1.재고관리 > 관리기기현황 > 조정출고
				// {
				// 	path: 'mediation-release',
				// 	component: () =>
				// 		import(
				// 			'../views/warehouse-mgmt/stock-mgmt/MgmtTargetDevice/MediationRelease.vue'
				// 		),
				// },
				// // 1.재고관리 > 관리기기현황 > 개통이력기기
				// {
				// 	path: 'open-store-device-status',
				// 	component: () =>
				// 		import(
				// 			'../views/warehouse-mgmt/device-mgmt/OpenStoreDeviceStatus.vue'
				// 		),
				// },
				// // 1.재고관리 > 관리기기현황 > 장기재고현황
				// {
				// 	path: 'longtime-stock',
				// 	component: () =>
				// 		import('../views/warehouse-mgmt/stock-mgmt/LongtimeStock'),
				// },
				// // 1.재고관리 > 관리기기현황 > 불량기기현황
				// {
				// 	path: 'faulty-device-status',
				// 	component: () =>
				// 		import('../views/warehouse-mgmt/stock-mgmt/FaultyDeviceStatus.vue'),
				// },
				// {
				// 	path: 'mediation-release',
				// 	component: () =>
				// 		import(
				// 			'../views/warehouse-mgmt/stock-mgmt/MgmtTargetDevice/MediationRelease.vue'
				// 			),
				// },
			],
		},
		{
			// 1.재고관리 or 판매관리 > 권리실행
			path: '/right-execution',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/RightExecution.vue'),
		},
		{
			// 1.재고관리 > 업체관리
			path: '/co-mgmt',
			component: () => import('../views/warehouse-mgmt/device-mgmt/CoMgmt.vue'),
			redirect: '/co-mgmt/provider-mgmt',
			children: [
				{
					path: 'provider-mgmt',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/co-mgmt/ProviderMgmt.vue'
						),
				},
				{
					path: 'hold-store-mgmt',
					component: () =>
						import(
							'../views/warehouse-mgmt/device-mgmt/co-mgmt/HoldStoreMgmt.vue'
						),
				},
			],
		},
		{
			// 2.판매관리 > 신용조회 결과
			path: '/credit-check',
			name: 'credit-check',
			component: () => import('../views/credit-check-mgmt/CreditCheck.vue'),
		},
		{
			// 2.판매관리 > 신청서작성
			path: '/application-form-creation',
			component: () =>
				import('../views/supply-chain-mgmt/ApplicationFormCreation.vue'),
			props: true,
		},
		{
			// 2.판매관리 > 판매현황
			path: '/sell-status',
			component: () => import('../views/supply-chain-mgmt/SellMgmt.vue'),
			children: [
				{
					// 2.판매관리 > 판매현황 > 판매현황
					path: '',
					props: true,
					name: 'sell-status',
					component: () => import('../views/supply-chain-mgmt/SellStatus.vue'),
				},
				{
					// 2.판매관리 > 판매현황 > 수납요청
					path: 'receive-request',
					component: () =>
						import('../views/warehouse-mgmt/device-mgmt/ReceiveRequest.vue'),
				},
				// 2.판매관리 > 판매현황 > 블랙리스트관리
				{
					path: 'black-list',
					component: () =>
						import('../views/supply-chain-mgmt/BlackListMgmt.vue'),
				},
				// 2.판매관리 > 판매현황 > 서명내역
				{
					path: 'signature-mgmt',
					component: () =>
						import('../views/supply-chain-mgmt/SignatureMgmt.vue'),
				},
				// 2.판매관리 > 판매현황 > 리텐션DB
				{
					path: 'retention-mgmt',
					component: () =>
						import(
							'../views/supply-chain-mgmt/manager-store-retention-mgmt/ManagerStoreRetentionDb.vue'
						),
				},
			],
		},
		{
			// 3.영업관리 > 신용조회 요청
			path: '/credit-check-request',
			name: 'credit-check-request',
			component: () => import('../views/credit-check-mgmt/CreditCheck.vue'),
		},
		{
			// 3.영업관리 > 상담관리
			path: '/consult-mgmt',
			component: () =>
				import('../views/sales-mgmt/consult-mgmt/ConsultMgmt.vue'),
			redirect: '/consult-mgmt/customer-consult',
			children: [
				{
					// 3.영업관리 > 상담관리 > 오토콜(예정)
					path: 'auto-call',
					component: () =>
						import('../views/sales-mgmt/consult-mgmt/auto-call/AutoCall.vue'),
				},
				{
					// 3.영업관리 > 상담관리 > 고객상담
					path: 'customer-consult',
					name: 'customer-consult',
					component: () =>
						import(
							'../views/sales-mgmt/consult-mgmt/customer-consult/CustomerConsult.vue'
						),
				},
				{
					// 3.영업관리 > 상담관리 > 상담DB
					path: 'consult-db',
					name: 'consult-db',
					component: () =>
						import('../views/sales-mgmt/consult-mgmt/consult-db/ConsultDb.vue'),
				},
			],
		},
		{
			// 3.영업관리 > 고객관리
			path: '/customer-mgmt',
			component: () => import('../views/sales-mgmt/customer-mgmt/CustomerMgmt'),
			redirect: '/customer-mgmt/customer-db',
			children: [
				{
					// 3.영업관리 > 고객관리 > 고객DB
					path: 'customer-db',
					name: 'customer-db',
					component: () =>
						import(
							'../views/sales-mgmt/customer-mgmt/customer-db/CustomerDb.vue'
						),
				},
				{
					// 3.영업관리 > 고객관리 > DB추출
					path: 'export-db',
					component: () =>
						import('../views/sales-mgmt/customer-mgmt/export-db/ExportDb.vue'),
				},
				{
					// 3.영업관리 > 고객관리 > 리텐션DB
					path: 'retention-mgmt',
					component: () =>
						import(
							'../views/sales-mgmt/customer-mgmt/sales-store-retention-mgmt/SalesStoreRetentionDb.vue'
						),
				},
				{
					// 3.영업관리 > 상담관리 > 요금계산기
					path: 'charge-calculator',
					component: () =>
						import(
							'../views/sales-mgmt/consult-mgmt/charge-calculator/ChargeCalculator.vue'
						),
				},
				{
					// 3.영업관리 > 상담관리 > 블랙리스트
					path: 'black-list',
					component: () =>
						import(
							'../views/sales-mgmt/consult-mgmt/black-list/ConsultBlackList'
						),
				},
				{
					// 3.영업관리 > 고객관리 > 서명 내역
					path: 'signature-mgmt',
					component: () =>
						import('../views/supply-chain-mgmt/SignatureMgmt.vue'),
				},
			],
		},
		{
			// 3.영업관리 > 환경관리
			path: '/env-settings',
			component: () =>
				import('../views/sales-mgmt/env-settings/EnvSettings.vue'),
			redirect: '/env-settings/consult-notice',
			children: [
				{
					// 3.영업관리 > 환경관리 > 공지관리
					path: 'consult-notice',
					component: () =>
						import(
							'../views/sales-mgmt/env-settings/consult-notice/ConsultNotice.vue'
						),
				},
				{
					// 3.영업관리 > 환경관리 > 상담관리 설정
					path: 'consult-setting',
					component: () =>
						import(
							'../views/sales-mgmt/env-settings/consult-setting/ConsultSetting.vue'
						),
				},
			],
		},
		{
			// 8.통계관리
			path: '/sale-stats',
			component: () => import('../views/sales-mgmt/sale-status/SaleStats.vue'),
		},
		{
			// 4.정산관리 > 채권관리
			path: '/bond-mgmt',
			component: () => import('../views/settlement-mgmt/BondMgmt'),
			redirect: '/bond-mgmt/bond-status/skt',
			children: [
				{
					// 3.정산관리 > SKT채권관리
					path: 'bond-status/skt',
					component: () =>
						import('../views/settlement-mgmt/BondMgmt/SktBondStatus.vue'),
				},
				{
					// 3.정산관리 > KT채권관리
					path: 'bond-status/kt',
					component: () =>
						import('../views/settlement-mgmt/BondMgmt/KtBondStatus.vue'),
				},
				{
					// 3.정산관리 > LGU채권관리
					path: 'bond-status/lgu',
					component: () =>
						import('../views/settlement-mgmt/BondMgmt/LguBondStatus.vue'),
				},
				{
					path: 'compe-status',
					component: () =>
						import('../views/settlement-mgmt/BondMgmt/CompeStatus.vue'),
				},
				{
					path: 'month-summary',
					component: () =>
						import('../views/settlement-mgmt/BondMgmt/bondMonthSummary.vue'),
				},
			],
		},
		{
			// 5.커뮤니티 > 민원처리내역
			path: '/complain-hndlg',
			component: () => import('../views/comm-mgmt/ComplainHndlg'),
		},
		{
			// 5_1.커뮤니티 > 고객센터
			path: '/cs-mgmt',
			component: () => import('../views/common-mgmt/csMgmt'),
			redirect: '/cs-mgmt/noticelist',
			children: [
				{
					// 5_1.커뮤니티 > 고객센터 > 공지사항
					path: 'noticelist',
					name: 'csnoticelist',
					component: () =>
						import(
							'../views/common-mgmt/system-mgmt/systemt-notice/SystemNotice.vue'
						),
				},
				{
					// 5_1.커뮤니티 > 고객센터 > 기술 및 장애문의
					path: 'qalist',
					component: () =>
						import('../views/common-mgmt/cs-mgmt/qa-board/qaBoard.vue'),
				},
			],
		},
		{
			// 6.권한설정 > 사업자관리
			path: '/bsnm-mgmt',
			component: () => import('../views/common-mgmt/BsnmMgmt.vue'),
			redirect: '/bsnm-mgmt/openingstore-mgmt',
			children: [
				{
					// 6.권한설정 > 사업자관리 > 개통점관리
					path: 'openingstore-mgmt',
					component: () =>
						import(
							'../views/common-mgmt/business-man-mgmt/OpeningStoreMgmt.vue'
						),
				},
				{
					// 6.권한설정 > 사업자관리 > 영업점관리
					path: 'salestore-mgmt',
					component: () =>
						import('../views/common-mgmt/business-man-mgmt/SaleStoreMgmt.vue'),
				},
				{
					// 6.권한설정 > 사업자관리 > 개통점 영업점매칭
					path: 'openingstorebcnc-matching',
					component: () =>
						import(
							'../views/common-mgmt/business-man-mgmt/OpeningStoreBcncMatching.vue'
						),
				},
				{
					// 6.권한설정 > 사업자관리 > 개통점 사용자매칭
					path: 'openingstoreuser-matching',
					component: () =>
						import(
							'../views/common-mgmt/business-man-mgmt/OpeningStoreUserMatching.vue'
						),
				},
			],
		},
		{
			// 6.권한설정 > 조직관리
			path: '/orgnzt-mgmt',
			component: () => import('../views/common-mgmt/OrgnztMgmt.vue'),
			redirect: '/orgnzt-mgmt/organization-mgmt',
			children: [
				{
					// 6.권한설정 > 조직관리 > 조직관리
					path: 'organization-mgmt',
					component: () =>
						import(
							'../views/common-mgmt/organization-mgmt/OrganizationMgmt.vue'
						),
				},
				{
					// 6.권한설정 > 조직관리 > 역할관리
					path: 'userrole-mgmt',
					component: () =>
						import('../views/common-mgmt/organization-mgmt/UserRoleMgmt.vue'),
				},
			],
		},
		{
			// 7.관리설정 > 시스템관리
			path: '/system-mgmt',
			component: () =>
				import('../views/common-mgmt/system-mgmt/SystemMgmt.vue'),
			redirect: '/system-mgmt/terms-mgmt',
			children: [
				{
					// 약관 목록
					path: 'terms-mgmt',
					component: () =>
						import('../views/mall_mgmt/terms_mgmt/TermsMgmt.vue'),
				},
			],
		},
		{
			// 약관 등록
			path: '/system-mgmt/terms-regi',
			component: () =>
				import('../views/mall_mgmt/terms_mgmt/TermsRegiForm.vue'),
		},
		{
			// 약관 상세
			path: '/system-mgmt/terms-detail',
			component: () => import('../views/mall_mgmt/terms_mgmt/TermsDetail.vue'),
		},
		{
			// 7.관리설정 > 상품관리
			path: '/goods-mgmt',
			component: () => import('../views/common-mgmt/goods-mgmt/GoodsMgmt.vue'),
			redirect: '/goods-mgmt/mobilephonelist',
			children: [
				{
					// 7.관리설정 > 상품관리 > 상품 목록
					path: 'mobilephonelist',
					component: () =>
						import('../views/common-mgmt/goods-mgmt/MobilephoneList.vue'),
				},
				{
					// 7.관리설정 > 상품관리 > 상품요청목록
					path: 'mobilephone-requestlist',
					component: () =>
						import(
							'../views/common-mgmt/goods-mgmt/MobilephoneRequestList.vue'
						),
				},
				{
					// 7.관리설정 > 상품관리 > 상품 그룹 목록
					path: 'mobilephone-grouplist',
					component: () =>
						import('../views/common-mgmt/goods-mgmt/MobilephoneGroupList.vue'),
				},
			],
		},
		{
			// 7.관리설정 > 요금관리
			path: '/charge-mgmt',
			component: () =>
				import('../views/common-mgmt/charge-mgmt/ChargeMgmt.vue'),
			redirect: '/charge-mgmt/chargelist',
			children: [
				{
					// 7.관리설정 > 요금관리 > 요금목록
					path: 'chargelist',
					component: ChargeList,
				},
				{
					// 7.관리설정 > 요금관리 > 요금요청목록
					path: 'chargerequest-list',
					component: ChargeRequestList,
				},
			],
		},
		{
			// 7.관리설정 > 부가서비스 관리
			path: '/additionservice-mgmt',
			component: () =>
				import(
					'../views/common-mgmt/addition-service-mgmt/AdditionServiceMgmt.vue'
				),
			redirect: '/additionservice-mgmt/additionservice-list',
			children: [
				{
					// 7.관리설정 > 부가서비스 관리 > 부가서비스목록 (SKT)
					path: 'additionservice-list',
					component: () =>
						import(
							'../views/common-mgmt/addition-service-mgmt/AdditionServiceList.vue'
						),
				},
				{
					// 7.관리설정 > 부가서비스 관리 > (KT)
					path: 'additionservicerequest-list',
					component: () =>
						import(
							'../views/common-mgmt/addition-service-mgmt/AdditionServiceRequestList.vue'
						),
				},
			],
		},
		{
			// 7.관리설정 > 부가서비스 관리 > 부가서비스 요청목록
			path: '/additionservice-requestList',
			component: () =>
				import(
					'../views/common-mgmt/addition-service-mgmt/AdditionServiceRequestList'
				),
		},
		{
			// 7.관리설정 > 공시지원금관리
			path: '/pnsupportmoney-mgmt',
			component: () =>
				import(
					'../views/common-mgmt/pn-support-money-mgmt/PnSupportMoneyMgmt.vue'
				),
			redirect: '/pnsupportmoney-mgmt/pnsupportmoney',
			children: [
				{
					// 7.관리설정 > 공시지원금관리 > 공시지원금관리
					path: 'pnsupportmoney',
					component: () =>
						import(
							'../views/common-mgmt/pn-support-money-mgmt/PnSupportMoney.vue'
						),
				},
				{
					// 7.관리설정 > 공시지원금관리 > 공시지원금데이터관리
					path: 'pnsupportmoneydata',
					component: () =>
						import(
							'../views/common-mgmt/pn-support-money-mgmt/PnSupportMoneyData.vue'
						),
				},
			],
		},
		{
			// 8.통계관리
			path: '/stats-mgmt',
			component: () => import('../views/supply-chain-mgmt/StatsMgmt.vue'),
		},

		// 00. 기타 페이지
		// (예전에 사용했다가 사라진 페이지)
		{
			// 상품데이터관리
			path: '/goodsdata-mgmt',
			component: () =>
				import('../views/common-mgmt/goods-mgmt/GoodsDataMgmt.vue'),
		},
		{
			// 요금데이터관리
			path: '/chargedata-mgmt',
			component: () =>
				import('../views/common-mgmt/charge-mgmt/ChargeDataMgmt.vue'),
		},
		{
			path: '/goods-mgmt/resultPage',
			component: () =>
				import('../views/common-mgmt/organization-mgmt/resultPage.vue'),
		},
		{
			path: '/goods-mgmt/',
			component: () => import('../views/common-mgmt/goods-mgmt/test.vue'),
		},
		//	물류 renewal 후 사라질 페이지
		{
			// 유심현황
			path: '/usim-status',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/UsimStatus.vue'),
		},
		{
			// 반품현황
			path: '/move-stock-rtngud',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/MoveStockRtngud.vue'),
		},
		// {
		// 	// 장기재고
		// 	path: '/longtime-stock',
		// 	component: () =>
		// 		import('../views/warehouse-mgmt/stock-mgmt/LongtimeStock.vue'),
		// },
		// {
		// 	// 불량기기현황
		// 	path: '/faulty-device-status',
		// 	component: () =>
		// 		import('../views/warehouse-mgmt/stock-mgmt/FaultyDeviceStatus.vue'),
		// },
		// {
		// 	// 개통이력기기현황
		// 	path: '/open-store-device-status',
		// 	component: () =>
		// 		import('../views/warehouse-mgmt/device-mgmt/OpenStoreDeviceStatus.vue'),
		// },
		{
			// 재고관리 > 기기현황
			path: '/device-status',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/DeviceStatus.vue'),
		},
		{
			// 재고관리 > 기기/유심현황
			path: '/device-usim',
			component: () =>
				import('../views/warehouse-mgmt/device-mgmt/DeviceUsimStatus.vue'),
			redirect: '/device-usim/device-status',
			children: [
				{
					path: 'device-status',
					component: () =>
						import('../views/warehouse-mgmt/device-mgmt/DeviceStatus.vue'),
				},
				{
					path: 'usim-status',
					component: () =>
						import('../views/warehouse-mgmt/device-mgmt/UsimStatus.vue'),
				},
			],
		},
		{
			// MallMgmt : 상품관리 > 쇼핑몰 페이지 관리
			path: '/product-mgmt',
			component: () => import('../views/mall_mgmt/ProductMgmt.vue'),
			redirect: '/product-mgmt/mall-page-mgmt',
			children: [
				{
					path: 'mall-page-mgmt',
					component: () =>
						import('../views/mall_mgmt/page_mgmt/MallPageMgmt.vue'),
				},
				{
					path: 'mall-product-mgmt',
					component: () =>
						import('../views/mall_mgmt/product_mgmt/MallProductMgmt.vue'),
				},
				{
					path: 'mall-judge-mgmt',
					component: () =>
						import('../views/mall_mgmt/judge_mgmt/MallJudgeMgmt.vue'),
				},
			],
		},
		// 상품관리 : 상품 등록/상세
		{
			path: '/product-mgmt/mall-product-form',
			props: true,
			component: () =>
				import(
					'../views/mall_mgmt/product_mgmt/mall_product/MallProductRegi.vue'
				),
		},
		// 상품관리 : 정책 등록/상세
		{
			path: '/product-mgmt/mall-judge-form',
			props: true,
			component: () =>
				import('../views/mall_mgmt/judge_mgmt/mall_judge/MallJudgeRegi.vue'),
		},
		{
			// MallMgmt : 의뢰 게시판
			path: '/producing-mgmt',
			component: () => import('../views/mall_mgmt/ProducingMgmt.vue'),
			redirect: '/producing-mgmt/logo-req-list',
			children: [
				{
					path: 'logo-req-list',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/logo_req_mgmt/LogoReqList.vue'
						),
				},
				{
					path: 'logo-req-regi',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/logo_req_mgmt/LogoReqRegiForm.vue'
						),
				},
				{
					path: 'logo-req-detail',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/logo_req_mgmt/LogoReqDetailForm.vue'
						),
				},
				{
					path: 'event-req-list',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/event_req_mgmt/EventReqList.vue'
						),
				},
				{
					path: 'event-req-regi',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/event_req_mgmt/EventReqRegiForm.vue'
						),
				},
				{
					path: 'event-req-detail',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/event_req_mgmt/EventReqDetailForm.vue'
						),
				},
				{
					path: 'request-list',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/request_mgmt/RequestList.vue'
						),
				},
				{
					path: 'request-regi',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/request_mgmt/RequestRegiForm.vue'
						),
				},
				{
					path: 'request-detail',
					component: () =>
						import(
							'../views/mall_mgmt/producing_request_board/request_mgmt/RequestDetailForm.vue'
						),
				},
			],
		},
		{
			path: '/producing-mgmt/req-main',
			props: true,
			component: () =>
				import('../views/mall_mgmt/producing_request_board/ProducingMainForm'),
		},
		{
			// MallMgmt : 환경설정 > 이벤트 정보
			path: '/mall-setting',
			component: () => import('../views/mall_mgmt/SettingMgmt.vue'),
			redirect: '/mall-setting/mall-event-mgmt',
			children: [
				{
					path: 'mall-event-mgmt',
					component: () =>
						import('../views/mall_mgmt/event_mgmt/MallEventMgmt.vue'),
				},
				{
					path: 'mall-domain-mgmt',
					component: () =>
						import('../views/mall_mgmt/domain_mgmt/MallDomainMgmt.vue'),
				},
			],
		},
		{
			path: '/mall-setting/mall-event-regi',
			props: true,
			component: () =>
				import('../views/mall_mgmt/event_mgmt/MallEventRegiForm.vue'),
		},
		{
			path: '/mall-setting/mall-event-detail',
			props: true,
			component: () =>
				import('../views/mall_mgmt/event_mgmt/MallEventDetailForm.vue'),
		},
		{
			// MallMgmt : 온라인 몰 입점 페이지
			path: '/mall-import',
			name: 'mall-import',
			component: () => import('../views/mall_mgmt/MallImport.vue'),
		},
		{
			// MallMgmt : 구매 고객 관리
			path: '/mall-member-mgmt',
			component: () => import('../views/mall_mgmt/MemberMgmt.vue'),
			redirect: '/mall-member-mgmt/mall-member-list',
			children: [
				{
					// 쇼핑몰 회원 관리 목록
					path: 'mall-member-list',
					component: () =>
						import('../views/mall_mgmt/member_mgmt/MallMemberMgmt.vue'),
				},
				{
					// 리뷰 관리 목록
					path: 'mall-review-list',
					component: () =>
						import('../views/mall_mgmt/review_mgmt/MallReviewMgmt.vue'),
				},
			],
		},
		// 2022.09.26 회원 수동등록 제거 / 비밀번호 초기화 제거
		// {
		// 	// 회원 등록 폼
		// 	path: '/mall-member-mgmt/mall-member-regi',
		// 	props: true,
		// 	component: () =>
		// 		import('../views/mall_mgmt/member_mgmt/MallMemberRegiForm.vue'),
		// },
		{
			// 회원 상세 폼
			path: '/mall-member-mgmt/mall-member-detail',
			props: true,
			component: () =>
				import('../views/mall_mgmt/member_mgmt/MallMemberDetail.vue'),
		},
		{
			// 리뷰 등록 폼
			path: '/mall-member-mgmt/mall-review-regi',
			props: true,
			component: () =>
				import('../views/mall_mgmt/review_mgmt/MallReviewRegiForm.vue'),
		},
		{
			// 리뷰 상세 폼
			path: '/mall-member-mgmt/mall-review-detail',
			props: true,
			component: () =>
				import('../views/mall_mgmt/review_mgmt/MallReviewDetail.vue'),
		},
	],
});

// 운영 배포시
// chunk load error 발생시 reload
router.onError((error) => {
	if (
		error.message.indexOf('Loading chunk') >= 0 &&
		error.message.indexOf('failed') >= 0
	) {
		window.location.reload();
	}
});

// 라우터 네이게이션 가드
// // 라우터에 설정해놓은 meta.auth 와 로그인한 정보가 없을시 login page 로 이동
router.beforeEach((to, from, next) => {
	if (process.env.VUE_APP_NAME === 'production') {
		// 스토리지에 마지막 새로고침 시간
		let prevRefreshTime = window.localStorage.getItem('refreshTime');
		// 스토리지에 새로고침시간이 없다면 0
		if (prevRefreshTime === null) {
			prevRefreshTime = 0;
		}
		let prevDiffNowTime = new Date().getTime() / 1000 - prevRefreshTime;
		if (
			(document.referrer === '' ||
				document.referrer.indexOf(process.env.VUE_APP_HOST) < 0) &&
			prevDiffNowTime > 1800
		) {
			// 1. 외부 유입 URL 이라면,
			// 2. 외부유입 URL 어드민 URL 이 아닐경우,
			// 3. 마지막 새로고친시간에서 1시간이 경과된경우

			// 스토리지에 새로고침시간 설정
			window.localStorage.setItem('refreshTime', new Date().getTime() / 1000);
			window.location.replace(window.location.href); // 새로고침
		}
	}

	if (!to.meta.auth && getFromCookie('ms') === '') {
		//window.localStorage.clear();
		window.localStorage.removeItem('mp');
		window.localStorage.removeItem('lastVersion');
		window.localStorage.removeItem('newVersion');
		deleteCookies(store.state.cookies);
		next('/login');
		return;
	}
	if (!to.meta.auth && !store.getters.isLogin) {
		//window.localStorage.clear();
		window.localStorage.removeItem('mp');
		window.localStorage.removeItem('lastVersion');
		window.localStorage.removeItem('newVersion');
		deleteCookies(store.state.cookies);
		next('/login');
		return;
	}
	// 로그인 한 정보가 있을시 /login url 이동하면 logout 기능요청 후 /login 으로 리다이렉트
	if (to.path === '/login' && store.getters.isLogin) {
		if (store.state.NoticeModule.notiPopList.length > 0) {
			store.state.NoticeModule.notiPopList = [];
		}
		if (store.state.NoticeModule.activeRnb === true)
			store.state.NoticeModule.activeRnb = false;
		store.dispatch('LOGOUT', 'Y');
	}
	if (to.path === '/sign-up' && store.getters.isLogin) {
		store.dispatch('LOGOUT', 'Y');
	}
	if (to.path === '/user/sign-up' && store.getters.isLogin) {
		store.dispatch('LOGOUT2', 'Y');
	}
	if (to.path === '/store/sign-up') {
		let params = new URL(document.location).searchParams;
		let at = params.get('at');
		if (at) {
			store.dispatch('INVITATION_JOIN');
		} else {
			store.dispatch('LOGOUT2', 'Y');
		}
	}
	if (
		to.path === '/consult-mgmt/customer-consult' &&
		store.state.cookies.cr ===
			'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd'
	) {
		next('/consult-mgmt/consult-db');
		return;
	}
	// if (to.path === '/'
	next();
	scrollMainToTop();
	// store.commit('initInterceptor');
	if (
		to.path === '/mall-setting/mall-event-mgmt' &&
		store.state.cookies.st === '22f353197e9b0c1cb58a11da8de7776a'
	) {
		next('/mall-setting/mall-domain-mgmt');
	}
});

// 버전관리 & 갱신
router.afterEach(() => {
	let lastVersion = window.localStorage.getItem('lastVersion');
	let newVersion = window.localStorage.getItem('newVersion');
	// console.log('lastVersion= ' + lastVersion)
	// console.log('newVersion= ' + newVersion)
	if (lastVersion !== newVersion) {
		window.localStorage.setItem('lastVersion', newVersion);
		window.location.replace(window.location.href);
	}
});
