import { instance, instanceAuth, instanceExcel } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = '/DeviceManagement/ReturnStockMgmt/';
const getReturnStockList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getReturnStockList${param}`);
	return response;
};
const getDeviceInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getDeviceInfo${param}`);
	return response;
};
const insertReturnStock = async data => {
	const response = await instance.post(`${domain}insertReturnStock`, data);
	return response;
};
const insertReturnStockExcel = async data => {
	const response = await instance.post(`${domain}insertReturnStockExcel`, data);
	return response;
};
const insertReturnStockExcelException = async data => {
	const response = await instanceExcel.post(
		`/download/excel/insertReturnStockExcelException`,
		data,
	);
	return response;
};

// 반품기기 조회
const getReturnDevice = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getReturnDevice${param}`);
	return response;
};

// 반품가능여부 체크(renewal)
const returnStoreWaitCheck = async data => {
	const response = await instance.post(`${domain}returnStoreWaitCheck`, data);
	return response;
};

// 반품 대기 등록(renewal)
const insertReturnStockWait = async data => {
	const response = await instance.post(`${domain}insertReturnStockWait`, data);
	return response;
};

// 반품 대기 수정(renewal)
const updateReturnStockWait = async data => {
	const response = await instance.post(`${domain}updateReturnStockWait`, data);
	return response;
};

// 반품 대기목록 조회(renewal)
const getReturnStockWaitList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getReturnStockWaitList${param}`);
	return response;
};

// 반품 대기 삭제(renewal)
const deleteReturnStockWait = async data => {
	const response = await instance.post(`${domain}deleteReturnStockWait`, data);
	return response;
};
export {
	getReturnStockList,
	insertReturnStock,
	getDeviceInfo,
	insertReturnStockExcel,
	insertReturnStockExcelException,
	getReturnStockWaitList,
	deleteReturnStockWait,
	getReturnDevice,
	returnStoreWaitCheck,
	insertReturnStockWait,
	updateReturnStockWait,
};
