import { instance } from '../../index';
import { jsonToQueryString } from '../../../common/common';

const domain = 'ComplainManagement/ComplainMgmt/';

// 1. 민원 템플릿 리스트 조회
const getComplainTemplateRecipList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getComplainTemplateRecipList${param}`,
	);
	return response;
};
// 2. 민원 템플릿 상세 조회
const getComplainRecipDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getComplainRecipDetail${param}`,
	);
	return response;
};
// 3. 민원 템플릿 등록
const insertComplainTemplate = async data => {
	const response = await instance.post(`${domain}insertComplainTemplate`, data);
	return response;
};
// 4. 민원 템플릿 수정
const updateComplainTemplate = async data => {
	const response = await instance.post(`${domain}updateComplainTemplate`, data);
	return response;
};
// 5. 민원 템플릿 삭제
const deleteComplainTemplate = async data => {
	const response = await instance.post(`${domain}deleteComplainTemplate`, data);
	return response;
};
// 6. 민원 처리 리스트 조회
const getComplainList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getComplainList${param}`);
	return response;
};
// 7. 민원 처리 상세 조회
const getComplainDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getComplainDetail${param}`);
	return response;
};
// 8. 민원 처리 등록
const insertComplain = async data => {
	const response = await instance.post(`${domain}insertComplain`, data);
	return response;
};
// 9. 민원 처리 수정
const updateComplain = async data => {
	const response = await instance.post(`${domain}updateComplain`, data);
	return response;
};
// 10. 민원내용 추가 등록
const insertComplainDetail = async data => {
	const response = await instance.post(`${domain}insertComplainDetail`, data);
	return response;
};
// 11. 민원 관련 파일 리스트 조회
const getComplainAttachMgmtList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getComplainAttachMgmtList${param}`,
	);
	return response;
};
// 12. 민원 관련 파일 등록
const updateComplainAttachMgmt = async data => {
	const response = await instance.post(
		`${domain}updateComplainAttachMgmt`,
		data,
	);
	return response;
};
// 13. 민원 관련 파일 삭제
const deleteComplainAttachMgmt = async data => {
	const response = await instance.post(
		`${domain}deleteComplainAttachMgmt`,
		data,
	);
	return response;
};
// 14. 민원 처리 항목 상태 카운트 조회
const getComplainStatusCnt = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getComplainStatusCnt${param}`);
	return response;
};
// 15. 민원 삭제
const deleteComplain = async data => {
	const response = await instance.post(`${domain}deleteComplain`, data);
	return response;
};

export {
	getComplainTemplateRecipList,
	getComplainRecipDetail,
	insertComplainTemplate,
	updateComplainTemplate,
	deleteComplainTemplate,
	getComplainList,
	getComplainDetail,
	insertComplain,
	updateComplain,
	insertComplainDetail,
	getComplainAttachMgmtList,
	updateComplainAttachMgmt,
	deleteComplainAttachMgmt,
	getComplainStatusCnt,
	deleteComplain,
};
