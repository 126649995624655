export default {
	methods: {
		regiRepFnc() {
			let regiNum = document.getElementsByClassName('regiNum');
			for (let i = 0; i < regiNum.length; i++) {
				//@ts-ignore
				if (regiNum[i].value.length < 13) {
					regiNum[i].value = regiNum[i].value.replace(
						/(\d{6})(\d{1})/,
						'$1-$2',
					);
				} else {
					regiNum[i].value = regiNum[i].value.replace(
						/(\d{6})(\d{7})/,
						'$1-$2',
					);
				}
			}
		},
		phoneRepFnc() {
			let phoneNum = document.getElementsByClassName('phoneNum');
			for (let i = 0; i < phoneNum.length; i++) {
				//@ts-ignore
				phoneNum[i].value = phoneNum[i].value.replace(
					/(\d{3})(\d{4})(\d{4})/,
					'$1-$2-$3',
				);
			}
		},
		bizRepFnc() {
			let bizNum = document.getElementsByClassName('bizNum');
			for (let i = 0; i < bizNum.length; i++) {
				//@ts-ignore
				bizNum[i].value = bizNum[i].value.replace(
					/(\d{3})(\d{2})(\d{5})/,
					'$1-$2-$3',
				);
			}
		},
		amtRepFnc() {
			let amtNum = document.getElementsByClassName('amtNum');
			for (let i = 0; i < amtNum.length; i++) {
				//@ts-ignore
				amtNum[i].value = String(amtNum[i].value).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				);
			}
		},
	},
	mounted() {
		this.regiRepFnc();
		this.phoneRepFnc();
		this.bizRepFnc();
		this.amtRepFnc();
	},
	updated() {
		this.regiRepFnc();
		this.phoneRepFnc();
		this.bizRepFnc();
		this.amtRepFnc();
	},
};
