import Vue from 'vue';
import Vuex from 'vuex';
import {
	retrieveGoodsSpecInfo,
	getPubNotiInfo,
	getCardList,
} from '@/api/sales-mgmt/ChargeCalculatorApi';
import {
	commonCodeEnumList,
	getChargePubNotiList,
	getTelecomAddServiceList,
	commonCodeList,
	getGoodsPubNotiSelectList,
} from '@/api/common/common';

Vue.use(Vuex);

const ChargeCalculatorModule = {
	namespaced: true,
	state: {
		headerCalcDialog: false,
		changeDate: null,
		changeDateName: '선택해 주세요.',
		Interest: 0.059, //이자 - 이자 변경시 이거 수정하면됩니다.
		amtSelectPopTitle: null,
		amtSelectDialog: false,
		discountTitle: null,
		discountDialog: false,
		addSvcPopTitle: null,
		addSvcDialog: false,
		chargeBoardPopTitle: null,
		chargeBoardPopDialog: false,
		prePopDialog: false,
		billPopDialog: false,
		preCardConditionDialog: false,
		billCardConditionDialog: false,
		preChargeDateDialog: false,
		billChargeDateDialog: false,
		chgChargeDto: null, // 변경요금제 dto
		preCardItem: {
			monthAmtItem: null,
			instlItem: null,
		},
		prePopFormData: {
			monthAmt: null,
			chargeDate: null,
		},
		preInsertDto: {
			cardItem: null,
			cardFormData: null,
		},
		billPopFormData: {
			monthAmt: null,
			chargeDate: null,
		},
		billInsertDto: {
			cardItem: null,
			cardFormData: null,
		},
		billCardItem: {
			monthAmtItem: null,
		},
		addSvcIdArray: [],
		calculatorActive1: true,
		calculatorActive2: false,
		calculatorActive3: false,
		calculatorActiveCnt: 1,
		reduction: {
			// 요금감면
			reduction_DSBL: 0.35, // 장애등급
			reduction_NTNL: 0.35, // 국가유공자
			reduction_BASE_RCP: 28600, // 기초생활 수급자
			reduction_PENSION_RCP: 12100, // 기초연금 수급자
			reduction_HIGHT: 23650, // 차상위계층
		},
		formData: {
			colorImgUrl:
				'https://www.sklmno.com/goods/upload/no_image_m.png',
			numChangeYn: 'N',
			colorCode: null,
			maker: null,
			goodsId: null,
			chargeName: null,
			chargeId: null,
			network: null,
			instlPeriodType: 'MONTH_24', // 할부개월
			agreementType: 'PUBNOTI_SUPAMT', // 약정유형
			chargeReductType: 'NONE', // 해당없음
			releaseAmt: 0, // 출고가
			pubNotiSupportAmt: 0, // 공시지원금
			extraSupportAmt: 0, // 추가지원금
			freeInstlAmt: 0, // 프리할부/선수납
			etcDiscountAmt: 0, // 기타할인금
			actualSellAmt: 0, // 실판매가
			instlPrincipalAmt: 0, // 실판매가
			monthDvcAmt: 0, // 월기기금액
			monthBasicAmt: 0, // 월기본료
			monthPaymentAmt: 0, // 월납부금액
			addSvcAmt: 0, // 부가서비스
			insId: null, // 보험
			insName: null, // 보험명
			insAmt: 0, // 보험료
			preCardAmt: 0, // 선할인카드
			billCardAmt: 0, // 청구할인카드
			agreementPeriodType: 'MONTH_24', // 약정기간
			usimPaymentType: null, // 유심타입
		},
		codeList: {
			telecomList: [
				{ name: 'SKT', value: 5 },
				{ name: 'KT', value: 6 },
				{ name: 'LGU', value: 7 },
			],
			networkItemList: [], // 네트워크 유형
			makerItemList: [], // 제조사
			agreementPeriodTypeItems: [], // 약정개월
			installmentsPeriodTypeItems: [], // 할부개월
			goodsItemList: [],
			chargeItemList: [],
			insuranceItemList: [], // 보험
			addServiceItemList: [], // 부가서비스
			chargeReductTypeItemList: [], // 요금감면
			usimPaymentTypeItemList: [], // 유심정보
			discountCardItemList: [], // 카드리스트
			chargeTypeItemList: [], // 요금제 카테고리
		},
		cardItem: null,
	},
	mutations: {
		goodsItemsSet(state, result) {
			/*result = result.filter(
				obj => obj.useYn === 'Y' && obj.goodsType === 'DVC',
			);*/
			state.codeList.goodsItemList = result || [];
		},
		setTelecomChargeList(state, result) {
			/*result = result.filter(obj => obj.useYn === 'Y');*/
			for (let item in result) {
				if (!result[item].category) {
					result[item].category = 'UNSPEC';
				}
			}
			state.codeList.chargeItemList = result || [];
		},
		setTelecomAddServiceList(state, result) {
			// 보험
			state.codeList.insuranceItemList =
				result.filter(obj => obj.addSvcType === 'INSURANCE' || obj.addSvcType === 'INSURANCE_NORMAL') || [];
			// 부가서비스
			state.codeList.addServiceItemList =
				result.filter(obj => obj.addSvcType === 'NORMAL' || obj.addSvcType === 'INSURANCE_NORMAL') || [];
			state.codeList.insuranceItemList.unshift({
				addSvcCharge: 0,
				addSvcId: 0,
				addSvcName: '미가입',
			});
		},
		setCommonCodeEnumList(state, result) {
			state.codeList.chargeTypeItemList = result.ChargeType;
			state.codeList.chargeTypeItemList.unshift({ name: '전체', value: '' });
			state.codeList.chargeTypeItemList.push({
				name: '미지정',
				value: 'UNSPEC',
			});

			result.AgreementPeriodType = result.AgreementPeriodType.filter(
				obj => obj.value === 'MONTH_12' || obj.value === 'MONTH_24',
			);
			state.codeList.usimPaymentTypeItemList = result.UsimPaymentType || [];
			state.codeList.agreementPeriodTypeItems =
				result.AgreementPeriodType || [];
			state.codeList.installmentsPeriodTypeItems =
				result.InstallmentsPeriodType || [];
			state.codeList.chargeReductTypeItemList = result.ChargeReductType || [];

			let usim = state.codeList.usimPaymentTypeItemList;
			for (let item in usim) {
				switch (usim[item].value) {
					case 'PRE_PAY':
						usim[item].name = '선납 (7,700원 1회 청구)';
						break;
					case 'POST_PAY':
						usim[item].name = '후납 (7,700원 1회 청구)';
						break;
				}
			}

			let reduct = state.codeList.chargeReductTypeItemList;
			//요금감면
			for (let item in reduct) {
				switch (reduct[item].value) {
					case 'NONE':
						reduct[item].name = '해당없음';
						break;
					case 'DSBL':
						reduct[item].name = '장애인 (35% 할인)';
						break;
					case 'NTNL':
						reduct[item].name = '국가유공자 (35% 할인)';
						break;
					case 'BASE_RCP':
						reduct[item].name = '기초수급자 (28,600원 할인)';
						break;
					case 'PENSION_RCP':
						reduct[item].name = '연금수급자 (최대 12,100원 할인)';
						break;
					case 'HIGHT':
						reduct[item].name = '차상위계층 (12,100원 할인)';
						break;
				}
			}
		},
		setCommonCodeList(state, result) {
			state.codeList.networkItemList = result.codeList.NETWORK || [];
			state.codeList.makerItemList = result.codeList.MAKER || [];
			state.codeList.networkItemList.unshift({ codeNm: '전체', codeSeq: 0 });
			state.codeList.makerItemList.unshift({ codeNm: '전체', codeSeq: 0 });
		},
		setCardList(state, result) {
			state.codeList.discountCardItemList = result || [];
		},
	},
	actions: {
		// 상품 셀렉트 목록 조회
		async getGoodsPubNotiSelectList({ commit }, data) {
			const result = await getGoodsPubNotiSelectList(data);
			if (result) {
				commit('goodsItemsSet', result.data.data);
			}
		},
		// 요금제 셀렉트 목록 조회
		async getTelecomChargeList({ commit }, data) {
			const result = await getChargePubNotiList(data);
			if (result) {
				commit('setTelecomChargeList', result.data.data);
			}
		},
		// 공통 목록 조회
		async commonCodeEnumList({ commit }) {
			const newEnum = {
				initEnumData: [
					'AgreementPeriodType', // 약정기간
					'InstallmentsPeriodType', // 할부기간
					'ChargeReductType', // 요금감면
					'AgreementPeriodType', // 약정개월
					'UsimPaymentType', // 유심정보
					'ChargeType', // 요금 카테고리
				],
			};
			const result = await commonCodeEnumList(newEnum);
			if (result) {
				commit('setCommonCodeEnumList', result.data.data);
			}
		},
		// 공통 목록 조회2
		async commonCodeList({ commit }) {
			const Enum = { code: ['NETWORK', 'MAKER'] };
			const result = await commonCodeList(Enum);
			if (result) {
				commit('setCommonCodeList', result.data.data);
			}
		},
		// 통신사 셀렉트 부가서비스 조회
		async getTelecomAddServiceList({ commit }, data) {
			const result = await getTelecomAddServiceList(data);
			if (result) {
				commit('setTelecomAddServiceList', result.data.data);
			}
		},
		async retrieveGoodsSpecInfo({ commit }, data) {
			try {
				const result = await retrieveGoodsSpecInfo(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 통신사 요금 조회
		async getPubNotiInfo({ commit }, data) {
			try {
				const result = await getPubNotiInfo(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getCardList({ commit }, data) {
			try {
				let item = {
					cardType: data.cardType,
					param: {
						telecom_id: data.telecom_id,
					},
				};
				const result = await getCardList(item);
				if (result) {
					commit('setCardList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default ChargeCalculatorModule;
