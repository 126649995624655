
import Vue from 'vue';
import {
	basicFormData,
	joinFormData,
} from '../../../store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import deviceEnumMixin from '@/common/deviceEnumMixin';
import { applSub } from '@/common/socketUtil';
import focusMixin from '@/common/focusMixin.js';
import bus from '@/common/bus';

interface dataType {
	openStoreObject: any;
	openingStoreTelecomCopy: any;
}

export default Vue.extend({
	name: 'BasicInformation',
	props: {
		cnsltId: { type: Number, required: true },
		applId: { type: undefined, required: true },
	},
	computed: {
		applTypeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.applTypeItems;
		},
		formData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.formData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.formData = newValue;
			},
		},
		updateFormData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.updateFormData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.updateFormData =
					newValue;
			},
		},
		AppFormCreFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFormCreFlag =
					newValue;
			},
		},
		AppFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFlag = newValue;
			},
		},
		consultList: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.formData
					.consultList;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.formData.consultList =
					newValue;
			},
		},
		basic: {
			get(): basicFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.basic;
			},
			set(newValue: basicFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.basic =
					newValue;
			},
		},
		joinData: {
			get(): joinFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.join;
			},
			set(newValue: joinFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.join =
					newValue;
			},
		},
		customerDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.customerDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.customerDialog =
					newValue;
			},
		},
		customerData(): string {
			return this.$store.state.ApplicationFormCreationModule.formData.customer
				.cusType;
		},
		saleStoreItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.saleStoreItems;
		},
		openingStoreItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.openingStoreItems;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.openingStoreItems =
					newValue;
			},
		},
		openingStoreTelecom: {
			get(): number {
				return this.$store.state.ApplicationFormCreationModule
					.openingStoreTelecom;
			},
			set(newValue: number) {
				this.$store.state.ApplicationFormCreationModule.openingStoreTelecom =
					newValue;
			},
		},
		goodsItems(): any {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.goodsItems;
		},
		telecomChargeItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.telecomChargeItems;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList.telecomChargeItems =
					newValue;
			},
		},
		capacityItems: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.capacityItems;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.capacityItems =
					newValue;
			},
		},
		colorItems: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.colorItems;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.colorItems =
					newValue;
			},
		},
		codeListItems: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList = newValue;
			},
		},
		storeMemberItems: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.storeMemberItems;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.storeMemberItems =
					newValue;
			},
		},
		selOrganizationList: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.selOrganizationList;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.selOrganizationList =
					newValue;
			},
		},
		crData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.crData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.crData = newValue;
			},
		},
		crListFlag: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.crListFlag;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.crListFlag = newValue;
			},
		},
		templateSeq(): any {
			return this.$store.state.ApplicationFormCreationModule.templateSeq;
		},
		consultUserData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.formData
					.consultList;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.formData.consultList =
					newValue;
			},
		},
	},
	mixins: [deviceEnumMixin, focusMixin],
	data: (): dataType => ({
		openStoreObject: {},
		openingStoreTelecomCopy: '',
	}),
	methods: {
		// 상담원 조회
		async retrieveMemberListByRole() {
			let data;
			if (this.AppFormCreFlag) {
				if (this.crData.saleStoreId) {
					data = { storeId: this.crData.saleStoreId, roleType: 'CONSULT' };
					await this.$store.dispatch(
						'ApplicationFormCreationModule/retrieveMemberListByRole',
						data,
					);
				} else {
					data = { storeId: this.basic.saleStoreId, roleType: 'CONSULT' };
					await this.$store.dispatch(
						'ApplicationFormCreationModule/retrieveMemberListByRole',
						data,
					);
				}
			} else {
				data = { storeId: this.basic.saleStoreId, roleType: 'CONSULT' };
				await this.$store.dispatch(
					'ApplicationFormCreationModule/retrieveMemberListByRole',
					data,
				);
			}
		},
		alertFnc() {
			if (this.basic.saleStoreId === 0) {
				return alert('영업점을 선택해 주세요.');
			}
		},
		resetOpeningStoreChainFnc() {
			this.codeListItems.goodsItems = [];
			this.codeListItems.capacityItems = [];
			this.codeListItems.colorItems = [];
			this.codeListItems.telecomAddServiceItems = [];
			this.codeListItems.telecomChargeItems = [];
			this.joinData.openingTelecomCodeId = null;
			this.joinData.openingTelecomName = null;
			// this.joinData.goodsId = null;
			this.joinData.chargeId = null;
			// this.joinData.capacity = null;
			// this.joinData.color = null;
			this.joinData.addServiceList = [];
			this.getCapacityListFnc();
			this.getColorListFnc();
		},
		// 개통통신사에 따라 요금제 '선택없음'을 default로 박아주는 로직
		chargeIdReset() {
			switch (this.joinData.openingTelecomCodeId) {
				case 5:
					this.joinData.chargeId = 1474;
					break;
				case 6:
					this.joinData.chargeId = 1472;
					break;
				case 7:
					this.joinData.chargeId = 1473;
					break;
			}
		},
		async customerDialogFnc() {
			// 개통점 변경시 같은 통신사일때 no reset
			if (
				this.openingStoreTelecomCopy !==
				this.basic.openingStoreId.openStoreTelecomId
			) {
				// data reset
				this.basic.reserveNum = ''; // 사전예약번호 초기화
				this.resetOpeningStoreChainFnc();
			}
			// data set
			this.joinData.openingTelecomName =
				this.basic.openingStoreId.openStoreTelecom;
			this.joinData.openingTelecomCodeId =
				this.basic.openingStoreId.openStoreTelecomId;
			this.openingStoreTelecom = this.basic.openingStoreId.openStoreTelecomId;
			this.basic.parentHierarchy = this.basic.openingStoreId.parentHierarchy;
			this.basic.parentSaleStoreId =
				this.basic.openingStoreId.parentSaleStoreId;
			// 개통점 변경시 같은 통신사일때 no reset
			if (
				this.openingStoreTelecomCopy ===
				this.basic.openingStoreId.openStoreTelecomId
			)
				return;
			// 개통점 변경시 이전 개통점과 바뀐 개통점 비교하기위한 copy data
			this.openingStoreTelecomCopy =
				this.basic.openingStoreId.openStoreTelecomId;
			this.joinData.networkId = null;
			this.joinData.goodsId = null; // 기기명
			this.joinData.capacity = null; // 용량
			this.joinData.color = null; // 색상
			this.joinData.addServiceList = []; // 부가서비스리스트
			this.joinData.releaseAmt = 0; // 출고가
			this.joinData.releaseAmtDate = null; // 출고가일자
			this.joinData.pubNotiSupportAmt = 0; // 공시지원금
			this.joinData.extraSupportAmt = 0; // 추가지원금
			this.joinData.freeInstlAmt = 0; // 프리할부/선수납
			this.joinData.etcDiscountAmt = 0; // 기타할인금
			this.joinData.actualSellAmt = 0; // 실판매가
			this.joinData.instlPrincipalAmt = 0; // 할부원금
			this.joinData.monthDvcAmt = 0; // 월기기금액
			this.joinData.monthBasicAmt = 0; // 월기본료
			this.joinData.monthPaymentAmt = 0; // 월납부금액
			this.joinData.depositAmt = 0; // 입금받을금액
			this.joinData.depositYn = 'N'; // 입금여부
			let openingStore = false;
			if (
				this.joinData.openingTelecomCodeId === 5 ||
				this.joinData.openingTelecomCodeId === 7
			) {
				openingStore = true;
			}
			if (openingStore && this.customerData === 'INDV_BSNM') {
				this.customerDialog = true;
			} else {
				this.customerDialog = false;
			}
			if (this.joinData.openingTelecomCodeId) {
				this.codeListItems.mostGoodsItems = [];
				await this.getGoodsSelectListFnc();
				await this.getTelecomChargeList();
				await this.getTelecomAddServiceList();
				await this.getMostGoodsSelectList();
				await this.getTelecomMostChargeList();
				await this.mostFilterFnc();
				this.codeListItems.capacityItems = [];
				this.codeListItems.colorItems = [];
			}
			let exTel = this.joinData.existTelecomCodeId;
			if (
				(this.joinData.openingTelecomName === 'SKT' && exTel === 42) ||
				(this.joinData.openingTelecomName === 'KT' && exTel === 43) ||
				(this.joinData.openingTelecomName === 'LGU' && exTel === 44)
			) {
				this.joinData.joinType = 'DVC_CHANGE';
			} else if (exTel === 45) {
				this.joinData.joinType = 'NEW';
				this.basic.applType = 'DVC_USIM';
			} else {
				switch (this.joinData.existTelecomCodeId) {
					case 42:
						this.joinData.joinType = 'NUM_CHANGE_S';
						break;
					case 43:
						this.joinData.joinType = 'NUM_CHANGE_K';
						break;
					case 44:
						this.joinData.joinType = 'NUM_CHANGE_L';
						break;
				}
				this.basic.applType = 'DVC_USIM';
			}
			// 인기기종추가
			let mostGoodsItems = this.codeListItems.mostGoodsItems;
			for (let i = 0; i < mostGoodsItems.length; i++) {
				mostGoodsItems[i].mostType = 'Y';
				this.codeListItems.goodsItems.unshift(mostGoodsItems[i]);
			}
			// 인기요금제추가
			let mostChargeItems = this.codeListItems.mostChargeItems;
			for (let i = 0; i < mostChargeItems.length; i++) {
				mostChargeItems[i].mostType = 'Y';
				this.codeListItems.telecomChargeItems.unshift(mostChargeItems[i]);
			}
		},
		async getMostGoodsSelectList() {
			// 빈도수 높은 기기명 조회
			let data = this.joinData.openingTelecomCodeId;
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getMostGoodsSelectList',
				data,
			);
		},
		async getTelecomMostChargeList() {
			// 빈도수 높은 요금제 조회
			let data = this.joinData.openingTelecomCodeId;
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getTelecomMostChargeList',
				data,
			);
		},
		async openingStoreFnc() {
			this.joinData.networkId = null;
			this.basic.openingStoreId = 0;
			this.basic.applRegiUserId = 0;
			this.basic.applRegiOrgId = null;
			this.openStoreObject = {};
			this.openingStoreItems = [];
			this.storeMemberItems = [];
			// ref 등록자 모듈
			this.$store.state.ApplicationFormCreationModule.applCounselor =
				this.$refs.appl_counselor;
			/*			if (this.crData.openStoreId) {
				this.basic.openingStoreId = this.crData.openStoreId;
			}*/
			await this.getOpeningStoreList();
			if (this.openingStoreItems && this.openingStoreItems.length > 0) {
				await this.resetOpeningStoreChainFnc();
				await this.getSaleStoreMemberList();
				await this.getSelOrgnztList();
			}
			await this.retrieveMemberListByRole();
			// 신청서 작성시에만
			// 개통점 등록자 0번지 삽입 관련
			// 기존 개통점 온체인지
			if (!this.AppFormCreFlag) {
				if (this.crListFlag) {
					await this.crSetOpeningStoreFnc();
				} else if (this.cnsltId) {
					await this.consultSetOpeningStoreFnc();
				}
				await this.customerDialogFnc();
			}
			// 신조 요청자 -> 상담원(1) AND 등록자 -> 상담원(2)
			this.consultUserData[0].consultUserId = this.crData.regiUserId;
			// this.consultUserData[1].consultUserId = this.formData.basic.applRegiUserId;
		},
		async getOpeningStoreList() {
			if (this.basic.saleStoreId === null) {
				alert('영업점을 선택해 주세요.');
			}
			let data;
			if (!this.AppFormCreFlag) {
				if (this.crData.saleStoreId) {
					data = { storeId: this.crData.saleStoreId };
					await this.$store.dispatch(
						'ApplicationFormCreationModule/getOpeningStoreList',
						data,
					);
				} else {
					data = { storeId: this.basic.saleStoreId };
					await this.$store.dispatch(
						'ApplicationFormCreationModule/getOpeningStoreList',
						data,
					);
				}
			}
			if (this.AppFormCreFlag) {
				data = { storeId: this.updateFormData.basic.saleStoreId };
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getOpeningStoreList',
					data,
				);
			}
		},
		async getCapacityListFnc() {
			if (this.joinData.goodsId) {
				this.colorItems = [];
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getCapacitySelectList',
					this.joinData.goodsId,
				);
			}
		},
		async getColorListFnc() {
			if (this.joinData.goodsId && this.joinData.capacity) {
				let data = {
					goodsId: this.joinData.goodsId,
					capacity: this.joinData.capacity,
				};
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getColorSelectList',
					data,
				);
			}
		},
		// 기기
		async getGoodsSelectListFnc() {
			let data = {
				goodsType: { goodsType: 'DVC' },
				telecomId: this.joinData.openingTelecomCodeId,
			};
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getGoodsSelectList',
				data,
			);
		},
		// 요금제
		async getTelecomChargeList() {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getTelecomChargeList',
				this.joinData.openingTelecomCodeId,
			);
		},
		// 부가서비스
		async getTelecomAddServiceList() {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getTelecomAddServiceList',
				this.joinData.openingTelecomCodeId,
			);
		},
		async createdEnumFnc() {
			if (
				(this.AppFormCreFlag !== undefined && this.AppFormCreFlag !== null) ||
				this.templateSeq !== 0
			) {
				await this.getOpeningStoreList(); // 개통점
				await this.getSaleStoreMemberList(); // 등록자
				await this.retrieveMemberListByRole(); // 상담원
				await this.getTelecomAddServiceList(); // 부가서비스
				await this.getTelecomChargeList(); // 요금제
				await this.getGoodsSelectListFnc(); // 기기
				await this.getCapacityListFnc(); // 용량
				await this.getColorListFnc(); // 색상
				await this.getMostGoodsSelectList(); // 인기 기기
				await this.getTelecomMostChargeList(); // 인기요금제
				await this.mostFilterFnc(); // 인기기기 필터
				await this.getSelOrgnztList(); // 영업점 조직
			}
		},
		mostFilterFnc() {
			// 펫네임 가공
			for (let j = 0; j < this.codeListItems.mostGoodsItems.length; j++) {
				this.codeListItems.mostGoodsItems[j].goodsName =
					this.codeListItems.mostGoodsItems[j].goodsName +
					' [' +
					this.codeListItems.mostGoodsItems[j].modelName +
					']';
			}
			if (this.joinData.networkId === null) {
				// 인기기종
				for (let j = 0; j < this.codeListItems.mostGoodsItems.length; j++) {
					this.codeListItems.mostGoodsItems[j].mostType = 'Y';
					this.goodsItems.unshift(this.codeListItems.mostGoodsItems[j]);
				}
				// 인기요금제
				for (let j = 0; j < this.codeListItems.mostChargeItems.length; j++) {
					this.codeListItems.mostChargeItems[j].mostType = 'Y';
					this.telecomChargeItems.unshift(
						this.codeListItems.mostChargeItems[j],
					);
				}
			} else {
				// 인기기종
				let mostGoodsItem = this.codeListItems.mostGoodsItems.filter(
					(i: any) => this.joinData.networkId === i.network,
				);
				if (mostGoodsItem.length !== 0) {
					for (let j = 0; j < mostGoodsItem.length; j++) {
						mostGoodsItem[j].mostType = 'Y';
						this.goodsItems.unshift(mostGoodsItem[j]);
					}
				}
				// 인기요금제
				let mostChargeItems = this.codeListItems.mostChargeItems.filter(
					(i: any) => this.joinData.networkId === i.network,
				);
				for (let j = 0; j < mostChargeItems.length; j++) {
					mostChargeItems[j].mostType = 'Y';
					this.telecomChargeItems.unshift(mostChargeItems[j]);
				}
			}
		},
		formDataRecallFnc() {
			if (this.formData.join.openingTelecomCodeId === 5) {
				this.formData.join.openingTelecomName = 'SKT';
			}
			if (this.formData.join.openingTelecomCodeId === 6) {
				this.formData.join.openingTelecomName = 'KT';
			}
			if (this.formData.join.openingTelecomCodeId === 7) {
				this.formData.join.openingTelecomName = 'LGU';
			}
		},
		async getApplicationDetail() {
			if (this.AppFormCreFlag !== undefined && this.AppFormCreFlag !== null) {
				// @ts-ignore
				this.$route.query.applId = this.applId;
				let data = { applId: this.AppFormCreFlag };
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getApplicationDetail',
					data,
				);
				bus.$emit('callConsultDefaultFnc', this.formData.cnsltId);
			}
		},
		setOpeningStoreFnc() {
			if (this.AppFormCreFlag !== undefined && this.AppFormCreFlag !== null) {
				this.basic.openingStoreId = {
					openStoreCode: this.updateFormData.basic.openingStoreId,
					openStoreTelecom: this.updateFormData.join.openingTelecomName,
					openStoreTelecomId: this.updateFormData.join.openingTelecomCodeId,
					parentHierarchy: this.updateFormData.basic.parentHierarchy,
					parentSaleStoreId: this.updateFormData.basic.parentSaleStoreId,
				};
			} else if (this.templateSeq) {
				this.basic.openingStoreId = {
					openStoreCode: this.updateFormData.basic.openingStoreId.openStoreCode,
					openStoreTelecom: this.updateFormData.join.openingTelecomName,
					openStoreTelecomId: this.updateFormData.join.openingTelecomCodeId,
					parentHierarchy: this.updateFormData.basic.parentHierarchy,
					parentSaleStoreId: this.updateFormData.basic.parentSaleStoreId,
				};
			}
		},
		async renderingCrFnc() {
			await this.openingStoreFnc();
		},
		async renderingConsultFnc() {
			await this.openingStoreFnc();
		},
		crSetOpeningStoreFnc() {
			// 개통점 selected
			for (let i = 0; i < this.openingStoreItems.length; i++) {
				if (this.openingStoreItems[i].openStoreId === this.crData.openStoreId) {
					this.basic.openingStoreId = {
						openStoreCode: this.crData.openStoreId,
						openStoreTelecom: this.openingStoreItems[i].telecomName,
						openStoreTelecomId: this.openingStoreItems[i].telecom,
						parentHierarchy: this.openingStoreItems[i].parentHierarchy,
						parentSaleStoreId: this.openingStoreItems[i].parentSaleStoreId,
					};
					break;
				}
			}
			// this.basic.applRegiUserId = this.crData.regiUserId; // 22.03.04 1차상담(신조요청자)과 신청서작성 등록자가 다를 수 있음으로 로그인사용자 기준 담당자선택처리로 변경
			this.formData.join.openingTelecomName =
				this.basic.openingStoreId.openStoreTelecom;
			this.formData.join.openingTelecomName = this.crData.telecomName;
		},
		consultSetOpeningStoreFnc() {
			// 개통점 selected
			let consultData = this.$store.state.CustomerConsultModule.formDataDetail;
			for (let i = 0; i < this.openingStoreItems.length; i++) {
				if (
					this.openingStoreItems[i].openStoreId === consultData.openingStoreId
				) {
					this.basic.openingStoreId = {
						openStoreCode: consultData.openingStoreId,
						openStoreTelecom: this.openingStoreItems[i].telecomName,
						openStoreTelecomId: this.openingStoreItems[i].telecom,
						parentHierarchy: this.openingStoreItems[i].parentHierarchy,
						parentSaleStoreId: this.openingStoreItems[i].parentSaleStoreId,
					};
					break;
				}
			}
			// this.basic.applRegiUserId = this.crData.regiUserId; // 22.03.04 1차상담(신조요청자)과 신청서작성 등록자가 다를 수 있음으로 로그인사용자 기준 담당자선택처리로 변경
			this.formData.join.openingTelecomName =
				this.basic.openingStoreId.openStoreTelecom;
			this.formData.join.openingTelecomName = this.crData.telecomName;
		},

		// 영업점 소속 사용자 조회
		async getSaleStoreMemberList() {
			let data;
			if (this.basic.creditInquireId) {
				if (this.crData?.saleStoreId) {
					data = { storeId: this.crData.saleStoreId };
					await this.$store.dispatch(
						'ApplicationFormCreationModule/getSaleStoreMemberList',
						data,
					);
				}
				data = { storeId: this.basic.saleStoreId };
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getSaleStoreMemberList',
					data,
				);
			} else {
				data = { storeId: this.basic.saleStoreId };
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getSaleStoreMemberList',
					data,
				);
			}
		},
		// 영업점 조직 조회
		async getSelOrgnztList() {
			let data = { storeId: this.basic.saleStoreId };
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getSelOrgnztListFnc',
				data,
			);
		},
		statusFilterFnc() {
			// 상담상태 - filter 유심관련
			switch (this.basic.consultTaskStatus) {
				case 'USIM_CHANGE_DMND_RCPT_CONSULT':
					break;
				case 'USIM_CHANGE_DMND_CONSULT':
					break;
				default:
					this.codeListItems.ConsultTaskStatusList =
						this.codeListItems.ConsultTaskStatusList.filter(
							(i: any) =>
								i.value !== 'USIM_CHANGE_DMND_RCPT_CONSULT' &&
								i.value !== 'USIM_CHANGE_DMND_CONSULT',
						);
			}
			// 개통상태 - filter 유심관련
			switch (this.basic.openingTaskStatus) {
				case 'USIM_CHANGE_DMND_RCPT_OPENING':
					break;
				case 'USIM_CHANGE_DMND_OPENING':
					break;
				case 'USIM_CHANGE_COMPL':
					break;
				default:
					this.codeListItems.OpeningTaskStatusList =
						this.codeListItems.OpeningTaskStatusList.filter(
							(i: any) =>
								i.value !== 'USIM_CHANGE_DMND_RCPT_OPENING' &&
								i.value !== 'USIM_CHANGE_DMND_OPENING' &&
								i.value !== 'USIM_CHANGE_COMPL',
						);
			}
		},
		async testFnc() {
			await this.getOpeningStoreList();
			await this.resetOpeningStoreChainFnc();
			await this.getSaleStoreMemberList();
			await this.retrieveMemberListByRole();
			await this.customerDialogFnc();
			await this.getSelOrgnztList();
		},
		async setTemplateData() {
			await this.createdEnumFnc();
			await this.formDataRecallFnc();
			await this.setOpeningStoreFnc();
			// 개통점 변경시 이전 개통점과 바뀐 개통점 비교하기위한 copy data
			if (this.basic.openingStoreId) {
				this.openingStoreTelecomCopy =
					this.basic.openingStoreId.openStoreTelecomId;
			}
		},
		async regiUserIdChainFnc(idx: any) {
			// @ts-ignore
			this.formData.basic.applRegiOrgId = this.storeMemberItems[idx - 1].orgId;
		},
	},
	async created() {
		this.AppFormCreFlag = this.applId;
		this.AppFlag = this.applId;
		this.$store.state.ApplicationFormCreationModule.templateSeq = 0;
		this.$store.state.ApplicationFormCreationModule.templateTitle = '';
		await this.getApplicationDetail();
		if (
			!this.$store.state.ApplicationFormCreationModule.formData.basic
				.regiDatetime &&
			this.AppFormCreFlag
		) {
			this.$store.state.ApplicationFormCreationModule.AppFormCreFlag = false;
			await history.back();
		} else {
			await this.createdEnumFnc();
			if (this.basic.creditInquireId && !this.AppFormCreFlag) {
				await this.renderingCrFnc(); // 신조
			} else if (this.cnsltId && !this.AppFormCreFlag) {
				await this.renderingConsultFnc(); // 상담
			} else {
				await this.formDataRecallFnc();
			}
			await this.setOpeningStoreFnc();
			// 개통점 변경시 이전 개통점과 바뀐 개통점 비교하기위한 copy data
			if (this.basic.openingStoreId) {
				this.openingStoreTelecomCopy =
					this.basic.openingStoreId.openStoreTelecomId;
			}
		}
		// 판매현황에서 들어온 신청서 번호
		if (this.$route.query.applId !== undefined) {
			// console.log('SOCKET appl ID ================== ' + this.$route.query.applId)
			/*let data = {
				desti: '/subscribe/application/main/',
				applId: this.$route.query.applId,
				wsType: 'APPLICATION_MAIN',
			}*/
			this.$store.state.applTabType = '/subscribe/application/main/';
			applSub(this.$route.query.applId);
		}
	},
	updated() {
		this.statusFilterFnc();
	},
	watch: {
		saleStoreItems() {
			if (!this.AppFormCreFlag) {
				// @ts-ignore
				if (this.saleStoreItems.length === 1) {
					// @ts-ignore
					this.basic.saleStoreId = this.saleStoreItems[0].storeId;
					this.testFnc();
				}
			}
		},
		templateSeq(val) {
			if (val) {
				this.setTemplateData();
			}
		},
	},
});
