<template>
	<div class="padW30">
		<div class="disFx">
			<div
				class="maker_radio_style1 borderRadi3Px overflow-hidden"
				v-for="(item, index) in codeList.chargeTypeItemList"
				:key="index"
				style="background-color: #f6f8fb"
			>
				<input
					type="radio"
					:id="`category${index}`"
					:value="item.value"
					name="maker"
					class="disNONE"
					@change="categoryChangeFnc($event.target.value, category)"
					v-model.trim="category"
				/>
				<label class="mainFontS cur_p" :for="`category${index}`">{{
					item.name
				}}</label>
			</div>
		</div>
		<div class="mt20 posR search_icon1 w300">
			<input
				type="text"
				class="device_input_style1 w300"
				placeholder="검색어를 입력해 주세요"
				@input="textSearchFnc($event.target.value)"
				v-model.trim="searchText"
			/>
		</div>
		<div
			class="device_table_style1 mt15 narrowScroll posR"
			id="mainTable"
			style="height: 430px !important"
		>
			<table class="w100P">
				<tr style="position: sticky; top: 0; z-index: 11">
					<th class="w200">요금제명</th>
					<th>공시지원금</th>
					<th>
						월 기본료<span class="font-size-10 normalWt">(VAT 포함)</span>
					</th>
					<th>유무선 제공</th>
					<th class="w240">데이터 제공</th>
				</tr>
				<tr v-if="searchList.length === 0" style="cursor: default">
					<td class="txAnC" colspan="20">데이터가 없습니다.</td>
				</tr>
				<tr
					class="cur_p"
					:class="{ backColorBlue6: item.chargeId === chargeId }"
					v-else
					v-for="(item, index) in searchList"
					:key="index"
					@click="chargeSelectFnc(item)"
				>
					<td class="mainBlack">{{ item.chargeName }}</td>
					<td class="txAnC">
						{{ String(item.supportAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
						원
					</td>
					<td class="txAnC">
						{{ String(item.chargeAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
						원
					</td>
					<td class="txAnC">
						{{ item.voiceAmtDetail ? item.voiceAmtDetail : '-' }}
					</td>
					<td>{{ item.dataAmtDetail ? item.dataAmtDetail : '-' }}</td>
				</tr>
			</table>
		</div>
		<div class="disFx justify-end mt10 mb20">
		</div>
	</div>
</template>

<script>
import { ch2pattern } from '@/common/common';

export default {
	name: 'ChargeSelect',
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.amtSelectDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.amtSelectDialog = newValue;
			},
		},
	},
	data: () => ({
		searchList: [],
		chargeName: null,
		chargeId: null,
		monthBasicAmt: 0, // 월 기본료
		pubNotiSupportAmt: 0, // 공시지원금
		searchText: null,
		category: '',
	}),
	methods: {
		textSearchFnc(text) {
			this.searchList = [];
			let chargeList = [...this.codeList.chargeItemList];

			if (this.category) {
				chargeList = chargeList.filter(i => i.category === this.category);
			} else {
				chargeList = [...this.codeList.chargeItemList];
			}

			let result = this.createFuzzyMatcher(text);
			if (text) {
				for (let item in chargeList) {
					if (result.test(chargeList[item].chargeName)) {
						this.searchList.push(chargeList[item]);
					}
				}
			} else {
				this.searchList = chargeList;
			}
		},
		createFuzzyMatcher(input) {
			const pattern = input.split('').map(ch2pattern).join('.*?');
			return new RegExp(pattern);
		},
		chargeSelectFnc(item) {
			this.chargeName = item.chargeName;
			this.chargeId = item.chargeId;
			this.monthBasicAmt = item.chargeAmt; // 월 기본료
			this.pubNotiSupportAmt = item.supportAmt; // 공시지원금

			this.insertFnc(this.chargeName, this.chargeId, this.monthBasicAmt, this.pubNotiSupportAmt);
		},
		setChargeData() {
			this.searchList = [...this.codeList.chargeItemList] || [];
			this.chargeName = this.formData.chargeName;
			this.chargeId = this.formData.chargeId;
			this.monthBasicAmt = this.formData.monthBasicAmt;
			this.pubNotiSupportAmt = this.formData.pubNotiSupportAmt;
		},
		insertFnc(chargeName, chargeId, monthBasicAmt, pubNotiSupportAmt) {
			if (!chargeId) {
				return alert('요금제를 선택해 주세요.');
			}
			this.formData.chargeName = chargeName;
			this.formData.chargeId = chargeId;
			this.formData.monthBasicAmt = monthBasicAmt;
			this.formData.pubNotiSupportAmt = pubNotiSupportAmt;
			this.dialog = false;
		},
		categoryChangeFnc(codeSeq) {
			document.getElementById('mainTable').scrollTo(0, 0);

			this.searchText = null;
			const chargeItemList = [...this.codeList.chargeItemList];
			if (codeSeq) {
				this.searchList = chargeItemList.filter(i => i.category === codeSeq);
			} else {
				this.searchList = chargeItemList;
			}
		},
	},
	created() {
		this.setChargeData();
	},
};
</script>

<style scoped></style>
