import Vue from 'vue';
import Vuex from 'vuex';
import { commonCodeListFnc } from '@/common/common';
import { getGoodsSelectCapacityList } from '../../../../api/common/common.js';
import {
	getApplicationRightDetail,
	insertData,
	upDateData,
	fileUpload,
	getApplicationAttachList,
	deleteApplicationAttachAndMemo,
} from '../../../../api/supply-chain-mgmt/application-form-creation/RightExecutionTableApi.js';
import { sendReload } from '@/common/socketUtil';

Vue.use(Vuex);

const RightExecutionModule = {
	namespaced: true,
	state: {
		cameraDialog: false,
		rightExeFlag: false,
		fileData: [],
		propsFileList: [],
		formData: {
			applId: '',
			rightProcStatus: 'RIGHT_DMND',
			complDate: '',
			rejectDate: '',
			openingDate: '',
			delayYn: 'N',
			delayPeriod: '',
			goodsId: '',
			capacity: '',
			deviceRawBarcode: '',
			expectRewardAmt: 0,
			expectRightAmt: 0,
			expectDiffAmt: 0,
			customerCompeChoice: '',
			acceptanceProcStatus: 'NONE_PROG',
			extrrStatusCheck: '',
			extrrStatusCheckMemo: '',
			executeCheck: '',
			executeCheckMemo: '',
			rightReturnType: '',
			reclaimDate: '',
			courierCodeId: '',
			invoiceNum: '',
			chargerName: '',
			chargerPhone: '',
			acceptanceYn: '',
			lastRightAmt: 0,
			lastDiffAmt: 0,
			lastReason: '',
			attachList: [],
		},
		codeListItems: {
			capacity: [],
			COURIER: [],
		},
	},
	mutations: {
		setCommonCodeList(state, result) {
			// 셋 공통코드리스트 - 택배사 COURIER
			state.codeListItems.COURIER = result.codeList.COURIER;
		},
		setCapacityList(state, result) {
			state.codeListItems.capacity = [];
			if (result.data.resultCode === '0000') {
				state.codeListItems.capacity = result.data.data;
			}
			// 셋 용량리스트

			state.codeListItems.capacity.unshift({ capacity: '미선택' });
		},
		setRightDetailList(state, result) {
			// 셋 디테일
			if (result.data.resultCode !== '9000') {
				state.formData = result.data.data;
				state.rightExeFlag = true;
			}
		},
	},
	actions: {
		async getCommonCodeList({ commit }) {
			// 공통코드리스트 콜 - 택배사 COURIER
			try {
				let data = { code: ['COURIER'] };
				const result = await commonCodeListFnc(data);
				if (result) {
					commit('setCommonCodeList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getGoodsSelectCapacityList({ commit }, data) {
			// 기기선택후 용량리스트콜
			try {
				const result = await getGoodsSelectCapacityList(data);
				if (result) {
					commit('setCapacityList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertData({ commit }, data) {
			// 등록
			try {
				const result = await insertData(data);
				if (result.data.resultCode === '0000') {
					sendReload('right');
					alert('저장되었습니다.');
				} else {
					alert('저장실패하였습니다.');
				}
			} catch (e) {
				console.log(e);
			}
		},
		async upDateData({ commit }, data) {
			// 업데이트
			try {
				const result = await upDateData(data);
				if (result.data.resultCode === '0000') {
					sendReload('right');
					alert('수정되었습니다.');
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getApplicationRightDetail({ commit }, data) {
			// 디테일콜
			const result = await getApplicationRightDetail(data);
			if (result) {
				commit('setRightDetailList', result);
			}
		},
		async fileUpload({ commit }, data) {
			// 파일업로드
			try {
				const result = await fileUpload(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteApplicationAttachAndMemo({ commit }, data) {
			// 파일 삭제
			try {
				const result = await deleteApplicationAttachAndMemo(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getApplicationAttachList({ commit }, data) {
			// 파일 리스트 조회
			try {
				const result = await getApplicationAttachList(data);
				if (result.data.status === 200) {
					if (result.data.resultCode !== '0000') {
						alert(result.data.resultMsg);
					} else {
						return result.data.data.resultList || [];
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default RightExecutionModule;
