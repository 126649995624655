import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/BondStockManagement/LGUBondMgmt/';

// 채권관리 목록 조회
const getBondMgmtList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getBondMgmtList${param}`);
	return response;
};

// 채권관리 상단 요약 조회
const getBondMgmtSummary = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getBondMgmtSummary${param}`);
	return response;
};

// 채권관리 월별 집계 조회
const getBondMonthSummary = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`BondStockManagement/LGUBondMgmt/getBondMonthSummary${param}`,
	);
	return response;
};

// 채권관리 월별 일반_공급처별_보유처별 집계 조회
const getBondMonthGroupSummary = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`BondStockManagement/LGUBondMgmt/getBondMonthGroupSummary${param}`,
	);
	return response;
};

// 채권관리 상단 요약 조회2
const getBondMgmtGroupSummary = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getBondMgmtGroupSummary${param}`,
	);
	return response;
};

//채권 보상완료 변경 처리
const updateBondCompe = async data => {
	const response = await instance.post(`${domain}updateBondCompe`, data);
	return response;
};

// 채권 회수 변경 처리
const updateBondInComplYn = async data => {
	const response = await instance.post(`${domain}updateBondInComplYn`, data);
	return response;
};

// 채권 상환 변경 처리
const updateBondOutComplYn = async data => {
	const response = await instance.post(`${domain}updateBondOutComplYn`, data);
	return response;
};

// 채권 이월 처리
const updateBondHoldOver = async data => {
	const response = await instance.post(`${domain}updateBondHoldOver`, data);
	return response;
};

// 채권 회수 일괄 변경 처리
const updateBulkBondInComplYn = async data => {
	const response = await instance.post(
		`${domain}updateBulkBondInComplYn`,
		data,
	);
	return response;
};

// 채권 상환 일괄 변경 처리
const updateBulkBondOutComplYn = async data => {
	const response = await instance.post(
		`${domain}updateBulkBondOutComplYn`,
		data,
	);
	return response;
};

// 채권 상환 회수 일괄 변경 처리
const updateBulkBondInOutComplYn = async data => {
	const response = await instance.post(
		`${domain}updateBulkBondInOutComplYn`,
		data,
	);
	return response;
};

// 월별 자산 저장 처리
const saveMonthAsset = async data => {
	const response = await instance.post(
		`BondStockManagement/LGUAssetMgmt/saveMonthAsset`,
		data,
	);
	return response;
};

export {
	getBondMgmtList,
	getBondMgmtSummary,
	getBondMgmtGroupSummary,
	getBondMonthSummary,
	getBondMonthGroupSummary,
	updateBondCompe,
	updateBondInComplYn,
	updateBondOutComplYn,
	updateBondHoldOver,
	updateBulkBondInComplYn,
	updateBulkBondOutComplYn,
	updateBulkBondInOutComplYn,
	saveMonthAsset,
};
