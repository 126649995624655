<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" :width="popWidth">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 disFx justify-lg-space-between"
						style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">{{ title }}</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad10">
						<template v-if="title === '요금감면 선택'">
							<reduct-select></reduct-select>
						</template>
						<template v-if="title === '선할인카드 선택'">
							<pre-discount-select></pre-discount-select>
						</template>
						<template v-if="title === '청구할인카드 선택'">
							<bill-discount-select></bill-discount-select>
						</template>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import ReductSelect from './pop-template/ReductSelect.vue';
import PreDiscountSelect from './pop-template/PreDiscountSelect.vue';
import BillDiscountSelect from './pop-template/BillDiscountSelect.vue';

export default {
	name: 'DiscountSelectPop',
	components: {
		ReductSelect,
		PreDiscountSelect,
		BillDiscountSelect,
	},
	props: ['title'],
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.discountDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.discountDialog = newValue;
			},
		},
		popWidth() {
			if (this.title === '요금감면 선택') {
				return 400;
			} else {
				return 800;
			}
		},
	},
};
</script>

<style scoped></style>
