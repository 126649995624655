import Vue from 'vue';
import Vuex from 'vuex';
import {
	getMallProdInquiryPhone,
	insertMallProdInquiry,
	getMallProdInquiryList,
	getMallProdInquiryDetail,
	insertMallProdInquiryComment,
	deleteMallProdInquiryComment,
	insertMallProdLogo,
	uploadMallProdLogoAttach,
	getMallProdLogoList,
	getMallProdLogoDetail,
} from "../../../api/mall_mgmt/MallProducingMgmtApi";
Vue.use(Vuex);

const MallProducingMgmtModule = {
	namespaced: true,
	state: {
		totalCnt: 0,
		filterData: {
			perPageCnt: 20,
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		mallProdInquiryList: [],
		mallProdLogoList: [],
	},
	mutations: {
		setMallProdInquiryList(state, result) {
			state.mallProdInquiryList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setMallProdInquiryListInit(state) {
			state.mallProdInquiryList = [];
		},
		setMallProdLogoList(state, result) {
			state.mallProdLogoList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setMallProdLogoListInit(state) {
			state.mallProdLogoList = [];
		},
	},
	actions: {
		async getMallProdInquiryPhone({ commit }, data) {
			try {
				const result = await getMallProdInquiryPhone(data);
				if (result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallProdInquiry({ commit }, data) {
			try {
				const result = await insertMallProdInquiry(data);
				if (result.data.resultCode === '0000') {
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallProdInquiryList({ commit }, data) {
			try {
				commit('setMallProdInquiryListInit');
				for (let item in data) {
					if (
						data[item] === '' ||
						data[item] === null ||
						data[item] === undefined
					) {
						delete data[item];
					}
				}
				const result = await getMallProdInquiryList(data);
				if (result) {
					commit('setMallProdInquiryList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallProdInquiryDetail({ commit }, data) {
			try {
				const result = await getMallProdInquiryDetail(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallProdInquiryComment({ commit }, data) {
			try {
				const result = await insertMallProdInquiryComment(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteMallProdInquiryComment({ commit }, data) {
			try {
				const result = await deleteMallProdInquiryComment(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallProdLogo({ commit }, data) {
			try {
				const result = await insertMallProdLogo(data);
				if (result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async uploadMallProdLogoAttach({ commit }, data) {
			try {
				const result = await uploadMallProdLogoAttach(data);
				if (result.data.resultCode === '0000') {
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallProdLogoList({ commit }, data) {
			try {
				commit('setMallProdLogoListInit');
				for (let item in data) {
					if (
						data[item] === '' ||
						data[item] === null ||
						data[item] === undefined
					) {
						delete data[item];
					}
				}
				const result = await getMallProdLogoList(data);
				if (result) {
					commit('setMallProdLogoList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallProdLogoDetail({ commit }, data) {
			try {
				const result = await getMallProdLogoDetail(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default MallProducingMgmtModule;
