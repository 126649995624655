import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const BookmarkModule = {
	namespaced: true,
	state: {
		menu: [
			{
				href: '/device/in-stock-regi',
				title: '입고등록',
			},
			{
				href: '/device/in-stock-status',
				title: '입고현황',
			},
			{
				href: '/device/in-stock-history',
				title: '입고이력',
			},
			{
				title: '이동',
				href: '/stock-move-transfer/stock-move-sale',
			},
			{
				title: '이관',
				href: '/stock-move-transfer/stock-move-transfer',
			},
			{
				title: '이동/이관현황',
				href: '/stock-move-transfer/move-status',
			},
			{
				title: '이동/이관이력',
				href: '/stock-move-transfer/move-history',
			},
			{
				title: '신청서매칭',
				href: '/matching/application-matching',
			},
			{
				title: '기기자동매칭',
				href: '/matching/auto-matching',
			},
			{
				title: '재고현황',
				href: '/stock-status/stock-status',
			},
			{
				title: '조정출고',
				href: '/stock-status/mediation-release',
			},
			{
				title: '기기/유심현황',
				href: '/stock-status/device-status',
			},
			{
				title: '보유처별 재고현황',
				href: '/stock-status/stock-stats',
			},
			// {
			// 	title: '관리기기현황',
			// 	href: '/mgmt-target/mgmt-target-device',
			// },
			// {
			// 	title: '조정출고',
			// 	href: '/mgmt-target/mediation-release',
			// },
			{
				title: '권리실행',
				href: '/right-execution',
			},
			{
				title: '공급처관리',
				href: '/co-mgmt/provider-mgmt',
			},
			{
				title: '보유처관리',
				href: '/co-mgmt/hold-store-mgmt',
			},
			{
				title: '신용조회 결과',
				href: '/credit-check',
			},
			{
				title: '신용조회 요청',
				href: '/credit-check-request',
			},
			{
				title: '신청서작성',
				href: '/application-form-creation',
			},
			{
				title: '판매현황',
				href: '/sell-status',
			},
			{
				title: '리텐션DB(대리점)',
				href: '/sell-status/retention-mgmt',
			},
			{
				title: '수납요청',
				href: '/sell-status/receive-request',
			},
			{
				title: '블랙리스트관리',
				href: '/sell-status/black-list',
			},
			{
				title: '서명내역',
				href: '/sell-status/signature-mgmt',
			},

			{
				title: '오토콜(예정)',
				href: '/consult-mgmt/auto-call',
			},
			{
				title: '고객상담',
				href: '/consult-mgmt/customer-consult',
			},
			{
				title: '상담DB',
				href: '/consult-mgmt/consult-db',
			},
			{
				title: '요금계산기',
				href: '/customer-mgmt/charge-calculator',
			},
			{
				title: '블랙리스트',
				href: '/customer-mgmt/black-list',
			},
			{
				title: '상담설정',
				href: '/consult-mgmt/consult-setting',
			},
			{
				title: '고객DB',
				href: '/customer-mgmt/customer-db',
			},
			{
				title: 'DB추출',
				href: '/customer-mgmt/export-db',
			},
			{
				title: '리텐션DB',
				href: '/customer-mgmt/retention-mgmt',
			},
			{
				title: '공지관리',
				href: '/env-settings/consult-notice',
			},
			{
				title: '상담관리 설정',
				href: '/env-settings/consult-setting',
			},
			{
				title: 'SKT채권관리',
				href: '/bond-mgmt/bond-status/skt',
			},
			{
				title: 'KT채권관리',
				href: '/bond-mgmt/bond-status/kt',
			},
			{
				title: 'LGU채권관리',
				href: '/bond-mgmt/bond-status/lgu',
			},
			{
				title: '보상재고리스트',
				href: '/bond-mgmt/compe-status',
			},
			{
				title: '연도별통계',
				href: '/bond-mgmt/month-summary',
			},
			{
				title: '민원처리',
				href: '/complain-hndlg',
			},
			{
				title: '개통점관리',
				href: '/bsnm-mgmt/openingstore-mgmt',
			},
			{
				title: '영업점관리',
				href: '/bsnm-mgmt/salestore-mgmt',
			},
			{
				title: '개통점 영업점매칭',
				href: '/bsnm-mgmt/openingstorebcnc-matching',
			},
			{
				title: '개통점 사용자매칭',
				href: '/bsnm-mgmt/openingstoreuser-matching',
			},
			{
				title: '조직관리',
				href: '/orgnzt-mgmt/organization-mgmt',
			},
			{
				title: '역할관리',
				href: '/orgnzt-mgmt/userrole-mgmt',
			},
			{
				href: '/cs-mgmt/noticelist',
				title: '공지사항',
			},
			{
				href: '/cs-mgmt/qalist',
				title: '기술 및 장애문의',
			},
			{
				href: '/goods-mgmt/mobilephonelist',
				title: '상품 목록',
			},
			{
				href: '/goods-mgmt/mobilephone-requestlist',
				title: '상품 요청목록',
			},
			{
				href: '/goods-mgmt/mobilephone-grouplist',
				title: '상품 그룹목록',
			},
			{
				href: '/charge-mgmt/chargelist',
				title: '요금 목록',
			},
			{
				href: '/charge-mgmt/chargerequest-list',
				title: '요금 요청목록',
			},
			{
				href: '/additionservice-mgmt/additionservice-list',
				title: '부가서비스 목록',
			},
			{
				href: '/additionservice-mgmt/additionservicerequest-list',
				title: '부가서비스 요청목록',
			},
			{
				title: '통계관리',
				href: '/stats-mgmt',
			},
			{
				title: '공시지원금관리',
				href: '/pnsupportmoney-mgmt/pnsupportmoney',
			},
			// 물류 renewal 후 없어질 항목목
			{
				title: '반품현황',
				href: '/move-stock-rtngud',
			},
			{
				title: '이동/이관현황',
				href: '/move-status',
			},

			{
				title: '기기현황',
				href: '/device-status',
			},
			{
				title: '기기/유심현황',
				href: '/device-usim/device-status',
			},
			{
				title: '유심현황',
				href: '/usim-status',
			},
			{
				title: '기기/유심현황',
				href: '/device-usim/device-status',
			},
			{
				title: '쇼핑몰 페이지 관리',
				href: '/product-mgmt/mall-page-mgmt',
			},
			{
				title: '이벤트 정보',
				href: '/mall-setting/mall-event-mgmt',
			},
			{
				title: '입점 관리',
				href: '/mall-setting/mall-domain-mgmt',
			},
			{
				title: '약관 관리',
				href: '/system-mgmt/terms-mgmt',
			},
			{
				title: '로고 요청',
				href: '/producing-mgmt/logo-req-list',
			},
			{
				title: '이벤트 페이지 요청',
				href: '/producing-mgmt/event-req-list',
			},
			{
				title: '문의사항',
				href: '/producing-mgmt/request-list',
			},
			{
				title: '쇼핑몰 회원관리',
				href: '/mall-member-mgmt/mall-member-list',
			},
			{
				title: '리뷰관리',
				href: '/mall-member-mgmt/mall-review-list',
			},
			{
				title: '판매상품 관리',
				href: '/product-mgmt/mall-product-mgmt',
			},
			{
				title: '판매정책 관리',
				href: '/product-mgmt/mall-judge-mgmt',
			},
		],
	},
	actions: {
		checkBookmark({ state }, path) {
			return !!state.menu.find(menu => menu.href === path);
		},
	},
};
export default BookmarkModule;
