<template>
	<div>
		<div>
			<table class="tell_table1 borderContColor1">
				<tr>
					<th>통신사</th>
					<td>
						<div class="disFx justify-space-between" style="padding: 0px 50px">
							<div
								class="telecomRadioStyle1 ml30 lh30 disIn"
								style="font-size: 20px; font-weight: bold"
								v-for="(item, index) in codeList.telecomList"
								:key="index"
							>
								<input
									:id="`telecom${index}`"
									:value="item.value"
									type="radio"
									v-model.trim="formData.telecomId"
									name="telecom"
									@change="telecomSelectFnc($event.target.value)"
								/>
								<label
									style="margin-left: 5px; margin-right: 8px"
									:for="`telecom${index}`"
									>{{ item.name }}</label
								>
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div
			class="cal_main_cont1 disFx w100P backColorWhite mt1 borderRadi5Px borderContColor1"
			style="padding: 5px 20px 5px 20px"
		>
			<div class="w50P">
				<h3
					class="mainBlue2 mainFontS lh32"
					style="border-bottom: 1px solid #007ef2"
				>
					기기 정보 (A)
				</h3>
				<table class="amt_table1 mt5">
					<tr>
						<td>기기명</td>
						<td>
							<div
								class="pop_active_input1"
								@click="deviceInforVal(formData.telecomId)"
							>
								<input
									type="text"
									class="cal_table_input1 cur_p txAnR disGray"
									v-model.trim="formData.goodsName"
									readonly
									@click="amtSelectPopFnc('기기 선택')"
									:disabled="!formData.telecomId"
								/>
							</div>
						</td>
					</tr>
					<tr>
						<td>출고가</td>
						<td>
							<div class="copyClass1">
								<input
									type="text"
									class="cal_table_input1 borderContColor3 amtInputBox1 amtNum amt"
									maxlength="9"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									v-model.trim="formData.releaseAmt"
									:disabled="!formData.telecomId"
								/>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="w50P ml40">
				<h3
					class="mainBlue2 mainFontS lh32"
					style="border-bottom: 1px solid #007ef2"
				>
					요금제 정보 (B)
				</h3>
				<table class="amt_table1 mt5">
					<tr>
						<td>요금제</td>
						<td>
							<div
								class="pop_active_input1"
								@click="deviceInforVal(formData.telecomId, '요금제')"
							>
								<input
									type="text"
									class="cal_table_input1 cur_p txAnR disGray"
									v-model.trim="formData.chargeName"
									@click="amtSelectPopFnc('요금제 선택')"
									readonly
									:disabled="!formData.telecomId || !formData.goodsId"
								/>
							</div>
						</td>
					</tr>
					<tr>
						<td style="line-height: 16px">
							월 기본료<br /><span class="subFontS11 normalWt">(VAT 포함)</span>
						</td>
						<td>
							<div class="copyClass1">
								<input
									type="text"
									class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
									maxlength="9"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									v-model.trim="formData.monthBasicAmt"
									readonly
								/>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="backColorWhite mt5 borderRadi5Px borderContColor1" style="padding: 5px 20px">
			<div class="agreement_radio_style1 backColorGray1">
				<input
					type="radio"
					id="PUBNOTI_SUPAMT"
					value="PUBNOTI_SUPAMT"
					name="agreementType"
					v-model.trim="formData.agreementType"
					class="disNONE"
				/>
				<label class="mainFontS cur_p" for="PUBNOTI_SUPAMT">공시지원금</label>
				<input
					type="radio"
					id="CHOICE_AGRMN"
					value="CHOICE_AGRMN"
					name="agreementType"
					v-model.trim="formData.agreementType"
					class="disNONE"
				/>
				<label class="mainFontS cur_p" for="CHOICE_AGRMN">선택약정할인</label>
			</div>
			<div class="disFx mt5">
				<table class="w50P amt_table1">
					<tr :class="agreeTypePubFnc(formData.agreementType)">
						<td style="font-weight: bolder">공시지원금</td>
						<td>
							<div class="copyClass1">
								<input
									type="text"
									class="cal_table_input1 borderContColor3 amtInputBox1 amtNum"
									maxlength="9"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									v-model.trim="formData.pubNotiSupportAmt"
									:disabled="
										formData.agreementType === 'CHOICE_AGRMN' ||
										!formData.telecomId
									"
								/>
							</div>
						</td>
					</tr>
					<tr :class="agreeTypePubFnc(formData.agreementType)">
						<td style="font-weight: bolder">
							추가지원금<br />
							<!--							<div class="checkStyleCont15 subFontS11 normalWt">
								<input
									id="checkId1"
									class="checkStyle"
									type="checkbox"
									v-model.trim="formData.numChangeYn"
									true-value="Y"
									false-value="N"
									:disabled="!formData.extraSupportAmt"
									@change="extraSupFnc(formData.numChangeYn)"
								/>
								<label for="checkId1" style="cursor: pointer">번호이동</label>
							</div>-->
						</td>
						<td>
							<div class="copyClass1">
								<input
									type="text"
									class="cal_table_input1 borderContColor3 amtInputBox1 amtNum"
									maxlength="9"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									v-model.trim="formData.extraSupportAmt"
									@input="extraKeyUpFnc(formData.extraSupportAmt, '추가지원금')"
									:disabled="
										formData.agreementType === 'CHOICE_AGRMN' ||
										!formData.telecomId
									"
								/>
							</div>
						</td>
					</tr>
				</table>
				<table class="w50P amt_table1 ml40">
					<tr :class="agreeTypeChoiceFnc(formData.agreementType)">
						<td style="font-weight: bolder">선택약정</td>
						<td>
							<div class="copyClass1">
								<input
									type="text"
									class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
									maxlength="9"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									:disabled="formData.agreementType === 'PUBNOTI_SUPAMT'"
									v-model.trim="choiceAmt"
									readonly
								/>
							</div>
						</td>
					</tr>
					<tr>
						<td style="height: 52px"></td>
						<td class="h40"></td>
					</tr>
				</table>
			</div>
		</div>

		<amt-select-pop
			v-if="amtSelectDialog"
			:title="amtSelectPopTitle"
		></amt-select-pop>
	</div>
</template>

<script>
import NumRepMixin from '../../../../../../common/NumRepMixin.js';
import AmtSelectPop from './popup/AmtSelectPop.vue';

export default {
	name: 'DeviceChargeData',
	components: {
		AmtSelectPop,
	},
	mixins: [NumRepMixin],
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		reduction: {
			get() {
				return this.$store.state.ChargeCalculatorModule.reduction;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.reduction = newValue;
			},
		},
		amtSelectDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.amtSelectDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.amtSelectDialog = newValue;
			},
		},
		amtSelectPopTitle: {
			get() {
				return this.$store.state.ChargeCalculatorModule.amtSelectPopTitle;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.amtSelectPopTitle = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		addSvcIdArray: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcIdArray;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcIdArray = newValue;
			},
		},
		preInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preInsertDto = newValue;
			},
		},
		billInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.billInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.billInsertDto = newValue;
			},
		},
		chgChargeDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chgChargeDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chgChargeDto = newValue;
			},
		},
		changeDate: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDate;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDate = newValue;
			},
		},
		changeDateName: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDateName;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDateName = newValue;
			},
		},
		choiceAmt() {
			if (this.formData.monthBasicAmt) {
				return Math.round(this.formData.monthBasicAmt * 0.25 * -1);
			} else {
				return 0;
			}
		},
		instlPeriod() {
			if (this.formData.instlPeriodType === 'CASH') {
				return 1;
			} else if (this.formData.instlPeriodType === 'MONTH_6') {
				return 6;
			} else if (this.formData.instlPeriodType === 'MONTH_12') {
				return 12;
			} else if (this.formData.instlPeriodType === 'MONTH_24') {
				return 24;
			} else if (this.formData.instlPeriodType === 'MONTH_30') {
				return 30;
			} else if (this.formData.instlPeriodType === 'MONTH_36') {
				return 36;
			} else if (this.formData.instlPeriodType === 'MONTH_48') {
				return 48;
			} else {
				return 1;
			}
		},
	},
	methods: {
		async getGoodsPubNotiSelectList(value) {
			await this.$store.dispatch(
				'ChargeCalculatorModule/getGoodsPubNotiSelectList',
				value,
			);
		},
		async getTelecomAddServiceList(value) {
			await this.$store.dispatch(
				'ChargeCalculatorModule/getTelecomAddServiceList',
				value,
			);
		},
		async commonCodeEnumList() {
			await this.$store.dispatch('ChargeCalculatorModule/commonCodeEnumList');
		},
		async commonCodeList() {
			await this.$store.dispatch('ChargeCalculatorModule/commonCodeList');
		},
		async getPubNotiInfo(goodsId, chargeId) {
			let param = {
				chargeId: chargeId,
				goodsId: goodsId,
			};
			const result = await this.$store.dispatch(
				'ChargeCalculatorModule/getPubNotiInfo',
				param,
			);
			if (result) {
				this.formData.monthBasicAmt = result.chargeAmt || 0;
				this.formData.releaseAmt = result.releaseAmt || 0;
				this.formData.pubNotiSupportAmt = result.supportAmt || 0;
				const choice = this.cal_agreementDiscount(this.formData.monthBasicAmt);
				this.formData.선택약정 = choice;
			}
		},
		async telecomSelectFnc(value) {
			this.addSvcIdArray = [];
			await this.formDataInit();
			this.formData.telecomId = value;
			// 부가서비스,보험도 초기화 필요함
			await this.getGoodsPubNotiSelectList(value);
			await this.getTelecomAddServiceList(value);
		},
		pubNotiFnc(goodsId, chargeId) {
			if (goodsId && chargeId) {
				this.getPubNotiInfo(goodsId, chargeId);
			}
		},
		// 약정할인
		cal_agreementDiscount(monthBasicAmt) {
			if (this.instlPeriod !== 1) {
				return Math.round(monthBasicAmt * this.instlPeriod * 0.25);
			}
			return 0;
		},
		// 추가지원금
		extraSupFnc(Yn) {
			if (Yn === 'Y') {
				this.formData.extraSupportAmt =
					Number(this.formData.extraSupportAmt) + 800;
			} else {
				this.formData.extraSupportAmt =
					Number(this.formData.extraSupportAmt) - 800;
			}
		},
		monthTypeFnc() {
			const choice = this.cal_agreementDiscount(this.formData.monthBasicAmt);
			this.formData.선택약정 = choice;
		},
		amtSelectPopFnc(title) {
			this.amtSelectDialog = true;
			this.amtSelectPopTitle = title;
		},
		formDataInit() {
			for (let data in this.formData) {
				this.formData[data] = null;
			}
			this.formData.maker = 0;
			this.formData.colorImgUrl =
				'https://www.sklmno.com/goods/upload/no_image_m.png';
			this.formData.agreementType = 'PUBNOTI_SUPAMT'; // 약정유형
			this.formData.instlPeriodType = 'MONTH_24'; // 약정유형
			this.formData.agreementPeriodType = 'MONTH_24'; // 약정기간
			this.formData.numChangeYn = 'N'; // 번호이동 Yn
			this.formData.releaseAmt = 0; // 출고가
			this.formData.pubNotiSupportAmt = 0; // 공시지원금
			this.formData.extraSupportAmt = 0; // 추가지원금
			this.formData.freeInstlAmt = 0; // 프리할부/선수납
			this.formData.etcDiscountAmt = 0; // 기타할인금
			this.formData.actualSellAmt = 0; // 실판매가
			this.formData.instlPrincipalAmt = 0; // 실판매가
			this.formData.monthDvcAmt = 0; // 월기기금액
			this.formData.monthBasicAmt = 0; // 월기본료
			this.formData.monthPaymentAmt = 0; // 월납부금액
			this.formData.addSvcAmt = 0; // 부가서비스
			this.formData.insAmt = 0; // 보험료
			this.formData.preCardAmt = 0; // 선할인카드
			this.formData.billCardAmt = 0; // 선할인카드
			this.codeList.goodsItemList = [];
			this.codeList.chargeItemList = [];
			this.addSvcIdArray = [];
			this.preInsertDto.cardFormData = null;
			this.preInsertDto.cardItem = null;
			this.billInsertDto.cardFormData = null;
			this.billInsertDto.cardItem = null;
			this.chgChargeDto = null;
			this.changeDateName = null;
			this.changeDate = null;
		},
		agreeTypePubFnc(e) {
			if (e === 'CHOICE_AGRMN') {
				return 'opacity2';
			}
		},
		agreeTypeChoiceFnc(e) {
			if (e === 'PUBNOTI_SUPAMT') {
				return 'opacity2';
			}
		},
		deviceInforVal(telId, name) {
			if (!telId) {
				return alert('통신사를 선택해 주세요.');
			}
			if (name === '요금제') {
				if (!this.formData.goodsId) {
					alert('기기를 선택해 주세요.');
				}
			}
		},
		extraKeyUpFnc(extraSupAmt, name) {
			this.formData.numChangeYn = 'N';

			/*if (!this.formData.chargeId) {
				return;
			}

			const result = this.totalAmtCreFnc(
				this.formData.releaseAmt,
				this.formData,
				['freeInstlAmt', 'etcDiscountAmt', 'pubNotiSupportAmt'],
			);

			if (this.formData.releaseAmt < this.totalDiscountAmt) {
				alert(
					`${name}은 ${String(result).replace(
						/\B(?=(\d{3})+(?!\d))/g,
						',',
					)}원 까지 가능합니다.`,
				);
				return (this.formData.extraSupportAmt = 0);
			}*/
			if (
				Math.abs(extraSupAmt) >=
				Math.abs(this.formData.pubNotiSupportAmt) * 0.15
			) {
				alert('추가지원금은 공시지원금의 15%를 넘을 수 없습니다.');
				return (this.formData.extraSupportAmt = 0);
			}
		},
		totalAmtCreFnc(basicAmt, array, newArray) {
			let totalAmt = 0;
			for (let data in newArray) {
				totalAmt += Math.abs(array[newArray[data]]);
			}
			return Number(basicAmt) - totalAmt;
		},
		amtReplaceFnc() {
			// 앞자리수 0일때 제거공식
			let amt = document.getElementsByClassName('amt');
			for (let i = 0; i < amt.length; i++) {
				//@ts-ignore
				if (amt[i].value.length >= 2) {
					//@ts-ignore
					if (amt[i].value.charAt(0) === '0') {
						//@ts-ignore
						amt[i].value = amt[i].value.slice(1);
					}
				}
			}
		},
	},
	created() {
		this.amtSelectDialog = false;
		this.commonCodeList();
		this.commonCodeEnumList();
		this.formDataInit();
	},
	updated() {
		// 출고가
		if (!this.formData.releaseAmt) {
			this.formData.releaseAmt = 0;
		} else {
			this.formData.releaseAmt = Math.abs(this.formData.releaseAmt);
		}
		// 공시지원금
		if (!this.formData.pubNotiSupportAmt) {
			this.formData.pubNotiSupportAmt = 0;
		} else {
			this.formData.pubNotiSupportAmt =
				Math.abs(this.formData.pubNotiSupportAmt) * -1;
		}
		// 추가지원금
		if (!this.formData.extraSupportAmt) {
			this.formData.extraSupportAmt = 0;
		} else {
			this.formData.extraSupportAmt =
				Math.abs(this.formData.extraSupportAmt) * -1;
		}
		// 앞자리수 0 제거 공식 - class: amt 붙여야 함
		this.amtReplaceFnc();
	},
};
</script>

<style scoped>
.cal_main_cont1 {
	position: relative;
}
.cal_main_cont1:before {
	position: absolute;
	content: '';
	display: block;
	width: 1px;
	height: 90px;
	top: 52px;
	left: 328px;
	background-color: #007ef2;
}
</style>
