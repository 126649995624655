<template>
	<div class="mt20">
		<h2 class="disIn">{{retentionYn ? '리텐션DB ' : ''}}상담이력</h2>
		<div class="newTableStyle">
			<table class="posR w100P mt5">
				<tr class="cur_d">
					<th class="w80">등록일자</th>
					<th class="w140">상담결과</th>
					<th>등록자</th>
				</tr>
			</table>
			<div class="narrowScroll" style="max-height: 200px; overflow-y: auto">
				<table class="posR w100P">
					<tr
						class="cur_d"
						style="height: 36px"
						:key="index"
						v-for="(item, index) in this.historyList"
					>
						<td class="mainBlack w80 pad0 lh14">
							{{ item.regiDatetime | moment('YYYY-MM-DD') }}<br />
							{{ item.regiDatetime | moment('HH:mm') }}
						</td>
						<td :style="tdColor(item)" class="w140 pad0 lh16">
							[{{ item.nextConsultStatusTypeMsg }}]
							{{ item.nextConsultStatusName }}
						</td>
						<td class="mainBlack pad0 lh16">
							{{ item.name }}<br />({{ item.username }})
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConsultHistory',
	props: {
		historyList: { type: Array, required: true },
		retentionYn: { type: Boolean, default: false },
	},
	methods: {
		tdColor(item) {
			return `color:${item.nextConsultStatusFontColor};background-color:${item.nextConsultStatusColor}`;
		},
	},
};
</script>

<style scoped></style>
