import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/MallEvent/';

// 이벤트 목록 조회
const getMallEventList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallEventList${param}`);
	return response;
};

// 이벤트 상세 조회
const getMallEvent = async data => {
	const response = await instance.get(`${domain}getMallEvent/${data}`);
	return response;
};

// 이벤트 등록
const insertMallEvent = async data => {
	const response = await instance.post(`${domain}insertMallEvent`, data);
	return response;
};

// 이벤트 수정
const updateMallEvent = async data => {
	const response = await instance.post(`${domain}updateMallEvent`, data);
	return response;
};

// 이벤트 삭제(단일)
const deleteMallEvent = async data => {
	const response = await instance.post(`${domain}deleteMallEvent`, data);
	return response;
};

// 몰 이벤트 진행여부 일괄 수정
const updateMallEventProcList = async data => {
	const response = await instance.post(`${domain}updateMallEventProc`, data);
	return response;
};

export {
	getMallEventList,
	getMallEvent,
	insertMallEvent,
	updateMallEvent,
	deleteMallEvent,
	updateMallEventProcList,
};
