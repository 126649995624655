<template>
	<div class="disFX" style="margin-left: 10px">
		<v-app id="inspire">
			<v-dialog v-model.trim="dialog" width="450" id="modal">
				<template v-slot:activator="{ on, attrs }">
					<button
						class="post-Api-Btn"
						style="
							width: 64px;
							padding: 0;
							border-radius: 3px;
							color: #fff;
							line-height: 36px;
							font-weight: 400;
						"
						:style="
							disabledYn
								? 'background-color: #bebebe !important; cursor: default;'
								: ''
						"
						:class="{ postWidth1: props_width }"
						:disabled="disabledYn"
						v-bind="attrs"
						v-on="on"
						:tabindex="
							paymentAddrChk === undefined ||
							(paymentAddrChk === true &&
								$store.state.ApplicationFormCreationModule.formData.basic
									.beforeOpeningType === 'BEFORE_OPENING')
								? '0'
								: '-1'
						"
					>
						검색
					</button>
				</template>

				<v-card>
					<v-card-text>
						<vue-daum-postcode
							v-if="josoApiType === 'K'"
							@complete="resultData"
						></vue-daum-postcode>
						<div
							class="pop-address-search"
							style="min-height: 466px"
							v-if="josoApiType === 'J'"
						>
							<div
								class="pop-address-search-inner"
								style="
									position: relative;
									width: 100%;
									height: 100%;
									background-color: rgb(255, 255, 255);
									z-index: 0;
									overflow: hidden;
									min-width: 300px;
									margin: 0px;
									padding: 0px;
								"
							>
								<div class="search-wrap">
									<fieldset style="border: 0px">
										<span class="wrap">
											<input
												type="text"
												class="popSearchInput"
												v-model="jusoObj.keyword"
												v-on:keyup.enter="searchAddr"
												title="예) 판교역로 235, 분당 주공, 삼평동 681"
												placeholder="예) 판교역로 235, 분당 주공, 삼평동 681"
												style="
													width: 310px;
													line-height: 28px;
													padding: 10px;
													outline: none;
													border-bottom: 1px solid #7f7f7f;
													margin: 0 5px;
												"
											/>
											<input
												type="button"
												title="검색"
												@click="searchAddr"
												value="주소검색"
												style="
													cursor: pointer;
													background-color: #0037a1;
													color: #fff;
													padding: 9px;
													border-radius: 3px;
												"
											/>
										</span>
										<div class="juso_info">
											<!--											<div class="juso_info_w">
												<input
													type="checkbox"
													tabindex="4"
													id="ckHstryYn"
													name="ckHstryYn"
													title="변동된 주소정보 포함"
													style="cursor: pointer"
												/>
												<label for="ckHstryYn">변동된 주소정보 포함</label>
											</div>-->
										</div>
									</fieldset>
								</div>
								<div id="list" class="result">
									<table class="table">
										<tr v-if="jusoObj.errorResult !== ''">
											<td>{{ jusoObj.errorResult }}</td>
										</tr>
										<tr v-for="item in postList">
											<td
												@click="
													resultData({
														address: item.roadAddrPart1,
														zonecode: item.zipNo,
													})
												"
											>
												<span class="redfont">{{ item.zipNo }}</span
												><br />
												<span
													><span class="mainBlue2">도로명:</span>
													{{ item.roadAddrPart1 }}</span
												><br />
												<span
													><span class="mainBlue2">지　번:</span>
													{{ item.jibunAddr }}</span
												>
											</td>
										</tr>
									</table>
									<!-- 페이지 정보 테이블 -->
									<table class="table" v-if="postList.length > 0">
										<tr>
											<td style="width: 58px">
												<a
													v-if="jusoObj.prevBtn"
													@click="changePageDiv('prev')"
													style="display: inline-block; width: 100%"
													>◁</a
												>
											</td>
											<td
												v-for="n in jusoObj.countPerPage"
												:key="n"
												style="padding: 0px !important; width: 30px"
											>
												<div
													v-if="index(n) <= jusoObj.pagination"
													style="text-align: center"
												>
													<div v-if="jusoObj.currentPage == index(n)">
														<a
															style="
																font-weight: bold;
																color: black;
																display: inline-block;
																width: 100%;
															"
															>{{ index(n) }}</a
														>
													</div>
													<div v-else>
														<a
															@click="goPageDiv(index(n))"
															style="display: inline-block; width: 100%"
															>{{ index(n) }}</a
														>
													</div>
												</div>
											</td>
											<td style="width: 58px; text-align: right">
												<a
													v-if="jusoObj.nextBtn"
													@click="changePageDiv('next')"
													style="display: inline-block; width: 100%"
													>▷</a
												>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-app>
	</div>
</template>

<script>
import Vue from 'vue';
import VueDaumPostcode from 'vue-daum-postcode';

Vue.use(VueDaumPostcode);

export default {
	props: ['postDialog', 'paymentAddrChk', 'props_width', 'disabledYn'],
	data() {
		return {
			dialog: false,
			josoApiType: 'K',
			postList: {},
			jusoObj: {
				total: '',
				keyword: '',
				searchData: '',
				prevBtn: false,
				nextBtn: true,
				pagination: 0,
				pageScope: 0,
				currentPage: 0,
				countPerPage: 10,
			},
		};
	},
	methods: {
		resultData(data) {
			let addressData = {
				address: data.address,
				zonecode: data.zonecode,
			};
			this.$emit('postapi', addressData);
			this.dialog = !this.dialog;
		},
		searchAddr() {
			this.jusoObj.currentPage = 1;
			this.jusoObj.pageScope = 0;
			this.getAddrLoc();
		},
		async getAddrLoc() {
			// 적용예 (api 호출 전에 검색어 체크)
			/*if (!checkSearchedWord(document.form.keyword)) {
				return ;
			}
			 */
			let currentPage = this.jusoObj.currentPage;
			let countPerPage = this.jusoObj.countPerPage;
			let resultType = 'json';
			let confmKey = 'U01TX0FVVEgyMDIyMDIwMzE1MTIyMjExMjIwNTM=';
			let keyword = this.jusoObj.keyword;

			let params = {
				currentPage: currentPage,
				countPerPage: countPerPage,
				resultType: resultType,
				confmKey: confmKey,
				keyword: keyword,
			};
			let result = await this.$jsonp(
				'https://www.juso.go.kr/addrlink/addrLinkApiJsonp.do',
				params,
			)
				.then(function (response) {
					result = response.results;
					return result;
				})
				.catch(function (error) {
					console.log(error);
				});
			if (result !== null) {
				if (result.common.errorMessage === '정상') {
					this.jusoObj.errorResult = '';
					this.postList = result.juso;
					this.jusoObj.total = result.common.totalCount;
					this.jusoObj.pagination = parseInt(
						this.jusoObj.total / this.jusoObj.countPerPage,
					);
					if (this.jusoObj.total % this.jusoObj.countPerPage) {
						this.jusoObj.pagination += 1;
						this.setPageBtn();
					}
				} else {
					this.postList = {};
					this.jusoObj.errorResult = result.common.errorMessage;
				}
			}
		},
		index(page) {
			return this.jusoObj.pageScope + page;
		},
		setPageBtn() {
			// 다음페이지 버튼
			if (
				this.jusoObj.pagination - this.jusoObj.pageScope < 11 ||
				this.jusoObj.pagination < 11
			)
				this.jusoObj.nextBtn = false;
			else this.jusoObj.nextBtn = true;
			//이전페이지 버튼
			if (this.jusoObj.currentPage > 10) this.jusoObj.prevBtn = true;
			else this.jusoObj.prevBtn = false;
		},
		goPageDiv(page) {
			this.jusoObj.currentPage = page;
			this.getAddrLoc();
		},
		changePageDiv(type) {
			if (type === 'prev') this.jusoObj.pageScope -= this.jusoObj.countPerPage;
			else if (type === 'next')
				this.jusoObj.pageScope += this.jusoObj.countPerPage;
			this.jusoObj.currentPage = this.jusoObj.pageScope + 1;
			this.getAddrLoc();
		},
	},
	watch: {
		postDialog: {
			deep: true,
			handler: function (newVal) {
				this.dialog = newVal;
			},
		},
		dialog: {
			deep: true,
			handler: function (newVal) {
				if (!newVal) {
					this.$emit('closeDialog', this.dialog);
				}
			},
		},
	},
};
</script>

<style scoped>
.vue-daum-postcode {
	width: 100% !important;
	display: block !important;
}
/* 바탕 배경 이미지 */
/*.pop-address-search { background-image: url(http://www.0000.com/img/backImg.png);}*/
/* 회사 로고 이미지 */
/*.pop-address-search .pop-address-search-inner .logo { background: url(http://www.0000.com/img/logo.png) no-repeat; background-position:center; }*/
/* 바탕 배경색상 */
/* 검색창 바탕 배경색상 */
/* 검색창 색상 */
.pop-address-search .pop-address-search-inner .wrap input {
	background-color: #ffffff;
}
/* 검색버튼 색상 */
.pop-address-search .pop-address-search-inner .wrap {
	background-color: #ffffff;
}
/* 본문 배경색(홀수) */
.pop-address-search
	.pop-address-search-inner
	.result
	table.data-col
	tbody
	tr:nth-child(odd)
	td {
	background: #ffffff;
}
/* 본문 배경색(짝수) */
.pop-address-search
	.pop-address-search-inner
	.result
	table.data-col
	tbody
	tr:nth-child(even)
	td {
	background: #ffffff;
}
#list {
	padding: 10px;
	background-color: rgba(49, 49, 49, 0.1);
	margin-top: 10px;
}
.pop-address-search table {
	width: 100%;
	background-color: #fff;
}
.pop-address-search table tr {
	border: 1px solid rgba(49, 49, 49, 0.1);
}
.pop-address-search table tr td {
	padding: 10px !important;
	cursor: pointer;
}
.postWidth1 {
	width: 89.84px !important;
}
</style>
