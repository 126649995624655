<template>
	<div class="padW30">
		<div class="mt20 posR search_icon1 w300">
			<input
				type="text"
				class="device_input_style1 w300"
				placeholder="검색어를 입력해 주세요"
				@input="textSearchFnc($event.target.value)"
			/>
		</div>
		<div
			class="device_table_style1 mt15 narrowScroll posR"
			style="height: 430px !important"
		>
			<table class="w100P">
				<tr
					style="position: sticky; top: 0; z-index: 11"
				>
					<th class="w150">보험명</th>
					<th class="w200">상세설명</th>
					<th class="w80">서비스 요금</th>
				</tr>
<!--				<tr @click="insuranceResetFnc" class="cur_p" :class="{ backColorBlue6: insId === 0 }">
					<td class="mainBlack">미가입</td>
					<td>-</td>
					<td class="txAnC">0원</td>
				</tr>-->
				<tr
					v-if="searchList.length > 0"
					class="cur_p"
					:class="{ backColorBlue6: item.addSvcId === insId }"
					v-for="(item, index) in searchList"
					:key="index"
					@click="insSelectFnc(item)"
				>
					<td class="mainBlack">
						{{ item.addSvcName }}
					</td>
					<td>{{ item.addSvcMemo ? item.addSvcMemo : '정보없음' }}</td>
					<td class="txAnC">
						{{
							String(item.addSvcCharge).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}}
						원
					</td>
				</tr>
				<tr v-else style="cursor: default">
					<td class="txAnC" colspan="20">데이터가 없습니다.</td>
				</tr>
			</table>
		</div>
		<div class="disFx justify-end mt10 mb20">
		</div>
	</div>
</template>

<script>
import { ch2pattern } from '@/common/common';

export default {
	name: 'InsuranceSelect',
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcDialog = newValue;
			},
		},
	},
	data: () => ({
		searchList: [],
		insId: null,
		insName: null,
		insAmt: null,
	}),
	methods: {
		insSelectFnc(item) {
			this.insName = item.addSvcName;
			this.insAmt = item.addSvcCharge;
			this.insId = item.addSvcId;
			this.insertFnc(this.insName, this.insAmt, this.insId);
		},
		insertFnc(insName, insAmt, insId) {
			this.formData.insName = insName;
			this.formData.insAmt = insAmt;
			this.formData.insId = insId;
			this.dialog = false;
		},
		setInsData() {
			this.searchList = [...this.codeList.insuranceItemList];
			this.insName = this.formData.insName;
			this.insAmt = this.formData.insAmt;
			this.insId = this.formData.insId;
		},
		textSearchFnc(text) {
			this.searchList = [];
			const addSvcList = [...this.codeList.insuranceItemList];
			let result = this.createFuzzyMatcher(text);
			if (text) {
				for (let item in addSvcList) {
					if (result.test(addSvcList[item].addSvcName)) {
						this.searchList.push(addSvcList[item]);
					}
				}
			} else {
				this.searchList = addSvcList;
			}
		},
		createFuzzyMatcher(input) {
			const pattern = input.split('').map(ch2pattern).join('.*?');
			return new RegExp(pattern);
		},
		insuranceResetFnc() {
			this.insName = null;
			this.insAmt = 0;
			this.insId = 0;
		},
	},
	created() {
		this.setInsData();
	},
};
</script>

<style scoped></style>
