import Vue from 'vue';
import Vuex from 'vuex';
import {
	getSample,
	insertStoreDomain,
	updateMallDomain,
	checkDuplicationSubdomain,
	getStoreDomain,
	getStoreDomainList,
	getStoreInfo,
	checkDuplicationStoreDomain,
	reviewStoreDomain,
	updateStoreDomainUse,
	getStoreDomainHistory,
	updateStoreDomainInfo,
} from '../../../api/mall_mgmt/MallDomainMgmtApi';
Vue.use(Vuex);

const MallPageMgmtModule = {
	namespaced: true,
	state: {
		storeDomainDto: {
			mallSubdomain: null, // 몰 서브도메인
			mallTitle: null, // 온라인몰 명칭
			mallDescription: null, // 온라인몰 소개 글
			mallKeywords: null, // 검색 키워드 등록
			telecomSalesNum: null, // 통신판매업신고
			customer_service_tel: null, // 고객센터 대표번호
			requestContents: null, // 추가요청사항
			agreeIdList: [], // 약관동의 IDs
			ictmarketPopUrl: null, // 사전승낙 팝업페이지 url
		},
		storeInfoDto: {
			storeName: null,
			ceoName: null,
			storePhone: null,
			storePhone1: null,
			storePhone2: null,
			storePhone3: null,
			storeEmail: null,
			bizNum: null,
			storeAddr: null,
			storeAddrDetail: null,
			storeZipCode: null,
		},
		formData: {
			saleStoreId: null, // 판매점 아이디
			hopeSubdomain1: null, // 희망 서브 도메인1
			hopeSubdomain2: null, // 희망 서브 도메인2
			hopeSubdomain3: null, // 희망 서브 도메인3
			requestContents: null, // 요청사항
		},
		domainInfor: null,
		filterData: {
			pageNo: 20,
		},
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		domainList: [],
		agreeDomainDialog: false,
		mallAuditDialog: false,
		rejectHistoryList: [],
		agreeIdList: [],
		preAgreeIdList: [],
		allChecked: false,
	},
	mutations: {
		setSample(state, result) {
			// sample Set
		},
		setSampleInit(state) {
			// sample Init
		},
		setSaleStoreDomain(state, result) {
			state.domainInfor = result;
			/*state.formData.hopeSubdomain1 = result.hopeSubdomain1;
			state.formData.hopeSubdomain2 = result.hopeSubdomain2;
			state.formData.hopeSubdomain3 = result.hopeSubdomain3;*/
			/*state.formData.requestContents = result.requestContents;*/
		},
		setMallDomainList(state, result) {
			state.domainList = result.resultList || [];
			state.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setStoreInfo(state, result) {
			state.storeInfoDto = result;
		},
		setStoreDomainHistory(state, result) {
			state.rejectHistoryList = [];
			if (result) {
				state.rejectHistoryList = result;
			}
		},
	},
	actions: {
		async getSample({ commit }, data) {
			try {
				commit('setSampleInit');
				const result = await getSample(data);
				if (result) {
					commit('setSample', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertStoreDomain({ commit }, data) {
			// 도메인 등록
			try {
				const result = await insertStoreDomain(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallDomain({ commit }, data) {
			// 도메인 수정
			try {
				const result = await updateMallDomain(data);
				if (result) {
					console.log(result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async reviewStoreDomain({ commit }, data) {
			// 영업점 입점신청 심사
			try {
				const result = await reviewStoreDomain(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async checkDuplicationSubdomain({ commit }, data) {
			// 중복 도메인 검사
			try {
				const result = await checkDuplicationSubdomain(data);
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreDomain({ commit }, data) {
			// 판매점 상세 조회
			try {
				const result = await getStoreDomain(data);
				if (result) {
					commit('setSaleStoreDomain', result.data.data);
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreInfo({ commit }, data) {
			// 로그인한 영업점 정보조회
			try {
				const result = await getStoreInfo(data);
				if (result) {
					commit('setStoreInfo', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreDomainList({ commit }, data) {
			// 로그인한 영업점 정보조회
			try {
				const result = await getStoreDomainList(data);
				if (result) {
					commit('setMallDomainList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreDomainHistory({ commit }, data) {
			// 영업점 심사 반려 이력 조회
			try {
				const result = await getStoreDomainHistory(data);
				if (result) {
					commit('setStoreDomainHistory', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async checkDuplicationStoreDomain({ commit }, data) {
			// 입점관리 이용약관 조회
			try {
				const result = await checkDuplicationStoreDomain(data);
				if (result) {
					return result.data.data === 0 ? result.data.data : 1;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateStoreDomainUse({ commit }, data) {
			// 영업점 사용여부 수정
			try {
				const result = await updateStoreDomainUse(data);
				if (result) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateStoreDomainInfo({ commit }, data) {
			// 입점 영업점 정보 수정
			try {
				const result = await updateStoreDomainInfo(data);
				if (result) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default MallPageMgmtModule;
