<template>
	<div>
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="600" @keydown.esc="Cancel">
				<v-card class="borderRadiReset infoPopup" v-click-outside="Cancel">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">매칭 알림</h2>
						<span class="closeBtn1 cur_p" @click="Cancel"></span>
					</v-card-title>

					<v-card-text class="pad30">

						<h4 class="mainRed">{{ matchingResultTitle }}</h4>
						<div class="new_popTable1">
							<table class="w100P">
								<tr>
									<td v-html="matchingResultMsg" style="font-size: 15px;">
									</td>
								</tr>
							</table>
						</div>
						<p class="mj0"></p>

						<div class="new_popTable1">

							<div class="disFx justify-end mt10">
								<v-btn
									v-if="matchingResultMsgType === 'CONFIRM'"
									class="backColorMint1 mainWhite borderReset w80"
									text
									outlined
									@click="Submit"
								>
									확인
								</v-btn>
								<v-btn
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
									text
									@click="Cancel"
									outlined
								>
									{{matchingResultMsgType === 'CONFIRM' ? '취소' : '닫기'}}
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'checkMoveDevicePop',
	props: {
		dvcType: {type: String, required: true},
		matchingResultMsg: {type: String, required: true},
		matchingResultTitle: {type: String, required: true},
		matchingResultMsgType: {type: String, required: true},
	},
	data: () => ({
		templateTitle: '',
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.checkMoveDeviceDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.checkMoveDeviceDialog = newValue;
			},
		},
		matchingDeviceType: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.matchingDeviceType;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.matchingDeviceType = newValue;
			},
		},
		matchingUsimType: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.matchingUsimType;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.matchingUsimType = newValue;
			},
		},
	},
	methods: {
		async Submit() {
			if (confirm('저장하시겠습니까?')) {
				this.$emit('insertFinalSubmit');
			} else {
				this.matchingDeviceType = null;
				this.matchingUsimType = null;
			}
			this.dialog = false;
		},
		async Cancel() {
			if(this.dvcType === 'DVC') {
				this.matchingDeviceType = null;
			} else if (this.dvcType === 'USIM') {
				this.matchingUsimType = null;
			} else {
				this.matchingDeviceType = null;
				this.matchingUsimType = null;
			}
			if(this.matchingResultMsgType === 'ALERT') {
				if(this.dvcType === 'DVC') {
					document.getElementById('deviceRawBarcode').focus();
				} else if (this.dvcType === 'USIM') {
					document.getElementById('usimRawBarcode').focus();
				} else {
					document.getElementById('deviceRawBarcode').focus();
				}
			}
			this.dialog = false;
		}
	},
	async created() {
	},
};
</script>

<style scoped></style>
