<template>
	<div>
		<div class="bottomSearchBar mt-5 w100P" v-if="this.$route.path.indexOf('retention-mgmt') === -1">
			<div class="disFx justify-space-between">
				<h2 class="mainBlack2">메모</h2>
				<memo-tab></memo-tab>
			</div>
			<memo-input :loadFromPopup="loadFromPopup"></memo-input>
			<memo-list
				:maxHeight="maxHeight"
				:useScrollArrow="useScrollArrow"
				:quickOpen="quickOpen"
				:taskHistoryPop="taskHistoryPop"
				:formData="formData"
			></memo-list>
		</div>
		<div class="bottomSearchBar w100P" v-if="this.$route.path.indexOf('retention-mgmt') !== -1">
			<memo-list
					:maxHeight="maxHeight"
					:useScrollArrow="useScrollArrow"
					:quickOpen="quickOpen"
					:taskHistoryPop="taskHistoryPop"
					:formData="formData"
			></memo-list>
		</div>
	</div>
</template>

<script>
import memoTab from './memo/memoTab.vue';
import memoInput from './memo/memoInput.vue';
import memoList from './memo/memoList.vue';

export default {
	props: {
		applId: Number,
		// required: true
		maxHeight: Number,
		useScrollArrow: Boolean,
		loadFromPopup: Boolean,
		quickOpen: Boolean,
		taskHistoryPop: Boolean,
		formData: Object,
	},
	components: {
		memoTab,
		memoInput,
		memoList,
	},
	created() {
		this.$store.state.memoModule.applId = this.applId;
	},
};
</script>

<style></style>
