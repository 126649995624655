import Vue from 'vue';
import Vuex from 'vuex';
import {
	getMallEventList,
	getMallEvent,
	insertMallEvent,
	updateMallEvent,
	deleteMallEvent,
	updateMallEventProcList,
} from '../../../api/mall_mgmt/MallEventMgmtApi';
Vue.use(Vuex);

const MallEventMgmtModule = {
	namespaced: true,
	state: {
		resetData: false,
		dateResetData: false,
		filterInitChk: false,
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		filterData: {
			mallEventSearchType: 'START_DATE',
			srhStartDate: '',
			srhEndDate: '',
			title: '',
			regiName: '',
			eventProcYn: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		mallEventList: [],
	},
	mutations: {
		setMallEventList(state, result) {
			state.mallEventList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setMallEventListInit(state) {
			state.mallEventList = [];
		},
		filterInit(state) {
			state.resetData = true;
			state.dateResetData = true;
			state.filterData.mallEventSearchType = 'START_DATE';
			state.filterData.srhStartDate = '';
			state.filterData.srhEndDate = '';
			state.filterData.title = '';
			state.filterData.regiName = '';
			state.filterData.eventProcYn = '';
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
	},
	actions: {
		async getMallEventList({ commit }, data) {
			try {
				commit('setMallEventListInit');
				for (let item in data) {
					if (
						data[item] === '' ||
						data[item] === null ||
						data[item] === undefined
					) {
						delete data[item];
					}
				}
				const result = await getMallEventList(data);
				if (result) {
					commit('setMallEventList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallEvent({ commit }, data) {
			try {
				const result = await getMallEvent(data);
				if (result) {
					return result.data.data;
				} else {
					history.back();
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallEvent({ commit }, data) {
			try {
				const result = await insertMallEvent(data);
				if (result.data.status === 200) {
					if(result.data.resultCode === '0000') {
						alert('저장되었습니다.');
						return true;
					} else {
						if(result.data.resultCode === '4301') {
							alert('진행중인 이벤트가 있어 등록하실 수 없습니다.')
						} else {
							alert(result.data.resultMsg)
						}
						// alert('기간이 중복되는 리뷰이벤트가 이미 존재합니다.')
						return false;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallEvent({ commit }, data) {
			try {
				const result = await updateMallEvent(data);
				if (result.data.status === 200) {
					if(result.data.resultCode === '0000') {
						alert('수정되었습니다.');
						return true;
					} else {
						alert(result.data.resultMsg)
						// alert('기간이 중복되는 리뷰이벤트가 이미 존재합니다.')
						return false;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteMallEvent({ commit }, data) {
			try {
				const result = await deleteMallEvent(data);
				if (result.data.status === 200) {
					alert('삭제되었습니다.');
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallEventProcList({ commit }, data) {
			try {
				const result = await updateMallEventProcList(data);
				if (result.data.status === 200) {
					if(result.data.resultCode === '0000') {
						alert('수정되었습니다.');
						return true;
					} else {
						alert(result.data.resultMsg)
						// alert('기간이 중복되는 리뷰이벤트가 이미 존재합니다.')
						return false;
					}
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default MallEventMgmtModule;
