<template>
	<div class="calculatorSummary posR">
		<div
			class="disFx mb-1"
			style="border-bottom: 2px solid #007ef2; height: 32px"
		>
			<h2
				class="cur_p font-size-13"
				:class="{ titleBtnStyleA: tabDialog, titleBtnStyleB: !tabDialog }"
				@click="tabFnc(0)"
			>
				요금계산
			</h2>
			<span
				v-if="!tabDialog"
				class="disIn backColorWhite"
				style="width: 88px; height: 2px; position: absolute; top: 36px"
			></span>
			<h2
				class="cur_p font-size-13"
				style="margin-left: 3px"
				@click="tabFnc(1)"
				:class="{ titleBtnStyleA: !tabDialog, titleBtnStyleB: tabDialog }"
			>
				기기비교
			</h2>
			<span
				v-if="tabDialog"
				class="disIn backColorWhite"
				style="
					width: 86px;
					height: 2px;
					position: absolute;
					top: 36px;
					left: 104px;
				"
			></span>
			<i
				v-if="componentIndex === '1'"
				class="fas fa-plus mainBlue2 ml20 mt-2 font-size-15 cur_p"
				@click="calculatorCntFnc('plus')"
			></i>
			<i
				v-if="
					componentIndex !== '1' &&
					Number(componentIndex) === this.calculatorActiveCnt
				"
				class="fas fa-times mainRed ml20 mt-2 font-size-16 cur_p"
				@click="calculatorCntFnc('minus')"
			></i>
		</div>
		<template v-if="tabIndex === 0">
			<!--			<section class="section0 mb-1" style="border-bottom: 1px solid #007ef2">
				<table class="chargeSummaryTable tableTwoCol mb-1">
					<tr>
						<th>통신사</th>
						<td class="disFx ml20">
							<div class="radioStyle w50">
								<input :id="`SKT${index}`" type="radio" name="telecom" />
								<label :for="`SKT${index}`">SKT</label>
							</div>
							<div class="radioStyle w40">
								<input :id="`KT${index}`" type="radio" name="telecom" />
								<label :for="`KT${index}`">KT</label>
							</div>
							<div class="radioStyle w40">
								<input :id="`LGT${index}`" type="radio" name="telecom" />
								<label :for="`LGT${index}`">LGT</label>
							</div>
						</td>
					</tr>
				</table>
			</section>-->
			<section
				class="section1 pb-1 mb-1"
				style="border-bottom: 1px solid #007ef2"
			>
				<div>
					<div style="display: flex">
						<div style="width: 75px" class="ml5">
							<div style="height: 100px; overflow: hidden">
								<img
									:id="`goodsPic_${componentIndex}_2`"
									:src="formData.colorImgUrl"
									alt=""
									style="
										object-fit: cover;
										width: 75px;
										height: 100px;
										overflow-y: hidden;
									"
								/>
							</div>
						</div>
						<div class="ml-1 disFx flexDColC">
							<h3>기기</h3>
							<div class="datePickerStyle1 mt1" style="width: 145px">
								<v-autocomplete
									:items="hopeDeviceList"
									style="font-size: 12px"
									item-text="goodsName"
									item-value="goodsId"
									return-object
									outlined
									placeholder="선택해주세요."
									class="w420 dvcFontBox"
									v-model="selectedGoods"
									@click="checkTelecomId($event)"
									@input="changeGoodsData($event)"
								>
								</v-autocomplete>
							</div>
							<div
								class="padW5d disFx mt-2"
								:class="`colorBall_${componentIndex}_2`"
								v-if="
									formData.goodsSpec.goodsColorList &&
									formData.goodsSpec.goodsColorList.length > 0
								"
							>
								<span
									class="colorBall mr5"
									@click="changeDeviceColor('2', item, $event.target)"
									v-for="(item, index) in formData.goodsSpec.goodsColorList"
									:style="`background:${item.colorCode}`"
									:key="index"
								></span>
							</div>
							<p
								class="font-size-10 ma-0 pt-1"
								style="line-height: 12px !important"
							>
								{{ formData.selectedColor }}
							</p>
						</div>
					</div>
				</div>
			</section>
			<section
				class="section2 pb-1 mb-1"
				style="border-bottom: 1px solid #007ef2"
			>
				<table class="chargeSummaryTable tableTwoCol">
					<tr>
						<th class="fw600">할부개월</th>
						<td>
							<div class="datePickerStyle1 w170" style="height: 28px">
								<v-autocomplete
									:items="installmentsPeriodType"
									style="font-size: 12px"
									item-text="name"
									item-value="value"
									outlined
									placeholder="선택해주세요."
									class="w420 dvcFontBox"
									@input="changePenaltyPeriod"
									:disabled="formData.goodsId === 0"
									v-model="formData.instlPeriodType"
								>
								</v-autocomplete>
							</div>
						</td>
					</tr>
					<tr>
						<th class="fw600">요금제</th>
						<td>
							<div class="datePickerStyle1 w170" style="height: 28px">
								<v-autocomplete
									:items="formData.goodsId === 0 ? [] : hopeChargeSelectList1"
									style="font-size: 12px"
									item-text="chargeName"
									item-value="chargeId"
									return-object
									outlined
									placeholder="선택해주세요."
									class="w420 dvcFontBox"
									v-model="formData.chargeId"
									:disabled="formData.goodsId === 0"
									@click="checkGoodsId($event)"
									@input="changeCharge($event)"
								>
								</v-autocomplete>
							</div>
						</td>
					</tr>
					<tr>
						<th class="fw600">요금감면</th>
						<td>
							<div class="datePickerStyle1 w170" style="height: 28px">
								<v-autocomplete
									:items="ChargeReductType"
									style="font-size: 12px"
									item-text="name"
									item-value="value"
									outlined
									placeholder="선택해 주세요."
									:disabled="formData.goodsId === 0 || formData.chargeId === 0"
									class="w420 dvcFontBox"
									v-model="formData.chargeReductType"
								>
								</v-autocomplete>
							</div>
						</td>
					</tr>
				</table>
			</section>
			<section class="section3">
				<div>
					<table class="chargeSummaryTable borderContColor2 tableThreeCol">
						<tr>
							<th>출고가/기본료</th>
							<td class="newTdAmt">
								<b>{{ formatWon(formData.amtData.releaseAmt) || '0' }} 원</b>
							</td>
							<td class="newTdAmt">
								<b>{{ formatWon(formData.amtData.monthBasicAmt) || '0' }} 원</b>
							</td>
						</tr>
					</table>
				</div>
				<div class="mt-2">
					<div class="disFx justify-space-between">
						<h5 class="mb-1">할인상세내역</h5>
						<small
							style="font-size: 10px; color: #999; margin-top: 2px"
							class="fw500"
							>공시/선택 약정기간:24개월</small
						>
					</div>
					<table class="chargeSummaryTable borderContColor2 tableThreeCol">
						<tr>
							<th class="grayCell">할인구분</th>
							<th class="grayCell">공시지원</th>
							<th class="grayCell">선택약정</th>
						</tr>
						<tr>
							<td>공시지원금</td>
							<td class="newTdAmt mainGreen2">
								- {{ formatWon(formData.amtData.supportAmt) || '0' }}<b> 원</b>
							</td>
							<td class="newTdAmt mainGray1"><b> 원</b></td>
						</tr>
						<tr>
							<td style="line-height: 12px">
								추가지원금<br /><small>(15%)</small>
							</td>
							<td
								class="disFx newTdAmt mainGreen2"
								style="border: none; margin-top: 3px"
							>
								-<input
									oninput="this.value = this.value.replace(/[^0-9]/g, '');"
									class="w50 ml5 text-right mainGreen2"
									style="border: 1px solid #999; border-radius:3px;margin-right: 2px; padding-right: 2px;height: 20px; !important"
									v-model.trim="formData.amtData.extraSupportAmt"
									:disabled="
										formData.amtData.supportAmt === null ||
										formData.amtData.supportAmt === 0
									"
									@focusout="checkWhetherALessThanB(0.15)"
									maxlength="7"
								/><b> 원</b>
							</td>
							<td class="newTdAmt mainGray1"><b> 원</b></td>
						</tr>
						<tr>
							<td>약정할인<small>(25%)</small></td>
							<td class="newTdAmt mainGray1"><b> 원</b></td>
							<td class="newTdAmt mainGreen2">
								-
								{{
									formData.amtData.monthBasicAmt
										? formatWon(
												cal_agreementDiscount(formData.amtData.monthBasicAmt),
										  )
										: '0'
								}}<b> 원</b>
							</td>
						</tr>
						<tr>
							<td>요금감면</td>
							<td colspan="2" class="text-center mainGreen2">
								월 -
								{{
									formData.amtData.monthBasicAmt &&
									formData.chargeReductType !== 'NONE'
										? formatWon(cal_chargeReduct())
										: '0'
								}}<b> 원</b>
							</td>
						</tr>
					</table>
					<table class="chargeSummaryTable borderContColor2 tableThreeCol mt-2">
						<tr>
							<th class="grayCell">납부구분</th>
							<th class="grayCell">공시지원</th>
							<th class="grayCell">선택약정</th>
						</tr>
						<tr>
							<th class="text-left">할부원금</th>
							<td class="newTdAmt">
								{{ formatWon(cal_instlPeriod()) || '0' }}<b> 원</b>
							</td>
							<td class="newTdAmt">
								{{ formatWon(formData.amtData.releaseAmt) || '0' }}
								<b> 원</b>
							</td>
						</tr>
						<tr>
							<th class="text-left lh14" style="line-height: 12px">
								<b>할부이자<small>(5.9%)</small></b>
							</th>
							<td class="newTdAmt">
								{{
									formData.instlPeriodType !== 'CASH'
										? formatWon(
												cal_instlPeriodPlusInterest(cal_instlPeriod(), 1) *
													instlPeriod -
													cal_instlPeriod(),
										  ) || '0'
										: '0'
								}}<b> 원</b>
							</td>
							<td class="newTdAmt">
								{{
									formData.instlPeriodType !== 'CASH'
										? formatWon(
												cal_instlPeriodPlusInterest(
													formData.amtData.releaseAmt,
													2,
												) *
													instlPeriod -
													formData.amtData.releaseAmt,
										  ) || '0'
										: '0'
								}}<b> 원</b>
							</td>
						</tr>
						<tr>
							<th class="grayCell font-black fw600">월 할부금+이자</th>
							<td class="newTdAmt">
								<b
									>{{
										formatWon(
											cal_instlPeriodPlusInterest(cal_instlPeriod(), 1) || '0',
										)
									}}
									원</b
								>
							</td>
							<td class="newTdAmt">
								<b
									>{{
										formatWon(
											cal_instlPeriodPlusInterest(
												formData.amtData.releaseAmt,
												2,
											),
										) || '0'
									}}
									원</b
								>
							</td>
						</tr>
						<tr>
							<th style="background: #ccf0df"><b>월 통신료</b></th>
							<td class="newTdAmt">
								<b>{{ formatWon(cal_monthlyCharge(1)) }} 원</b>
							</td>
							<td class="newTdAmt">
								<b>{{ formatWon(cal_monthlyCharge(2)) }} 원</b>
							</td>
						</tr>
					</table>
					<table class="chargeSummaryTable mt-2 borderContColor2 tableThreeCol">
						<tr>
							<th
								class="lh14 backColorBlue2 mainWhite"
								style="line-height: 12px"
							>
								월 총 납부금<br /><small>(vat 포함)</small>
							</th>
							<td class="newTdAmt mainBlue2 fw600">
								{{ formatWon(cal_monthlyTotal(1)) }} 원
							</td>
							<td class="newTdAmt mainBlue2 fw600">
								{{ formatWon(cal_monthlyTotal(2)) }} 원
							</td>
						</tr>
					</table>
				</div>
				<div class="mt-2">
					<div>
						<div class="disFx justify-space-between">
							<h3 class="mt5">해지위약금</h3>
							<div class="datePickerStyle1 w130" style="height: 28px">
								<v-autocomplete
									:items="penaltyTypeList"
									style="font-size: 12px"
									item-text="name"
									item-value="value"
									outlined
									placeholder="선택해 주세요."
									class="w420 dvcFontBox"
									v-model="formData.penaltyPeriod"
									@input="changePenaltyPeriod"
									:disabled="!formData.goodsId"
								>
								</v-autocomplete>
							</div>
						</div>
						<table
							class="chargeSummaryTable borderContColor2 tableThreeCol mt5"
						>
							<tr>
								<th class="blackCell">납부구분</th>
								<th class="blackCell">공시지원금</th>
								<th class="blackCell">선택약정</th>
							</tr>
							<tr>
								<th class="text-left">위약금</th>
								<td class="newTdAmt">
									{{
										formatWon(
											Math.round(penaltyData.PUBNOTI_SUPAMT.penaltyAmt),
										)
									}}<b> 원</b>
								</td>
								<td class="newTdAmt">
									{{ formatWon(Math.round(penaltyData.CHOICE_AGRMN.penaltyAmt))
									}}<b> 원</b>
								</td>
							</tr>
							<tr>
								<th class="text-left">잔여할부금</th>
								<td class="newTdAmt">
									{{
										formatWon(
											Math.round(penaltyData.PUBNOTI_SUPAMT.leftInstlAmt),
										)
									}}<b> 원</b>
								</td>

								<td class="newTdAmt">
									{{
										formatWon(
											Math.round(penaltyData.CHOICE_AGRMN.leftInstlAmt),
										)
									}}<b> 원</b>
								</td>
							</tr>
							<tr>
								<th class="text-left">해지총액</th>
								<td class="newTdAmt">
									{{
										formatWon(
											Math.round(
												penaltyData.PUBNOTI_SUPAMT.penaltyAmt +
													penaltyData.PUBNOTI_SUPAMT.leftInstlAmt,
											),
										)
									}}<b> 원</b>
								</td>
								<td class="newTdAmt">
									{{
										formatWon(
											Math.round(
												penaltyData.CHOICE_AGRMN.penaltyAmt +
													penaltyData.CHOICE_AGRMN.leftInstlAmt,
											),
										)
									}}<b> 원</b>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="mt5 chargeBottomSummary">
					<p v-if="cal_monthlyTotal(1) !== 0 && cal_monthlyTotal(2) !== 0">
						<span class="fw600 font-size-12 mt10">
							[공시지원금]<span class="redfont">
								{{ formatWon(cal_monthlyTotal(1)) }} </span
							>원
						</span>

						<span class="fw600" style="font-size: 10px">
							[선택약정]<span class="mainGray1">
								{{ formatWon(cal_monthlyTotal(2)) }} </span
							>원
						</span>
					</p>
					<p
						v-if="cal_monthlyTotal(1) !== 0 && cal_monthlyTotal(2) !== 0"
						class="pd0 ma-0 text-center fw600"
					>
						<span class="orangefont">{{
							cal_monthlyTotal(1) < cal_monthlyTotal(2)
								? '공시지원금'
								: '선택약정'
						}}</span>
						이 월
						<span class="redfont ml5">{{
							cal_monthlyTotal(1) > cal_monthlyTotal(2)
								? formatWon(cal_monthlyTotal(1) - cal_monthlyTotal(2))
								: formatWon(cal_monthlyTotal(2) - cal_monthlyTotal(1))
						}}</span>
						원 / 연
						<span class="redfont ml5">{{
							cal_monthlyTotal(1) > cal_monthlyTotal(2)
								? formatWon((cal_monthlyTotal(1) - cal_monthlyTotal(2)) * 12)
								: formatWon((cal_monthlyTotal(2) - cal_monthlyTotal(1)) * 12)
						}}</span
						>원 이익
					</p>
				</div>
			</section>
		</template>

		<template v-if="tabIndex === 1">
			<div>
				<section
					class="w100P disFx mb-1"
					style="border-bottom: 1px solid #007ef2"
				>
					<article class="disFx flexDColC align-center w50P">
						<div
							style="
								height: 120px;
								overflow: hidden;
								text-align: center;
								margin-bottom: 8px;
								margin-top: 6px;
							"
						>
							<img
								:id="`goodsPic_${componentIndex}_0`"
								:src="compareData[0].colorImgUrl"
								alt=""
								style="object-fit: cover; width: 90px"
							/>
						</div>
						<div class="datePickerStyle1 w110 mt1">
							<v-autocomplete
								:items="hopeDeviceList"
								style="font-size: 12px"
								item-text="goodsName"
								item-value="goodsId"
								outlined
								class="w420 dvcFontBox"
								placeholder="선택해주세요."
								v-model="compareData[0].goodsId"
								@input="getGoodsSpecToCompare('0')"
							>
							</v-autocomplete>
						</div>
						<div class="mt-2" style="min-height: 32px">
							<div
								class="padW5d disFx"
								:class="`colorBall_${componentIndex}_0`"
								v-if="
									compareData[0].goodsSpec.goodsColorList &&
									compareData[0].goodsSpec.goodsColorList.length > 0
								"
							>
								<span
									class="colorBall mr5"
									@click="changeDeviceColor('0', item, $event.target)"
									v-for="(item, index) in compareData[0].goodsSpec
										.goodsColorList"
									:key="index"
									:style="`background:${item.colorCode}`"
								>
								</span>
							</div>
							<p
								class="text-center font-size-10 ma-0"
								style="line-height: 20px !important"
							>
								{{ compareData[0].selectedColor }}
							</p>
						</div>
					</article>
					<article class="disFx flexDColC align-center w50P">
						<div
							style="
								height: 120px;
								overflow: hidden;
								text-align: center;
								margin-bottom: 8px;
								margin-top: 6px;
							"
						>
							<img
								:id="`goodsPic_${componentIndex}_1`"
								:src="compareData[1].colorImgUrl"
								alt=""
								style="object-fit: cover; width: 90px"
							/>
						</div>
						<div class="datePickerStyle1 w110 mt1">
							<v-autocomplete
								:items="hopeDeviceList"
								style="font-size: 12px"
								item-text="goodsName"
								item-value="goodsId"
								outlined
								class="w420 dvcFontBox"
								placeholder="선택해주세요."
								v-model="compareData[1].goodsId"
								@input="getGoodsSpecToCompare('1')"
							>
							</v-autocomplete>
						</div>
						<div class="mt-2" style="min-height: 32px">
							<div
								class="padW5d disFx"
								:class="`colorBall_${componentIndex}_1`"
								v-if="
									compareData[1].goodsSpec.goodsColorList &&
									compareData[1].goodsSpec.goodsColorList.length > 0
								"
							>
								<span
									class="colorBall mr5"
									@click="changeDeviceColor('1', item, $event.target)"
									v-for="(item, index) in compareData[1].goodsSpec
										.goodsColorList"
									:key="index"
									:style="`background:${item.colorCode}`"
								>
								</span>
							</div>
							<p
								class="text-center font-size-10 ma-0"
								style="line-height: 20px !important"
							>
								{{ compareData[1].selectedColor }}
							</p>
						</div>
					</article>
				</section>
				<section class="compareDeviceTable">
					<div class="mt5 mb-2" style="min-height: 60px">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">
							출고가
						</h4>
						<table>
							<tr>
								<td class="text-center w50P">
									{{
										formatWon(
											compareData[0].goodsSpec.goodsInfo.factoryPrice,
										) || '0'
									}}
									원 부터
								</td>
								<td class="text-center w50P">
									{{
										formatWon(
											compareData[1].goodsSpec.goodsInfo.factoryPrice,
										) || '0'
									}}
									원 부터
								</td>
							</tr>
						</table>
					</div>
					<div class="mt5 mb-2" style="min-height: 60px">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">
							출시일
						</h4>
						<table>
							<tr>
								<td class="text-center">
									{{ compareData[0].goodsSpec.goodsInfo.releaseDate || '' }}
								</td>
								<td class="text-center">
									{{ compareData[1].goodsSpec.goodsInfo.releaseDate || '' }}
								</td>
							</tr>
						</table>
					</div>
					<div class="mt5 mb-2">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">
							CPU / RAM
						</h4>
						<table>
							<tr>
								<td>
									{{ compareData[0].goodsSpec.goodsInfo.cpu || '' }}<br />
									{{ compareData[0].goodsSpec.goodsInfo.ramInfo || '' }}
								</td>
								<td>
									{{ compareData[1].goodsSpec.goodsInfo.cpu || '' }}<br />
									{{ compareData[1].goodsSpec.goodsInfo.ramInfo || '' }}
								</td>
							</tr>
						</table>
					</div>
					<div class="mt5 mb-2" style="min-height: 60px">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">
							저장용량
						</h4>
						<table>
							<tr>
								<td>
									{{ compareData[0].goodsSpec.goodsInfo.memoryInfo || '' }}
								</td>
								<td>
									{{ compareData[1].goodsSpec.goodsInfo.memoryInfo || '' }}
								</td>
							</tr>
						</table>
					</div>
					<div class="mt5" style="min-height: 60px">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">화면</h4>
						<table>
							<tr>
								<td>
									{{ compareData[0].goodsSpec.goodsInfo.displayInfo || '' }}
								</td>
								<td>
									{{ compareData[1].goodsSpec.goodsInfo.displayInfo || '' }}
								</td>
							</tr>
						</table>
					</div>
					<div class="mt5" style="min-height: 60px">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">
							카메라
						</h4>
						<table>
							<tr>
								<td>
									{{ compareData[0].goodsSpec.goodsInfo.frontCamInfo || ''
									}}<br />
									{{ compareData[0].goodsSpec.goodsInfo.rearCamInfo || '' }}
								</td>
								<td>
									{{ compareData[1].goodsSpec.goodsInfo.frontCamInfo || ''
									}}<br />
									{{ compareData[1].goodsSpec.goodsInfo.rearCamInfo || '' }}
								</td>
							</tr>
						</table>
					</div>
					<div class="mt5" style="min-height: 60px">
						<h4 class="mb5 lh26" style="border-bottom: 1px solid #000">기타</h4>
						<table>
							<tr>
								<td>
									{{ compareData[0].goodsSpec.goodsInfo.etcInfo || '' }}
								</td>
								<td>
									{{ compareData[1].goodsSpec.goodsInfo.etcInfo || '' }}
								</td>
							</tr>
						</table>
					</div>
				</section>
			</div>
		</template>
		<button
			v-if="!consultDisableType"
			class="btnStyle1 btnShadow1 backColorBlue2 w60 mt5 posA font-size-13"
			style="left: 6px; bottom: 8px"
			@click="applyGoods"
			:disabled="formData.goodsId === 0"
		>
			적용
		</button>
		<button
			class="btnStyle1 btnShadow1 backColorBlue3 w110 mt5 posA font-size-13"
			style="right: 6px; bottom: 8px"
			@click="sendSummaryToMemoInput()"
		>
			상담메모 전송
		</button>
	</div>
</template>

<script>
import bus from '../../../common/bus';

export default {
	name: 'CalculatorSummary',
	props: {
		noGoodsAlertMsg: { type: String },
		consultDisableType: { type: Boolean },
		componentIndex: { type: String },
		telecomId: {
			type: Number,
		},
		hopeGoodsId: {
			type: Number,
		},
		hopeDeviceList: { type: Array, default: () => [] },
		hopeChargeSelectList: { type: Array, default: () => [] },
		// installmentsPeriodType: { type: Array, default: () => [] },
		ChargeReductType: { type: Array, default: () => [] },
	},
	data: () => ({
		selectedGoods: {},
		tabDialog: false,
		tabIndex: 0,
		interest: 0.059,
		monthlyInterest: 0.0049167,
		reduction: {
			reduction_DSBL: 0.35,
			reduction_NTNL: 0.35,
			reduction_BASE_RCP: 28600,
			reduction_PENSION_RCP: 12100,
			reduction_HIGHT: 23650,
		},
		codeList: {},
		penaltyTypeList: [
			{ name: '3개월 유지', value: 3 },
			{ name: '6개월 유지', value: 6 },
			{ name: '12개월 유지', value: 12 },
			{ name: '18개월 유지', value: 18 },
			{ name: '24개월 유지', value: 24 },
			// { name: '30개월 유지', value: 30 },
			// { name: '36개월 유지', value: 36 },
			// { name: '48개월 유지', value: 48 },
		],
		installmentsPeriodType: [
			{ name: '현금개통', value: 'CASH' },
			{ name: '6개월', value: 'MONTH_6' },
			{ name: '12개월', value: 'MONTH_12' },
			{ name: '24개월', value: 'MONTH_24' },
			{ name: '30개월', value: 'MONTH_30' },
			{ name: '36개월', value: 'MONTH_36' },
			{ name: '48개월', value: 'MONTH_48' },
		],
		hopeChargeSelectList1: [],
		formData: {
			colorImgUrl:
				'https://www.sklmno.com/goods/upload/no_image_m.png',
			selectedColor: '',
			goodsId: 0,
			goodsName: '',
			chargeId: 0,
			chargeName: '',
			network: 0,
			instlPeriodType: 'MONTH_36',
			chargeReductType: 'NONE',
			penaltyPeriod: '',
			amtData: {
				extraSupportAmt: 0,
				supportAmt: 0, //공시지원
				releaseAmt: 0, //출고가
				chargeAmt: 0, //요금제
				terminationAmt: 0,
				monthBasicAmt: 0,
			},
			goodsSpec: {
				goodsColorList: [],
				goodsInfo: {
					factoryPrice: 0,
					releaseDate: '',
					accessory: '',
					battery: '',
					color: '',
					cpu: '',
					deviceDetailInfo: '',
					displayInfo: '',
					etcInfo: '',
					frontCamInfo: '',
					memoryInfo: '',
					ramInfo: '',
					rearCamInfo: '',
					usimName: '',
					weight: 0,
				},
			},
		},
		// 위약금 : PUBNOTI_SUPAMT(공시지원금) , CHOICE_AGRMN(선택약정)
		penaltyData: {
			PUBNOTI_SUPAMT: {
				penaltyAmt: 0,
				leftInstlAmt: 0,
			},
			CHOICE_AGRMN: {
				penaltyAmt: 0,
				leftInstlAmt: 0,
			},
		},
		compareData: [
			{
				goodsId: 0,
				selectedColor: '',
				colorImgUrl:
					'https://www.sklmno.com/goods/upload/no_image_m.png',
				goodsSpec: {
					goodsColorList: [],
					goodsInfo: {
						factoryPrice: 0,
						releaseDate: '',
						accessory: '',
						battery: '',
						color: '',
						cpu: '',
						deviceDetailInfo: '',
						displayInfo: '',
						etcInfo: '',
						frontCamInfo: '',
						memoryInfo: '',
						ramInfo: '',
						rearCamInfo: '',
						usimName: '',
						weight: 0,
					},
				},
			},
			{
				goodsId: 0,
				selectedColor: '',
				colorImgUrl:
					'https://www.sklmno.com/goods/upload/no_image_m.png',
				goodsSpec: {
					goodsColorList: [],
					goodsInfo: {
						factoryPrice: 0,
						releaseDate: '',
						accessory: '',
						battery: '',
						color: '',
						cpu: '',
						deviceDetailInfo: '',
						displayInfo: '',
						etcInfo: '',
						frontCamInfo: '',
						memoryInfo: '',
						ramInfo: '',
						rearCamInfo: '',
						usimName: '',
						weight: 0,
					},
				},
			},
		],
	}),
	computed: {
		instlPeriod() {
			if (this.formData.instlPeriodType === 'CASH') {
				return 1;
			} else if (this.formData.instlPeriodType === 'MONTH_6') {
				return 6;
			} else if (this.formData.instlPeriodType === 'MONTH_12') {
				return 12;
			} else if (this.formData.instlPeriodType === 'MONTH_24') {
				return 24;
			} else if (this.formData.instlPeriodType === 'MONTH_30') {
				return 30;
			} else if (this.formData.instlPeriodType === 'MONTH_36') {
				return 36;
			} else if (this.formData.instlPeriodType === 'MONTH_48') {
				return 48;
			} else {
				return 1;
			}
		},
		calculatorActive1: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActive1;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActive1 = newValue;
			},
		},
		calculatorActive2: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActive2;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActive2 = newValue;
			},
		},
		calculatorActive3: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActive3;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActive3 = newValue;
			},
		},
		calculatorActiveCnt: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActiveCnt;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActiveCnt = newValue;
			},
		},
	},
	methods: {
		calculatorCntFnc(action) {
			const dialog = document.querySelector('.v-dialog.v-dialog--active');
			if (action === 'plus') {
				if (this.calculatorActiveCnt < 3) {
					this.calculatorActiveCnt += 1;
					this[`calculatorActive${this.calculatorActiveCnt}`] = true;
					if (this.calculatorActiveCnt === 2) {
						dialog.classList.add('calculator_2');
					}
					if (this.calculatorActiveCnt === 3) {
						dialog.classList.add('calculator_3');
					}
				}
			} else if (action === 'minus') {
				if (this.calculatorActiveCnt > 1) {
					this.calculatorActiveCnt -= 1;
					this[`calculatorActive${this.componentIndex}`] = false;
					if (this.calculatorActiveCnt === 2) {
						dialog.classList.remove('calculator_3');
					}
					if (this.calculatorActiveCnt === 1) {
						dialog.classList.remove('calculator_2');
					}
				}
				this.resetAllData();
			}
		},
		resetAllData() {},
		formatWon(data) {
			if (data !== null && data !== undefined) {
				return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
		},
		applyGoods() {
			if (this.formData.goodsId) {
				this.$emit('applyGoods', this.formData.goodsId);
			}
		},
		checkWhetherALessThanB(percentage) {
			if (
				this.formData.amtData.supportAmt * percentage <
				parseInt(this.formData.amtData.extraSupportAmt)
			) {
				alert('공시지원금의 15%까지 입력 가능합니다.');
				this.formData.amtData.extraSupportAmt = 0;
				return;
			}
			if (this.formData.penaltyPeriod !== '') {
				this.changePenaltyPeriod();
			}
		},
		initGoodsData(data) {
			data.colorImgUrl =
				'https://www.sklmno.com/goods/upload/no_image_m.png';
			data.selectedColor = '';
			data.goodsId = 0;
			data.goodsSpec = {
				goodsColorList: [],
				goodsInfo: {},
			};
		},
		changePenaltyPeriod() {
			if (this.formData.penaltyPeriod === '') {
				this.initPenaltyData();
				return;
			}
			const agreementPeriod = 24;
			const leftMonth = agreementPeriod - this.formData.penaltyPeriod;
			// 잔여할부금
			if (
				this.instlPeriod === 1 ||
				this.instlPeriod < this.formData.penaltyPeriod
			) {
				this.penaltyData.PUBNOTI_SUPAMT.leftInstlAmt = 0;
				this.penaltyData.CHOICE_AGRMN.leftInstlAmt = 0;
			} else {
				this.penaltyData.PUBNOTI_SUPAMT.leftInstlAmt = Math.round(
					this.cal_instlPeriod() -
						(this.cal_instlPeriod() / this.instlPeriod) *
							this.formData.penaltyPeriod,
				);
				this.penaltyData.CHOICE_AGRMN.leftInstlAmt = Math.round(
					this.formData.amtData.releaseAmt -
						(this.formData.amtData.releaseAmt / this.instlPeriod) *
							this.formData.penaltyPeriod,
				);
			}
			//위약금
			if (this.formData.penaltyPeriod <= 6) {
				this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt =
					this.formData.amtData.supportAmt +
					parseInt(this.formData.amtData.extraSupportAmt);
				this.penaltyData.CHOICE_AGRMN.penaltyAmt =
					(this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt) /
						24) *
					this.formData.penaltyPeriod;
			}
			if (
				this.formData.penaltyPeriod === 12 ||
				this.formData.penaltyPeriod === 18
			) {
				this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt =
					(this.formData.amtData.supportAmt +
						parseInt(this.formData.amtData.extraSupportAmt)) *
					((leftMonth * 30) / 550);

				// 모비셀은 아래 공식 적용
				// this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt =
				// 	(this.formData.amtData.supportAmt +
				// 		parseInt(this.formData.amtData.extraSupportAmt)) *
				// 	(leftMonth / 18);

				this.penaltyData.CHOICE_AGRMN.penaltyAmt =
					(this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt) /
						24) *
					this.formData.penaltyPeriod *
					(leftMonth / 18);
			}

			if (this.formData.penaltyPeriod === 24) {
				this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt = 0;
				this.penaltyData.CHOICE_AGRMN.penaltyAmt = 0;
			}
			/*

						let leftMonth = this.instlPeriod - this.formData.penaltyPeriod;
						this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt =
							this.formData.amtData.supportAmt +
							parseInt(this.formData.amtData.extraSupportAmt);
						if (this.formData.penaltyPeriod > 6) {
							this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt =
								this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt *
								(leftMonth / (this.instlPeriod - 6));
						}
						if (this.instlPeriod > 24) {
							this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt =
								this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt *
								((24 - this.formData.penaltyPeriod) / 18);
						}
						if (this.formData.penaltyPeriod === 3) {
							this.penaltyData.CHOICE_AGRMN.penaltyAmt = this.cal_agreementDiscount(
								this.formData.amtData.monthBasicAmt,
							);
						} else if (
							this.formData.penaltyPeriod === 6 ||
							this.formData.penaltyPeriod === 12
						) {
							this.penaltyData.CHOICE_AGRMN.penaltyAmt =
								(this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt) /
									this.instlPeriod) *
								this.formData.penaltyPeriod *
								(leftMonth / (this.instlPeriod - 3));
						} else if (this.formData.penaltyPeriod === 18) {
							this.penaltyData.CHOICE_AGRMN.penaltyAmt =
								(this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt) /
									this.instlPeriod) *
								this.formData.penaltyPeriod *
								(leftMonth / (this.instlPeriod - 6));
						} else if (this.formData.penaltyPeriod === 24) {
							this.penaltyData.CHOICE_AGRMN.penaltyAmt = 0;
						}*/
		},
		changeDeviceColor(compare_idx, item, target) {
			if (compare_idx !== '2') {
				this.compareData[compare_idx].selectedColor = '';
				this.compareData[compare_idx].selectedColor = item.colorName;
				this.compareData[compare_idx].colorImgUrl = item.colorImgUrl;
			} else {
				this.formData.selectedColor = '';
				this.formData.selectedColor = item.colorName;
				this.formData.colorImgUrl = item.colorImgUrl;
			}
			let colorBallList = document.querySelector(
				`.colorBall_${this.componentIndex}_${compare_idx}`,
			);
			for (let i = 1; i < colorBallList.childElementCount; i++) {
				if (colorBallList.children[i].classList.contains('borderContColor0')) {
					colorBallList.children[i].classList.remove('borderContColor0');
				}
			}
			target.classList.add('borderContColor0');
		},
		checkGoodsId(e) {
			if (this.formData.goodsId === 0) {
				alert('기기를 선택해주세요.');
			}
		},
		checkTelecomId() {
			if (this.hopeDeviceList.length === 0) {
				alert(this.noGoodsAlertMsg);
			}
		},
		async changeCharge(item) {
			this.formData.chargeId = item.chargeId;
			this.formData.chargeName = item.chargeName;
			this.formData.amtData.extraSupportAmt = 0;
			await this.getPubNotiInfo();
			if (this.formData.penaltyPeriod !== '') {
				this.changePenaltyPeriod();
			}
		},
		async changeGoodsData(value) {
			this.formData.amtData.extraSupportAmt = 0;
			this.initGoodsData(this.formData);
			await this.getGoodsData(value);
		},
		filterChargeSelectList() {
			this.formData.chargeId = 0;
			this.formData.chargeName = '';
			if (this.formData.network !== 0) {
				this.hopeChargeSelectList1 = this.hopeChargeSelectList.filter(
					obj => obj.network === this.formData.network,
				);
			} else {
				this.hopeChargeSelectList1 = JSON.stringify(this.hopeChargeSelectList);
			}
		},
		async getGoodsData(value) {
			this.formData.goodsId = value.goodsId;
			this.formData.goodsName = value.goodsName;
			this.formData.network = value.network;
			if (this.formData.goodsId > 0) {
				this.filterChargeSelectList();
				let param = { goodsId: this.formData.goodsId };
				let response = await this.$store.dispatch(
					'ChargeCalculatorModule/retrieveGoodsSpecInfo',
					param,
				);
				this.formData.goodsSpec.goodsColorList = [];
				this.formData.amtData.releaseAmt = 0;
				if (response) {
					if (this.formData.amtData.releaseAmt === 0) {
						this.formData.amtData.releaseAmt = parseInt(
							response.goodsInfo.factoryPrice,
						);
					}
					for (let i = 0; i < response.goodsColorList.length; i++) {
						let idx = this.formData.goodsSpec.goodsColorList.findIndex(obj => {
							return obj.colorName === response.goodsColorList[i].colorName;
						});
						if (
							this.formData.goodsSpec.goodsColorList.length === 0 ||
							(this.formData.goodsSpec.goodsColorList.length > 0 && idx === -1)
						) {
							this.formData.goodsSpec.goodsColorList.push(
								response.goodsColorList[i],
							);
						}
					}
					this.formData.goodsSpec.goodsInfo = response.goodsInfo;
					this.formData.colorImgUrl =
						this.formData.goodsSpec.goodsColorList[0].colorImgUrl;
					this.formData.selectedColor =
						this.formData.goodsSpec.goodsColorList[0].colorName;
				}
			}
			if (this.formData.chargeId !== 0 && this.formData.chargeName !== '') {
				await this.getPubNotiInfo();
			}
		},
		async getPubNotiInfo() {
			let param = {
				chargeId: this.formData.chargeId,
				goodsId: this.formData.goodsId,
			};
			let response = await this.$store.dispatch(
				'ChargeCalculatorModule/getPubNotiInfo',
				param,
			);
			if (response) {
				this.formData.amtData.supportAmt = response.supportAmt || 0;
				this.formData.amtData.releaseAmt = response.releaseAmt || 0;
				this.formData.amtData.monthBasicAmt = response.chargeAmt || 0;
			} else {
				this.formData.amtData.supportAmt = 0;
				this.formData.amtData.releaseAmt = 0;
				this.formData.amtData.monthBasicAmt = 0;
			}
		},
		// 약정할인
		cal_agreementDiscount(monthBasicAmt) {
			// 24개월 선택약정으로 고정
			return Math.round(monthBasicAmt * 24 * 0.25);
			/*			if (this.instlPeriod !== 1) {
				if (this.instlPeriod > 24) {
					return Math.round(monthBasicAmt * 24 * 0.25);
				} else {
					return Math.round(monthBasicAmt * this.instlPeriod * 0.25);
				}
			}
			return 0;*/
		},
		// 요금감면
		cal_chargeReduct(monthBasicAmt) {
			let amt = this.formData.amtData.monthBasicAmt || 0;
			if (amt !== 0 && this.formData.chargeReductType !== 'NONE') {
				switch (this.formData.chargeReductType) {
					case 'DSBL':
						amt = amt * this.reduction.reduction_DSBL;
						break;
					case 'NTNL':
						amt = amt * this.reduction.reduction_NTNL;
						break;
					case 'BASE_RCP':
						// 기초생활수급자(생계/의로) 기준 적용
						amt = amt - this.reduction.reduction_BASE_RCP;
						break;
					case 'PENSION_RCP':
						amt = amt - this.reduction.reduction_PENSION_RCP;
						break;
					case 'HIGHT':
						amt = amt - this.reduction.reduction_HIGHT;
						break;
				}
				return Math.round(amt);
			} else {
				return 0;
			}
		},
		// 할부원금
		cal_instlPeriod() {
			return (
				this.formData.amtData.releaseAmt -
				this.formData.amtData.supportAmt -
				parseInt(this.formData.amtData.extraSupportAmt)
			);
		},
		// 할부이자
		cal_monthlyInterest(type) {
			// type 1 : 공시지원금 / type 2 : 공시지원금
			let amt = 0;
			if (this.formData.amtData.releaseAmt > 0 && this.instlPeriod !== 1) {
				if (type === 1) {
					// this.formData.amtData.releaseAmt - [this.cal_instlPeriod() * 0.004917];
					amt =
						(this.cal_instlPeriod() *
							this.monthlyInterest *
							Math.pow(1 + this.monthlyInterest, this.instlPeriod)) /
						(Math.pow(1 + this.monthlyInterest, this.instlPeriod) - 1);
					return Math.round(amt * this.instlPeriod - this.cal_instlPeriod());
				} else if (type === 2) {
					amt =
						this.formData.amtData.releaseAmt *
						this.monthlyInterest *
						(Math.pow(1 + this.monthlyInterest, this.instlPeriod) /
							(Math.pow(1 + this.monthlyInterest, this.instlPeriod) - 1));
					return Math.round(
						amt * this.instlPeriod - this.formData.amtData.releaseAmt,
					);
				}
			}
			return Math.round(amt);
		},
		// 월 할부금 + 이자
		cal_instlPeriodPlusInterest(instlPeriodAmt, type) {
			if (this.formData.instlPeriodType === 'CASH') {
				return 0;
			} else {
				if (instlPeriodAmt > 0) {
					return Math.round(
						Number(
							Number(instlPeriodAmt) + Number(this.cal_monthlyInterest(type)),
						) / this.instlPeriod,
					);
				}
				return 0;
			}
		},
		// 월 통신료
		cal_monthlyCharge(type) {
			let amt = this.formData.amtData.monthBasicAmt - this.cal_chargeReduct();
			if (type === 2) {
				let period = 24;
				// this.instlPeriod > 24 ? (period = 24) : (period = this.instlPeriod);
				amt =
					amt -
					this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt) /
						period;
			}
			return Math.round(amt);
		},
		// 월총납부금
		cal_monthlyTotal(type) {
			let amt = this.cal_monthlyCharge(type);
			if (type === 1) {
				amt = amt + this.cal_instlPeriodPlusInterest(this.cal_instlPeriod(), 1);
			} else if (type === 2) {
				amt =
					amt +
					this.cal_instlPeriodPlusInterest(this.formData.amtData.releaseAmt, 2);
			}
			return Math.round(amt / 10) * 10;
		},
		async getGoodsSpecToCompare(compare_idx) {
			if (this.compareData[compare_idx].goodsId > 0) {
				let param = { goodsId: this.compareData[compare_idx].goodsId };
				let response = await this.$store.dispatch(
					'ChargeCalculatorModule/retrieveGoodsSpecInfo',
					param,
				);
				if (response) {
					this.compareData[compare_idx].goodsSpec.goodsColorList = [];
					for (let i = 0; i < response.goodsColorList.length; i++) {
						let idx = this.compareData[
							compare_idx
						].goodsSpec.goodsColorList.findIndex(obj => {
							return obj.colorName === response.goodsColorList[i].colorName;
						});
						if (
							this.compareData[compare_idx].goodsSpec.goodsColorList.length ===
								0 ||
							(this.compareData[compare_idx].goodsSpec.goodsColorList.length >
								0 &&
								idx === -1)
						) {
							this.compareData[compare_idx].goodsSpec.goodsColorList.push(
								response.goodsColorList[i],
							);
						}
					}
					this.compareData[compare_idx].goodsSpec.goodsInfo =
						response.goodsInfo;
					if (
						this.compareData[compare_idx].goodsSpec.goodsColorList.length > 0
					) {
						this.compareData[compare_idx].colorImgUrl =
							this.compareData[
								compare_idx
							].goodsSpec.goodsColorList[0].colorImgUrl;
						this.compareData[compare_idx].selectedColor =
							this.compareData[
								compare_idx
							].goodsSpec.goodsColorList[0].colorName;
					}
				} else {
					this.noGoodsData(this.compareData[compare_idx]);
				}
			}
		},
		noGoodsData(data) {
			data.colorImgUrl =
				'https://www.sklmno.com/goods/upload/no_image_m.png';
			data.selectedColor = '';
			data.goodsSpec = {
				goodsColorList: [],
				goodsInfo: {},
			};
		},
		sendSummaryToMemoInput() {
			if (this.formData.goodsName === '') {
				alert('기기를 선택해주세요.');
				return;
			}
			if (this.formData.chargeName === '') {
				alert('요금제를 선택해주세요.');
				return;
			}
			if (this.formData.instlPeriodType === '') {
				alert('할부개월을 선택해주세요.');
				return;
			}
			if (this.formData.chargeReductType === '') {
				alert('요금감면을 선택해주세요.');
				return;
			}
			let telecom = '';
			switch (this.telecomId) {
				case 5:
					telecom = 'SK';
					break;
				case 6:
					telecom = 'KT';
					break;
				case 7:
					telecom = 'LG';
					break;
			}
			bus.$emit(
				'syncMemo',
				`[요금계산 간편요약]
통신사 : ${telecom}
기기명 : ${this.formData.goodsName}
요금제 :  ${this.formData.chargeName}
할부/약정개월 : ${
					this.instlPeriod === 1 ? '현금개통' : this.instlPeriod + ' 개월'
				}

출고가 : ${this.formatWon(this.formData.amtData.releaseAmt)} 원

## 공시지원

공시지원 : - ${this.formatWon(this.formData.amtData.supportAmt)} 원
추가지원금  : ${this.formatWon(
					this.formData.amtData.extraSupportAmt,
				)} 원(이자포함)

할부원금 : ${
					this.cal_instlPeriod() ? this.formatWon(this.cal_instlPeriod()) : '0'
				} 원(이자포함)

■ 월 할부금 : ${this.formatWon(
					this.cal_instlPeriodPlusInterest(this.cal_instlPeriod(), 1),
				)} 원
■ 월 통신료 : ${this.formatWon(this.cal_monthlyCharge(1))} 원
-------------------------------------
■ 월 총 납부금 : ${this.formatWon(this.cal_monthlyTotal(1))} 원(VAT포함)
-------------------------------------

## 선택약정

선택약정 : -${
					this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt)
						? this.formatWon(
								this.cal_agreementDiscount(this.formData.amtData.monthBasicAmt),
						  )
						: '0'
				} 원

할부원금 : ${this.formatWon(this.formData.amtData.releaseAmt)} 원(이자포함)

■ 월 할부금 : ${
					this.cal_instlPeriodPlusInterest(this.formData.amtData.releaseAmt, 2)
						? this.formatWon(
								this.cal_instlPeriodPlusInterest(
									this.formData.amtData.releaseAmt,
									2,
								),
						  )
						: '0'
				} 원
■ 월 통신료 : ${this.formatWon(this.cal_monthlyCharge(2))} 원
-------------------------------------
■ 월 총 납부금 : ${this.formatWon(this.cal_monthlyTotal(2))} 원(VAT포함)
-------------------------------------`,
			);
		},
		async tabFnc(index) {
			if (index === 0) {
				this.tabDialog = false;
			}
			if (index === 1) {
				this.tabDialog = true;
				// if (this.formData.goodsId && this.compareData[0].goodsId === 0) {
				if (
					this.formData.goodsId &&
					this.formData.goodsId !== this.compareData[0].goodsId
				) {
					this.compareData[0].goodsId = this.formData.goodsId;
					await this.getGoodsSpecToCompare('0');
				}
			}
			this.tabIndex = index;
		},
		async getTelecomChargeList(data) {
			await this.$store.dispatch(
				'CustomerConsultModule/getTelecomChargeList',
				data,
			);
		},
		initAmtData() {
			this.formData.amtData.chargeAmt = 0;
			this.formData.amtData.extraSupportAmt = 0;
			this.formData.amtData.monthBasicAmt = 0;
			this.formData.amtData.releaseAmt = 0;
			this.formData.amtData.supportAmt = 0;
			this.formData.amtData.terminationAmt = 0;
		},
		initPenaltyData() {
			this.penaltyData.PUBNOTI_SUPAMT.penaltyAmt = 0;
			this.penaltyData.PUBNOTI_SUPAMT.leftInstlAmt = 0;
			this.penaltyData.CHOICE_AGRMN.penaltyAmt = 0;
			this.penaltyData.CHOICE_AGRMN.leftInstlAmt = 0;
		},
		calculatorRefreshFnc() {
			this.initGoodsData(this.formData);
			this.selectedGoods = {};
			this.initAmtData();
			this.initPenaltyData();
			this.formData.goodsName = '';
			this.formData.chargeId = 0;
			this.formData.chargeName = '';
			this.formData.network = 0;
			this.formData.instlPeriodType = 'MONTH_36';
			this.formData.chargeReductType = 'NONE';
			this.formData.penaltyPeriod = '';
			this.initGoodsData(this.compareData[0]);
			this.initGoodsData(this.compareData[1]);
			this.tabFnc(0);
			this.hopeChargeSelectList1 = JSON.parse(
				JSON.stringify(this.hopeChargeSelectList),
			);
			if (this.hopeGoodsId && this.hopeDeviceList.length > 0) {
				this.formData.goodsId = this.hopeGoodsId;
				if (this.hopeGoodsId !== 0 && this.hopeGoodsId !== null) {
					this.selectedGoods =
						this.hopeDeviceList[
							this.hopeDeviceList.findIndex(
								obj => obj.goodsId === this.hopeGoodsId,
							)
						];
					this.getGoodsData(this.selectedGoods);
				}
			}
		},
		calculatorOpenFnc() {
			// calculator 1개만 활성화 되도록
			if (this.calculatorActiveCnt !== 1) {
				this.calculatorActiveCnt = 1;
				this.calculatorActive2 = false;
				this.calculatorActive3 = false;
				const dialog = document.querySelector('.v-dialog.v-dialog--active');
				dialog.classList.remove('calculator_2');
				dialog.classList.remove('calculator_3');
			}
			this.calculatorRefreshFnc();
		},
	},
	created() {
		bus.$on('chargeSummaryOpen', this.calculatorOpenFnc);
		bus.$on('chargeSummaryRefresh', this.calculatorRefreshFnc);
	},
	beforeDestroy() {
		bus.$off('start:chargeSummaryOpen', this.calculatorOpenFnc);
		bus.$off('start:chargeSummaryRefresh', this.calculatorRefreshFnc);
	},
};
</script>

<style scoped>
.calculatorSummary {
	width: 244px;
	height: 890px;
	padding: 6px 8px 12px 9px;
	background: #fff;
	border: 1px solid #eee;
	border-right: 0;
}
.calculatorSummary b {
	font-weight: 700 !important;
}
.calculatorSummary section {
	width: 100%;
}
.calculatorSummary .radioStyle label:before,
.calculatorSummary .radioStyle input[type='radio']:checked + label:before {
	top: 0 !important;
	left: -21px !important;
	width: 10px;
	height: 10px;
}
.calculatorSummary h3,
.calculatorSummary h5 {
	font-size: 12px;
}
.calculatorSummary table.chargeSummaryTable,
.calculatorSummary table.chargeSummaryTable tr {
	width: 100%;
}
.calculatorSummary table.chargeSummaryTable th,
.calculatorSummary table.chargeSummaryTable td,
.calculatorSummary table.chargeSummaryTable span {
	font-size: 11px !important;
	padding: 0;
	font-weight: 400;
}
.calculatorSummary table.chargeSummaryTable b {
	font-size: 11px !important;
	font-weight: 600;
}
table.chargeSummaryTable select,
.calculatorSummary select {
	height: 24px;
	width: 100%;
	border: 1px solid #e0e0e0;
	border-radius: 3px;
	padding: 2px 10px;
	font-size: 11px;
}
.tableTwoCol tr,
.tableTwoCol th,
.tableTwoCol td {
	padding: 1px 0 !important;
}
.tableTwoCol th {
	text-align: left;
	width: 30%;
}
.tableTwoCol td {
}
.tableThreeCol th,
.tableThreeCol td {
	width: 33%;
}
.chargeSummaryTable.borderContColor2 tr,
.chargeSummaryTable.borderContColor2 td,
.chargeSummaryTable.borderContColor2 th {
	border: 1px solid #e0e0e0;
	padding: 3px 2px !important;
}
.newTdAmt {
	text-align: right;
}
.colorBall {
	display: flex;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	cursor: pointer;
}
.chargeBottomSummary {
	background: #eee;
	padding: 8px 0;
	min-height: 54px;
}
.chargeBottomSummary p {
	padding: 0;
	margin: 0;
	text-align: center;
	line-height: 18px !important;
}
.chargeBottomSummary p:nth-child(2) {
	font-size: 10px;
}
.calculatorSummary .titleBtnStyleA,
.calculatorSummary .titleBtnStyleB {
	line-height: 30px !important;
}
.grayCell {
	background: #b0b6bc;
	color: #fff;
	font-weight: 400;
}
.blackCell {
	background: #000;
	color: #f2c768;
	font-weight: 400;
}
.compareDeviceTable table,
.compareDeviceTable table tr {
	width: 100%;
}
.compareDeviceTable table td {
	width: 48%;
	font-size: 12px;
	word-break: break-word;
	padding: 4px 0;
}
.compareDeviceTable h4 {
	font-size: 12px;
}
</style>
