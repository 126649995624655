<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="390">
			<v-card class="borderRadiReset infoPopup">
				<v-card-title
					class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
				>
					<h2 class="mainWhite mainFontS boldWt">공시요금코드</h2>
					<span class="closeBtn1 cur_p" @click="$emit('close')"></span>
				</v-card-title>

				<v-card-text class="pad30">
					<v-row class="">
						<v-col cols="9" class="flex-center"
							><v-text-field
								class="simple-input"
								outlined
								v-model.trim="searchText"
								@keydown.enter="onClickSearch()"
							></v-text-field
						></v-col>
						<v-col cols="3"
							><v-btn
								class="backColorMint1 mainWhite borderReset w80"
								text
								@click="onClickSearch"
								outlined
							>
								검색
							</v-btn></v-col
						>
					</v-row>

					<v-row>
						<ul class="result-box">
							<li>
								<div class="name">공시요금코드를 알 수 없을 때 선택 -></div>
								<div class="code" @click="onClickItem('요금코드')">
									요금코드
								</div>
							</li>

							<li v-for="(item, idx) in list" :key="idx">
								<div class="name" v-html="getHighlightedText(item)"></div>
								<div class="code" @click="onClickItem(item.chargeCode)">
									{{ item.chargeCode }}
								</div>
							</li>
							<li v-if="list.length === 0" class="no-result">
								<span>검색결과가 없습니다.</span>
							</li>
						</ul>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { searchPublicNoticeByRawChargeName } from '@/api/common-mgmt/charge-mgmt/ChargeMgmtApi';
export default {
	name: 'RawPubNotiListPop',
	props: ['telecomName', 'searchKeywords'],
	data: () => ({
		dialog: true,
		searchText: '',
		textSearched: '',
		list: [],
	}),
	computed: {},
	watch: {
		dialog(newVal) {
			if (newVal === false) {
				this.$emit('close');
			}
		},
	},
	created() {
		this.searchText = this.searchKeywords;
		this.onSearchPublicNoticeByName();
	},
	mounted() {
		this.dialog = true;
	},
	beforeDestroy() {},
	methods: {
		async onSearchPublicNoticeByName() {
			console.log('onSearchPublicNoticeByName', this.chargeNameSelection);

			const { data } = await searchPublicNoticeByRawChargeName({
				searchKeywords: this.searchText,
				telecomName: this.telecomName,
			});

			console.log(data.data);

			// searchText가 변경될 때 v-html에 등록된 getHighlightedText가 계속 실행되는 문제가 있음
			// vue2 버그로 생각됨. 코드 상으로는 reactive chain이 확인 되지 않고 있음.
			const searched = this.searchText.slice(0);
			this.textSearched = searched;
			this.list.splice(0);

			if (data.resultCode === '0000') {
				data.data.forEach((n) => {
					this.list.push(n);
				});
			}
		},
		onClickSearch() {
			this.onSearchPublicNoticeByName();
		},
		onClickItem(code) {
			this.$emit('code-select', code);
			this.$emit('close');
		},
		getHighlightedText(item) {
			return `[${item.telecomName}] ${this.highlight(
				item.chargeName,
				this.textSearched,
			)}`;
		},
		highlight(text, keywords) {
			const idx = text.indexOf(keywords);
			if (idx > -1) {
				const part1 = text.slice(0, idx);
				const part2 = text.slice(idx, idx + keywords.length);
				const part3 = text.slice(idx + keywords.length);

				console.log(part1, part2, part3);
				return `${part1}<span style="background-color:yellow;">${part2}</span>${part3}`;
			}
		},
	},
};
</script>

<style scoped>
.simple-input {
	min-height: 20px;
	height: 34px;
	width: 95%;
}

/deep/ .v-text-field--outlined > .v-input__control > .v-input__slot {
	min-height: 20px;
}

.result-box {
	margin-top: 8px;
	padding-right: 8px;

	width: 100%;
	min-height: 80px;
	max-height: 400px;
	overflow-y: auto;
}

.result-box > li {
	width: 100%;
	height: 48px;

	border-top: 1px solid #eeeeee;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.result-box > li:last-child {
	border-bottom: 1px solid #eeeeee;
}

.result-box .name {
}

.result-box .code {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 60px;

	cursor: pointer;
}

.result-box .code:hover {
	text-decoration: underline;
}

.result-box .no-result {
	justify-content: center;
}
</style>
