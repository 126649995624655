import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/SalesManagement/ConsultNoticeMgmt/';

// 공지사항 조회
const getConsultNoticeList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getConsultNoticeList${param}`);
	return response;
};

// 공지사항 팝업 조회
const getConsultNoticeTopList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getConsultNoticeTopList${param}`,
	);
	return response;
};

// 공지사항 cnt
const getConsultNoticeStatusCount = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getConsultNoticeStatusCount${param}`,
	);
	return response;
};

// 공지사항 파일 조회
const getConsultNoticeRegiMemberInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getConsultNoticeRegiMemberInfo${param}`,
	);
	return response;
};

// 판매정책 조회
const consultPolicyList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/SalesManagement/ConsultMgmt/consultPolicyList${param}`,
	);
	return response;
};

// 공지사항 파일 조회
const getConsultNoticeAttachList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getConsultNoticeAttachList${param}`,
	);
	return response;
};

// 공지사항 등록
const insertConsultNotice = async data => {
	const response = await instance.post(`${domain}insertConsultNotice`, data);
	return response;
};

// 판매정책 등록
const insertConsultPolicy = async data => {
	const response = await instance.post(
		`/SalesManagement/ConsultMgmt/insertConsultPolicy`,
		data,
	);
	return response;
};

// 공지사항 수정
const updateConsultNotice = async data => {
	const response = await instance.post(`${domain}updateConsultNotice`, data);
	return response;
};

// 공지사항 파일추가
const uploadConsultNoticeAttach = async data => {
	const response = await instance.post(
		`${domain}uploadConsultNoticeAttach`,
		data,
	);
	return response;
};

// 공지사항 삭제
const deleteConsultNotice = async data => {
	const response = await instance.post(`${domain}deleteConsultNotice`, data);
	return response;
};

// 공지사항 관련 파일 삭제
const deleteConsultNoticeAttach = async data => {
	const response = await instance.post(
		`${domain}deleteConsultNoticeAttach`,
		data,
	);
	return response;
};

export {
	getConsultNoticeList,
	getConsultNoticeTopList,
	getConsultNoticeStatusCount,
	getConsultNoticeRegiMemberInfo,
	getConsultNoticeAttachList,
	consultPolicyList,
	insertConsultNotice,
	insertConsultPolicy,
	updateConsultNotice,
	uploadConsultNoticeAttach,
	deleteConsultNotice,
	deleteConsultNoticeAttach,
};
