import Vue from 'vue';
import Vuex from 'vuex';
import { commonCodeEnumListFnc } from '@/common/common';
import {
	getOpeningStoreList,
	getSaleStoreList,
} from '../../../../api/supply-chain-mgmt/application-form-creation/ApplicationFromApi';
import {
	cancelDeliveryMatching,
	cancelDeviceTypeMatching,
	changeCourier,
	deliveryCompleted,
	deliveryCompletedCancel,
	deliveryMatching,
	deviceMatching,
	getCourierInfo,
	getDetailList,
	getApplicationMatchPage,
	getDeviceAutoMatchGroupList,
	getDeviceAutoMatchList,
	getApplicationMatchCount,
	getDeviceAutoMatchCount,
} from '../../../../api/supply-chain-mgmt/application-matching/ApplicationMatchingApi';

Vue.use(Vuex);

const ApplicationMatchingModule = {
	namespaced: true,
	state: {
		resetData: false,
		allChecked: false,
		copyData: {
			consultRegiStartDateTime: '',
			consultRegiEndDateTime: '',
			barcode: '',
			beforeReserveYn: '전체',
			useYn: 'N',
		},
		applMatchingList: [],
		autoMatchList: [],
		autoMatchListTotalCnt: 0,
		autoMatchFinCnt: 0,
		applMatchingDetailObj: {},
		totalCnt: 0,
		filterData: {
			perPageCnt: 20,
			pageNo: 1,
			consultRegiStartDateTime: '',
			consultRegiEndDateTime: '',
			barcode: '',
			beforeReserveYn: '',
			openingTaskStatuses: [],
			consultTaskStatuses: [],
			logisticsTaskStatuses: [],
			saleStoreId: [],
			openingStoreId: [],
			goodsIds: [],
			colorNames: [],
			usimPaymentTypes: [],
			cusName: '',
			addr: '',
			invoiceNum: '',
			// taskStatus: '',
			// capacity: '',
			// deliveryType: '',
		},
		filterData2: {
			consultRegiStartDateTime: '',
			consultRegiEndDateTime: '',
		},
		tableFilterData: {
			storeName: [],
			openStoreName: [],
			goodsId: [],
			color: [],
			usimPaymentTypeMsg: [],
			barcode: '',
			cusNamePhone: '',
			cusAddr: '',
		},
		statusList: {
			OpeningTaskStatus: [],
			ConsultTaskStatus: [],
			LogisticsTaskStatus: [],
			UsimPaymentType: [],
		},
		saleStoreList: [],
		openingStoreList: [],
		dateResetData: false,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		applSocketList: null,
		applicationMatchCount: {
			deviceMatchCount: 0,
			deviceNotMatchCount: 0,
			deviceNusimMatchCount: 0,
			deviceNusimNotMatchCount: 0,
			deviceNusimPartMatchCount: 0,
			totalCount: 0,
			usimMatchCount: 0,
			usimNotMatchCount: 0,
		},
		deviceAutoMatchCount: {
			deviceMatchCount: 0,
			deviceNotMatchCount: 0,
			deviceNusimMatchCount: 0,
			deviceNusimNotMatchCount: 0,
			deviceNusimPartMatchCount: 0,
			totalCount: 0,
		},
	},
	getters: {},
	mutations: {
		initCopyData(state) {
			state.copyData.consultRegiStartDateTime = '';
			state.copyData.consultRegiEndDateTime = '';
			state.copyData.barcode = '';
			state.copyData.beforeReserveYn = '전체';
			state.copyData.useYn = 'N';
		},
		initApplicationMatchCount(state) {
			for (let i in state.applicationMatchCount) {
				state.applicationMatchCount[i] = 0;
			}
		},
		initDeviceAutoMatchCount(state) {
			for (let i in state.deviceAutoMatchCount) {
				state.deviceAutoMatchCount[i] = 0;
			}
		},
		initTableFilter(state) {
			state.tableFilterData.openStoreName = [];
			state.tableFilterData.storeName = [];
			state.tableFilterData.goodsId = [];
			state.tableFilterData.color = [];
			state.tableFilterData.usimPaymentTypeMsg = [];
			state.tableFilterData.barcode = '';
			state.tableFilterData.cusNamePhone = '';
			state.tableFilterData.cusAddr = '';
		},
		initAutoMatchList(state) {
			state.autoMatchList = [];
			state.autoMatchFinCnt = 0;
			state.autoMatchListTotalCnt = 0;
		},
		setApplMatchingList(state, result) {
			state.applMatchingList = result.resultList || [];
			state.totalCnt = result.totalCnt || 0;
			state.pagingData.perPageCnt = result.perPageCnt || 0;
			state.pagingData.pageNo = result.pageNo || 0;
			state.pagingData.pageNumList = result.pageNumList || [];
			state.pagingData.pageLastNo = result.pageLastNo || 0;
			state.pagingData.pageEndNo = result.pageEndNo || 0;
		},
		setDeviceAutoMatchList(state, result) {
			state.autoMatchList = result.resultList || [];
			state.autoMatchListTotalCnt = result.totalCnt;
		},
		setApplicationMatchCount(state, result) {
			state.applicationMatchCount.deviceMatchCount =
				result.deviceMatchCount || 0;
			state.applicationMatchCount.deviceNotMatchCount =
				result.deviceNotMatchCount || 0;
			state.applicationMatchCount.deviceNusimMatchCount =
				result.deviceNusimMatchCount || 0;
			state.applicationMatchCount.deviceNusimNotMatchCount =
				result.deviceNusimNotMatchCount || 0;
			state.applicationMatchCount.deviceNusimPartMatchCount =
				result.deviceNusimPartMatchCount || 0;
			state.applicationMatchCount.totalCount = result.totalCount || 0;
			state.applicationMatchCount.usimMatchCount = result.usimMatchCount || 0;
			state.applicationMatchCount.usimNotMatchCount =
				result.usimNotMatchCount || 0;
		},
		setDeviceAutoMatchCount(state, result) {
			state.deviceAutoMatchCount.deviceMatchCount =
				result.deviceMatchCount || 0;
			state.deviceAutoMatchCount.deviceNotMatchCount =
				result.deviceNotMatchCount || 0;
			state.deviceAutoMatchCount.deviceNusimMatchCount =
				result.deviceNusimMatchCount || 0;
			state.deviceAutoMatchCount.deviceNusimNotMatchCount =
				result.deviceNusimNotMatchCount || 0;
			state.deviceAutoMatchCount.deviceNusimPartMatchCount =
				result.deviceNusimPartMatchCount || 0;
			state.deviceAutoMatchCount.totalCount = result.totalCount || 0;
		},
		setDetailList(state, result) {
			state.applMatchingDetailObj = result || [];
		},
		totalCntInit(state) {
			state.totalCnt = 0;
		},
		filterTableRows(state) {
			// 필터 항목들 특징별로 구분하여 변수 내 선언
			let arr = [
				'storeName',
				'openStoreName',
				'usimPaymentTypeMsg',
				'goodsId',
				'color',
			];
			let multi = ['barcode', 'cusNamePhone', 'cusAddr'];
			for (let i in state.autoMatchList) {
				let isAMatch = true;
				for (let j in arr) {
					if (state.tableFilterData[arr[j]].length > 0) {
						if (
							state.tableFilterData[arr[j]].includes(
								state.autoMatchList[i][arr[j]].toString(),
							)
						) {
							isAMatch = true;
						} else {
							isAMatch = false;
							break;
						}
						if (arr[j] === 'goodsId') {
						} else {
							if (
								state.tableFilterData[arr[j]].includes(
									state.autoMatchList[i][arr[j]],
								)
							) {
								isAMatch = true;
							} else {
								isAMatch = false;
								break;
							}
						}
					}
				}
				if (isAMatch) {
					for (let k in multi) {
						let result;
						if (state.tableFilterData[multi[k]].length > 0) {
							if (multi[k] === 'barcode') {
								if (state.autoMatchList[i].deviceRawBarcode) {
									result = state.autoMatchList[i].deviceRawBarcode.includes(
										state.tableFilterData[multi[k]].toUpperCase(),
									);
								}
								if (state.autoMatchList[i].usimRawBarcode) {
									if (!result) {
										result = state.autoMatchList[i].usimRawBarcode.includes(
											state.tableFilterData[multi[k]].toUpperCase(),
										);
									}
								}
								if (result) {
									isAMatch = true;
								} else {
									isAMatch = false;
								}
							}
							if (multi[k] === 'cusNamePhone') {
								if (isAMatch) {
									isAMatch =
										state.autoMatchList[i].customerAttribute.cusName.includes(
											state.tableFilterData[multi[k]],
										) ||
										state.autoMatchList[i].cusPhone.includes(
											state.tableFilterData[multi[k]],
										);
								}
							}
							if (multi[k] === 'cusAddr') {
								if (isAMatch) {
									isAMatch = state.autoMatchList[i].cusFullAddr.includes(
										state.tableFilterData[multi[k]],
									);
								}
							}
						}
					}
				}
				if (isAMatch) {
					state.autoMatchList[i].isHidden = false;
					document.getElementById(`appl${i}`).style.display = '';
				} else {
					state.autoMatchList[i].isHidden = true;
					document.getElementById(`appl${i}`).style.display = 'none';
				}
			}
		},
		subFilterInit(state) {
			let str = ['cusName', 'cusAddr', 'invoiceNum', 'addr'];
			for (const data in str) {
				state.filterData[str[data]] = '';
			}
			let arr = [
				'openingTaskStatuses',
				'consultTaskStatuses',
				'logisticsTaskStatuses',
				'saleStoreId',
				'openingStoreId',
				'goodsIds',
				'colorNames',
				'usimPaymentTypes',
			];
			for (const data in arr) {
				state.filterData[arr[data]] = [];
			}
		},
		filterInit(state) {
			state.filterData.consultRegiStartDateTime = '';
			state.filterData.consultRegiEndDateTime = '';
			state.filterData.barcode = '';
			state.filterData.beforeReserveYn = '전체';
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
		filterInit2(state) {
			for (const data in state.filterData2) {
				state.filterData2[data] = '';
			}
			state.dateResetData = !state.dateResetData;
		},
		setStatusList(state, result) {
			state.statusList = result || [];
		},
		setSaleStoreList(state, result) {
			state.saleStoreList = result || [];
		},
		setOpeningStoreList(state, result) {
			state.openingStoreList = result || [];
		},
	},
	actions: {
		async getApplicationMatchPage({ commit }, data) {
			try {
				if (data && data.beforeReserveYn === '전체') {
					data.beforeReserveYn = '';
				}
				const result = await getApplicationMatchPage(data);
				if (data && data.beforeReserveYn === '') {
					data.beforeReserveYn = '전체';
				}
				if (200 === result.data.status) {
					if ('0000' === result.data.resultCode) {
						commit('setApplMatchingList', result.data.data);
						commit('setApplMatchingList', result.data.data);
						return true;
						//alert(result.data.resultMsg);
					} else {
						return false;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getDeviceAutoMatchList({ commit }, data) {
			try {
				commit('initAutoMatchList');
				const result = await getDeviceAutoMatchList(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						return false;
					} else {
						commit('setDeviceAutoMatchList', result.data.data);
						return result.data.data;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getApplicationMatchCount({ commit }, data) {
			try {
				commit('initApplicationMatchCount');
				if (data && data.beforeReserveYn === '전체') {
					data.beforeReserveYn = '';
				}
				const result = await getApplicationMatchCount(data);
				if (data && data.beforeReserveYn === '') {
					data.beforeReserveYn = '전체';
				}

				if (result && '0000' === result.data.resultCode) {
					commit('setApplicationMatchCount', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getDeviceAutoMatchCount({ commit }, data) {
			try {
				commit('initDeviceAutoMatchCount');
				const result = await getDeviceAutoMatchCount(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setDeviceAutoMatchCount', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStatusList({ commit }, data) {
			try {
				const result = await commonCodeEnumListFnc(data);
				commit('setStatusList', result);
			} catch (e) {
				console.log(e);
			}
		},
		async getSaleStoreList({ commit }, data) {
			try {
				const result = await getSaleStoreList(data);
				if (200 === result.data.status) {
					if ('0000' === result.data.resultCode) {
						commit('setSaleStoreList', result.data.data);
						return true;
					} else {
						return false;
						//alert(result.data.resultMsg);
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getOpeningStoreList({ commit }, data) {
			try {
				const result = await getOpeningStoreList(data);
				if (200 === result.data.status) {
					if ('0000' === result.data.resultCode) {
						commit('setOpeningStoreList', result.data.data);
						return true;
					} else {
						return false;
						//alert(result.data.resultMsg);
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getDetailList({ commit }, data) {
			try {
				const result = await getDetailList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setDetailList', result.data.data);
					return true;
				} else {
					return false;
					//alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deviceMatching({ commit }, data) {
			try {
				// 신청서에 이미 매칭되어있는지 체크
				data['matchingDvcChangeYn'] = 'N';
				const result = await deviceMatching(data);
				if (200 === result.data.status) {
					if ('3999' === result.data.resultCode) {
						if (!confirm(result.data.resultMsg)) {
							return;
						}
						data['myStockMatchingDvcChangeYn'] = 'Y';
						const result2 = await deviceMatching(data);
						if ('0000' !== result2.data.resultCode) {
							alert(result2.data.resultMsg);
							return false;
						} else {
							return true;
						}
					} else if ('3998' === result.data.resultCode) {
						if (!confirm(result.data.resultMsg)) {
							return;
						}
						//TODO 비재고 매칭 진행 로직 추가
					} else if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async cancelDeviceTypeMatching({ commit }, data) {
			try {
				const result = await cancelDeviceTypeMatching(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deliveryMatching({ commit }, data) {
			try {
				const result = await deliveryMatching(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async cancelDeliveryMatching({ commit }, data) {
			try {
				const result = await cancelDeliveryMatching(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},

		async getCourierInfo({ commit }, data) {
			try {
				const result = await getCourierInfo(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return result.data.data;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deliveryCompleted({ commit }, data) {
			try {
				const result = await deliveryCompleted(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deliveryCompletedCancel({ commit }, data) {
			try {
				const result = await deliveryCompletedCancel(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async changeCourier({ commit }, data) {
			try {
				const result = await changeCourier(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default ApplicationMatchingModule;
