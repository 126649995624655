import Vue from 'vue';
import Vuex from 'vuex';

import { getFromCookie, saveCookies, deleteCookies } from '../common/cookies';
import {
	checkApplicationStatusUpdateFlag,
	getBookmarkList,
	maskingFnc,
	postBookmarkList,
} from '../api/common/common';
import { unescapeHtml } from '../common/common';
import { logout } from '../api/member/login';
import { router } from '../router/index';
import commonModule from './common/commonModule.js';
import CodeModule from './common/CodeModule.js';
import BookmarkModule from './common/BookmarkModule';
import memoModule from './common/MemoModule';
import ConsultMemoModule from './common/ConsultMemoModule';
import OpeningStoreMgmtModule from './modules/common-mgmt/business-man-mgmt/OpeningStoreMgmtModule.js';
import SaleStoreMgmtModule from './modules/common-mgmt/business-man-mgmt/SaleStoreMgmtModule.js';
import ChargeListModule from './modules/common-mgmt/charge-mgmt/ChargeListModule.js';
import ChargeRequestModule from './modules/common-mgmt/charge-mgmt/ChargeRequestModule.js';
import MobilephoneListModule from './modules/common-mgmt/goods-mgmt/MobilephoneListModule.js';
import MobilephoneRequestModule from './modules/common-mgmt/goods-mgmt/MobilephoneRequestModule.js';
import MobilephoneGroupListModule from './modules/common-mgmt/goods-mgmt/MobilephoneGroupListModule.js';
import AdditionServiceModule from './modules/common-mgmt/addition-service-mgmt/AdditionServiceModule.js';
import AdditionServiceRequestModule from './modules/common-mgmt/addition-service-mgmt/AdditionServiceRequestModule.js';
import OrganizationMgmtModule from './modules/common-mgmt/organization-mgmt/OrganizationMgmtModule.js';
import ProviderMgmtModule from './modules/warehouse-mgmt/stock-setting-mgmt/ProviderMgmtModule';
import InStockMgmtModule from './modules/warehouse-mgmt/device-mgmt/InStockMgmtModule.js';
import HoldStoreMgmtModule from './modules/warehouse-mgmt/stock-setting-mgmt/HoldStoreMgmtModule.js';
import NormalInStockModule from './modules/warehouse-mgmt/device-mgmt/NormalInStockModule.js';
import OpenInStockModule from './modules/warehouse-mgmt/device-mgmt/OpenInStockModule.js';
import MoveStockRtngudModule from './modules/warehouse-mgmt/device-mgmt/MoveStockRtngudModule.js';
import PnSupportMoneyMgmtModule from './modules/common-mgmt/pn-support-money-mgmt/PnSupportMoneyMgmtModule.js';
import StockStatusModule from './modules/warehouse-mgmt/stock-mgmt/StockStatusModule.js';
import MoveStatusModule from './modules/warehouse-mgmt/device-mgmt/MoveStatusModule.js';
import DeviceStatusModule from './modules/warehouse-mgmt/device-mgmt/DeviceStatusModule.js';
import SalesMoveModule from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/SalesMoveModule.js';
import StockMoveModule from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/StockMoveModule.js';
import StockTransferModule from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/StockTransferModule.js';
import FaultyTransferModule from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/FaultyTransferModule.js';
import SalesTransferModule from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/SalesTransferModule.js';
import LongtimeStockModule from './modules/warehouse-mgmt/stock-mgmt/LongtimeStockModule';
import FaultyDeviceStatusModule from './modules/warehouse-mgmt/stock-mgmt/FaultyDeviceStatusModule';
import MediationReleaseModule from './modules/warehouse-mgmt/stock-mgmt/MediationReleaseModule.js';
import StockMoveFilterCommonModules from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/StockMoveFilterCommonModules';
import OpenStoreDeviceStatusModule from './modules/warehouse-mgmt/device-mgmt/OpenStoreDeviceStatusModule.ts';
import ReturnTransferModule from './modules/warehouse-mgmt/device-mgmt/StockMoveTransfer/ReturnTransferModule.js';
import SellStatusModule from './modules/supply-chain-mgmt/sell-mgmt/SellStatusModule';
import QuickOpeningPopModule from './modules/supply-chain-mgmt/sell-mgmt/QuickOpeningPopModule';
import ApplicationFormCreationModule from './modules/supply-chain-mgmt/application-form-creation/ApplicationFormCreationModule.ts';
import BlackListModule from './modules/supply-chain-mgmt/black-list/BlackListModule';
import TaskHistoryModule from './common/task-history/TaskHistory.js';
import MgmtTargetDeviceModule from '@/store/modules/warehouse-mgmt/stock-mgmt/MgmtTargetDeviceModule';
import SktBondMgmtModule from '@/store/modules/settlement-mgmt/SktBondMgmtModule';
import KtBondMgmtModule from '@/store/modules/settlement-mgmt/KtBondMgmtModule';
import LguBondMgmtModule from '@/store/modules/settlement-mgmt/LguBondMgmtModule';
import UsimInStockModule from '@/store/modules/warehouse-mgmt/device-mgmt/UsimInStockModule';
import UsimStatusModule from '@/store/modules/warehouse-mgmt/device-mgmt/UsimStatusModule';
import ApplicationMatchingModule from './modules/supply-chain-mgmt/application-matching/ApplicationMatchingModule';
import StatsMgmtModule from '@/store/modules/supply-chain-mgmt/stats-mgmt/StatsMgmtModule';
import CreditCheckModule from '@/store/modules/credit-check-mgmt/credit-check/CreditCheckModule';
import RightExecutionModule from '@/store/modules/supply-chain-mgmt/application-form-creation/RightExecutionModule';
import ComplainHndlgModule from '@/store/modules/comm-mgmt/complain-hndlg/ComplainHndlgModule';
import ReceiveRequestModule from '@/store/modules/supply-chain-mgmt/application-form-creation/ReceiveRequestModule';
import RightExeTableModule from '@/store/modules/supply-chain-mgmt/application-form-creation/RightExeTableModule';
import ReceiveReqTableModule from '@/store/modules/supply-chain-mgmt/application-form-creation/ReceiveReqTableModule';
import NoticeModule from './common/NoticeModule';
import SignatrueMgmtModule from './modules/supply-chain-mgmt/signatrue-mgmt/SignatrueMgmtModule';
import InStockRegiModule from '@/store/modules/warehouse-mgmt/device-mgmt/InStockRegiModule';
import CustomerConsultModule from './modules/sales-mgmt/CustomerConsultModule';
import ConsultDbModule from './modules/sales-mgmt/ConsultDbModule';
import ConsultNoticeModule from './modules/sales-mgmt/ConsultNoticeModule';
import CustomerDbModule from './modules/sales-mgmt/CustomerDbModule';
import EnvSettingsModule from './modules/sales-mgmt/EnvSettingsModule';
import RetentionDbModule from './modules/sales-mgmt/RetentionDbModule';
import SalesMgmtModule from './modules/sales-mgmt/SalesMgmtModule';
import ConsultSettingModule from '@/store/modules/sales-mgmt/ConsultSettingModule';
import ChargeCalculatorModule from '@/store/modules/sales-mgmt/ChargeCalculatorModule';
import TodoModule from '@/store/common/TodoModule';
import DashboardModule from '@/store/common/DashboardModule';
import SalesStatsModule from './modules/sales-mgmt/SalesStatsModule';
import SystemMgmtModule from './modules/common-mgmt/system-mgmt/SystemMgmtModule';
import csMgmtModule from './modules/common-mgmt/cs-mgmt/csMgmtModule';
import MallPageMgmtModule from './modules/mall_mgmt/MallPageMgmtModule';
import MallEventMgmtModule from './modules/mall_mgmt/MallEventMgmtModule';
import MallDomainMgmtModule from './modules/mall_mgmt/MallDomainMgmtModule';
import MallProductMgmtModule from '@/store/modules/mall_mgmt/MallProductMgmtModule';
import MallJudgeMgmtModule from '@/store/modules/mall_mgmt/MallJudgeMgmtModule';
import MallProducingMgmtModule from '@/store/modules/mall_mgmt/MallProducingMgmtModule';
import MallTermsMgmtModule from '@/store/modules/mall_mgmt/MallTermsMgmtModule';
import MallMemberMgmtModule from '@/store/modules/mall_mgmt/MallMemberMgmtModule';
import MallReviewMgmtModule from '@/store/modules/mall_mgmt/MallReviewMgmtModule';

Vue.use(Vuex);

const ACCESS_TOKEN_NAME = '_at';
const existingToken = window.localStorage.getItem(ACCESS_TOKEN_NAME);

export default new Vuex.Store({
	state: {
		auth: {
			accessToken: existingToken,
		},
		drawer: false,
		footerFlag: false,
		cookies: {
			name: getFromCookie('name') || '',
			role: getFromCookie('role') || '',
			sct: getFromCookie('sct') || '',
			mq: getFromCookie('mq') || '',
			mf: getFromCookie('mf') || '',
			pu: getFromCookie('pu') || null,
			st: getFromCookie('st') || null,
			sg: getFromCookie('sg') || null,
			ms: getFromCookie('ms') || null,
			ss: getFromCookie('ss') || null,
			mr: getFromCookie('mr') || null,
			mp: getFromCookie('mp') || null,
			cr: getFromCookie('cr') || null,
			oi: getFromCookie('oi') || null,
			sn: getFromCookie('sn') || null,
			sm: getFromCookie('sm') || null,
			ca: getFromCookie('ca') || null,
		},
		bookmarkList: [],
		bookmarkUseYn: '',
		alarm: false,
		alarmData: [],
		applSocketList: null,
		validationFocus: null,
		initDomainStatusType: null,
		importantSysDialog: false,
		termsDialog: false,
		TOSForExistingMemberDialog: false,
		termsCheckDialog: false,
		customToolbar: [
			[
				{
					header: [false, 1, 2, 3, 4, 5, 6],
				},
			],
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			[
				{
					align: '',
				},
				{
					align: 'center',
				},
				{
					align: 'right',
				},
				{
					align: 'justify',
				},
			],
			[
				{
					list: 'ordered',
				},
				{
					list: 'bullet',
				},
				{
					list: 'check',
				},
			],
			[
				{
					indent: '-1',
				},
				{
					indent: '+1',
				},
			], // outdent/indent
			[
				{
					color: [],
				},
				{
					background: [],
				},
			], // dropdown with defaults from theme
			['link', 'image'], // remove formatting button
		],
		//name: '',
	},
	getters: {
		isLogin(state) {
			return state.cookies.name !== '';
		},
	},
	mutations: {
		setBearerToken(state, token) {
			state.auth.accessToken = token;
		},
		setInfo(state, data) {
			// 로그인시 기본 쿠키정보 초기화 Start
			for (let [key] of Object.entries(state.cookies)) {
				key = '';
			}
			deleteCookies(state.cookies);
			// 로그인시 기본 쿠키정보 초기화 End
			saveCookies(data, state.cookies);
			state.footerFlag = true;
		},
		clearInfo(state) {
			for (let [key] of Object.entries(state.cookies)) {
				state.cookies[key] = '';
			}
		},
		redirectLogin(state) {
			router.push('/login').catch(() => {});
			state.footerFlag = false;
		},
		alarmFnc(state) {
			if (!state.alarm) {
				state.alarm = true;
				setTimeout(() => {
					state.alarm = false;
				}, 10000);
			}
		},
		alarmPush(state, data) {
			state.alarmData.push(data);
		},
		// 즐겨찾기 조회
		setBookmarkList(state, result) {
			if (!result || result.bookmarkList === '[null]') {
				state.bookmarkList = [];
			} else {
				state.bookmarkList = JSON.parse(unescapeHtml(result.bookmarkList));
			}
		},
		// 즐겨찾기 추가
		addMark(state, data) {
			state.bookmarkList.push(data);
		},
		// 즐겨찾기 삭제
		removeMark(state, index) {
			state.bookmarkList.splice(index, 1);
		},
		// drawer닫기
		closeDrawer(state, data) {
			state.drawer = true;
		},
	},
	actions: {
		async LOGIN({ commit }, data) {
			commit('clearInfo');
			deleteCookies(this.state.cookies);
			commit('setInfo', data);

			commit('setBearerToken', data.accessToken);
			// local storage
			window.localStorage.setItem(ACCESS_TOKEN_NAME, data.accessToken);
		},
		async LOGOUT({ commit }, tokenDel) {
			if (tokenDel === 'Y') {
				await logout();
			}
			commit('clearInfo');
			deleteCookies(this.state.cookies);
			commit('redirectLogin');
		},
		async LOGOUT2({ commit }, tokenDel) {
			if (tokenDel === 'Y') {
				await logout();
			}
			deleteCookies(this.state.cookies);
			commit('redirectLogin');
		},
		async INVITATION_JOIN({}) {
			await logout();
			deleteCookies(this.state.cookies);
		},
		//즐겨찾기 추가
		async addBookmark({ commit, dispatch }, data) {
			commit('addMark', data);
			dispatch('saveBookmarkList');
		},
		// 즐겨찾기 삭제
		async removeBookmark({ commit, dispatch }, index) {
			commit('removeMark', index);
			dispatch('saveBookmarkList');
		},
		// 즐겨찾기 조회
		async getBookmarkList({ commit }) {
			try {
				const result = await getBookmarkList();
				if (result) {
					commit('setBookmarkList', result.data.data);
				}
			} catch {
				// alert('즐겨찾기 조회 실패.');
			}
		},
		// 즐겨찾기 저장/업데이트
		async saveBookmarkList({ state }) {
			try {
				let bookmarkList = JSON.stringify(state.bookmarkList);
				let data = {
					bookmarkList: bookmarkList,
				};
				await postBookmarkList(data);
			} catch (e) {
				alert('알 수 없는 에러입니다.');
				console.log(e);
			}
		},
		async maskingFnc({ state }) {
			try {
				await maskingFnc();
				location.reload();
			} catch (e) {
				alert('알 수 없는 에러입니다.');
				console.log(e);
			}
		},
		async checkApplicationStatusUpdateFlag({ commit }, data) {
			try {
				const result = await checkApplicationStatusUpdateFlag(data);
				if (200 === result.data.status) {
					if ('0000' !== result.data.resultCode) {
						alert(result.data.resultMsg);
						return false;
					} else {
						if (result.data.data === true) {
							alert('상담상태에서 상태값을 변경해 주세요.');
							return false;
						}
						return true;
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
	modules: {
		commonModule: commonModule,
		CodeModule: CodeModule,
		memoModule: memoModule,
		ConsultMemoModule: ConsultMemoModule,
		BookmarkModule: BookmarkModule,
		OpeningStoreMgmtModule: OpeningStoreMgmtModule, // 개통점관리
		SaleStoreMgmtModule: SaleStoreMgmtModule, //영업점관리
		MobilephoneListModule: MobilephoneListModule, // 상품관리-상품목록
		MobilephoneRequestModule: MobilephoneRequestModule, // 상품관리-상품요청목록
		MobilephoneGroupListModule: MobilephoneGroupListModule, // 상품관리-상품그룹목록
		AdditionServiceModule: AdditionServiceModule, //부가서비스관리 - 요금목록
		AdditionServiceRequestModule: AdditionServiceRequestModule, //부가서비스관리 - 요금요청목록
		ProviderMgmtModule: ProviderMgmtModule, // 공급처관리
		ChargeListModule: ChargeListModule, //요금관리 - 요금목록
		ChargeRequestModule: ChargeRequestModule, // 요금관리 - 요금요청목록
		InStockMgmtModule: InStockMgmtModule, // 입고현황
		HoldStoreMgmtModule: HoldStoreMgmtModule, // 보유처관리
		NormalInStockModule: NormalInStockModule, // 정상입고
		OpenInStockModule: OpenInStockModule, // 개봉입고
		MoveStockRtngudModule: MoveStockRtngudModule, // 반품현황
		MoveStatusModule: MoveStatusModule, // 이동/이관현황
		DeviceStatusModule: DeviceStatusModule, // 기기현황
		OrganizationMgmtModule: OrganizationMgmtModule, // 조직관리
		SalesMoveModule: SalesMoveModule, // 판매이동
		StockMoveModule: StockMoveModule, // 이동재고
		StockTransferModule: StockTransferModule, // 재고이관
		FaultyTransferModule: FaultyTransferModule, // 불량이관
		SalesTransferModule: SalesTransferModule, // 판매이관
		StockStatusModule: StockStatusModule, // 재고현황
		LongtimeStockModule: LongtimeStockModule, // 장기재고
		FaultyDeviceStatusModule: FaultyDeviceStatusModule, // 불량기기현황
		MediationReleaseModule: MediationReleaseModule, //조정출고
		StockMoveFilterCommonModules: StockMoveFilterCommonModules, // 이동재고/이관 공통 필터
		PnSupportMoneyMgmtModule: PnSupportMoneyMgmtModule, // 공시지원금관리
		SellStatusModule: SellStatusModule, // 판매현황
		ApplicationFormCreationModule: ApplicationFormCreationModule, // 신청서작성
		OpenStoreDeviceStatusModule: OpenStoreDeviceStatusModule, // 개통기기현황
		QuickOpeningPopModule: QuickOpeningPopModule, //빠른개통
		ReturnTransferModule: ReturnTransferModule, // 반품이관
		MgmtTargetDeviceModule: MgmtTargetDeviceModule, // 관리기기현황
		SktBondMgmtModule: SktBondMgmtModule, // 채권관리(SKT)
		KtBondMgmtModule: KtBondMgmtModule, // 채권관리(KT)
		LguBondMgmtModule: LguBondMgmtModule, // 채권관리(LGU)
		UsimInStockModule: UsimInStockModule, // 유심입고
		UsimStatusModule: UsimStatusModule, // 유심현황
		BlackListModule: BlackListModule, // 블랙리스트관리
		TaskHistoryModule: TaskHistoryModule, // 업무 이력
		ApplicationMatchingModule: ApplicationMatchingModule, // 신청서매칭
		StatsMgmtModule: StatsMgmtModule, // 통계현황
		CreditCheckModule: CreditCheckModule, // 신용조회
		RightExecutionModule: RightExecutionModule, // 권리실행
		ComplainHndlgModule: ComplainHndlgModule, //민원처리내역
		ReceiveRequestModule: ReceiveRequestModule, // 수납요청
		RightExeTableModule: RightExeTableModule, // 권리실행리스트
		ReceiveReqTableModule: ReceiveReqTableModule, // 수납요청리스트
		NoticeModule: NoticeModule, // 알림
		SignatrueMgmtModule: SignatrueMgmtModule, // 서명내역
		InStockRegiModule: InStockRegiModule, // 입고등록(정상,개봉,유심입고 통합)
		ConsultDbModule: ConsultDbModule, //  상담 DB
		CustomerConsultModule: CustomerConsultModule, // 고객상담
		ConsultNoticeModule: ConsultNoticeModule, // 공지사항
		CustomerDbModule: CustomerDbModule, // 고객 DB
		EnvSettingsModule: EnvSettingsModule, // 환경설정
		RetentionDbModule: RetentionDbModule, // 리텐션 DB
		SalesMgmtModule: SalesMgmtModule, //영업관리(공통)
		ChargeCalculatorModule: ChargeCalculatorModule, // 요금계산기
		ConsultSettingModule: ConsultSettingModule, // 상담설정
		TodoModule: TodoModule, // 영업관리 To_Do
		DashboardModule: DashboardModule, // 대시보드
		SalesStatsModule: SalesStatsModule, // Todo
		SystemMgmtModule: SystemMgmtModule, // 시스템관리
		csMgmtModule: csMgmtModule, // 기술 및 장애문의
		MallPageMgmtModule: MallPageMgmtModule, // 쇼핑몰 페이지 관리
		MallEventMgmtModule: MallEventMgmtModule, // 쇼핑몰 이벤트 관리
		MallDomainMgmtModule: MallDomainMgmtModule, // 쇼핑몰 입점 관리
		MallProductMgmtModule: MallProductMgmtModule, // 쇼핑몰 판매상품 관리
		MallJudgeMgmtModule: MallJudgeMgmtModule, // 쇼핑몰 판매정책 관리
		MallProducingMgmtModule: MallProducingMgmtModule, // 쇼핑몰 의뢰게시판 관리
		MallTermsMgmtModule: MallTermsMgmtModule, // 몰 약관 관리
		MallMemberMgmtModule: MallMemberMgmtModule, // 몰 회원관리
		MallReviewMgmtModule: MallReviewMgmtModule, // 몰 리뷰관리
	},
});
