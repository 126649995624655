import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/';

const getTodoList = async data => {
	if (!data.param) {
		data.param = '';
	}
	if (data.param) data.param = jsonToQueryString(data.param);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/consultTodoList/${data.consultType}${data.param}`,
	);
	return response;
};

const getMainList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/customerConsultList${param}`,
	);
	return response;
};

const insertConsult = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/insertConsult`,
		data,
	);
	return response;
};

const updateConsult = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/updateConsult`,
		data,
	);
	return response;
};

const checkDuplicationConsult = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/checkDuplicationConsult`,
		data,
	);
	return response;
};

const consultTodoGroupCnt = async data => {
	if (!data.param) {
		data.param = '';
	}
	if (data.param) data.param = jsonToQueryString(data.param);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/consultTodoGroupCnt/${data.consultType}${data.param}`,
	);
	return response;
};

const consultGroupCnt = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/consultGroupCnt${param}`,
	);
	return response;
};

const deleteConsultTodo = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/deleteConsultTodo`,
		data,
	);
	return response;
};

// 고객상담 설정목록
const consultSettingList = async data => {
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/consultSettingList`,
		data,
	);
	return response;
};
// 상담 상태 조회
const consultStatusList = async data => {
	let param = '';
	if (data) {
		param = data.consultantType;
	}
	const response = await instance.get(
		`SalesManagement/ConsultStatusMgmt/consultStatusList/${param}`,
	);
	return response;
};

// 권한별 상담 상태 조회
const authConsultStatus = async data => {
	const response = await instance.get(
		`SalesManagement/ConsultStatusMgmt/authConsultStatus`,
		data,
	);
	return response;
};

// get용 sample
const Example_get = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getXXX${param}`);
	return response;
};

// update(post)용 sample
const Example_update = async data => {
	const response = await instance.post(`${domain}updateXXX`, data);
	return response;
};

// to-do 등록
const insertConsultTodo = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/insertConsultTodo`,
		data,
	);
	return response;
};

// 상담DB/고객상담 TO-DO등록상태조회
const consultTodoStatusGroup = async data => {
	let consultType = '';
	let param = '';
	if (data.consultType) {
		consultType = data.consultType;
	}
	if (data.param) param = jsonToQueryString(data.param);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/consultTodoStatusGroup/${consultType}${param}`,
	);
	return response;
};

// 상담DB/고객상담 등록상태조회
const consultStatusGroupList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/consultStatusGroupList/${param}`,
	);
	return response;
};

// 상담 신용조회 요청
const insertCreditConsult = async data => {
	const response = await instance.post(
		`SalesManagement/ConsultMgmt/insertCreditConsult`,
		data,
	);
	return response;
};

// 고객상담 통신사/개통점 목록
const getStoreConsultSettingList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`SalesManagement/ConsultMgmt/getStoreConsultSettingList${param}`,
	);
	return response;
};

export {
	Example_get,
	Example_update,
	getTodoList,
	getMainList,
	insertConsult,
	updateConsult,
	checkDuplicationConsult,
	consultTodoGroupCnt,
	consultGroupCnt,
	deleteConsultTodo,
	consultSettingList,
	authConsultStatus,
	consultStatusList,
	insertConsultTodo,
	consultTodoStatusGroup,
	consultStatusGroupList,
	insertCreditConsult,
	getStoreConsultSettingList,
};
