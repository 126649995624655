<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" :width="dialogWidth">
				<v-card class="borderRadiReset" style="display: inline-block">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
						style="position: sticky; top: 0; z-index: 10"
					>
						<h2 class="mainWhite mainFontS">민원요청</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<div :class="{ complainCont: this.loadFromAppl }">
						<v-card-text v-if="loadFromAppl" class="complainLCont narrowScroll">
							<div>
								<ul>
									<li
										v-for="(item, index) in complainList"
										:id="`complainHistoryLi${index}`"
										class="complainLContLi cur_p"
										style="border: 1px solid #d8d8d8"
										:key="index"
										@click="editComplainDetail(index)"
										:class="{
											selectedLiBorder:
												selectedHistoryLiId === `complHistoryLi${index}`,
										}"
									>
										<div>
											<div>
												<span v-if="retentionType">
													{{ item.regiDate }}
												</span>
												<span v-if="!retentionType">
													{{ item.regiDate }}<span> | </span>{{ item.regiName }}
												</span>
											</div>
											<div>
												<img
													v-if="item.emergencyYn === 'Y'"
													class="alert"
													src="../../../../../src/assets/img/alert.png"
													alt="긴급요청"
												/>
												<span class="font-bold ml5">{{
													item.complainTypeMsg
												}}</span>
											</div>
										</div>
										<div>
											<table class="complainTable">
												<tr>
													<td>
														민원 시작일:
														<span>{{ item.complainStartDate }}</span>
													</td>
													<td style="padding-left: 8px">
														민원 처리기한:
														<span>{{ item.complainHandlingEndDate }}</span>
													</td>
												</tr>
												<tr>
													<td>
														영업점:
														<span>
															{{ item.saleStoreProcStatusMsg }}
														</span>
													</td>
													<td style="padding-left: 8px">
														개통점:
														<span
															:class="{
																redfont:
																	item.openingStoreProcStatus === 'REJECT' ||
																	item.openingStoreProcStatus === 'PRONOUNCE',
															}"
															>{{ item.openingStoreProcStatusMsg }}</span
														>
													</td>
												</tr>
											</table>
										</div>
										<div>
											<span style="white-space: pre-line">{{
												item.complainContents
											}}</span>
										</div>
									</li>
								</ul>
							</div>
						</v-card-text>
						<v-card-text class="pad20 complainRegiPop complainInfoPop posR">
							<div>
								<div class="posR">
									<h2 class="boldWt mainBlack2 mb10">민원요청</h2>
									<div
										style="z-index: 2; position: absolute; top: -4px; right: 0"
										class="checkStyleCont10"
										v-if="!retentionType"
									>
										<input
											type="checkbox"
											class="checkStyle"
											v-model="complain.emergencyYn"
											id="emergencyYn1"
											:true-value="'Y'"
											:false-value="'N'"
											:disabled="
												(loadFromAppl && formStatus === 'disabled') ||
												complain.regiStoreId !== storeSeq
											"
										/>
										<label
											class="font-btn-color ml-2 cur_p font-size-13"
											for="emergencyYn1"
											>긴급요청</label
										>
									</div>
									<div class="new_popTable1">
										<table class="w100P complainTable">
											<tr v-if="!retentionType">
												<th>민원 등록인</th>
												<td class="w240">
													<input type="text" v-model="complain.regiName" />
												</td>
												<th>민원 등록일자</th>
												<td class="w240" style="padding-left: 18px !important">
													<v-menu
														v-model.trim="menu1"
														:close-on-content-click="false"
														:nudge-left="70"
														transition="scale-transition"
														offset-y
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																outlined
																append-icon="mdi-calendar"
																@click:append="on.click"
																v-model.trim="complain.regiDate"
																readonly
																v-bind="attrs"
																v-on="on"
																class="datePickerInput input_custom1"
															></v-text-field>
														</template>
														<v-date-picker
															@input="menu1 = false"
															v-model.trim="complain.regiDate"
															:max="
																new Date(
																	Date.now() -
																		new Date().getTimezoneOffset() * 60000,
																)
																	.toISOString()
																	.substr(0, 10)
															"
															locale="ko-KR"
														></v-date-picker>
													</v-menu>
												</td>
											</tr>

											<tr v-if="!retentionType">
												<th>민원 영업점</th>
												<td>
													<v-select
														style="margin-left: 10px"
														outlined
														:items="saleStoreList"
														item-text="storeName"
														item-value="storeId"
														v-model.trim="complain.saleStoreId"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														:attach="true"
														@change="initOnChange('saleStoreId')"
													>
													</v-select>
												</td>
												<th>민원 개통점</th>
												<td>
													<v-select
														style="margin-left: 10px"
														outlined
														:items="openingStoreList"
														item-text="openStoreName"
														item-value="openStoreId"
														v-model.trim="selectedOpeningStore"
														return-object
														@change="setParentHierarchy"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														:attach="true"
													>
													</v-select>
												</td>
											</tr>
											<tr v-if="storeVal === 'StoreGrade_M'">
												<th>민원 경로</th>
												<td colspan="3">
													<input
														type="radio"
														id="rPathEmail"
														v-model="complain.complainPath"
														value="EMAIL"
														name="compPath"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/><label for="rPathEmail"><em></em>메일</label>
													<input
														type="radio"
														id="rPathPhone"
														v-model="complain.complainPath"
														value="PHONE"
														name="compPath"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/><label for="rPathPhone"><em></em>전화</label>
													<input
														type="radio"
														id="rPathKaKao"
														v-model="complain.complainPath"
														value="KAKAO_TALK"
														name="compPath"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/><label for="rPathKaKao"><em></em>카카오톡</label>
													<input
														type="radio"
														id="rPathNetwork"
														v-model="complain.complainPath"
														value="NETWORK"
														name="compPath"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/><label for="rPathNetwork"><em></em>전산</label>
													<input
														type="radio"
														id="rPathFmail"
														v-model="complain.complainPath"
														value="FRIENDS_MAIL"
														name="compPath"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/><label for="rPathFmail"><em></em>프렌즈메일</label>
												</td>
											</tr>
											<tr>
												<th>민원 종류</th>
												<td colspan="3">
													<input
														type="radio"
														id="foreign3"
														v-model="complain.complainType"
														value="FOREIGN_COMPLAIN"
														name="vocType"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														@click="setNewTemplateRecipList('FOREIGN_COMPLAIN')"
													/><label for="foreign3"><em></em>대외민원</label>
													<input
														type="radio"
														id="voice3"
														v-model="complain.complainType"
														value="VOICE_COMPLAIN"
														name="vocType"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														@click="setNewTemplateRecipList('VOICE_COMPLAIN')"
													/><label for="voice3"><em></em>VOC</label>
													<input
														type="radio"
														id="sr3"
														v-model="complain.complainType"
														value="SR_COMPLAIN"
														name="vocType"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														@click="initStatusRelatedData('SR_COMPLAIN')"
													/><label for="sr3"><em></em>일반SR</label>
													<input
														type="radio"
														id="call3"
														v-model="complain.complainType"
														value="CALL_COMPLAIN"
														name="vocType"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														@click="initStatusRelatedData('CALL_COMPLAIN')"
													/><label for="call3"><em></em>통화요청</label>
												</td>
											</tr>
											<tr>
												<th>민원 시작일시</th>
												<td colspan="3">
													<span
														style="
															display: inline-block;
															margin-right: 4px;
															padding-left: 10px;
															width: 130px;
														"
													>
														<v-menu
															v-model.trim="menu2"
															:close-on-content-click="false"
															:nudge-right="40"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	outlined
																	v-model.trim="complain.complainStartDate"
																	v-bind="attrs"
																	v-on="on"
																	append-icon="mdi-calendar"
																	@click:append="on.click"
																	readonly
																	class="w130"
																></v-text-field>
															</template>
															<v-date-picker
																v-model.trim="complain.complainStartDate"
																@input="menu2 = false"
																locale="ko-KR"
															></v-date-picker>
														</v-menu>
													</span>
													<span style="width: 10px !important">
														<vue-timepicker
															close-on-complete
															v-model.trim="complain.complainStartTime"
															:disabled="
																(loadFromAppl && formStatus === 'disabled') ||
																complain.regiStoreId !== storeSeq
															"
														></vue-timepicker>
													</span>
												</td>
											</tr>
										</table>
									</div>
								</div>

								<div class="posR">
									<h2 class="boldWt mainBlack2 mt20 mb10">민원고객</h2>
									<div
										v-if="this.complain.applId && !loadFromAppl"
										style="float: right; position: relative"
									>
										<button
											style="
												font-size: 13px;
												background-color: #95a8b8;
												border-radius: 3px;
												color: #fff;
												position: absolute;
												top: -36px;
												right: 0;
												width: 70px;
												padding: 4px 0;
											"
											@click="openApplWindow(complain.applId)"
										>
											신청서
										</button>
									</div>
									<div class="new_popTable1">
										<table class="w100P complainTable">
											<tr>
												<th>고객명(법인명)</th>
												<td>
													<input
														type="text"
														v-model="complain.cusName"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/>
												</td>
												<th>주민등록번호<br />(앞 6자리)</th>
												<td>
													<input
														type="text"
														v-model="complain.cusBirthDate"
														oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
														maxlength="6"
														:disabled="
															complain.regiStoreId !== complain.saleStoreId &&
															complain.regiStoreId !== storeSeq
														"
													/>
												</td>

												<!-- 생년월일 => 주민등록번호 앞6자리로 변경 -->
												<!--												<th>생년월일</th>
												<td style="padding-left: 10px">
													<v-menu
														v-model.trim="menu3"
														:close-on-content-click="false"
														:nudge-left="50"
														transition="scale-transition"
														offset-y
														min-width="auto"
														:disabled="
															loadFromAppl && formStatus === 'disabled'
														"
														:attach="true"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																outlined
																@click:append="on.click"
																append-icon="mdi-calendar"
																v-model.trim="complain.cusBirthDate"
																readonly
																v-bind="attrs"
																v-on="on"
																class="datePickerInput input_custom1"
															></v-text-field>
														</template>
														<v-date-picker
															@input="menu3 = false"
															v-model.trim="complain.cusBirthDate"
															:max="
																new Date(
																	Date.now() -
																		new Date().getTimezoneOffset() * 60000,
																)
																	.toISOString()
																	.substr(0, 10)
															"
															locale="ko-KR"
														></v-date-picker>
													</v-menu>
												</td>-->
											</tr>
											<tr>
												<th>개통 휴대폰번호</th>
												<td>
													<input
														type="text"
														v-model="complain.openingPhone"
														oninput="this.value = this.value.replace(/[^0-9]/g, '');"
														@input="
															phoneNumSplitFnc(
																$event.target.value,
																'openingPhone',
																'complain',
															)
														"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/>
												</td>
												<th>개통일자</th>
												<td style="padding-left: 18px !important">
													<v-menu
														v-model.trim="menu4"
														:close-on-content-click="false"
														:nudge-left="50"
														transition="scale-transition"
														offset-y
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																outlined
																append-icon="mdi-calendar"
																@click:append="on.click"
																v-model.trim="complain.openingDate"
																readonly
																v-bind="attrs"
																v-on="on"
																class="datePickerInput input_custom1"
															></v-text-field>
														</template>
														<v-date-picker
															@input="menu4 = false"
															v-model.trim="complain.openingDate"
															:max="
																new Date(
																	Date.now() -
																		new Date().getTimezoneOffset() * 60000,
																)
																	.toISOString()
																	.substr(0, 10)
															"
															locale="ko-KR"
														></v-date-picker>
													</v-menu>
												</td>
											</tr>
										</table>
									</div>

									<div class="posR">
										<h2 class="boldWt mainBlack2 mt20 mb10">민원 내용</h2>
										<ul
											v-if="prevComplainDetailList.length > 0"
											class="vocHistoryUl"
										>
											<li
												v-for="(item, index) in prevComplainDetailList"
												:key="index"
												class="listAccordion"
												style="padding: 0 !important"
											>
												<div
													v-if="activeComplain !== `historyLi${index + 1}`"
													class="listAccordionTd cur_p"
													style="padding: 10px !important"
													@click="activateComplain(index)"
												>
													<p>
														{{ item.complainContents }}
													</p>
													<div>
														<span v-if="item.regiDatetime" class="font-gray">
															{{
																item.regiDatetime | moment('YYYY-MM-DD HH:mm')
															}}
														</span>
														<input
															:id="`toggle${index + 1}`"
															type="radio"
															name="history"
															:value="`historyLi${index + 1}`"
															v-model="activeComplain"
															@click="activateComplain(index)"
														/><label :for="`toggle${index + 1}`">
															<!--															<em></em>-->
															<i
																class="fas fa-chevron-circle-down font-size-22 font-gray"
																style="transform: translateY(2px)"
															></i>
														</label>
													</div>
												</div>
												<transition name="fades">
													<div
														class="posR"
														v-if="activeComplain === `historyLi${index + 1}`"
													>
														<div
															class="disFX"
															style="
																margin: 12px 0;
																justify-content: space-between;
															"
														>
															<span class="disIn">
																<span class="boldWt">민원요청일시</span> :
																<span v-if="item.regiDatetime">{{
																	item.regiDatetime | moment('YYYY-MM-DD HH:mm')
																}}</span></span
															>
														</div>
														<div class="new_popTable1 posR">
															<table class="w100P mb20 complainTable">
																<tr>
																	<th class="txAnC">민원 내용</th>
																	<td>
																		<textarea
																			class="narrowScroll1"
																			style="width: calc(100% - 30px)"
																			v-model="item.complainContents"
																			:disabled="
																				(loadFromAppl &&
																					formStatus === 'disabled') ||
																				complain.regiStoreId !== storeSeq ||
																				index !==
																					prevComplainDetailList.length - 1
																			"
																			@input="
																				setComplainDetail('complainContents')
																			"
																		></textarea>
																	</td>
																</tr>
																<tr>
																	<th class="txAnC">민원 답변</th>
																	<td>
																		<template
																			v-if="storeVal === 'StoreGrade_M'"
																		>
																			<div
																				class="tempSelect ml8 mt5 posR"
																				style="width: calc(100% - 30px)"
																				v-if="
																					(complain &&
																						prevComplainDetailList &&
																						loadFromAppl &&
																						formStatus !== 'disabled') ||
																					index ===
																						prevComplainDetailList.length - 1
																				"
																			>
																				<v-select
																					v-if="storeVal === 'StoreGrade_M'"
																					:disabled="
																						(loadFromAppl &&
																							formStatus === 'disabled') ||
																						complain.complainType ===
																							'SR_COMPLAIN' ||
																						complain.complainType ===
																							'CALL_COMPLAIN' ||
																						index !==
																							prevComplainDetailList.length - 1
																					"
																					label="템플릿선택"
																					no-data-text="등록된 템플릿이 없습니다."
																					outlined
																					:items="templateList"
																					item-text="templateTitle"
																					item-value="complainTemplateId"
																					v-model="selectedComplainTemplatId"
																					@input="
																						setTemplateData(index, $event)
																					"
																					class="floatL"
																					:attach="true"
																				>
																				</v-select>
																				<button
																					v-if="storeVal === 'StoreGrade_M'"
																					class="boldWt borderRadi3Px w60 lh30 mainWhite ml10"
																					:class="{
																						backColorMint1:
																							selectedComplainTemplatId !== 0,
																						backColorGray1:
																							selectedComplainTemplatId === 0,
																					}"
																					@click="
																						selectedComplainTemplatId = 0;
																						selectedComplainTemplatId !== 0
																							? (item.complainAnswer = '')
																							: '';
																					"
																				>
																					선택 취소
																				</button>
																				<button
																					v-if="storeVal === 'StoreGrade_M'"
																					class="backColorBlue3 borderRadi3Px w100 lh30 mainWhite ml10 floatR"
																					@click="
																						openDialogFnc('templateMgmtPop')
																					"
																				>
																					템플릿관리
																				</button>
																			</div>
																		</template>
																		<textarea
																			class="narrowScroll1"
																			style="width: calc(100% - 30px)"
																			v-model="item.complainAnswer"
																			:disabled="
																				(loadFromAppl &&
																					formStatus === 'disabled') ||
																				index !==
																					prevComplainDetailList.length - 1
																			"
																			@change="
																				setComplainDetail('complainAnswer')
																			"
																		></textarea>
																	</td>
																</tr>
																<tr v-if="!retentionType">
																	<th class="txAnC">첨부파일</th>
																	<td>
																		<FileUpload3
																			fileInputId="fileInput3"
																			:class="{
																				pointEventNone:
																					index !==
																					prevComplainDetailList.length - 1,
																			}"
																			@fileValueFnc="fileValueFnc"
																			@deletePropFile="deletePropFile"
																			:parentFileData="propsComplainFileList"
																			:disableYN="
																				(loadFromAppl &&
																					formStatus === 'disabled') ||
																				index !==
																					prevComplainDetailList.length - 1
																			"
																		></FileUpload3>
																	</td>
																</tr>
																<tr v-if="!retentionType">
																	<th class="txAnC">민원 처리기한</th>
																	<td>
																		<span
																			style="
																				display: inline-block;
																				margin-right: 4px;
																				padding-left: 10px;
																				width: 140px;
																			"
																			:class="
																				(loadFromAppl &&
																					formStatus === 'disabled') ||
																				index !==
																					prevComplainDetailList.length - 1 ||
																				storeVal === 'StoreGrade_S'
																					? 'noPointEvent'
																					: ''
																			"
																		>
																			<v-menu
																				v-model.trim="menu5"
																				:close-on-content-click="false"
																				:nudge-top="467"
																				transition="scale-transition"
																				offset-y
																			>
																				<template
																					v-slot:activator="{ on, attrs }"
																				>
																					<v-text-field
																						outlined
																						v-model.trim="
																							item.preComplainHandlingEndDate
																						"
																						v-bind="attrs"
																						v-on="on"
																						readonly
																						append-icon="mdi-calendar"
																						@click:append="on.click"
																						class="w130"
																					></v-text-field>
																				</template>
																				<v-date-picker
																					v-model.trim="
																						item.preComplainHandlingEndDate
																					"
																					@input="menu5 = false"
																					locale="ko-KR"
																					@change="
																						setComplainDetail(
																							'preComplainHandlingEndDate',
																						)
																					"
																					:allowed-dates="getAllowedDates"
																				></v-date-picker>
																			</v-menu>
																		</span>
																		<span>
																			<vue-timepicker
																				close-on-complete
																				v-model.trim="
																					item.preComplainHandlingEndTime
																				"
																				@change="
																					setComplainDetail(
																						'preComplainHandlingEndTime',
																					)
																				"
																				:disabled="
																					(loadFromAppl &&
																						formStatus === 'disabled') ||
																					index !==
																						prevComplainDetailList.length - 1 ||
																					complain.regiStoreId !== storeSeq
																				"
																			></vue-timepicker>
																		</span>
																	</td>
																</tr>
															</table>
														</div>
													</div>
												</transition>
											</li>
										</ul>
									</div>
								</div>

								<button
									v-if="complain.openingStoreProcStatus === 'REJECT'"
									class="posRe w100P addNewVocBtn cur_p"
									@click="addNewComplain"
									:disabled="loadFromAppl && formStatus === 'disabled'"
								>
									+ 추가민원
								</button>
								<div class="posR new_popTable1" v-if="!retentionType">
									<h2 class="boldWt mainBlack2 mt10 mb10">민원 진행상태</h2>
									<table class="w100P complainTable">
										<tr>
											<th>영업점</th>
											<td colspan="3">
												<input
													id="REQ4"
													type="radio"
													name="saleStoreProcStatus1"
													v-model="complain.saleStoreProcStatus"
													value="REQ"
													:disabled="loadFromAppl && formStatus === 'disabled'"
												/><label for="REQ4"><em></em>요청</label>
												<input
													id="PROC4"
													type="radio"
													name="saleStoreProcStatus1"
													v-model="complain.saleStoreProcStatus"
													value="PROC"
													:disabled="loadFromAppl && formStatus === 'disabled'"
												/><label for="PROC4"><em></em>진행중</label>
												<input
													id="COMPL4"
													type="radio"
													name="saleStoreProcStatus1"
													v-model="complain.saleStoreProcStatus"
													value="COMPL"
													:disabled="loadFromAppl && formStatus === 'disabled'"
												/><label for="COMPL4"><em></em>완료</label>
											</td>
										</tr>
										<tr>
											<th :rowspan="rowSpan">개통점</th>
											<td colspan="3">
												<input
													id="REQ5"
													type="radio"
													name="openingStoreProcStatus1"
													v-model="complain.openingStoreProcStatus"
													:disabled="
														(loadFromAppl && formStatus === 'disabled') ||
														complain.regiStoreId !== storeSeq
													"
													value="REQ"
													@input="
														initStatusRelatedData('openingStoreProcStatus')
													"
												/><label for="REQ5"><em></em>요청</label>
												<input
													id="COMPL5"
													type="radio"
													name="openingStoreProcStatus1"
													v-model="complain.openingStoreProcStatus"
													:disabled="
														(loadFromAppl && formStatus === 'disabled') ||
														complain.regiStoreId !== storeSeq
													"
													value="COMPL"
													@input="
														initStatusRelatedData('openingStoreProcStatus')
													"
													@change="setCurrentTime('COMPL')"
												/><label for="COMPL5"><em></em>종결</label>
												<input
													id="REJECT5"
													type="radio"
													name="openingStoreProcStatus1"
													v-model="complain.openingStoreProcStatus"
													:disabled="
														(loadFromAppl && formStatus === 'disabled') ||
														complain.regiStoreId !== storeSeq
													"
													value="REJECT"
													@input="
														initStatusRelatedData('openingStoreProcStatus')
													"
													@change="setCurrentTime('REJECT')"
												/><label for="REJECT5"><em></em>반려</label>
												<input
													id="PRONOUNCE5"
													type="radio"
													name="openingStoreProcStatus1"
													v-model="complain.openingStoreProcStatus"
													:disabled="
														(loadFromAppl && formStatus === 'disabled') ||
														complain.regiStoreId !== storeSeq
													"
													value="PRONOUNCE"
													@input="
														initStatusRelatedData('openingStoreProcStatus')
													"
													@change="setCurrentTime('PRONOUNCE')"
												/><label for="PRONOUNCE5"><em></em>선고</label>
											</td>
										</tr>
										<template
											v-if="complain.openingStoreProcStatus === 'COMPL'"
										>
											<tr
												v-if="
													complain.openingStoreProcStatus === 'COMPL' ||
													complain.openingStoreProcStatus === 'REJECT'
												"
											>
												<td>
													<span
														style="
															display: inline-block;
															margin-right: 4px;
															padding-left: 10px;
															width: 130px;
														"
													>
														<v-menu
															v-model.trim="menu6"
															:close-on-content-click="false"
															:nudge-top="468"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	outlined
																	v-model.trim="complain.complDate"
																	v-bind="attrs"
																	v-on="on"
																	append-icon="mdi-calendar"
																	@click:append="on.click"
																	readonly
																	class="w130"
																></v-text-field>
															</template>
															<v-date-picker
																@input="menu6 = false"
																locale="ko-KR"
																v-model.trim="complain.complDate"
																:disabled="
																	(loadFromAppl && formStatus === 'disabled') ||
																	complain.regiStoreId !== storeSeq
																"
															></v-date-picker>
														</v-menu>
													</span>
													<span class="dropTimeUp">
														<vue-timepicker
															drop-direction="up"
															close-on-complete
															v-model.trim="complTime"
															:disabled="
																(loadFromAppl && formStatus === 'disabled') ||
																complain.regiStoreId !== storeSeq
															"
															@input="setComplain('complTime', 'complain')"
														></vue-timepicker>
													</span>
													<label for="responseAmt" style="margin-left: 12px"
														>민원처리금액</label
													>
													<input
														type="text"
														id="responseAmt"
														class="procAmtInput backColorWhite"
														oninput="this.value = this.value.replace(/[^0-9]/g, '');"
														v-model.trim="complain.complSaleStoreResponsAmt"
														placeholder="0"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/>원
												</td>
											</tr>
										</template>
										<template
											v-if="complain.openingStoreProcStatus === 'REJECT'"
										>
											<tr>
												<td>
													<span
														style="
															display: inline-block;
															margin-right: 4px;
															padding-left: 10px;
															width: 130px;
														"
													>
														<v-menu
															v-model.trim="menu6"
															:close-on-content-click="false"
															:nudge-top="468"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	outlined
																	v-model.trim="complain.rejectDate"
																	v-bind="attrs"
																	v-on="on"
																	append-icon="mdi-calendar"
																	@click:append="on.click"
																	readonly
																	class="w130"
																></v-text-field>
															</template>
															<v-date-picker
																@input="menu6 = false"
																locale="ko-KR"
																v-model.trim="complain.rejectDate"
																:disabled="
																	(loadFromAppl && formStatus === 'disabled') ||
																	complain.regiStoreId !== storeSeq
																"
															></v-date-picker>
														</v-menu>
													</span>
													<span class="dropTimeUp">
														<vue-timepicker
															close-on-complete
															v-model.trim="rejectTime"
															:disabled="
																(loadFromAppl && formStatus === 'disabled') ||
																complain.regiStoreId !== storeSeq
															"
															@input="setComplain('rejectTime', 'complain')"
														></vue-timepicker>
													</span>
												</td>
											</tr>
										</template>
										<template
											v-if="complain.openingStoreProcStatus === 'PRONOUNCE'"
										>
											<tr>
												<th>선고일시</th>
												<td colspan="3">
													<span
														style="
															display: inline-block;
															margin-right: 4px;
															padding-left: 10px;
															width: 130px;
														"
													>
														<v-menu
															v-model.trim="menu7"
															:close-on-content-click="false"
															:nudge-top="468"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	outlined
																	v-model.trim="complain.pronounceDate"
																	v-bind="attrs"
																	v-on="on"
																	readonly
																	append-icon="mdi-calendar"
																	@click:append="on.click"
																	class="w130"
																></v-text-field>
															</template>
															<v-date-picker
																v-model.trim="complain.pronounceDate"
																@input="menu7 = false"
																locale="ko-KR"
															></v-date-picker>
														</v-menu>
													</span>
													<span>
														<vue-timepicker
															close-on-complete
															v-model.trim="complain.pronounceTime"
															:disabled="
																(loadFromAppl && formStatus === 'disabled') ||
																complain.regiStoreId !== storeSeq
															"
														></vue-timepicker>
													</span>
												</td>
											</tr>
											<tr>
												<th>민원처리금액</th>
												<td colspan="3">
													<input
														type="text"
														class="procAmtInput"
														v-model.number="complain.complainProcAmt"
														oninput="this.value = this.value.replace(/[^0-9]/g, '');"
														placeholder="0"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
													/>원
												</td>
											</tr>
											<tr>
												<th>개통점 부담금</th>
												<td style="padding-left: 10px">
													금액
													<input
														type="text"
														oninput="this.value = this.value.replace(/[^0-9]/g, '');"
														class="procAmtInput"
														v-model.number="complain.openingStoreResponsAmt"
														placeholder="0"
														:disabled="
															(loadFromAppl && formStatus === 'disabled') ||
															complain.regiStoreId !== storeSeq
														"
														@input="abstractProcAmt"
														@click="alertFnc('chkComplainProcAmt')"
													/>원
													<input
														type="text"
														style="
															width: 50px;
															margin-right: 4px;
															margin-left: 10px;
															text-align: right;
															padding: 0 6px;
															background: #f5f5f5;
														"
														v-model.number="openingStoreResponsAmt"
														placeholder="0"
														disabled
													/>%
												</td>
												<th>영업점 부담금</th>
												<td style="padding-left: 10px">
													금액
													<input
														type="text"
														oninput="this.value = this.value.replace(/[^0-9]/g, '');"
														class="procAmtInput"
														v-model.number="this.complain.saleStoreResponsAmt"
														placeholder="0"
														disabled
													/>원
													<input
														type="text"
														style="
															width: 50px;
															margin-right: 4px;
															margin-left: 10px;
															text-align: right;
															padding: 0 6px;
															background: #f5f5f5;
														"
														v-model.number="saleStoreResponsPercent"
														placeholder="0"
														disabled
													/>%
												</td>
											</tr>
											<tr>
												<th>첨부파일</th>
												<td colspan="3">
													<FileUpload3
														fileInputId="fileInput4"
														@fileValueFnc="fileValueFnc"
														@deletePropFile="deletePropFile"
														:parentFileData="propsPronounceFileList"
														:disableYN="
															loadFromAppl && formStatus === 'disabled'
														"
													></FileUpload3>
												</td>
											</tr>
										</template>
									</table>
								</div>
								<div
									class="buttonCont justify-space-between disFx w100P"
									v-if="!retentionType"
								>
									<div>
										<button
											class="backColorRed"
											@click="deleteComplain()"
											v-if="st === '3b14c9d61a1ce5782698616844edc9ee'"
										>
											삭제
										</button>
									</div>
									<div>
										<button
											class="backColorMint1"
											@click="submit(false)"
											:disabled="loadFromAppl && formStatus === 'disabled'"
											:class="{
												backColorGray1:
													loadFromAppl && formStatus === 'disabled',
											}"
										>
											수정
										</button>
										<button class="backColorGray1" @click="dialog = false">
											닫기
										</button>
									</div>
								</div>
							</div>
						</v-card-text>
					</div>
				</v-card>
			</v-dialog>
		</div>
		<template-mgmt-pop
			v-if="templateMgmtPop"
			:popComplainType="complain.complainType"
			@getComplainTemplateRecipList="getComplainTemplateRecipList"
			@closeDialog="closeDialog"
		></template-mgmt-pop>
	</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker';
import FileUpload3 from '../../../../components/FileUpload3';
import { getComplainList } from '../../../../api/comm-mgmt/complain-hndlg/ComplainHndlgApi';
import { nullValidation2, getToday, getTime } from '../../../../common/common';
import numberSplit from '../../../../common/numberSplit';
import TemplateMgmtPop from '../popup/TemplateMgmtPop';
import bus from '@/common/bus';

export default {
	name: 'ComplainInfoPop',
	mixins: [numberSplit],
	props: {
		loadFromAppl: { type: Boolean, default: false },
		loadFromNotice: { type: Boolean, default: false },
		loadFromRnbNotice: { type: Boolean, default: false },
		retentionType: { type: Boolean, default: false },
		applId: { type: Number },
		complainId: { type: Number, default: 0 },
	},
	components: {
		FileUpload3,
		VueTimepicker,
		TemplateMgmtPop,
	},
	data: () => ({
		endDateArr: [],
		dialog: true,
		updated: false,
		newComplainYN: false,
		formStatus: 'disabled',
		complainTemplateId: 0,
		selectedComplainId: 0,
		selectedHistoryLiId: '',
		activeComplain: 'historyLi1',
		complTime: {},
		rejectTime: {},
		menu1: false,
		menu2: false,
		menu3: false,
		menu4: false,
		menu5: false,
		menu6: false,
		menu7: false,
		complain: {
			regiDate: '',
			openingDate: '',
			complDate: '',
			rejectDate: '',
			pronounceDate: '',
		},
		complainDetail: {},
		saleStoreList: [],
		openingStoreList: [],
		complainList: [],
		prevComplainDetailList: [
			{
				complainAnswer: '',
				complainContents: '',
				complainId: 0,
				complainTemplateId: 0,
				preComplainHandlingEndDate: '',
				preComplainHandlingEndTime: '',
			},
		],
		templateList: [],
		selectedComplainTemplatId: 0,
		selectedOpeningStore: {},
		complainFileList: [],
		pronounceFileList: [],
		propsComplainFileList: [],
		propsPronounceFileList: [],
		complainNewFileYn: false,
		pronounceNewFileYn: false,
		templateMgmtPop: false,
	}),

	computed: {
		storeVal() {
			return this.$store.state.cookies.sg;
		},
		storeSeq() {
			return Number(this.$store.state.cookies.ss);
		},
		st() {
			return this.$store.state.cookies.st;
		},
		filterData() {
			return this.$store.state.ComplainHndlgModule.filterData;
		},
		dialogWidth() {
			if (this.loadFromAppl) {
				return 1200;
			} else {
				return 800;
			}
		},
		/*	openingStoreProcStatusMsg() {
			if (this.complain.openingStoreProcStatus === 'REQ') {
				return '요청';
			} else if (this.complain.openingStoreProcStatus === 'COMPL') {
				return '종결';
			} else if (this.complain.openingStoreProcStatus === 'REJECT') {
				return '반려';
			} else if (this.complain.openingStoreProcStatus === 'PRONOUNCE') {
				return '선고';
			}
			return '';
		},*/
		rowSpan() {
			if (
				this.complain.openingStoreProcStatus === 'REQ' ||
				this.complain.openingStoreProcStatus === 'PRONOUNCE'
			) {
				return 1;
			} else {
				return 2;
			}
		},
		saleStoreResponsPercent() {
			let percent =
				(this.complain.saleStoreResponsAmt / this.complain.complainProcAmt) *
				100;
			if (isNaN(percent) || !isFinite(percent) || percent < 0) {
				return 0;
			} else {
				return percent.toFixed(1);
			}
		},
		openingStoreResponsAmt() {
			let percent =
				(this.complain.openingStoreResponsAmt / this.complain.complainProcAmt) *
				100;
			if (isNaN(percent) || !isFinite(percent) || percent < 0) {
				return 0;
			} else {
				return percent.toFixed(1);
			}
		},
	},
	methods: {
		async deletePropFile(data) {
			if (!confirm('삭제하시겠습니까?')) {
				return;
			}
			let deleteData = this[data.fileList][data.index];
			console.log(deleteData);
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/deleteComplainAttachMgmt',
				deleteData,
			);
			if (result) {
				if (data.fileList === 'propsComplainFileList') {
					await this.getComplainAttachMgmtList(
						this.complainDetail.complainDetailId,
					);
				} else {
					await this.getComplainAttachMgmtList(
						this.complainDetail.complainDetailId,
						this.complain.complainId,
					);
				}
			}
		},
		fileValueFnc(data) {
			this[data.yn] = true;
			this[data.name] = data.fileData;
		},
		alertFnc(type) {
			if (type === 'chkComplainProcAmt') {
				if (
					this.complain.complainProcAmt === 0 ||
					this.complain.complainProcAmt === null
				) {
					alert('민원처리금액을 먼저 입력해주세요.');
				}
			}
		},
		abstractProcAmt() {
			if (this.openingStoreResponsAmt > 100) {
				alert('개통점 부담금을 민원처리금액보다 적게 입력해 주세요.');
				return;
			}
			this.complain.saleStoreResponsAmt =
				this.complain.complainProcAmt - this.complain.openingStoreResponsAmt;
		},
		pronounceFileValueFnc(value) {
			this.pronounceNewFileYn = true;
			this.pronounceFileList = value;
			let asb = new URLSearchParams();
		},
		async editComplainDetail(index) {
			this.selectedHistoryLiId = `complHistoryLi${index}`;
			this.selectedComplainId = this.complainList[index].complainId;
			this.formStatus = 'edit';
			await this.getComplainDetail(this.complainList[index].complainId);
			await this.getComplainAttachMgmtList(
				this.complainDetail.complainDetailId,
				this.complainId,
			);
			this.activeComplain = `historyLi${this.prevComplainDetailList.length}`;
			await this.getSaleStoreList();
			await this.getOpeningStoreList();
			if (
				this.complain.complainType === 'FOREIGN_COMPLAIN' ||
				this.complain.complainType === 'VOICE_COMPLAIN'
			) {
				await this.getComplainTemplateRecipList(this.complain.complainType);
			}
		},
		openApplWindow(applId) {
			let data = {
				consultType: 'NONE',
				cnsltId: 0,
				applId: applId,
			};
			bus.$emit('todoDialogBus', data);
			// window.open(
			// 	`/application-form-creation?applId=${applId}`,
			// 	'_blank',
			// 	'width=1650,height=1000,scrollbars=yes',
			// );
			// document.getElementById('newWindowDrawerYn').value = applId;
			// setTimeout(
			// 	() => (document.getElementById('newWindowDrawerYn').value = ''),
			// 	1000,
			// );
		},
		async activateComplain(index) {
			if (this.newComplainYN) {
				if (confirm('작성중인 추가민원이 삭제됩니다. 진행하시겠습니까?')) {
					this.prevComplainDetailList.pop();
					this.newComplainYN = false;
				} else {
					return;
				}
			}
			this.propsComplainFileList = [];
			await this.getComplainAttachMgmtList(
				this.prevComplainDetailList[index].complainDetailId,
			);
			this.activeComplain = `historyLi${index + 1}`;
		},
		initOnChange(changedData) {
			if (changedData === 'saleStoreId') {
				this.selectedOpeningStore = {};
				this.complain.openingStoreId = 0;
				this.getOpeningStoreList();
			}
		},
		setCurrentTime(type) {
			let currentDate = getToday();
			let currentTime = getTime();
			if (type === 'COMPL') {
				this.complain.complDate = currentDate;
				this.complTime.HH = currentTime.substring(0, 2);
				this.complTime.mm = currentTime.substring(3, 5);
				this.complain.complTime = currentTime;
			} else if (type === 'REJECT') {
				this.complain.rejectDate = currentDate;
				this.rejectTime.HH = currentTime.substring(0, 2);
				this.rejectTime.mm = currentTime.substring(3, 5);
				this.complain.rejectTime = currentTime;
			} else if (type === 'PRONOUNCE') {
				this.complain.pronounceDate = currentDate;
				this.complain.pronounceTime = currentTime;
			}
		},
		initStatusRelatedData(name) {
			if (name === 'SR_COMPLAIN' || name === 'CALL_COMPLAIN') {
				this.templateList = [];
				this.initComplainTemplateId('complainDetail');
				this.initComplainTemplateId('prevComplainDetailList');
			}
			if (name === 'openingStoreProcStatus') {
				this.complTime = {
					HH: '',
					mm: '',
				};
				this.rejectTime = {
					HH: '',
					mm: '',
				};
				this.complain.complDate = '';
				this.complain.complTime = '';
				this.complain.complSaleStoreResponsAmt = 0;
				this.complain.rejectDate = '';
				this.complain.rejectTime = '';
				this.complain.pronounceDate = '';
				this.complain.pronounceTime = '';
				this.complain.openingStoreResponsAmt = 0;
				this.complain.saleStoreResponsAmt = 0;
				this.complain.complainProcAmt = 0;
				this.complTime = {
					HH: '',
					mm: '',
				};
				this.rejectTime = {
					HH: '',
					mm: '',
				};
			}
		},
		setComplain(dataName, parentName) {
			let time = '';
			for (let i in this[dataName]) {
				time = time + this[dataName][i];
				if (i === 'HH') {
					time = time + ':';
				}
			}
			if (time === ':') {
				this[parentName][dataName] = '';
			} else {
				this[parentName][dataName] = time;
			}
		},
		// html구조상 this.prevComplainDetailList 와 데이터 바인딩 되어있다보니
		// this.complainDetail에 동기화를 해주는 fnc
		setComplainDetail(dataName) {
			/*if (dataName === 'complainTemplateId') {
				this.complainDetail.complainTemplateId = this.prevComplainDetailList[
				this.prevComplainDetailList.length - 1
					].complainTemplateId;
			} else*/
			if (dataName === 'complainContents') {
				this.complainDetail.complainContents =
					this.prevComplainDetailList[
						this.prevComplainDetailList.length - 1
					].complainContents;
			} else if (dataName === 'complainAnswer') {
				this.complainDetail.complainAnswer =
					this.prevComplainDetailList[
						this.prevComplainDetailList.length - 1
					].complainAnswer;
			} else if (dataName === 'preComplainHandlingEndDate') {
				this.complainDetail.preComplainHandlingEndDate =
					this.prevComplainDetailList[
						this.prevComplainDetailList.length - 1
					].preComplainHandlingEndDate;
			} else if (dataName === 'preComplainHandlingEndTime') {
				this.complainDetail.preComplainHandlingEndTime =
					this.prevComplainDetailList[
						this.prevComplainDetailList.length - 1
					].preComplainHandlingEndTime;
			}
		},
		setParentHierarchy() {
			this.complain.openingStoreId = this.selectedOpeningStore.openStoreId;
			this.complain.parentHierarchy = this.selectedOpeningStore.parentHierarchy;
		},
		setTemplateData(index, id) {
			let item = this.templateList.find(
				(item) => item.complainTemplateId === id,
			);
			if (
				this.prevComplainDetailList[this.prevComplainDetailList.length - 1]
					.complainAnswer.length > 0
			) {
				if (!confirm('기존 민원답변을 지우시겠습니까?')) {
					this.prevComplainDetailList[index].complainTemplateId =
						this.complainDetail.complainTemplateId;
					this.selectedComplainTemplateId =
						this.complainDetail.complainTemplateId;
					return;
				}
			}
			this.prevComplainDetailList[index].complainAnswer = item.templateContents;
			this.prevComplainDetailList[index].complainTemplateId =
				item.complainTemplateId;
			this.complainDetail.complainAnswer = item.templateContents;
			this.complainDetail.complainTemplateId = item.complainTemplateId;
		},
		async addNewComplain() {
			if (
				this.prevComplainDetailList[this.prevComplainDetailList.length - 1]
					.lastYn !== 'Y'
			) {
				alert('이미 추가된 민원이 있습니다.');
				return;
			}
			if (!nullValidation2(this.complainDetail, 'complainContents')) {
				return;
			}
			this.newComplainYN = true;
			this.complain.saleStoreProcStatus = 'REQ';
			this.complain.openingStoreProcStatus = 'REQ';
			await this.submit(true);
			let newComplain = {
				complainAnswer: '',
				complainContents: '',
				complainId: this.selectedComplainId,
				complainTemplateId: 0,
				preComplainHandlingEndDate: '',
				preComplainHandlingEndTime: '',
			};
			this.selectedComplainTemplatId = null;
			this.complainFileList = [];
			this.propsComplainFileList = [];
			this.complainDetail = { ...newComplain };
			this.prevComplainDetailList.push(newComplain);
			this.activeComplain = `historyLi${this.prevComplainDetailList.length}`;
			/*	let result = await this.insertComplainDetail(newComplain);
			if (result && '0000' === result.data.resultCode) {
				this.newComplainYN = true;
				this.complainDetail = { ...newComplain };
				this.prevComplainDetailList.push(newComplain);
				this.activeComplain = `historyLi${this.prevComplainDetailList.length}`;
			}*/
		},
		validTime() {
			if (
				this.complain.complainStartTime &&
				(this.complain.complainStartTime.indexOf('HH') >= 0 ||
					this.complain.complainStartTime.indexOf('mm') >= 0)
			) {
				alert('민원 시작시간을 다시 입력해주세요.');
				return false;
			}
			if (
				typeof this.complainDetail.preComplainHandlingEndTime === 'string' &&
				(this.complainDetail.preComplainHandlingEndTime.indexOf('HH') >= 0 ||
					this.complainDetail.preComplainHandlingEndTime.indexOf('mm') >= 0)
			) {
				alert('민원 처리시간을 다시 입력해주세요.');
				return false;
			} else if (
				typeof this.complainDetail.preComplainHandlingEndTime !== 'string'
			) {
				if (
					this.complainDetail.preComplainHandlingEndTime.HH === '' ||
					this.complainDetail.preComplainHandlingEndTime.mm === ''
				) {
					alert('민원 처리시간을 다시 입력해주세요.');
					return false;
				}
				if (
					this.complainDetail.preComplainHandlingEndTime.HH !== '' &&
					this.complainDetail.preComplainHandlingEndTime.mm !== ''
				) {
					this.complainDetail.preComplainHandlingEndTime =
						this.complainDetail.preComplainHandlingEndTime.HH +
						':' +
						this.complainDetail.preComplainHandlingEndTime.mm;
				}
			}

			if (
				this.complain.openingStoreProcStatus === 'COMPL' &&
				this.complain.complTime.length < 5
			) {
				alert('민원종결시간을 다시 입력해주세요.');
				return false;
			}
			if (
				this.complain.openingStoreProcStatus === 'REJECT' &&
				this.complain.rejectTime.length < 5
			) {
				alert('민원반려시간을 다시 입력해주세요.');
				return false;
			}
			if (
				this.complain.openingStoreProcStatus === 'PRONOUNCE' &&
				(this.complain.pronounceTime.indexOf('HH') >= 0 ||
					this.complain.pronounceTime.indexOf('mm') >= 0)
			) {
				alert('민원선고시간을 다시 입력해주세요.');
				return false;
			}
			return true;
		},
		async submit(skipConfirm) {
			let complainValid = [
				'regiName',
				'regiDate',
				'saleStoreId',
				'openingStoreId',
				'complainPath',
				'complainType',
				'complainStartDate',
				'complainStartTime',
				'complainHandlingEndDate',
				'complainHandlingEndTime',
				'cusName',
				'cusBirthDate',
				'openingPhone1',
				'openingPhone2',
				'openingPhone3',
				'preComplainHandlingEndDate',
				'preComplainHandlingEndTime',
				'saleStoreProcStatus',
				'openingStoreProcStatus',
			];
			if (!nullValidation2(this.complain, complainValid)) {
				return;
			}
			let complainDetailValid = [
				'complainContents',
				'preComplainHandlingEndDate',
				'preComplainHandlingEndTime',
			];
			if (!nullValidation2(this.complainDetail, complainDetailValid)) {
				return;
			}
			let complainValid2 = [];
			if (this.complain.openingStoreProcStatus === 'COMPL') {
				complainValid2.push('complDate', 'complTime');
			}
			if (this.complain.openingStoreProcStatus === 'REJECT') {
				complainValid2.push('rejectDate', 'rejectTime');
			}
			if (!nullValidation2(this.complain, complainValid2)) {
				return;
			}
			// 시간 format 하기
			if (!this.validTime()) {
				return;
			}
			// 민원 시작일이 민원 처리기한보다 이전일 수 있도록 하는 fnc
			// this.isStartTimeFasterThanEndTime();
			if (
				this.isEndTimeLaterThanStartTime(
					`${this.complain.complainStartDate} ${this.complain.complainStartTime}`,
					`${this.complainDetail.preComplainHandlingEndDate} ${this.complainDetail.preComplainHandlingEndTime}`,
				)
			) {
				return;
			}
			if (this.complain.openingStoreProcStatus === 'PRONOUNCE') {
				complainValid2.push('pronounceDate', 'pronounceTime');
				if (this.openingStoreResponsAmt > 100) {
					alert('개통점 부담금을 민원처리금액보다 적게 입력해 주세요.');
					return;
				}
				if (!nullValidation2(this.complain, complainValid2)) {
					return;
				}
				if (
					!nullValidation2(this.complain, [
						'complainProcAmt',
						'openingStoreResponsAmt',
					])
				) {
					return;
				}
			}
			if (!skipConfirm) {
				if (!confirm('수정하시겠습니까?')) {
					return;
				}
			}
			this.updated = true;
			let data = {
				complainDto: this.complain,
				complainDetailDto: this.complainDetail,
			};

			// 22.01.19 종결로 수정 시 긴급요청 해제 자동 처리
			if (data.complainDto.openingStoreProcStatus === 'COMPL') {
				data.complainDto.emergencyYn = 'N';
			}

			if (
				this.newComplainYN &&
				this.prevComplainDetailList[this.prevComplainDetailList.length - 1]
					.lastYn === undefined
			) {
				let insertResult = await this.insertComplainDetail(this.complainDetail);
				if (insertResult && '0000' === insertResult.data.resultCode) {
					this.complain.complainDetailId = insertResult.data.data;
					data.complainDetailDto.complainDetailId =
						this.complain.complainDetailId;
					this.newComplainYN = false;
					//
					await this.updateComplainAttachMgmt(this.complain.complainDetailId);
				}
			}
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/updateComplain',
				data,
			);
			if (result && '0000' === result.data.resultCode) {
				if (
					(this.complainNewFileYn && this.complainFileList.length !== 0) ||
					(this.pronounceNewFileYn && this.pronounceFileList.length !== 0)
				) {
					let lastResult = await this.updateComplainAttachMgmt(
						this.prevComplainDetailList[this.prevComplainDetailList.length - 1]
							.complainDetailId,
					);
					if (lastResult && '0000' === lastResult.data.resultCode) {
						alert('수정되었습니다.');
						this.complainNewFileYn = false;
						this.pronounceNewFileYn = false;
					}
				} else {
					if (!this.newComplainYN) {
						alert('수정되었습니다.');
					}
				}
				await this.getComplainDetail(this.selectedComplainId);
				await this.getComplainAttachMgmtList(
					this.complainDetail.complainDetailId,
					this.complain.complainId,
				);
				if (this.loadFromAppl) {
					await this.getComplainListFnc();
				}
			}
		},
		// 민원 리스트 조회 (applID로 조회)
		async getComplainListFnc() {
			let data;
			if (this.applId) {
				data = {
					applId: this.applId,
				};
			}
			this.complainList = [];
			let result = await getComplainList(data);
			if (result && '0000' === result.data.resultCode) {
				if (result.data.data.resultList.length !== 0) {
					this.complainList = result.data.data.resultList;
				}
			}
		},
		// 민원 상세 조회 (complainId로 조회)
		async getComplainDetail(id) {
			this.complain = [];
			this.complainDetail = [];
			this.prevComplainDetailList = [];
			let data = {
				complainId: id,
			};
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainDetail',
				data,
			);
			if (result) {
				this.complain = result.data.data.complain;
				this.complainDetail = result.data.data.complainDetail;

				this.prevComplainDetailList = result.data.data.prevComplainDetailList;
				this.prevComplainDetailList.map((item) => {
					if (item.complainAnswer !== null && item.complainAnswer !== '') {
						item.complainAnswer = item.complainAnswer
							.replace(/&amp;/g, '&')
							.replace(/&lt;/g, '<')
							.replace(/&gt;/g, '>')
							.replace(/&quot;/g, '"');
					}
					item.complainAnswer =
						item.complainAnswer === null ? '' : item.complainAnswer;
					if (item.complainContents !== null && item.complainContents !== '') {
						item.complainContents = item.complainContents
							.replace(/&amp;/g, '&')
							.replace(/&lt;/g, '<')
							.replace(/&gt;/g, '>')
							.replace(/&quot;/g, '"');
					}
				});
				// 계산식 오류 예외처리를 위해 0 으로 셋팅
				if (this.complain.openingStoreResponsAmt === null) {
					this.complain.openingStoreResponsAmt = 0;
				}
				if (this.complain.saleStoreResponsAmt === null) {
					this.complain.saleStoreResponsAmt = 0;
				}
				// vue-timepicker가 rejectTime, complTime 은 희한하게도 object로 저장되어 예외처리 ^^..
				if (
					this.complain.openingStoreProcStatus === 'COMPL' &&
					this.complain.complTime !== ''
				) {
					let complTime = this.complain.complTime.split(':');
					this.complTime.HH = complTime[0];
					this.complTime.mm = complTime[1];
				}
				if (
					this.complain.openingStoreProcStatus === 'REJECT' &&
					this.complain.rejectTime !== ''
				) {
					let rejectTime = this.complain.rejectTime.split(':');
					this.rejectTime.HH = rejectTime[0];
					this.rejectTime.mm = rejectTime[1];
				}
				this.selectedComplainId = this.complain.complainId;
				this.activeComplain = `historyLi${this.prevComplainDetailList.length}`;
			}
		},
		async setNewTemplateRecipList(type) {
			this.initComplainTemplateId('selected');
			this.initComplainTemplateId('complainDetail');
			this.initComplainTemplateId('prevComplainDetailList');
			await this.getComplainTemplateRecipList(type);
		},
		// 민원 템플릿 리스트 조회
		async getComplainTemplateRecipList(type) {
			this.templateList = [];
			let data;
			if (type) {
				data = { templateType: type };
			}
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainTemplateRecipList',
				data,
			);
			this.templateList = [];
			if (result && '0000' === result.data.resultCode) {
				this.templateList = result.data.data;
			}
		},
		initComplainTemplateId(type) {
			if (type === 'selected') {
				this.selectedComplainTemplateId = 0;
			} else if (type === 'complainDetail') {
				this.complainDetail.complainTemplateId = 0;
			} else if (type === 'prevComplainDetailList') {
				this.prevComplainDetailList[
					this.prevComplainDetailList.length - 1
				].complainTemplateId = 0;
			}
		},
		// 파일리스트 조회(complainFileList, pronounceFileList)
		async getComplainAttachMgmtList(detailId, complainId) {
			if (complainId && this.complain.openingStoreProcStatus === 'PRONOUNCE') {
				let data = {
					attachType: 'COMPLAIN_PRONOUNCE',
					subId: complainId,
				};
				this.propsPronounceFileList = [];
				let result = await this.$store.dispatch(
					'ComplainHndlgModule/getComplainAttachMgmtList',
					data,
				);
				if (result && '0000' === result.data.resultCode) {
					this.propsPronounceFileList = result.data.data.resultList;
				}
			}
			let data = {
				attachType: 'COMPLAIN_DETAIL',
				subId: detailId,
			};
			this.propsComplainFileList = [];
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainAttachMgmtList',
				data,
			);
			if (result && '0000' === result.data.resultCode) {
				this.propsComplainFileList = result.data.data.resultList;
			}
		},
		async getSaleStoreList() {
			let saleStoreList = await this.$store.dispatch(
				'ComplainHndlgModule/getSaleStoreList',
			);
			if (saleStoreList) {
				this.saleStoreList = saleStoreList;
			}
		},
		async getOpeningStoreList() {
			let data = {
				storeId: this.complain.saleStoreId,
			};
			this.openingStoreList = [];
			let openingStoreList = await this.$store.dispatch(
				'ComplainHndlgModule/getOpeningStoreList',
				data,
			);
			if (openingStoreList) {
				this.openingStoreList = openingStoreList;
			}
			this.selectedOpeningStore.openStoreId = this.complain.openingStoreId;
		},
		async insertComplainDetail(data) {
			return await this.$store.dispatch(
				'ComplainHndlgModule/insertComplainDetail',
				data,
			);
		},
		async updateComplain() {
			let data;
			if (this.applId) {
				data = {
					applId: this.applId,
				};
			}
			this.complainList = [];
			let result = await getComplainList(data);
			if (result && '0000' === result.data.resultCode) {
				if (result.data.data.resultList.length !== 0) {
					this.complainList = result.data.data.resultList;
				}
			}
		},
		async updateComplainAttachMgmt(subId) {
			if (this.complainNewFileYn) {
				if (this.complainFileList.length !== 0) {
					let formData = new FormData();
					formData.append('subId', subId);
					for (let i = 0; i < this.complainFileList.length; i++) {
						formData.append('attachFile', this.complainFileList[i]);
						formData.append('attachType', 'COMPLAIN_DETAIL');
					}
					// 파일 이력
					let result = await this.$store.dispatch(
						'ComplainHndlgModule/updateComplainAttachMgmt',
						formData,
					);
					if (result && '0000' === result.data.resultCode) {
						this.complainNewFileYn = false;
						this.complainFileList = [];
						this.$store.commit('ComplainHndlgModule/initComplainFileList');
					}
				}
			}
			if (this.pronounceNewFileYn) {
				if (this.pronounceFileList.length !== 0) {
					let formData = new FormData();
					formData.append('subId', this.complain.complainId);
					for (let i = 0; i < this.pronounceFileList.length; i++) {
						formData.append('attachFile', this.pronounceFileList[i]);
						formData.append('attachType', 'COMPLAIN_PRONOUNCE');
					}
					// 파일 이력
					let result = await this.$store.dispatch(
						'ComplainHndlgModule/updateComplainAttachMgmt',
						formData,
					);
					if (result && '0000' === result.data.resultCode) {
						this.pronounceNewFileYn = false;
						this.pronounceFileList = [];
						this.$store.commit('ComplainHndlgModule/initPronounceFileList');
					}
				}
			}
		},
		getAllowedDates(val) {
			return (
				val >=
				new Date(this.complain.complainStartDate).toISOString().substr(0, 10)
			);
		},
		// getAllowedDates2(val) {
		// 	return val <= new Date(this.endDateArr[0]).toISOString().substr(0, 10);
		// },
		isStartTimeFasterThanEndTime() {
			this.endDateArr = this.prevComplainDetailList;
			this.endDateArr = this.endDateArr.map(function (obj) {
				return obj.preComplainHandlingEndDate;
			});
			if (this.endDateArr.length !== 1) {
				this.endDateArr.sort(function (a, b) {
					if (a > b) {
						return 1;
					}
					if (a < b) {
						return -1;
					}
					return 0;
				});
			}
		},
		isEndTimeLaterThanStartTime(start, end) {
			let _start = new Date(start).getTime();
			let _end = new Date(end).getTime();
			if (_start > _end) {
				alert('민원 처리기한을 민원 시작일시 이후로 설정하시기 바랍니다.');
				return true;
			}
		},
		openDialogFnc(dialogName, id) {
			if (dialogName === 'complainInfoPop') {
				this.complainId = id;
			}
			this[dialogName] = true;
		},
		closeDialog(data, dialogName) {
			this.$set(this, dialogName, data);
		},
		async deleteComplain() {
			if (!confirm('삭제하시겠습니까?')) {
				return;
			}
			let param = [this.complainId];

			let result = await this.$store.dispatch(
				'ComplainHndlgModule/deleteComplain',
				param,
			);
			if (result) {
				await this.$store.dispatch(
					'ComplainHndlgModule/getComplainList',
					this.filterData,
				);
				this.dialog = false;
			}
		},
	},
	watch: {
		dialog: {
			deep: true,
			handler: function (newVal) {
				if (!newVal) {
					if (this.updated && this.loadFromNotice) {
						this.$emit(
							'closeDialog',
							this.dialog,
							'complainInfoPop',
							'updated',
						);
					} else {
						this.$emit('closeDialog', this.dialog, 'complainInfoPop');
						if (
							this.updated &&
							!this.loadFromNotice &&
							!this.loadFromRnbNotice
						) {
							this.$store.dispatch(
								'ComplainHndlgModule/getComplainList',
								this.filterData,
							);
						}
						if (this.updated && this.loadFromRnbNotice) {
							this.$store.dispatch('NoticeModule/getNotiRnbList', {
								notiTargetType: 'RNB',
							});
						}
					}
				}
			},
		},
	},
	async created() {
		if (this.loadFromAppl) {
			await this.getComplainListFnc();
			if (this.complainList.length !== 0) {
				await this.editComplainDetail('0');
			}
		} else {
			await this.getComplainDetail(this.complainId);
			await this.getComplainAttachMgmtList(
				this.complainDetail.complainDetailId,
				this.complainId,
			);
			await this.getSaleStoreList();
			await this.getOpeningStoreList();
		}
		if (
			this.complain.complainType === 'FOREIGN_COMPLAIN' ||
			this.complain.complainType === 'VOICE_COMPLAIN'
		) {
			await this.getComplainTemplateRecipList(this.complain.complainType);
			this.initComplainTemplateId('selected');
			this.initComplainTemplateId('complainDetail');
			this.initComplainTemplateId('prevComplainDetailList');
		}
		// this.isStartTimeFasterThanEndTime();
	},
};
</script>

<style scoped>
.alert {
	float: left;
	transform: translateY(2px);
}
.complainInfoPop .checkStyleCont10 label:before {
	left: -10px !important;
}
.complainInfoPop table td input[type='text'] {
	padding: 0 10px !important;
}
.complainCont {
	display: flex;
	position: relative;
	flex-direction: row;
}
.complainLCont {
	background: #fbfbfb;
	position: sticky;
	top: 46px;
	width: 480px;
	max-height: 795px;
	overflow-y: auto;
	padding: 10px 16px !important;
}
.complainLCont > div {
	overflow-y: auto;
}

.complainLCont span {
	font-size: 11px;
}
.complainLContLi {
	margin-top: 10px;
}
.complainLContLi > div {
	padding: 2px 8px;
	border-top: 1px solid #d8d8d8;
}
.complainLContLi > div:first-child {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	border-top: 0;
}
.complainLContLi table td {
	font-size: 11px;
}
.complainLContLi table td span {
	font-weight: bold;
	font-size: 11px;
}
.checkStyleCont10 label {
	padding: 4px 0;
}
.complainRegiPop table input[type='text'] {
	width: 200px;
	height: 32px;
}
.complainRegiPop table tr {
	height: 40px;
}
.complainRegiPop table th {
	background-color: #fbfbfb;
	width: 130px;
}
.complainRegiPop .v-input {
	width: 200px;
	font-size: 13px !important;
}
.complainRegiPop table td {
	padding-left: 8px;
}
.complainRegiPop table input,
.complainRegiPop table textarea {
	border: 1px solid rgba(52, 52, 52, 0.3);
	border-radius: 4px;
	outline: none;
	margin-left: 10px;
}
.complainRegiPop label,
.complainRegiPop label {
	cursor: pointer;
	padding: 2px 8px;
}
.complainRegiPop table textarea {
	height: 130px;
	width: 90%;
	resize: none;
	margin-top: 8px !important;
}

.complainInfoPop textarea {
	height: 250px !important;
	padding: 2px 10px;
}
.buttonCont {
	float: right;
	margin: 20px 0;
}
.complainRegiPop .buttonCont button {
	color: #fff;
	width: 72px;
	height: 35px;
	border-radius: 3px;
}
.complainRegiPop .buttonCont button:not(first-child) {
	margin-left: 8px;
}
.vocHistoryUl {
	border-top: 1px solid rgba(0, 0, 0, 0.3);
}
.vocHistoryUl li {
	width: 100%;
	padding: 8px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.vocHistoryUl li span:first-child {
	display: inline-block;
	color: #000;
}
.addNewVocBtn {
	background-color: #e0effc;
	margin-top: 10px;
	padding: 10px 0;
	color: #288ce9;
	font-weight: 600;
	border: 2px solid #b3cce2;
	border-radius: 3px;
}
.procAmtInput {
	width: 90px !important;
	margin-right: 4px;
	text-align: right;
	padding: 0 6px;
}
.pointEventNone {
	pointer-events: none;
}
.selectedLiBorder {
	border: 3px solid #007ef2 !important;
	border-radius: 5px;
}
.noPointEvent {
	pointer-events: none;
}
</style>
