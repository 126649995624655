import { instance } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

// 첫 화면데이터
const getList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`GoodsManagement/goodsGroupMgmt/getGoodsGroupList${param}`,
	);
	return response;
};

// 등록팝업
const insertData = async data => {
	const response = await instance.post(
		`GoodsManagement/goodsGroupMgmt/insertGoodsGroup`,
		data,
	);
	return response;
};

// 정보수정팝업
const updateData = async data => {
	const response = await instance.post(
		`GoodsManagement/goodsGroupMgmt/updateGoodsGroup`,
		data,
	);
	return response;
};

// 판매점 상품 그룹 목록 조회
const getGroupList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`GoodsManagement/goodsGroupMgmt/getStoreGoodsGroupList${param}`,
	);
	return response;
};


export {
	getList,
	insertData,
	updateData,
	getGroupList,
};
