<template>
	<footer class="disFx justify-center">
		<div>
			<div class="disFx">
				<a @click="openDialog(1)">이용약관</a>
				<a @click="openDialog(2)">개인정보 처리방침</a>
				<a @click="openDialog(3)">전자금융거래 이용약관</a>
				<a @click="moveToCs">고객센터</a>
			</div>
			<div>
				<div class="subFontS11 mainBlack3 mt-2" style="line-height: 16px">
					<div>
						<span>주식회사 폰씨티 | 사업자등록번호 : 501-88-01494</span>
						<!--						<span>-->
						<!--							주식회사 대마 | 사업자 등록번호 : 264-81-51441 | 입금계좌 :-->
						<!--							국민은행 285301-00-042046 주식회사 대마</span-->
						<!--						>-->
					</div>
					<!--					<div>-->
					<!--						<span>대표이사 : 이은화</span>-->
					<!--						<span>-->
					<!--							주소 : 경기도 시흥시 서울대학로 278번길 19-14 배곧프라자 706호 |-->
					<!--							평일 09시 ~ 18시-->
					<!--						</span>-->
					<!--					</div>-->
					<!--					<div class="boldWt">-->
					<!--						<span>-->
					<!--							TEL : 1833-9413 | FAX : 070-8680-9413 | E-mail: cs@daemacorp.co.kr-->
					<!--						</span>-->
					<!--					</div>-->
				</div>
				<div class="boldWt subFontS11">
					Copyright © 폰씨티. All rights reserved
				</div>
				<!--        <div class="boldWt subFontS11">-->
				<!--          Copyright © Daemacorp. All rights reserved-->
				<!--        </div>-->
			</div>
		</div>
	</footer>
</template>

<script>
import bus from '@/common/bus';

export default {
	computed: {},
	methods: {
		openDialog(type) {
			bus.$emit('open:terms', type);
		},
		moveToCs() {
			if (window.location.pathname !== '/cs-mgmt/noticelist') {
				this.$router.push('/cs-mgmt/noticelist');
			}
		},
	},
};
</script>

<style scoped>
footer {
	background: #e5e5e5;
	margin-top: 20px;
	padding: 14px 0 8px 0;
}
footer a {
	color: #000;
	font-size: 12px;
	background: #cbcbcb;
	padding: 0 12px;
	line-height: 24px;
	border-radius: 5px;
	margin-right: 8px;
}
footer a:last-of-type {
	margin-right: 0;
}
</style>
