import { instance, instanceNS } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = 'DashBoardManagement/DashBoardMgmt/';

// 개인실적비교 조회
const getIndvOpeningCnt = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getIndvOpeningCnt${param}`);
	return response;
};
// 조직별 실적 비교 조회
const getOrganizationComplCnt = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getOrganizationComplCnt${param}`,
	);
	return response;
};
//TO-DO 예약 조회
const getTodoReservationList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getTodoReservationList${param}`,
	);
	return response;
};
// 당월현황 조회
const getCurrentMonthInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instanceNS.get(`${domain}getCurrentMonthInfo${param}`);
	return response;
};
// 당월현황 조회
const getSaleStoreAllMemberList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instanceNS.get(
		`/Commgmt/Search/getSaleStoreAllMemberList${param}`,
	);
	return response;
};

// const insertMemo = async data => {
// 	const response = await instance.post(`${domain}insertMemo`, data);
// 	return response;
// };

export {
	getIndvOpeningCnt,
	getOrganizationComplCnt,
	getTodoReservationList,
	getCurrentMonthInfo,
	getSaleStoreAllMemberList,
};
