<template>
	<div
		class="noticeDrawer"
		:class="{ closeRnb: !activeRnb }"
		v-click-outside="outSideFnc"
	>
		<!-- [KT-CB] 신조개통점 권한 예외처리 -->
		<a id="RnbBtn" @click="toggleRnb" v-if="ss !== '140' && ss !== '141'">
			<img
				src="../../assets/img/alert1.png"
				alt="알림목록"
				:class="{ bellRing: notiRnbCnt > 0 }"
			/>
			<!-- 알림 갯수가 0초과 100미만일 경우-->
			<span v-if="notiRnbCnt > 0 && notiRnbCnt < 100" class="noticeCount">
				{{ notiRnbCnt }}
			</span>
			<!-- 100개 이상일 경우 99+ 표시 svg -->
			<em class="maxCnt" v-if="notiRnbCnt >= 100"></em>
		</a>
		<template v-if="activeRnbIndex === 1">
			<div
				class="noticeTopCont"
				:class="{ disNone: notiRnbResultList.length === 0 }"
			>
				<p>
					<span @click="deleteFnc('read')">읽은 알림 삭제</span> |
					<span @click="deleteFnc('all')">전체 삭제</span>
				</p>
			</div>
			<ul
				class="noticeNoticeUl narrowScroll"
				id="noticeNoticeUl"
				@scroll="scrollPaging"
			>
				<template v-if="notiRnbResultList.length === 0">
					<li
						class="noticeLiCont"
						:class="{ noList: notiRnbResultList.length === 0 }"
					>
						<div>
							<h3>새로운 알림이 없습니다.</h3>
							<p>알림목록에서 지난 알림을 확인해 주세요.</p>
						</div>
					</li>
				</template>

				<template v-else>
					<li
						v-for="(item, index) in notiRnbResultList"
						:id="`notiRnbList${index}`"
						:key="index"
						class="noticeLiCont"
						:class="{ emergencyNoti: item.emergencyYn === 'Y' }"
					>
						<div :class="{ read: item.readYn === 'Y' }">
							<!-- push 선택시 dom 바인딩으로 처리 -->
							<div class="statusDot" :class="[item.notiType]">
								<template v-if="item.emergencyYn === 'N'">
									<em v-if="item.readYn === 'N'" class="bgTurquoise"></em>
									<em v-if="item.readYn === 'Y'" class="bgGray"></em>
								</template>
								<template v-if="item.emergencyYn === 'Y'">
									<em v-if="item.readYn === 'N'" class="bgRed"></em>
									<em v-if="item.readYn === 'Y'" class="bgRedRead"></em>
								</template>
							</div>
							<div
								class="cur_p"
								style="width: 260px"
								@click="locateNotiPage(index)"
							>
								<div class="disFx">
									<h3 v-if="item.notiContentsDto">
										{{ item.notiContentsDto.category }}
									</h3>
									<h3
										class="ml5"
										v-if="item.notiContentsDto"
										v-html="item.notiContentsDto.title"
									></h3>
								</div>
								<p
									v-if="item.notiContentsDto"
									style="
										width: 260px;
										text-overflow: ellipsis;
										white-space: nowrap;
									"
								>
									{{ item.notiContentsDto.openingStoreName }} |
									{{ item.notiContentsDto.saleStoreName }} |
									{{ item.notiContentsDto.cusName }} |
									{{ item.notiContentsDto.openingPhone1 }}-{{
										item.notiContentsDto.openingPhone2
									}}-{{ item.notiContentsDto.openingPhone3 }}
								</p>
								<span>
									{{ item.updDateTime | moment('YYYY-MM-DD HH:mm:ss') }} |
									{{ item.regiUserName }}</span
								>
							</div>
							<div class="posR">
								<button
									class="closeBtn4"
									@click="deleteFnc('notice', index)"
								></button>
							</div>
						</div>
					</li>
					<li v-if="pageLastNo === pageNo && notiRnbResultList.length !== 0">
						<div
							class="noticeLiCont"
							style="height: 40px; line-height: 40px; text-align: center"
						>
							<p style="font-size: 14px; width: 100%">
								더 이상 확인할 알림이 없습니다.
							</p>
						</div>
					</li>
				</template>
			</ul>
			<div class="noticeBottomCont">
				<router-link to="/to-do">알림목록</router-link>
			</div>
		</template>

		<!-- drawer 추가 시, 아래에 추가해주세요. -->
		<TodoDrawer></TodoDrawer>
		<ToastPopup @focusOnRnb="focusOnRnb"></ToastPopup>
	</div>
</template>

<script>
import bus from '@/common/bus';
import ToastPopup from '@/components/webSocket/ToastPopup';
import TodoDrawer from '@/components/rnb/TodoDrawer';

export default {
	components: {
		ToastPopup,
		TodoDrawer,
	},
	name: 'RnbDrawer',
	props: {},
	data: () => ({
		readNoticeIdx: [],
		pageNo: 1,
		pageLastNo: null,
	}),
	computed: {
		readNoticeList() {
			return this.notiRnbList.filter(item => item.readYn === 'N');
		},
		notiRnbCnt() {
			return this.$store.state.NoticeModule.notiRnbCnt;
		},
		activeRnb: {
			get() {
				return this.$store.state.NoticeModule.activeRnb;
			},
			set(newValue) {
				this.$store.state.NoticeModule.activeRnb = newValue;
			},
		},
		activeRnbIndex: {
			get() {
				return this.$store.state.NoticeModule.activeRnbIndex;
			},
			set(newValue) {
				this.$store.state.NoticeModule.activeRnbIndex = newValue;
			},
		},
		todoActive: {
			get() {
				return this.$store.state.TodoModule.activeRnb;
			},
			set(newValue) {
				this.$store.state.TodoModule.activeRnb = newValue;
			},
		},
		notiRnbList: {
			get() {
				return this.$store.state.NoticeModule.notiRnbList;
			},
			set(newValue) {
				this.$store.state.NoticeModule.notiRnbList = newValue;
			},
		},
		notiRnbResultList: {
			get() {
				return this.$store.state.NoticeModule.notiRnbResultList;
			},
			set(newValue) {
				this.$store.state.NoticeModule.notiRnbResultList = newValue;
			},
		},
		ss() {
			return this.$store.state.cookies.ss; // [KT-CB] 신조개통점 권한 예외처리
		},
	},
	methods: {
		outSideFnc() {
			this.activeRnb = false;
			setTimeout(() => (this.activeRnbIndex = 0));
		},
		async toggleRnb() {
			if (!this.activeRnb) {
				this.activeRnb = true;
				await this.getNotiList();
				this.activeRnbIndex = 1;
			} else {
				if (this.activeRnbIndex === 1) {
					this.activeRnb = false;
				} else {
					await this.getNotiList();
					this.activeRnbIndex = 1;
				}
			}
		},
		async deleteFnc(target, index) {
			if (
				target === 'all' &&
				confirm(
					'전체삭제 하시겠습니까?\n알림내역에서만 전체삭제되며, 알림목록에서 전체 재확인이 가능합니다.',
				)
			) {
				await this.hideNotiReceiver('ALL');
			}
			if (
				target === 'read' &&
				confirm(
					'읽은알림 삭제 하시겠습니까?\n알림내역에서만 삭제되며, 알림목록에서 재확인이 가능합니다.',
				)
			) {
				await this.hideNotiReceiver('READ');
			}
			if (target === 'notice') {
				await this.updateNotiReceiver('hideYn', index);
			}
			await this.getNotiList();
			await this.getNotiRnbCnt();
		},

		async locateNotiPage(index) {
			// 읽기 상태변경
			if (this.notiRnbResultList[index].readYn === 'N') {
				await this.updateNotiReceiver('readYn', index);
				await this.getNotiRnbCnt();
			}
			// notiType별 popup띄우기
			if (this.notiRnbResultList[index].notiType === 'COMPLAIN') {
				this.$emit('passIdInfo1', this.notiRnbResultList[index].idInfo1);
				this.$store.commit('NoticeModule/togglePopup', 'complainInfoPop');
			}
			// 알림목록 읽기상태값 수정
			if (this.$route.path === '/to-do') {
				let targetNotiId = this.notiRnbResultList[index].notiId;
				bus.$emit('todo:readFnc', targetNotiId);
			}
		},
		async getNotiList(index) {
			this.pageNo = 1;
			let data = {
				pageNo: this.pageNo,
				notiTargetType: 'RNB',
			};
			let result = await this.$store.dispatch(
				'NoticeModule/getNotiRnbList',
				data,
			);
			this.pageLastNo = result.pageLastNo;
			this.notiRnbResultList = result.resultList || [];

			let notiList = [];
			notiList = this.$store.state.NoticeModule.notiPopList;
			if (index >= 0 && this.notiRnbResultList) {
				let pushCheck = notiList[index].message;
				if (pushCheck === 'noti') {
					let targetIdx = this.notiRnbResultList.findIndex(
						notice => notice.notiId === notiList[index].notiId,
					);
					let target;
					await this.$nextTick(function () {
						target = document.getElementById(`notiRnbList${targetIdx}`)
							.children[0].classList;
						if (!target.contains('targetNotice')) {
							target.add('targetNotice');
							setTimeout(function () {
								target.remove('targetNotice');
							}, 1800);
						}
					});
				} else {
					let target;
					let notiType = notiList[index].notiType;
					let emergencyChk = notiList[index].emergencyYn === 'Y' ? true : false;
					await this.$nextTick(function () {
						target = document.getElementById(`noticeNoticeUl`).children;
						for (let i = 0; i < target.length; i++) {
							// 1. 읽기처리가 안되어있는것 / 2. 푸시 긴급유무와 목록의 긴급유무 비교 / 3. 푸시 타입과 목록 타입 비교[민원/공지...]
							if (
								!target[i].children[0].classList.contains('read') &&
								target[i].classList.contains('emergencyNoti') ===
									emergencyChk &&
								target[i].children[0].children[0].classList.contains(notiType)
							) {
								target[i].children[0].classList.add('targetNotice');
								setTimeout(function () {
									target[i].children[0].classList.remove('targetNotice');
								}, 1800);
							}
						}
					});
				}
				notiList.splice(index, 1);
			}
		},
		async getNotiScrollList() {
			let data = {
				pageNo: this.pageNo,
				notiTargetType: 'RNB',
			};
			let result = await this.$store.dispatch(
				'NoticeModule/getNotiRnbList',
				data,
			);
			result.resultList.map(item => {
				this.notiRnbResultList.push(item);
			});
		},
		async updateNotiReceiver(type, index) {
			let data;
			if (type === 'readYn') {
				data = {
					notiId: this.notiRnbResultList[index].notiId,
					readYn: 'Y',
					notiRecvrId: this.notiRnbResultList[index].notiRecvrId,
				};
				this.notiRnbResultList[index].readYn = 'Y';
			}
			if (type === 'hideYn') {
				data = {
					notiId: this.notiRnbResultList[index].notiId,
					hideYn: 'Y',
					notiRecvrId: this.notiRnbResultList[index].notiRecvrId,
				};
				this.notiRnbResultList[index].hideYn = 'Y';
			}
			await this.$store.dispatch('NoticeModule/updateNotiReceiver', data);
		},
		async hideNotiReceiver(type) {
			let data = {
				notiTargetType: type,
			};
			await this.$store.dispatch('NoticeModule/hideNotiReceiver', data);
		},
		async getNotiRnbCnt() {
			await this.$store.dispatch('NoticeModule/getNotiRnbCnt');
		},
		scrollPaging(event) {
			let scrollTopNumber = event.target.scrollTop;
			let scrollBottomNumber = event.target.scrollHeight;
			let targetDomHg = document.getElementById(`noticeNoticeUl`).offsetHeight;
			if (
				scrollTopNumber + targetDomHg >= scrollBottomNumber &&
				this.pageNo !== this.pageLastNo
			) {
				this.pageNo++;
				this.getNotiScrollList();
			}
		},
		focusOnRnb(id) {
			this.noticeActive = true;
			this.focusNotiId = id;
		},
	},
	watch: {},
	async created() {
		await this.getNotiRnbCnt();
		await this.getNotiList();

		// push 알림 오게되면 RNB목록 재조회
		bus.$on('getNotiList:rnb', this.getNotiList);
	},
	beforeDestroy() {
		bus.$off('getNotiList:rnb', this.getNotiList);
	},
};
</script>

<style scoped>
.bgTurquoise {
	background: #00aac1;
}
.bgRed {
	background: #ff2e00;
}
.bgRedRead {
	background: rgba(255, 46, 0, 0.35);
}
.bgGray {
	background: #d8d8d8;
}
.noticeDrawer * {
	font-family: 'Noto Sans KR', sans-serif;
}
.noticeDrawer {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	width: 370px;
	height: 100vh;
	background: #fff;
	z-index: 37;
	transition: all 0.3s;
}
.closeRnb {
	right: -370px;
	transition: all 0.3s;
}
.noticeDrawer #RnbBtn {
	display: block;
	position: absolute;
	width: 42px;
	height: 37px;
	background: #007ef2;
	border-radius: 100% 0 0 100%;
	top: 54px;
	left: -42px;
	transition: all 0.2s;
	transform-origin: right;
}
.noticeDrawer .bellRing {
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-name: bellRingLong;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	transform-origin: top center;
}
.noticeDrawer #RnbBtn:hover {
	left: -45px;
	width: 45px;
}
.noticeDrawer #RnbBtn img {
	position: absolute;
	top: 9px;
	left: 12px;
	width: 16px;
	height: 20px;
}
.noticeDrawer #RnbBtn .noticeCount {
	display: inline-block;
	text-align: center;
	position: absolute;
	min-width: 18px;
	height: 18px;
	top: 2px;
	right: 2px;
	border-radius: 3em;
	padding: 0.1em;
	font-size: 0.7em;
	line-height: 1.4em;
	background: #ff7a00;
	color: #fff;
	z-index: 16;
	transition: all 0.2s;
}
.noticeDrawer #RnbBtn:hover .noticeCount,
.noticeDrawer #RnbBtn:hover .maxCnt {
	right: 4px;
}

.noticeDrawer #RnbBtn .maxCnt {
	position: absolute;
	display: flex;
	width: 18px;
	height: 20px;
	background: url('../../assets/img/maxCnt.svg');
	top: 0;
	right: 2px;
	z-index: 14;
	transition: all 0.2s;
}

.noticeDrawer .disNone {
	visibility: hidden;
}
.noticeDrawer .noticeTopCont {
	display: flex;
	width: 100%;
	height: 88px;
	padding-bottom: 4px;
	border-bottom: 1px solid #d8d8d8;
}
.noticeDrawer > div {
	border-top: 1px solid #d8d8d8;
}
.noticeDrawer > div p {
	position: absolute;
	top: 58px;
	right: 12px;
	color: #999;
	font-size: 12px;
	margin: 0;
}
.noticeDrawer > div p span {
	color: #000;
	padding: 4px 6px;
	cursor: pointer;
}
.noticeDrawer .noticeNoticeUl {
	display: flex;
	flex-direction: column;
	height: 90vh;
	overflow-y: auto;
}
.noticeDrawer .noticeNoticeUl .noticeLiCont > div {
	display: flex;
	height: 70px;
	border-bottom: 1px solid #d8d8d8;
	padding: 8px 10px;
	transition: all 0.15s ease;
}

.noticeDrawer .noticeNoticeUl .noticeLiCont:hover {
	background: rgba(242, 242, 242, 0.5);
}
.noticeDrawer .noticeNoticeUl .noticeLiCont .statusDot {
	width: 10px;
	display: flex;
	flex-direction: column;
	margin-right: 8px;
}
.noticeDrawer .noticeNoticeUl .noticeLiCont .statusDot > em {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-top: 4px;
}
.noticeDrawer .noticeNoticeUl .noticeLiCont h3 {
	font-size: 12px;
	color: #000;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.noticeDrawer .noticeNoticeUl .noticeLiCont .read h3 {
	color: #666;
	font-weight: 400 !important;
}
.noticeDrawer .noticeNoticeUl .noticeLiCont p {
	margin: 2px 0 0 0;
	width: 280px;
	overflow: hidden;
}
.noticeDrawer .noticeNoticeUl .noticeLiCont span {
	font-size: 10px;
	color: #999;
}
.noticeDrawer .noList {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}
.noticeDrawer .noList:hover {
	background: #fff !important;
}
.noticeDrawer .noList div {
	flex-direction: column;
	justify-content: center;
	text-align: center;
	border-bottom: 0 !important;
}
.noticeDrawer .noList li h3 {
	font-size: 14px;
	padding-top: 10px;
}
.noticeDrawer .noList h3:before {
	position: absolute;
	display: block;
	top: 44.5%;
	left: 47%;
	width: 20px;
	height: 24px;
	background: url('../../assets/img/alert2.svg');
	content: '';
}
.noticeDrawer .noList .noticeLiCont p,
.noticeDrawer .noticeNoticeUl .noticeLiCont p {
	font-size: 11px;
	font-weight: 400;
	color: #666;
}
.closeBtn4 {
	position: absolute;
	right: -68px;
	top: 20px;
	width: 14px;
	height: 14px;
	background: url('../../assets/img/close_Btn.svg');
	background-size: contain;
	opacity: 0.6;
}
.noticeDrawer .noticeBottomCont {
	height: 60px;
	position: sticky;
	bottom: 0;
	background-color: #fbfbfb;
	display: flex;
	align-items: center;
}
.noticeDrawer .noticeBottomCont a {
	font-size: 13px;
	text-decoration: underline;
	font-weight: 600;
	color: #000;
	line-height: 2.8rem;
	text-underline-position: under;
	margin-left: 8px;
	padding: 6px 8px;
}
.targetNotice {
	animation-duration: 1.8s;
	animation-timing-function: linear;
	animation-name: targetBgBlue;
}
.emergencyNoti .targetNotice {
	animation-name: targetBgRed;
}
@keyframes targetBgBlue {
	0% {
		background: #ebf3ff;
	}
	80% {
		background: #ebf3ff;
	}
	100% {
		background: #ffffff;
	}
}
@keyframes targetBgRed {
	0% {
		background: rgba(255, 46, 0, 0.08);
	}
	80% {
		background: rgba(255, 46, 0, 0.08);
	}
	100% {
		background: #ffffff;
	}
}
</style>
