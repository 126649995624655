import Vue from 'vue';
import Vuex from 'vuex';
import {
	getBondMgmtList,
	getBondMgmtSummary,
	getBondMonthSummary,
	getBondMonthGroupSummary,
	getBondMgmtGroupSummary,
	updateBondCompe,
	updateBondInComplYn,
	updateBondOutComplYn,
	updateBondHoldOver,
	updateBulkBondInOutComplYn,
	saveMonthAsset,
} from '@/api/settlement-mgmt/KtBondMgmtApi';
import { commonCodeEnumList } from '@/api/warehouse-mgmt/device-mgmt/NormalInStockApi';
import { getRetrieveDeviceHistory } from '@/api/common/common';
import { getDateFormat } from '@/common/common';

Vue.use(Vuex);

const KtBondMgmtModule = {
	namespaced: true,
	state: {
		compeDialog: false,
		infoDialog: false,
		totalCnt: 0,
		selectedMonth: 0,
		selectedYear: 0,
		selectedOpeningStoreIds: [],
		// selectedStockYn: 'Y',
		assetAmt: 0,
		statusList: [],
		detailHistory: [],
		bondMgmtList: [],
		bondMgmtSummary: {
			prevMonthSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
			nowMonthSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
			accrueSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
			holdOverOpeningSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
		},
		bondMgmtGroupSummary: {
			prevMonthSummaryList: {
				device: [],
				usim: [],
			},
			nowMonthSummaryList: {
				device: [],
				usim: [],
			},
		},
		compeComplList: [],
		noneComplList: [],
		bondCompensationList: [],
		bondMonthSummaryList: [],
		bondMonthGroupSummary: [],
		tblArr: [],
		summaryGroupType: 'NORMAL',
		filterData: {
			barcode: '',
			goodsName: '',
			stockIds: '',
			provIds: 0,
			openingHistoryStoreIds: [],
			orderDate: '',
			orderEndDate: '',
			inStockRegiDate: '',
			inStockEndDate: '',
			openingDate: '',
			openingEndDate: '',
			goodsType: '',
			maker: '',
			unLockYn: '',
			compensationTargetYn: '',
			compensationYn: '',
			retractYn: '',
			// stockYn: '',
			srhYear: 0,
			telecom: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		filterDataCompe: {
			goodsName: '',
			maker: '',
			unLockYn: '',
			compensationDate: '',
			compensationEndDate: '',
			openingHistoryStoreIds: [],
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		enumCodeList: {},
	},
	mutations: {
		initCompeComplList(state) {
			state.compeComplList = [];
			state.noneComplList = [];
			state.bondCompensationList = [];
		},
		insertToFilterData(state) {
			state.filterData.srhYear = state.selectedYear;
			state.filterData.srhMonth = state.selectedMonth;
			state.filterData.openingHistoryStoreIds = state.selectedOpeningStoreIds;
			// state.filterData.stockYn = state.selectedStockYn;
		},
		filterInit(state) {
			for (const data in state.filterData) {
				state.filterData[data] = '';
			}
			state.filterData.srhYear = state.selectedMonth;
			state.filterData.srhMonth = state.selectedYear;
			state.filterData.openingHistoryStoreIds = state.selectedOpeningStoreIds;
			// state.filterData.stockYn = state.selectedStockYn;
			state.filterData.maker = '';
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
			state.filterData.goodsName = '전체';
		},
		initPagingData(state) {
			for (const data in state.pagingData) {
				state.pagingData[data] = 0;
			}
			state.pagingData.perPageCnt = 20;
			state.pagingData.pageNumList = [];
		},
		setBondMgmtList(state, result) {
			state.bondMgmtList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setBondMgmtSummary(state, result) {
			for (let i in result) {
				if (result[i].length > 0) {
					for (let j = 0; j < result[i].length; j++) {
						// 자가유통
						if (result[i][j].unLockYn === 'Y') {
							state.bondMgmtSummary[i].unlockY = result[i][j];
						} else if (result[i][j].unLockYn === 'N') {
							state.bondMgmtSummary[i].unlockN = result[i][j];
						}
					}
				}
			}
		},
		initBondMgmtSummary(state) {
			state.bondMgmtSummary = {
				prevMonthSummaryList: {
					unlockY: {},
					unlockN: {},
				},
				nowMonthSummaryList: {
					unlockY: {},
					unlockN: {},
				},
				accrueSummaryList: {
					unlockY: {},
					unlockN: {},
				},
				holdOverOpeningSummaryList: {
					unlockY: {},
					unlockN: {},
				},
			};
		},
		setUsimTypeItems(state, data) {
			state.enumCodeList.goodsTypeItems = [];
			state.enumCodeList.goodsTypeItems = data.data.data.GoodsType;
			state.enumCodeList.goodsTypeItems.unshift({ name: '전체', value: '' });
		},
		setDeviceHistory(state, result) {
			state.deviceHistory = result;
		},
		initDeviceHistory(state) {
			state.deviceHistory = [];
		},
		setDefaultInStockRegiDate(state) {
			state.filterData.inStockRegiDate = getDateFormat(
				new Date(state.selectedYear, state.selectedMonth - 1, 1),
			);
			state.filterData.inStockEndDate = getDateFormat(
				new Date(state.selectedYear, state.selectedMonth, 0),
			);
		},
		initBondMgmtGroupSummary(state) {
			if (state.summaryGroupType === 'NORMAL') {
				state.bondMgmtGroupSummary.prevMonthSummaryList = [];
				state.bondMgmtGroupSummary.nowMonthSummaryList = [];
				state.bondMgmtGroupSummary.accrueSummaryList = [];
			} else {
				state.bondMgmtGroupSummary = {
					prevMonthSummaryList: {
						device: [],
						usim: [],
					},
					nowMonthSummaryList: {
						device: [],
						usim: [],
					},
				};
			}
		},
		setBondMgmtGroupSummary(state, result) {
			if (state.summaryGroupType === 'NORMAL') {
				state.bondMgmtGroupSummary.prevMonthSummaryList =
					result.prevMonthSummaryList || [];
				state.bondMgmtGroupSummary.nowMonthSummaryList =
					result.nowMonthSummaryList || [];
				// state.bondMgmtGroupSummary.accrueSummaryList =
				// 	result.accrueSummaryList || [];
			} else {
				for (let i in result) {
					if (result[i] !== null && result[i].length > 0) {
						for (let j = 0; j < result[i].length; j++) {
							if (result[i][j].goodsType === 'DVC') {
								state.bondMgmtGroupSummary[i].device.push(result[i][j]);
							} else if (result[i][j].goodsType === 'USIM') {
								state.bondMgmtGroupSummary[i].usim.push(result[i][j]);
							}
						}
					}
				}
			}
		},
		initBondMonthSummary(state) {
			state.bondMonthSummaryList = [
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
			];
			state.tblArr = [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			];
			state.bondMonthGroupSummary = [
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
			];
		},
		setBondMonthSummary(state, result) {
			state.bondMonthSummaryList = result;
		},
		// setBondMonthGroupSummary(state, idx, result) {
		// 	console.log(result);
		// 	state.bondMonthGroupSummary[idx] = result;
		// 	console.log(state.bondMonthGroupSummary);
		// },
	},
	actions: {
		async getBondMgmtList({ commit }, data) {
			try {
				if (data && data.goodsName === '전체') {
					data.goodsName = '';
				}
				commit('insertToFilterData');
				const result = await getBondMgmtList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondMgmtList', result.data.data);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMgmtSummary({ commit }, data) {
			try {
				commit('initBondMgmtSummary');
				const result = await getBondMgmtSummary(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondMgmtSummary', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMgmtGroupSummary({ commit }, data) {
			try {
				commit('initBondMgmtGroupSummary');
				const result = await getBondMgmtGroupSummary(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondMgmtGroupSummary', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondCompe({ commit }, data) {
			try {
				const result = await updateBondCompe(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondInComplYn({ commit }, data) {
			try {
				const result = await updateBondInComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondOutComplYn({ commit }, data) {
			try {
				const result = await updateBondOutComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondHoldOver({ commit }, data) {
			try {
				const result = await updateBondHoldOver(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBulkBondInOutComplYn({ commit }, data) {
			try {
				const result = await updateBulkBondInOutComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async saveMonthAsset({ commit }, data) {
			try {
				const result = await saveMonthAsset(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async commonCodeEnumList({ commit }) {
			const data = { initEnumData: ['GoodsType'] };
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setUsimTypeItems', result);
			}
		},
		async getEnumCommonCodeList({ commit }) {
			try {
				/* 신규 ENUM 데이터 */
				const newEnum = {
					initEnumData: ['RetractType'],
				};
				const result = await commonCodeEnumList(newEnum);
				if (result) {
					commit('setCommonCodeNewList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 이력 리스트 조회
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				commit('initDeviceHistory');
				const result = await getRetrieveDeviceHistory(data);
				if (result && result.data.resultCode === '0000') {
					commit('setDeviceHistory', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMonthSummary({ commit }, data) {
			try {
				commit('initBondMonthSummary');
				const result = await getBondMonthSummary(data);
				if (result && result.data.resultCode === '0000') {
					commit('setBondMonthSummary', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMonthGroupSummary({ commit }, data) {
			try {
				const result = await getBondMonthGroupSummary(data);
				if (result && result.data.resultCode === '0000') {
					// commit('setBondMonthGroupSummary', data.idx, result.data);
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default KtBondMgmtModule;
