<template>
	<v-app id="app">
		<template v-if="loginToken">
			<header-app v-if="!onLoginPage"></header-app>
			<lnb-app class="defaultboxShadow" v-if="!onLoginPage"></lnb-app>
			<RnbDrawer
				v-if="!onLoginPage && ss !== '140' && ss !== '141'"
				:activeRnb="activeRnb"
				:focusNotiId="focusNotiId"
				@passIdInfo1="passIdInfo1"
			></RnbDrawer>
			<NoticePopupCollection :idInfo1="idInfo1"></NoticePopupCollection>
			<v-main
				id="mainBox"
				style="background-color: #f5f5f5; overflow: scroll"
				class="drawerOn narrowScroll"
				:class="{ drawerOff: drawerToggle, loginBg: onLoginPage }"
			>
				<v-container
					:class="{ backColorWhite: onLoginPage }"
					:style="onLoginPage ? 'height: 100vh; padding: 0' : ''"
					style="min-height: calc(100vh - 80px)"
					fluid
				>
					<router-view></router-view>
				</v-container>
				<footer-app v-if="loginToken && footerFlag"></footer-app>
			</v-main>
			<progress-loading-bar :loading="loadingStatus"></progress-loading-bar>
		</template>
		<template v-else>
			<router-view></router-view>
			<progress-loading-bar :loading="loadingStatus"></progress-loading-bar>
		</template>
		<WebSocket v-if="socketFlag" ref="websocket"></WebSocket>
		<v-snackbar v-model="snackbar" color="blue" top :timeout="1500"
			>신청서 업무/수정이 감지되었습니다.<br />최신 정보로 자동
			적용됩니다.</v-snackbar
		>
		<terms-pop></terms-pop>
		<!--		<ToastPopup bgColor="#FF4D00"></ToastPopup>-->
		<!--	notice pop 모음	-->
	</v-app>
</template>

<script>
import HeaderApp from './components/HeaderApp.vue';
import LnbApp from './components/LnbApp.vue';
import FooterApp from './components/FooterApp.vue';
import bus from './common/bus.js';
import ProgressLoadingBar from './components/progressLoadingBar.vue';
import WebSocket from './components/webSocket/WebSocket.vue';
import RnbDrawer from './components/rnb/RnbDrawer';
import termsPop from '@/components/popup/TermsPop';
import ToastPopup from './components/webSocket/ToastPopup';
import NoticePopupCollection from './components/noticeList/NoticePopupCollection';
import { getFromCookie } from '@/common/cookies';

let mq = null;

export default {
	components: {
		NoticePopupCollection,
		RnbDrawer,
		HeaderApp,
		LnbApp,
		FooterApp,
		ProgressLoadingBar,
		WebSocket,
		termsPop,
	},
	data() {
		return {
			loadingStatus: false,
			socketFlag: false,
			snackbar: false,
			focusNotiId: 0,
			idInfo1: 0,
			onLoginPage: false,
		};
	},
	computed: {
		pu() {
			return this.$store.state.cookies.pu;
		},
		loginToken() {
			let result;
			if (this.$store.state.cookies.name) {
				result = true;
			} else {
				result = false;
			}
			return result;
		},
		applId() {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		drawerToggle: {
			get() {
				return this.$store.state.drawer;
			},
			set(newValue) {
				this.$store.state.drawer = newValue;
			},
		},
		footerFlag: {
			get() {
				return this.$store.state.footerFlag;
			},
			set(newValue) {
				this.$store.state.footerFlag = newValue;
			},
		},
		activeRnb: {
			get() {
				return this.$store.state.NoticeModule.activeRnb;
			},
			set(newValue) {
				this.$store.state.NoticeModule.activeRnb = newValue;
			},
		},
		crData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.crData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.crData = newValue;
			},
		},
		creditInquireId: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.formData.basic
					.creditInquireId;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.formData.basic.creditInquireId =
					newValue;
			},
		},
		termsDialog: {
			get() {
				return this.$store.state.termsDialog;
			},
			set(newValue) {
				this.$store.state.termsDialog = newValue;
			},
		},
		ss() {
			return this.$store.state.cookies.ss; // [KT-CB] 신조개통점 권한 예외처리
		},
	},
	methods: {
		startSpinner() {
			this.loadingStatus = true;
		},
		endSpinner() {
			this.loadingStatus = false;
		},
		reloadAppl() {
			this.snackbar = true;
		},
		passIdInfo1(id) {
			this.idInfo1 = id;
		},
	},
	created() {
		if (this.loginToken) {
			this.footerFlag = true;
		}
		bus.$on('start:spinner', this.startSpinner);
		bus.$on('end:spinner', this.endSpinner);
		bus.$on('reload:socket', this.reloadAppl);
	},
	beforeDestroy() {
		bus.$off('start:spinner', this.startSpinner);
		bus.$off('end:spinner', this.endSpinner);
		bus.$off('reload:socket', this.reloadAppl);
	},

	watch: {
		async $route(to, from) {
			if (this.loginToken) {
				if (to.fullPath.includes('/application-form-creation')) {
					this.footerFlag = false;
				} else {
					this.footerFlag = true;
				}
			}
			if (to.fullPath === '/login') {
				this.onLoginPage = true;
				mq = null;
			}
			if (from.fullPath === '/login') {
				this.onLoginPage = false;
				if (this.drawerToggle) {
					this.drawerToggle = false;
				}
				mq = this.$store.state.cookies.mq;
			}
			if (
				from.path !== '/credit-check' ||
				from.path !== '/credit-check-request'
			) {
				this.crData = [];
				this.creditInquireId = null;
			}
			// 페이지주소에 따라 Socket Connect 호출 부분

			if (
				this.$store.state.cookies.mq &&
				to.path !== '/login' &&
				to.path !== '/sign-up' &&
				to.path !== '/user/sign-up' &&
				to.path !== '/store/sign-up'
			) {
				this.socketFlag = true;
			} else {
				this.socketFlag = false;
			}

			// 쿠키정보 변경시 사용중인 쿠키정보들 재정의
			if (getFromCookie('name') !== this.$store.state.cookies.name) {
				this.$store.state.cookies.name = getFromCookie('name') || '';
				this.$store.state.cookies.role = getFromCookie('role') || '';
				this.$store.state.cookies.sct = getFromCookie('sct') || '';
				this.$store.state.cookies.mq = getFromCookie('mq') || '';
				this.$store.state.cookies.mf = getFromCookie('mf') || '';
				this.$store.state.cookies.pu = getFromCookie('pu') || null;
				this.$store.state.cookies.st = getFromCookie('st') || null;
				this.$store.state.cookies.sg = getFromCookie('sg') || null;
				this.$store.state.cookies.ms = getFromCookie('ms') || null;
				this.$store.state.cookies.ss = getFromCookie('ss') || null;
				this.$store.state.cookies.mr = getFromCookie('mr') || null;
				this.$store.state.cookies.mp = getFromCookie('mp') || null;
				this.$store.state.cookies.cr = getFromCookie('cr') || null;
				this.$store.state.cookies.oi = getFromCookie('oi') || null;
				this.$store.state.cookies.sn = getFromCookie('sn') || null;
				this.$store.state.cookies.sm = getFromCookie('sm') || null;
				this.$store.state.cookies.ca = getFromCookie('ca') || null;
				this.$store.dispatch('NoticeModule/getNotiRnbCnt');
				if (this.activeRnb) {
					bus.$emit('getNotiList:rnb');
				}
			}
		},
	},
};

window.addEventListener(
	'contextmenu',
	function (e) {
		if (
			process.env.NODE_ENV !== 'local' &&
			mq !== '3b14c9d61a1ce5782698616844edc9ee' &&
			mq !== '22f353197e9b0c1cb58a11da8de7776a'
		) {
			e.preventDefault();
		}
	},
	false,
);

window.addEventListener(
	'keydown',
	function (e) {
		if (
			e.key === 'F12' &&
			process.env.NODE_ENV !== 'local' &&
			mq !== '3b14c9d61a1ce5782698616844edc9ee' &&
			mq !== '22f353197e9b0c1cb58a11da8de7776a'
		) {
			e.preventDefault();
			e.returnValue = false;
		}
	},
	false,
);
</script>

<style>
@import './assets/css/main.css';
@import './assets/css/style.css';
@import './assets/css/common.css';
@import './assets/css/newCommon.css';
@import './assets/css/newStyle.css';
@media only screen and (max-width: 800px) {
	#app {
		min-width: 0px;
	}
}
.loginBg {
	padding: 0 !important;
	z-index: 10000;
	overflow: hidden !important;
}
.loginBg .loginWrap {
	pointer-events: none;
}
.loginContainer {
}
</style>
