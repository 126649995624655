import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/MallProdInquiry/'; // 제작 문의
const domainComment = '/MallManagement/MallProdInquiryComment/'; // 제작 문의
const domainLogo = '/MallManagement/MallProdLogo/'; // 로고 제작 관리

// 몰 제작 요청자 연락처 조회
const getMallProdInquiryPhone = async data => {
	const response = await instance.get(`${domain}getMallProdInquiryPhone`);
	return response;
};

// 몰 제작 문의 등록
const insertMallProdInquiry = async data => {
	const response = await instance.post(`${domain}insertMallProdInquiry`, data);
	return response;
};

// 몰 제작 문의 목록 조회
const getMallProdInquiryList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallProdInquiryList${param}`);
	return response;
};

// 몰 제작 문의 상세 조회
const getMallProdInquiryDetail = async data => {
	const response = await instance.get(`${domain}getMallProdInquiryDetail/${data}`);
	return response;
};

// 몰 제작 문의 댓글 등록
const insertMallProdInquiryComment = async data => {
	const response = await instance.post(`${domainComment}insertMallProdInquiryComment`, data);
	return response;
};

// 몰 제작 문의 댓글 삭제
const deleteMallProdInquiryComment = async data => {
	const response = await instance.post(`${domainComment}deleteMallProdInquiryComment`, data);
	return response;
};

// 몰 로고 제작 요청 등록
const insertMallProdLogo = async data => {
	const response = await instance.post(`${domainLogo}insertMallProdLogo`, data);
	return response;
};

// 몰 로고 제작 파일 등록
const uploadMallProdLogoAttach = async data => {
	const response = await instance.post(`${domainLogo}uploadMallProdLogoAttach`, data);
	return response;
};

// 몰 로고 제작 요청 목록 조회
const getMallProdLogoList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainLogo}getMallProdLogoList${param}`);
	return response;
};

// 몰 로고 제작 요청 상세 조회
const getMallProdLogoDetail = async data => {
	const response = await instance.get(`${domainLogo}getMallProdLogoDetail/${data}`);
	return response;
};


export {
	getMallProdInquiryPhone,
	insertMallProdInquiry,
	getMallProdInquiryList,
	getMallProdInquiryDetail,
	insertMallProdInquiryComment,
	deleteMallProdInquiryComment,
	insertMallProdLogo,
	uploadMallProdLogoAttach,
	getMallProdLogoList,
	getMallProdLogoDetail,
};
