import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/MallReview/';

// 몰 리뷰 관리 등록
const insertMallReview = async data => {
	const response = await instance.post(`${domain}insertMallReview`, data);
	return response;
};

// 몰 리뷰 관리 목록 조회
const getMallReviewList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallReviewList${param}`);
	return response;
};

// 몰 리뷰 관리 상태값 카운트 조회
const getMallReviewStatusCount = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMallReviewStatusCount${param}`);
	return response;
};


// 몰 리뷰 관리 리뷰 이벤트 진행중인 아이디 조회
const getProcReviewEventId = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getProcReviewEventId${param}`);
	return response;
};

// 몰 리뷰 관리 상세조회
const getMallReviewDetail = async data => {
	const response = await instance.get(`${domain}getMallReviewDetail/${data}`);
	return response;
};

// 몰 리뷰 관리 수정
const updateMallReview = async data => {
	const response = await instance.post(`${domain}updateMallReview`, data);
	return response;
};

export {
	insertMallReview,
	getMallReviewList,
	getMallReviewStatusCount,
	getProcReviewEventId,
	getMallReviewDetail ,
	updateMallReview,
};
