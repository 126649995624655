<template>
	<div
		class="backColorWhite mt5 borderRadi5Px borderContColor1 cal_main_cont3"
		style="padding: 5px 20px 5px 20px"
	>
		<h3
			class="mainBlue2 mainFontS lh32"
			style="border-bottom: 1px solid #007ef2"
		>
			서비스 정보 (D)
		</h3>
		<div class="disFx mt10">
			<table class="amt_table1 w50P">
				<tr>
					<td>기기 보험</td>
					<td>
						<div class="copyClass4" @click="addSvcVal(formData.telecomId)">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox2 amtNum cur_p disGray"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@click="addSvcPopFnc('보험 선택')"
								v-model.trim="formData.insAmt"
								:disabled="!formData.telecomId"
								readonly
							/>
							<span
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
								>{{
									formData.insName ? formData.insName : '선택해 주세요.'
								}}</span
							>
						</div>
					</td>
				</tr>
				<tr>
					<td>부가서비스</td>
					<td>
						<div class="copyClass1" @click="addSvcVal(formData.telecomId)">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_p disGray"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@click="addSvcPopFnc('부가서비스 선택')"
								v-model.trim="formData.addSvcAmt"
								:disabled="!formData.telecomId"
								readonly
							/>
						</div>
					</td>
				</tr>
			</table>
			<table class="amt_table1 w50P ml40">
				<tr>
					<td style="height: 52px; line-height: 16px">
						유심<br /><span class="subFontS11 mainGray1 normalWt"
							>1회만 납부</span
						>
					</td>
					<td>
						<div class="copyClass4" @click="addSvcVal(formData.telecomId)">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox2 amtNum cur_p disGray"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@click="addSvcPopFnc('유심 선택')"
								:disabled="!formData.telecomId"
								v-model.trim="usimAmt"
								readonly
							/>
							<span
								v-if="formData.usimPaymentType"
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								{{ usimName }}
							</span>
							<span
								v-else
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								선택해 주세요.
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td></td>
					<td class="h40"></td>
				</tr>
			</table>
		</div>

		<div class="addSvcArrBox1 mt10" v-if="addSvcIdArray.length > 0">
			<div
				class="disFx justify-space-between"
				v-for="(item, index) in addSvcIdArray"
				:key="index"
			>
				<div class="disFx">
					<span class="addSvcNameSpan1">
						{{ addSvcIdArray.length > 0 ? addSvcNameSet(item) : null }}
					</span>
					<span style="margin-left: 3px" v-if="addSvcIdArray.length > 0">
						({{
							String(addSvcPriceSet(item)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}}
						원)
					</span>
				</div>
				<button
					style="display: flex; align-self: center"
					@click="addSvcDelFnc(addSvcIdArray, item)"
				>
					<img src="../../../../../../assets/img/close_Btn1.png" alt="" />
				</button>
			</div>
		</div>

		<add-service-pop
			v-if="addSvcDialog"
			:title="addSvcPopTitle"
		></add-service-pop>
	</div>
</template>

<script>
import AddServicePop from './popup/AddServicePop.vue';
import NumRepMixin from '../../../../../../common/NumRepMixin.js';

export default {
	name: 'AddServiceData',
	mixins: [NumRepMixin],
	components: {
		AddServicePop,
	},
	computed: {
		addSvcDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcDialog = newValue;
			},
		},
		addSvcPopTitle: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcPopTitle;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcPopTitle = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		addSvcIdArray: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcIdArray;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcIdArray = newValue;
			},
		},
		usimAmt() {
			// 유심요금
			if (!this.formData.usimPaymentType) {
				return 0;
			} else {
				if (this.formData.usimPaymentType === 'EXIST_USIM') {
					return 0;
				} else if (this.formData.usimPaymentType === 'PRE_PAY') {
					return 7700;
				} else if (this.formData.usimPaymentType === 'POST_PAY') {
					return 7700;
				} else {
					return 0;
				}
			}
		},
		usimName() {
			if (!this.formData.usimPaymentType) {
				return null;
			} else {
				if (this.codeList.usimPaymentTypeItemList) {
					let usim = this.codeList.usimPaymentTypeItemList.find(
						i => i.value === this.formData.usimPaymentType,
					);
					return usim.name;
				} else {
					return '정보없음';
				}
			}
		},
	},
	methods: {
		addSvcPopFnc(title) {
			this.addSvcDialog = true;
			this.addSvcPopTitle = title;
		},
		addSvcNameSet(svcId) {
			if (this.addSvcIdArray.length > 0) {
				let addSvcName = this.codeList.addServiceItemList.find(
					i => i.addSvcId === svcId,
				);
				return addSvcName.addSvcName;
			}
		},
		addSvcPriceSet(svcId) {
			if (this.addSvcIdArray.length > 0) {
				let addSvcName = this.codeList.addServiceItemList.find(
					i => i.addSvcId === svcId,
				);
				return addSvcName.addSvcCharge;
			}
		},
		addSvcDelFnc(addSvcIdArray, addSvcId) {
			for (let data in addSvcIdArray) {
				if (addSvcIdArray[data] === addSvcId) {
					const minusAmt = this.addSvcPriceSet(addSvcIdArray[data]);
					this.formData.addSvcAmt -= minusAmt;
					return addSvcIdArray.splice(data, 1);
				}
			}
			this.amtRepFnc();
		},
		addSvcVal(telId) {
			if (!telId) {
				alert('통신사를 선택해 주세요.');
			}
		},
	},
	created() {
		this.addSvcIdArray = [];
		this.addSvcDialog = false;
	},
};
</script>

<style scoped>
.cal_main_cont3 {
	position: relative;
}
.cal_main_cont3:before {
	position: absolute;
	content: '';
	display: block;
	width: 1px;
	height: 90px;
	top: 55px;
	left: 328px;
	background-color: #007ef2;
}
.addSvcArrBox1 {
	display: flex;
	flex: 1 1 30%;
	flex-wrap: wrap;
}
.addSvcArrBox1 > div {
	width: 190px;
	color: #fff;
	background-color: #007ef2;
	border-radius: 10px;
	margin: 2px 5px;
	line-height: 26px;
	padding: 0 10px;
}
.addSvcNameSpan1 {
	font-weight: bolder;
	font-size: 12px;
	max-width: 75px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
