var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commonInput disFX"},[_c('div',{staticStyle:{"flex-grow":"1","width":"100%","display":"inline-flex"}},[_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.selected),expression:"selected",modifiers:{"trim":true}}],staticClass:"fw500",staticStyle:{"flex-grow":"1","width":"100%","cursor":"pointer"},attrs:{"disabled":_vm.disableYN ||
				(this.moduleName === 'CustomerDbModule' && _vm.title === '개통') ||
				(this.moduleName === 'CustomerDbModule' && _vm.title === '물류') ||
				(this.moduleName === 'ConsultDbModule' && _vm.title === '개통') ||
				(this.moduleName === 'ConsultDbModule' && _vm.title === '물류')},on:{"input":function($event){return _vm.setNewValue($event.target.value, $event.target)},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.title)+"을 선택해 주세요")]),_vm._l((_vm.optionList),function(option,index){return (_vm.selected !== 'DLVR_PEND' && option.value !== 'DLVR_PEND')?_c('option',{key:index,class:{
					backColorGray1:
						(option.value.includes('COMPL') &&
							option.value !== 'RCPT_COMPL' &&
							_vm.title === '상담') ||
						option.value === 'EXCHANGE_RCPT_DLVR_CONSULT' ||
						_vm.consultDbChk(option.value),

					mainWhite:
						(option.value.includes('COMPL') &&
							option.value !== 'RCPT_COMPL' &&
							_vm.title === '상담') ||
						option.value === 'EXCHANGE_RCPT_DLVR_CONSULT' ||
						_vm.consultDbChk(option.value),
				},domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()}),_vm._l((_vm.optionList),function(option,index){return (_vm.selected === 'DLVR_PEND')?_c('option',{key:index,class:{ backColorBlue2: option.value.includes('COMPL') },domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }