import { instance } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = '/DeviceManagement/MoveStockMgmt/';

// 이동현황
const getMoveStatusList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMoveMgmtList${param}`);
	return response;
};

// 유형별 이동현황
const getMoveMgmtGroupList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMoveMgmtGroupList${param}`);
	return response;
};

// 이동이력
const getMoveHistoryList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getMoveMgmtHistoryList${param}`);
	return response;
};

export { getMoveStatusList, getMoveMgmtGroupList, getMoveHistoryList };
