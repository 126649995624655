import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/SalesManagement/ConsultMgmt/';

// 고객 DB 목록
const customerList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}customerList${param}`);
	return response;
};
// 고객 DB 1차, 2차 등록한 상담원 목록
const customerMemberGroupList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}customerMemberGroupList${param}`,
	);
	return response;
};
// 고객 DB/고객상담 등록된 상태조회
const customerStatusGroupList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}customerStatusGroupList${param}`,
	);
	return response;
};
// 고객 DB/고객상담 등록된 개통점 조회
const customerOpeningGroupList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}customerOpeningGroupList${param}`,
	);
	return response;
};
// 고객 DB/고객상담 등록된 조직 조회
const customerOrganizationGroupList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}customerOrganizationGroupList${param}`,
	);
	return response;
};
// 고객 DB/고객상담 등록된 조직 조회
const customerChargeGroupList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}customerChargeGroupList${param}`,
	);
	return response;
};
// 고객 DB 그룹카운트 조회
const customerStatusGroupCountList = async (data) => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}customerStatusGroupCountList${param}`,
	);
	return response;
};
// TO-DO요금제 필터조회
const consultTodoChargeGroup = async (data) => {
	let param = '';
	if (data) {
		param = data.consultType;
	}
	const response = await instance.get(
		`${domain}consultTodoChargeGroup/${param}`,
	);
	return response;
};
// 상담원배정
const insertConsultAssign = async (data) => {
	const response = await instance.post(
		`${domain}insertConsultAssign/CUSTOMER`,
		data,
	);
	return response;
};

// 고객DB 엑셀다운로드 권한 확인 API
const excelDownAuthCheck = async (data) => {
	// let param = '';
	// if (data) param = jsonToQueryString(data);
	const response = await instance.get(`/user/excelDownAuthCheck`);
	return response;
};

export {
	customerList,
	customerMemberGroupList,
	customerStatusGroupList,
	customerOpeningGroupList,
	customerOrganizationGroupList,
	customerStatusGroupCountList,
	customerChargeGroupList,
	consultTodoChargeGroup,
	insertConsultAssign,
	excelDownAuthCheck,
};
