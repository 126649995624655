import Vue from 'vue';
import Vuex from 'vuex';
import {
	commonCodeEnumList,
	insertList,
	insertWaitList,
	getList,
	getTransStoreList,
	deleteList,
} from '@/api/warehouse-mgmt/device-mgmt/InStockRegiApi';
import {
	getDeviceColorList,
	getGoodsSelectCapacityList,
	getGoodsSelectList,
	selectCodeList,
} from '@/api/common/common';
import { commonCodeListFnc } from '@/common/common';
import { updateInStock } from '@/api/warehouse-mgmt/device-mgmt/InStockStatusApi';

Vue.use(Vuex);

const InStockRegiModule = {
	namespaced: true,
	state: {
		registDialog: false,
		excelUploadDialog: false,
		waitUpdDialog: false,
		allCheckedBox: '',
		checkListIdArray: [],
		getModelListData: [],
		getListData: [],
		formData: {
			orderDate: null,
			transStoreId: null,
			ddctAmt: null,
			extrrStatus: 'T',
			inStockAmt: null, // 입고금액
			inStockMemo: null, // 입고메모
			inStockStatus: 'NORMAL',
			missProduct: null,
			productFaultyYn: 'N',
			productMissYn: null,
			provId: null,
			telecom: null,
			stockId: null,
			goodsId: null,
			ownStoreId: null,
			addDdctAmt: null,
			unLockYn: 'N',
			barcode: null,
			barcodeType: 'K',
			inStockType: 'INSTOCK_TYPE_01',
		},
		formDataM: {
			goodsId: null,
			capacity: null,
			colorName: null,
			inStockMemo: null,
			barcode: null,
		},
		codeList: {
			stockItems: [],
			goodsItems: [],
			capacityItems: [],
			colorItems: [],
			provItems: [],
			telecomItems: [],
			stockListItems: [],
			transItems: [],
		},
	},
	mutations: {
		itemsInit(state) {
			state.formData.provIds = null;
			state.formData.stockIds = null;
			state.formData.goodsId = null;
			state.formData.telecoms = null;
			state.formData.capacity = null;
			state.formData.colorName = null;
			state.formData.barcode = null;
			state.formData.inStockMemo = null;
			state.formData.unLockYn = 'N';
			state.checkListIdArray = [];
			state.codeList.goodsItems = [];
		},
		setCommonCodeNewList(state, result) {},
		resultListInit(state) {
			state.getListData = [];
			state.checkListIdArray = [];
			state.allCheckedBox = '';
		},
		setCommonCodeList(state, data) {
			state.codeList.telecomItems = data.codeList.TELECOM;
			state.codeList.provItems = data.provList;
			state.codeList.stockListItems = data.stockList;
		},
		selectListSet(state, data) {
			state.codeList.stockItems = data.data.data;
		},
		goodsItemsSet(state, result) {
			state.codeList.goodsItems = result.data.data;
			let list = state.codeList.goodsItems;
			for (let item in list) {
				list[item].goodsName = list[item].goodsName.concat(
					' ',
					'(',
					list[item].modelName,
					')',
				);
			}
		},
		goodsCapacitySet(state, result) {
			state.codeList.capacityItems = result.data.data;
		},
		goodsColorList(state, result) {
			state.codeList.colorItems = result.data.data;
		},
		getListDataSet(state, result) {
			state.getListData = result.data.data.inStockWaitDtoList;
			state.getModelListData = result.data.data.inStockWaitGroupDtoList;
		},
		transItemsSet(state, data) {
			state.codeList.transItems = data.data.data;
		},
	},
	actions: {
		async getEnumCommonCodeList({ commit }) {
			try {
				/* 신규 ENUM 데이터 */
				const newEnum = {
					initEnumData: ['InStockType'],
				};
				const result = await commonCodeEnumList(newEnum);
				if (result) {
					commit('setCommonCodeNewList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getCommonCodeList({ commit }) {
			try {
				let data = { code: ['TELECOM'], initData: ['provList', 'stockListI'] };
				const result = await commonCodeListFnc(data);
				if (result) {
					commit('setCommonCodeList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 셀렉트 리스트콜
		async selectCodeList({ commit }, data) {
			const result = await selectCodeList(data);
			if (result) {
				commit('selectListSet', result);
			}
		},
		// 상품 셀렉트 목록 조회
		async getGoodsSelectList({ commit }, data) {
			const result = await getGoodsSelectList(data);
			if (result) {
				commit('goodsItemsSet', result);
			}
		},
		// 상품 용량 셀렉트 목록 조회
		async getGoodsSelectCapacityList({ commit }, data) {
			const result = await getGoodsSelectCapacityList(data);
			if (result) {
				if (result.data.resultCode === '0000') {
					commit('goodsCapacitySet', result);
				}
				return result;
			}
		},
		// 상품 색상 목록 조회
		async getDeviceColorList({ commit }, data) {
			const result = await getDeviceColorList(data);
			if (result) {
				if (result.data.resultCode === '0000') {
					commit('goodsColorList', result);
					return result;
				}
			}
		},
		// 입고 대기 목록 post (입고 대기 등록은 form 에서 insert)
		async insertWaitList({ commit }, formData) {
			try {
				const result = await insertWaitList(formData);
				if (result.data.status === 200) {
					if (result.data.status === 200) {
						if (result.data.resultCode !== '0000') {
							alert(result.data.resultMsg);
						}
					}
				}
				return result;
			} catch (e) {
				console.log(e);
				alert(`알 수 없는 오류가 발생했습니다.`);
			}
		},
		/* 등록 (입고 등록은 리스트에서 insert) */
		async insertList({ commit }, formData) {
			try {
				const result = await insertList(formData);
				if (result.data.status === 200) {
					alert('입고되었습니다.');
				}
				// null 체크
			} catch (e) {
				console.log(e);
				alert(`알 수 없는 오류가 발생했습니다.`);
			}
		},
		// 리스트콜
		async getList({ commit }, data) {
			try {
				switch (data) {
					case 'NORMAL':
						data = 'INSTOCK_TYPE_01';
						break;
					case 'OPEN':
						data = 'INSTOCK_TYPE_02';
						break;
				}
				// 입고 대기리스트, 체크박스리스트, 전체 체크박스 초기화
				commit('resultListInit');
				let result = await getList(data);
				if (result) {
					commit('getListDataSet', result);
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 이관처 목록 조회
		async getTransStoreList({ commit }, data) {
			try {
				const result = await getTransStoreList(data);
				if (result) {
					commit('transItemsSet', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 삭제
		async deleteList({ commit }, data) {
			try {
				if (data.length === 0) return alert('삭제 할 기기정보를 선택해주세요.');
				const checklist = {
					waitId: data,
				};
				const conText = '삭제하시겠습니까?';
				if (confirm(conText) === true) {
					const result = await deleteList(checklist);
					if (result.data.status === 200) {
						alert('삭제되었습니다.');
						return true;
					} else {
						alert(`알 수 없는 오류가 발생했습니다.`);
						return false;
					}
				}
			} catch (error) {
				console.log(error);
				alert(`알 수 없는 오류가 발생했습니다.`);
			}
		},
		async updateInStock({ commit }, data) {
			try {
				const result = await updateInStock(data);
				if (result) {
					if (result.data.status === 200) {
						if (result.data.resultCode !== '0000') {
							alert(result.data.resultMsg);
						} else {
							alert('수정되었습니다.');
						}
					}
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default InStockRegiModule;
