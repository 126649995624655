import Vue from 'vue';
import Vuex from 'vuex';
import {getOrganizationCompl} from "../../../api/sales-mgmt/SalesStatsApi";
Vue.use(Vuex);

const SalesStatsModule = {
	namespaced: true,
	state: {
		orgUserApplComplTotal: {
			totalCnt: 0,
			totalRcptComplCnt: 0,
			totalOpenComplCnt: 0,
			totalRetractComplCnt: 0,
		},
		orgUserApplComplList: [],
		orgMemberList: [],
		orgRcptComplCntList: [],
		orgOpenComplCntList: [],
		orgRetractComplCntList: [],
	},
	mutations: {
		setOrganizationCompl(state, result) {
			state.orgUserApplComplTotal.totalCnt = result.totalCnt || 0;
			state.orgUserApplComplTotal.totalRcptComplCnt = result.totalRcptComplCnt || 0;
			state.orgUserApplComplTotal.totalOpenComplCnt = result.totalOpenComplCnt || 0;
			state.orgUserApplComplTotal.totalRetractComplCnt = result.totalRetractComplCnt || 0;
			state.orgUserApplComplList = result.orgUserApplComplList;
			if(result.orgUserApplComplList !== null && result.orgUserApplComplList.length > 0) {
				result.orgUserApplComplList.forEach(item => {
					state.orgMemberList.push(item.memberName);
					state.orgRcptComplCntList.push(item.rcptComplCnt);
					state.orgOpenComplCntList.push(item.openComplCnt);
					state.orgRetractComplCntList.push(item.retractComplCnt);
				})
			}
		},
		setOrganizationComplInit(state) {
			state.orgUserApplComplTotal = {
				totalCnt: 0,
				totalRcptComplCnt: 0,
				totalOpenComplCnt: 0,
				totalRetractComplCnt: 0,
			}
			state.orgUserApplComplList = [];
			state.orgMemberList = [];
			state.orgRcptComplCntList = [];
			state.orgOpenComplCntList = [];
			state.orgRetractComplCntList = [];
		}
	},
	actions: {
		async getOrganizationCompl({ commit }, data) {
			try {
				commit('setOrganizationComplInit');
				const result = await getOrganizationCompl(data);
				if (result) {
					commit('setOrganizationCompl', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default SalesStatsModule;
