<template>
	<div class="mt20 w100P" :style="componentStyle">
		<div class="mt20 posFix">
			<div class="disFx justify-space-between" style="padding-right: 5px">
				<h2 class="mainBlack2 disIN">신청서 템플릿</h2>
			</div>
			<div
				id="taskArea"
				class="invisibleScroll w100P mt-1 borderRadi5Px borderContColor1 mb-1 bgWhite"
				style="overflow-y: auto; max-height: 500px"
				@mouseover="ableToScroll = true"
				@mouseleave="ableToScroll = false"
				@scroll="taskScroll"
				v-if="applicationTemplateList.length > 0"
			>
				<!--			fas fa-chevron-down-->
				<div v-if="ableToScroll" style="position: absolute; top: 35px">
					<i
						v-if="scrollUp"
						class="fas fa-sort-up arrowUp scrollArrow"
						aria-hidden="true"
						style="top: 5px; left: 150px"
					></i>
					<i
						v-if="scrollDown"
						class="fas fa-sort-down arrowDown scrollArrow"
						aria-hidden="true"
						style="top: 761px; left: 150px"
					></i>
				</div>
				<div
					class="disFX pd10 font-size-12"
					style="border-bottom: 1px solid #ddd"
				>
					<div class="w100P tempDevice1 cur_p" style="line-height: 12px">
						<div
							v-for="item in applicationTemplateList"
							style="padding: 5px 2px 5px 2px"
							class="disFx justify-space-between"
							:class="{ tempSelTit: item.applTempId === templateSeq }"
						>
							<div
								class="disFx justify-space-between w80P"
								style="line-height: 14px"
							>
								<span
									class="font-btn-color disIn w100P"
									style="
										display: inline-block;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									"
									@click="getTemplateData(item.applTempId)"
									>{{ item.templateTitle }}</span
								>
							</div>
							<span @click="deleteTemplateData(item.applTempId)"
								><img
									style="width: 12px; vertical-align: top; margin-top: 1px"
									src="../assets/img/close_Btn2.png"
							/></span>
							<!--							<span class="mainBlack3 w20P" style="text-align: right; display: inline-block; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;">등록자</span>-->
						</div>
					</div>
				</div>
			</div>
			<div
				v-else
				class="disFX bgWhite mt-1 pd10"
				style="border-bottom: 1px solid #ddd"
			>
				<div class="w100P tempDevice1">데이터가 없습니다.</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from '@/common/bus';

export default {
	name: 'ApplTemplate',
	props: {
		componentStyle: {
			default:
				'position: fixed; max-width: 330px; max-height: 90vh; margin-left: 10px;',
		},
	},
	data: () => ({
		scrollUp: false,
		scrollDown: false,
		ableToScroll: false,
	}),
	computed: {
		formData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.formData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.formData = newValue;
			},
		},
		applicationTemplateList: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.applicationTemplateList;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.codeList.applicationTemplateList =
					newValue;
			},
		},
		templateSeq() {
			return this.$store.state.ApplicationFormCreationModule.templateSeq;
		},
	},
	methods: {
		taskScroll() {
			let taskArea = document.getElementById('taskArea');
			taskArea.scrollTop > 0 ? (this.scrollUp = true) : (this.scrollUp = false);
			taskArea.scrollHeight - taskArea.scrollTop - 1 <= taskArea.clientHeight
				? (this.scrollDown = false)
				: (this.scrollDown = true);
		},
		async getTemplateData(applTempId) {
			if (applTempId !== this.templateSeq) {
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getApplicationTemplate',
					applTempId,
				);
			}
		},
		async deleteTemplateData(applTempId) {
			if (confirm('삭제하시겠습니까?')) {
				let data = { applTempId: applTempId };
				await this.$store.dispatch(
					'ApplicationFormCreationModule/deleteApplicationTemplate',
					data,
				);
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getApplicationTemplateList',
				);
				await bus.$emit('applReset');
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getSaleStoreList',
				);
			}
		},
	},
};
</script>

<style scoped>
.posFix {
	width: 100%;
	max-width: 338px;
	max-height: 90vh;
}
.tempDevice1 > div {
	margin-top: 10px;
}
.tempDevice1 > div:first-child {
	margin-top: 0;
}
.tempSelTit {
	font-weight: bold;
	background-color: #d9f1ee;
	border: 1px solid #eaeaea;
	border-radius: 3px;
}
</style>
