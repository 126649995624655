import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/SellRuleMgmt/';
const domainGroup = '/MallManagement/SellRuleGroupMgmt/';

// 판매 정책 등록
const insertSellRule = async data => {
	const response = await instance.post(`${domain}insertSellRule`, data);
	return response;
};

// 판매 정책 수정
const updateSellRule = async data => {
	const response = await instance.post(`${domain}updateSellRule`, data);
	return response;
};

// 판매 정책 수정
const deleteSellRule = async data => {
	const response = await instance.post(`${domain}deleteSellRule`, data);
	return response;
};

// 판매 정책 목록 조회
const getSellRuleList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSellRuleList${param}`);

	return response;
};

// 판매 상품에서 정책 목록 조회(팝업)
const getSellRuleListFromSellGoods = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getSellRuleListFromSellGoods${param}`,
	);

	return response;
};

// 현재 개통점으로 등록 및 진행중인 판매 정책 데이터 확인
const isPresentOpeningStoreSellRule = async data => {
	const response = await instance.post(
		`${domain}isPresentOpeningStoreSellRule`,
		data,
	);
	return response;
};

// 판매 정책 상세 조회
const getSellRule = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSellRule${param}`);
	return response;
};

// 판매정책 등급별 마진 신규
const insertSellRuleGroupMargin = async data => {
	const response = await instance.post(
		`${domainGroup}insertSellRuleGroup`,
		data,
	);
	return response;
};

// 판매정책 등급별 마진 수정
const updateSellRuleGroupMargin = async data => {
	const response = await instance.post(
		`${domainGroup}updateSellRuleGroup`,
		data,
	);
	return response;
};

// 판매정책 등급별 마진 삭제
const deleteSellRuleGroupMargin = async data => {
	const response = await instance.post(
		`${domainGroup}deleteSellRuleGroup`,
		data,
	);
	return response;
};

// 판매정책 영업점별 등급 부여
const updateSellRuleGroupStore = async data => {
	const response = await instance.post(
		`${domainGroup}updateSellRuleGroupStore`,
		data,
	);
	return response;
};

// 판매점 그룹마진 가져오기
const getSellRuleGroupMarginList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainGroup}getSellRuleGroupList${param}`,
	);
	return response;
};

// 판매 정책 그룹 관리점 목록 조회
const getSellRuleGroupStoreList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainGroup}getSellRuleGroupStoreList${param}`,
	);
	return response;
};

// 개통점의 판매 정책 그룹 마진 목록 조회
const getOpenStoreSellRuleGroupMarginList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domainGroup}getOpenStoreSellRuleGroupList${param}`,
	);
	return response;
};

// 개통점의 판매 정책 그룹 정보를 신규 판매 정책에 적용
const setOpenStoreSellRuleGroupInfo = async data => {
	const response = await instance.post(
		`${domainGroup}setOpenStoreSellRuleGroupInfo`,
		data,
	);
	return response;
};

// 판매정책 양식 조회
const getSellRuleTemplateList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getSellRuleTemplateList${param}`,
	);
	return response;
};

// 판매정책 양식 저장
const insertSellRuleTemplate = async data => {
	const response = await instance.post(`${domain}insertSellRuleTemplate`, data);
	return response;
};

// 판매정책 양식 수정
const updateSellRuleTemplate = async data => {
	const response = await instance.post(`${domain}updateSellRuleTemplate`, data);
	return response;
};

// 판매정책 양식 삭제
const deleteSellRuleTemplate = async data => {
	const response = await instance.post(`${domain}deleteSellRuleTemplate`, data);
	return response;
};

// 판매정책 배포여부 변경
const updateSellRuleDeployYn = async data => {
	const response = await instance.post(`${domain}updateSellRuleDeployYn`, data);
	return response;
};

// 판매정책 목록 사용여부 수정
const updateSellRuleUseYn = async data => {
	const response = await instance.post(`${domain}updateSellRuleUseYn`, data);
	return response;
};

// 판매정책 기존 저장된 구간별 요금제 정보를 전달한다.
const getSellRuleChargeRangeList = async data => {
	let param = '';
	if (data.telecomId) param = jsonToQueryString(data);
	if (data.sellRuleId) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getSellRuleChargeRangeList${param}`,
	);
	return response;
};

// 분류별 추가 지급하도록 설정한 마진을 조회한다
const getSellRuleGroupMarginInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`/MallManagement/SellRuleGroupMarginMgmt/getSellRuleGroupMarginInfo${param}`,
	);
	return response;
};

// 판매정책 그룹별 마진등록
const insertSellRuleGroupMarginSheet = async data => {
	const response = await instance.post(`/MallManagement/SellRuleGroupMarginMgmt/insertSellRuleGroupMargin`, data);
	return response;
};

// 판매 정책 통신사별 개통점 설정
const setSellRuleOpenStore = async data => {
	const response = await instance.post(`${domain}setSellRuleOpenStore`, data);
	return response;
};

// 판매 정책 통신사별 개통점 조회
const getSellRuleOpenStoreList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getSellRuleOpenStoreList${param}`,
	);
	return response;
};

// 판매 정책 통신사별 개통점 조회
const getOpenStorePrevSellRule = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getOpenStorePrevSellRule${param}`,
	);
	return response;
};

// 판매 정책 최대 지원금 설정
const insertSellRuleMaxSup = async data => {
	const response = await instance.post(`${domain}insertSellRuleMaxSup`, data);
	return response;
};

// 판매 정책 최대 지원금 조회
const getSellRuleMaxSup = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getSellRuleMaxSup${param}`,
	);
	return response;
};


export {
	getSellRule,
	insertSellRule,
	getSellRuleList,
	getSellRuleListFromSellGoods,
	isPresentOpeningStoreSellRule,
	insertSellRuleGroupMargin,
	updateSellRuleGroupMargin,
	deleteSellRuleGroupMargin,
	updateSellRuleGroupStore,
	getSellRuleGroupMarginList,
	getOpenStoreSellRuleGroupMarginList,
	getSellRuleGroupStoreList,
	setOpenStoreSellRuleGroupInfo,
	getSellRuleTemplateList,
	deleteSellRuleTemplate,
	insertSellRuleTemplate,
	updateSellRuleTemplate,
	updateSellRule,
	deleteSellRule,
	updateSellRuleDeployYn,
	updateSellRuleUseYn,
	getSellRuleChargeRangeList,
	getSellRuleGroupMarginInfo,
	insertSellRuleGroupMarginSheet,
	setSellRuleOpenStore,
	getSellRuleOpenStoreList,
	getOpenStorePrevSellRule,
	insertSellRuleMaxSup,
	getSellRuleMaxSup,
};
