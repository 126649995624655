import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';

import {
	getComplainTemplateRecipList,
	getComplainRecipDetail,
	insertComplainTemplate,
	updateComplainTemplate,
	deleteComplainTemplate,
	getComplainList,
	getComplainDetail,
	insertComplain,
	updateComplain,
	insertComplainDetail,
	getComplainAttachMgmtList,
	updateComplainAttachMgmt,
	deleteComplainAttachMgmt,
	getComplainStatusCnt,
	deleteComplain,
} from '../../../../api/comm-mgmt/complain-hndlg/ComplainHndlgApi';
import {
	getOpeningStoreList,
	getSaleStoreList,
} from '@/api/supply-chain-mgmt/sell-status/QuickOpeningApi';

Vue.use(Vuex);

const ComplainHndlgModule = {
	namespaced: true,
	state: {
		dialog: false,
		complainInfoPop: false,
		complainRegiPop: false,
		templateMgmtPop: false,
		totalCnt: 0,
		filterData: {
			openingStoreProcStatus: [''],
			saleStoreProcStatus: [''],
			regiName: '',
			startRegiDate: '',
			endRegiDate: '',
			openingStoreId: '',
			saleStoreId: '',
			complainPath: '',
			complainType: '',
			startComplainStartDate: '',
			endComplainStartDate: '',
			startComplainHandlingEndDate: '',
			endComplainHandlingEndDate: '',
			cusName: '',
			cusPhone: '',
			startComplDate: '',
			endComplDate: '',
			startPronounceDate: '',
			endPronounceDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		complainList: [],
		complainStatusCnt: {
			procCnt: 0,
			complCnt: 0,
			pronounceComplCnt: 0
		},
		complainFileList: [],
		pronounceFileList: [],
		templateAllList: [],
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		dateResetData: false,
	},
	mutations: {
		initComplainFileList(state) {
			state.complainFileList = [];
		},
		initPronounceFileList(state) {
			state.pronounceFileList = [];
		},
		setComplainList(state, result) {
			state.complainList = result.resultList;
			state.totalCnt = result.totalCnt || 0;
			state.pagingData.perPageCnt = result.perPageCnt || 0;
			state.pagingData.pageNo = result.pageNo || 0;
			state.pagingData.pageNumList = result.pageNumList || [];
			state.pagingData.pageLastNo = result.pageLastNo || 0;
			state.pagingData.pageEndNo = result.pageEndNo || 0;
		},
		setComplainStatusCnt(state, result) {
			state.complainStatusCnt = result;
		}
	},
	actions: {
		async getSaleStoreList({ commit }, data) {
			try {
				const result = await getSaleStoreList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getOpeningStoreList({ commit }, data) {
			try {
				const result = await getOpeningStoreList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 1. 민원 템플릿 리스트 조회
		async getComplainTemplateRecipList({ commit }, data) {
			try {
				const result = await getComplainTemplateRecipList(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 2. 민원 템플릿 상세 조회
		async getComplainRecipDetail({ commit }, data) {
			try {
				const result = await getComplainRecipDetail(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 3. 민원 템플릿 등록
		async insertComplainTemplate({ commit }, data) {
			try {
				const result = await insertComplainTemplate(data);
				if (result && '0000' === result.data.resultCode) {
					alert('등록되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 4. 민원 템플릿 수정
		async updateComplainTemplate({ commit }, data) {
			try {
				const result = await updateComplainTemplate(data);
				if (result && '0000' === result.data.resultCode) {
					alert('수정되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 5. 민원 템플릿 삭제
		async deleteComplainTemplate({ commit }, data) {
			try {
				const result = await deleteComplainTemplate(data);
				if (result && '0000' === result.data.resultCode) {
					alert('삭제되었습니다.');
					return true;
				} else {
					alert('오류가 발생하였습니다.');
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 6. 민원 처리 리스트 조회
		async getComplainList({ commit }, data) {
			try {
				const result = await getComplainList(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.data.data.resultList.length !== 0) {
						commit('setComplainList', result.data.data);
					}
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 7. 민원 처리 상세 조회
		async getComplainDetail({ commit }, data) {
			try {
				const result = await getComplainDetail(data);
				if (result && '0000' === result.data.resultCode) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 8. 민원 처리 등록
		async insertComplain({ commit }, data) {
			try {
				const result = await insertComplain(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 9. 민원 처리 수정
		async updateComplain({ commit }, data) {
			try {
				const result = await updateComplain(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 15. 민원 삭제
		async deleteComplain({ commit }, data) {
			try {
				const result = await deleteComplain(data);
				if (result && '0000' === result.data.resultCode) {
					alert('삭제되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 10. 민원내용 추가 등록
		async insertComplainDetail({ commit }, data) {
			try {
				const result = await insertComplainDetail(data);
				if (result) {
					return result;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 11. 민원 관련 파일 리스트 조회
		async getComplainAttachMgmtList({ commit }, data) {
			try {
				const result = await getComplainAttachMgmtList(data);
				if (result) {
					return result;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 12. 민원 관련 파일 등록
		async updateComplainAttachMgmt({ commit }, data) {
			try {
				const result = await updateComplainAttachMgmt(data);
				if (result && '0000' === result.data.resultCode) {
					alert('저장되었습니다.');
					return result;
				} else {
					alert('저장 실패하였습니다.');
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 13. 민원 관련 파일 삭제
		async deleteComplainAttachMgmt({ commit }, data) {
			try {
				const result = await deleteComplainAttachMgmt(data);
				if (result && '0000' === result.data.resultCode) {
					alert('삭제되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 14. 민원 처리 항목 상태 카운트 조회
		async getComplainStatusCnt({ commit }, data) {
			try {
				const result = await getComplainStatusCnt(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.data.data !== null) {
						commit('setComplainStatusCnt', result.data.data);
					}
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default ComplainHndlgModule;
