import Vue from 'vue';
import Vuex from 'vuex';
import {
	getCounselorList,
	getConsultCounselorStatus,
	consultList,
	consultTodoList,
	consultTodoGroupCnt,
	deleteConsult,
	insertConsultAssign,
	insertConsultCounselor,
	updateConsultCounselorStatus,
	insertConsultSetting,
	updateConsultSetting,
	deleteConsultSetting,
	consultSettingList,
	consultMemberList,
	consultTodoMemberList,
} from '@/api/sales-mgmt/ConsultDbApi';
import { commonCodeEnumList, commonCodeList } from '@/api/common/common';
import {
	consultStatusGroupList,
	consultTodoStatusGroup,
} from '@/api/sales-mgmt/CustomerConsultApi';
import { getSalesRetentionDetailInfo } from '@/api/sales-mgmt/RetentionDbApi';
Vue.use(Vuex);

const ConsultDbModule = {
	namespaced: true,
	state: {
		excelDate: null,
		allTodoCheckedBox: false,
		dateResetData: false,
		checkListTodoIdArray: [],
		resetData: false,
		todoResetData: false,
		consultDialog: false,
		consultRegiDialog: false,
		assignDialog: false,
		autoAssignDialog: false,
		applDialog: false,
		totalCnt: 0,
		autoYn: null,
		cancelFeeYnChk: false,

		filterData: {
			srhStartDate: '',
			srhEndDate: '',
			dateTimeOrder: '',
			consultCountOrder: '',
			orderCancelTotalAmt: '',
			cancelFeeYn: 'Y',
			perPageCnt: 20,
			pageNo: 1,
		},
		todoFilterData: {
			dateTimeOrder: '',
			orderCancelTotalAmt: '',
			srhStartDate: '',
			srhEndDate: '',
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		checkListArray: [],
		checkListArray1: [],
		todoList: [],
		mainList: [],
		memoTabValue: 'ALL',
		historyList: [],
		codeList: {
			filteredExistTelList: [],
			customerTypeItems: [],
			joinTypeItems: [],
			procStatusItems: [],
			searchTypeItems: [],
			nationalItems: [],
			stayCodeItems: [],
			existTelecomList: [],
			openStoreList: [],
			chargeList: [],
			deviceList: [],
			hopeDeviceList: [],
			capacityList: [],
			colorList: [],
			openingTaskStatusList: [],
			memberList: [],
			todoMemberList: [],
			consultStatus: [
				{ name: '신규(예약)', value: 'NEW_RESERVATION' },
				{ name: '가망/2차', value: 'PROSPECT_SECOND' },
				{ name: '신조요청', value: 'CREDIT_REQUEST' },
				{ name: '거절/불가', value: 'REJECT_IMPOSSIBLE' },
				{ name: '블랙리스트', value: 'BLACKLIST' },
				/*{ name: '신조완료', value: 'CREDIT_COMPLETE' },*/
			],
			todoFirstStatusList: [],
			todoSecondStatusList: [],
			firstStatusList: [],
			secondStatusList: [],
		},
		todoTitleCntDto: [],
		retentionDetailInfo: null, // 리텐션 DB 상세정보
	},
	mutations: {
		setMainList(state, result) {
			let resultMainList = [];
			result.data.data.resultList.forEach(item => {
				if (state.filterData.cancelFeeYn === 'Y') {
					state.cancelFeeYnChk = true;
					item.cancelTotalAmtResult = item.cancelTotalAmt;
				} else {
					item.cancelTotalAmtResult = item.cancelTotalAmtExcludeCancelFeeAmt;
					state.cancelFeeYnChk = false;
				}
				resultMainList.push(item);
			});
			state.mainList = resultMainList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		initMainList(state) {
			state.mainList = [];
			state.totalCnt = 0;
			state.checkListArray = [];
			state.checkListArray1 = [];
		},
		setCommonCodeList(state, result) {
			// 필터용 기존 통신사
			state.codeList.filteredExistTelList = result.EXISTELECOM || [];
		},
		setCommonEnumList(state, result) {
			state.codeList.openingTaskStatusList = result.data.data.OpeningTaskStatus;
			state.codeList.openingTaskStatusList.unshift({
				name: '개통요청(전체)',
				value: 'OPENING_REQ_ALL',
			});
			if (
				state.codeList.openingTaskStatusList !== null ||
				state.codeList.openingTaskStatusList !== undefined
			) {
				state.codeList.openingTaskStatusList.map(item => {
					item.name =
						item.name !== null ? item.name.replace('(개통)', '') : null;
					return item;
				});
				/*this.statusList.OpeningTaskStatus.splice(2, 0, { divider: true });*/
			}
		},
		setTodoList(state, result) {
			let resultTodoList = [];
			result.forEach(item => {
				item.cancelTotalAmtResult = item.cancelTotalAmt;
				resultTodoList.push(item);
			});
			state.todoList = resultTodoList || [];
		},
		setTodoStatusCntList(state, result) {
			state.todoTitleCntDto = result;
		},
		setAutoYn(state, result) {
			if (result === '9000') {
				state.autoYn = null;
			} else {
				state.autoYn = result;
			}
		},
		setConsultMemberList(state, result) {
			const memberItem = result.secondConsultMemberList.find(i => !i.memberId);
			if (memberItem) {
				memberItem.memberName = '미배정';
				memberItem.memberId = 0;
			}
			state.codeList.memberList = result;
		},
		setConsultStatusGroupList(state, result) {
			let first =
				result.consultFirstStatusList.forEach(item => {
					item.statusName =
						'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
				}) || [];
			let second =
				result.consultSecondStatusList.forEach(item => {
					item.statusName =
						'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
				}) || [];

			const statusItem = result.consultSecondStatusList.find(
				i => !i.cnsltStatusId,
			);
			if (statusItem) {
				statusItem.cnsltStatusId = 0;
				statusItem.statusName = '미진행';
			}

			state.codeList.firstStatusList = result.consultFirstStatusList || [];
			state.codeList.secondStatusList = result.consultSecondStatusList || [];
		},
		setConsultTodoMemberList(state, result) {
			const memberItem = result.secondConsultMemberList.find(i => !i.memberId);
			if (memberItem) {
				memberItem.memberName = '미배정';
				memberItem.memberId = 0;
			}
			state.codeList.todoMemberList = result;
		},
		setConsultTodoStatusGroup(state, result) {
			let first =
				result.consultTodoFirstTodoStatusList.forEach(item => {
					item.statusName =
						'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
				}) || [];
			let second =
				result.consultTodoSecondTodoStatusList.forEach(item => {
					item.statusName =
						'[' + item.consultProcessTypeMsg + '] ' + item.statusName;
				}) || [];

			const statusItem = result.consultTodoSecondTodoStatusList.find(
				i => !i.cnsltStatusId,
			);
			if (statusItem) {
				statusItem.cnsltStatusId = 0;
				statusItem.statusName = '미진행';
			}

			state.codeList.todoFirstStatusList =
				result.consultTodoFirstTodoStatusList || [];
			state.codeList.todoSecondStatusList =
				result.consultTodoSecondTodoStatusList || [];
		},
		setRetentionDetailInfo(state, result) {
			state.retentionDetailInfo = result;
		},
		initRetentionDetail(state) {
			state.retentionDetailInfo = null;
		},
	},
	actions: {
		async commonCodeList({ commit }) {
			const Enum = { code: ['existelecom'] };
			let result = await commonCodeList(Enum);
			if (result) {
				commit('setCommonCodeList', result.data.data.codeList);
			}
		},
		async consultList({ commit }, data) {
			try {
				if (data) {
					if (!data.pageNo || data.pageNo === '') {
						data.pageNo = 1;
					}
				}
				const result = await consultList(data);
				commit('initMainList', result);
				if (result && result.data.resultCode === '0000') {
					commit('setMainList', result);
					return true;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultSettingList({ commit }, data) {
			try {
				const result = await consultSettingList(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getCounselorList({ commit }, data) {
			try {
				const result = await getCounselorList(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultCounselorStatus({ commit }, data) {
			try {
				const result = await getConsultCounselorStatus(data);
				if (result && result.data.resultCode === '0000') {
					commit('setAutoYn', result.data.data);
					return true;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultAssign({ commit }, data) {
			try {
				const result = await insertConsultAssign(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateConsultSetting({ commit }, data) {
			try {
				const result = await updateConsultSetting(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					if (result && result.data.resultCode === '1002') {
						alert(result.data.resultMsg);
					}
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultCounselor({ commit }, data) {
			try {
				const result = await insertConsultCounselor(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateConsultCounselorStatus({ commit }, data) {
			try {
				const result = await updateConsultCounselorStatus(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},

		async insertConsultSetting({ commit }, data) {
			try {
				const result = await insertConsultSetting(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					if (result && result.data.resultCode === '1002') {
						alert(result.data.resultMsg);
					}
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteConsultSetting({ commit }, data) {
			try {
				const result = await deleteConsultSetting(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async commonCodeEnumList({ commit }) {
			const data = {
				initEnumData: ['OpeningTaskStatus'],
			};
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setCommonEnumList', result);
			}
		},
		async consultTodoList({ commit }, data) {
			try {
				let param = {
					consultType: 'CONSULT',
					param: data,
				};
				const result = await consultTodoList(param);
				if (result) {
					commit('setTodoList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoGroupCnt({ commit }, data) {
			try {
				let param = {
					consultType: 'CONSULT',
					param: data,
				};
				const result = await consultTodoGroupCnt(param);
				if (result) {
					commit('setTodoStatusCntList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoMemberList({ commit }, data) {
			try {
				let param = {
					consultType: 'CONSULT',
					param: data,
				};
				const result = await consultTodoMemberList(param);
				if (result) {
					commit('setConsultTodoMemberList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteConsult({ commit }, data) {
			try {
				const result = await deleteConsult(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultMemberList({ commit }, data) {
			try {
				const result = await consultMemberList(data);
				if (result) {
					commit('setConsultMemberList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoStatusGroup({ commit }, data) {
			try {
				let param = {
					consultType: 'CONSULT',
					param: data,
				};
				const result = await consultTodoStatusGroup(param);
				if (result) {
					commit('setConsultTodoStatusGroup', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultStatusGroupList({ commit }, data) {
			try {
				const result = await consultStatusGroupList(data);
				if (result) {
					commit('setConsultStatusGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getSalesRetentionDetailInfo({ commit }, data) {
			try {
				const result = await getSalesRetentionDetailInfo(data);
				if (result) {
					commit('setRetentionDetailInfo', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// async getAssignNeededConsultList({ commit, state }, data) {
		// 	// const result = await getCounselorList(data);
		// 	// if (result && result.data.resultCode === '0000') {
		// 	// return result.data;
		// 	// }
		// 	return state.임시assignNeededConsultList;
		// },
	},
};
export default ConsultDbModule;
