<template>
	<div>
		<div class="disFx">
			<div class="padW20" style="width: 35%">
				<div style="height: 260px; padding-top: 30px">
					<div class="txAnC">
						<img
							id="deviceImgUrl"
							:src="colorImgUrl"
							alt=""
							style="
								object-fit: cover;
								width: 150px;
								height: 200px;
								overflow-y: hidden;
							"
						/>
						<div>
							<span
								v-for="(item, index) in goodsColorList"
								:key="index"
								class="goods_color_radio1"
							>
								<input
									v-model.trim="colorImgUrl"
									type="radio"
									class="disNONE"
									name="color_code"
									:id="`colorCode${index}`"
									:value="item.colorImgUrl"
									@change="colorSelectFnc(index)"
								/>
								<label
									:for="`colorCode${index}`"
									class="cur_p"
									style="
										width: 20px;
										height: 20px;
										display: inline-block;
										margin: 0 2px;
										border-radius: 100%;
									"
									:style="{ background: item.colorCode }"
								></label>
							</span>
						</div>
					</div>
				</div>
				<div class="txAnC">
					<span style="font-weight: bolder; color: #000">
						{{ goodsName ? goodsName : '기기를 선택해 주세요.' }}
					</span>
					<br />
					<span style="font-weight: bolder" v-if="goodsId">
						({{ modelName ? modelName : '' }})
					</span>
				</div>
				<div class="device_infor_table_style1 mt20">
					<table class="w100P">
						<tr>
							<td class="w100">색상</td>
							<td>
								{{ colorName ? colorName : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>출시일</td>
							<td>
								{{ goodsInfo ? goodsInfo.releaseDate : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>CPU</td>
							<td>
								{{ goodsInfo ? goodsInfo.cpu : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>RAM</td>
							<td>
								{{ goodsInfo ? goodsInfo.ramInfo : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>메모리</td>
							<td>
								{{ goodsInfo ? goodsInfo.memoryInfo : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>디스플레이</td>
							<td>
								{{ goodsInfo ? goodsInfo.displayInfo : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>카메라</td>
							<td>
								{{ goodsInfo ? goodsInfo.rearCamInfo : '정보없음' }}
							</td>
						</tr>
						<tr>
							<td>기타</td>
							<td>
								{{ goodsInfo ? goodsInfo.etcInfo : '정보없음' }}
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="w70P ml10">
				<div class="disFx justify-space-between">
					<div class="disFx">
						<div
							class="maker_radio_style1 borderRadi3Px overflow-hidden"
							v-for="(item, index) in codeList.makerItemList"
							:key="index"
							style="background-color: #f6f8fb"
						>
							<input
								type="radio"
								:id="`maker${item.codeSeq}`"
								:value="item.codeSeq"
								name="maker"
								v-model.trim="formData.maker"
								class="disNONE"
								@change="makerChangeFnc($event.target.value, formData.network)"
							/>
							<label class="mainFontS cur_p" :for="`maker${item.codeSeq}`">{{
								item.codeNm
							}}</label>
						</div>
					</div>
					<div>
						<select
							class="lh38 borderRadi3Px borderContColor3 w150"
							@change="networkChangeFnc($event.target.value, formData.maker)"
							v-model.trim="formData.network"
						>
							<option hidden :value="null">네트워크 유형</option>
							<option
								v-for="(item, index) in codeList.networkItemList"
								:key="index"
								:value="item.codeSeq"
							>
								{{ item.codeNm }}
							</option>
						</select>
					</div>
				</div>
				<div class="disFX justify-space-between">
					<div class="mt20 posR search_icon1 w300">
						<input
								type="text"
								class="device_input_style1 w300"
								placeholder="검색어를 입력해 주세요"
								@input="textSearchFnc($event.target.value)"
								v-model.trim="searchText"
						/>
					</div>
					<div class="mt40 posR fw500 font-black mr5">
						※ 선택할 기기를 <span class="font-royalblue">'더블클릭'</span>시 바로 적용됩니다.
					</div>
				</div>
				<div class="device_table_style1 mt15 narrowScroll" id="mainTable">
					<table class="w100P">
						<tr style="position: sticky; top: 0">
							<th class="w200">기기명</th>
							<th class="w200">모델명</th>
							<th class="w110">출고가</th>
							<th class="w110">
								최대 공시지원금<span class="hoverIconNoti1">
									<div class="disIn posR">
										<v-icon
											class="posR"
											style="
												font-size: 14px;
												margin-bottom: 1px;
												margin-left: 1px;
											"
											>fas fa-exclamation-circle
										</v-icon>
										<div
											class="hoverNoti1"
											style="
												position: absolute;
												left: -200px;
												top: 10px;
												width: 200px;
												background-color: #fff;
												border: 1px solid #e0e0e0;
												padding: 5px 15px;
												z-index: 3;
											"
										>
											<span>요금제 선택시 반영됩니다.</span>
										</div>
									</div>
								</span>
							</th>
						</tr>
						<tr v-if="searchList.length === 0" style="cursor: default">
							<td class="txAnC" colspan="20">데이터가 없습니다.</td>
						</tr>
						<tr
							class="cur_p"
							v-for="(item, index) in searchList"
							:key="index"
							@click="goodsSelectFnc(item)"
							@dblclick="insertFnc(goodsName, goodsId, colorImgUrl, colorName, releaseAmt)"
							:class="{ backColorBlue6: item.goodsId === goodsId }"
						>
							<td class="mainBlack">{{ item.goodsName }}</td>
							<td>
								<div class="w120">{{ item.modelName }}</div>
							</td>
							<td class="txAnC">
								{{
									String(item.releaseAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}}
								원
							</td>
							<td class="txAnC">
								{{
									String(item.supportAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}}
								원
							</td>
						</tr>
					</table>
				</div>
				<div class="disFx justify-end mt10">
					<button
						class="backColorBlue2 mainWhite padW30 lh40 borderRadi3Px font-size-16"
						style="font-weight: bolder"
						@click="
							insertFnc(goodsName, goodsId, colorImgUrl, colorName, releaseAmt)
						"
					>
						적용
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ch2pattern } from '@/common/common';

export default {
	name: 'GoodsSelect',
	data: () => ({
		goodsId: null,
		goodsName: null,
		goodsInfo: null,
		goodsColorList: null,
		colorImgUrl:
			'https://www.sklmno.com/goods/upload/no_image_m.png',
		colorName: null,
		releaseAmt: 0,
		searchList: [],
		searchText: null,
	}),
	computed: {
		modelName() {
			const findModelName = this.codeList.goodsItemList.find(item => {
				return item.goodsId === this.goodsId;
			});
			return findModelName.modelName;
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.amtSelectDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.amtSelectDialog = newValue;
			},
		},
		chgChargeDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chgChargeDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chgChargeDto = newValue;
			},
		},
		changeDateName: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDateName;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDateName = newValue;
			},
		},
		changeDate: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDate;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDate = newValue;
			},
		},
	},
	methods: {
		goodsDataInit() {
			this.formData.maker = 0;
			this.formData.network = 0;
			this.releaseAmt = 0;
			this.goodsId = null;
			this.goodsInfo = null;
		},
		async retrieveGoodsSpecInfo(item) {
			let param = {
				goodsId: item,
			};
			const result = await this.$store.dispatch(
				'ChargeCalculatorModule/retrieveGoodsSpecInfo',
				param,
			);
			if (result) {
				this.colorImgUrl =
					'https://www.sklmno.com/goods/upload/no_image_m.png';
				this.goodsInfo = result.goodsInfo || [];
				this.goodsColorList = result.goodsColorList || [];
				if (result.goodsColorList.length > 0) {
					this.colorImgUrl = this.goodsColorList[0].colorImgUrl;
					this.colorName = this.goodsColorList[0].colorName;
				}
			} else {
				this.goodsInfo = null;
				this.goodsColorList = null;
				this.colorImgUrl =
					'https://www.sklmno.com/goods/upload/no_image_m.png';
				this.colorName = null;
			}
		},
		async getTelecomChargeList(telecomId, goodsId) {
			let param = {
				telecomId: telecomId,
				goodsId: goodsId,
			};
			await this.$store.dispatch(
				'ChargeCalculatorModule/getTelecomChargeList',
				param,
			);
		},
		async goodsSelectFnc(item) {
			this.goodsId = item.goodsId;
			this.goodsName = item.goodsName;
			this.releaseAmt = item.releaseAmt;
			this.colorImgUrl =
				'https://www.sklmno.com/goods/upload/no_image_m.png';
			await this.retrieveGoodsSpecInfo(item.goodsId);
		},
		chargeInit() {
			this.formData.chargeName = null;
			this.formData.chargeId = null;
			this.formData.monthBasicAmt = 0;
			this.formData.pubNotiSupportAmt = 0;
			this.formData.chargeReductType = null;
			this.chgChargeDto = null;
			this.changeDateName = null;
			this.changeDate = null;
		},
		async insertFnc(goodsName, goodsId, colorImgUrl, colorName, releaseAmt) {
			this.formData.goodsName = goodsName;
			this.formData.goodsId = goodsId;
			this.formData.colorImgUrl = colorImgUrl;
			this.formData.colorName = colorName;
			this.formData.releaseAmt = releaseAmt;
			if (!goodsId) {
				return alert('기기를 선택해 주세요.');
			}
			await this.chargeInit();
			await this.getTelecomChargeList(this.formData.telecomId, goodsId);
			this.dialog = false;
		},
		colorSelectFnc(idx) {
			this.colorName = this.goodsColorList[idx].colorName;
		},
		textSearchFnc(text) {
			this.searchList = [];
			let goodsList = [...this.codeList.goodsItemList];

			if (Number(this.formData.maker) && !Number(this.formData.network)) {
				goodsList = goodsList.filter(
					i => i.maker === Number(this.formData.maker),
				);
			} else if (
				!Number(this.formData.maker) &&
				Number(this.formData.network)
			) {
				goodsList = goodsList.filter(
					i => i.network === Number(this.formData.network),
				);
			} else if (Number(this.formData.maker) && Number(this.formData.network)) {
				goodsList = goodsList.filter(
					i =>
						i.maker === Number(this.formData.maker) &&
						i.network === Number(this.formData.network),
				);
			}

			let result = this.createFuzzyMatcher(text);
			if (text) {
				for (let item in goodsList) {
					if (result.test(goodsList[item].goodsName)) {
						this.searchList.push(goodsList[item]);
					}
				}
			} else {
				this.searchList = goodsList;
			}
		},
		createFuzzyMatcher(input) {
			const pattern = input.split('').map(ch2pattern).join('.*?');
			return new RegExp(pattern);
		},
		makerChangeFnc(codeSeq, network) {
			document.getElementById('mainTable').scrollTo(0, 0);

			this.searchText = null;
			const goodsList = [...this.codeList.goodsItemList];
			if (Number(codeSeq) && !Number(network)) {
				this.searchList = goodsList.filter(i => i.maker === Number(codeSeq));
			} else if (Number(codeSeq) && Number(network)) {
				this.searchList = goodsList.filter(
					i => i.maker === Number(codeSeq) && i.network === Number(network),
				);
			} else if (!Number(codeSeq) && Number(network)) {
				this.searchList = goodsList.filter(i => i.network === Number(network));
			} else {
				this.searchList = goodsList;
			}
			return this.searchList;
		},
		networkChangeFnc(codeSeq, maker) {
			document.getElementById('mainTable').scrollTo(0, 0);
			this.searchText = null;
			const goodsList = [...this.codeList.goodsItemList];
			if (Number(codeSeq) && !Number(maker)) {
				this.searchList = goodsList.filter(i => i.network === Number(codeSeq));
			} else if (Number(codeSeq) && Number(maker)) {
				this.searchList = goodsList.filter(
					i => i.network === Number(codeSeq) && i.maker === Number(maker),
				);
			} else if (!Number(codeSeq) && Number(maker)) {
				this.searchList = goodsList.filter(i => i.maker === Number(maker));
			} else {
				this.searchList = goodsList;
			}
			return this.searchList;
		},
	},
	async created() {
		await this.goodsDataInit();
		if (this.formData.goodsId) {
			const copyData = { ...this.formData };
			this.goodsId = this.formData.goodsId;
			this.goodsName = this.formData.goodsName;
			this.releaseAmt = this.formData.releaseAmt;
			await this.retrieveGoodsSpecInfo(this.formData.goodsId);
			this.colorImgUrl = copyData.colorImgUrl;
			this.colorName = copyData.colorName;
		}
		this.searchList = [...this.codeList.goodsItemList];
	},
};
</script>

<style scoped></style>
