<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="986">
				<v-card class="borderRadiReset noticeListPop" style="overflow: hidden">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2
							class="mainWhite mainFontS boldWt"
							style="font-size: 16px !important"
						>
							공지사항
						</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>
					<v-card-text style="padding: 0 10px 30px 30px !important">
						<div class="disFx">
							<div class="leftCont narrowScroll">
								<div>
									<div
										class="disFx mt20 justify-lg-space-between"
										style="align-items: center; position: sticky; z-index: 1"
									>
										<div class="pagingSelectCont disFX">
											<select
												class="pagingSelect"
												v-model.trim="perPageCnt"
												@change="selectPerPageCnt($event.target.value)"
											>
												<option value="20">20개씩</option>
												<option value="50">50개씩</option>
												<option value="100">100개씩</option>
											</select>
											<div>
												<div class="ml10 lh28 disFx cur_d">
													<div class="fakeLine6">
														<span>전체 : </span>
														<span
															class="redfont txUnL fw600 cur_p"
															@click="getSpecificNoticeType('')"
														>
															{{
																(COMMON + SALES + OPENING + IMPORTANT)
																	.toString()
																	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
															}}
														</span>
													</div>
													<div class="fakeLine6 ml20 cur_d">
														<span
															:class="{
																fw600:
																	filterData.systemNoticeTargetType ===
																	'IMPORTANT',
															}"
														>
															중요공지 :
														</span>
														<span
															class="font-red txUnL fw600 cur_p"
															@click="getSpecificNoticeType('IMPORTANT')"
															>{{
																String(IMPORTANT).replace(
																	/\B(?=(\d{3})+(?!\d))/g,
																	',',
																)
															}}</span
														>
													</div>
													<div class="fakeLine6 ml20 cur_d">
														<span
															:class="{
																fw600:
																	filterData.systemNoticeTargetType ===
																	'COMMON',
															}"
														>
															공통 :
														</span>
														<span
															class="mainOrange txUnL fw600 cur_p"
															@click="getSpecificNoticeType('COMMON')"
															>{{
																String(COMMON).replace(
																	/\B(?=(\d{3})+(?!\d))/g,
																	',',
																)
															}}</span
														>
													</div>
													<div class="fakeLine6 ml20 cur_d">
														<span
															:class="{
																fw600:
																	filterData.systemNoticeTargetType ===
																	'OPENING',
															}"
														>
															대리점(개통점) :
														</span>
														<span
															class="mainBlue2 txUnL fw600 cur_p"
															@click="getSpecificNoticeType('OPENING')"
														>
															{{
																String(OPENING).replace(
																	/\B(?=(\d{3})+(?!\d))/g,
																	',',
																)
															}}
														</span>
													</div>
													<div class="ml20 cur_d">
														<span
															:class="{
																fw600:
																	filterData.systemNoticeTargetType === 'SALES',
															}"
														>
															판매점(영업점) :
														</span>
														<span
															class="mainMint1 txUnL fw600 cur_p"
															@click="getSpecificNoticeType('SALES')"
															>{{
																String(SALES).replace(
																	/\B(?=(\d{3})+(?!\d))/g,
																	',',
																)
															}}</span
														>
													</div>
												</div>
											</div>
										</div>
										<div
											class="disFX"
											style="margin-top: 8px; transform: translateY(-8px)"
										>
											<div class="w230">
												<input
													class="newInput1"
													style="height: 38px"
													type="text"
													placeholder="제목 또는 내용을 입력해주세요."
													v-model.trim="filterData.srhKeyword"
													@keydown.enter="filterSubmit"
												/>
											</div>
											<button
												class="btnStyle1 btnShadow1 backColorMint1 w60 ml5"
												@click="filterSubmit"
											>
												조회
											</button>
										</div>
									</div>
									<div
										class="newTableStyle narrowScroll posR"
										style="width: 930px"
									>
										<table class="w100P">
											<tr class="cur_d" style="position: sticky; top: 0; z-index: 1;">
												<th class="w50">번호</th>
												<th class="w70">분류</th>
												<th>제목</th>
												<th class="w60">첨부파일</th>
												<th class="w90">등록자</th>
												<th class="w90">
													조회수
													<span
														:style="
															filterData.orderReadCnt !== ''
																? 'color:#F2C768'
																: ''
														"
														class="cur_p"
														@click="toggleOrder('ReadCnt')"
													>
														{{
															filterData.orderReadCnt === 'ASC' ||
															filterData.orderReadCnt === ''
																? '▲'
																: '▼'
														}}
													</span>
												</th>
												<th class="w90">
													등록일
													<span
														:style="
															filterData.orderRegiDateTime !== ''
																? 'color:#F2C768'
																: ''
														"
														class="cur_p"
														@click="toggleOrder('RegiDateTime')"
													>
														{{
															filterData.orderRegiDateTime === 'ASC' ||
															filterData.orderRegiDateTime === ''
																? '▲'
																: '▼'
														}}
													</span>
												</th>
											</tr>
											<tr class="cur_d" v-if="noticeList.length === 0">
												<td colspan="20">데이터가 없습니다.</td>
											</tr>
											<template v-else v-for="(item, index) in noticeList">
												<tr
													class="h40"
													@click="toggleAccodion(index, item.sysNoticeId, item)"
												>
													<td class="w50">
														{{
															totalCnt -
															pagingData.perPageCnt * (pagingData.pageNo - 1) -
															index
														}}
													</td>
													<td
														class="w120"
														:class="{
															'font-red':
																item.systemNoticeTargetType === 'IMPORTANT',
															mainOrange:
																item.systemNoticeTargetType === 'COMMON',
															mainMint1:
																item.systemNoticeTargetType === 'SALES',
															mainBlue2:
																item.systemNoticeTargetType === 'OPENING',
														}"
													>
														[ {{ item.systemNoticeTargetTypeMsg }} ]
													</td>
													<td>
														<span
															class="text-left ellipsisH disBl padW10"
															style="height: 20px"
															v-html="toHtml(item.sysNoticeTitle)"
														></span>
													</td>
													<td class="w60">
														<div v-if="item.attachCnt !== 0">
															<i
																style="font-size: 14px !important"
																class="far fa-file-alt"
															></i>
														</div>
													</td>
													<td class="w90">
														{{ item.regiUserName }}
													</td>
													<td>
														{{
															String(item.readCnt).replace(
																/\B(?=(\d{3})+(?!\d))/g,
																',',
															)
														}}
													</td>
													<td class="w90 lh16">
														{{ item.regiDateTime | moment('YYYY-MM-DD HH:mm') }}
													</td>
												</tr>
												<tr
													class="detailRow cur_d"
													:id="`detail${index}`"
													style="display: none"
												>
													<td
														colspan="7"
														class="text-left pad10"
														style="background: #f9f9f9"
													>
														<h3>제목</h3>
														<p
															style="color: rgba(0, 0, 0, 0.6)"
															class="mt5 mb10 w100P backColorWhite borderRadi3Px borderContColor2 pl-2 pr-2 font-size-13"
															v-html="toHtml(item.sysNoticeTitle)"
															disabled
														/>
														<h3 class="mt-2">공지내용</h3>
														<div
															class="disFx justify-center editor-notice narrowScroll1"
														>
															<div class="pad10 ql-container ql-snow w100P">
																<div
																	:id="`sysNotiContents${index}`"
																	class="ql-editor"
																	v-html="toHtml(item.sysNoticeContents)"
																></div>
															</div>
														</div>
														<div
															class="disFX flex-column"
															v-if="attachFileList.length > 0"
														>
															<h3 class="w80 mt5">첨부파일</h3>
															<file-upload2
																:readOnly="true"
																:fileInputId="`fileInput${index}`"
																@fileValueFnc="fileValueFnc"
																:parentFileData="attachFileList"
																category="SystemNotice"
															></file-upload2>
														</div>
													</td>
												</tr>
											</template>
										</table>
										<paging
											:pagingData="pagingData"
											@pagingAction="pagingAction"
										></paging>
									</div>
								</div>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>
<script>
import paging from '../../../../components/paging';
import fileUpload2 from '../../../../components/FileUpload2';
import {
	getSysNotiList,
	getSysNoticeGroupCnt,
} from '../../../../api/common-mgmt/system-mgmt/SystemMgmtApi';
import { findAExtractBfromC } from '../../../../common/common';
import { toHtml } from '../../../../common/common';

export default {
	name: 'SystemNoticeListPop',
	props: {
		targetNoticeId: { type: Number },
		targetSysNoticeType: { type: String },
		dialogData: { type: Object },
	},
	components: {
		paging,
		fileUpload2,
	},
	data: () => ({
		menu1: false,
		postDialog: false,
		dateResetData: false,
		perPageCnt: 20,
		totalCnt: 0,
		attachFileList: [],
		targetNoticeIdx: '',
		url: '',
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		sellNoticeData: [],
		IMPORTANT: 0,
		COMMON: 0,
		SALES: 0,
		OPENING: 0,
		rightContText: '',
		filterData: {
			systemNoticeTargetType: '',
			srhKeyword: '',
			orderReadCnt: '',
			orderRegiDateTime: '',
			srhStartDate: '',
			srhEndDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.SystemMgmtModule.noticeListDialog;
			},
			set(newValue) {
				this.$store.state.SystemMgmtModule.noticeListDialog = newValue;
			},
		},
		noticeList: {
			get() {
				return this.$store.state.SalesMgmtModule.noticeList;
			},
			set(newValue) {
				this.$store.state.SalesMgmtModule.noticeList = newValue;
			},
		},
	},
	methods: {
		async getSpecificNoticeType(type) {
			this.filterData.systemNoticeTargetType = type;
			this.filterData.orderReadCnt = '';
			this.filterData.orderRegiDateTime = '';
			this.filterData.pageNo = 1;
			this.closeAllAccordion();
			await this.getNoticeList(this.filterData);
			let param = {
				srhKeyword: this.filterData.srhKeyword,
				srhStartDate: this.filterData.srhStartDate,
				srhEndDate: this.filterData.srhEndDate,
			};
			await this.getNoticeCnt(param);
		},
		toHtml(data) {
			if (data !== null && data !== undefined) {
				return toHtml(data);
			}
		},
		fileValueFnc(data) {
			this[data.name] = data.fileData;
		},
		async toggleOrder(orderType) {
			this.closeAllAccordion();
			if (orderType === 'RegiDateTime') {
				if (
					this.filterData.orderRegiDateTime === '' ||
					this.filterData.orderRegiDateTime === 'DESC'
				) {
					this.filterData.orderRegiDateTime = 'ASC';
				} else {
					this.filterData.orderRegiDateTime = 'DESC';
				}
				this.filterData.orderReadCnt = '';
			} else if (orderType === 'ReadCnt') {
				if (
					this.filterData.orderReadCnt === '' ||
					this.filterData.orderReadCnt === 'DESC'
				) {
					this.filterData.orderReadCnt = 'ASC';
				} else {
					this.filterData.orderReadCnt = 'DESC';
				}
				this.filterData.orderRegiDateTime = '';
			}
			await this.getNoticeList(this.filterData);
		},
		async filterSubmit() {
			this.$store.commit('SystemMgmtModule/orderInit');
			this.filterData.orderReadCnt = '';
			this.filterData.orderRegiDateTime = '';
			this.filterData.systemNoticeTargetType = '';
			this.filterData.pageNo = 1;
			await this.getNoticeList(this.filterData);
			await this.getNoticeCnt(this.filterData);
			document.querySelector('.v-dialog.v-dialog--active').scrollTop = 0;
			this.closeAllAccordion();
		},
		async toggleAccodion(index, sysNoticeId, item) {
			this.rightContText = '';
			let targetList = document.getElementById(`detail${index}`);
			if (targetList) {
				if (targetList.classList.contains('open')) {
					targetList.classList.remove('open');
					targetList.previousSibling.classList.remove('selectedTrBg');
				} else {
					let listArr = document.querySelectorAll('.detailRow');
					listArr.forEach(obj => {
						obj.classList.remove('open');
						obj.previousSibling.classList.remove('selectedTrBg');
					});
					if (item.attachCnt !== 0) {
						await this.getSystemNoticeAttachList(sysNoticeId);
					} else {
						this.attachFileList = [];
					}
					targetList.classList.add('open');
					targetList.previousSibling.classList.add('selectedTrBg');
					await this.updateReadCnt(sysNoticeId);
					this.noticeList[index].readCnt++;
				}
				document.getElementById(`sysNotiContents${index}`).scrollTop = 0;
			}
		},
		pagingAction(data) {
			this.filterData.pageNo = data.pageNo;
			this.filterData.perPageCnt = data.perPageCnt;
			this.getNoticeList(this.filterData);
			this.closeAllAccordion();
		},
		closeAllAccordion() {
			document.querySelector('.newTableStyle.narrowScroll.posR').scrollTop = 0;
			let listArr = document.querySelectorAll('.detailRow');
			listArr.forEach(obj => {
				obj.classList.remove('open');
				obj.previousSibling.classList.remove('selectedTrBg');
			});
		},
		selectPerPageCnt(val) {
			this.filterData.perPageCnt = Number(val);
			this.filterData.pageNo = 1;
			this.filterData.systemNoticeTargetType = '';
			this.getNoticeList(this.filterData);
		},
		async getSystemNoticeAttachList(sysNoticeId) {
			let result = await this.$store.dispatch(
				'SystemMgmtModule/getSysNoticeAttachList',
				{ sysNoticeId: sysNoticeId },
			);
			result ? (this.attachFileList = result) : (this.attachFileList = []);
		},
		async getNoticeList(data) {
			let result = await getSysNotiList(data);
			if (result && result.data.resultCode === '0000') {
				this.noticeList = result.data.data.resultList;
				this.totalCnt = result.data.data.totalCnt;
				this.pagingData.perPageCnt = result.data.data.perPageCnt;
				this.pagingData.pageNo = result.data.data.pageNo;
				this.pagingData.pageNumList = result.data.data.pageNumList;
				this.pagingData.pageLastNo = result.data.data.pageLastNo;
				this.pagingData.pageEndNo = result.data.data.pageEndNo;
			}
		},
		getCntFromResultData(arr, value) {
			return findAExtractBfromC(
				'systemNoticeTargetType',
				value,
				'typeCnt',
				arr,
			);
		},
		async getNoticeCnt(data) {
			let result = await getSysNoticeGroupCnt(data);
			// 혹시 받는 데이터의 순서가 바뀔 수 있어 findIndex로 예외처리 함
			if (result && result.data.resultCode === '0000') {
				let arr_CntData = result.data.data;
				this.IMPORTANT = this.getCntFromResultData(arr_CntData, 'IMPORTANT');
				this.COMMON = this.getCntFromResultData(arr_CntData, 'COMMON');
				this.SALES = this.getCntFromResultData(arr_CntData, 'SALES');
				this.OPENING = this.getCntFromResultData(arr_CntData, 'OPENING');
			}
		},
		async updateReadCnt(sysNoticeId) {
			await this.$store.dispatch('SystemMgmtModule/updateReadCnt', {
				sysNoticeId: sysNoticeId,
			});
		},
	},
	async created() {
		let param;
		if (this.targetNoticeId) {
			param = { systemNoticeTargetType: this.targetSysNoticeType };
			this.filterData.systemNoticeTargetType = this.targetSysNoticeType;
		}
		await this.getNoticeList(param);
		await this.getNoticeCnt();

		let targetFilter = this.noticeList.filter(item => {
			return item.sysNoticeId === this.targetNoticeId;
		});
		if (targetFilter.length === 0) {
			alert('삭제된 게시글입니다.');
			await this.$store.dispatch('SystemMgmtModule/getTopSysNotice');
		} else if (this.targetNoticeId) {
			this.targetNoticeIdx = this.noticeList.findIndex(
				obj => obj.sysNoticeId === this.targetNoticeId,
			);
			await this.toggleAccodion(
				this.targetNoticeIdx,
				this.targetNoticeId,
				this.noticeList[this.targetNoticeIdx],
			);
			document.querySelector('.newTableStyle.narrowScroll.posR').scrollTop =
				this.targetNoticeIdx * 45;
		}
		this.url = process.env['VUE_APP_SERVER_AUTH'];
	},
};
</script>

<style scoped>
.announceMgmtPop.new_popTable1 table th {
	background: #fbfbfb;
	width: 190px !important;
}
.noticeListPop .text-left.pad10 {
}
.fakeLine6 {
	position: relative;
}
.fakeLine6:before {
	position: absolute;
	right: -10px;
	top: 9px;
	display: block;
	content: '';
	width: 1px;
	height: 10px;
	background-color: #bebebe;
}
summary::marker {
	/*font-size: 0;*/
}
tr.detailRow {
	display: none;
}

tr.detailRow.open {
	display: table-row !important;
}

.noticeListPop .newNotiTableStyle table,
.noticeListPop .newTableStyle table,
.noticeListPop .newTableStyle table tr,
.noticeListPop .newTableStyle table td,
.noticeListPop .newTableStyle table th {
	border-color: #e0e0e0;
}
.selectedTrBg {
	background: #ececec !important;
}
.noticeListPop .newNotiTableStyle table * {
	font-size: 12px !important;
}
button.btnShadow2.backColorWhite:focus {
	outline: none;
}
.newTableStyle.narrowScroll {
	height: 690px;
	overflow-y: auto;
}
.editor-notice {
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 3px;
}
</style>
