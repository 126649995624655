import { instance } from '@/api';
import { jsonToQueryString } from '@/common/common';

const domain = '/SignManagement/SignMgmt/';
const encrypt = '/dataHandle/';

// 1. 서명내역 리스트 조회
const getSignatrueList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSignList${param}`);
	return response;
};
// 2. 서명내역 상세 조회
const getSignatrueDetail = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSignInfo${param}`);
	return response;
};
// 3. 서명내역 등록
const insertSignatrue = async data => {
	const response = await instance.post(`${domain}insertSign`, data);
	return response;
};
// 4. 서명내역 수정
const updateSignatrue = async data => {
	const response = await instance.post(`${domain}updateSign`, data);
	return response;
};
// 5. 서명 Seq 암호화
const retrieveEncId = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${encrypt}retrieveEncId${param}`);
	return response;
};
// 6. 서명 템플릿 리스트 조회
const getSignTemplateList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSignTemplateList${param}`);
	return response;
};
export {
	getSignatrueList,
	getSignatrueDetail,
	insertSignatrue,
	updateSignatrue,
	retrieveEncId,
	getSignTemplateList,
};
