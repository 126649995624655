import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {
	getSysNotiList,
	getSysNoticeGroupCnt,
	getSysNoticeAttachList,
	insertSysNoti,
	updateSysNoti,
	uploadSysNoticeAttach,
	deleteSysNotice,
	deleteSysNoticeAttach,
	getTopSysNotice,
	updateReadCnt,
	getTopImportantSysNotice,
} from '@/api/common-mgmt/system-mgmt/SystemMgmtApi';
import { findAExtractBfromC } from '@/common/common';

Vue.use(Vuex);

const SystemMgmtModule = {
	namespaced: true,
	state: {
		dateResetData: false,
		noticeDialog: false,
		noticeListDialog: false,
		totalCnt: 0,
		noticeList: [],
		topNotice: null,
		COMMON: 0,
		SALES: 0,
		OPENING: 0,
		IMPORTANT: 0,
		filterData: {
			systemNoticeTargetType: '',
			srhKeyword: '',
			orderRegiDateTime: '',
			orderReadCnt: '',
			srhStartDate: '',
			srhEndDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
	},
	mutations: {
		orderInit(state) {
			state.filterData.orderRegiDateTime = '';
			state.filterData.orderReadCnt = '';
		},
		initSysNoticeList(state) {
			state.noticeList = [];
			state.totalCnt = 0;
		},
		initNoticeCnt(state) {
			state.IMPORTANT = 0;
			state.COMMON = 0;
			state.SALES = 0;
			state.OPENING = 0;
		},
		topFilterInit(state) {
			state.dateResetData = true;
			state.filterData.systemNoticeTargetType = '';
			state.filterData.srhKeyword = '';
			state.filterData.srhStartDate = '';
			state.filterData.srhEndDate = '';
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
		setSysNoticeList(state, result) {
			state.noticeList = result.data.data.resultList || [];
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setNoticeCnt(state, result) {
			state.IMPORTANT = findAExtractBfromC(
				'systemNoticeTargetType',
				'IMPORTANT',
				'typeCnt',
				result.data.data,
			);
			state.COMMON = findAExtractBfromC(
				'systemNoticeTargetType',
				'COMMON',
				'typeCnt',
				result.data.data,
			);
			state.SALES = findAExtractBfromC(
				'systemNoticeTargetType',
				'SALES',
				'typeCnt',
				result.data.data,
			);
			state.OPENING = findAExtractBfromC(
				'systemNoticeTargetType',
				'OPENING',
				'typeCnt',
				result.data.data,
			);
		},
		initTopSysNotice(state) {
			state.topNotice = null;
		},
		setTopSysNotice(state, result) {
			state.topNotice = result.data.data || null;
		},
	},
	actions: {
		async getSysNotiList({ commit }, data) {
			try {
				commit('initSysNoticeList');
				const result = await getSysNotiList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setSysNoticeList', result);
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getSysNoticeGroupCnt({ commit }, data) {
			try {
				commit('initNoticeCnt');
				const result = await getSysNoticeGroupCnt(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNoticeCnt', result);
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getSysNoticeAttachList({ commit }, data) {
			try {
				const result = await getSysNoticeAttachList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertSysNoti({ commit }, data) {
			try {
				const result = await insertSysNoti(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateSysNoti({ commit }, data) {
			try {
				const result = await updateSysNoti(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async uploadSysNoticeAttach({ commit }, data) {
			try {
				const result = await uploadSysNoticeAttach(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteSysNotice({ commit }, data) {
			try {
				const result = await deleteSysNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteSysNoticeAttach({ commit }, data) {
			try {
				const result = await deleteSysNoticeAttach(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 공지사항 상단 출력 1건 조회
		async getTopSysNotice({ commit }, data) {
			try {
				commit('initTopSysNotice');
				const result = await getTopSysNotice(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setTopSysNotice', result);
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 시스템 공지 조회수 증가
		async updateReadCnt({ commit }, data) {
			try {
				const result = await updateReadCnt(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 시스템 중요공지 조회
		async getTopImportantSysNotice({ commit }, data) {
			try {
				const result = await getTopImportantSysNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default SystemMgmtModule;
