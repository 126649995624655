<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="800">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 disFx justify-lg-space-between"
						style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">선할인카드 선택</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text style="padding: 30px !important">
						<h3 class="mb10 ml10">
							{{ cardItem.cardName }}
						</h3>
						<div
							class="disFx"
							style="
								border-top: 1px solid #bebebe;
								border-bottom: 1px solid #bebebe;
								padding: 15px 10px;
							"
						>
							<div class="w200" style="height: 127px; align-self: center">
								<img
										id="deviceImgUrl"
										v-if="cardItem.cardImage !== null"
										:src="cardItem.cardImage"
										alt=""
										style="
										object-fit: cover;
										width: 200px;
										height: 127px;
										overflow-y: hidden;
									"
										@error="errorImage"
								/>
								<img
										id="deviceNoImgUrl"
										v-else
										src="../../../../../../../../../assets/img/card_noImage1.png"
										alt=""
										style="
										object-fit: cover;
										width: 200px;
										height: 127px;
										overflow-y: hidden;
									"
								/>
							</div>
							<div class="ml50" style="align-self: center">
								<table class="card_info_table1">
									<tr>
										<td>카드구분</td>
										<td>선할인카드</td>
									</tr>
									<tr>
										<td>결제구분</td>
										<td>
											{{
												cardItem.autoTransac === 0
													? '자동이체 미필수'
													: '자동이체 필수'
											}}
										</td>
									</tr>
									<tr>
										<td>연회비</td>
										<td>
											국내
											{{
												cardItem.feeInternal
													? String(cardItem.feeInternal).replace(
															/\B(?=(\d{3})+(?!\d))/g,
															',',
													  )
													: '0'
											}}
											원 / 국외
											{{
												cardItem.feeForeign
													? String(cardItem.feeForeign).replace(
															/\B(?=(\d{3})+(?!\d))/g,
															',',
													  )
													: '0'
											}}
											원
										</td>
									</tr>
									<tr>
										<td>발급센터</td>
										<td>
											전화번호
											<span style="color: #1976d2">{{
												cardItem.ars ? cardItem.ars : '정보없음'
											}}</span>
										</td>
									</tr>
									<tr v-if="cardItem.url">
										<td></td>
										<td>
											웹페이지
											<a class="txUnL" :href="cardItem.url" target="_blank">{{
												setUrlFnc(cardItem.url)
											}}</a>
										</td>
									</tr>
								</table>
							</div>
							<!--							<div class="disFx">
								<div style="align-self: end">
									<img
										:src="cardItem.qrImage"
										alt=""
										style="
											object-fit: cover;
											width: 50px;
											height: 50px;
											overflow-y: hidden;
										"
									/>
								</div>
							</div>-->
						</div>
						<!--						<div class="w100P mt15" style="padding: 0 10px">
							<h3>카드혜택</h3>
							<template v-if="cardItem.benefit">
								&lt;!&ndash;									<div v-html="cardItem.benefit"></div>&ndash;&gt;
								<textarea
									type="text"
									placeholder="사유 입력"
									v-html="cardItem.benefit"
									class="txtAreaStyle1 w100P borderContColor3 borderRadi5Px narrowScroll1 mt5 mainBlack"
									style="
										padding: 5px 10px;
										min-height: 150px;
										max-height: 150px;
										overflow-y: auto;
										resize: none;
									"
									readonly
								/>
							</template>
						</div>-->
						<!--						<div class="disFx">
							<div class="w50P">
								<h3>전월실적 포함</h3>
								<div
									class="txtAreaStyle1 w100P borderContColor3 borderRadi5Px narrowScroll1"
									style="
										padding: 5px 10px;
										min-height: 100px;
										max-height: 100px;
										overflow-y: auto;
									"
								>
									<template v-if="cardItem.cardInclude">
										<div v-html="toHtml(cardItem.cardInclude)"></div>
									</template>
								</div>
							</div>
							<div class="ml10 w50P">
								<h3>전월실적 미포함</h3>
								<div
									class="txtAreaStyle1 w100P borderContColor3 borderRadi5Px narrowScroll1"
									style="
										padding: 5px 10px;
										min-height: 100px;
										max-height: 100px;
										overflow-y: auto;
									"
								>
									<template v-if="cardItem.cardNotInclude">
										<div v-html="toHtml(cardItem.cardNotInclude)"></div>
									</template>
								</div>
							</div>
						</div>-->
						<div class="disFx w100P padW10 mt15">
							<div class="w50P">
								<h3 class="mb5">최소 전월 실적</h3>
								<div
									@click="monthAmtFnc"
									class="w100P borderContColor3 borderRadi5Px lh40 cur_p padW10 mainBlack"
									style="height: 42px"
								>
									<span
										v-if="prePopFormData.monthAmt"
										class="disIn lh40 w90P ellipsisH"
									>
										<span v-if="prePopFormData.monthAmt.disType === 'PROMOTION'"
											>[프로모션]</span
										>
										{{ prePopFormData.monthAmt.disCondition }}, 월
										{{
											String(prePopFormData.monthAmt.disMoney).replace(
												/\B(?=(\d{3})+(?!\d))/g,
												',',
											)
										}}
										원 할인
										{{
											prePopFormData.monthAmt.contents
												? prePopFormData.monthAmt.contents
												: ''
										}}
									</span>
									<span v-else class="disIn lh40"> 선택해 주세요. </span>
								</div>
							</div>
							<div class="ml10 w50P">
								<h3 class="mb5">할부기간</h3>
								<div
									@click="chargeDateFnc"
									class="w100P borderContColor3 borderRadi5Px lh40 cur_p padW10 txAnR mainBlack"
									style="height: 42px; font-weight: bold"
								>
									<span v-if="prePopFormData.chargeDate" class="font-size-16">
										{{ prePopFormData.chargeDate }} 개월
									</span>
									<span v-else> 선택해 주세요. </span>
								</div>
							</div>
						</div>
						<div
							class="w100P disFx justify-end lh40 font-size-16"
							style="
								border-bottom: 1px solid #bebebe;
								padding: 15px 10px;
								font-weight: bold;
							"
						>
							<div>
								<h3 class="lh18 mb5">총 할인금액</h3>
								<input
									type="text"
									class="borderContColor3 borderRadi5Px txAnR mainBlue2 padW10 cur_d"
									style="width: 355px"
									v-model.trim="preDiscountTotalAmt"
									readonly
								/>
							</div>
						</div>
						<div
							class="disFx w100P justify-end mt15"
							style="font-weight: bolder"
						>
							<button
								class="lh40 backColorBlue2 mainWhite w90 borderRadi3Px"
								@click="insertFnc"
							>
								적용
							</button>
							<button
								class="lh40 backColorGray1 mainWhite w90 borderRadi3Px ml10"
								@click="dialog = false"
							>
								취소
							</button>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<pre-card-condition-pop
			v-if="preCardConditionDialog"
		></pre-card-condition-pop>
		<pre-charge-date-pop v-if="preChargeDateDialog"></pre-charge-date-pop>
	</div>
</template>

<script>
import PreCardConditionPop from './option-pop/PreCardConditionPop.vue';
import PreChargeDatePop from './option-pop/PreChargeDatePop.vue';
import errorImg from '../../../../../../../../../assets/img/card_noImage1.png';

export default {
	name: 'PreCardPop',
	components: {
		PreCardConditionPop,
		PreChargeDatePop,
	},
	computed: {
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.prePopDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.prePopDialog = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		preCardConditionDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preCardConditionDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preCardConditionDialog = newValue;
			},
		},
		preChargeDateDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preChargeDateDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preChargeDateDialog = newValue;
			},
		},
		cardItem: {
			get() {
				return this.$store.state.ChargeCalculatorModule.cardItem;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.cardItem = newValue;
			},
		},
		preCardItem: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preCardItem;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preCardItem = newValue;
			},
		},
		prePopFormData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.prePopFormData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.prePopFormData = newValue;
			},
		},
		preDiscountTotalAmt() {
			if (this.prePopFormData.monthAmt && this.prePopFormData.chargeDate) {
				return (
					String(
						this.prePopFormData.monthAmt.disMoney *
							Math.abs(this.prePopFormData.chargeDate),
					).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' 원'
				);
			} else {
				return '0 원';
			}
		},
		preInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preInsertDto = newValue;
			},
		},
		parentsDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.discountDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.discountDialog = newValue;
			},
		},
	},
	methods: {
		toHtml(text) {
			return text
				.replace(/&amp;/g, '&')
				.replace(/&lt;/g, '<')
				.replace(/&gt;/g, '>')
				.replace(/&quot;/g, '"')
				.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '')
				.replace(/<p>/g, '<span>');
		},
		monthAmtFnc() {
			this.preCardConditionDialog = true;
			this.preCardItem.monthAmtItem = null;
			this.preCardItem.monthAmtItem = this.cardItem.cardDiscountList;
			if (this.cardItem.cardPromotionList.length > 0) {
				/*this.preCardItem.monthAmtItem.push(...this.cardItem.cardPromotionList);*/
				this.preCardItem.monthAmtItem = [].concat(
					this.cardItem.cardDiscountList,
					this.cardItem.cardPromotionList,
				);
			}
		},
		chargeDateFnc() {
			this.preChargeDateDialog = true;
			this.preCardItem.instlItem = [];
			this.preCardItem.instlItem = this.cardItem.installmentMonth;
		},
		insertFnc() {
			this.preInsertDto.cardItem = this.cardItem;
			this.preInsertDto.cardFormData = this.prePopFormData;
			this.formData.preCardAmt =
				this.prePopFormData.monthAmt.disMoney *
				Math.abs(this.prePopFormData.chargeDate) *
				-1;
			this.dialog = false;
			this.parentsDialog = false;
		},
		setFormData() {
			if (this.cardItem) {
				if (this.cardItem.cardDiscountList.length > 0) {
					this.prePopFormData.monthAmt = this.cardItem.cardDiscountList[0];
				} else {
					this.prePopFormData.monthAmt =
						this.cardItem.cardPromotionList[0] || [];
				}

				if (this.cardItem.installmentMonth.length > 0) {
					this.prePopFormData.chargeDate = this.cardItem.installmentMonth[0];
				}
			}
		},
		setUrlFnc(url) {
			if (url) {
				let splitUrl = url.split('/');
				return splitUrl[0] + '//' + splitUrl[2];
			} else {
				return '정보없음';
			}
		},
		errorImage(e) {
			console.log(e)
			e.target.src = errorImg;
		},
	},
	async created() {
		this.prePopFormData.monthAmt = null;
		this.prePopFormData.chargeDate = null;
		await this.setFormData();
		this.setUrlFnc(this.cardItem.url);
	},
};
</script>

<style scoped>
h3 {
	font-size: 16px;
	color: #000;
}
</style>
