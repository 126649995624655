<template>
	<tbody>
		<template v-if="formData.cusType === 'INDV'">
			<tr>
				<template>
					<td class="w80">고객명<span class="redfont">*</span></td>
					<td class="w130 lh30">
						<input
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5"
							maxlength="15"
							:disabled="consultDisableType"
							v-model.trim="consultCustomerDto.cusName"
						/>
					</td>
					<td class="w80" style="letter-spacing: -1px">
						휴대폰번호<span class="redfont">*</span>
					</td>
					<td class="w130 lh30">
						<input
							ref="cusPhone"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
							:disabled="consultDisableType"
							v-model.trim="consultCustomerDto.cusPhone"
							maxlength="13"
							@input="
								phoneNumSplitFnc(
									$event.target.value,
									'cusPhone',
									'consultCustomerDto',
								)
							"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
						/>
					</td>
					<td class="w80 lh18">
						주민번호<span v-if="creditInquireChk === 'Y'" class="redfont"
							>*</span
						><br />(생년월일)
					</td>
					<td class="w130 lh30">
						<input
							ref="cusRegiNum"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5 regiNum"
							:disabled="consultDisableType"
							v-model.trim="consultCustomerDto.cusRegiNumConcat"
							maxlength="14"
							@input="
								regiNumFnc(
									$event.target.value,
									'cusRegiNum',
									'consultCustomerDto',
								)
							"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
						/>
					</td>
				</template>
			</tr>
		</template>
		<template v-if="formData.cusType === 'MINORS'">
			<tr>
				<template>
					<td class="w80">고객명<span class="redfont">*</span></td>
					<td class="w130 lh30">
						<input
							ref="cusName"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5"
							maxlength="15"
							:disabled="consultDisableType"
							v-model.trim="consultCustomerDto.cusName"
						/>
					</td>
					<td class="w80" style="letter-spacing: -1px">
						휴대폰번호<span class="redfont">*</span>
					</td>
					<td class="w130 lh30">
						<input
							ref="cusPhone"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
							:disabled="consultDisableType"
							v-model.trim="consultCustomerDto.cusPhone"
							@input="
								phoneNumSplitFnc(
									$event.target.value,
									'cusPhone',
									'consultCustomerDto',
								)
							"
							maxlength="13"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
						/>
					</td>
					<td class="w80 lh18">
						주민번호<span v-if="creditInquireChk === 'Y'" class="redfont"
							>*</span
						><br />(생년월일)
					</td>
					<td class="w130 lh30">
						<input
							ref="cusRegiNum"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5 regiNum"
							:disabled="consultDisableType"
							v-model.trim="consultCustomerDto.cusRegiNumConcat"
							@input="
								regiNumFnc(
									$event.target.value,
									'cusRegiNum',
									'consultCustomerDto',
								)
							"
							maxlength="14"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
						/>
					</td>
				</template>
			</tr>
			<tr>
				<td class="w80 lh18" style="letter-spacing: -1px">
					법정대리인<span v-if="creditInquireChk === 'Y'" class="redfont"
						>*</span
					><br />
					성명
				</td>
				<td class="w130 lh30">
					<input
						ref="courtProctorName"
						type="text"
						class="w100P borderContColor3 borderRadi3Px padW5"
						maxlength="15"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.courtProctorName"
					/>
				</td>
				<td class="w80 lh18" style="letter-spacing: -1px">
					법정대리인<span v-if="creditInquireChk === 'Y'" class="redfont"
						>*</span
					><br />휴대폰번호
				</td>
				<td class="w130 lh30">
					<input
						ref="courtProctorPhone"
						type="text"
						class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.courtProctorPhone"
						@input="
							phoneNumSplitFnc(
								$event.target.value,
								'courtProctorPhone',
								'consultCustomerDto',
							)
						"
						maxlength="13"
						oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
					/>
				</td>
				<td class="w80 lh18" style="letter-spacing: -1px">
					법정대리인<span v-if="creditInquireChk === 'Y'" class="redfont"
						>*</span
					><br />주민번호
				</td>
				<td class="w130 lh30">
					<input
						ref="courtProctorRegiNum"
						type="text"
						class="w100P borderContColor3 borderRadi3Px padW5 regiNum"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.courtProctorRegiNumConcat"
						@input="
							proctorRegiNumFnc(
								$event.target.value,
								'courtProctorRegiNum',
								'consultCustomerDto',
							)
						"
						maxlength="14"
						oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
					/>
				</td>
			</tr>
		</template>
		<template v-if="cusSelectArr.selectTelecom !== 6">
			<template v-if="formData.cusType === 'INDV_BSNM'">
				<tr>
					<template>
						<td class="w80">사업자명<span class="redfont">*</span></td>
						<td class="w130 lh30">
							<input
								ref="bizName"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5"
								maxlength="15"
								:disabled="consultDisableType"
								v-model.trim="consultCustomerDto.bizName"
							/>
						</td>
						<td class="w80" style="letter-spacing: -1px">
							휴대폰번호<span class="redfont">*</span>
						</td>
						<td class="w130 lh30">
							<input
								ref="bizPhone"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
								:disabled="consultDisableType"
								v-model.trim="consultCustomerDto.bizPhone"
								maxlength="13"
								@input="
									phoneNumSplitFnc(
										$event.target.value,
										'bizPhone',
										'consultCustomerDto',
									)
								"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							/>
						</td>
						<td class="w80 lh18" style="letter-spacing: -1px">
							사업자번호<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							>
						</td>
						<td class="w130 lh30">
							<input
								ref="bizNum"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5 bizNum"
								:disabled="consultDisableType"
								v-model.trim="consultCustomerDto.bizNum"
								maxlength="12"
								@input="
									bizNumSplitFnc(
										$event.target.value,
										'bizNum',
										'consultCustomerDto',
									)
								"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							/>
						</td>
					</template>
				</tr>
			</template>
		</template>
		<template v-if="formData.cusType === 'CORP'">
			<tr>
				<td class="w80">법인명<span class="redfont">*</span></td>
				<td class="w130 lh30">
					<input
						ref="bizName"
						type="text"
						class="w100P borderContColor3 borderRadi3Px padW5"
						maxlength="15"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.bizName"
					/>
				</td>
				<td class="w80 lh18" style="letter-spacing: -1px">
					휴대폰번호<span class="redfont">*</span>
				</td>
				<td class="w130 lh30">
					<input
						ref="bizPhone"
						type="text"
						class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.bizPhone"
						maxlength="13"
						@input="
							phoneNumSplitFnc(
								$event.target.value,
								'bizPhone',
								'consultCustomerDto',
							)
						"
						oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
					/>
				</td>
				<td class="w80 lh18" style="letter-spacing: -1px">
					사업자번호<span v-if="creditInquireChk === 'Y'" class="redfont"
						>*</span
					>
				</td>
				<td class="w130 lh30">
					<input
						ref="bizNum"
						type="text"
						class="w100P borderContColor3 borderRadi3Px padW5 bizNum"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.bizNum"
						maxlength="12"
						@input="
							bizNumSplitFnc(
								$event.target.value,
								'bizNum',
								'consultCustomerDto',
							)
						"
						oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
					/>
				</td>
			</tr>
			<tr>
				<td class="w80 lh18">
					법인등록<span v-if="creditInquireChk === 'Y'" class="redfont">*</span
					><br />번호
				</td>
				<td class="w130 lh30" colspan="5">
					<input
						ref="bizRegiNum"
						type="text"
						class="w150 borderContColor3 borderRadi3Px padW5 regiNum"
						:disabled="consultDisableType"
						v-model.trim="consultCustomerDto.bizRegiNumConcat"
						maxlength="14"
						@input="
							regiNumFnc(
								$event.target.value,
								'bizRegiNum',
								'consultCustomerDto',
							)
						"
						oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
					/>
				</td>
			</tr>
		</template>
		<template v-if="formData.cusType === 'FOREIGN'">
			<template v-if="cusSelectArr.selectTelecom === 5">
				<template v-if="formData.joinType === 'DVC_CHANGE'">
					<tr>
						<td class="w80">고객명<span class="redfont">*</span></td>
						<td class="w130 lh30">
							<input
								ref="cusName"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5"
								maxlength="15"
								v-model.trim="consultCustomerDto.cusName"
							/>
						</td>
						<td class="w80" style="letter-spacing: -1px">
							휴대폰번호<span class="redfont">*</span>
						</td>
						<td class="w130 lh30">
							<input
								ref="cusPhone"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
								v-model.trim="consultCustomerDto.cusPhone"
								maxlength="13"
								@input="
									phoneNumSplitFnc(
										$event.target.value,
										'cusPhone',
										'consultCustomerDto',
									)
								"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							/>
						</td>
						<td class="w80 lh18" style="letter-spacing: -1px">
							외국인등록<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							><br />번호
						</td>
						<td class="w130 lh30">
							<input
								ref="licenseNum"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5 regiNum"
								v-model.trim="consultCustomerDto.licenseNumConcat"
								@input="
									regiNumSplitFnc(
										$event.target.value,
										'licenseNum',
										'consultCustomerDto',
									)
								"
								maxlength="14"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							/>
						</td>
					</tr>
					<tr>
						<td>
							체류코드<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							>
						</td>
						<td>
							<div class="datePickerStyle1 w150">
								<v-autocomplete
									style="font-size: 12px"
									:items="codeList.stayCodeItems"
									item-text="name"
									item-value="value"
									outlined
									placeholder="검색해 주세요."
									class="w420 dvcFontBox"
									ref="stayCode"
									v-model.trim="consultCustomerDto.stayCode"
								>
								</v-autocomplete>
							</div>
						</td>
						<td>
							만료일자<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							>
						</td>
						<td
							colspan="3"
							class="datePickerStyle1"
							style="width: 150px !important"
						>
							<div style="width: 150px; overflow: hidden">
								<v-menu
									v-model.trim="menu2"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									:attach="true"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											placeholder="YYYY-mm-dd"
											style="font-size: 12px; width: 150px"
											outlined
											readonly
											v-bind="attrs"
											v-on="on"
											append-icon="mdi-calendar"
											tabindex="-1"
											v-model.trim="consultCustomerDto.licenseExpiredDate"
											ref="licenseExpiredDate"
										></v-text-field>
									</template>
									<v-date-picker
										@input="menu2 = false"
										locale="ko-KR"
										v-model.trim="consultCustomerDto.licenseExpiredDate"
									></v-date-picker>
								</v-menu>
							</div>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr>
						<td class="w80">고객명<span class="redfont">*</span></td>
						<td class="w130 lh30">
							<input
								ref="cusName"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5"
								maxlength="15"
								v-model.trim="consultCustomerDto.cusName"
							/>
						</td>
						<td class="w80" style="letter-spacing: -1px">
							휴대폰번호<span class="redfont">*</span>
						</td>
						<td class="w130 lh30">
							<input
								ref="cusPhone"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
								v-model.trim="consultCustomerDto.cusPhone"
								maxlength="13"
								@input="
									phoneNumSplitFnc(
										$event.target.value,
										'cusPhone',
										'consultCustomerDto',
									)
								"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							/>
						</td>
						<td class="w80 lh18" style="letter-spacing: -1px">
							외국인등록<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							><br />번호
						</td>
						<td class="w130 lh30">
							<input
								ref="licenseNum"
								type="text"
								class="w100P borderContColor3 borderRadi3Px padW5 regiNum"
								v-model.trim="consultCustomerDto.licenseNumConcat"
								@input="
									regiNumSplitFnc(
										$event.target.value,
										'licenseNum',
										'consultCustomerDto',
									)
								"
								maxlength="14"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							/>
						</td>
					</tr>
					<tr>
						<td>
							국가<span v-if="creditInquireChk === 'Y'" class="redfont">*</span>
						</td>
						<td>
							<div class="datePickerStyle1 w150">
								<v-autocomplete
									style="font-size: 12px"
									:items="codeList.nationalItems"
									item-text="name"
									item-value="value"
									outlined
									placeholder="검색해 주세요."
									class="w420 dvcFontBox"
									ref="ntnlCode"
									v-model.trim="consultCustomerDto.ntnlCode"
								>
								</v-autocomplete>
							</div>
						</td>
						<td>
							발급일자<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							>
						</td>
						<td class="datePickerStyle1" style="width: 150px !important">
							<div style="width: 150px; overflow: hidden">
								<v-menu
									v-model.trim="menu1"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									:attach="true"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											placeholder="YYYY-mm-dd"
											style="font-size: 12px; width: 150px"
											outlined
											readonly
											v-bind="attrs"
											v-on="on"
											append-icon="mdi-calendar"
											tabindex="-1"
											v-model.trim="consultCustomerDto.licenseIssueDate"
											ref="licenseIssueDate"
										></v-text-field>
									</template>
									<v-date-picker
										@input="menu1 = false"
										locale="ko-KR"
										v-model.trim="consultCustomerDto.licenseIssueDate"
									></v-date-picker>
								</v-menu>
							</div>
						</td>
						<td>
							만료일자<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							>
						</td>
						<td class="datePickerStyle1" style="width: 150px !important">
							<div style="width: 150px; overflow: hidden">
								<v-menu
									v-model.trim="menu2"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									:attach="true"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											placeholder="YYYY-mm-dd"
											style="font-size: 12px; width: 150px"
											outlined
											readonly
											v-bind="attrs"
											v-on="on"
											append-icon="mdi-calendar"
											tabindex="-1"
											v-model.trim="consultCustomerDto.licenseExpiredDate"
											ref="licenseExpiredDate"
										></v-text-field>
									</template>
									<v-date-picker
										@input="menu2 = false"
										locale="ko-KR"
										v-model.trim="consultCustomerDto.licenseExpiredDate"
									></v-date-picker>
								</v-menu>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							체류코드<span v-if="creditInquireChk === 'Y'" class="redfont"
								>*</span
							>
						</td>
						<td colspan="5">
							<div class="datePickerStyle1 w150">
								<v-autocomplete
									style="font-size: 12px"
									:items="codeList.stayCodeItems"
									item-text="name"
									item-value="value"
									outlined
									placeholder="검색해 주세요."
									class="w420 dvcFontBox"
									ref="stayCode"
									v-model.trim="consultCustomerDto.stayCode"
								>
								</v-autocomplete>
							</div>
						</td>
					</tr>
				</template>
			</template>
			<template
				v-if="
					cusSelectArr.selectTelecom === 6 || cusSelectArr.selectTelecom === 7
				"
			>
				<tr>
					<td class="w80">고객명<span class="redfont">*</span></td>
					<td class="w130 lh30">
						<input
							ref="cusName"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5"
							maxlength="15"
							v-model.trim="consultCustomerDto.cusName"
						/>
					</td>
					<td class="w80" style="letter-spacing: -1px">
						휴대폰번호<span class="redfont">*</span>
					</td>
					<td class="w130 lh30">
						<input
							ref="cusPhone"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5 phoneNum"
							v-model.trim="consultCustomerDto.cusPhone"
							maxlength="13"
							@input="
								phoneNumSplitFnc(
									$event.target.value,
									'cusPhone',
									'consultCustomerDto',
								)
							"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
						/>
					</td>
					<td class="w80 lh18" style="letter-spacing: -1px">
						외국인등록<span v-if="creditInquireChk === 'Y'" class="redfont"
							>*</span
						><br />번호
					</td>
					<td class="w130 lh30">
						<input
							ref="licenseNum"
							type="text"
							class="w100P borderContColor3 borderRadi3Px padW5 regiNum"
							v-model.trim="consultCustomerDto.licenseNumConcat"
							@input="
								regiNumSplitFnc(
									$event.target.value,
									'licenseNum',
									'consultCustomerDto',
								)
							"
							maxlength="14"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
						/>
					</td>
				</tr>
				<tr>
					<td>
						국가<span v-if="creditInquireChk === 'Y'" class="redfont">*</span>
					</td>
					<td>
						<div class="datePickerStyle1 w150">
							<v-autocomplete
								style="font-size: 12px"
								:items="codeList.nationalItems"
								item-text="name"
								item-value="value"
								outlined
								placeholder="검색해 주세요."
								class="w420 dvcFontBox"
								ref="ntnlCode"
								v-model.trim="consultCustomerDto.ntnlCode"
							>
							</v-autocomplete>
						</div>
					</td>
					<td>
						발급일자<span v-if="creditInquireChk === 'Y'" class="redfont"
							>*</span
						>
					</td>
					<td
						colspan="3"
						class="datePickerStyle1"
						style="width: 150px !important"
					>
						<div style="width: 150px; overflow: hidden">
							<v-menu
								v-model.trim="menu1"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								:attach="true"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										placeholder="YYYY-mm-dd"
										style="font-size: 12px; width: 150px"
										outlined
										readonly
										v-bind="attrs"
										v-on="on"
										append-icon="mdi-calendar"
										tabindex="-1"
										v-model.trim="consultCustomerDto.licenseIssueDate"
										ref="licenseIssueDate"
									></v-text-field>
								</template>
								<v-date-picker
									@input="menu1 = false"
									locale="ko-KR"
									v-model.trim="consultCustomerDto.licenseIssueDate"
								></v-date-picker>
							</v-menu>
						</div>
					</td>
				</tr>
			</template>
		</template>
	</tbody>
</template>

<script>
import numberSplit from '@/common/numberSplit';
import NumRepMixin from '@/common/NumRepMixin';
import focusMixin from '../../../../../common/focusMixin.js';
import {
	regiNumValFnc,
	minorsValidationFnc,
} from '../../../../../common/common.js';

export default {
	name: 'CustomerRegiTableDetail',
	props: {},
	data: () => ({
		menu1: false,
		menu2: false,
	}),
	mixins: [numberSplit, NumRepMixin, focusMixin],
	computed: {
		cusSelectArr() {
			return this.$store.state.CustomerConsultModule.cusSelectArrDetail;
		},
		formData: {
			get() {
				return this.$store.state.CustomerConsultModule.formDataDetail;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.formDataDetail = newValue;
			},
		},
		consultCustomerDto: {
			get() {
				return this.$store.state.CustomerConsultModule.formDataDetail
					.consultCustomerDto;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.formDataDetail.consultCustomerDto = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.CustomerConsultModule.codeList;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.codeList = newValue;
			},
		},
		consultDisableType: {
			get() {
				return this.$store.state.CustomerConsultModule.consultDisableType;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.consultDisableType = newValue;
			},
		},
		creditInquireChk: {
			get() {
				return this.$store.state.CustomerConsultModule.creditInquireChkDetail;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.creditInquireChkDetail = newValue;
			},
		},
	},
	methods: {
		regiNumFnc(value, model, dto) {
			this.regiNumSplitFnc(value, model, dto);
			if (
					this.consultCustomerDto.cusRegiNum2 &&
					this.consultCustomerDto.cusRegiNum2.length === 1
			) {
				const result = minorsValidationFnc(
						this.consultCustomerDto.cusRegiNum1,
						this.consultCustomerDto.cusRegiNum2,
				);
				if (result) {
					if (result === '미성년자 입니다.') {
						this.formData.cusType = 'MINORS';
					}
					alert(`${result}`);
				}
			}
			if (this.consultCustomerDto.cusRegiNumConcat.length > 12) {
				const result1 = regiNumValFnc(this.consultCustomerDto.cusRegiNumConcat);
				if (result1 === '유효하지 않은 주민번호 입니다.') {
					return alert(`${result1}`);
				}
			}
		},
		proctorRegiNumFnc(value, model, dto) {
			this.regiNumSplitFnc(value, model, dto);
			if (this.consultCustomerDto.courtProctorRegiNumConcat.length > 12) {
				const result1 = regiNumValFnc(
						this.consultCustomerDto.courtProctorRegiNumConcat,
				);
				if (result1 === '유효하지 않은 주민번호 입니다.') {
					return alert(`${result1}`);
				}
			}
		},
	},
	async created() {},
};
</script>

<style scoped>
.cus_table_style1 {
	font-size: 12px;
}
.cus_table_style1 tr {
	line-height: 40px;
}
.cus_table_style1 td {
	border: 1px solid #eee;
	border-collapse: collapse;
	padding: 0 10px;
	background-color: #fff;
}
.cus_table_style1 td:nth-child(odd) {
	background-color: #fbfbfb;
	width: 80px !important;
}
.btnPlus1 > div {
	top: 1px !important;
	right: 0px !important;
}

input[type='text']:disabled {
	color: rgba(0, 0, 0, 0.38);
	border: 1px solid #e0e0e0 !important;
}
</style>
