
import Vue from 'vue';
import BasicInformation from './ConsultBasicInformation.vue';
import CustomerInformation from './ConsultCustomerInformation.vue';
import PaymentInformation from './ConsultPaymentInformation.vue';
import JoinInformation from './ConsultJoinInformation.vue';
import DeliveryInformation from './ConsultDeliveryInformation.vue';
import EtcInformation from './ConsultEtcInformation.vue';
import {
	confirmFor,
	getTime,
	getToday,
	htmlParse,
	nullValidation2,
} from '../../../common/common.js';
import {
	appCommonFormData,
	joinFormData,
	memoFormData,
	propCustomerData,
} from '@/store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import WorkStatus from '../../../views/supply-chain-mgmt/sell-mgmt/popup/QuickOpeningPop/WorkStatus.vue';
import BlackListPop from '../../../views/supply-chain-mgmt/black-list/popup/BlackListPop.vue';
import ComplainInfoPop from '@/views/comm-mgmt/complain-hndlg/popup/ComplainInfoPop.vue';
import ComplainRegiPop from '@/views/comm-mgmt/complain-hndlg/popup/ComplainRegiPop.vue';
import ApplTemplatePop from './popup/ConsultApplTemplatePop.vue';
import ReturnTargetPop from './popup/ConsultReturnTargetPop.vue';
import ExchangeFaultyPop from './popup/ConsultExchangeFaultyPop.vue';
import checkMoveDevicePop from './popup/ConsultCheckMoveDevicePop.vue';
import bus from '@/common/bus';
import { sendReload } from '@/common/socketUtil';

interface dataType {
	customerVal: Array<string>;
	paymentVal: Array<string>;
	joinVal: Array<string>;
	deliveryVal: Array<string>;
	validationDialog: boolean;
	complainInfoPop: boolean;
	complainRegiPop: boolean;
	blackListPop: boolean;
	addBlackListInfo: any;
	tempNoti: boolean;
	dvcType: any;
	matchingResultTitle: any;
	matchingResultMsg: any;
	matchingResultMsgType: any;
	matchingInfo: any;
	matchingDvcInfo: any;
	matchingUsimInfo: any;
}

export default Vue.extend({
	name: 'ApplicationFormCreationTable',
	props: {
		cnsltId: { type: Number, required: true },
		applId: { type: undefined, required: true },
		moduleName: { type: String },
	},
	components: {
		ComplainRegiPop,
		ComplainInfoPop,
		BasicInformation, // 기본정보
		CustomerInformation, // 고객정보
		PaymentInformation, // 납부정보
		JoinInformation, // 가입정보
		DeliveryInformation, // 배송정보
		EtcInformation, // 기타정보
		WorkStatus, // 진행상태
		BlackListPop, // 블랙리스트 등록
		ApplTemplatePop, // 템플릿 등록
		ReturnTargetPop, // 반품대상지 선택
		checkMoveDevicePop, // 신청서작성 재고매칭처리 알림
		ExchangeFaultyPop, // 교품요청 및 불량취소 처리 알림
	},
	data: (): dataType => ({
		tempNoti: false,
		customerVal: [], // 고객정보 validation
		paymentVal: [], // 납부정보 validation
		joinVal: [], // 가입정보 validation
		deliveryVal: [], // 배송정보 validation
		validationDialog: false,
		complainInfoPop: false,
		complainRegiPop: false,
		blackListPop: false,
		addBlackListInfo: {},
		dvcType: '',
		matchingResultTitle: '',
		matchingResultMsg: '',
		matchingResultMsgType: '',
		matchingInfo: {},
		matchingDvcInfo: {},
		matchingUsimInfo: {},
	}),
	computed: {
		module: {
			get(): any {
				return this.$store.state[this.moduleName];
			},
			set(newValue: any) {
				this.$store.state[this.moduleName] = newValue;
			},
		},
		dialog: {
			get(): any {
				return this.module.applDialog;
			},
			set(newValue: any) {
				this.module.applDialog = newValue;
			},
		},
		applConsultTodoDto: {
			get(): any {
				return this.$store.state.SalesMgmtModule.applConsultTodoDto;
			},
			set(newValue: any) {
				this.$store.state.SalesMgmtModule.applConsultTodoDto = newValue;
			},
		},
		applConsultMemoDto: {
			get(): any {
				return this.$store.state.SalesMgmtModule.applConsultMemoDto;
			},
			set(newValue: any) {
				this.$store.state.SalesMgmtModule.applConsultMemoDto = newValue;
			},
		},
		thriftyPhone: {
			get(): number {
				return this.$store.state.ApplicationFormCreationModule.thriftyPhone;
			},
			set(newValue: number) {
				this.$store.state.ApplicationFormCreationModule.thriftyPhone = newValue;
			},
		},
		templateDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.templateDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.templateDialog =
					newValue;
			},
		},
		returnDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.returnDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.returnDialog = newValue;
			},
		},
		excFauDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.excFauDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.excFauDialog = newValue;
			},
		},
		checkMoveDeviceDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule
					.checkMoveDeviceDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.checkMoveDeviceDialog =
					newValue;
			},
		},
		matchingDeviceType: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule
					.matchingDeviceType;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.matchingDeviceType =
					newValue;
			},
		},
		matchingUsimType: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.matchingUsimType;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.matchingUsimType =
					newValue;
			},
		},
		propCustomerData(): any {
			let data;
			data = {
				//@ts-ignore
				applRegiUserIdString: this.formData.basic.applRegiUserIdString,
				cusName: this.formData.customer.cusName,
				cusRegiNum1: this.formData.customer.cusRegiNum1,
				cusRegiNum2: this.formData.customer.cusRegiNum2,
				openingPhone: this.formData.join.openingPhone,
				openingPhone1: this.formData.join.openingPhone1,
				openingPhone2: this.formData.join.openingPhone2,
				openingPhone3: this.formData.join.openingPhone3,
				openingDate: this.formData.basic.openingDate,
				saleStoreId: this.formData.basic.saleStoreId,
				openingStoreId: this.formData.basic.openingStoreId.openStoreCode,
				parentHierarchy: this.formData.basic.openingStoreId.parentHierarchy,
				bizName: this.formData.customer.bizName,
			};
			if (this.formData.customer.cusType === 'FOREIGN') {
				//@ts-ignore
				data.cusRegiNum1 = this.formData.customer.licenseNum1;
				//@ts-ignore
				data.cusRegiNum2 = this.formData.customer.licenseNum2;
			}
			return data;
		},
		storeVal(): string {
			return this.$store.state.cookies.sg;
		},
		crListFlag: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.crListFlag;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.crListFlag = newValue;
			},
		},
		AppFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFlag = newValue;
			},
		},
		addServiceFormData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule
					.addServiceFormData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.addServiceFormData =
					newValue;
			},
		},
		updateFormData(): appCommonFormData {
			return this.$store.state.ApplicationFormCreationModule.updateFormData;
		},
		blackList: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.blackList;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.blackList = newValue;
			},
		},
		formData: {
			get(): appCommonFormData {
				return this.$store.state.ApplicationFormCreationModule.formData;
			},
			set(newValue: appCommonFormData) {
				this.$store.state.ApplicationFormCreationModule.formData = newValue;
			},
		},
		memoData: {
			get(): memoFormData {
				return this.$store.state.ApplicationFormCreationModule.memoData;
			},
			set(newValue: memoFormData) {
				this.$store.state.ApplicationFormCreationModule.memoData = newValue;
			},
		},
		customerDialog(): any {
			return this.$store.state.ApplicationFormCreationModule.customerDialog;
		},
		optionList(): any {
			return this.$store.state.ApplicationFormCreationModule.codeList;
		},
		codeList: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.codeList;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.codeList = newValue;
			},
		},
		crData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.crData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.crData = newValue;
			},
		},
		ApplExchangeFlag(): Object {
			return this.$store.state.ApplicationFormCreationModule.ApplExchangeFlag;
		},
		crCompleteFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.crCompleteFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.crCompleteFlag =
					newValue;
			},
		},
		sg(): any {
			return this.$store.state.cookies.sg;
		},
		templateTitle(): any {
			return this.$store.state.ApplicationFormCreationModule.templateTitle;
		},
		templateSeq(): any {
			return this.$store.state.ApplicationFormCreationModule.templateSeq;
		},
		joinData: {
			get(): joinFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.join;
			},
			set(newValue: joinFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.join =
					newValue;
			},
		},
		colorItems: {
			get(): Object {
				return this.$store.state.ApplicationFormCreationModule.codeList
					.colorItems;
			},
			set(newValue: Object) {
				this.$store.state.ApplicationFormCreationModule.codeList.colorItems =
					newValue;
			},
		},
		workStatusData: {
			get(): joinFormData {
				return this.$store.state.ApplicationFormCreationModule.workStatusData;
			},
			set(newValue: joinFormData) {
				this.$store.state.ApplicationFormCreationModule.workStatusData =
					newValue;
			},
		},
		AppFormCreFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFormCreFlag =
					newValue;
			},
		},
		creditTodoDetail: {
			get(): any {
				return this.$store.state.SalesMgmtModule.creditTodoDetail;
			},
			set(newValue: any) {
				this.$store.state.SalesMgmtModule.creditTodoDetail = newValue;
			},
		},
		/*todoFilterData: {
      get(): any {
        return this.$store.state.CustomerDbModule.todoFilterData;
      },
      set(newValue: any) {
        this.$store.state.CustomerDbModule.todoFilterData = newValue;
      },
    },
    filterData: {
      get(): any {
        return this.$store.state.CustomerDbModule.filterData;
      },
      set(newValue: any) {
        this.$store.state.CustomerDbModule.filterData = newValue;
      },
    },*/
		filterData: {
			get(): any {
				return this.module.filterData;
			},
			set(newValue: any) {
				this.module.filterData = newValue;
			},
		},
		todoFilterData: {
			get(): any {
				return this.module.todoFilterData;
			},
			set(newValue: any) {
				this.module.todoFilterData = newValue;
			},
		},
	},
	methods: {
		statusAlertFnc() {
			alert('개통완료상태에서는 내용수정이 불가합니다.');
		},
		openDialogFnc(dialogName: string) {
			if (dialogName === 'complainInfoPop' && this.formData.complainCnt === 0) {
				alert('민원처리내역이 없습니다.');
				return;
			}
			// @ts-ignore
			this[dialogName] = true;
		},
		regExpReserveNum(value: string) {
			this.formData.join.openingTelecomCodeId === 7
				? (this.formData.basic.reserveNum = value.replace(/[^a-zA-Z0-9]/gi, ''))
				: (this.formData.basic.reserveNum = value.replace(/[^0-9]/g, ''));
		},
		validateReserveYN(e: any) {
			if (!this.formData.join.openingTelecomCodeId) {
				alert('개통점을 먼저 선택해주세요.');
				e.preventDefault();
			} else {
				this.toggleReserveNum();
			}
		},
		toggleReserveNum() {
			// @ts-ignore
			if (this.formData.basic.beforeReserveYn === 'Y') {
				this.formData.basic.reserveNum = '';
			} else {
				this.formData.basic.beforeReserveYn = 'Y';
				// @ts-ignore
				this.$nextTick(() => this.$refs.preOrderNumInput.focus());
			}
		},
		async crRequestFnc() {
			if (
				this.applId !== null &&
				this.creditTodoDetail.creditInquireResponseDto !== null
			) {
				if (
					!confirm(
						'신용조회가 진행된 이력이 있습니다. 그래도 진행하시겠습니까?',
					)
				) {
					return;
				}
			}
			this.formData.basic.creditInquireYn = 'Y';
			this.formData.basic.creditInquireId = null;
			await this.finalSubmit();
			await this.$store.dispatch(
				'CreditCheckModule/getCreditInquireStatusCount',
			);
			/*let data: any = [];
			data = {
				inquireBasicDto: {
					openingStoreId: this.formData.basic.openingStoreId.openStoreCode,
					joinType: this.formData.join.joinType,
					cusType: this.formData.customer.cusType,
					parentHierarchy: this.formData.basic.openingStoreId.parentHierarchy,
					creditReqType: 'ITEMS',
				},
				inquireRequestDto: {
					creditInquireYn: 'Y',
					creditInquireId: null,
					bizName: this.formData.customer.bizName,
					bizNum: this.formData.customer.bizNum,
					bizPhone: this.formData.customer.bizPhone,
					bizPhone1: this.formData.customer.bizPhone1,
					bizPhone2: this.formData.customer.bizPhone2,
					bizPhone3: this.formData.customer.bizPhone3,
					bizRegiNum1: this.formData.customer.bizRegiNum1,
					bizRegiNum2: this.formData.customer.bizRegiNum2,
					courtProctorName: this.formData.customer.courtProctorName,
					courtProctorPhone: this.formData.customer.courtProctorPhone,
					courtProctorPhone1: this.formData.customer.courtProctorPhone1,
					courtProctorPhone2: this.formData.customer.courtProctorPhone2,
					courtProctorPhone3: this.formData.customer.courtProctorPhone3,
					courtProctorRegiNum1: this.formData.customer.courtProctorRegiNum1,
					courtProctorRegiNum2: this.formData.customer.courtProctorRegiNum2,
					courtProctorRegiNumConcat: this.formData.customer
						.courtProctorRegiNumConcat,
					courtProctorRelation: this.formData.customer.courtProctorRelation,
					cusName: this.formData.customer.cusName,
					cusPhone: this.formData.customer.cusPhone,
					cusPhone1: this.formData.customer.cusPhone1,
					cusPhone2: this.formData.customer.cusPhone2,
					cusPhone3: this.formData.customer.cusPhone3,
					cusRegiNum1: this.formData.customer.cusRegiNum1,
					cusRegiNum2: this.formData.customer.cusRegiNum2,
					cusRegiNumConcat: this.formData.customer.cusRegiNumConcat,
					licenseAuthType: this.formData.customer.licenseAuthType,
					licenseExpiredDate: this.formData.customer.licenseExpiredDate,
					licenseIssueArea: this.formData.customer.licenseIssueArea,
					licenseIssueDate: this.formData.customer.licenseIssueDate,
					licenseNum1: this.formData.customer.licenseNum1,
					licenseNum2: this.formData.customer.licenseNum2,
					licenseNum3: this.formData.customer.licenseNum3,
					licenseNumConcat: this.formData.customer.licenseNumConcat,
					ntnlCode: this.formData.customer.ntnlCode,
					stayCode: this.formData.customer.stayCode,
				},
				inquireResponseDto: {},
			};
			await this.$store.dispatch('CreditCheckModule/insertCreditInquire', data);*/
		},
		insertCancelFnc() {
			if (confirmFor('return')) {
				this.formDataResetFnc();
			}
		},
		setOpeningStoreFnc() {
			if (this.AppFormCreFlag !== undefined) {
				this.formData.basic.openingStoreId = {
					openStoreCode: this.updateFormData.basic.openingStoreId,
					openStoreTelecom: this.updateFormData.join.openingTelecomName,
					openStoreTelecomId: this.updateFormData.join.openingTelecomCodeId,
					parentHierarchy: this.updateFormData.basic.parentHierarchy,
					parentSaleStoreId: this.updateFormData.basic.parentSaleStoreId,
				};
			}
		},
		// 블랙리스트 등록
		openBlackListPop() {
			// const birthDate = this.getBirthData(
			// 	this.formData.customer.cusRegiNum1,
			// 	this.formData.customer.cusRegiNum2,
			// );
			const formData = {
				blackName: this.formData.customer.cusName,
				blackDateBirth: '',
				blackZipCode: this.formData.customer.cusZipCode,
				blackAddr: this.formData.customer.cusAddr,
				blackAddrDetail: this.formData.customer.cusAddrDetail,
				blackPhone1: this.formData.customer.cusPhone1,
				blackPhone2: this.formData.customer.cusPhone2,
				blackPhone3: this.formData.customer.cusPhone3,
			};
			this.addBlackListInfo = formData;
			this.blackListPop = !this.blackListPop;
		},
		// 블랙리스트등록시 넘겨줄 데이터 생년월일
		getBirthData(cusRegiNum1: any, cusRegiNum2: any) {
			let n1 = Number(cusRegiNum1.substring(0, 2));
			let n2 = Number(cusRegiNum2.substring(0, 1));
			let year;
			if (n2 === 1 || n2 === 2 || n2 === 5 || n2 === 6) {
				//뒤 첫째값이 1, 2, 5, 6일 경우(1900년대에 출생한 남녀)
				year = 1900 + n1;
			}
			if (n2 === 3 || n2 === 4 || n2 === 7 || n2 === 8) {
				//뒤 첫째값이 3, 4, 7, 8일 경우(2000년대에 출생한 남녀)
				year = 2000 + n1;
			}
			let month = cusRegiNum1.substring(2, 4);
			let day = cusRegiNum1.substring(4);
			return `${String(year)}-${month}-${day}`;
		},
		prevPage() {
			this.$router.push(`sell-status?prevPage=true`);
		},
		async finalSubmit() {
			// 신청서저장
			if (this.formData.join.actualSellAmt < 0) {
				return alert('올바른 할부원금을 위해 금액 정보를 수정해 주세요.');
			}
			if (this.formData.join.monthDvcAmt < 0) {
				return alert('올바른 할부원금을 위해 금액 정보를 수정해 주세요.');
			}
			await this.resetValidation();
			if (await this.validationRuleFnc()) return;
			// 필수값처리
			if (this.memoData.memoContents && this.memoData.category === '') {
				alert('메모에 대한 공개범위를 설정해주세요.');
				return;
			}

			// 22.06.02 일련번호 입력을 했을 시 해당 스팩 필수 확인
			// deviceInfo = rawBarcode, goodsId, capacity, color
			// usimInfo = rawBarcode, capacity
			if (this.formData.join.deviceRawBarcode) {
				if (
					!nullValidation2(this.formData.join, [
						'goodsId', // 기기Id
						'capacity', // 용량
						'color', // 색상
					])
				) {
					return true;
				}
			}
			if (this.formData.join.usimRawBarcode) {
				if (
					!nullValidation2(this.formData.join, [
						'usimType', // 유심종류
					])
				) {
					return true;
				}
			}
			// 기기일련, 유심일련을 입력한 경우
			if (
				this.formData.join.deviceRawBarcode ||
				this.formData.join.usimRawBarcode
			) {
				await this.checkMoveDeviceFromBarcode();
			}
			if (this.checkMoveDeviceDialog === true) {
				return false;
			}

			if (confirm('저장하시겠습니까?')) {
				await this.insertFinalSubmit(); // submit
			}
			// await this.formDataResetFnc(); // 신청서 초기화
		},
		async updateSubmit() {
			if (this.formData.join.actualSellAmt < 0) {
				return alert('올바른 할부원금을 위해 금액 정보를 수정해 주세요.');
			}
			if (this.formData.join.monthDvcAmt < 0) {
				return alert('올바른 할부원금을 위해 금액 정보를 수정해 주세요.');
			}
			await this.resetValidation();
			if (await this.validationRuleFnc()) return; // 필수값처리
			// @ts-ignore
			await this.updateApplicationSubmit(); // submit
		},
		async totalEnumFnc() {
			await this.getSaleStoreList();
			await this.commonCodeListFnc();
		},
		async getSaleStoreList() {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getSaleStoreList',
			);
		},
		async fileUploadFnc(data: any) {
			if (this.formData.etc.attachList.length !== 0) {
				let formData: any = new FormData();
				formData.append('applId', this.formData.etc.applId);
				for (let i = 0; i < this.formData.etc.attachList.length; i++) {
					if (this.formData.etc.attachList[i].applAttachId) {
						return;
					}
					formData.append('attachFile', this.formData.etc.attachList[i]);
					let fileHtml = []; // 파일 이력
					let fileObj: any = {
						title: '첨부파일',
						// @ts-ignore
						data: this.formData.etc.attachList[i].name,
						changeData: '추가',
					};
					fileHtml.push(fileObj);
					let fileHtmlString = htmlParse(fileHtml);
					formData.append('memoContents', fileHtmlString);
				}
				formData.append('taskMemoId', data);
				// 파일 이력
				await this.$store.dispatch(
					'ApplicationFormCreationModule/fileUpload',
					formData,
				);
			}
		},
		async commonCodeListFnc() {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getCommonCodeList',
			);
			await this.usimPaymentTypeItemsSort(); // 유심 지불 방식 목록 정렬
		},
		usimPaymentTypeItemsSort() {
			let applType = this.formData.basic.applType;
			// 유심지불 목록 정렬
			const copyUsimPaymentTypeItems = this.codeList.usimPaymentTypeItemsCopy;
			let tempUsimPaymentTypeItemsY = Array<Object>();
			let tempUsimPaymentTypeItemsN = Array<Object>();
			copyUsimPaymentTypeItems.forEach(function (item: any) {
				if (
					applType === 'DVC' &&
					(item.value === 'EXIST_USIM' ||
						item.value === 'OTHER_PRE_PAY' ||
						item.value === 'OTHER_POST_PAY')
				) {
					tempUsimPaymentTypeItemsY.push(item);
				} else if (
					applType === 'DVC_USIM' &&
					(item.value === 'PRE_PAY' ||
						item.value === 'POST_PAY' ||
						item.value === 'OTHER_PRE_PAY' ||
						item.value === 'OTHER_POST_PAY')
				) {
					tempUsimPaymentTypeItemsY.push(item);
				} else if (
					applType === 'USIM' &&
					(item.value === 'USIM_ONLY_PRE_PAY' ||
						item.value === 'USIM_ONLY_POST_PAY')
				) {
					tempUsimPaymentTypeItemsY.push(item);
				} else {
					tempUsimPaymentTypeItemsN.push(item);
				}
			});
			this.codeList.usimPaymentTypeItems = [];
			this.codeList.usimPaymentTypeItems = [
				...tempUsimPaymentTypeItemsY,
				...tempUsimPaymentTypeItemsN,
			];
		},
		async insertFinalSubmit() {
			if (this.formData.payment.paymentType === 'EXIST_SAME') {
				// @ts-ignore
				this.formData.payment = {};
				// @ts-ignore
				this.formData.payment = { paymentType: 'EXIST_SAME' };
			}
			// 신청서 최초작성 일련번호 있을시 매칭타입 추가
			// 22.06.02 신청서작성시 재고매칭 프로세스 추가로 인해 제거
			/*
			if (this.formData.join.deviceRawBarcode) {
				this.formData.join.deviceMatchingType = 'MATCHING_TYPE_NOT_STOCK';
			}
			if (this.formData.join.usimRawBarcode) {
				this.formData.join.usimMatchingType = 'MATCHING_TYPE_NOT_STOCK';
			}
			 */
			if (
				this.formData.join.deviceRawBarcode &&
				this.matchingDeviceType === 'MATCHING_TYPE_NOT_STOCK'
			) {
				this.formData.join.deviceMatchingType = 'MATCHING_TYPE_NOT_STOCK';
			}
			if (
				this.formData.join.deviceRawBarcode &&
				this.matchingUsimType === 'MATCHING_TYPE_NOT_STOCK'
			) {
				this.formData.join.usimMatchingType = 'MATCHING_TYPE_NOT_STOCK';
			}

			let consultTodoDto = this.applConsultTodoDto;
			if (consultTodoDto.notiYn === 'Y') {
				if (!consultTodoDto.reservationDateTime) {
					return alert('예약 일시를 입력해주세요.');
				}
				if (!consultTodoDto.toDoContents) {
					return alert('알림 내용을 입력해주세요.');
				}
				consultTodoDto.consultTodoType = 'CUSTOMER';
				if (typeof consultTodoDto.time === 'object') {
					consultTodoDto.time =
						consultTodoDto.time.HH + ':' + consultTodoDto.time.mm;
				}
				consultTodoDto.reservationDateTime =
					consultTodoDto.reservationDateTime + 'T' + consultTodoDto.time;
			}

			if (this.AppFormCreFlag === undefined) {
				this.formData.basic.consultTaskStatus = null; // 상담업무상태
				this.formData.basic.openingTaskStatus = null; // 개통업무상태
				this.formData.basic.logisticsTaskStatus = null; // 물류업무상태
			}
			if (this.formData.join.existTelecomCodeId === 1) {
				this.formData.join.existTelecomCodeId = this.thriftyPhone;
			}
			if (this.formData.basic.priorityTargetYn === true) {
				this.formData.basic.priorityTargetYn = 'Y';
			} else {
				this.formData.basic.priorityTargetYn = 'N';
			}
			let returnOpeningStoreId = { ...this.formData.basic.openingStoreId };
			this.formData.basic.openingStoreId =
				this.formData.basic.openingStoreId.openStoreCode;
			if (!this.formData.basic.openingStoreId) {
				alert('데이터 저장 실패했습니다. 다시 시도해주세요.');
				return false;
			}
			let formData: any = this.formData;
			let memoData = { memo: this.memoData };
			if (
				(this.memoData.memoContents !== null, this.memoData.category !== '')
			) {
				Object.assign(formData, memoData);
			}
			if (
				formData.basic.openingStoreId === null ||
				formData.basic.openingStoreId === undefined ||
				formData.basic.openingStoreId === ''
			) {
				alert('개통점을 선택해 주세요.');
				return;
			}

			// 매칭할 기기가 있는 경우 배송구분 택배(영업점 배송)
			if (
				this.formData.join.deviceRawBarcode &&
				this.matchingDeviceType === 'MATCHING_TYPE_STOCK'
			) {
				for (let item in formData.delivery) {
					formData.delivery[item] = null;
				}
				formData.delivery.deliveryType = 'PS_SALES';
			}

			const result = await this.$store.dispatch(
				'ApplicationFormCreationModule/insertList',
				formData,
			);

			// 매칭할 기기/유심이 있을 경우 기기매칭 처리
			// 기기 매칭 처리
			if (this.matchingDeviceType === 'APPL_MATCHING') {
				this.matchingDeviceType = 'MATCHING_TYPE_STOCK';
			}
			if (
				this.formData.join.deviceRawBarcode &&
				this.matchingDeviceType === 'MATCHING_TYPE_STOCK'
			) {
				// 매칭처리 진행(기기)
				this.matchingDvcInfo.applId = this.formData.basic.applId;
				await this.deviceMatchingFnc(this.matchingDvcInfo);
			}

			// 유심 매칭 처리
			if (this.matchingUsimType === 'APPL_MATCHING') {
				this.matchingUsimType = 'MATCHING_TYPE_STOCK';
			}
			if (
				this.formData.join.usimRawBarcode &&
				this.matchingUsimType === 'MATCHING_TYPE_STOCK'
			) {
				// 매칭처리 진행(유심)
				this.matchingUsimInfo.applId = this.formData.basic.applId;
				await this.deviceMatchingFnc(this.matchingUsimInfo);
			}

			if (result) {
				await this.fileUploadFnc(null); // fileUpload
				alert('완료되었습니다.');
				this.dialog = false;
				let paramConsult = {};
				this.$store.state.CustomerConsultModule.applInsertTempId =
					this.formData.basic.applId;
				if (this.cnsltId === 0) {
					// 신청서 작성후 상담DB 저장
					paramConsult = {
						applId: this.formData.basic.applId,
					};
				} else if (this.cnsltId > 0) {
					paramConsult = {
						applId: this.formData.basic.applId,
						cnsltId: this.cnsltId,
					};
				}
				const resultConsult = await this.$store.dispatch(
					'SalesMgmtModule/insertApplicationFromConsult',
					paramConsult,
				);
				this.$store.state.CustomerConsultModule.formDataDetail.cnsltId =
					resultConsult.data.data;
				await this.getMainList();

				let cnsltIdRes = resultConsult.data.data;
				if (consultTodoDto.notiYn === 'Y' && this.cnsltId === 0) {
					consultTodoDto.cnsltId = cnsltIdRes;
					await this.insertConsultTodo(consultTodoDto);
				} else if (consultTodoDto.notiYn === 'Y' && this.cnsltId !== 0) {
					consultTodoDto.cnsltId = this.cnsltId;
					await this.insertConsultTodo(consultTodoDto);
				}
				if (
					this.cnsltId === 0 &&
					this.applConsultMemoDto.memoContents !== null
				) {
					let data = {
						cnsltId: cnsltIdRes,
						// consultMemoType: this.consultMemoType,
						memoContents: this.applConsultMemoDto.memoContents,
					};
					let resultMemo = await this.$store.dispatch(
						'SalesMgmtModule/insertConsultMemo',
						data,
					);
				}
				this.formData.basic.openingStoreId = returnOpeningStoreId;
			} else {
				this.formData.basic.openingStoreId = returnOpeningStoreId;
			}
		},
		async insertConsultTodo(consultTodoDto: any) {
			const result = await this.$store.dispatch(
				'CustomerConsultModule/insertConsultTodo',
				consultTodoDto,
			);
		},
		async deviceMatchingFnc(matchingInfo: any) {
			const result2 = await this.$store.dispatch(
				'ApplicationMatchingModule/deviceMatching',
				matchingInfo,
			);
		},
		async checkMoveDeviceFromBarcode() {
			// 22.06.02 추가 신청서 등록시 기기/유심 매칭가능유무 확인
			// deviceInfo = rawBarcode, goodsId, capacity, colorName
			// usimInfo = rawBarcode, capacity
			this.matchingDeviceType = null;
			this.matchingUsimType = null;

			let data = {
				openingStoreId: this.formData.basic.openingStoreId.openStoreCode,
				deviceInfo: {
					rawBarcode:
						this.formData.join.deviceRawBarcode === ''
							? null
							: this.formData.join.deviceRawBarcode,
					goodsId: this.formData.join.goodsId,
					capacity: this.formData.join.capacity,
					colorName: this.formData.join.color,
				},
				usimInfo: {
					rawBarcode:
						this.formData.join.usimRawBarcode === ''
							? null
							: this.formData.join.usimRawBarcode,
					capacity: this.formData.join.usimType,
				},
			};
			const result = await this.$store.dispatch(
				'ApplicationFormCreationModule/cwsCheckMoveDeviceFromBarcode',
				data,
			);
			// 기기 매칭 유무 체크
			let dvcMatchRes = null;
			if (data.deviceInfo.rawBarcode && result.deviceCheckInfo) {
				if (this.matchingDeviceType === null) {
					dvcMatchRes = await this.checkMoveDeviceResultFnc(
						result.deviceCheckInfo,
					);
					this.matchingDeviceType = dvcMatchRes;
				}
			} else {
				this.matchingDeviceType = null;
			}

			// 유심 매칭 유무 체크
			let usimMatchRes = null;
			if (data.usimInfo.rawBarcode && result.usimCheckInfo) {
				if (this.matchingUsimType === null) {
					usimMatchRes = await this.checkMoveDeviceResultFnc(
						result.usimCheckInfo,
					);
					this.matchingUsimType = usimMatchRes;
				}
			} else {
				this.matchingUsimType = null;
			}

			// console.log('dvc : ' + dvcMatchRes)
			// console.log('usim : ' + usimMatchRes)
			// 개통점과 공급처가 상이한경우
			if (dvcMatchRes === 'DIFF_PROVIDER' && usimMatchRes === 'DIFF_PROVIDER') {
				// all
				await this.openMoveDivceDialog(
					'DVC_USIM',
					'DIFF_PROVIDER',
					'ALERT',
					result,
				);
			} else if (
				dvcMatchRes === 'DIFF_PROVIDER' &&
				usimMatchRes !== 'DIFF_PROVIDER'
			) {
				// dvc
				await this.openMoveDivceDialog('DVC', 'DIFF_PROVIDER', 'ALERT', result);
			} else if (
				dvcMatchRes !== 'DIFF_PROVIDER' &&
				usimMatchRes === 'DIFF_PROVIDER'
			) {
				// usim
				await this.openMoveDivceDialog(
					'USIM',
					'DIFF_PROVIDER',
					'ALERT',
					result,
				);

				// 신청서와 기기정보가 다른 경우
			} else if (
				dvcMatchRes === 'DIFF_APPLICATION_INFO' &&
				usimMatchRes === 'DIFF_APPLICATION_INFO'
			) {
				// all
				await this.openMoveDivceDialog(
					'DVC_USIM',
					'DIFF_APPLICATION_INFO',
					'ALERT',
					result,
				);
			} else if (
				dvcMatchRes === 'DIFF_APPLICATION_INFO' &&
				usimMatchRes !== 'DIFF_APPLICATION_INFO'
			) {
				// dvc
				await this.openMoveDivceDialog(
					'DVC',
					'DIFF_APPLICATION_INFO',
					'ALERT',
					result,
				);
			} else if (
				dvcMatchRes !== 'DIFF_APPLICATION_INFO' &&
				usimMatchRes === 'DIFF_APPLICATION_INFO'
			) {
				// usim
				await this.openMoveDivceDialog(
					'USIM',
					'DIFF_APPLICATION_INFO',
					'ALERT',
					result,
				);

				// 기존 신청서가 ... 상태이므로 매칭이 불가능
			} else if (dvcMatchRes === 'PROGRESS' && usimMatchRes === 'PROGRESS') {
				// all
				await this.openMoveDivceDialog('DVC_USIM', 'PROGRESS', 'ALERT', result);
			} else if (dvcMatchRes === 'PROGRESS' && usimMatchRes !== 'PROGRESS') {
				// dvc
				await this.openMoveDivceDialog('DVC', 'PROGRESS', 'ALERT', result);
			} else if (dvcMatchRes !== 'PROGRESS' && usimMatchRes === 'PROGRESS') {
				// usim
				await this.openMoveDivceDialog('USIM', 'PROGRESS', 'ALERT', result);

				// 매칭기기는있지만 물려있는 신청서가 있는경우
			} else if (
				dvcMatchRes === 'APPL_MATCHING' &&
				usimMatchRes === 'APPL_MATCHING'
			) {
				// all
				this.matchingDvcInfo = await this.matchingInfoSet(
					result.deviceCheckInfo,
					'DVC',
					'MATCHING_TYPE_STOCK',
					true,
				);
				this.matchingUsimInfo = await this.matchingInfoSet(
					result.usimCheckInfo,
					'USIM',
					'MATCHING_TYPE_STOCK',
					true,
				);
				await this.openMoveDivceDialog(
					'DVC_USIM',
					'APPL_MATCHING',
					'CONFIRM',
					result,
				);
			} else if (
				dvcMatchRes === 'APPL_MATCHING' &&
				usimMatchRes === 'MATCHING_TYPE_NOT_STOCK'
			) {
				// dvc AND usim - MATCHING_TYPE_NOT_STOCK
				this.matchingDvcInfo = await this.matchingInfoSet(
					result.deviceCheckInfo,
					'DVC',
					'MATCHING_TYPE_STOCK',
					true,
				);
				await this.openMoveDivceDialog(
					'DVC_BUT_USIM_NOT_MATCHING',
					'APPL_MATCHING',
					'CONFIRM',
					result,
				);
			} else if (
				dvcMatchRes === 'MATCHING_TYPE_NOT_STOCK' &&
				usimMatchRes === 'APPL_MATCHING'
			) {
				// usim AND dvc - MATCHING_TYPE_NOT_STOCK
				this.matchingUsimInfo = await this.matchingInfoSet(
					result.usimCheckInfo,
					'USIM',
					'MATCHING_TYPE_STOCK',
					true,
				);
				await this.openMoveDivceDialog(
					'USIM_BUT_DVC_NOT_MATCHING',
					'APPL_MATCHING',
					'CONFIRM',
					result,
				);
			} else if (
				dvcMatchRes === 'APPL_MATCHING' &&
				usimMatchRes !== 'APPL_MATCHING'
			) {
				// dvc
				this.matchingDvcInfo = await this.matchingInfoSet(
					result.deviceCheckInfo,
					'DVC',
					'MATCHING_TYPE_STOCK',
					true,
				);
				await this.openMoveDivceDialog(
					'DVC',
					'APPL_MATCHING',
					'CONFIRM',
					result,
				);
			} else if (
				dvcMatchRes !== 'APPL_MATCHING' &&
				usimMatchRes === 'APPL_MATCHING'
			) {
				// usim
				this.matchingUsimInfo = await this.matchingInfoSet(
					result.usimCheckInfo,
					'USIM',
					'MATCHING_TYPE_STOCK',
					true,
				);
				await this.openMoveDivceDialog(
					'USIM',
					'APPL_MATCHING',
					'CONFIRM',
					result,
				);
			}

			// 재고매칭
			// 기기 재고매칭
			if (dvcMatchRes === 'MATCHING_TYPE_STOCK') {
				// dvc
				this.matchingDvcInfo = await this.matchingInfoSet(
					result.deviceCheckInfo,
					'DVC',
					'MATCHING_TYPE_STOCK',
					false,
				);
			}
			// 유심 재고매칭
			if (usimMatchRes === 'MATCHING_TYPE_STOCK') {
				// usim
				this.matchingUsimInfo = await this.matchingInfoSet(
					result.usimCheckInfo,
					'USIM',
					'MATCHING_TYPE_STOCK',
					false,
				);
			}
			// 비재고매칭
			if (
				dvcMatchRes === 'MATCHING_TYPE_NOT_STOCK' &&
				usimMatchRes === 'MATCHING_TYPE_NOT_STOCK'
			) {
				// all
				await this.openMoveDivceDialog(
					'DVC_USIM',
					'MATCHING_TYPE_NOT_STOCK',
					'CONFIRM',
					result,
				);
				return true;
			} else if (
				dvcMatchRes === 'MATCHING_TYPE_NOT_STOCK' &&
				usimMatchRes === null
			) {
				// dvcOnly
				await this.openMoveDivceDialog(
					'DVC_ONLY',
					'MATCHING_TYPE_NOT_STOCK',
					'CONFIRM',
					result,
				);
				return true;
			} else if (
				dvcMatchRes === null &&
				usimMatchRes === 'MATCHING_TYPE_NOT_STOCK'
			) {
				// usimOnly
				await this.openMoveDivceDialog(
					'USIM_ONLY',
					'MATCHING_TYPE_NOT_STOCK',
					'CONFIRM',
					result,
				);
				return true;
			} else if (
				dvcMatchRes === 'MATCHING_TYPE_NOT_STOCK' &&
				usimMatchRes === 'MATCHING_TYPE_STOCK'
			) {
				// dvc
				await this.openMoveDivceDialog(
					'DVC',
					'MATCHING_TYPE_NOT_STOCK',
					'CONFIRM',
					result,
				);
				return true;
			} else if (
				dvcMatchRes === 'MATCHING_TYPE_STOCK' &&
				usimMatchRes === 'MATCHING_TYPE_NOT_STOCK'
			) {
				// usim
				await this.openMoveDivceDialog(
					'USIM',
					'MATCHING_TYPE_NOT_STOCK',
					'CONFIRM',
					result,
				);
				return true;
			}

			if (this.checkMoveDeviceDialog === true) {
				return false;
			}
		},
		async checkMoveDeviceResultFnc(dvcInfo: any) {
			if (dvcInfo.diffProviderYn === 'Y') {
				return 'DIFF_PROVIDER';
			}
			if (dvcInfo.diffApplicationInfoYn === 'Y') {
				return 'DIFF_APPLICATION_INFO';
			} else if (dvcInfo.matchingYn === 'Y') {
				if (dvcInfo.existPrevApplicationCount === 9999) {
					return 'PROGRESS';
				} else if (dvcInfo.existPrevApplicationCount > 0) {
					return 'APPL_MATCHING';
				} else if (dvcInfo.existPrevApplicationCount === 0) {
					return 'MATCHING_TYPE_STOCK';
				}
			} else if (dvcInfo.matchingYn === 'N') {
				// 매칭불가 -> 비재고매칭
				return 'MATCHING_TYPE_NOT_STOCK';
			}
		},
		async openMoveDivceDialog(
			dvcType: string,
			resType: string,
			msgType: string,
			res: any,
		) {
			this.dvcType = dvcType;
			let dvcText = dvcType === 'DVC' ? '기기' : '유심';
			if (
				dvcType === 'DVC' ||
				dvcType === 'DVC_ONLY' ||
				dvcType === 'DVC_BUT_USIM_NOT_MATCHING'
			) {
				dvcText = '기기';
			} else if (
				dvcType === 'USIM' ||
				dvcType === 'USIM_ONLY' ||
				dvcType === 'USIM_BUT_DVC_NOT_MATCHING'
			) {
				dvcText = '유심';
			} else {
				dvcText = '기기/유심';
			}
			if (resType === 'DIFF_PROVIDER') {
				this.matchingResultTitle = '개통점과 공급처가 불일치';
				this.matchingResultMsg =
					'개통점과 공급처가 일치하지 않는 ' + dvcText + '일련번호입니다.';
			} else if (resType === 'DIFF_APPLICATION_INFO') {
				console.log(dvcType);
				this.matchingResultTitle =
					dvcText + '일련번호 조회 정보와 신청서 정보 불일치';
				this.matchingResultMsg = '';
				// '해당 ' + dvcText + '일련번호로 검색된 정보입니다.';
				if (dvcType === 'DVC_USIM') {
					let usimTxt =
						this.formData.join.usimType === 'NANO_USIM' ? '나노' : '마이크로';
					this.matchingResultMsg +=
						'<div>' +
						'<table class="w100P"><tr>' +
						'<td><b>기기</b></td>' +
						'<td><b>용량</b></td>' +
						'<td><b>색상</b></td>' +
						'</tr>' +
						'<tr>' +
						'<td>신청서 정보</td>' +
						'<td>' +
						this.formData.join.capacity +
						'</td>' +
						'<td>' +
						this.formData.join.color +
						'</td>' +
						'</tr>' +
						'<tr>' +
						'<td>기기일련번호 조회 정보</td>' +
						'<td>' +
						res.deviceCheckInfo.deviceDto.goodsOptionDto.capacity +
						'</td>' +
						'<td>' +
						res.deviceCheckInfo.deviceDto.goodsOptionDto.colorName +
						'</td>' +
						'</tr><tr>' +
						'<td><b>유심</b></td>' +
						'<td colspan="2"><b>용량</b></td>' +
						'</tr>' +
						'<tr>' +
						'<td>신청서 정보</td>' +
						'<td colspan="2">' +
						usimTxt +
						'</td>' +
						'</tr>' +
						'<tr>' +
						'<td>유심일련번호 조회 정보</td>' +
						'<td colspan="2">' +
						res.usimCheckInfo.deviceDto.goodsOptionDto.capacity +
						'</td>' +
						'</tr>' +
						'</table>' +
						'</div>';
				} else if (dvcType === 'DVC') {
					this.matchingResultMsg +=
						'<div>' +
						'<table class="w100P"><tr>' +
						'<td><b>기기</b></td>' +
						'<td><b>용량</b></td>' +
						'<td><b>색상</b></td>' +
						'</tr>' +
						'<tr>' +
						'<td>신청서 정보</td>' +
						'<td>' +
						this.formData.join.capacity +
						'</td>' +
						'<td>' +
						this.formData.join.color +
						'</td>' +
						'</tr>' +
						'<tr>' +
						'<td>기기일련번호 조회 정보</td>' +
						'<td>' +
						res.deviceCheckInfo.deviceDto.goodsOptionDto.capacity +
						'</td>' +
						'<td>' +
						res.deviceCheckInfo.deviceDto.goodsOptionDto.colorName +
						'</td>' +
						'</tr>' +
						'</table>' +
						'</div>';
				} else if (dvcType === 'USIM') {
					let usimTxt =
						this.formData.join.usimType === 'NANO_USIM' ? '나노' : '마이크로';
					this.matchingResultMsg +=
						'<div>' +
						'<table class="w100P"><tr>' +
						'<td><b>유심</b></td>' +
						'<td><b>용량</b></td>' +
						'</tr>' +
						'<tr>' +
						'<td>신청서 정보</td>' +
						'<td>' +
						usimTxt +
						'</td>' +
						'</tr>' +
						'<tr>' +
						'<td>유심일련번호 조회 정보</td>' +
						'<td>' +
						res.usimCheckInfo.deviceDto.goodsOptionDto.capacity +
						'</td>' +
						'</tr>' +
						'</table>' +
						'</div>';
				}
				this.matchingResultMsg += '</br>신청서 기기정보를 확인해주세요.';
			} else if (resType === 'PROGRESS') {
				this.matchingResultTitle = '기존 신청서가 진행중으로 매칭 불가';
				this.matchingResultMsg =
					'기존 신청서의 개통 / 물류 상태가 아래와 같으면 매칭이 불가능합니다.<br />' +
					'- 개통 : 개통완료<br />' +
					'- 물류 : 배송준비 / 집하전(송장등록완료) / 배송중';
			} else if (resType === 'APPL_MATCHING') {
				this.matchingResultTitle = '기존 신청서에 매칭된 ' + dvcText;
				this.matchingResultMsg =
					'기존 신청서에 이미 매칭된 ' + dvcText + '일련번호입니다.';

				if (dvcText === '기기') {
					this.matchingResultMsg +=
						'</br>매칭을 진행하시면 기존 신청서는 취소 처리됩니다.';
				} else if (dvcText === '기기/유심') {
					this.matchingResultMsg +=
						'</br>매칭을 진행하시면 기기가 매칭된 기존 신청서는 취소 처리됩니다.';
				}

				if (dvcType === 'DVC_BUT_USIM_NOT_MATCHING') {
					this.matchingResultMsg +=
						'<br/><br/>입력된 유심일련번호를 통해 확인된 정보입니다.';
					this.matchingResultMsg +=
						'<div>' +
						'<table>' +
						'<tr>' +
						'<td>유심</td>' +
						'</tr>' +
						'<tr>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'</tr>' +
						'</table>' +
						'</div>';
				} else if (dvcType === 'USIM_BUT_DVC_NOT_MATCHING') {
					this.matchingResultMsg +=
						'<br/><br/>입력된 기기일련번호를 통해 확인된 정보입니다.';
					this.matchingResultMsg +=
						'<div>' +
						'<table>' +
						'<tr>' +
						'<td>기기</td>' +
						'</tr>' +
						'<tr>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'</tr>' +
						'</table>' +
						'</div>';
				}
				this.matchingResultMsg +=
					'<br /><br />위 내용으로 신청서 작성을 진행하시겠습니까?';
			} else if (resType === 'MATCHING_TYPE_NOT_STOCK') {
				this.matchingResultTitle = dvcText + ' 비재고 매칭 정보 확인';
				this.matchingResultMsg = '';
				// this.matchingResultMsg = '입력된 ' + dvcText + '일련번호를 통해 확인된 정보입니다.</br>';
				this.matchingResultMsg += '<div>' + '<table>';
				if (dvcType === 'DVC_USIM') {
					this.matchingResultMsg +=
						'<tr>' +
						'<td>기기</td>' +
						'<td>유심</td>' +
						'</tr>' +
						'<tr>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'</tr>';
				} else if (dvcType === 'DVC_ONLY') {
					this.matchingResultMsg +=
						'<tr>' +
						'<td>기기</td>' +
						'</tr>' +
						'<tr>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'</tr>';
				} else if (dvcType === 'USIM_ONLY') {
					this.matchingResultMsg +=
						'<tr>' +
						'<td>유심</td>' +
						'</tr>' +
						'<tr>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'</tr>';
				} else if (dvcType === 'DVC') {
					this.matchingResultMsg +=
						'<tr>' +
						'<td>기기</td>' +
						'<td>유심</td>' +
						'</tr>' +
						'<tr>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'<td>재고 매칭</td>' +
						'</tr>';
				} else if (dvcType === 'USIM') {
					this.matchingResultMsg +=
						'<tr>' +
						'<td>기기</td>' +
						'<td>유심</td>' +
						'</tr>' +
						'<tr>' +
						'<td>재고 매칭</td>' +
						'<td style="font-weight: 700; color:red;">비재고 매칭</td>' +
						'</tr>';
				}
				this.matchingResultMsg += '</table>' + '</div>';
				this.matchingResultMsg +=
					'<br />위 내용으로 신청서 작성을 진행하시겠습니까?';
			}
			this.matchingResultMsgType = msgType;
			this.checkMoveDeviceDialog = true;
		},
		async matchingInfoSet(
			dvcInfo: any,
			dvcType: string,
			matchingType: string,
			ChangeYn: boolean,
		) {
			let matchingInfo = {
				applId: null,
				barcode: dvcInfo.deviceDto.rawBarcode,
				dvcId: dvcInfo.deviceDto.dvcId,
				applType: this.formData.basic.applType,
				deviceType: dvcType,
				matchingType: matchingType,
				openingStoreId: this.formData.basic.openingStoreId.openStoreCode,
			};
			if (ChangeYn) {
				// @ts-ignore
				matchingInfo.myStockMatchingDvcChangeYn = 'Y';
			}
			return matchingInfo;
		},
		async updateApplicationSubmit(data: any) {
			let consultTodoDto = { ...this.applConsultTodoDto };
			if (consultTodoDto.notiYn === 'Y') {
				if (!consultTodoDto.reservationDateTime) {
					return alert('예약 일시를 입력해주세요.');
				}
				if (!consultTodoDto.toDoContents) {
					return alert('알림 내용을 입력해주세요.');
				}
				consultTodoDto.consultTodoType = 'CUSTOMER';
				if (typeof consultTodoDto.time === 'object') {
					consultTodoDto.time =
						consultTodoDto.time.HH + ':' + consultTodoDto.time.mm;
				}
				consultTodoDto.reservationDateTime =
					consultTodoDto.reservationDateTime + 'T' + consultTodoDto.time;
			}
			/*const data = objectCompare(this.formData, this.updateFormData);*/
			// for (let i = 0; i < this.formData.consultList.length; i++) {
			// 	if (this.formData.consultList[i].consultUserId === null) {
			// 		this.formData.consultList.splice(i, this.formData.consultList.length);
			// 	}
			// }
			/*const consult = '상담상태';
			const opening = '개통상태';
			const delivery = '물류상태';*/
			let alertMsg = '수정하시겠습니까?';
			if (data) {
				if (
					this.formData.basic.parentApplId !== null &&
					this.formData.basic.retractYn === 'N' &&
					data.value === 'OPENING_COMPL'
				) {
					// 신규신청서이고,
					// 교품신청서 A/S 기변이고
					// 개통 - 개통완료를 할때.

					if (
						this.formData.basic.openingDate === null ||
						this.formData.basic.openingDate === undefined
					) {
						alertMsg = `[${data.itemName}] 상태로 변경하시겠습니까?`;
					} else {
						alertMsg =
							'교품접수(반품) 신청서의 개통일자로 설정됩니다.\n' +
							'개통완료 설정후 개통일시를 변경하실 수 있습니다.';
					}
				} else {
					alertMsg = `[${data.itemName}] 상태로 변경하시겠습니까?`;
				}
				/*if (data.name === 'consultTaskStatus') {
					alertMsg = `${consult}를 ${data.itemName} 상태로 변경하시겠습니까?`;
				} else if (data.name === 'openingTaskStatus') {
					alertMsg = `${opening}를 ${data.itemName} 상태로 변경하시겠습니까?`;
				} else if (data.name === 'logisticsTaskStatus') {
					alertMsg = `${delivery}를 ${data.itemName} 상태로 변경하시겠습니까?`;
				}*/
			}
			if (confirm(alertMsg)) {
				// @ts-ignore
				document.getElementById('focusTarget').focus();
				let returnOpeningStoreId = { ...this.formData.basic.openingStoreId };
				this.formData.basic.openingStoreId =
					this.formData.basic.openingStoreId.openStoreCode;
				if (
					this.formData.customer.bizNum1 !== null ||
					this.formData.customer.bizNum2 !== null ||
					this.formData.customer.bizNum3 !== null
				) {
					this.formData.customer.bizNum =
						this.formData.customer.bizNum1 +
						this.formData.customer.bizNum2 +
						this.formData.customer.bizNum3;
				}
				if (this.formData.payment.paymentType === 'EXIST_SAME') {
					// @ts-ignore
					this.formData.payment = {};
					// @ts-ignore
					this.formData.payment = { paymentType: 'EXIST_SAME' };
				}
				if (this.formData.join.existTelecomCodeId === 1) {
					this.formData.join.existTelecomCodeId = this.thriftyPhone;
				}
				// 신청서 수정 일련번호 없을시 매칭타입 null 추가
				if (!this.formData.join.deviceRawBarcode) {
					this.formData.join.deviceMatchingType = null;
				}
				if (!this.formData.join.usimRawBarcode) {
					this.formData.join.usimMatchingType = null;
				}
				if (this.addServiceFormData.length > 0) {
					for (let i = 0; i < this.addServiceFormData.length; i++) {
						this.addServiceFormData[i][0].addSvcId = null;
						this.formData.join.addServiceList.push(
							this.addServiceFormData[i][0],
						);
					}
				}
				// 상태값 개통완료 철회완료시 날짜 삽입
				await this.openingTaskDateFnc(this.formData.basic.openingTaskStatus);
				const result = await this.$store.dispatch(
					'QuickOpeningPopModule/updateFormData',
					this.formData,
				);

				if (result) {
					//신청서 수정후 상담DB 수정
					let paramConsult = {
						applId: this.formData.basic.applId,
						cnsltId: this.cnsltId,
					};
					const resultConsult = await this.$store.dispatch(
						'SalesMgmtModule/updateApplicationFromConsult',
						paramConsult,
					);
				}
				// if (this.moduleName === 'ConsultDbModule') {
				if (result && this.moduleName === 'CustomerDbModule') {
					if (consultTodoDto.notiYn === 'Y') {
						consultTodoDto.cnsltId = this.cnsltId;
						await this.insertConsultTodo(consultTodoDto);
					} else if (consultTodoDto.notiYn === 'N') {
						consultTodoDto.notiYn = 'N';
						consultTodoDto.cnsltId = this.cnsltId;
						consultTodoDto.reservationDateTime = null;
						await this.insertConsultTodo(consultTodoDto);
					}
				}

				if (!result) {
					this.formData.basic.openingStoreId = returnOpeningStoreId;
				}
				// 반품도착지 팝업에서 취소할경우 22.05.16
				if (this.formData.basic.consultTaskStatus === 'RETURN_DMND_CONSULT') {
					this.$store.state.ApplicationFormCreationModule.returnDialog = false;
				}
				// 교품 / 불량취소 팝업에서 취소할경우 22.06.12
				if (
					this.formData.basic.consultTaskStatus === 'FAULTY_CANCEL' ||
					this.formData.basic.consultTaskStatus === 'EXCHANGE_RCPT_CONSULT' ||
					this.formData.basic.consultTaskStatus === 'RETRACT_DMND_CONSULT'
				) {
					this.$store.state.ApplicationFormCreationModule.excFauDialog = false;
				}
				if (result) {
					await this.fileUploadFnc(result.data.data);
					sendReload('appl');
					alert('수정되었습니다.');
				}

				let data = {
					applId: this.AppFormCreFlag,
				};
				await this.$store.dispatch('TaskHistoryModule/getHistoryList', data);
				await this.commonCodeListFnc(); // 상태 enum reset
				await this.getApplicationDetail();
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getGoodsSelectList',
					this.formData.join.openingTelecomCodeId,
				);
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getTelecomChargeList',
					this.formData.join.openingTelecomCodeId,
				);
				this.formData.basic.openingStoreId = returnOpeningStoreId;
				await this.setOpeningStoreFnc();
				bus.$emit('fileReload');
				await this.getMainList();
			} else {
				return true;
			}
		},
		async getApplicationDetail() {
			let applId = { applId: this.AppFormCreFlag };
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getApplicationDetail',
				applId,
			);
			bus.$emit('callConsultDefaultFnc', this.formData.cnsltId);
		},
		async applReload() {
			let data = {
				applId: this.AppFormCreFlag,
			};
			await this.$store.dispatch('TaskHistoryModule/getHistoryList', data);
			let applId = { applId: this.AppFormCreFlag };
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getApplicationDetail',
				applId,
			);
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getGoodsSelectList',
				this.formData.join.openingTelecomCodeId,
			);
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getTelecomChargeList',
				this.formData.join.openingTelecomCodeId,
			);
			await this.getCapacityListFnc();
			await this.getColorListFnc();
			let returnOpeningStoreId = { ...this.formData.basic.openingStoreId };
			this.formData.basic.openingStoreId = returnOpeningStoreId;
			await this.setOpeningStoreFnc();
			// this.getApplicationAttachList();
			bus.$emit('fileReload');
		},
		async getCapacityListFnc() {
			if (this.joinData.goodsId) {
				this.colorItems = [];
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getCapacitySelectList',
					this.joinData.goodsId,
				);
			}
		},
		async getColorListFnc() {
			if (this.joinData.goodsId && this.joinData.capacity) {
				let data = {
					goodsId: this.joinData.goodsId,
					capacity: this.joinData.capacity,
				};
				await this.$store.dispatch(
					'ApplicationFormCreationModule/getColorSelectList',
					data,
				);
			}
		},
		// null 체크
		async validationRuleFnc() {
			if (await this.basicValidationFnc()) return true; // 기본정보 필수값
			if (await this.customerValidationFnc()) return true; // 고객정보 필수값
			if (await this.customerAuthValidationFnc()) return true; // 고객정보 - 신분증진위 필수값
			if (await this.deliveryValidationFnc()) return true; // 배송정보 필수값
			if (await this.joinValidationFnc()) return true; // 가입정보 필수값
			if (this.formData.basic.beforeOpeningType === 'BEFORE_OPENING') {
				if (await this.paymentValidationFnc()) return true; // 납부정보 필수값
			}
			/*if(this.etcValidationFnc()) return true; // 기타 필수값*/
		},
		// 기본정보 필수값
		basicValidationFnc() {
			// 사전예약 필수값
			// @ts-ignore
			/*if (this.formData.basic.beforeReserveYn === 'Y') {
				if (
					!nullValidation2(this.formData.basic, [
						'reserveNum', //사전예약번호
					])
				) {
					return true;
				}
			}*/
			if (
				!nullValidation2(this.formData.basic, [
					'saleStoreId', // 영업점
					'openingStoreId', // 개통점
					'applRegiUserId', // 등록자
				])
			) {
				return true;
			}
		},
		// 고객정보 필수값
		customerValidationFnc() {
			const cusTypeINDV = this.formData.customer.cusType === 'INDV'; //고객유형 - 개인
			const cusTypeMINORS = this.formData.customer.cusType === 'MINORS'; //고객유형 - 미성년자
			const cusTypeBSNM = this.customerDialog; //고객유형 - 개인사업자
			const cusTypeCORP = this.formData.customer.cusType === 'CORP'; //고객유형 - 법인
			const cusTypeFOREIGN = this.formData.customer.cusType === 'FOREIGN'; // 고객유형 - 외국인
			this.customerReset();
			if (cusTypeMINORS) {
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
				// if (
				// 	!regiNumCheck(
				// 		this.formData.customer.courtProctorRegiNum1,
				// 		this.formData.customer.courtProctorRegiNum2,
				// 	)
				// ) {
				// 	alert('법정대리인 주민등록번호를 확인해주세요.');
				// 	return true;
				// }
			}
			if (cusTypeBSNM) {
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (cusTypeCORP) {
				this.customerVal.splice(
					11,
					0,
					'bizName',
					'bizNum1',
					'bizNum2',
					'bizNum3',
					'bizPhone1',
					'bizPhone2',
					'bizPhone3',
					'bizRegiNum1',
					'bizRegiNum2',
					'cusName',
					'cusRegiNum1',
				);
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (cusTypeFOREIGN) {
				this.customerVal.splice(11, 0, 'licenseNum1', 'licenseNum2');
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (cusTypeINDV || this.formData.customer.cusType === 'INDV_BSNM') {
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (!nullValidation2(this.formData.customer, this.customerVal)) {
				return true;
			}
			// if (
			// 	this.formData.customer.cusType !== 'CORP' && // 법인이 아닐시에만
			// 	!regiNumCheck(
			// 		this.formData.customer.cusRegiNum1,
			// 		this.formData.customer.cusRegiNum2,
			// 	)
			// ) {
			// 	alert('고객 주민등록번호를 확인해주세요.');
			// 	return true;
			// }
		},
		customerAuthValidationFnc() {
			this.customerReset();

			const REGI = this.formData.customer.licenseAuthType === 'REGI_RGSTR_CARD'; // 신분증진위 - 주민등록증
			const DRIVER =
				this.formData.customer.licenseAuthType === 'DRIVER_LICENSE'; // 신분증진위 - 운전면허증
			const DSBL = this.formData.customer.licenseAuthType === 'DSBL_RGSTR_CARD'; // 신분증진위 - 장애인등록증
			const NTNL = this.formData.customer.licenseAuthType === 'NTNL_CARD'; // 신분증진위 - 국가유공자증
			const FOREIGN =
				this.formData.customer.licenseAuthType === 'FOREIGN_RGSTR_CARD'; // 신분증진위 - 외국인등록증
			if (REGI) {
				this.customerVal.push('licenseIssueDate');
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (DRIVER) {
				this.customerVal.push(
					'licenseIssueDate',
					'licenseIssueArea',
					'licenseNum1',
					'licenseNum2',
					'licenseNum3',
				);
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (DSBL) {
				this.customerVal.push('licenseIssueDate');
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (NTNL) {
				this.customerVal.push('licenseIssueDate');
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (FOREIGN) {
				this.customerVal.push(
					'licenseNum1',
					'licenseNum2',
					'licenseIssueDate',
					'licenseExpiredDate',
					'stayCode',
					'ntnlCode',
				);
				if (!nullValidation2(this.formData.customer, this.customerVal)) {
					return true;
				}
			}
			if (!nullValidation2(this.formData.customer, this.customerVal)) {
				return true;
			}
		},
		// 납부정보 필수값
		paymentValidationFnc() {
			this.paymentReset();
			const EXIST = this.formData.payment.paymentType === 'EXIST_SAME'; // 납부방법 -기존과동일
			const ACCOUNT = this.formData.payment.paymentType === 'ACC_TRNS'; // 납부방법 - 계좌이체
			const CARD = this.formData.payment.paymentType === 'CARD_PAY'; // 납부방법 - 카드
			const GIRO = this.formData.payment.paymentType === 'GIRO'; // 납부방법 - 지로
			if (EXIST) {
				this.paymentVal = [];
				if (!nullValidation2(this.formData.payment, this.paymentVal)) {
					return true;
				}
			}
			if (ACCOUNT) {
				this.paymentVal.push(
					'bankCode',
					'accountNum',
					'accountHolderName',
					'accountHolderBirth',
					'accountHolderRelation',
				);
				if (!nullValidation2(this.formData.payment, this.paymentVal)) {
					return true;
				}
			}
			if (CARD) {
				this.paymentVal.push(
					'cardCompanyCode',
					'cardNum1',
					'cardNum2',
					'cardNum3',
					'cardNum4',
					'cardHolderName',
					'cardHolderRegiNum1',
					'cardHolderRegiNum2',
					'cardValidityMm',
					'cardValidityYy',
				);
				if (!nullValidation2(this.formData.payment, this.paymentVal)) {
					return true;
				}
			}
			if (GIRO) {
				this.paymentVal.push('giroZipCode', 'giroAddr', 'giroAddrDetail');
				if (!nullValidation2(this.formData.payment, this.paymentVal)) {
					return true;
				}
			}
			if (!nullValidation2(this.formData.payment, this.paymentVal)) {
				return true;
			}
		},
		// 가입정보 필수값
		joinValidationFnc() {
			this.joinReset();
			const chipPhone =
				this.formData.join.existTelecomCodeId === 1 && this.thriftyPhone === 0;
			if (chipPhone) {
				alert('알뜰폰을 입력해주세요.');
				return true;
			}
			const NUM_CHANGE =
				this.formData.join.joinType === 'NUM_CHANGE_S' ||
				this.formData.join.joinType === 'NUM_CHANGE_K' ||
				this.formData.join.joinType === 'NUM_CHANGE_L' ||
				this.formData.join.joinType === 'NUM_CHANGE_V'; // 번호이동
			if (NUM_CHANGE) {
				if (this.formData.join.numChangeAuthType !== 'GIRO') {
					this.joinVal.splice(
						6,
						0,
						'numChangeAuthType', // 인증방식
						'numChangeAuthTypeNum', // 인증번호
					);
				} else {
					this.joinVal.splice(
						6,
						0,
						'numChangeAuthType', // 인증방식
					);
				}
				if (!nullValidation2(this.formData.join, this.joinVal)) {
					return true;
				}
			}
			if (!nullValidation2(this.formData.join, this.joinVal)) {
				return true;
			}
			if (this.$store.state.ApplicationFormCreationModule.deviceChange) {
				alert('변경된 기기일련번호 저장을 해주세요.');
				return true;
			}
			if (this.$store.state.ApplicationFormCreationModule.usimChange) {
				alert('변경된 유심일련번호 저장을 해주세요.');
				return true;
			}
		},
		// 배송정보 필수값
		deliveryValidationFnc() {
			this.deliveryReset();
			const PS = this.formData.delivery.deliveryType === 'PS'; // 배송정보
			const PS_SALES = this.formData.delivery.deliveryType === 'PS_SALES'; // 자체발송
			if (PS) {
				if (
					this.formData.delivery.invoiceNum &&
					!this.formData.delivery.courierCodeId
				) {
					alert('택배사를 입력해주세요.');
					return true;
				}
				this.deliveryVal.push(
					'deliveryZipCode', // 우편번호
					'deliveryAddr', // 기본주소
					'deliveryAddrDetail', // 상세주소
				);
				if (!nullValidation2(this.formData.delivery, this.deliveryVal)) {
					return true;
				}
			}
			if (PS_SALES) {
				if (
					this.formData.delivery.invoiceNum &&
					!this.formData.delivery.courierCodeId
				) {
					alert('택배사를 입력해주세요.');
					return true;
				}
			}
			if (!nullValidation2(this.formData.delivery, this.deliveryVal)) {
				return true;
			}
		},
		// 기타 필수값
		etcValidationFnc() {
			if (!nullValidation2(this.formData.etc)) {
				return true;
			}
		},
		resetValidation() {
			this.customerReset();
			this.paymentReset();
			this.joinReset();
			this.deliveryReset();
		},
		customerReset() {
			this.customerVal = [];
			if (this.formData.customer.cusType === 'CORP') {
				// 법인
				this.customerVal.push(
					'chargeReductType', // 요금감면
					'cusPhone1', // 휴대폰번호
					'cusPhone2', // 휴대폰번호
					'cusPhone3', // 휴대폰번호
					'cusZipCode', // 고객우편번호
					'cusAddr', // 고객기본주소
					'cusAddrDetail', // 고객상세주소
					'cusType', // 고객유형
					// 'licenseAuthType', // 신분증진위
				);
			} else if (this.formData.customer.cusType === 'INDV_BSNM') {
				this.customerVal.push(
					'cusType', // 고객유형
					'cusName', // 고객이름
					'cusRegiNum1', // 사업자 등록번호
					'chargeReductType', // 요금감면
					'cusPhone1', // 휴대폰번호
					'cusPhone2', // 휴대폰번호
					'cusPhone3', // 휴대폰번호
					'cusZipCode', // 고객우편번호
					'cusAddr', // 고객기본주소
					'cusAddrDetail', // 고객상세주소
					// 'licenseAuthType', // 신분증진위
				);
			} else if (this.formData.customer.cusType === 'FOREIGN') {
				this.customerVal.push(
					'cusName', // 고객명
					'chargeReductType', // 요금감면
					'cusPhone1', // 휴대폰번호
					'cusPhone2', // 휴대폰번호
					'cusPhone3', // 휴대폰번호
					'cusZipCode', // 고객우편번호
					'cusAddr', // 고객기본주소
					'cusAddrDetail', // 고객상세주소
					'cusType', // 고객유형
				);
			} else if (this.formData.customer.cusType === 'MINORS') {
				this.customerVal.push(
					'cusType', // 고객유형
					'courtProctorRelation', // 법정대리인 관계
					'courtProctorName', // 법정대리인명
					'courtProctorRegiNum1', // 법정대리인 주민번호
					'courtProctorRegiNum2', // 법정대리인 주민번호
					'courtProctorPhone1', // 법정대리인 전화번호
					'courtProctorPhone2', // 법정대리인 전화번호
					'courtProctorPhone3', // 법정대리인 전화번호
					'cusName', // 고객명
					'cusRegiNum1', // 주민등록번호
					'chargeReductType', // 요금감면
					'cusPhone1', // 휴대폰번호
					'cusPhone2', // 휴대폰번호
					'cusPhone3', // 휴대폰번호
					'cusZipCode', // 고객우편번호
					'cusAddr', // 고객기본주소
					'cusAddrDetail', // 고객상세주소
					// 'licenseAuthType', // 신분증진위
				);
			} else {
				this.customerVal.push(
					'cusName', // 고객명
					'cusRegiNum1', // 주민등록번호
					'chargeReductType', // 요금감면
					'cusPhone1', // 휴대폰번호
					'cusPhone2', // 휴대폰번호
					'cusPhone3', // 휴대폰번호
					'cusZipCode', // 고객우편번호
					'cusAddr', // 고객기본주소
					'cusAddrDetail', // 고객상세주소
					'cusType', // 고객유형
					// 'licenseAuthType', // 신분증진위
				);
			}
		},
		paymentReset() {
			this.paymentVal = [];
			this.paymentVal.push(
				'paymentType', // 납부방법
				'billType', // 청구서종류
			);
		},
		joinReset() {
			this.joinVal = [];
			if (this.AppFlag > 28177 || !this.AppFlag) {
				this.joinVal.push(
					'openingPhone1', // 개통 휴대폰번호
					'openingPhone2', // 개통 휴대폰번호
					'openingPhone3', // 개통 휴대폰번호
					'openingTelecomCodeId', // 개통통신사
					'existTelecomCodeId', // 기존 통신사
					'joinType', // 가입유형
					'goodsId', // 기기명
					'capacity', // 용량
					'color', // 색상
					'usimPaymentType', // 유심
				);
				// 선개통
				if (this.formData.basic.beforeOpeningType === 'BEFORE_OPENING') {
					this.joinVal.push(
						'agreementType', // 약정유형
						'agreementPeriodType', // 약정기간
						'instlPeriodType', // 할부기간
						'chargeId', // 요금제
						'releaseAmt', // 출고가
					);
				} else {
					// 후개통
					if (
						this.formData.join.chargeId === 1472 ||
						this.formData.join.chargeId === 1473 ||
						this.formData.join.chargeId === 1474
					) {
					} else {
						this.joinVal.push(
							'chargeId', // 요금제
						);
					}
				}
			}
			if (this.AppFlag <= 28177) {
				this.joinVal.push(
					'openingPhone1', // 개통 휴대폰번호
					'openingPhone2', // 개통 휴대폰번호
					'openingPhone3', // 개통 휴대폰번호
					'openingTelecomCodeId', // 개통통신사
					'existTelecomCodeId', // 기존 통신사
					'joinType', // 가입유형
					'goodsId', // 기기명
					'usimPaymentType', // 유심
					'agreementType', // 약정유형
				);
				if (this.formData.basic.beforeOpeningType === 'BEFORE_OPENING') {
					this.joinVal.push(
						'agreementPeriodType', // 약정기간
						'instlPeriodType', // 할부기간
						'chargeId', // 요금제
					);
				}
			}
			if (this.formData.join.joinType === 'NEW') {
				this.joinVal.splice(0, 3, 'openingHopeNum');
			}
		},
		deliveryReset() {
			this.deliveryVal = [];
			this.deliveryVal.push('deliveryType');
		},
		formDataResetFnc() {
			this.blackList = '';
			this.$store.state.ApplicationFormCreationModule.paymentCheckType = false;
			this.formData.basic.openingDate = null;
			this.formData.basic.cancelDate = null;
			this.formData.basic.beforeOpeningType = 'AFTER_OPENING';
			this.formData.basic.applType = 'DVC'; // 개통종류
			this.formData.basic.saleStoreId = 0; // 영업점
			this.formData.basic.openingStoreId = 0; // 개통점
			this.formData.basic.applRegiUserId = 0; // 신청서등록자
			this.formData.basic.consultTaskStatus = null; // 상담업무상태
			this.formData.basic.openingTaskStatus = null; // 개통업무상태
			this.formData.basic.logisticsTaskStatus = null; // 물류업무상태
			this.formData.basic.delYn = null; // 삭제여부
			this.formData.basic.docTargetYn = 'N'; // 서류대상여부
			this.formData.basic.parentApplId = null; // 부모신청서아이디
			this.formData.basic.priorityTargetYn = false; // 우선대상여부
			this.formData.basic.regiDatetime = null; // 등록일시
			this.formData.basic.updDateTime = null; //수정일시
			this.formData.basic.parentHierarchy = null; // 상위계층
			this.formData.basic.parentSaleStoreId = null; // 상위 영업 관리점 아이디
			this.formData.basic.beforeReserveYn = 'N'; // 사전예약
			this.formData.basic.reserveNum = ''; // 사전예약 번호
			this.formData.basic.creditInquireId = null;
			this.formData.basic.retractYn = null;
			this.formData.customer.cusName = null; // 고객명
			this.formData.customer.cusRegiNumConcat = null; // 고객주민등록번호
			this.formData.customer.cusRegiNum1 = null; // 고객주민등록번호
			this.formData.customer.cusRegiNum2 = null; // 고객주민등록번호
			this.formData.customer.chargeReductType = 'NONE'; // 요금감면
			this.formData.customer.cusPhone = null; // 휴대폰번호
			this.formData.customer.cusPhone1 = null; // 휴대폰번호
			this.formData.customer.cusPhone2 = null; // 휴대폰번호
			this.formData.customer.cusPhone3 = null; // 휴대폰번호
			this.formData.customer.emgPhone = null; // 비상연락망
			this.formData.customer.emgPhone1 = null; // 비상연락망
			this.formData.customer.emgPhone2 = null; // 비상연락망
			this.formData.customer.emgPhone3 = null; // 비상연락망
			this.formData.customer.cusEmail = null; // 이메일
			this.formData.customer.cusAddr = null; // 고객기본주소
			this.formData.customer.cusAddrDetail = null; // 고객상세주소
			this.formData.customer.cusZipCode = null; // 고객우편번호
			this.formData.customer.cusType = 'INDV'; // 고객유형
			this.formData.customer.courtProctorName = null; // 법정대리인명
			this.formData.customer.courtProctorRegiNumConcat = null; // 법정대리인주민등록번호
			this.formData.customer.courtProctorRegiNum1 = null; // 법정대리인주민등록번호
			this.formData.customer.courtProctorRegiNum2 = null; // 법정대리인주민등록번호
			this.formData.customer.courtProctorPhone = null; // 법정대리인연락처
			this.formData.customer.courtProctorPhone1 = null; // 법정대리인연락처
			this.formData.customer.courtProctorPhone2 = null; // 법정대리인연락처
			this.formData.customer.courtProctorPhone3 = null; // 법정대리인연락처
			this.formData.customer.courtProctorRelation = null; // 법정대리인관계
			this.formData.customer.bizName = null; // 사업자명
			this.formData.customer.bizNum1 = null; // 사업자번호
			this.formData.customer.bizNum2 = null; // 사업자번호
			this.formData.customer.bizNum3 = null; // 사업자번호
			this.formData.customer.bizPhone = null; // 사업자연락처
			this.formData.customer.bizPhone1 = null; // 사업자연락처
			this.formData.customer.bizPhone2 = null; // 사업자연락처
			this.formData.customer.bizPhone3 = null; // 사업자연락처
			this.formData.customer.bizRegiNum1 = null; // 법인등록번호
			this.formData.customer.bizRegiNum2 = null; // 법인등록번호
			this.formData.customer.licenseAuthType = null; // 신분증진위
			this.formData.customer.licenseIssueDate = null; // 발급일자
			this.formData.customer.licenseExpiredDate = null; // 만료일자
			this.formData.customer.licenseIssueArea = null; // 만료일자
			this.formData.customer.licenseNum1 = null; // 면허번호
			this.formData.customer.licenseNum2 = null; // 면허번호
			this.formData.customer.licenseNum3 = null; // 면허번호
			this.formData.customer.stayCode = null; // 체류코드
			this.formData.customer.ntnlCode = null; // 국가
			this.formData.payment.paymentType = 'ACC_TRNS'; // 납부방법
			this.formData.payment.billType = null; // 청구서종류
			this.formData.payment.bankCode = null; // 은행정보
			this.formData.payment.accountNum = null; // 계좌번호
			this.formData.payment.accountHolderName = null; // 예금주
			this.formData.payment.accountHolderBirth = null; // 생년월일
			this.formData.payment.accountHolderRelation = null; // 관계
			this.formData.payment.cardCompanyCode = null; // 카드정보
			this.formData.payment.cardNum1 = null; // 카드번호
			this.formData.payment.cardNum2 = null; // 카드번호
			this.formData.payment.cardNum3 = null; // 카드번호
			this.formData.payment.cardNum4 = null; // 카드번호
			this.formData.payment.cardHolderName = null; // 카드주
			this.formData.payment.cardHolderRegiNum1 = null; // 주민등록번호
			this.formData.payment.cardHolderRegiNum2 = null; // 주민등록번호
			this.formData.payment.cardValidityMm = null; // 유효기간MM
			this.formData.payment.cardValidityYy = null; // 유효기간YY
			this.formData.payment.giroZipCode = null; // 우편번호
			this.formData.payment.giroAddr = null; // 기본주소
			this.formData.payment.giroAddrDetail = null; // 상세주소
			this.formData.join.openingPhone1 = null; // 개통 휴대폰번호
			this.formData.join.openingPhone2 = null; // 개통 휴대폰번호
			this.formData.join.openingPhone3 = null; // 개통 휴대폰번호
			this.formData.join.openingHopeNum = null; // 개통희망번호
			this.formData.join.openingTelecomCodeId = null; // 개통통신사
			this.formData.join.openingTelecomName = null; // 개통통신사
			this.formData.join.existTelecomCodeId = 42; // 기존 통신사
			this.formData.join.joinType = 'NUM_CHANGE_S'; // 가입유형
			this.formData.join.numChangeAuthType = null; // 인증방식
			this.formData.join.numChangeAuthTypeNum = null; // 인증번호
			this.formData.join.goodsId = null; // 기기명
			this.formData.join.dvcId = null; // 기기명
			this.formData.join.usimDvcId = null; // 기기명
			this.formData.join.goodsId = null; // 기기명
			this.formData.join.capacity = ''; // 용량
			this.formData.join.color = ''; // 색상
			this.formData.join.modelName = null; // 모델네임
			this.formData.join.deviceRawBarcode = null; // 기기일련번호
			this.formData.join.usimPaymentType = ''; // 유심
			this.formData.join.usimRawBarcode = null; // 유심일련번호
			this.formData.join.usimType = 'NANO_USIM'; //
			this.formData.join.agreementType = 'CHOICE_AGRMN'; // 약정유형
			this.formData.join.agreementPeriodType = null; // 약정기간
			this.formData.join.instlPeriodType = null; // 할부기간
			this.formData.join.chargeId = null; // 요금제
			this.formData.join.addServiceList = []; // 부가서비스리스트
			this.formData.join.releaseAmt = 0; // 출고가
			this.formData.join.releaseAmtDate = null; // 출고가일자
			this.formData.join.openingPhone = ''; // 개통 휴대폰번호
			this.formData.join.pubNotiSupportAmt = 0; // 공시지원금
			this.formData.join.extraSupportAmt = 0; // 추가지원금
			this.formData.join.freeInstlAmt = 0; // 프리할부/선수납
			this.formData.join.etcDiscountAmt = 0; // 기타할인금
			this.formData.join.actualSellAmt = 0; // 실판매가
			this.formData.join.instlPrincipalAmt = 0; // 할부원금
			this.formData.join.monthDvcAmt = 0; // 월기기금액
			this.formData.join.monthBasicAmt = 0; // 월기본료
			this.formData.join.monthPaymentAmt = 0; // 월납부금액
			this.formData.join.deviceMatchingType = null; // 기기매칭타입
			this.formData.join.usimMatchingType = null; // 유심매칭타입
			this.formData.join.depositAmt = 0; // 입금받을금액
			this.formData.join.depositYn = 'N'; // 입금여부
			this.formData.join.pubNotiId = 0; // 공시알림아이디
			this.formData.delivery.deliveryType = 'PS'; // 배송구분
			this.formData.delivery.courierCodeId = 30; // 택배사 (우체국 디폴트)
			this.formData.delivery.invoiceNum = null; // 송장번호
			this.formData.delivery.deliveryZipCode = null; // 우편번호
			this.formData.delivery.deliveryAddr = null; // 기본주소
			this.formData.delivery.deliveryAddrDetail = null; // 상세주소
			this.formData.delivery.couriorMatchingYn = null; // 상세주소
			this.formData.delivery.deliveryCusName = null; // 실수령인이름
			this.formData.delivery.deliveryCusPhone = null; // 실수령인이름
			this.formData.delivery.deliveryCusPhone1 = null; // 실수령인이름
			this.formData.delivery.deliveryCusPhone2 = null; // 실수령인이름
			this.formData.delivery.deliveryCusPhone3 = null; // 실수령인이름
			this.formData.etc.giftEnclosedYn = 'N'; // 사은품동봉여부
			this.formData.etc.giftList[0].giftName = null; // 사은품
			this.formData.etc.usedDvcReclaimYn = 'N'; // 중고폰회수여부
			this.formData.etc.usedDvcModelName = null; // 중고폰모델명
			this.formData.etc.usedDvcCompe = null; // 중고보상
			this.formData.etc.attachList = []; // 파일추가
			this.formData.consultList[0].consultUserId = null;
			this.formData.consultList[1].consultUserId = null;
			this.formData.consultList[2].consultUserId = null;
			this.memoData.category = ''; // 메모공개범위
			this.memoData.memoContents = null; // 메모내용
			this.codeList.chargeReductTypeItems = []; // 요금감면
			this.codeList.customerTypeItems = []; // 고객유형
			this.codeList.licenseAuthTypeItems = []; // 신분증진위
			this.codeList.stayCodeItems = []; // 체류코드
			this.codeList.driverAreaCodeItems = []; // 운전면허지역코드 (앞자리)
			this.codeList.billTypeItems = []; // 청구서종류
			this.codeList.bankCodeItems = []; // 은행정보
			this.codeList.cardCompanyCodeItems = []; // 카드정보
			this.codeList.joinTypeItems = []; // 가입유형
			this.codeList.numChangeAuthTypeItems = []; // 인증방식
			this.codeList.usimPaymentTypeItems = []; // 유심방식
			this.codeList.agreementPeriodTypeItems = []; // 약정기간
			this.codeList.installmentsPeriodTypeItems = []; // 할부기간
			this.codeList.courierCodeItems = []; // 택배사코드
			this.codeList.goodsItems = []; // 기기명
			this.codeList.capacityItems = []; // 용량
			this.codeList.colorItems = []; // 색상
			this.codeList.saleStoreItems = []; // 영업점
			this.codeList.openingStoreItems = []; // 개통점
			this.codeList.telecomAddServiceItems = []; // 부가서비스
			this.codeList.telecomChargeItems = []; // 요금제
			this.codeList.existTelecomItems = []; // 기존 통신사
			this.codeList.thriftyPhoneItems = []; // 알뜰폰리스트
			this.codeList.storeMemberItems = []; // 등록자
			this.codeList.pubNotiInfoItems = []; // 요금제 조회
			this.codeList.nationalCodeItems = []; // 국가코드
			this.codeList.ConsultTaskStatusList = []; // 업무진행상태
			this.codeList.OpeningTaskStatusList = []; // 업무진행상태
			this.codeList.LogisticsTaskStatusList = []; // 업무진행상태
			this.codeList.detailList = []; // 블랙리스트
			this.codeList.counselorItems = []; // 상담원
			this.codeList.counselorItemsAll = []; // 상담원
			this.codeList.selOrganizationList = []; // 영업점 조직 목록

			this.creditTodoDetail = null;
			this.$store.state.ApplicationFormCreationModule.templateSeq = 0; // 템플릿 seq
			this.$store.state.ApplicationFormCreationModule.templateTitle = ''; // 템플릿 타이틀
			this.$store.state.ApplicationFormCreationModule.matchingDeviceType = null; // 작성시 비재고매칭 기기체크
			this.$store.state.ApplicationFormCreationModule.matchingUsimType = null; // 작성시 비재고매칭 유심체크
		},
		closeDialogFnc(data: any, dialogName: any) {
			// 모달 닫기 emit
			this.$set(this, dialogName, data);
		},
		async changeWorkStatusFnc(data: any) {
			if (this.formData.join.actualSellAmt < 0) {
				return alert('올바른 할부원금을 위해 금액 정보를 수정해 주세요.');
			}
			if (this.formData.join.monthDvcAmt < 0) {
				return alert('올바른 할부원금을 위해 금액 정보를 수정해 주세요.');
			}
			await this.resetValidation();
			if (await this.validationRuleFnc()) {
				// @ts-ignore
				this.formData.basic[data.name] = data.beforeValue;
				return; // 필수값처리
			}

			// 상태 변경 불가시 false
			if (this.formData.delivery.deliveryType === 'PS_SALES') {
				if (data.name === 'logisticsTaskStatus') {
					if (
						data.value !== 'DLVR_NONE' &&
						this.$store.state.cookies.st !==
							'3b14c9d61a1ce5782698616844edc9ee' &&
						this.$store.state.cookies.st !== '22f353197e9b0c1cb58a11da8de7776a'
					) {
						alert(
							'배송정보가 택배(영업점 발송)일 경우 \n물류상태는 해당없음만 선택 가능합니다.',
						);
						// @ts-ignore
						return (this.formData.basic[data.name] = data.beforeValue);
					}

					// 물류상태 강제업데이트 필요시 (22.05.30 택배가 영업점 발송인경우 물류상태 -> 반품완료(영업점) 처리를 하기위해 _ 대마Only)
					// 반품요청, 반품완료, 반품완료(재판) 추가 (22.07.11)
					if (
						(data.value === 'STORE_RETURN' ||
							data.value === 'RETURN_DMND' ||
							data.value === 'RETURN_COMPL' ||
							data.value === 'RETURN_COMPL_RESELL') &&
						(this.$store.state.cookies.st ===
							'3b14c9d61a1ce5782698616844edc9ee' ||
							this.$store.state.cookies.st ===
								'22f353197e9b0c1cb58a11da8de7776a')
					) {
						this.formData.basic.forceUpdateLogisticsTaskStatus = 'Y';
					}
				}
			}
			const check = await this.statusFalseFnc(data);
			if (!check) {
				return;
			}

			// 철회요청시 개통일시 정보가 없으면 철회요청불가.
			if (
				data.value === 'RETRACT_DMND_CONSULT' &&
				(!this.formData.basic.openingDate || !this.formData.basic.openingDate)
			) {
				alert('개통일시 정보가 없습니다.');
				// @ts-ignore
				return (this.formData.basic[data.name] = data.beforeValue);
			}

			// 철회요청, 교품접수 및 불량취소 시 모달 EXCHANGE_RCPT_CONSULT  FAULTY_CANCEL	RETRACT_DMND_CONSULT
			if (
				data.value === 'EXCHANGE_RCPT_CONSULT' ||
				data.value === 'FAULTY_CANCEL' ||
				data.value === 'RETRACT_DMND_CONSULT'
			) {
				let workStatusData = data;
				this.workStatusData = workStatusData;
				this.$store.state.ApplicationFormCreationModule.excFauDialog = true;
				return;
			}

			if (data.value === 'OPENING_COMPL') {
				// 개통완료일시 우선순위대상 N 처리
				this.formData.basic.priorityTargetYn = 'N';
				// @ts-ignore
				if (this.formData.basic.openingPriorityTargetYn) {
					// @ts-ignore
					this.formData.basic.openingPriorityTargetYn = 'N';
				}
			}
			// 신청서 업데이트
			await this.updateFalseFnc(data);
		},
		async statusFalseFnc(data: any) {
			let formData;
			if (data.name === 'openingTaskStatus') {
				// 개통

				// 영업점일시 validation
				if (this.storeVal === 'StoreGrade_S') {
					alert('영업점일시 개통상태를 변경할 수 없습니다.');
					// @ts-ignore
					this.formData.basic[data.name] = data.beforeValue;
					return false;
				}

				formData = {
					applId: this.formData.basic.applId,
					consultTaskStatus: this.formData.basic.consultTaskStatus,
					reqConsultTaskStatus: this.formData.basic.consultTaskStatus,
					openingTaskStatus: data.beforeValue,
					reqOpeningTaskStatus: data.value,
					logisticsTaskStatus: this.formData.basic.logisticsTaskStatus,
					reqLogisticsTaskStatus: this.formData.basic.logisticsTaskStatus,
					invoiceNum: this.formData.delivery.invoiceNum,
					task: 'opening',
				};
			} else if (data.name === 'logisticsTaskStatus') {
				// 물류

				if (
					'STORE_RETURN' === data.value &&
					this.$store.state.cookies.st !== '3b14c9d61a1ce5782698616844edc9ee' &&
					this.$store.state.cookies.st !== '22f353197e9b0c1cb58a11da8de7776a'
				) {
					// 반품완료(영업점) 수동 변경 불가
					alert('반품완료(영업점) 상태는 직접 변경할 수 없습니다.');
					// @ts-ignore
					this.formData.basic[data.name] = data.beforeValue;
					return false;
				} else if (
					this.storeVal === 'StoreGrade_S' &&
					data.value !== 'DLVR_DMND'
				) {
					// 영업점일시 validation
					alert(
						'영업점일시 배송상태를 배송요청 이외의 상태로 변경할 수 없습니다.',
					);
					// @ts-ignore
					this.formData.basic[data.name] = data.beforeValue;
					return false;
				}

				formData = {
					applId: this.formData.basic.applId,
					consultTaskStatus: this.formData.basic.consultTaskStatus,
					reqConsultTaskStatus: this.formData.basic.consultTaskStatus,
					openingTaskStatus: this.formData.basic.openingTaskStatus,
					reqOpeningTaskStatus: this.formData.basic.openingTaskStatus,
					logisticsTaskStatus: data.beforeValue,
					reqLogisticsTaskStatus: data.value,
					invoiceNum: this.formData.delivery.invoiceNum,
					task: 'logistics',
				};
			} else if (data.name === 'consultTaskStatus') {
				// 상담
				// if (this.storeVal === 'StoreGrade_S' && data.value === 'RCPT_CANCEL') {
				// 	alert('영업점일시 상담상태를 접수취소 상태로 변경할 수 없습니다.');
				// 	// @ts-ignore
				// 	this.formData.basic[data.name] = data.beforeValue;
				// 	return false;
				// }
				// 등급이 영업점 && 상담 - 개통상태 개통완료일때
				// 철회요청외의 상태로 변경이 불가함
				if (
					this.storeVal === 'StoreGrade_S' &&
					this.formData.basic.openingTaskStatus === 'OPENING_COMPL'
				) {
					if (
						this.formData.basic.consultTaskStatus !== 'RETRACT_DMND_CONSULT' &&
						this.formData.basic.consultTaskStatus !== 'FAULTY_CANCEL' &&
						this.formData.basic.consultTaskStatus !== 'EXCHANGE_RCPT_CONSULT'
					) {
						alert(
							'개통완료 상태에서는 아래의 상태값으로 변경 가능합니다. \n[불량취소, 철회요청, 반품요청, 교품접수]',
						);
						//alert('개통완료 상태에서는 철회요청 외 상태값 설정이 불가합니다.');
						// @ts-ignore
						this.formData.basic[data.name] = data.beforeValue;
						return false;
					}
				}
				if (data.value === 'RCPT_CANCEL') {
					if (
						this.formData.basic.logisticsTaskStatus ===
						'DLVR_ASSIGNMENT_REQUEST'
					) {
						alert('물류 상태가 배정요청일때는 접수취소가 불가능합니다.');
						// @ts-ignore
						this.formData.basic[data.name] = data.beforeValue;
						return false;
					}
					if (this.formData.basic.logisticsTaskStatus === 'DLVR_READY') {
						alert('물류 상태가 배송준비일때는 접수취소가 불가능합니다.');
						// @ts-ignore
						this.formData.basic[data.name] = data.beforeValue;
						return false;
					}
					if (this.formData.delivery.couriorMatchingYn === 'Y') {
						alert('송장매칭취소 후 접수취소 상태로 변경 가능합니다.');
						// @ts-ignore
						this.formData.basic[data.name] = data.beforeValue;
						return false;
					}
					if (this.formData.join.deviceRawBarcode) {
						alert('기기매칭취소 후 접수취소 상태로 변경 가능합니다.');
						// @ts-ignore
						this.formData.basic[data.name] = data.beforeValue;
						return false;
					}
					if (this.formData.join.usimRawBarcode) {
						alert('유심매칭취소 후 접수취소 상태로 변경 가능합니다.');
						// @ts-ignore
						this.formData.basic[data.name] = data.beforeValue;
						return false;
					}
				}
				formData = {
					applId: this.formData.basic.applId,
					consultTaskStatus: data.beforeValue,
					reqConsultTaskStatus: data.value,
					openingTaskStatus: this.formData.basic.openingTaskStatus,
					reqOpeningTaskStatus: this.formData.basic.openingTaskStatus,
					logisticsTaskStatus: this.formData.basic.logisticsTaskStatus,
					reqLogisticsTaskStatus: this.formData.basic.logisticsTaskStatus,
					invoiceNum: this.formData.delivery.invoiceNum,
					task: 'consult',
					faultYn: this.formData.basic.faultYn,
				};
			}
			// api 날리고
			const result = await this.$store.dispatch(
				'checkApplicationStatusUpdateFlag',
				formData,
			);
			if (result === false || result === undefined) {
				// @ts-ignore
				this.formData.basic[data.name] = data.beforeValue;
				return false;
			}
			return true;
		},
		async updateFalseFnc(data: any) {
			// @ts-ignore
			if (await this.updateApplicationSubmit(data)) {
				// 저장 취소시 예전값으로 변경
				// @ts-ignore
				this.formData.basic[data.name] = data.beforeValue;
			}
		},
		openingTaskDateFnc(data: any) {
			if (data === 'OPENING_COMPL' && !this.formData.basic.openingDate) {
				//개통완료 && 개통일자가 없을시
				this.formData.basic.openingDate = getToday();
				this.formData.basic.openingTime = getTime();
			} else if (
				data === 'USIM_OPENING_COMPL_OPENING' &&
				!this.formData.basic.openingDate
			) {
				// 22.05.13 유심개통완료(개통) 상태로 변경시 개통 일자/시간 설정 추가
				//개통완료 && 개통일자가 없을시
				this.formData.basic.openingDate = getToday();
				this.formData.basic.openingTime = getTime();
			} else if (data === 'RETRACT_COMPL' && !this.formData.basic.cancelDate) {
				//철회완료 && 철회일자가 없을시
				this.formData.basic.cancelDate = getToday();
				this.formData.basic.cancelTime = getTime();
			} else if (
				this.formData.basic.openingDate &&
				data !== 'OPENING_COMPL' &&
				data !== 'RETRACT_DMND' &&
				data !== 'RETRACT_COMPL' &&
				data !== 'EXCHANGE_RCPT_OPENING' &&
				data !== 'EXCHANGE_DMND_OPENING' &&
				data !== 'EXCHANGE_COMPL' &&
				!this.ApplExchangeFlag
			) {
				// 개통일자 값이 있는데
				// 개통상태가 개통완료, 철회요청, 철회완료가 아닐시
				// 교품관련 신청서가 아닐시
				// 개통일자 초기화
				this.formData.basic.openingDate = null;
				this.formData.basic.openingTime = null;
			}
			if (
				this.formData.basic.cancelDate &&
				data !== 'RETRACT_COMPL' &&
				!this.ApplExchangeFlag
			) {
				// 철회일자 값이 있는데
				// 개통상태가 철회완료가 아닐시
				// 교품관련 신청서가 아닐시
				// 철회일자 초기화
				this.formData.basic.cancelDate = null;
				this.formData.basic.cancelTime = null;
			}
		},
		cnsltRenderingFnc() {
			// console.log(this.$store.state.CustomerConsultModule.formDataDetail);
			let consultData = {
				...this.$store.state.CustomerConsultModule.formDataDetail,
			};

			this.formData.basic.saleStoreId = consultData.saleStoreId; // 영업점
			this.formData.customer.cusType = consultData.cusType;
			/*formData.customer.cusType === 'INDV' ||
			formData.customer.cusType === 'MINORS' ||
			formData.customer.cusType === 'INDV_BSNM' ||
			formData.customer.cusType === 'CORP*/
			if (consultData.cusType === 'INDV') {
				this.formData.customer.cusName = consultData.consultCustomerDto.cusName;
				this.formData.customer.cusPhone =
					consultData.consultCustomerDto.cusPhone;
				this.formData.customer.cusPhone1 =
					consultData.consultCustomerDto.cusPhone1;
				this.formData.customer.cusPhone2 =
					consultData.consultCustomerDto.cusPhone2;
				this.formData.customer.cusPhone3 =
					consultData.consultCustomerDto.cusPhone3;
				this.formData.customer.cusRegiNumConcat =
					consultData.consultCustomerDto.cusRegiNumConcat;
				this.formData.customer.cusRegiNum1 =
					consultData.consultCustomerDto.cusRegiNum1;
				this.formData.customer.cusRegiNum2 =
					consultData.consultCustomerDto.cusRegiNum2;
			} else if (consultData.cusType === 'MINORS') {
				this.formData.customer.courtProctorName =
					consultData.consultCustomerDto.courtProctorName;
				this.formData.customer.courtProctorPhone =
					consultData.consultCustomerDto.courtProctorPhone;
				this.formData.customer.courtProctorPhone1 =
					consultData.consultCustomerDto.courtProctorPhone1;
				this.formData.customer.courtProctorPhone2 =
					consultData.consultCustomerDto.courtProctorPhone2;
				this.formData.customer.courtProctorPhone3 =
					consultData.consultCustomerDto.courtProctorPhone3;
				this.formData.customer.courtProctorRegiNum1 =
					consultData.consultCustomerDto.courtProctorRegiNum1;
				this.formData.customer.courtProctorRegiNum2 =
					consultData.consultCustomerDto.courtProctorRegiNum2;
				this.formData.customer.courtProctorRegiNumConcat =
					consultData.consultCustomerDto.courtProctorRegiNumConcat;
				this.formData.customer.courtProctorRelation =
					consultData.consultCustomerDto.courtProctorRelation;
				this.formData.customer.cusName = consultData.consultCustomerDto.cusName;
				this.formData.customer.cusPhone =
					consultData.consultCustomerDto.cusPhone;
				this.formData.customer.cusPhone1 =
					consultData.consultCustomerDto.cusPhone1;
				this.formData.customer.cusPhone2 =
					consultData.consultCustomerDto.cusPhone2;
				this.formData.customer.cusPhone3 =
					consultData.consultCustomerDto.cusPhone3;
				this.formData.customer.cusRegiNumConcat =
					consultData.consultCustomerDto.cusRegiNumConcat;
				this.formData.customer.cusRegiNum1 =
					consultData.consultCustomerDto.cusRegiNum1;
				this.formData.customer.cusRegiNum2 =
					consultData.consultCustomerDto.cusRegiNum2;
			} else if (consultData.cusType === 'INDV_BSNM') {
				this.formData.customer.cusName = consultData.consultCustomerDto.bizName;
				this.formData.customer.cusPhone =
					consultData.consultCustomerDto.bizPhone;
				this.formData.customer.cusPhone1 =
					consultData.consultCustomerDto.bizPhone1;
				this.formData.customer.cusPhone2 =
					consultData.consultCustomerDto.bizPhone2;
				this.formData.customer.cusPhone3 =
					consultData.consultCustomerDto.bizPhone3;
				this.formData.customer.cusRegiNumConcat =
					consultData.consultCustomerDto.bizNum;
				this.formData.customer.cusRegiNum1 =
					consultData.consultCustomerDto.bizRegiNum1;
				this.formData.customer.cusRegiNum2 =
					consultData.consultCustomerDto.bizRegiNum2;
			} else if (consultData.cusType === 'CORP') {
				this.formData.customer.cusName = consultData.consultCustomerDto.bizName;
				this.formData.customer.bizPhone =
					consultData.consultCustomerDto.bizPhone;
				this.formData.customer.bizPhone1 =
					consultData.consultCustomerDto.bizPhone1;
				this.formData.customer.bizPhone2 =
					consultData.consultCustomerDto.bizPhone2;
				this.formData.customer.bizPhone3 =
					consultData.consultCustomerDto.bizPhone3;
				this.formData.customer.bizNum = consultData.consultCustomerDto.bizNum;
				this.formData.customer.bizRegiNumConcat =
					consultData.consultCustomerDto.bizRegiNumConcat;
				this.formData.customer.bizRegiNum1 =
					consultData.consultCustomerDto.bizRegiNum1;
				this.formData.customer.bizRegiNum2 =
					consultData.consultCustomerDto.bizRegiNum2;
			} else if (consultData.cusType === 'FOREIGN') {
				this.formData.customer.cusName = consultData.consultCustomerDto.cusName;
				this.formData.customer.cusPhone =
					consultData.consultCustomerDto.cusPhone;
				this.formData.customer.cusPhone1 =
					consultData.consultCustomerDto.cusPhone1;
				this.formData.customer.cusPhone2 =
					consultData.consultCustomerDto.cusPhone2;
				this.formData.customer.cusPhone3 =
					consultData.consultCustomerDto.cusPhone3;
				this.formData.customer.licenseAuthType = 'FOREIGN_RGSTR_CARD';
				this.formData.customer.licenseExpiredDate =
					consultData.consultCustomerDto.licenseExpiredDate;
				this.formData.customer.licenseIssueArea =
					consultData.consultCustomerDto.licenseIssueArea;
				this.formData.customer.licenseIssueDate =
					consultData.consultCustomerDto.licenseIssueDate;
				this.formData.customer.licenseNum1 =
					consultData.consultCustomerDto.licenseNum1;
				this.formData.customer.licenseNum2 =
					consultData.consultCustomerDto.licenseNum2;
				this.formData.customer.licenseNum3 =
					consultData.consultCustomerDto.licenseNum3;
				this.formData.customer.licenseNumConcat =
					consultData.consultCustomerDto.licenseNum1 +
					consultData.consultCustomerDto.licenseNum2;
				this.formData.customer.ntnlCode =
					consultData.consultCustomerDto.ntnlCode;
				this.formData.customer.stayCode =
					consultData.consultCustomerDto.stayCode;
			} else {
				this.formData.customer.cusName = consultData.consultCustomerDto.cusName;
				this.formData.customer.cusPhone =
					consultData.consultCustomerDto.cusPhone;
				this.formData.customer.cusPhone1 =
					consultData.consultCustomerDto.cusPhone1;
				this.formData.customer.cusPhone2 =
					consultData.consultCustomerDto.cusPhone2;
				this.formData.customer.cusPhone3 =
					consultData.consultCustomerDto.cusPhone3;
				this.formData.customer.cusRegiNumConcat =
					consultData.consultCustomerDto.cusRegiNumConcat;
				this.formData.customer.cusRegiNum1 =
					consultData.consultCustomerDto.cusRegiNum1;
			}

			this.formData.customer.cusZipCode =
				consultData.consultCustomerDto.cusZipCode;
			this.formData.customer.cusAddr = consultData.consultCustomerDto.cusAddr;
			this.formData.customer.cusAddrDetail =
				consultData.consultCustomerDto.cusAddrDetail;

			this.formData.join.existTelecomCodeId = consultData.existTelecomCodeId;

			this.formData.join.goodsId = consultData.hopeGoodsId;
			this.formData.join.capacity = consultData.hopeCapacity;
			this.formData.join.color = consultData.hopeColorName;
		},
		crRenderingFnc() {
			if (this.crData) {
				if (this.crData.telecomName === 'SKT') {
					this.formData.join.existTelecomCodeId = 42;
				} else if (this.crData.telecomName === 'KT') {
					this.formData.join.existTelecomCodeId = 43;
				} else if (this.crData.telecomName === 'LGU') {
					this.formData.join.existTelecomCodeId = 44;
				}
				if (this.crData.joinType === 'NUM_CHANGE') {
					this.formData.join.existTelecomCodeId = 1;
					this.thriftyPhone = 41;
					this.crData.joinType = 'NUM_CHANGE_V';
				} else if (this.crData.joinType === 'NEW') {
					this.formData.join.existTelecomCodeId = 45;
				}
				this.formData.basic.creditInquireId = this.crData.creditInquireId;
				this.formData.join.openingTelecomName = this.crData.telecomName;
				this.formData.basic.saleStoreId = this.crData.saleStoreId;
				// this.formData.basic.applRegiUserId = this.crData.regiUserId; // 22.03.04 1차상담(신조요청자)과 신청서작성 등록자가 다를 수 있음으로 로그인사용자 기준 담당자선택처리로 변경
				this.formData.join.joinType = this.crData.joinType;
				this.formData.customer.cusType = this.crData.cusType;
				this.formData.customer.bizNum = this.crData.bizNum;
				this.formData.customer.bizName = this.crData.bizName;
				this.formData.customer.bizPhone = this.crData.bizPhone;
				this.formData.customer.bizPhone1 = this.crData.bizPhone1;
				this.formData.customer.bizPhone2 = this.crData.bizPhone2;
				this.formData.customer.bizPhone3 = this.crData.bizPhone3;
				this.formData.customer.bizRegiNum1 = this.crData.bizRegiNum1;
				this.formData.customer.bizRegiNum2 = this.crData.bizRegiNum2;
				this.formData.customer.bizRegiNumConcat = this.crData.bizRegiNumConcat;
				this.formData.customer.courtProctorName = this.crData.courtProctorName;
				this.formData.customer.courtProctorPhone =
					this.crData.courtProctorPhone;
				this.formData.customer.courtProctorPhone1 =
					this.crData.courtProctorPhone1;
				this.formData.customer.courtProctorPhone2 =
					this.crData.courtProctorPhone2;
				this.formData.customer.courtProctorPhone3 =
					this.crData.courtProctorPhone3;
				this.formData.customer.courtProctorRegiNum1 =
					this.crData.courtProctorRegiNum1;
				this.formData.customer.courtProctorRegiNum2 =
					this.crData.courtProctorRegiNum2;
				this.formData.customer.courtProctorRegiNumConcat =
					this.crData.courtProctorRegiNumConcat;
				this.formData.customer.courtProctorRelation =
					this.crData.courtProctorRelation;
				this.formData.customer.cusName = this.crData.cusName;
				this.formData.customer.cusPhone = this.crData.cusPhone;
				this.formData.customer.cusPhone1 = this.crData.cusPhone1;
				this.formData.customer.cusPhone2 = this.crData.cusPhone2;
				this.formData.customer.cusPhone3 = this.crData.cusPhone3;
				this.formData.customer.cusRegiNumConcat = this.crData.cusRegiNumConcat;
				this.formData.customer.cusRegiNum1 = this.crData.cusRegiNum1;
				this.formData.customer.cusRegiNum2 = this.crData.cusRegiNum2;
				this.formData.join.goodsId = this.crData.goodsId;
				this.formData.join.capacity = this.crData.capacity;
				this.formData.join.color = this.crData.color;
				if (this.crData.cusType === 'FOREIGN') {
					this.formData.customer.licenseAuthType = 'FOREIGN_RGSTR_CARD';
					this.formData.customer.licenseExpiredDate =
						this.crData.licenseExpiredDate;
					this.formData.customer.licenseIssueArea =
						this.crData.licenseIssueArea;
					this.formData.customer.licenseIssueDate =
						this.crData.licenseIssueDate;
					this.formData.customer.licenseNumConcat =
						this.crData.licenseNum1 + this.crData.licenseNum2;
					this.formData.customer.ntnlCode = this.crData.ntnlCode;
					this.formData.customer.stayCode = this.crData.stayCode;
				}
			}
		},
		crListFlagFnc() {
			if (Object.keys(this.formData.basic).includes('creditInquireId')) {
				if (this.AppFormCreFlag) {
					this.crCompleteFlag = true;
				}
			}
		},
		templateSubmitPopOpen() {
			if (
				!nullValidation2(this.formData.basic, [
					'saleStoreId', // 영업점
					'openingStoreId', // 개통점
				])
			) {
				return true;
			}
			this.templateDialog = true;
		},
		async getApplicationTemplateList() {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getApplicationTemplateList',
			);
		},
		templateUpdate: async function () {
			if (
				!nullValidation2(this.formData.basic, [
					'saleStoreId', // 영업점
					'openingStoreId', // 개통점
				])
			) {
				return true;
			}
			if (confirm('[' + this.templateTitle + '] 템플릿을 수정 하시겠습니까?')) {
				if (this.formData.payment.paymentType === 'EXIST_SAME') {
					// @ts-ignore
					this.formData.payment = {};
					// @ts-ignore
					this.formData.payment = { paymentType: 'EXIST_SAME' };
				}
				if (this.AppFormCreFlag == undefined) {
					this.formData.basic.consultTaskStatus = null; // 상담업무상태
					this.formData.basic.openingTaskStatus = null; // 개통업무상태
					this.formData.basic.logisticsTaskStatus = null; // 물류업무상태
				}
				if (this.formData.join.existTelecomCodeId === 1) {
					this.formData.join.existTelecomCodeId = this.thriftyPhone;
				}
				if (this.formData.basic.priorityTargetYn === true) {
					this.formData.basic.priorityTargetYn = 'Y';
				} else {
					this.formData.basic.priorityTargetYn = 'N';
				}

				if (
					this.formData.customer.bizNum1 ||
					this.formData.customer.bizNum2 ||
					this.formData.customer.bizNum3
				) {
					this.formData.customer.bizNum =
						this.formData.customer.bizNum1 +
						this.formData.customer.bizNum2 +
						this.formData.customer.bizNum3;
				} else {
					this.formData.customer.bizNum = null;
				}

				let formData = this.formData;
				let memoData = { memo: this.memoData };
				if (
					(this.memoData.memoContents !== null, this.memoData.category !== '')
				) {
					Object.assign(formData, memoData);
				}

				let paramObj = {
					templateTitle: this.templateTitle,
					templateContents: formData,
				};
				await this.$store.dispatch(
					'ApplicationFormCreationModule/updateApplicationTemplate',
					paramObj,
				);
				await this.getApplicationTemplateList();
				// await this.formDataResetFnc();
			}
		},
		async getMainList() {
			if (this.$route.path === '/sell-status') {
				// 판매현황 리스트 갱신
				let data = this.$store.state.SellStatusModule.filterData;
				await this.$store.dispatch('SellStatusModule/getSellCurrentPage', data);
			}
			if (
				this.$route.path !== '/customer-mgmt/customer-db' &&
				this.$route.path !== '/consult-mgmt/consult-db'
			) {
				return false;
			}
			if (this.moduleName === 'ConsultDbModule') {
				let data = this.filterData;
				let dataTodo = this.todoFilterData;
				await this.$store.dispatch(this.moduleName + '/consultList', data);
				await this.$store.dispatch(
					this.moduleName + '/consultTodoList',
					dataTodo,
				);
				await this.$store.dispatch(
					this.moduleName + '/consultTodoGroupCnt',
					dataTodo,
				);
				await this.$store.dispatch(this.moduleName + '/consultTodoStatusGroup');
			} else {
				await this.$store.dispatch(
					'CustomerDbModule/consultTodoList',
					this.todoFilterData,
				);
				await this.$store.dispatch(
					'CustomerDbModule/consultTodoGroupCnt',
					this.todoFilterData,
				);
				await this.$store.dispatch('CustomerDbModule/consultTodoMemberList');
				await this.$store.dispatch('CustomerDbModule/consultTodoStatusGroup');
				await this.$store.dispatch('CustomerDbModule/consultTodoChargeGroup');
				await this.$store.dispatch(
					'CustomerDbModule/customerList',
					this.filterData,
				);
			}
			await this.customerMemberGroupList();
			await this.customerStatusGroupList();
			await this.customerOpeningGroupList();
			await this.customerOrganizationGroupList();
			await this.customerChargeGroupList();
		},
		async customerMemberGroupList() {
			// 1,2차 상담원 목록
			await this.$store.dispatch('CustomerDbModule/customerMemberGroupList');
		},
		async customerStatusGroupList() {
			await this.$store.dispatch('CustomerDbModule/customerStatusGroupList');
		},
		async customerOpeningGroupList() {
			// 개통점
			await this.$store.dispatch('CustomerDbModule/customerOpeningGroupList');
		},
		async customerOrganizationGroupList() {
			// 조직명
			await this.$store.dispatch(
				'CustomerDbModule/customerOrganizationGroupList',
			);
		},
		async customerChargeGroupList() {
			// 요금제
			await this.$store.dispatch('CustomerDbModule/customerChargeGroupList');
		},
	},
	// mounted() {
	// 	// @ts-ignore
	// 	document.getElementById('mainBox').scrollTo(0, 0);
	// },
	async created() {
		if (!this.AppFormCreFlag) {
			await this.formDataResetFnc();
		}
		if (this.crListFlag) {
			await this.crRenderingFnc();
		}
		if (this.cnsltId) {
			await this.cnsltRenderingFnc();
		}
		await this.resetValidation();
		await this.totalEnumFnc();

		if (!this.AppFormCreFlag && !this.crListFlag) {
			await this.getApplicationTemplateList();
		}
		bus.$on('reloadAppl:appl', this.applReload);
		bus.$on('applReset', this.formDataResetFnc);
	},
	beforeDestroy() {
		if (!this.$store.state.ApplicationFormCreationModule.receiveChk) {
			this.$store.dispatch('ApplicationFormCreationModule/formDataInit');
		}
		bus.$off('reloadAppl:appl', this.applReload);
		bus.$off('applReset', this.formDataResetFnc);
	},
});
