import { render, staticRenderFns } from "./memoInput.vue?vue&type=template&id=afc55d5e&scoped=true&"
import script from "./memoInput.vue?vue&type=script&lang=js&"
export * from "./memoInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afc55d5e",
  null
  
)

export default component.exports