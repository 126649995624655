
import Vue from 'vue';
import PostApi from '../../../components/PostApi.vue';
import {
	basicFormData,
	customerFormData,
	deliveryFormData,
} from '@/store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import numberSplit from '../../../common/numberSplit';
import NumRepMixin from '../../../common/NumRepMixin.js';
import focusMixin from '../../../common/focusMixin.js';

interface dataType {
	postDialog: boolean;
}

interface CourierItem {
  codeNm: string;
  codeSeq: number;
  // Add other properties if they exist
}

export default Vue.extend({
	name: 'DeliveryInformation',
	components: { PostApi },
	mixins: [numberSplit, NumRepMixin, focusMixin],
	watch: {
		courierCodeItems(newItems) {
		if (newItems.length >= 3) {
			const rosenItem = newItems.find((item:CourierItem) => item.codeNm === '로젠택배');
			this.deliveryData.courierCodeId = rosenItem
			? rosenItem.codeSeq
			: newItems[2].codeSeq;
		}
		},
	},
	data: (): dataType => ({
		postDialog: false,
	}),
	computed: {
		sct(): string {
			return this.$store.state.cookies.sct;
		},
		deliveryData: {
			get(): deliveryFormData {
				return this.$store.state.ApplicationFormCreationModule.formData
					.delivery;
			},
			set(newValue: deliveryFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.delivery =
					newValue;
			},
		},
		deliveryTypeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.DeliveryType;
		},
		customerData: {
			get(): customerFormData {
				return this.$store.state.ApplicationFormCreationModule.formData
					.customer;
			},
			set(newValue: customerFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.customer =
					newValue;
			},
		},
		addrDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.dialogData
					.deliveryAddrDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.dialogData.deliveryAddrDialog =
					newValue;
			},
		},
		courierCodeItems(): any {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.courierCodeItems;
		},
		basicData: {
			get(): basicFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.basic;
			},
			set(newValue: basicFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.basic =
					newValue;
			},
		},
		AppFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFlag = newValue;
			},
		},
	},
	methods: {
		alertFnc(event: any) {
			if (
				this.deliveryData.couriorMatchingYn === 'Y' &&
				this.deliveryData.deliveryType === 'PS'
			) {
				alert('송장매칭 취소 후 배송구분 변경이 가능합니다.');
				event.preventDefault();
			}
		},
		cusInfoFnc() {
			if (this.deliveryData.deliveryType === 'PS') {
				if (
					this.deliveryData.couriorMatchingYn === 'Y' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PEND' ||
					this.basicData.logisticsTaskStatus === 'DLVR_READY' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PREV_PROG' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PROG' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PREV_CANCEL' ||
					this.basicData.logisticsTaskStatus === 'DLVR_COMPL' ||
					this.basicData.logisticsTaskStatus === 'RETURN_DMND' ||
					this.basicData.logisticsTaskStatus === 'RETURN_COMPL' ||
					this.basicData.logisticsTaskStatus === 'RETURN_COMPL_RESELL'
				) {
					return;
				}
				if (
					!this.customerData.cusName ||
					!this.customerData.cusPhone ||
					!this.customerData.cusPhone1 ||
					!this.customerData.cusPhone2 ||
					!this.customerData.cusPhone3
				) {
					return alert('고객정보를 입력해주세요.');
				}
				if (
					!this.customerData.cusZipCode ||
					!this.customerData.cusAddr ||
					!this.customerData.cusAddrDetail
				) {
					return alert('고객주소를 입력해주세요.');
				}
				this.deliveryData.deliveryCusName = this.customerData.cusName;
				this.deliveryData.deliveryCusPhone = this.customerData.cusPhone;
				this.deliveryData.deliveryCusPhone1 = this.customerData.cusPhone1;
				this.deliveryData.deliveryCusPhone2 = this.customerData.cusPhone2;
				this.deliveryData.deliveryCusPhone3 = this.customerData.cusPhone3;
				this.deliveryData.deliveryZipCode = this.customerData.cusZipCode;
				this.deliveryData.deliveryAddr = this.customerData.cusAddr;
				this.deliveryData.deliveryAddrDetail = this.customerData.cusAddrDetail;
			} else {
				alert('배송 구분이 택배일 때 버튼 사용 가능합니다.');
			}
		},
		addressFnc() {
			if (this.deliveryData.deliveryType === 'PS') {
				if (
					this.deliveryData.couriorMatchingYn === 'Y' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PEND' ||
					this.basicData.logisticsTaskStatus === 'DLVR_READY' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PREV_PROG' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PROG' ||
					this.basicData.logisticsTaskStatus === 'DLVR_PREV_CANCEL' ||
					this.basicData.logisticsTaskStatus === 'DLVR_COMPL' ||
					this.basicData.logisticsTaskStatus === 'RETURN_DMND' ||
					this.basicData.logisticsTaskStatus === 'RETURN_COMPL' ||
					this.basicData.logisticsTaskStatus === 'RETURN_COMPL_RESELL'
				) {
					return;
				}
				this.deliveryData.deliveryCusName = null;
				this.deliveryData.deliveryCusPhone = null;
				this.deliveryData.deliveryCusPhone1 = null;
				this.deliveryData.deliveryCusPhone2 = null;
				this.deliveryData.deliveryCusPhone3 = null;
				if (
					!this.customerData.cusZipCode ||
					!this.customerData.cusAddr ||
					!this.customerData.cusAddrDetail
				) {
					return alert('고객주소를 입력해주세요.');
				}
				this.deliveryData.deliveryZipCode = this.customerData.cusZipCode;
				this.deliveryData.deliveryAddr = this.customerData.cusAddr;
				this.deliveryData.deliveryAddrDetail = this.customerData.cusAddrDetail;
			} else {
				alert('배송 구분이 택배일 때 버튼 사용 가능합니다.');
			}
		},
		addressData(data: any) {
			this.deliveryData.deliveryAddr = data.address;
			this.deliveryData.deliveryZipCode = data.zonecode;
		},
		dialogClose(data: boolean) {
			this.postDialog = data;
		},
		resetDeliveryTypeFnc() {
			this.deliveryData.deliveryCusName = null;
			this.deliveryData.deliveryCusPhone = null;
			this.deliveryData.deliveryCusPhone1 = null;
			this.deliveryData.deliveryCusPhone2 = null;
			this.deliveryData.deliveryCusPhone3 = null;
			this.deliveryData.courierCodeId = null; // 택배사
			this.deliveryData.invoiceNum = null; // 송장번호
			this.deliveryData.deliveryZipCode = null; // 우편번호
			this.deliveryData.deliveryAddr = null; // 기본주소
			this.deliveryData.deliveryAddrDetail = null; // 상세주소
			this.addrDialog = false; // 고객 주소와 동일
		},
		setDefaultCourier() {
		if (this.courierCodeItems.length >= 3) {
			const rosenItem = this.courierCodeItems.find((item:CourierItem) => item.codeNm === '로젠택배');
			this.deliveryData.courierCodeId = rosenItem
			? rosenItem.codeSeq
			: this.courierCodeItems[2].codeSeq;
		}
		},
	},
});
