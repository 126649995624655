<template>
	<div>
		<div class="w100P contentBoxGray consultmemo">
			<div class="disFX justify-space-between">
				<h2 class="disIn">상담메모</h2>
			</div>
			<div class="mt-1">
				<div class="new-btnA1">
					<textarea
						class="mt-1 pd4 narrowScroll borderContColor1 consult_new_memo backColorWhite"
						placeholder="메모 내용 입력"
						maxlength="255"
						v-model.trim="formData.memoContents"
					/>
					<button class="outlineNone font-size-12" @click="addNewMemo">
						확인
					</button>
				</div>
			</div>
			<div
				class="memoArea narrowScroll mt-1"
				:style="`max-height:${maxHeight}px; overflow-y: auto;`"
			>
				<ul class="font-size-12">
					<li v-if="memoList.length === 0">
						<div
							style="padding: 10px; text-align: center; color: gray"
							class="borderRadi3Px borderContColor1 backColorWhite"
						>
							데이터가 없습니다.
						</div>
					</li>
					<li
						v-for="(item, index) in memoList"
						class="pd3 mb-1 borderContColor1 borderRadi3Px backColorWhite"
						:key="index"
					>
						<div class="borderBottomColor1 pb-0 lh26">
							<div class="disIN w30P">
								<span
									style="transform: translate(-3px, -2px)"
									class="disIN memoTag pdW10"
									:class="tabColor[item.consultMemoType]"
									>{{ item.consultMemoTypeMsg }}</span
								>
							</div>
							<div class="disIN w70P textRight">
								<span class="font-gray">
									{{ item.username }} | <b>{{ item.storeName }}</b>
								</span>
							</div>
						</div>
						<div class="mb5">
							<textarea
								class="disBl pd3 w100P borderContColor1 consult_memo narrowScroll"
								style="
									border-top: 0 !important;
									min-height: 50px;
									overflow-y: hidden;
								"
								:style="item.modifyYn === 'N' ? 'border:0!important' : ''"
								:disabled="item.modifyYn === 'N'"
								v-model.trim="item.memoContents"
								@input="resizeTextArea($event)"
							></textarea>
						</div>
						<div class="disFX justify-space-between">
							<button
								v-if="item.modifyYn === 'Y'"
								class="mb5 btnShadow1 backColorBlue3 mainWhite borderRadi3Px"
								style="padding: 1px 8px"
								@click="editMemo(item, $event)"
							>
								수정
							</button>
							<div>
								<span
									class="font-btn-color"
									v-text="dateTime(item.regiDatetime)"
								>
								</span>
								<span class="ml-3 font-gray">
									{{ item.regiDatetime | moment('YYYY-MM-DD HH:mm:ss') }}
									{{ item.modifyMode }}
								</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { toHtml, compareTime } from '../../common/common';
import bus from '../../common/bus';

export default {
	name: 'ConsultMemo',
	props: {
		maxHeight: {
			type: Number,
			default: 310,
		},
		cnsltId: { type: Number, required: true },
		consultMemoType: { type: String, required: true },
		memoList: { type: Array, required: true },
		loadFromPopup: { type: Boolean, default: false },
		loadFromAppl: { type: Boolean, default: false },
		taskHistoryPop: { type: Boolean },
		moduleName: { type: String, required: true },
	},
	data: () => ({
		formData: {
			memoContents: '',
		},
		tabColor: {
			'': 'backColorBlue3',
			CUSTOMER: 'backColorMint1',
			CONSULT: 'backColorOrange',
		},
		memoTabList: [
			{ name: '전체', value: '', listCnt: 2 },
			{ name: '고객', value: 'CUSTOMER', listCnt: 4 },
			{ name: '상담', value: 'CONSULT', listCnt: 5 },
		],
		// memoTabValue: '',
		preMemoContent: '',
		// category: 'CUSTOMER',
	}),
	computed: {
		module: {
			get() {
				return this.$store.state[this.moduleName];
			},
			set(newValue) {
				this.$store.state[this.moduleName] = newValue;
			},
		},
	},
	methods: {
		resizeTextArea(e) {
			let area = e.target;
			area.style.height = '1px';
			area.style.height = 1 + area.scrollHeight + 'px';
			// area.scrollHeight > 140
			// 	? (area.style.overflowY = 'auto')
			// 	: (area.style.overflowY = 'hidden');
		},
		setTextAreaScript() {
			let memoArr = document.getElementsByClassName('consult_memo');
			let clientH;
			for (let i = 0; i < memoArr.length; i++) {
				clientH =
					document.getElementsByClassName('consult_memo')[i].scrollHeight;
				if (clientH < 50) {
					clientH = 50;
					continue;
				}
				document.getElementsByClassName('consult_memo')[i].style.height =
					String(clientH) + 'px';
			}
		},
		toHtml(text) {
			return toHtml(text);
		},
		dateTime(Time) {
			return compareTime(Time);
		},
		async getMemoList() {
			let param = { cnsltId: this.cnsltId };
			await this.$emit('getConsultMemoList', param);
		},
		async tabFnc(tab) {
			// this.memoTabValue = tab;
			await this.getMemoList();
		},
		async addNewMemo(e) {
			if (this.formData.memoContents.length === 0) {
				alert('메모 내용을 입력해주세요.');
				e.preventDefault();
				return;
			}
			if (!confirm('등록하시겠습니까?')) {
				e.preventDefault();
				return;
			}
			let data = {
				cnsltId: this.cnsltId,
				// consultMemoType: this.consultMemoType,
				memoContents: this.formData.memoContents,
			};
			let result = await this.$store.dispatch(
				'SalesMgmtModule/insertConsultMemo',
				data,
			);
			if (result) {
				this.formData.memoContents = '';
				// this.memoTabValue = '';
				await this.getMemoList({ cnsltId: this.cnsltId });
			}
			if (this.loadFromPopup) {
				this.$emit('getList', this.module.filterData);
			}
		},
		async editMemo(item, e) {
			if (item.memoContents.length === 0) {
				alert('메모 내용을 입력해주세요.');
				e.preventDefault();
				return;
			}
			if (!confirm('수정하시겠습니까?')) {
				e.preventDefault();
				return;
			}
			let data = {
				cnsltId: this.cnsltId,
				cnsltMemoId: item.cnsltMemoId,
				// consultMemoType: item.consultMemoType,
				memoContents: item.memoContents,
			};
			let result = await this.$store.dispatch(
				'SalesMgmtModule/updateConsultMemo',
				data,
			);
			if (result) {
				await this.getMemoList({ cnsltId: this.cnsltId });
			}
			if (this.loadFromPopup) {
				this.$emit('getList', this.module.filterData);
			}
		},
		insertMemoData(data) {
			if (this.formData.memoContents.length > 0) {
				this.formData.memoContents = `${this.formData.memoContents}\n\n${data}`;
			} else {
				this.formData.memoContents = data;
			}
		},
	},
	created() {
		bus.$on('syncMemo', this.insertMemoData);
	},
	beforeDestroy() {
		bus.$off('syncMemo', this.insertMemoData);
	},
};
</script>

<style scoped>
input[type='radio'] + label.memoTab {
	display: inline;
	font-size: 12px;
	font-weight: 600;
	text-decoration: underline;
	cursor: pointer;
	color: darkgray;
}
input[type='radio']:checked + label.memoTab.active_blue {
	color: #007ef2;
}
input[type='radio']:checked + label.memoTab.active_green {
	color: #00aac1;
}
input[type='radio']:checked + label.memoTab.active_orange {
	color: #ff7a00;
}

.contentBoxGray button:focus {
	outline: none;
}
.new-btnA1 textarea {
	/*resize: none;*/
	/*font-size: 12px;*/
	height: 66px;
	width: 248px;
	border-radius: 5px 0 0 5px;
}
.consult_new_memo {
	line-height: 18px;
	resize: none;
	font-size: 12px;
	outline: none;
}
.consult_memo {
	line-height: 18px;
	resize: none;
	font-size: 12px;
	outline: none;
}
</style>
