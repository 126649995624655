import Vue from 'vue';
import Vuex from 'vuex';
import {
	consultStatusList,
	insertConsultStatus,
} from '@/api/sales-mgmt/SalesMgmtApi';

Vue.use(Vuex);

const ConsultSettingModule = {
	namespaced: true,
	state: {
		totalCnt: 0,
		noticeListDialog: false,
		settingDialog: false,
		filterData: {
			perPageCnt: 20,
			pageNo: 1,
		},
		noticeList: [],
		c1_CONSULT_PROSPECT: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_PROSPECT',
				consultantType: 'CONSULT_FIRST',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c1_CONSULT_PROGRESS: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_PROGRESS',
				consultantType: 'CONSULT_FIRST',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c1_CONSULT_IMPOSSIBLE: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_IMPOSSIBLE',
				consultantType: 'CONSULT_FIRST',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c2_CONSULT_PROSPECT: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_PROSPECT',
				consultantType: 'CONSULT_SECOND',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c2_CONSULT_PROGRESS: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_PROGRESS',
				consultantType: 'CONSULT_SECOND',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c2_CONSULT_COMPLETE: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_COMPLETE',
				consultantType: 'CONSULT_SECOND',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c2_CONSULT_CANCEL: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_CANCEL',
				consultantType: 'CONSULT_SECOND',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		c2_CONSULT_IMPOSSIBLE: [
			{
				cnsltStatusId: 0,
				consultProcessType: 'CONSULT_IMPOSSIBLE',
				consultantType: 'CONSULT_SECOND',
				orderNum: 1,
				saleStoreId: 0,
				statusColor: '#ffffff',
				statusFontColor: '#000000',
				statusName: '',
				useYn: 'Y',
				updateYn: 'Y',
			},
		],
		consultStatusList: [],
	},
	mutations: {
		initConsultStatusList(state) {
			state.consultStatusList = [];
		},
		setConsultStatusList(state, result) {
			function splitStatusList(list, arr) {
				for (let i in list.consultStatusDtoList) {
					arr.push({
						...list.consultStatusDtoList[i],
						orderNum: i,
					});
				}
			}
			splitStatusList(
				result.data.data.consultantDtoList[0].processTypeDtoList[0],
				state.c1_CONSULT_PROSPECT,
			);
			splitStatusList(
				result.data.data.consultantDtoList[0].processTypeDtoList[1],
				state.c1_CONSULT_PROGRESS,
			);
			splitStatusList(
				result.data.data.consultantDtoList[0].processTypeDtoList[2],
				state.c1_CONSULT_IMPOSSIBLE,
			);
			splitStatusList(
				result.data.data.consultantDtoList[1].processTypeDtoList[0],
				state.c2_CONSULT_PROSPECT,
			);
			splitStatusList(
				result.data.data.consultantDtoList[1].processTypeDtoList[1],
				state.c2_CONSULT_PROGRESS,
			);
			splitStatusList(
				result.data.data.consultantDtoList[1].processTypeDtoList[2],
				state.c2_CONSULT_COMPLETE,
			);
			splitStatusList(
				result.data.data.consultantDtoList[1].processTypeDtoList[3],
				state.c2_CONSULT_CANCEL,
			);
			splitStatusList(
				result.data.data.consultantDtoList[1].processTypeDtoList[4],
				state.c2_CONSULT_IMPOSSIBLE,
			);
		},
	},
	actions: {
		async getConsultStatusList({ commit }, data) {
			try {
				const result = await consultStatusList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setConsultStatusList', result);
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultStatus({ commit }, data) {
			try {
				const result = await insertConsultStatus(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default ConsultSettingModule;
