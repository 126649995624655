<template>
	<span ref="websocket"></span>
</template>
<script>
import Stomp from 'webstomp-client';
import SockJS from 'sockjs-client';
import bus from '../../common/bus.js';

let subId = '';
let stomp = null;
let debugType = false;
let serverURL = ``;
let reLoadCnt = 0;

export default {
	name: 'WebSocket',
	data() {
		return {
			url: '',
		};
	},
	computed: {
		ss() {
			return this.$store.state.cookies.ss;
		},
		ms() {
			return this.$store.state.cookies.ms;
		},
		mr() {
			return this.$store.state.cookies.mr;
		},
		tabDialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.tabDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.tabDialog = newValue;
			},
		},
		AppFlag: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.AppFlag;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.AppFlag = newValue;
			},
		},
	},
	methods: {
		send(desti) {
			if (this.stompClient && this.stompClient.connected) {
				this.stompClient.send(desti + this.$route.query.applId, '', {
					subId: subId,
					action: 'refresh',
				});
			}
		},
		getApplSubListSend(desti, headers = {}) {
			if (this.stompClient && this.stompClient.connected) {
				this.stompClient.send(desti, '', { action: 'subscribers', ...headers });
			}
		},
		async connect() {
			let socket = new SockJS(serverURL);
			this.stompClient = Stomp.over(socket);
			if (!debugType) {
				this.stompClient.debug = () => {};
			}
			//console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`);
			const headers = {
				Authorization: `Bearer ${this.$store.state.auth.accessToken}`,
			};
			await this.stompClient.connect(
				headers,
				(frame) => {
					// 소켓 연결 성공
					this.connected = true;
					reLoadCnt = 0;
					//console.log('소켓 연결 성공', frame);
					// 서버의 메시지 전송 endpoint를 구독합니다.
					// 이런형태를 pub sub 구조라고 합니다.
					this.stompClient.subscribe(
						'/subscribe/notification/all',
						(res) => {
							let resBody = JSON.parse(res.body);
							// 마스킹처리 새로고침
							if (resBody.message === 'refresh') {
								alert(
									'관리자가 데이터 마스킹처리하였습니다.\n페이지가 새로고침 됩니다.',
								);
								location.reload();
							} else if (resBody.message === 'fe_version') {
								// console.log('newVersion = ' + resBody.etc)
								window.localStorage.setItem('newVersion', resBody.etc);
							}
						},
						headers,
					);
					this.stompClient.subscribe(
						`/subscribe/notification/store/${this.ss}`,
						(res) => {
							let resBody = JSON.parse(res.body);
							// console.log(resBody);
							if (resBody.message === 'refresh') {
								// 마스킹처리 새로고침
								if (resBody.etc === 'masking') {
									alert(
										'관리자가 데이터 마스킹처리하였습니다.\n페이지가 새로고침 됩니다.',
									);
								}
								location.reload();
							} else if (resBody.message === 'noti') {
								// 알림관련 메세지
								if (
									resBody.notiType === 'COMPLAIN' &&
									this.mr.includes('/COMPLAIN/') &&
									this.mpCheckFnc(resBody)
								) {
									bus.$emit('push:msg', resBody); // 알림 Push + 알림 Cnt
								} else if (
									resBody.notiType === 'CREDIT' &&
									this.mr.includes('/CREDIT/') &&
									this.mpCheckFnc(resBody)
								) {
									if (
										resBody.etc7 === this.ss &&
										resBody.notiSubType === 'CREDIT_REQ'
									) {
										// no send push
									} else {
										bus.$emit('pushCredit:msg', resBody); // 신조 알림
									}
								} else if (resBody.notiType === 'APPLICATION') {
									bus.$emit('pushApplDeadLine:msg', resBody); // 신청서만료 알림
								} else if (
									resBody.notiType === 'CONSULT' &&
									resBody.etc === this.ms.toString()
								) {
									bus.$emit('pushConsultToDo:msg', resBody); // 상담 To-Do알림
								} else {
									bus.$emit('push:msg', null); // 알림 Cnt Only
								}
							} else if (resBody.message === 'noti_resend') {
								let replaceData = JSON.parse(resBody.contents);
								if (replaceData[Number(this.ms)] !== undefined) {
									resBody.subTitle = resBody.subTitle.replace(
										'[replaceData]',
										replaceData[Number(this.ms)],
									);
									bus.$emit('push:msg', resBody); // 알림 Push + 알림 Cnt
								}
							}
						},
						headers,
					);
					this.sendMessageDeadLineApplication();
				},
				(error) => {
					// 소켓 연결 실패
					console.log('소켓 연결 실패', error);
					this.connected = false;

					if (process.env.NODE_ENV === 'production') {
						this.reConnect();
					}
				},
			);
		},
		async connectAppl(dataObj) {
			if (!this.stompClient) {
				let socket = new SockJS(serverURL);
				this.stompClient = Stomp.over(socket);
			}

			const headers = {
				Authorization: `Bearer ${this.$store.state.auth.accessToken}`,
			};

			if (!debugType) {
				this.stompClient.debug = () => {};
			}

			if (!this.stompClient.connected) {
				await this.stompClient.connect(
					headers,
					(frame) => {
						// 소켓 연결 성공
						this.connected = true;
						//console.log('소켓 연결 성공', frame);
						// 서버의 메시지 전송 endpoint를 구독합니다.
						// 이런형태를 pub sub 구조라고 합니다.
						stomp = this.stompClient.subscribe(
							dataObj.desti + dataObj.applId,
							(res) => {
								// console.log(res)
								// console.log(resBody)
								if (res.headers['content-length'] === '0') {
									return;
								}
								let resBody = JSON.parse(res.body);
								// 신청서내용 변경 새로고침
								if (resBody.message === 'refresh') {
									if (
										resBody.subscribeLink ===
											this.$store.state.applTabType +
												this.$route.query.applId &&
										this.$store.state.cookies.ms !== resBody.id
									) {
										if (subId != resBody.id) {
											// alert('신청서 내용이 수정되었습니다.\n페이지가 새로고침 됩니다.');
											bus.$emit('reload:socket');
											if (
												'/subscribe/application/main/' ===
												this.$store.state.applTabType
											) {
												bus.$emit('reloadAppl:appl');
											} else if (
												'/subscribe/application/recip/' ===
												this.$store.state.applTabType
											) {
												bus.$emit('reloadReceive:appl');
											} else if (
												'/subscribe/application/right/' ===
												this.$store.state.applTabType
											) {
												bus.$emit('reloadRight:appl');
											} else {
												location.reload();
											}
										}
									}
								} else if (resBody.message === 'subscribers') {
									// 접속자 목록 소켓통신
									// console.log('접속자 목록 소켓통신 현재값 = ' + this.$store.state.applTabType + this.$route.query.applId)
									// console.log('접속자 목록 소켓통신 받은값 = ' + resBody.subscribeLink)
									if (
										resBody.subscribeLink ===
										this.$store.state.applTabType + this.$route.query.applId
									) {
										this.$store.state.applSocketList = resBody.subscribers;
									}
								}
								subId = res.headers.subscription;
							},
							headers,
						);

						// 소켓 목록 조회
						this.getApplSubListSend(dataObj.desti + dataObj.applId, headers);
					},
					(error) => {
						// 소켓 연결 실패
						console.log('소켓 연결 실패', error);
						this.connected = false;

						if (process.env.NODE_ENV === 'production') {
							this.reConnect(), 5000;
						}
					},
				);
			} else {
				stomp = this.stompClient.subscribe(
					dataObj.desti + dataObj.applId,
					(res) => {
						// console.log(res)
						// console.log(resBody)
						if (res.headers['content-length'] === '0') {
							return;
						}
						let resBody = JSON.parse(res.body);
						// 신청서내용 변경 새로고침
						if (resBody.message === 'refresh') {
							if (
								resBody.subscribeLink ===
									this.$store.state.applTabType + this.$route.query.applId &&
								this.$store.state.cookies.ms !== resBody.id
							) {
								if (subId != resBody.id) {
									// alert('신청서 내용이 수정되었습니다.\n페이지가 새로고침 됩니다.');
									bus.$emit('reload:socket');
									if (
										'/subscribe/application/main/' ===
										this.$store.state.applTabType
									) {
										bus.$emit('reloadAppl:appl');
									} else if (
										'/subscribe/application/recip/' ===
										this.$store.state.applTabType
									) {
										bus.$emit('reloadReceive:appl');
									} else if (
										'/subscribe/application/right/' ===
										this.$store.state.applTabType
									) {
										bus.$emit('reloadRight:appl');
									} else {
										location.reload();
									}
								}
							}
						} else if (resBody.message === 'subscribers') {
							// 접속자 목록 소켓통신
							// console.log('접속자 목록 소켓통신 현재값 = ' + this.$store.state.applTabType + this.$route.query.applId)
							// console.log('접속자 목록 소켓통신 받은값 = ' + resBody.subscribeLink)
							if (
								resBody.subscribeLink ===
								this.$store.state.applTabType + this.$route.query.applId
							) {
								this.$store.state.applSocketList = resBody.subscribers;
							}
						}
						subId = res.headers.subscription;
					},
					headers,
				);

				// 소켓 목록 조회
				this.getApplSubListSend(dataObj.desti + dataObj.applId, headers);
			}
		},
		async unSubAppl(dataObj) {
			if (!this.stompClient.connected) {
				return;
			}
			// console.log('구독 취소 처리 === ' + dataObj.desti + dataObj.applId);
			await stomp.unsubscribe({
				simpDestination: dataObj.desti + dataObj.applId,
			});
			// 소켓 목록 조회
			// console.log('구독 취소후 현재접속자들에게 목록조회 === ' + dataObj.desti + dataObj.applId);
			await this.getApplSubListSend(dataObj.desti + dataObj.applId);
		},
		async unSubNoti() {
			if (!this.stompClient.connected) {
				return;
			}
			for (let item in this.stompClient.subscriptions) {
				this.stompClient.unsubscribe(item, { simpDestination: '' });
			}
		},
		connectCheck() {
			console.log(this.stompClient);
			console.log(this.stompClient.subscriptions);
			for (let item in this.stompClient.subscriptions) {
				console.log(item);
			}
		},
		reConnect() {
			if (reLoadCnt > 4) {
				window.location.replace(window.location.href);
			}
			setTimeout(() => {
				// noti관련 소켓 재연결
				this.connect();

				// 신청서 관련 소켓 재연결
				let applPathList = [
					'/application-form-creation', // 신청서 상세
					'/sell-status', // 신청서 목록(빠른개통)
					'/application-matching', //
				];
				if (
					applPathList.indexOf(this.$route.path) >= 0 &&
					Number(this.$route.query.applId)
				) {
					// 신청서 관련 페이지에서만 신청서 재구독
					/*console.log(Number(this.$route.query.applId))
						console.log(this.tabDialog.applTab)
						console.log(this.tabDialog.executionTab)
						console.log(this.tabDialog.receiveTab)*/
					let data = {
						desti: null,
						applId: Number(this.$route.query.applId),
						wsType: null,
					};
					if (this.tabDialog.applTab === true) {
						data.desti = '/subscribe/application/main/';
						data.wsType = 'APPLICATION_MAIN';
					} else if (this.tabDialog.receiveTab === true) {
						data.desti = '/subscribe/application/recip/';
						data.wsType = 'APPLICATION_RECIP';
					} else if (this.tabDialog.executionTab === true) {
						data.desti = '/subscribe/application/right/';
						data.wsType = 'APPLICATION_RIGHT';
					}
					// console.log(data)
					this.connectAppl(data);
				}
			}, 5000);
		},
		mpCheckFnc(resBody) {
			// localStorage - mp 값이 있을경우(알림설정 수정 후)
			if (localStorage.getItem('mp') !== null) {
				if (
					localStorage.getItem('mp').includes('/Y/') ||
					(!localStorage.getItem('mp').includes(resBody.notiType + ':Y') &&
						!localStorage.getItem('mp').includes(resBody.notiType + ':N')) ||
					(localStorage.getItem('mp').includes(resBody.notiType + ':Y') &&
						localStorage.getItem('mp').includes(resBody.notiSubType))
				) {
					// console.log('check to loaclStorage that mp');
					return true;
				} else {
					return false;
				}
			} else {
				// 방어코드 mp 재조회 방식 예정
				/*
				// localStorage - mp 값이 없을 경우 쿠키(store.state.cookies)값 참조
				if (this.mp.includes('/Y/')
						|| (!this.mp.includes(resBody.notiType+':Y') && !this.mp.includes(resBody.notiType+':N')
								|| (this.mp.includes(resBody.notiType+':Y') && this.mp.includes(resBody.notiSubType))
						)
				) {
					// console.log('check to store cookie that mp');
					return true
				} else {
					return false
				}
				*/
			}
		},
		async sendMessageDeadLineApplication() {
			const applDeadLineCallDate =
				window.localStorage.getItem('deadLineCallDate');
			const toDate = this.$moment(new Date()).format('YYYY-MM-DD');
			if (applDeadLineCallDate !== toDate) {
				this.$store.dispatch(
					'ApplicationFormCreationModule/sendMessageDeadLineApplication',
				);
				window.localStorage.setItem('deadLineCallDate', toDate);
			}
		},
	},
	async created() {
		// App.vue가 생성되면 소켓 연결을 시도합니다.
		this.url = process.env.VUE_APP_SERVER_AUTH;
		if (process.env.NODE_ENV === 'local') {
			this.url = process.env.VUE_APP_SERVER_SOCKET;
		}
		serverURL = this.url + 'new-skl-mno/ws';
		await this.connect();

		bus.$on('appl:socket', this.connectAppl);
		bus.$on('unsubAppl:socket', this.unSubAppl);
		bus.$on('unsubNoti:socket', this.unSubNoti);
		bus.$on('check:socket', this.connectCheck);
		bus.$on('send:socket', this.send);
	},
	beforeDestroy() {
		bus.$off('appl:socket', this.connectAppl);
		bus.$off('unsubAppl:socket', this.unSubAppl);
		bus.$off('unsubNoti:socket', this.unSubNoti);
		bus.$off('check:socket', this.connectCheck);
		bus.$off('send:socket', this.send);
	},
};
</script>
