<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="1300">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">약관 안내</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>
					<v-card-text class="pad30">
						<div class="disFx posR" style="border-bottom: 2px solid #007ef2">
							<h2
								class="cur_p font-size-14"
								:class="{
									titleBtnStyleA: tabDialog !== 1,
									titleBtnStyleB: tabDialog === 1,
								}"
								@click="tabDialog = 1"
							>
								이용약관
							</h2>
							<span
								v-if="tabDialog === 1"
								class="disIn backColorWhite"
								style="width: 84px; height: 2px; position: absolute; top: 42px"
							></span>
							<h2
								class="cur_p ml5 font-size-14"
								@click="tabDialog = 2"
								:class="{
									titleBtnStyleA: tabDialog !== 2,
									titleBtnStyleB: tabDialog === 2,
								}"
							>
								개인정보 처리방침
							</h2>
							<span
								v-if="tabDialog === 2"
								class="disIn backColorWhite"
								style="
									width: 136px;
									height: 2px;
									position: absolute;
									top: 42px;
									left: 93px;
								"
							></span>
							<h2
								class="cur_p ml5 font-size-14"
								@click="tabDialog = 3"
								:class="{
									titleBtnStyleA: tabDialog !== 3,
									titleBtnStyleB: tabDialog === 3,
								}"
							>
								전자금융거래 이용약관
							</h2>
							<span
								v-if="tabDialog === 3"
								class="disIn backColorWhite"
								style="
									width: 156px;
									height: 2px;
									position: absolute;
									top: 42px;
									left: 238px;
								"
							></span>
						</div>

						<div class="termsContent">
							<template v-if="tabDialog === 1">
								<h3>이용약관</h3>
								<div
									class="disFx justify-center editor-notice narrowScroll1"
									style="max-height: 548px !important"
								>
									<div class="pad5 ql-container ql-snow w100P">
										<div
											class="ql-editor"
											v-html="toHtml(SALES_TERMS.storesTermsDto.termsContents)"
										></div>
									</div>
								</div>
							</template>
							<template v-if="tabDialog === 2">
								<h3>개인정보 처리방침</h3>
								<div
									class="disFx justify-center editor-notice narrowScroll1"
									style="max-height: 548px !important"
								>
									<div class="pad5 ql-container ql-snow w100P">
										<div
											class="ql-editor"
											v-html="
												toHtml(PRIVACY_POLICY.storesTermsDto.termsContents)
											"
										></div>
									</div>
								</div>
							</template>
							<template v-if="tabDialog === 3">
								<h3>전자금융거래 이용약관</h3>
								<div
									class="disFx justify-center editor-notice narrowScroll1"
									style="max-height: 548px !important"
								>
									<div class="pad5 ql-container ql-snow w100P">
										<div
											class="ql-editor"
											v-html="
												toHtml(
													ELECTRONIC_FINACIAL_PROVISION.storesTermsDto
														.termsContents,
												)
											"
										></div>
									</div>
								</div>
							</template>
						</div>
						<!-- 하단 btn -->
						<div class="disFx justify-space-between mt-6">
							<div></div>
							<div>
								<v-btn
									@click="closeDialog"
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
								>
									닫기
								</v-btn>
							</div>
						</div>
						<vue-editor style="display: none"></vue-editor>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import bus from '@/common/bus';
import { getStoresTermsList } from '@/api/mall_mgmt/MallTermsMgmtApi';
import { toHtml } from '@/common/common';
import { VueEditor, Quill } from 'vue2-editor-table';

export default {
	name: 'TermsPop',
	components: { VueEditor },
	data: () => ({
		tabDialog: 1,
		SALES_TERMS: { storesTermsDto: { termsContents: '' } },
		PRIVACY_POLICY: { storesTermsDto: { termsContents: '' } },
		ELECTRONIC_FINACIAL_PROVISION: { storesTermsDto: { termsContents: '' } },
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.termsDialog;
			},
			set(newValue) {
				this.$store.state.termsDialog = newValue;
			},
		},
	},
	methods: {
		toHtml(data) {
			if (data !== null && data !== undefined) {
				return toHtml(data);
			}
		},
		closeDialog() {
			this.dialog = false;
		},
		async getTermsList(tab) {
			this.dialog = true;
			this.tabDialog = tab;
			const result = await getStoresTermsList();
			if (result && result.data.resultCode === '0000') {
				this.SALES_TERMS = result.data.data.find(
					item => item.storesTermsType === 'SALES_TERMS',
				);
				this.PRIVACY_POLICY = result.data.data.find(
					item => item.storesTermsType === 'PRIVACY_POLICY',
				);
				this.ELECTRONIC_FINACIAL_PROVISION = result.data.data.find(
					item => item.storesTermsType === 'ELECTRONIC_FINACIAL_PROVISION',
				);
			} else {
				this.SALES_TERMS = { storesTermsDto: { termsContents: '' } };
				this.PRIVACY_POLICY = { storesTermsDto: { termsContents: '' } };
				this.ELECTRONIC_FINACIAL_PROVISION = {
					storesTermsDto: { termsContents: '' },
				};
			}
		},
	},
	created() {
		bus.$on('open:terms', this.getTermsList);
	},
	beforeDestroy() {
		bus.$off('open:terms', this.getTermsList);
		this.dialog = false;
	},
	watch: {
		tabDialog: {
			deep: true,
			handler: function (newVal) {
				if (document.getElementsByClassName('editor-notice')[0]) {
					document.getElementsByClassName('editor-notice')[0].scrollTo(0, 0);
				}
			},
		},
		dialog: {
			deep: true,
			handler: function (newVal) {
				if (document.getElementsByClassName('editor-notice')[0]) {
					document.getElementsByClassName('editor-notice')[0].scrollTo(0, 0);
				}
			},
		},
	},
};
</script>

<style scoped>
.editor-notice {
	min-height: 300px;
	max-height: 660px;
	overflow-y: auto;
}
.titleBtnStyleA:first-of-type,
.titleBtnStyleB:first-of-type {
	width: 86px;
}
.titleBtnStyleA:nth-of-type(2),
.titleBtnStyleB:nth-of-type(2) {
	width: 140px;
}
.titleBtnStyleA:nth-of-type(3),
.titleBtnStyleB:nth-of-type(3) {
	width: 160px;
}
.termsContent {
	padding: 30px 10px 20px 10px;
}
.termsContent h3 {
	color: #0b256b;
	text-align: center;
	font-size: 16px;
	margin-bottom: 12px;
}
.termsContent p {
	width: 100%;
	line-height: 20px;
}
</style>
