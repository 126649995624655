<template>
	<div class="padW30">
		<div class="disFx justify-space-between">
			<div class="mt20 posR search_icon1 w300">
				<input
					type="text"
					class="device_input_style1 w300"
					placeholder="검색어를 입력해 주세요"
					@input="textSearchFnc($event.target.value)"
				/>
			</div>
			<span class="mainBlack" style="font-weight: bolder; align-self: end">
				총 선택 개수 : {{ popAddSvcIdArray.length }} 건
			</span>
		</div>
		<div
			class="device_table_style1 mt15 narrowScroll posR"
			style="height: 430px !important"
		>
			<table class="w100P">
				<tr style="position: sticky; top: 0; z-index: 11">
					<th class="w150">서비스명</th>
					<th class="w200">상세설명</th>
					<th class="w80">이용요금</th>
				</tr>
				<tr v-if="searchList.length === 0" style="cursor: default">
					<td class="txAnC" colspan="20">데이터가 없습니다.</td>
				</tr>
				<tr
					class="cur_p"
					v-else
					v-for="(item, index) in searchList"
					:key="index"
					@click="addSvcFnc(item.addSvcId, popAddSvcIdArray)"
				>
					<td class="mainBlack">
						<div class="checkStyleCont4 ml30" style="pointer-events: none">
							<input
								:id="`checkBox${item.addSvcId}`"
								class="checkStyle"
								type="checkbox"
								:value="item.addSvcId"
								v-model.trim="popAddSvcIdArray"
							/>
							<label :for="`checkBox${item.addSvcId}`" style="cursor: pointer">
								{{ item.addSvcName }}
							</label>
						</div>
					</td>
					<td>{{ item.addSvcMemo ? item.addSvcMemo : '정보없음' }}</td>
					<td class="txAnC">
						{{
							String(item.addSvcCharge).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}}
						원
					</td>
				</tr>
			</table>
		</div>
		<div class="disFx justify-end mt10 mb20">
			<button
				class="backColorBlue2 mainWhite padW30 lh40 borderRadi3Px font-size-16"
				style="font-weight: bolder"
				@click="addSvcInsertFnc(popAddSvcIdArray)"
			>
				적용
			</button>
		</div>
	</div>
</template>

<script>
import { ch2pattern } from '@/common/common';

export default {
	name: 'AddServiceSelect',
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcDialog = newValue;
			},
		},
		addSvcIdArray: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcIdArray;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcIdArray = newValue;
			},
		},
	},
	data: () => ({
		popAddSvcIdArray: [],
		searchList: [],
	}),
	methods: {
		addSvcFnc(addSvcId, addSvcIdArray) {
			for (let data in addSvcIdArray) {
				if (addSvcIdArray[data] === addSvcId) {
					return addSvcIdArray.splice(data, 1);
				}
			}
			if (addSvcIdArray.length <= 5) {
				addSvcIdArray.push(addSvcId);
			} else {
				return alert('총 6개까지 선택 가능합니다.');
			}
		},
		arraySetFnc() {
			this.popAddSvcIdArray = [...this.addSvcIdArray];
			this.searchList = [...this.codeList.addServiceItemList];
		},
		addSvcInsertFnc(popArray) {
			this.formData.addSvcAmt = 0;
			for (let data in popArray) {
				let addSvcData = this.codeList.addServiceItemList.find(
					i => i.addSvcId === popArray[data],
				);
				this.formData.addSvcAmt += addSvcData.addSvcCharge;
			}
			this.addSvcIdArray = popArray;
			this.dialog = false;
		},
		textSearchFnc(text) {
			this.searchList = [];
			const addSvcList = [...this.codeList.addServiceItemList];
			let result = this.createFuzzyMatcher(text);
			if (text) {
				for (let item in addSvcList) {
					if (result.test(addSvcList[item].addSvcName)) {
						this.searchList.push(addSvcList[item]);
					}
				}
			} else {
				this.searchList = addSvcList;
			}
		},
		createFuzzyMatcher(input) {
			const pattern = input.split('').map(ch2pattern).join('.*?');
			return new RegExp(pattern);
		},
	},
	created() {
		this.arraySetFnc();
	},
};
</script>

<style scoped></style>
