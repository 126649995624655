import { instance, instanceNS } from "../../index.js";
import { jsonToQueryString } from "../../../common/common";

const domain = "/DashBoardManagement/DashBoardMgmt/";

// Today To-do 리스트 조회
const getTodoList = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instanceNS.get(`${domain}getTodoReservationList${param}`);
  return response;
};

// 진행업무 조회
const getCurrentTaskList = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instanceNS.get(`${domain}getCurrentTaskList${param}`);
  return response;
};

// 물류업무 조회
const getLogisticsTaskList = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instanceNS.get(`${domain}getLogisticsTaskList${param}`);
  return response;
};

// 상담원배정
const updateStoreReturn = async data => {
  const response = await instance.post(
      `ApplicationManagement/ApplicationMgmt/updateStoreReturn`,
      data,
  );
  return response;
};

export {
  getTodoList,
  getCurrentTaskList,
  getLogisticsTaskList,
  updateStoreReturn,
};
