import axios from 'axios';
import { setInterceptors, setInterceptorsNS } from './common/interceptors';

// public API
function createInstanceAuthNone() {
	return axios.create({
		baseURL: process.env['VUE_APP_SERVER_AUTH'],
	});
}
// private API
function createInstanceAuth() {
	const instance = axios.create({
		baseURL: process.env['VUE_APP_SERVER_AUTH'],
		withCredentials: true,
	});
	return setInterceptors(instance);
}
// private API
function createInstance() {
	const instance = axios.create({
		baseURL: process.env['VUE_APP_SERVER'],
		withCredentials: true,
	});
	return setInterceptors(instance);
}
// private API No Spinner
function createInstanceNS() {
	const instance = axios.create({
		baseURL: process.env['VUE_APP_SERVER'],
		withCredentials: true,
	});
	return setInterceptorsNS(instance);
}
// public API
function excelInstance() {
	return setInterceptorsNS(
		axios.create({
			baseURL: process.env['VUE_APP_SERVER_AUTH'],
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	);
}

function jsonInstance() {
	return setInterceptorsNS(
		axios.create({
			baseURL: process.env['VUE_APP_SERVER'],
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	);
}

function creditTextInstance() {
	return setInterceptorsNS(
		axios.create({
			baseURL: process.env['VUE_APP_SERVER_TEXT'],
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	);
}

export const instanceText = creditTextInstance();
export const instanceAuth = createInstanceAuth();
export const instance = createInstance();
export const instanceNS = createInstanceNS();
export const instanceAuthNone = createInstanceAuthNone();
export const instanceExcel = excelInstance();
export const instanceJson = jsonInstance();
