import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {
	getTechInquiryNoticeList,
	getTechInquiryNoticeGroupCnt,
	getTechInquiryNoticeAttachList,
	insertTechInquiryNotice,
	updateTechInquiryNotice,
	uploadTechInquiryNoticeAttach,
	deleteTechInquiryNotice,
	deleteTechInquiryNoticeAttach,
	updateReadCnt,
	getTopImportantTechInquiryNotice,
	getTechInquiryCommentList,
	insertTechInquiryComment,
	deleteTechInquiryComment,
} from '@/api/common-mgmt/cs-mgmt/csMgmtApi';
import { findAExtractBfromC } from '@/common/common';

Vue.use(Vuex);

const csMgmtModule = {
	namespaced: true,
	state: {
		dateResetData: false,
		noticeDialog: false,
		noticeListDialog: false,
		totalCnt: 0,
		noticeList: [],
		topNotice: null,
		REQUEST: 0,
		INQUIRY: 0,
		ERROR: 0,
		filterData: {
			inquiryNoticeTargetType: '',
			srhKeyword: '',
			orderRegiDateTime: '',
			orderReadCnt: '',
			srhStartDate: '',
			srhEndDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
	},
	mutations: {
		orderInit(state) {
			state.filterData.orderRegiDateTime = '';
			state.filterData.orderReadCnt = '';
		},
		initTechInquiryNoticeList(state) {
			state.noticeList = [];
			state.totalCnt = 0;
		},
		initNoticeCnt(state) {
			state.COMMON = 0;
			state.SALES = 0;
			state.OPENING = 0;
		},
		topFilterInit(state) {
			state.dateResetData = true;
			state.filterData.inquiryNoticeTargetType = '';
			state.filterData.srhKeyword = '';
			state.filterData.srhStartDate = '';
			state.filterData.srhEndDate = '';
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
		setTechInquiryNoticeList(state, result) {
			state.noticeList = result.data.data.resultList || [];
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setNoticeCnt(state, result) {
			/*
			REQUEST
			INQUIRY
			ERROR
			* [
    {
        "inquiryNoticeTargetType": "REQUEST",
        "inquiryNoticeTargetTypeMsg": "요청",
        "typeCnt": 1
    },
    {
        "inquiryNoticeTargetType": "INQUIRY",
        "inquiryNoticeTargetTypeMsg": "문의",
        "typeCnt": 0
    },
    {
        "inquiryNoticeTargetType": "ERROR",
        "inquiryNoticeTargetTypeMsg": "오류",
        "typeCnt": 0
    }
]
			* */
			state.REQUEST = findAExtractBfromC(
				'inquiryNoticeTargetType',
				'REQUEST',
				'typeCnt',
				result.data.data,
			);
			state.INQUIRY = findAExtractBfromC(
				'inquiryNoticeTargetType',
				'INQUIRY',
				'typeCnt',
				result.data.data,
			);
			state.ERROR = findAExtractBfromC(
				'inquiryNoticeTargetType',
				'ERROR',
				'typeCnt',
				result.data.data,
			);
		},
		initTopTechInquiryNotice(state) {
			state.topNotice = null;
		},
		setTopTechInquiryNotice(state, result) {
			state.topNotice = result.data.data || null;
		},
	},
	actions: {
		async getTechInquiryNoticeList({ commit }, data) {
			try {
				commit('initTechInquiryNoticeList');
				const result = await getTechInquiryNoticeList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setTechInquiryNoticeList', result);
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getTechInquiryNoticeGroupCnt({ commit }, data) {
			try {
				commit('initNoticeCnt');
				const result = await getTechInquiryNoticeGroupCnt(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNoticeCnt', result);
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getTechInquiryNoticeAttachList({ commit }, data) {
			try {
				const result = await getTechInquiryNoticeAttachList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertTechInquiryNotice({ commit }, data) {
			try {
				const result = await insertTechInquiryNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateTechInquiryNotice({ commit }, data) {
			try {
				const result = await updateTechInquiryNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async uploadTechInquiryNoticeAttach({ commit }, data) {
			try {
				const result = await uploadTechInquiryNoticeAttach(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteTechInquiryNotice({ commit }, data) {
			try {
				const result = await deleteTechInquiryNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteTechInquiryNoticeAttach({ commit }, data) {
			try {
				const result = await deleteTechInquiryNoticeAttach(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 시스템 공지 조회수 증가
		async updateReadCnt({ commit }, data) {
			try {
				const result = await updateReadCnt(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 시스템 중요공지 조회
		async getTopImportantTechInquiryNotice({ commit }, data) {
			try {
				const result = await getTopImportantTechInquiryNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 기술문의 댓글 조회
		async getTechInquiryCommentList({ commit }, data) {
			try {
				const result = await getTechInquiryCommentList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 기술문의 댓글 등록
		async insertTechInquiryComment({ commit }, data) {
			try {
				const result = await insertTechInquiryComment(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 기술문의 댓글 삭제
		async deleteTechInquiryComment({ commit }, data) {
			try {
				const result = await deleteTechInquiryComment(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default csMgmtModule;
