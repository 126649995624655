import Vue from 'vue';
import Vuex from 'vuex';
import {
	getDeviceStatusList,
	insertOpening,
	commonCodeEnumList,
	getDeviceCurrentGroupList,
} from '../../../../api/warehouse-mgmt/device-mgmt/DeviceStatusApi.js';
import { getRetrieveDeviceHistory } from '../../../../api/common/common';
import { excelDownLoad } from '../../../../api/common/common.js';

Vue.use(Vuex);

const DeviceStatusModule = {
	namespaced: true,
	state: {
		openDeviceDialog: false,
		infoDialog: false,
		statusList: [],
		totalCnt: 0,
		totalCntGroup: 0,
		// allCheckedBox: '',
		// checkListIdArray: [],
		deviceStatusList: [],
		listGroupData: [],
		listGroupDataView: [],
		listGroupDataViewCnt: 1,
		deviceHistory: [],
		filterData: {
			telecom: '',
			inStockRegiDate: '',
			inStockEndDate: '',
			moveRegiDate: '',
			moveEndDate: '',
			deliveryStartDate: '',
			deliveryEndDate: '',
			openingStartDateTime: '',
			openingEndDateTime: '',
			provId: '',
			openingHistoryStoreId: '',
			prevStockId: '',
			prevStockName: '',
			nextStockId: '',
			stockType: '',
			maker: '',
			goodsName: '전체',
			capacity: '전체',
			colorName: '전체',
			goodsType: '',
			inStockStatus: '',
			productFaultyYn: '',
			extrrStatus: '',
			deliveryType: '',
			deliveryStatus: '',
			barcode: '',
			openingText: '',
			perPageCnt: 20,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		enumCodeList: {},
		groupCntObj: {
			INSTOCK_TYPE_01: 0,
			INSTOCK_TYPE_02: 0,
			INSTOCK_TYPE_03: 0,
			STOCK_MOVE: 0,
			SELL_MOVE: 0,
			RETURN_TRNS: 0,
			UNKNOWN: 0,
			SELL_TRNS: 0,
			STOCK_TRNS: 0,
			FAULTY_TRNS: 0,
			MEDIATION_OUT: 0,
		},
		dvcListChk: false,
	},
	getters: {},
	mutations: {
		filterInit(state) {
			for (let data in state.filterData) {
				state.filterData[data] = '';
			}
			state.filterData.pageNo = 1;
			state.filterData.perPageCnt = 20;
			state.filterData.goodsName = '전체';
			state.filterData.capacity = '전체';
			state.filterData.colorName = '전체';
		},
		setList(state, result) {
			state.dvcListChk = true;
			state.deviceStatusList = [];
			state.deviceStatusList = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setListGroup(state, data) {
			state.listGroupData = Object.freeze(data.data.data.deviceCurrentGroupDtos) || [];
			// 유형별 테이블 스크롤 페이징
			document.getElementById(`listGroupBox`).scroll(0, 0)
			state.listGroupDataViewCnt = 1;
			state.listGroupDataView = state.listGroupData.slice(0, state.listGroupDataViewCnt*100);
		},
		setGroupDeviceCntFnc(state, data) {
			const totalCnt = state.totalCnt;
			state.totalCntGroup = totalCnt;
			state.groupCntObj = {
				INSTOCK_TYPE_01: 0,
				INSTOCK_TYPE_02: 0,
				INSTOCK_TYPE_03: 0,
				STOCK_MOVE: 0,
				SELL_MOVE: 0,
				RETURN_TRNS: 0,
				UNKNOWN: 0,
				SELL_TRNS: 0,
				STOCK_TRNS: 0,
				MEDIATION_OUT: 0,
				FAULTY_TRNS: 0,
			};
			if (data.data.data.deviceCurrentGroupCnts.length > 0) {
				data.data.data.deviceCurrentGroupCnts.forEach(item => {
					state.groupCntObj[item.stockTypeValue] = item.stockCnt;
				});
			} else {
				state.groupCntObj = {
					INSTOCK_TYPE_01: 0,
					INSTOCK_TYPE_02: 0,
					INSTOCK_TYPE_03: 0,
					STOCK_MOVE: 0,
					SELL_MOVE: 0,
					RETURN_TRNS: 0,
					UNKNOWN: 0,
					SELL_TRNS: 0,
					STOCK_TRNS: 0,
					MEDIATION_OUT: 0,
					FAULTY_TRNS: 0,
				};
			}
		},
		setDeviceHistory(state, data) {
			state.deviceHistory = data.data.data;
		},
		setUsimTypeItems(state, data) {
			state.enumCodeList.goodsTypeItems = [];
			state.enumCodeList.goodsTypeItems = data.data.data.GoodsType;
			state.enumCodeList.goodsTypeItems.unshift({ name: '전체', value: '' });
			state.enumCodeList.openingTextItems = [];
			state.enumCodeList.openingTextItems = data.data.data.openingText;
			state.enumCodeList.openingTextItems.unshift({ name: '전체', value: '' });
		},
		setCommonCodeNewList(state, data) {
			state.statusList.goodsTypeItems = [];
			state.statusList.goodsTypeItems = data.GoodsType;
			state.statusList.goodsTypeItems.unshift({ name: '전체', value: '' });
			state.statusList.inStockTypeItems = [];
			state.statusList.inStockTypeItems = data.InStockType;
			state.statusList.stockManualCheckStatus = [];
			state.statusList.stockManualCheckStatus = data.stockManualCheckStatus;
		},
		getListInit(state) {
			state.totalCnt = 0;
            state.deviceStatusList = [];
			state.dvcListChk = false;
		},
		getGroupListInit(state) {
			state.listGroupData = [];
			state.listGroupDataView = [];
			state.groupCntObj = {
                INSTOCK_TYPE_01: 0,
                INSTOCK_TYPE_02: 0,
                INSTOCK_TYPE_03: 0,
                STOCK_MOVE: 0,
                SELL_MOVE: 0,
                RETURN_TRNS: 0,
                UNKNOWN: 0,
                SELL_TRNS: 0,
                STOCK_TRNS: 0,
                FAULTY_TRNS: 0,
                MEDIATION_OUT: 0};
		},
	},
	actions: {
		// 기기현황 리스트 조회
		async getList({ commit }, data) {
			try {

				commit('getListInit');
				const result = await getDeviceStatusList(data);
				if (result) {
					commit('setList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 기기 유형별 현황 리스트 조회
		async getListGroup({ commit }, data) {
			try {
				commit('getGroupListInit')
				const result = await getDeviceCurrentGroupList(data);
				if (result) {
					commit('setListGroup', result);
					commit('setGroupDeviceCntFnc', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 이력 리스트 조회
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceHistory(data);
				if (result) {
					commit('setDeviceHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertOpening({ commit }, data) {
			try {
				let result = await insertOpening(data);
				if (result && result.data.resultCode === '0000') {
					alert('개통 완료되었습니다.');
					return true;
				} else {
					alert('알 수 없는 오류가 발생했습니다.');
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertOpeningJoin({ commit }, data) {
			try {
				let result = await insertOpening(data);
				if (result && result.data.resultCode === '0000') {
					alert('가입정보가 등록되었습니다.');
					return true;
				} else {
					alert('알 수 없는 오류가 발생했습니다.');
				}
			} catch (e) {
				console.log(e);
			}
		},
		async excelDownLoad({ commit }, data) {
			await excelDownLoad(data);
		},
		async commonCodeEnumList({ commit }) {
			const data = { initEnumData: ['GoodsType', 'openingText'] };
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setUsimTypeItems', result);
			}
		},
		async getEnumCommonCodeList({ commit }) {
			try {
				/* 신규 ENUM 데이터 */
				const newEnum = {
					initEnumData: [
						'stockCheckType',
						'stockManualCheckStatus',
						'GoodsType',
						'InStockType',
					],
				};
				const result = await commonCodeEnumList(newEnum);
				if (result) {
					commit('setCommonCodeNewList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default DeviceStatusModule;
