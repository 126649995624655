<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="1122" class="calculator_1">
				<v-card
					class="borderRadiReset disFx narrowScroll"
					style="overflow-x: hidden"
				>
					<div class="disFx">
						<div style="width: 1122px">
							<div class="posR">
								<button
									class="posA backColorBlue2 mainWhite chargeToggleBtn"
									@click="toggleChargeInfo"
								>
									<i class="font-size-18 fas fa-calculator lh28"></i>
								</button>
							</div>
							<v-card-title
								class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
							>
								<h2 class="mainWhite mainFontS boldWt">
									상담 {{ dialogTypeText }}
								</h2>
								<span class="closeBtn1 cur_p" @click="closeDialog"></span>
							</v-card-title>
							<v-card-text
								class="disFX consultDetailPop"
								style="padding: 20px 26px"
							>
								<div style="width: 756px; max-width: 756px">
									<div class="new_popTable1">
										<table class="w100P">
											<tr class="w100P">
												<td class="w80">등록일</td>
												<td class="w170">
													<template v-if="formData.regiDatetime">
														{{
															formData.regiDatetime | moment('YYYY-MM-DD HH:mm')
														}}
													</template>
												</td>
												<td class="w80">영업점</td>
												<td style="width: 172px">
													{{ formData.storeName }}
												</td>
												<td class="w80">조직명<br />등록자</td>
												<td>
													{{ formData.orgName }}<br />
													{{ formData.regiMemberName }}({{
														formData.regiUserName
													}})
												</td>
											</tr>
										</table>
										<h2 class="mt10 mainFontS boldWt mainBlack mb10">
											기본정보
										</h2>
										<table
											class="w100P"
											v-if="consultantType === 'CONSULT_FIRST'"
										>
											<tr class="w100P">
												<td class="w80">
													통신사/개통점<span class="redfont">*</span>
												</td>
												<td class="w170">
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="telecomOpeningList"
															style="font-size: 12px"
															v-model.trim="telecomId"
															item-text="openStoreName"
															outlined
															placeholder="검색해 주세요."
															class="w420 dvcFontBox"
															ref="chargeId"
															:disabled="consultDisableType"
															@change="telecomChangeFnc($event)"
														>
														</v-autocomplete>
													</div>
												</td>
												<td class="w80">
													가입유형
													<span v-if="creditInquireChk === 'Y'" class="redfont"
														>*</span
													>
												</td>
												<td style="width: 172px">
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.joinTypeItems"
															style="font-size: 12px"
															item-text="name"
															item-value="value"
															outlined
															placeholder="검색해 주세요."
															@change="cusInforInitFnc"
															:disabled="consultDisableType"
															v-model.trim="formData.joinType"
														>
														</v-autocomplete>
													</div>
												</td>
												<td class="w80">
													고객유형
													<span v-if="creditInquireChk === 'Y'" class="redfont"
														>*</span
													>
												</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.customerTypeItems"
															style="font-size: 12px"
															item-text="name"
															item-value="value"
															outlined
															placeholder="검색해 주세요."
															@change="cusInforInitFnc"
															:disabled="consultDisableType"
															v-model.trim="formData.cusType"
														>
														</v-autocomplete>
													</div>
												</td>
											</tr>
										</table>
										<table class="w100P" v-else>
											<tr class="w100P">
												<td class="w80">
													개통점<span class="redfont">*</span>
												</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.openStoreList"
															style="font-size: 12px"
															item-text="openStoreName"
															item-value="openStoreId"
															outlined
															placeholder="검색해 주세요."
															class="w420 dvcFontBox"
															ref="chargeId"
															@change="selectOpenStoreFnc($event)"
															:disabled="consultDisableType"
															v-model.trim="formData.openingStoreId"
														>
														</v-autocomplete>
													</div>
												</td>
												<td class="w80">
													가입유형
													<span v-if="creditInquireChk === 'Y'" class="redfont"
														>*</span
													>
												</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.joinTypeItems"
															style="font-size: 12px"
															item-text="name"
															item-value="value"
															outlined
															placeholder="검색해 주세요."
															@change="cusInforInitFnc"
															:disabled="consultDisableType"
															v-model.trim="formData.joinType"
														>
														</v-autocomplete>
													</div>
												</td>
												<td class="w80">
													고객유형
													<span v-if="creditInquireChk === 'Y'" class="redfont"
														>*</span
													>
												</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.customerTypeItems"
															style="font-size: 12px"
															item-text="name"
															item-value="value"
															outlined
															placeholder="검색해 주세요."
															@change="cusInforInitFnc"
															:disabled="consultDisableType"
															v-model.trim="formData.cusType"
														>
														</v-autocomplete>
													</div>
												</td>
											</tr>
										</table>
										<h2 class="mt10 mainFontS boldWt mainBlack mb10">
											고객정보/가입정보
										</h2>
										<table class="w100P">
											<template
												v-if="
													cusSelectArr.selectTelecom === 6 &&
													formData.cusType === 'INDV_BSNM'
												"
											>
												<tr>
													<td class="txAnC" colspan="6">고객정보</td>
												</tr>
											</template>
											<template v-else>
												<customer-regi-table
													v-if="
														cusSelectArr.selectTelecom &&
														formData.joinType &&
														formData.cusType
													"
												></customer-regi-table>
												<tr v-else>
													<template>
														<td class="w80">
															고객명<span class="redfont">*</span>
														</td>
														<td class="w130 lh30">
															<input
																type="text"
																class="w100P borderContColor2 borderRadi3Px padW5"
																maxlength="15"
																:disabled="consultDisableType"
																v-model.trim="consultCustomerDto.cusName"
															/>
														</td>
														<td class="w80" style="letter-spacing: -1px">
															휴대폰번호<span class="redfont">*</span>
														</td>
														<td class="w130 lh30" style="width: 172px">
															<input
																type="text"
																class="w100P borderContColor2 borderRadi3Px padW5 phoneNum"
																:disabled="consultDisableType"
																v-model.trim="consultCustomerDto.cusPhone"
																maxlength="13"
																oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
																@input="
																	phoneNumSplitFnc(
																		$event.target.value,
																		'cusPhone',
																		'consultCustomerDto',
																	)
																"
															/>
														</td>
														<td class="w80 lh18">주민번호<br />(생년월일)</td>
														<td class="w130 lh30">
															<input
																type="text"
																class="w100P borderContColor2 borderRadi3Px padW5 regiNum"
																:disabled="consultDisableType"
																v-model.trim="
																	consultCustomerDto.cusRegiNumConcat
																"
																maxlength="14"
																oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
																@input="
																	regiNumFnc(
																		$event.target.value,
																		'cusRegiNum',
																		'consultCustomerDto',
																	)
																"
															/>
														</td>
													</template>
												</tr>
											</template>
											<tr>
												<td>고객주소</td>
												<td colspan="5">
													<div class="disFx">
														<div class="btnPlus1 disIN" style="width: 377px">
															<input
																class="borderRadi3Px borderContColor2 h36 padW5"
																style="width: 164px"
																type="text"
																@click="postDialog = true"
																:disabled="consultDisableType"
																v-model.trim="consultCustomerDto.cusZipCode"
																readonly
																placeholder="우편번호"
																tabindex="-1"
															/>
															<post-api
																@postapi="addressData"
																:postDialog="postDialog"
																:disabledYn="consultDisableType"
																@closeDialog="dialogClose"
															></post-api>
														</div>
														<input
															class="borderRadi3Px borderContColor2 w100P h36 padW5"
															type="text"
															@click="postDialog = true"
															readonly
															:disabled="consultDisableType"
															v-model.trim="consultCustomerDto.cusAddr"
															placeholder="기본주소"
															tabindex="-1"
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>상세주소</td>
												<td colspan="5">
													<input
														class="borderRadi3Px borderContColor2 w100P h36 padW5"
														type="text"
														placeholder="상세주소"
														maxlength="35"
														:disabled="consultDisableType"
														v-model.trim="consultCustomerDto.cusAddrDetail"
													/>
												</td>
											</tr>
											<tr>
												<td>블랙리스트</td>
												<td colspan="5">
													<input
														type="text"
														class="borderContColor2 borderRadi3Px padW5 disGray padH4W10 h36 font-size-12"
														style="width: 164px"
														maxlength="15"
														v-model.trim="blackListText"
														disabled
													/>
													<button
														class="borderRadi3Px btnShadow1 backColorBlue3 mainWhite padW10 ml5 lh36"
														style="width: 64px"
														@click="duplicatedFnc"
														:disabled="consultDisableType"
													>
														재조회
													</button>
													<span class="ml10"
														>※ 입력하신 휴대폰의 주소로 조회됩니다.</span
													>
												</td>
											</tr>
											<tr>
												<td>기존 통신사</td>
												<td colspan="3">
													<div class="disFX ml10">
														<div
															class="radioStylePop ml10 lh30 existTelInput"
															v-for="(item, index) in codeList.existTelecomList"
															:key="index"
														>
															<input
																type="radio"
																:id="`existDetail${item.orderNum}`"
																:name="item.codeId"
																:value="item.codeSeq"
																@change="existelcomFnc($event.target.value)"
																:disabled="consultDisableType"
																v-model.trim="formData.existTelecomCodeId"
															/>
															<label
																class="ml-1 cur_p mr16"
																:for="`existDetail${item.orderNum}`"
																tabindex="0"
																>{{ item.codeNm }}
															</label>
														</div>
													</div>
												</td>
												<td>개통일자</td>
												<td
													class="datePickerStyle1"
													style="width: 120px !important"
												>
													<div style="width: 150px; overflow: hidden">
														<v-menu
															v-model.trim="menu1"
															:close-on-content-click="false"
															:nudge-right="40"
															transition="scale-transition"
															offset-y
															:attach="true"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	placeholder="YYYY-mm-dd"
																	style="font-size: 12px; width: 150px"
																	outlined
																	readonly
																	v-bind="attrs"
																	v-on="on"
																	append-icon="mdi-calendar"
																	tabindex="-1"
																	:disabled="consultDisableType"
																	v-model.trim="formData.openingDate"
																	@change="elapDateFnc($event)"
																></v-text-field>
															</template>
															<v-date-picker
																@input="menu1 = false"
																locale="ko-KR"
																v-model.trim="formData.openingDate"
																@change="elapDateFnc($event)"
															></v-date-picker>
														</v-menu>
													</div>
													<!--            <button-->
													<!--                v-if="basicData.openingDate"-->
													<!--                class="resetBtn"-->
													<!--                :disabled="!basicData.openingDate"-->
													<!--                @click="resetDate('openingDate')"-->
													<!--            >-->
													<!--              x-->
													<!--            </button>-->
												</td>
											</tr>
											<tr>
												<td style="letter-spacing: -1px">
													기존기기
													<span class="hoverIconNoti1">
														<div class="disIn posR">
															<v-icon
																class="posR"
																style="
																	font-size: 14px;
																	margin-bottom: 1px;
																	margin-left: 1px;
																"
																>fas fa-exclamation-circle
															</v-icon>
															<div
																class="hoverNoti1"
																style="
																	position: absolute;
																	left: 5px;
																	top: 20px;
																	width: 180px;
																	background-color: #fff;
																	border: 1px solid #e0e0e0;
																	padding: 5px 15px;
																	z-index: 3;
																"
															>
																<span>기존기기를 선택해 주세요.</span>
															</div>
														</div>
													</span>
												</td>
												<td>
													<template v-if="formData.existTelecomCodeId === '1'">
														<div class="datePickerStyle1 w130">
															<v-autocomplete
																:items="codeList.thriftyPhoneItems"
																style="font-size: 12px"
																item-text="codeDesc"
																item-value="codeSeq"
																outlined
																placeholder="검색해 주세요."
																class="w420 dvcFontBox"
																ref="chargeId"
																:disabled="consultDisableType"
																v-model.trim="formData.thriftyPhone"
															>
															</v-autocomplete>
														</div>
													</template>
													<template v-else>
														<div class="datePickerStyle1 w150">
															<v-autocomplete
																:items="codeList.deviceList"
																style="font-size: 12px"
																item-text="goodsName"
																item-value="goodsId"
																outlined
																placeholder="검색해 주세요."
																class="w420 dvcFontBox"
																ref="chargeId"
																:disabled="consultDisableType"
																v-model.trim="formData.existGoodsId"
															>
															</v-autocomplete>
														</div>
													</template>
												</td>
												<td>기존요금제</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.chargeList"
															style="font-size: 12px"
															item-text="chargeName"
															item-value="chargeId"
															outlined
															placeholder="검색해 주세요."
															class="w420 dvcFontBox"
															ref="chargeId"
															:disabled="consultDisableType"
															v-model.trim="formData.existChargeId"
														>
														</v-autocomplete>
													</div>
												</td>
												<td>경과일</td>
												<td
													class="datePickerStyle1"
													style="width: 130px !important"
												>
													<input
														type="text"
														class="borderContColor2 borderRadi3Px disGray padH4W10"
														style="width: 150px"
														maxlength="15"
														v-model.trim="elapsedDateText"
														disabled
													/>
												</td>
											</tr>
											<tr>
												<td>희망기기</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.hopeDeviceList"
															style="font-size: 12px"
															item-text="goodsName"
															item-value="goodsId"
															outlined
															placeholder="검색해 주세요."
															class="w420 dvcFontBox"
															ref="chargeId"
															@change="hopeDeviceChange($event)"
															:disabled="consultDisableType"
															v-model.trim="formData.hopeGoodsId"
														>
														</v-autocomplete>
													</div>
												</td>
												<td>용량</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															:items="codeList.capacityList"
															style="font-size: 12px"
															item-text="capacity"
															item-value="capacity"
															outlined
															placeholder="검색해 주세요."
															class="w420 dvcFontBox"
															ref="chargeId"
															@change="hopeCapacityChange"
															:disabled="consultDisableType"
															v-model.trim="formData.hopeCapacity"
														>
														</v-autocomplete>
													</div>
												</td>
												<td>색상</td>
												<td>
													<div class="datePickerStyle1 w150">
														<v-autocomplete
															id="colorAutoComplete"
															:items="codeList.colorList"
															style="font-size: 12px"
															item-text="colorName"
															item-value="colorName"
															outlined
															placeholder="검색해 주세요."
															class="w420 dvcFontBox"
															ref="chargeId"
															:disabled="consultDisableType"
															v-model.trim="formData.hopeColorName"
														>
														</v-autocomplete>
													</div>
												</td>
											</tr>
										</table>

										<!-- 신용조회 결과 Start-->
										<div class="disFx" v-if="formData.creditInquireId !== null">
											<h2 class="mt10 mainFontS boldWt mainBlack mb10">
												신용조회 결과
											</h2>
											<span class="mt10 ml5">
												[{{
													formData.creditInquireId === null
														? '미진행'
														: creditProcStatusEnum[
																formData.creditInquireDto.creditProcStatus
														  ]
												}}]
											</span>
										</div>
										<table
											class="w100P"
											v-if="formData.creditInquireDto"
											v-html="creditInquireResultTbl"
										></table>
										<table class="w100P" v-if="formData.creditInquireDto">
											<tr class="w100P">
												<td class="w100">신용조회 메모</td>
												<td style="white-space: pre">
													{{ formData.creditInquireDto.memoContents }}
												</td>
											</tr>
										</table>
										<!-- 신용조회 결과 Start-->
										<h2 class="mt10 mainFontS boldWt mainBlack mb10">
											리텐션 DB 정보
										</h2>
										<table class="w100P" v-if="retentionDetailInfo">
											<tr>
												<td class="w80 lh30">개통 통신사</td>
												<td colspan="5">
													{{
														retentionDetailInfo.join.openingTelecomCodeIdString
													}}
												</td>
											</tr>
											<tr>
												<td class="w80">개통일시</td>
												<td class="w130">
													{{ retentionDetailInfo.basic.openingDate }}
												</td>
												<td class="w80">경과일</td>
												<!--												<td class="w130">{{listFormData.diffOpeningDate}}일</td>-->
												<td class="w80">가입유형</td>
												<td class="w130">
													{{ retentionDetailInfo.join.joinTypeMsg }}
												</td>
											</tr>
											<tr>
												<td class="w80">개통기기</td>
												<td class="w130 lh30">
													{{ retentionDetailInfo.join.goodsIdString }}
												</td>
												<td class="w80">용량</td>
												<td class="w130 lh30">
													{{ retentionDetailInfo.join.capacity }}
												</td>
												<td class="w80 lh18">유심종류</td>
												<td class="w130 lh30">
													{{ retentionDetailInfo.join.usimTypeMsg }}
												</td>
											</tr>
											<tr>
												<td class="w80">사용요금제</td>
												<td class="w130 lh30">
													{{ retentionDetailInfo.join.chargeIdString }}
												</td>
												<td class="w80">약정유형</td>
												<td class="w130 lh30">
													{{ retentionDetailInfo.join.agreementTypeMsg }}
												</td>
												<td class="w80 lh18">약정/할부</td>
												<td class="w130 lh30">
													{{
														getAgreementPeriodTypMsg(
															retentionDetailInfo.join.agreementPeriodType,
														)
													}}
													/ {{ retentionDetailInfo.join.instlPeriodTypeMsg }}
												</td>
											</tr>
											<tr>
												<td class="w80">요금감면</td>
												<td class="w130 lh30">
													{{ retentionDetailInfo.customer.chargeReductTypeMsg }}
												</td>
												<td class="w80">월납부금액</td>
												<td class="w130 lh30">
													{{ commaFnc(listFormData.monthPaymentAmt) }}
												</td>
												<td class="w80 lh18">잔여할부</td>
												<td class="w130 lh30">
													{{ commaFnc(listFormData.residueInstlAmt) }}
												</td>
											</tr>
											<tr>
												<td class="w80">해지위약금</td>
												<td class="w130 lh30">
													{{ commaFnc(listFormData.cancelFeeAmt) }}
												</td>
												<td class="w80 redfont">해지총액</td>
												<td class="w130 lh30 fw700">
													{{
														commaFnc(
															!cancelFeeAmtCheckType
																? listFormData.residueInstlAmt
																: listFormData.residueInstlAmt +
																		listFormData.cancelFeeAmt,
														)
													}}
												</td>
												<td class="w80 lh18 backColorWhite" colspan="2">
													<div class="posR checkStyleCont4 ml20">
														<input
															class="checkStyle"
															type="checkbox"
															id="cancelFeeYn"
															v-model="cancelFeeAmtCheckType"
														/>
														<label for="cancelFeeYn" class="cur_p"
															>해지위약금 포함</label
														>
													</div>
												</td>
											</tr>
										</table>
									</div>
									<div class="disFx justify-end mt20 posR">
										<div class="disFx mr20">
											<div class="mr5 boldWt" style="line-height: 32px">
												통화결과 :
											</div>
											<div class="datePickerStyle1 w150">
												<v-autocomplete
													:items="codeList.consultStatusDetail"
													style="font-size: 12px"
													item-text="statusName"
													item-value="cnsltStatusId"
													outlined
													placeholder="가망"
													class="w420 dvcFontBox"
													ref="chargeId"
													v-model.trim="copyConsultStatus"
													return-object
													:disabled="statusDisableChk()"
													@change="statusChangeFnc($event)"
												>
													<template v-slot:item="{ item, on, attrs }">
														<v-list-item
															v-on="on"
															v-bind="attrs"
															:style="statusColor(item)"
														>
															<v-list-item-content>
																<v-list-item-title>
																	{{ item.statusName }}
																</v-list-item-title>
															</v-list-item-content>
														</v-list-item>
													</template>
												</v-autocomplete>
											</div>
										</div>

										<div style="position: absolute; left: 0">
											<v-btn
												v-if="
													formData.applId === null &&
													applInsertTempId === null &&
													this.moduleName ===
														('ConsultDbModule' || 'CustomerDbModule')
												"
												@click="openApplicationPop('regi')"
												class="backColorBlue3 mainWhite borderReset padW10"
											>
												신청서 등록
											</v-btn>
											<v-btn
												v-if="
													(formData.applId !== null ||
														applInsertTempId !== null) &&
													this.moduleName ===
														('ConsultDbModule' || 'CustomerDbModule')
												"
												@click="openApplicationPop('view')"
												class="backColorBlue3 mainWhite borderReset padW10"
											>
												신청서 보기
											</v-btn>

											<v-btn
												v-if="
													cr ===
														'61c366ce54117ac8c1ce25fe7fa885600d38ca9e7b236fc0748eea23907af848' &&
													formData.secondConsultName === null &&
													formData.applId === null &&
													(formData.creditInquireDto === null ||
														(formData.creditInquireDto.creditProcStatus !==
															'REQ' &&
															formData.creditInquireDto.creditProcStatus !==
																'PROC' &&
															formData.creditInquireDto.creditProcStatus !==
																'COMPL'))
												"
												class="borderRadi3Px btnShadow1 backColorMint1 mainWhite padW5 ml5 lh32"
												@click="crRequestFnc"
											>
												신용조회 요청
											</v-btn>
											<v-btn
												v-else-if="
													formData.creditInquireId !== null &&
													(formData.creditInquireDto.creditProcStatus ===
														'REQ' ||
														formData.creditInquireDto.creditProcStatus ===
															'PROC' ||
														formData.creditInquireDto.creditProcStatus ===
															'COMPL')
												"
												class="borderRadi3Px btnShadow1 backColorGray1 mainWhite padW5 ml5 lh32"
												@click="
													crRequestFailFnc(
														formData.creditInquireDto.creditProcStatus,
													)
												"
											>
												<template
													v-if="
														formData.creditInquireDto.creditProcStatus === 'REQ'
													"
												>
													신용조회 요청중
												</template>
												<template
													v-else-if="
														formData.creditInquireDto.creditProcStatus ===
														'PROC'
													"
												>
													신용조회 진행중
												</template>
												<template
													v-else-if="
														formData.creditInquireDto.creditProcStatus ===
														'COMPL'
													"
												>
													신용조회 완료
												</template>
											</v-btn>
											<v-btn
												v-else-if="
													(cr ===
														'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4' ||
														cr ===
															'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd') &&
													formData.applId === null
												"
												class="borderRadi3Px btnShadow1 backColorMint1 mainWhite padW5 ml5 lh32"
												@click="crRequestFnc"
											>
												신용조회 요청
											</v-btn>
										</div>
										<v-btn
											v-if="dialogTypeText === '상세' && !consultDisableType"
											class="backColorMint1 mainWhite borderReset w80"
											@click="updateConsultFnc"
										>
											수정
										</v-btn>
										<v-btn
											@click="closeDialog"
											class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
										>
											닫기
										</v-btn>
									</div>
								</div>
								<div class="ml20 w310" style="min-width: 300px">
									<div class="disFx lh20 backColorWhite">
										<div class="disFx">
											<h2 class="mr30 mb5">To-Do 등록</h2>
											<div class="checkStyleCont9">
												<input
													class="checkStyle"
													type="checkbox"
													id="notiYnDetail"
													true-value="Y"
													false-value="N"
													v-model.trim="consultTodoDto.notiYn"
													@input="notiFnc"
												/>
												<label
													for="notiYnDetail"
													style="cursor: pointer; line-height: 20px"
												>
													예약
												</label>
											</div>
										</div>
									</div>
									<table style="width: 310px">
										<tr>
											<td style="">
												<div class="datePickerStyle1 disFx">
													<span style="width: 150px; overflow: hidden">
														<v-menu
															v-model.trim="menu3"
															:close-on-content-click="false"
															:nudge-right="40"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	placeholder="YYYY-mm-dd"
																	style="font-size: 13px; width: 150px"
																	outlined
																	readonly
																	v-bind="attrs"
																	v-on="on"
																	append-icon="mdi-calendar"
																	tabindex="-1"
																	v-model.trim="
																		consultTodoDto.reservationDateTime
																	"
																	:disabled="
																		consultTodoDto.notiYn === 'N' ||
																		!consultTodoDto.notiYn
																	"
																></v-text-field>
															</template>
															<v-date-picker
																@input="menu3 = false"
																locale="ko-KR"
																v-model.trim="
																	consultTodoDto.reservationDateTime
																"
																:disabled="
																	consultTodoDto.notiYn === 'N' ||
																	!consultTodoDto.notiYn
																"
																:min="
																	new Date(
																		Date.now() -
																			new Date().getTimezoneOffset() * 60000,
																	)
																		.toISOString()
																		.substr(0, 10)
																"
															></v-date-picker>
														</v-menu>
													</span>
													<span class="ml10">
														<vue-timepicker
															class="backColorWhite"
															v-model.trim="consultTodoDto.time"
															close-on-complete
															:disabled="
																consultTodoDto.notiYn === 'N' ||
																!consultTodoDto.notiYn
															"
															:minute-interval="10"
														></vue-timepicker>
													</span>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<textarea
													class="borderContColor2 backColorWhite borderRadi3Px consult_memo pd4 lh20 noResize narrowScroll"
													style="width: 304px; height: 70px"
													maxlength="500"
													placeholder="메모 내용 입력"
													v-model.trim="consultTodoDto.toDoContents"
												></textarea>
											</td>
										</tr>
									</table>

									<consult-memo
										v-if="dialogTypeText !== '신규등록'"
										:memoList="memoList"
										:moduleName="moduleName"
										:consultMemoType="consultMemoType"
										:cnsltId="cnsltId"
										:loadFromPopup="true"
										@getConsultMemoList="getConsultMemoList"
										@getList="getMainList"
									></consult-memo>
									<consult-history
										v-if="dialogTypeText !== '신규등록'"
										:historyList="historyList"
									></consult-history>
								</div>
							</v-card-text>
						</div>
						<section class="chargeInfo disFx bgWhite">
							<calculator-summary
								v-if="calculatorActive1"
								componentIndex="1"
								noGoodsAlertMsg="통신사를 선택해주세요."
								:telecomId="formData.openingTelecomCodeId"
								:hopeDeviceList="codeList.hopeDeviceList"
								:hopeGoodsId="formData.hopeGoodsId"
								:hopeChargeSelectList="hopeChargeSelectList"
								:installmentsPeriodType="codeList.InstallmentsPeriodType"
								:ChargeReductType="codeList.ChargeReductType"
								:consultDisableType="consultDisableType"
								@applyGoods="applyGoods"
							></calculator-summary>
							<calculator-summary
								v-if="calculatorActive2"
								componentIndex="2"
								noGoodsAlertMsg="통신사를 선택해주세요."
								:telecomId="formData.openingTelecomCodeId"
								:hopeDeviceList="codeList.hopeDeviceList"
								:hopeChargeSelectList="hopeChargeSelectList"
								:installmentsPeriodType="codeList.InstallmentsPeriodType"
								:ChargeReductType="codeList.ChargeReductType"
								:consultDisableType="consultDisableType"
								@applyGoods="applyGoods"
							></calculator-summary>
							<calculator-summary
								v-if="calculatorActive3"
								componentIndex="3"
								noGoodsAlertMsg="통신사를 선택해주세요."
								:telecomId="formData.openingTelecomCodeId"
								:hopeDeviceList="codeList.hopeDeviceList"
								:hopeChargeSelectList="hopeChargeSelectList"
								:installmentsPeriodType="codeList.InstallmentsPeriodType"
								:ChargeReductType="codeList.ChargeReductType"
								:consultDisableType="consultDisableType"
								@applyGoods="applyGoods"
							></calculator-summary>
						</section>
					</div>
				</v-card>
			</v-dialog>
		</div>
		<consult-appl-pop
			:moduleName="moduleActName"
			:cnsltId="cnsltId"
			:applId="applId"
			:dialogTypeText="dialogTypeText"
			:consultMemoType="consultMemoType"
			@getList="getList"
			@reloadConsult="reloadConsult"
			@switchCustomerAppl="switchCustomerAppl"
			v-if="applDialog || applCustomerDialog"
		></consult-appl-pop>
	</div>
</template>

<script>
import ConsultApplPop from './ConsultApplPop';
import ConsultMemo from '../../../components/memo/ConsultMemo';
import ConsultHistory from '../../../components/memo/ConsultHistory';
import PostApi from '../../../components/PostApi.vue';
import CustomerRegiTable from '../consult-mgmt/customer-consult/cusRegiTable/CustomerRegiTableDetail.vue';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker';
import {
	minorsValidationFnc,
	nullValidation2,
	regiNumValFnc,
} from '@/common/common';
import numberSplit from '@/common/numberSplit';
import NumRepMixin from '@/common/NumRepMixin';
import focusMixin from '../../../common/focusMixin.js';
import CalculatorSummary from './CalculatorSummary';
import { getTelecomChargeList } from '../../../api/common/common';
import bus from '../../../common/bus';

export default {
	name: 'ConsultDetailPop',
	components: {
		CalculatorSummary,
		ConsultHistory,
		ConsultMemo,
		ConsultApplPop,
		PostApi,
		CustomerRegiTable,
		VueTimepicker,
	},
	mixins: [numberSplit, NumRepMixin, focusMixin],
	props: {
		cnsltId: { type: Number, required: true },
		dialogTypeText: { type: String, required: true },
		consultantType: { type: String, required: true },
		moduleName: { type: String, required: true },
		consultMemoType: { type: String },
		loadFromPopup: { type: Boolean, default: false },
		listFormData: { type: Object, required: true },
	},
	data: () => ({
		isChargeInfoActive: false,
		memoList: [],
		hopeChargeSelectList: [],
		menu1: false,
		menu2: false,
		menu3: false,
		postDialog: false,
		time: null,
		elapsedDate: '0',
		creditInquireResultTbl: '',
		creditProcStatusEnum: {
			REQ: '요청',
			PROC: '진행중',
			COMPL: '완료',
			ERR: '오류',
			CANCEL: '취소',
		},
		telecomId: null,
		telecomList: [{ header: '통신사' }],
		telecomOpeningList: [],
		copyCnsltStatusId: null,
		copyConsultStatus: null,
		CustomerName: '',
		CustomerPhone: '',
		CustomerPhone1: '',
		CustomerPhone2: '',
		CustomerPhone3: '',
		applId: null,
		copyConsultTodoDto: null,
		moduleActName: null,
		dialogTypeActText: null,
		cancelFeeAmtCheckType: false,
	}),
	computed: {
		module: {
			get() {
				return this.$store.state[this.moduleName];
			},
			set(newValue) {
				this.$store.state[this.moduleName] = newValue;
			},
		},
		historyList: {
			get() {
				return this.module.historyList;
			},
			set(newValue) {
				this.module.historyList = newValue;
			},
		},
		dialog: {
			get() {
				return this.module.consultDialog;
			},
			set(newValue) {
				this.module.consultDialog = newValue;
			},
		},
		applDialog: {
			get() {
				return this.module.applDialog;
			},
			set(newValue) {
				this.module.applDialog = newValue;
			},
		},
		applCustomerDialog: {
			get() {
				return this.$store.state.CustomerDbModule.applDialog;
			},
			set(newValue) {
				this.$store.state.CustomerDbModule.applDialog = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.CustomerConsultModule.codeList;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.codeList = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.CustomerConsultModule.formDataDetail;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.formDataDetail = newValue;
			},
		},
		consultCustomerDto: {
			get() {
				return this.$store.state.CustomerConsultModule.formDataDetail
					.consultCustomerDto;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.formDataDetail.consultCustomerDto =
					newValue;
			},
		},
		consultTodoDto: {
			get() {
				return this.$store.state.CustomerConsultModule.consultTodoDto;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.consultTodoDto = newValue;
			},
		},
		calculatorActive1: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActive1;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActive1 = newValue;
			},
		},
		calculatorActive2: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActive2;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActive2 = newValue;
			},
		},
		calculatorActive3: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActive3;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActive3 = newValue;
			},
		},
		calculatorActiveCnt: {
			get() {
				return this.$store.state.ChargeCalculatorModule.calculatorActiveCnt;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.calculatorActiveCnt = newValue;
			},
		},
		ss() {
			return this.$store.state.cookies.ss;
		},
		elapsedDateText() {
			let resultMsg = `${this.elapsedDate} 일`;
			return resultMsg;
		},
		filterData: {
			get() {
				return this.module.filterData;
			},
			set(newValue) {
				this.module.filterData = newValue;
			},
		},
		todoFilterData: {
			get() {
				return this.module.todoFilterData;
			},
			set(newValue) {
				this.module.todoFilterData = newValue;
			},
		},
		duplCnt: {
			get() {
				return this.$store.state.CustomerConsultModule.duplCnt;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.duplCnt = newValue;
			},
		},
		blackCnt: {
			get() {
				return this.$store.state.CustomerConsultModule.blackCnt;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.blackCnt = newValue;
			},
		},
		consultDisableType: {
			get() {
				return this.$store.state.CustomerConsultModule.consultDisableType;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.consultDisableType = newValue;
			},
		},
		blackListText() {
			let resultMsg = `블랙리스트: ${this.blackCnt}건 / 중복: ${this.duplCnt}건`;
			return resultMsg;
		},
		cusSelectArr: {
			get() {
				return this.$store.state.CustomerConsultModule.cusSelectArrDetail;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.cusSelectArrDetail = newValue;
			},
		},
		creditInquireChk: {
			get() {
				return this.$store.state.CustomerConsultModule.creditInquireChkDetail;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.creditInquireChkDetail =
					newValue;
			},
		},
		consultSwitchType: {
			get() {
				return this.$store.state.ApplicationFormCreationModule
					.consultSwitchType;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.consultSwitchType =
					newValue;
			},
		},
		cr() {
			return this.$store.state.cookies.cr;
		},
		applInsertTempId: {
			get() {
				return this.$store.state.CustomerConsultModule.applInsertTempId;
			},
			set(newValue) {
				this.$store.state.CustomerConsultModule.applInsertTempId = newValue;
			},
		},
		retentionDetailInfo: {
			get() {
				return this.$store.state.ConsultDbModule.retentionDetailInfo;
			},
			set(newValue) {
				this.$store.state.ConsultDbModule.retentionDetailInfo = newValue;
			},
		},
		cancelFeeYnChk: {
			get() {
				return this.$store.state.ConsultDbModule.cancelFeeYnChk;
			},
			set(newValue) {
				this.$store.state.ConsultDbModule.cancelFeeYnChk = newValue;
			},
		},
	},
	methods: {
		toggleChargeInfo() {
			const dialog = document.querySelector('.v-dialog.v-dialog--active');
			dialog.classList.toggle('moveLeft');
			dialog.classList.toggle('calculator_1');
			this.isChargeInfoActive = true;
			if (dialog.classList.contains('moveLeft')) {
				bus.$emit('chargeSummaryOpen', this.calculatorOpenFnc);
			}
		},
		async getList(data) {
			this.$emit('getList', data);
		},
		async getConsultMemoList(data) {
			this.memoList = [];
			this.memoList = await this.$store.dispatch(
				'SalesMgmtModule/getConsultMemoList',
				data,
			);
		},
		async getConsultHistoryList(data) {
			this.historyList = [];
			this.historyList = await this.$store.dispatch(
				'SalesMgmtModule/getConsultHistoryList',
				data,
			);
		},
		async getDeviceColorList() {
			let data = {
				goodsId: this.formData.hopeGoodsId,
				capacity: this.formData.hopeCapacity,
			};
			await this.$store.dispatch(
				'CustomerConsultModule/getDeviceColorList',
				data,
			);
		},
		async hopeCapacityChange() {
			this.codeList.colorList = [];
			await this.getDeviceColorList();
		},
		hopeDeviceChange(data) {
			this.codeList.capacity = [];
			this.getCapacityList(data);
			this.codeList.colorList = [];
		},
		async getOpeningStoreSelectList() {
			// 영업점 chain 개통점
			let data = this.ss;
			const result = await this.$store.dispatch(
				'CustomerConsultModule/getOpeningStoreSelectList',
				data,
			);
		},
		async getStoreConsultSettingList() {
			// 고객상담 통신사/개통점 목록
			const result = await this.$store.dispatch(
				'CustomerConsultModule/getStoreConsultSettingList',
			);
			if (result) {
				this.telecomOpeningList = [...this.telecomList].concat(
					this.codeList.settingTelecomList,
				);
				this.telecomOpeningList.push({ header: '개통점' });
				this.telecomOpeningList = [...this.telecomOpeningList].concat(
					this.codeList.copyOpenStoreList,
				);
			}
		},
		async getHopeGoodsSelectList(data) {
			await this.$store.dispatch(
				'CustomerConsultModule/getHopeGoodsSelectList',
				data,
			);
			this.codeList.capacityList = [];
		},
		//getHopeChargeSelectList : 요금계산기 props로 내려줄 hopeChargeSelectList
		async getHopeChargeSelectList(data) {
			let response = await getTelecomChargeList(data);
			if (response && response.data.resultCode === '0000') {
				this.hopeChargeSelectList = response.data.data;
			}
		},
		async selectOpenStoreFnc(e) {
			// 개통점 선택시 - 개통점 통신사 select
			let openingData = this.codeList.openStoreList.find(
				obj => obj.openStoreId === e,
			);
			if (this.cusSelectArr.selectTelecom !== openingData.telecom) {
				this.cusSelectArr.selectTelecom = openingData.telecom;
				this.formData.hopeGoodsId = null;
				this.formData.hopeCapacity = null;
				this.formData.hopeColorName = null;
				await this.getHopeGoodsSelectList(openingData.telecom);
				await this.getHopeChargeSelectList(openingData.telecom);
				bus.$emit('chargeSummaryRefresh', this.calculatorRefreshFnc);
			}
			this.formData.parentHierarchy = openingData.parentHierarchy;
			await this.cusInforInitFnc();
		},
		async getCapacityList(data) {
			await this.$store.dispatch('CustomerConsultModule/getCapacityList', data);
		},
		async commonCodeEnumList() {
			await this.$store.dispatch('CustomerConsultModule/commonCodeList');
		},
		async getCommonEnumList() {
			await this.$store.dispatch('CustomerConsultModule/commonCodeEnumList');
		},
		async codeListInitFnc() {
			this.codeList.deviceList = [];
			this.codeList.chargeList = [];
			this.codeList.hopeDeviceList = [];
			this.codeList.capacityList = [];
			this.codeList.colorList = [];
		},
		async consultStatusList() {
			let param = {
				consultantType: this.consultantType,
			};
			await this.$store.dispatch(
				'CustomerConsultModule/consultStatusListDetail',
				param,
			);

			// creditInquire Check
			let cnsltCreChk = this.codeList.consultStatusDetail.filter(
				item =>
					(item.cnsltStatusId === this.formData.firstConsultStatusId ||
						item.cnsltStatusId === this.formData.secondConsultStatusId) &&
					item.creditInquireChk === 'Y',
			);
			if (cnsltCreChk.length > 0) {
				this.creditInquireChk = 'Y';
			}
		},
		cusInforInitFnc() {
			if (
				this.formData.cusType !== null &&
				this.formData.cusType !== 'INDV' &&
				this.formData.joinType === null
			) {
				// 고객유형이 없거나 '개인'이 아니고
				// 가입유형이 없는 경우
				if (!nullValidation2(this.formData, ['openingStoreId', 'joinType'])) {
					this.$nextTick(() => {
						this.formData.cusType = 'INDV';
					});
					return true;
				}
			}

			const CORP = this.formData.cusType === 'CORP';
			const INDV_BSNM = this.formData.cusType === 'INDV_BSNM';
			this.formData.consultCustomerDto.courtProctorName = null; // 법정대리인명
			this.formData.consultCustomerDto.courtProctorRegiNumConcat = null; // 법정대리인주민등록번호
			this.formData.consultCustomerDto.courtProctorRegiNum1 = null; // 법정대리인주민등록번호
			this.formData.consultCustomerDto.courtProctorRegiNum2 = null; // 법정대리인주민등록번호
			this.formData.consultCustomerDto.courtProctorPhone = null; // 법정대리인연락처
			this.formData.consultCustomerDto.courtProctorPhone1 = null; // 법정대리인연락처
			this.formData.consultCustomerDto.courtProctorPhone2 = null; // 법정대리인연락처
			this.formData.consultCustomerDto.courtProctorPhone3 = null; // 법정대리인연락처
			this.formData.consultCustomerDto.courtProctorRelation = null; // 법정대리인관계
			this.formData.consultCustomerDto.bizName = null; // 사업자명
			this.formData.consultCustomerDto.bizNum = null; // 사업자번호
			this.formData.consultCustomerDto.bizNum1 = null; // 사업자번호
			this.formData.consultCustomerDto.bizNum2 = null; // 사업자번호
			this.formData.consultCustomerDto.bizNum3 = null; // 사업자번호
			this.formData.consultCustomerDto.bizPhone = null; // 사업자연락처
			this.formData.consultCustomerDto.bizPhone1 = null; // 사업자연락처
			this.formData.consultCustomerDto.bizPhone2 = null; // 사업자연락처
			this.formData.consultCustomerDto.bizPhone3 = null; // 사업자연락처
			this.formData.consultCustomerDto.bizRegiNum1 = null; // 법인등록번호
			this.formData.consultCustomerDto.bizRegiNum2 = null; // 법인등록번호
			this.formData.consultCustomerDto.cusName = null; //
			this.formData.consultCustomerDto.cusPhone = null; //
			this.formData.consultCustomerDto.cusRegiNumConcat = null; //
			this.formData.consultCustomerDto.cusRegiNum1 = null; //
			this.formData.consultCustomerDto.cusRegiNum2 = null; //
			this.formData.consultCustomerDto.licenseNum1 = null;
			this.formData.consultCustomerDto.licenseNum2 = null;
			this.formData.consultCustomerDto.licenseNum3 = null;
			this.formData.consultCustomerDto.licenseNumConcat = null;

			if (CORP || INDV_BSNM) {
				this.consultCustomerDto.bizName = this.customerName; // 사업자명
				this.consultCustomerDto.bizPhone = this.customerPhone; // 사업자연락처
				this.consultCustomerDto.bizPhone1 = this.customerPhone1; // 사업자연락처
				this.consultCustomerDto.bizPhone2 = this.customerPhone2; // 사업자연락처
				this.consultCustomerDto.bizPhone3 = this.customerPhone3; // 사업자연락처
			} else {
				this.consultCustomerDto.cusName = this.customerName;
				this.consultCustomerDto.cusPhone = this.customerPhone;
				this.consultCustomerDto.cusPhone1 = this.customerPhone1;
				this.consultCustomerDto.cusPhone2 = this.customerPhone2;
				this.consultCustomerDto.cusPhone3 = this.customerPhone3;
			}
			if (
				this.cusSelectArr.selectTelecom === 6 &&
				this.formData.cusType === 'INDV_BSNM'
			) {
				alert(
					'선택하신 개통점(KT) 는 개인사업자의 신용조회를 지원하지 않습니다.',
				);
			}
		},
		async existelcomFnc(data) {
			if (data === '42' || data === '43' || data === '44') {
				switch (data) {
					case '42':
						data = 5;
						break;
					case '43':
						data = 6;
						break;
					case '44':
						data = 7;
						break;
				}
				let param = data;
				await this.getTelecomChargeList(param);
				await this.getGoodsSelectList(param);
			} else if (data === '1') {
				this.codeList.deviceList = this.codeList.thriftyPhoneItems;
				this.codeList.chargeList = [];
				this.formData.existChargeId = null;
			} else {
				this.codeList.deviceList = [];
			}
		},
		async getGoodsSelectList(data) {
			await this.$store.dispatch(
				'CustomerConsultModule/getGoodsSelectList',
				data,
			);
		},
		async getTelecomChargeList(data) {
			await this.$store.dispatch(
				'CustomerConsultModule/getTelecomChargeList',
				data,
			);
		},
		async addressData(data) {
			this.consultCustomerDto.cusAddr = data.address;
			this.consultCustomerDto.cusZipCode = data.zonecode;
			await this.checkDuplicationConsult();
			await this.getBlackDetailList();
		},
		dialogClose(data) {
			this.postDialog = data;
		},
		async getConsultDetail() {
			let data = {
				cnsltId: this.cnsltId,
			};
			await this.$store.dispatch(
				'CustomerConsultModule/getConsultDetail',
				data,
			);

			// TO-DO Copy
			this.copyConsultTodoDto = { ...this.consultTodoDto };
			// 상담상태값 Copy
			// 이전상태값 임시저장용 copyCnsltStatusId
			// 화면 OR 권한 에따른 1,2차 상태값 분기 필요 데이터
			if (this.consultantType === 'CONSULT_FIRST') {
				this.copyCnsltStatusId = this.formData.firstConsultStatusId;
				this.copyConsultStatus = this.formData.firstConsultStatusId;
			} else {
				if (
					this.formData.secondConsultName === null &&
					this.formData.secondConsultStatusId === null
				) {
					this.copyCnsltStatusId = this.formData.firstConsultStatusId;
					this.copyConsultStatus = this.formData.firstConsultStatusId;
				} else {
					this.copyCnsltStatusId = this.formData.secondConsultStatusId;
					this.copyConsultStatus = this.formData.secondConsultStatusId;
				}
			}

			if (this.formData.existTelecomCodeId !== null) {
				await this.existelcomFnc(this.formData.existTelecomCodeId.toString());
			}
			if (this.formData.openingDate) {
				await this.elapDateFnc(this.formData.openingDate);
			}
			let openingData = this.codeList.openStoreList.find(
				obj => obj.openStoreId === this.formData.openingStoreId,
			);
			if (openingData) {
				this.cusSelectArr.selectTelecom = openingData.telecom;
				this.telecomId = openingData.openStoreName;
				this.formData.parentHierarchy = openingData.parentHierarchy;
				await this.getHopeGoodsSelectList(openingData.telecom); // 희망기기목록 조회
				await this.getHopeChargeSelectList(openingData.telecom);
				if (this.formData.hopeGoodsId) {
					await this.hopeDeviceChange(this.formData.hopeGoodsId); // 희망기기용량목록 조회
					await this.hopeCapacityChange(); // 희망기기색상목록 조회
				}
			} else {
				if (this.formData.openingTelecomCodeId) {
					this.telecomId = this.formData.openingTelecomCodeId;
					await this.getHopeGoodsSelectList(this.formData.openingTelecomCodeId);
					await this.getHopeChargeSelectList(
						this.formData.openingTelecomCodeId,
					);
					if (this.formData.hopeGoodsId) {
						await this.hopeDeviceChange(this.formData.hopeGoodsId); // 희망기기용량목록 조회
						await this.hopeCapacityChange(); // 희망기기색상목록 조회
					}
				}
			}

			// 상담 개통점은있는데 통신사가 선택안된경우
			if (
				this.formData.openingTelecomCodeId === null &&
				this.formData.openingStoreId !== null
			) {
				// this.formData.openingTelecomCodeId
				let telecom = this.codeList.openStoreList.filter(item => {
					return item.openStoreId === this.formData.openingStoreId;
				})[0].telecom;
				this.formData.openingTelecomCodeId = telecom;
			}

			// 알뜰폰 통신사일경우 선택처리
			if (
				this.formData.existTelecomCodeId === 41 ||
				this.formData.existTelecomCodeId > 45
			) {
				this.formData.thriftyPhone = this.formData.existTelecomCodeId;
				this.formData.existTelecomCodeId = '1';
			}

			// 신조 테이블 데이터 생성
			if (this.formData.creditInquireResponseDto) {
				let tbl_content = '';
				for (
					let i = 0;
					i <
					Object.keys(
						this.formData.creditInquireResponseDto.creditInquireResult,
					).length;
					i++
				) {
					let keyName = Object.keys(
						this.formData.creditInquireResponseDto.creditInquireResult,
					)[i];

					if (i % 3 === 0) {
						tbl_content += '<tr class="w100P">';
					}
					tbl_content += '<td class="w100">' + keyName + '</td>';
					tbl_content +=
						'<td><input type="text" class="borderRadi3Px borderContColor2 lh30 w100P padW10" readonly disabled value="' +
						this.formData.creditInquireResponseDto.creditInquireResult[
							keyName
						] +
						'"/></td>';
					if ((i + 1) % 3 === 0) {
						tbl_content += '</tr>';
					}
				}
				this.creditInquireResultTbl = tbl_content;
			}

			// 블랙리스트 / 중복 조회
			if (
				this.formData.consultCustomerDto.cusAddr &&
				this.formData.consultCustomerDto.cusZipCode &&
				this.formData.consultCustomerDto.cusPhone1 &&
				this.formData.consultCustomerDto.cusPhone2 &&
				this.formData.consultCustomerDto.cusPhone3
			) {
				await this.duplicatedFnc();
			}

			// 고객 기본정보 유지
			const CORP = this.formData.cusType === 'CORP';
			const INDV_BSNM = this.formData.cusType === 'INDV_BSNM';
			if (!CORP && !INDV_BSNM) {
				this.customerName = this.formData.consultCustomerDto.cusName;
				this.customerPhone = this.formData.consultCustomerDto.cusPhone;
				this.customerPhone1 = this.formData.consultCustomerDto.cusPhone1;
				this.customerPhone2 = this.formData.consultCustomerDto.cusPhone2;
				this.customerPhone3 = this.formData.consultCustomerDto.cusPhone3;
			} else if (CORP || INDV_BSNM) {
				this.customerName = this.formData.consultCustomerDto.bizName;
				this.customerPhone = this.formData.consultCustomerDto.bizPhone;
				this.customerPhone1 = this.formData.consultCustomerDto.bizPhone1;
				this.customerPhone2 = this.formData.consultCustomerDto.bizPhone2;
				this.customerPhone3 = this.formData.consultCustomerDto.bizPhone3;
			}

			this.$store.commit('ConsultDbModule/initRetentionDetail');
			if (this.formData.retentionApplId) {
				let param = {
					applId: this.formData.retentionApplId,
				};
				await this.$store.dispatch(
					'ConsultDbModule/getSalesRetentionDetailInfo',
					param,
				);

				if (this.cancelFeeYnChk) {
					this.cancelFeeAmtCheckType = true;
				}
			}
		},
		elapDateFnc(val) {
			let stYear = val.substring(0, 4);
			let stMonth = val.substring(5, 7);
			let stDay = val.substring(8, 10);

			let now = new Date();

			let year = now.getFullYear();
			let month = now.getMonth() + 1;
			let day = now.getDate();

			let stDate = new Date(stYear, stMonth, stDay);
			let endDate = new Date(year, month, day);
			let btMs = endDate.getTime() - stDate.getTime();
			const btDay = btMs / (1000 * 60 * 60 * 24);
			this.elapsedDate = btDay;
		},
		validationRuleFnc() {
			if (this.formData.joinType) {
				if (
					this.formData.cusType === 'INDV' ||
					this.formData.cusType === 'MINORS' ||
					this.formData.cusType === 'FOREIGN' ||
					!this.formData.cusType
				) {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'cusName',
							'cusPhone1',
							'cusPhone2',
							'cusPhone3',
						])
					) {
						return true;
					}
				} else {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'bizName',
							'bizPhone',
							'bizPhone1',
							'bizPhone2',
							'bizPhone3',
						])
					) {
						return true;
					}
				}
			} else {
				if (
					!nullValidation2(this.consultCustomerDto, [
						'cusName',
						'cusPhone1',
						'cusPhone2',
						'cusPhone3',
					])
				) {
					return true;
				}
			}
		},
		getMainList() {
			let data = this.filterData;
			let dataTodo = this.todoFilterData;

			if (this.consultantType === 'CONSULT_FIRST') {
				this.$store.dispatch(this.moduleName + '/getMainList', data);
				this.$store.dispatch(this.moduleName + '/getTodoList', dataTodo);
				this.$store.dispatch(
					this.moduleName + '/consultTodoGroupCnt',
					dataTodo,
				);
			} else {
				this.$store.dispatch(this.moduleName + '/consultList', data);
				this.$store.dispatch(this.moduleName + '/consultTodoList', dataTodo);
				this.$store.dispatch(
					this.moduleName + '/consultTodoGroupCnt',
					dataTodo,
				);
				this.$store.dispatch(this.moduleName + '/consultTodoStatusGroup');
			}
			this.$store.dispatch(this.moduleName + '/consultStatusGroupList');
			this.$store.dispatch('CustomerConsultModule/consultGroupCnt', data);
		},
		async checkDuplicationConsult() {
			let data = this.formData.consultCustomerDto;
			await this.$store.dispatch(
				'CustomerConsultModule/checkDuplicationConsult',
				data,
			);
		},
		async getBlackDetailList() {
			let data = {
				blackAddr: this.formData.consultCustomerDto.cusAddr,
				blackPhone:
					this.formData.consultCustomerDto.cusPhone1 +
					this.formData.consultCustomerDto.cusPhone2 +
					this.formData.consultCustomerDto.cusPhone3,
			};
			await this.$store.dispatch(
				'CustomerConsultModule/getBlackDetailList',
				data,
			);
		},
		async duplicatedFnc() {
			await this.checkDuplicationConsult();
			await this.getBlackDetailList();
		},
		statusDisableChk() {
			if (this.formData.applId !== null) {
				this.consultDisableType = true;
				return true;
			} else if (
				this.consultantType === 'CONSULT_FIRST' &&
				this.formData.secondConsultName !== null
			) {
				// [고객상담] 메뉴(1차)에서 2차 배정이 되어있는 상태일 경우
				this.consultDisableType = true;
				return true;
			} else {
				this.consultDisableType = false;
				return false;
			}
		},
		async statusChangeFnc(e) {
			this.$nextTick(() => {
				if (e.creditInquireChk === 'Y') {
					if (this.formData.openingStoreId === null) {
						alert('고객상담 설정의 개통점 등록후 신용조회 요청이 가능합니다.');
						this.copyConsultStatus = this.copyCnsltStatusId;
						return;
					}
					this.creditInquireChk = 'Y';
				} else {
					this.creditInquireChk = 'N';
				}
				this.copyCnsltStatusId = e.cnsltStatusId;
			});
			//creditInquireChk
		},
		statusValidationFnc() {
			if (
				this.creditInquireChk === 'N' &&
				this.consultantType === 'CONSULT_SECOND'
			) {
				if (!nullValidation2(this.formData, ['openingStoreId'])) {
					return true;
				}
			}

			if (
				this.creditInquireChk === 'Y' ||
				this.consultantType === 'CONSULT_SECOND'
			) {
				if (
					!nullValidation2(this.formData, [
						'openingStoreId',
						'joinType',
						'cusType',
					])
				) {
					return true;
				}
			}
		},
		crValidationFnc() {
			const NEW = this.formData.joinType === 'NEW'; // 신규가입
			const DVC_CHANGE = this.formData.joinType === 'DVC_CHANGE'; // 기기변경
			const NUM_CHANGE = this.formData.joinType === 'NUM_CHANGE'; // 번호이동
			const SKT = this.cusSelectArr.selectTelecom === 5;
			const KT = this.cusSelectArr.selectTelecom === 6;
			const LG = this.cusSelectArr.selectTelecom === 7;
			const INDV = this.formData.cusType === 'INDV';
			const MINORS = this.formData.cusType === 'MINORS';
			const INDV_BSNM = this.formData.cusType === 'INDV_BSNM';
			const CORP = this.formData.cusType === 'CORP';
			const FOREIGN = this.formData.cusType === 'FOREIGN';
			if (INDV) {
				if (
					!nullValidation2(this.consultCustomerDto, [
						'cusName', // 고객명
						'cusRegiNum1', // 고객주민번호
						'cusRegiNum2', // 고객주민번호
					])
				) {
					return true;
				}
			}
			if (MINORS) {
				if (
					!nullValidation2(this.consultCustomerDto, [
						'cusName', // 고객명
						'cusRegiNum1', // 고객주민번호
						'cusRegiNum2', // 고객주민번호
						'courtProctorName', // 법대이름
						'courtProctorPhone1', // 법대전화번호
						'courtProctorPhone2', // 법대전화번호
						'courtProctorPhone3', // 법대전화번호
						'courtProctorRegiNum1', // 법대주민번호
						'courtProctorRegiNum2', // 법대주민번호
					])
				) {
					return true;
				}
			}
			if (INDV_BSNM) {
				if (
					!nullValidation2(this.consultCustomerDto, [
						'bizName',
						'bizPhone',
						'bizNum', // 사업자번호
					])
				) {
					return true;
				}
			}
			if (CORP) {
				if (DVC_CHANGE) {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'bizName', // 사업자명
							'bizNum', // 사업자번호
							'bizPhone', // 사업자 휴대폰번호
							'bizRegiNum', // 법인번호
							'bizRegiNum1', // 법인번호
							'bizRegiNum2', // 법인번호
						])
					) {
						return true;
					}
				}
				if (NEW || NUM_CHANGE) {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'bizName', // 사업자명
							'bizRegiNum', // 법인번호
							'bizRegiNum1', // 법인번호
							'bizRegiNum2', // 법인번호
							'bizNum', // 사업자번호
							'bizPhone',
						])
					) {
						return true;
					}
				}
			}
			if (FOREIGN) {
				if (DVC_CHANGE && SKT) {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'cusName', // 고객명
							'licenseNum1', // 외국인등록번호
							'licenseNum2', // 외국인등록번호
							'stayCode', // 체류코드
							'licenseExpiredDate', // 만료일자
						])
					) {
						return true;
					}
				}
				if ((DVC_CHANGE && KT) || (DVC_CHANGE && LG)) {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'cusName', // 고객명
							'licenseNum1', // 외국인등록번호
							'licenseNum2', // 외국인등록번호
							'licenseIssueDate', // 발급일자
							'ntnlCode', // 국적
						])
					) {
						return true;
					}
				}
				if (NEW || NUM_CHANGE) {
					if (
						!nullValidation2(this.consultCustomerDto, [
							'cusName', // 고객명
							'licenseNum1', // 외국인등록번호
							'licenseNum2', // 외국인등록번호
							'licenseIssueDate', // 발급일자
							'ntnlCode', // 국적
						])
					) {
						return true;
					}
					if (SKT) {
						if (
							!nullValidation2(this.consultCustomerDto, [
								'cusName', // 고객명
								'licenseNum1', // 외국인등록번호
								'licenseNum2', // 외국인등록번호
								'licenseIssueDate', // 발급일자
								'ntnlCode', // 국적
								'licenseExpiredDate', // 만료일자
								'stayCode', // 체류코드
							])
						) {
							return true;
						}
					}
				}
			}
		},
		async updateConsultFnc() {
			if (!this.copyCnsltStatusId) {
				return alert('통화결과를 선택해주세요.');
			}
			if (this.consultDisableType) {
				return;
			}
			if (this.validationRuleFnc()) {
				return;
			}
			if (this.statusValidationFnc()) {
				this.copyConsultStatus = this.copyCnsltStatusId;
				return;
			}
			if (this.creditInquireChk === 'Y') {
				if (this.crValidationFnc()) {
					this.copyConsultStatus = this.copyCnsltStatusId;
					return;
				}
			}
			if (this.formData.existTelecomCodeId === '1') {
				if (!this.formData.thriftyPhone) {
					return alert('기존기기란의 알뜰폰 기기를 등록해주세요.');
				}
			}
			if (!confirm('수정하시겠습니까?')) {
				return;
			}

			let param = {};
			let consultTodoDto = {};
			Object.assign(param, this.formData);
			Object.assign(consultTodoDto, this.consultTodoDto);
			/*TO-DO 예약 체크 validation 추가필요*/
			if (consultTodoDto.notiYn === 'Y') {
				if (
					!this.consultTodoDto.reservationDateTime ||
					!this.consultTodoDto.time ||
					this.consultTodoDto.time.HH === '' ||
					this.consultTodoDto.time.mm === ''
				) {
					return alert('예약 일시를 입력해주세요.');
				}
				if (!this.consultTodoDto.toDoContents) {
					return alert('알림 내용을 입력해주세요.');
				}

				consultTodoDto.cnsltId = param.cnsltId;
				consultTodoDto.consultTodoType = 'CONSULT';
				if (typeof consultTodoDto.time === 'object') {
					consultTodoDto.time =
						consultTodoDto.time.HH + ':' + consultTodoDto.time.mm;
				}
				consultTodoDto.reservationDateTime =
					consultTodoDto.reservationDateTime + 'T' + consultTodoDto.time;

				await this.insertConsultTodo(consultTodoDto);
			} else if (consultTodoDto.notiYn === 'N') {
				consultTodoDto.notiYn = 'N';
				consultTodoDto.cnsltId = param.cnsltId;
				consultTodoDto.reservationDateTime = null;
				await this.insertConsultTodo(consultTodoDto);
			}

			if (typeof this.copyConsultStatus === 'object') {
				param.consultStatus = {
					cnsltStatusId: this.copyConsultStatus.cnsltStatusId,
				};
			} else {
				param.consultStatus = { cnsltStatusId: this.copyCnsltStatusId };
			}

			/*알뜰폰 선택시 thriftyPhone과 기존 통신사 existTelecomCodeId 교체*/
			if (this.formData.existTelecomCodeId === '1') {
				param.existTelecomCodeId = this.formData.thriftyPhone;
			}

			const result = await this.$store.dispatch(
				'CustomerConsultModule/updateConsult',
				param,
			);

			if (result.resultCode === '0000') {
				alert('수정되었습니다.');
				this.$store.state.ConsultDbModule.consultDialog = false;
				this.$store.state.CustomerConsultModule.consultDialog = false;
				await this.getMainList();
			} else if (result.resultCode === '5500') {
				if (confirm(result.resultMsg)) {
					param.skipProc = 'Y';
					const skipResult = await this.$store.dispatch(
						'CustomerConsultModule/updateConsult',
						param,
					);
					if (skipResult.resultCode === '0000') {
						alert('수정되었습니다.');

						this.$store.state.ConsultDbModule.consultDialog = false;
						this.$store.state.CustomerConsultModule.consultDialog = false;
						await this.getMainList();
					}
				}
			}
		},
		async insertConsultTodo(consultTodoDto) {
			const result = await this.$store.dispatch(
				'CustomerConsultModule/insertConsultTodo',
				consultTodoDto,
			);
		},
		statusColor(item) {
			let styleResult = '';
			styleResult = '' + 'color:' + item.statusFontColor + ' !important; ';
			styleResult += '' + 'background:' + item.statusColor + '; ';
			return styleResult;
		},
		openApplicationPop(action) {
			this.moduleActName = this.moduleName;
			this.dialogTypeActText = this.dialogTypeText;
			if (action === 'regi') {
				this.applDialog = true;
				this.applInsertTempId = null;
			} else if (action === 'view') {
				if (this.applInsertTempId !== null) {
					this.applId = this.applInsertTempId;
				} else {
					this.applId = this.formData.applId;
				}
				this.consultSwitchType = false;
				this.applDialog = true;
			}
		},
		async telecomChangeFnc(e) {
			if (e === 5 || e === 6 || e === 7) {
				let telecomList = this.telecomOpeningList.find(obj => obj.value === e);
				if (!telecomList.openStoreId) {
					this.$nextTick(() => {
						this.telecomId = null;
						return alert(
							`${telecomList.openStoreName}통신사의 개통점/영업점 매칭 등록이 필요합니다.\n개통점 미등록시, 해당 관리점으로 문의 하시기 바랍니다.\n(등록경로: 권한설정 > 사업자관리 > 개통점관리(사용여부:Y), 개통점 영업점매칭 확인)`,
						);
					});
				} else {
					this.formData.openingStoreId = null;
					this.formData.parentHierarchy = null;
					this.formData.openingTelecomCodeId = e;
					let openingStoreId = this.codeList.settingTelecomList.find(
						obj => obj.value === e,
					);
					this.formData.openingTelecomCodeId = openingStoreId.value;
					this.formData.parentHierarchy = openingStoreId.parentHierarchy;
					this.formData.openingStoreId = openingStoreId.openStoreId;
					await this.getHopeGoodsSelectList(this.formData.openingTelecomCodeId);
					this.formData.hopeGoodsId = null;
					this.formData.hopeCapacity = null;
					this.formData.hopeColorName = null;
				}
			} else {
				let openingStoreId = this.codeList.openStoreList.find(
					obj => obj.openStoreName === e,
				);
				this.formData.openingTelecomCodeId = openingStoreId.telecom;
				this.formData.parentHierarchy = openingStoreId.parentHierarchy;
				this.formData.openingStoreId = openingStoreId.openStoreId;
				await this.getHopeGoodsSelectList(this.formData.openingTelecomCodeId);
				if (
					this.cusSelectArr.selectTelecom === 6 &&
					this.formData.cusType === 'INDV_BSNM'
				) {
					alert(
						'선택하신 개통점(KT) 는 개인사업자의 신용조회를 지원하지 않습니다.',
					);
				}
				/*await this.selectOpenStoreFnc();*/
			}
			bus.$emit('chargeSummaryRefresh', this.calculatorRefreshFnc);
		},
		async reloadConsult() {
			await this.getConsultDetail();
			await this.consultStatusList();
			await this.getConsultMemoList({ cnsltId: this.cnsltId });
			await this.getConsultHistoryList({ cnsltId: this.cnsltId });
		},
		async applyGoods(data) {
			this.formData.hopeGoodsId = data;
			let result = await this.$store.dispatch(
				'CustomerConsultModule/getCapacityList',
				data,
			);
			if (result) {
				this.formData.hopeCapacity = this.codeList.capacityList[0].capacity;
				await this.getDeviceColorList();
				document.getElementById('colorAutoComplete').click();
			}
		},
		notiFnc() {
			this.consultTodoDto.reservationDateTime = null;
			this.consultTodoDto.time = { HH: '', mm: '' };
		},
		async crRequestFnc() {
			this.creditInquireChk = 'Y';
			if (
				!nullValidation2(this.formData, [
					'openingStoreId',
					'joinType',
					'cusType',
				])
			) {
				return true;
			}
			if (this.crValidationFnc()) {
				this.copyConsultStatus = this.copyCnsltStatusId;
				return;
			}
			if (!confirm('신용조회를 요청 하시겠습니까?')) {
				return;
			}
			let param = {};
			Object.assign(param, this.formData);
			let result = await this.$store.dispatch(
				'CustomerConsultModule/insertCreditConsult',
				param,
			);
			if (result) {
				alert('요청되었습니다.');
				await this.reloadConsult();
				await this.getMainList();
			}
		},
		crRequestFailFnc(creStatus) {
			if (creStatus === 'REQ') {
				alert('신용조회 상태가 진행중일때에는 요청하실 수 없습니다.');
			} else if (creStatus === 'PROC') {
				alert('신용조회 상태가 진행중일때에는 요청하실 수 없습니다.');
			} else if (creStatus === 'COMPL') {
				alert('신용조회 상태가 완료일때에는 요청하실 수 없습니다.');
			}
		},
		async switchCustomerAppl() {
			this.applId = this.formData.applId;
			this.cnsltId = this.formData.cnsltId;
			this.moduleActName = 'CustomerDbModule';
			this.dialogTypeActText = 'CUSTOMER';
			this.applCustomerDialog = true;
		},
		async closeDialog() {
			if (
				!(
					JSON.stringify(this.copyConsultTodoDto) ===
					JSON.stringify(this.consultTodoDto)
				)
			) {
				let param = {};
				let consultTodoDto = {};
				Object.assign(param, this.formData);
				Object.assign(consultTodoDto, this.consultTodoDto);
				/*TO-DO 예약 체크 validation 추가필요*/
				if (consultTodoDto.notiYn === 'Y') {
					if (
						!this.consultTodoDto.reservationDateTime ||
						!this.consultTodoDto.time ||
						this.consultTodoDto.time.HH === '' ||
						this.consultTodoDto.time.mm === ''
					) {
						return alert('예약 일시를 입력해주세요.');
					}
					if (!this.consultTodoDto.toDoContents) {
						return alert('알림 내용을 입력해주세요.');
					}

					consultTodoDto.cnsltId = param.cnsltId;
					consultTodoDto.consultTodoType = 'CONSULT';
					if (typeof consultTodoDto.time === 'object') {
						consultTodoDto.time =
							consultTodoDto.time.HH + ':' + consultTodoDto.time.mm;
					}
					consultTodoDto.reservationDateTime =
						consultTodoDto.reservationDateTime + 'T' + consultTodoDto.time;

					await this.insertConsultTodo(consultTodoDto);
				} else if (consultTodoDto.notiYn === 'N') {
					consultTodoDto.notiYn = 'N';
					consultTodoDto.cnsltId = param.cnsltId;
					consultTodoDto.reservationDateTime = null;
					await this.insertConsultTodo(consultTodoDto);
				}
				await this.getMainList();
			}
			this.$store.state.ConsultDbModule.consultDialog = false;
			this.$store.state.CustomerConsultModule.consultDialog = false;
		},
		regiNumFnc(value, model, dto) {
			this.regiNumSplitFnc(value, model, dto);
			if (
				this.consultCustomerDto.cusRegiNum2 &&
				this.consultCustomerDto.cusRegiNum2.length === 1
			) {
				const result = minorsValidationFnc(
					this.consultCustomerDto.cusRegiNum1,
					this.consultCustomerDto.cusRegiNum2,
				);
				if (result) {
					if (result === '미성년자 입니다.') {
						this.formData.cusType = 'MINORS';
					}
					alert(`${result}`);
				}
			}
			if (this.consultCustomerDto.cusRegiNum.length > 12) {
				const result1 = regiNumValFnc(this.consultCustomerDto.cusRegiNum);
				if (result1 === '유효하지 않은 주민번호 입니다.') {
					return alert(`${result1}`);
				}
			}
		},
		commaFnc(data) {
			if (data) {
				return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			} else {
				return 0;
			}
		},
		getAgreementPeriodTypMsg(type) {
			switch (type) {
				case 'MONTH_6':
					return '6개월';
					break;
				case 'MONTH_12':
					return '12개월';
					break;
				case 'MONTH_24':
					return '24개월';
					break;
				case 'MONTH_30':
					return '30개월';
					break;
				case 'MONTH_36':
					return '36개월';
					break;
				case 'MONTH_48':
					return '48개월';
					break;
			}
		},
	},
	async created() {
		this.consultSwitchType = false;
		this.consultTodoDto.notiYn = 'N';
		this.creditInquireChk = 'N';
		this.calculatorActiveCnt = 1;
		this.calculatorActive2 = false;
		this.calculatorActive3 = false;
		this.applInsertTempId = null;
		await this.codeListInitFnc();
		await this.getCommonEnumList();
		await this.commonCodeEnumList();
		await this.getConsultMemoList({ cnsltId: this.cnsltId });
		await this.getConsultHistoryList({ cnsltId: this.cnsltId });
		await this.getOpeningStoreSelectList();
		// 통신사별 고객상담설정값 조회
		await this.getStoreConsultSettingList();
		await this.getConsultDetail();
		await this.consultStatusList();
	},
	destroyed() {
		this.$store.state.ConsultDbModule.consultDialog = false;
		this.$store.state.CustomerConsultModule.consultDialog = false;
		this.$store.state.CustomerConsultModule.codeList.deviceList = [];
		this.$store.state.CustomerConsultModule.codeList.chargeList = [];
		this.$store.state.CustomerConsultModule.codeList.hopeDeviceList = [];
		this.duplCnt = '0';
		this.blackCnt = '0';
	},
};
</script>

<style>
.new_popTable1 table,
.new_popTable1 table tr,
.new_popTable1 table td,
.new_popTable1 table th {
	padding: 5px 8px !important;
}
.chargeToggleBtn {
	top: 50px;
	left: 1080px;
	width: 50px;
	height: 40px;
	border-radius: 20px 0 0 20px;
	transition: all 0.2s ease;
	cursor: pointer;
}
.chargeToggleBtn:focus {
	outline: none;
}
.chargeToggleBtn:hover {
	left: 1076px;
}
section.chargeInfo {
	display: none;
	z-index: 2;
	width: 900px;
}
section.chargeInfo article {
	display: flex;
	width: 250px;
	height: 100%;
	background: #fff;
}
.moveLeft section.chargeInfo {
	display: flex;
}
.v-list {
	padding: 0px 0px;
}
.theme--light.v-subheader {
	color: black;
	height: 25px;
	background-color: #dadada;
	font-weight: 500;
	padding: 0px 6px;
}
/*.borderRadi3Px input[type=text]:disabled {
	color: #E0E0E0;
	border: 1px solid #E0E0E0 !important;
}*/
.consultDetailPop .borderContColor3 {
	border: 1px solid #e0e0e0 !important;
}
.consult_memo {
	line-height: 18px;
	resize: none;
	font-size: 12px;
	outline: none;
}
.hoverNoti1 {
	display: none;
	position: absolute;
	left: 5px;
	top: 20px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
	padding: 5px 15px;
	z-index: 3;
	color: black;
}
.hoverIconNoti1:hover .hoverNoti1 {
	display: block;
}
</style>
