<template>
	<div>
		<book-mark path="/charge-mgmt/chargerequest-list"></book-mark>
		<charge-request-list-filter></charge-request-list-filter>
		<charge-request-list-list></charge-request-list-list>
	</div>
</template>

<script>
import BookMark from '../../../components/BookMark';
import ChargeRequestListFilter from './ChargeRequestList/ChargeRequestListFilter.vue';
import ChargeRequestListList from './ChargeRequestList/ChargeRequestListList.vue';

export default {
	components: {
		BookMark,
		ChargeRequestListFilter,
		ChargeRequestListList,
	},
};
</script>

<style></style>
