<template>
	<div>
		<!-- 알림 click 시 연결되는 팝업 : 민원처리내역 -->
		<complain-info-pop
			v-if="complainInfoPop"
			:complainId="idInfo1"
			:loadFromRnbNotice="loadFromRnbNotice"
			@closeDialog="closeDialogFnc"
		></complain-info-pop>
		<!-- TO-DO 예약(대시보드/RNB click 시 연결되는 팝업 : 상담상세 -->
		<template
			v-if="
				this.$route.path.indexOf('customer-consult') === -1 &&
				this.$route.path.indexOf('consult-db') === -1 &&
				salesConsultPop
			"
		>
			<consult-detail-pop
				v-if="consultCusDialog || consultDbDialog"
				:moduleName="moduleName"
				:dialogTypeText="dialogTypeText"
				:consultantType="consultantType"
				:cnsltId="cnsltId"
				:consultMemoType="consultMemoType"
			></consult-detail-pop>
		</template>

		<template
			v-if="this.$route.path.indexOf('customer-db') === -1 && salesApplPop"
		>
			<consult-appl-pop
				:cnsltId="cnsltId"
				:applId="applId"
				dialogTypeText="상세"
				consultMemoType="CUSTOMER"
				moduleName="CustomerDbModule"
				v-if="applCustomerDialog"
			></consult-appl-pop>
		</template>
		<logistics-task-return-pop
			v-if="returnDialog"
			:formData="returnTargetItem"
			@openApplPop="todoDialogFnc"
		>
		</logistics-task-return-pop>

		<!--	로그인 / 새로고침 시 중요 공지 팝업 -->
		<notice-popup
			v-if="importantSysDialog"
			:noticeData="importantSysNotice"
			:attachData="sysNotiAttachFileList"
		></notice-popup>
	</div>
</template>

<script>
import ComplainInfoPop from '../../views/comm-mgmt/complain-hndlg/popup/ComplainInfoPop';
import consultDetailPop from '../../views/sales-mgmt/popup/ConsultDetailPop';
import ConsultApplPop from '../../views/sales-mgmt/popup/ConsultApplPop';
import logisticsTaskReturnPop from '../dashboard/popup/LogisticsTaskReturnPop';
import bus from '@/common/bus';
import NoticePopup from '@/components/popup/NoticePop';

export default {
	name: 'NoticePopupCollection',
	props: {
		idInfo1: { default: 0 },
	},
	components: {
		ComplainInfoPop,
		consultDetailPop,
		ConsultApplPop,
		logisticsTaskReturnPop,
		NoticePopup,
	},
	data: () => ({
		loadFromRnbNotice: true,
		// 영업관리 팝업 (상담상세/신청서팝업)
		dialogTypeText: '상세',
		consultantType: 'CONSULT_FIRST',
		moduleName: 'CustomerConsultModule',
		consultMemoType: 'CUSTOMER',
		cnsltId: null,
		returnTargetItem: {},
		importantSysNotice: {
			sysNoticeContents: '컨텐츠',
			sysNoticeId: 0,
			sysNoticeTitle: '제목',
		},
		sysNotiAttachFileList: [],
	}),
	computed: {
		ms() {
			return this.$store.state.cookies.ms;
		},
		complainInfoPop() {
			return this.$store.state.NoticeModule.complainInfoPop;
		},
		salesConsultPop: {
			get() {
				return this.$store.state.NoticeModule.salesConsultPop;
			},
			set(newValue) {
				this.$store.state.NoticeModule.salesConsultPop = newValue;
			},
		},
		salesApplPop: {
			get() {
				return this.$store.state.NoticeModule.salesApplPop;
			},
			set(newValue) {
				this.$store.state.NoticeModule.salesApplPop = newValue;
			},
		},
		cr() {
			return this.$store.state.cookies.cr;
		},
		consultCusDialog() {
			return this.$store.state.CustomerConsultModule.consultDialog;
		},
		consultDbDialog() {
			return this.$store.state.ConsultDbModule.consultDialog;
		},
		importantSysDialog: {
			get() {
				return this.$store.state.importantSysDialog;
			},
			set(newValue) {
				this.$store.state.importantSysDialog = newValue;
			},
		},
		applCustomerDialog: {
			get() {
				return this.$store.state.CustomerDbModule.applDialog;
			},
			set(newValue) {
				this.$store.state.CustomerDbModule.applDialog = newValue;
			},
		},
		returnDialog: {
			get() {
				return this.$store.state.TodoModule.logisticsTaskReturnDialog;
			},
			set(newValue) {
				this.$store.state.TodoModule.logisticsTaskReturnDialog = newValue;
			},
		},
	},
	methods: {
		closeDialogFnc(data, dialogName) {
			this.$store.commit('NoticeModule/togglePopup', dialogName);
		},
		todoDialogFnc(item) {
			// 고객상담 && 상담DB - 상담상세 팝업
			if (item.consultType === 'CONSULT') {
				if (
					this.cr ===
					'61c366ce54117ac8c1ce25fe7fa885600d38ca9e7b236fc0748eea23907af848'
				) {
					// 1차 상담원
					this.consultantType = 'CONSULT_FIRST';
					this.consultMemoType = 'CUSTOMER';
				} else if (
					this.cr ===
					'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd'
				) {
					// 2차 상담원
					this.consultantType = 'CONSULT_SECOND';
					this.consultMemoType = 'CONSULT';
				} else if (
					this.cr ===
					'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4'
				) {
					// 상담 매니저
					// 매니저는 상담DB 기준으로 업무처리(배정 등) 업무처리가 이루어짐
					this.consultantType = 'CONSULT_SECOND';
					this.consultMemoType = 'CONSULT';
				}

				// 동일페이지에 같은 모달 컴포넌트가 있는경우 중복 에러 방지
				if (
					this.$route.path.indexOf('customer-consult') === -1 &&
					this.$route.path.indexOf('consult-db') === -1
				) {
					if (this.consultantType === 'CONSULT_FIRST') {
						this.$store.state.CustomerConsultModule.consultDialog = true;
						this.salesConsultPop = true;
						this.moduleName = 'CustomerConsultModule';
					} else {
						this.$store.state.ConsultDbModule.consultDialog = true;
						this.salesConsultPop = true;
						this.moduleName = 'ConsultDbModule';
					}
					this.cnsltId = Number(item.cnsltId);
				} else {
					// 동일페이지에 같은 모달 컴포넌트가 있어 중복방지를위해 해당 페이지에 모달호출 함수 Bus 사용
					if (
						this.cr ===
							'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4' &&
						this.$route.path.indexOf('customer-consult') > 0
					) {
						// 관리자 상세팝업 상담DB기준 모달 처리 분기
						item.userType = 'MANAGER';
						bus.$emit('cusConDialogBus', item);
					} else if (this.consultantType === 'CONSULT_FIRST') {
						bus.$emit('cusConDialogBus', item);
					} else if (this.consultantType === 'CONSULT_SECOND') {
						bus.$emit('conDialogBus', item);
					}
				}
				return;
				// 신청서 상세팝업 - 고객DB기준 Only
			} else if (item.consultType === 'CUSTOMER') {
				if (this.$route.path.indexOf('customer-db') === -1) {
					this.cnsltId = item.cnsltId;
					this.applId = item.applId;
					this.applCustomerDialog = true;
					this.salesApplPop = true;
				} else {
					item.dialogTypeText = '상세';
					bus.$emit('cusDialogBus', item);
				}
				return;
			} else if (item.consultType === 'NONE') {
				this.applId = item.applId;
				this.cnsltId = item.cnsltId;
				this.applCustomerDialog = true;
				this.salesApplPop = true;
			}
		},
		returnCheck(item) {
			this.returnTargetItem = item;
			this.returnDialog = true;
		},
		getCookie(key) {
			let result;
			let cookie = document.cookie.split(';');
			cookie.some(item => {
				item = item.replace(' ', '');
				let dic = item.split('=');
				if (`sysnoti_${key}_${this.ms}` === dic[0]) {
					result = dic[1];
					return true;
				}
			});
			return result;
		},
		async checkToShow(data) {
			this.importantSysDialog = !this.getCookie(data.sysNoticeId);
			if (this.importantSysDialog && data.attachCnt > 0) {
				let result = await this.$store.dispatch(
					'SystemMgmtModule/getSysNoticeAttachList',
					{ sysNoticeId: data.sysNoticeId },
				);
				result
					? (this.sysNotiAttachFileList = result)
					: (this.sysNotiAttachFileList = []);
			}
		},
		async getTopImportantSysNotice() {
			let response = await this.$store.dispatch(
				'SystemMgmtModule/getTopImportantSysNotice',
			);
			if (response) {
				this.importantSysNotice = response;
				if (response.sysNoticeId !== 0) {
					await this.checkToShow(response);
				}
			} else {
				this.importantSysNotice = {
					sysNoticeContents: '',
					sysNoticeId: 0,
					sysNoticeTitle: '',
				};
			}
		},
	},
	async created() {
		await this.getTopImportantSysNotice();
		bus.$on('todoDialogBus', this.todoDialogFnc);
		bus.$on('returnCheckBus', this.returnCheck);
	},
	beforeDestroy() {
		bus.$off('todoDialogBus', this.todoDialogFnc);
		bus.$off('returnCheckBus', this.returnCheck);
	},
	watch: {
		consultCusDialog: function (newValue) {
			if (!newValue) {
				this.salesConsultPop = false;
			}
		},
		consultDbDialog: function (newValue) {
			if (!newValue) {
				this.salesConsultPop = false;
			}
		},
		applCustomerDialog: function (newValue) {
			if (!newValue) {
				this.salesApplPop = false;
			}
		},
		$route(to, from) {
			// 페이지이동/뒤로가기 시 팝업강제 닫힘처리
			this.$store.state.CustomerDbModule.applDialog = false; // 고객DB 신청서 팝업
			this.$store.state.CustomerConsultModule.consultDialog = false; // 고객상담 상담 팝업
			this.$store.state.ConsultDbModule.consultDialog = false; // 상담DB 신청서 팝업
			this.$store.state.TodoModule.logisticsTaskReturnDialog = false; // 대시보드/RNB 반품확인 팝업
			this.$store.state.SalesMgmtModule.noticeListDialog = false; // 영업관리 공지사항 목록팝업
			this.$store.state.MallDomainMgmtModule.mallAuditDialog = false; // 입점신청 심사 팝업
			this.$store.state.ChargeCalculatorModule.headerCalcDialog = false; // 요금계산기 모달 팝업
			this.$store.state.RetentionDbModule.retentionDialog = false; // 리텐션DB 상세 모달 팝업
		},
	},
};
</script>

<style scoped></style>
