<template>
	<div class="lnbWrapper" v-if="!drawer">
		<lnb-bookmark></lnb-bookmark>
		<div>
			<sidebar-menu
				:menu="menu"
				showOneChild
				class="vsm_white-theme"
				@toggle-collapse="onToggleCollapse"
				@item-click="goRouteLnb"
			>
			</sidebar-menu>
		</div>
	</div>
</template>

<script>
// https://github.com/yaminncco/vue-sidebar-menu
// https://yaminncco.github.io/vue-sidebar-menu/#/styling
import { SidebarMenu } from 'vue-sidebar-menu';
import LnbBookmark from '@/components/LnbBookmark';
import lnb from '../common/lnb';

export default {
	props: {
		// Sidebar menu (required)

		// Keep only one child opened at a time (first level only)
		showOneChild: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		menu: [],
	}),
	components: {
		LnbBookmark,
		SidebarMenu,
	},
	computed: {
		drawer() {
			return this.$store.state.drawer;
		},
		roleVal() {
			return this.$store.state.cookies.role;
		},
		sct() {
			return this.$store.state.cookies.sct;
		},
		st() {
			return this.$store.state.cookies.st;
		},
		sg() {
			return this.$store.state.cookies.sg;
		},
		ss() {
			return this.$store.state.cookies.ss; // [KT-CB] 신조개통점 권한 예외처리
		},
		cr() {
			return this.$store.state.cookies.cr;
		},
		sm() {
			return this.$store.state.cookies.sm;
		},
		mq() {
			return this.$store.state.cookies.mq;
		},
		ca() {
			return this.$store.state.cookies.ca;
		},
		initDomainStatusType: {
			get() {
				return this.$store.state.initDomainStatusType;
			},
			set(newValue) {
				this.$store.state.initDomainStatusType = newValue;
			},
		},
	},
	methods: {
		editBookmark() {
			if (confirm('편집하시겠습니까?')) {
				this.editMode = !this.editMode;
			}
		},
		saveBookmark() {
			if (confirm('저장하시겠습니까?')) {
				this.$store.dispatch('saveBookmarkList');
				this.editMode = !this.editMode;
			}
		},
		removeBookmark(index) {
			this.$store.dispatch('removeBookmark', index);
		},
		onToggleCollapse(collapsed) {
			this.$store.state.drawer = collapsed;
		},
		async getStoreDomain() {
			const result = await this.$store.dispatch(
				'MallDomainMgmtModule/getStoreDomain',
			);
			if (result) {
				this.initDomainStatusType = result.reviewStatusType;
			} else {
				this.initDomainStatusType = null;
			}
			if (this.sm) {
				return result.reviewStatusType;
			} else {
				return null;
			}
		},
		async goRouteLnb(event, item) {
			if (item.mallDomainChk) {
				if (
					this.initDomainStatusType === 'ACCEPT' ||
					this.st === '22f353197e9b0c1cb58a11da8de7776a'
				) {
					return;
				}
				const result = await this.$store.dispatch(
					'MallDomainMgmtModule/getStoreDomain',
				);
				if (result.reviewStatusType !== 'ACCEPT') {
					alert(
						'현재 입점 진행중 입니다.\n입점 진행이 완료된 후 이용해주세요.',
					);
					this.menu[
						this.menu.findIndex(obj => obj.title === '쇼핑몰관리')
					].child = null;
				} else {
					// 입점된 상태값으로 변경할거.
					// this.sm = 입점 상태값
					this.menu = lnb(
						this.sct,
						this.st,
						this.sg,
						this.roleVal,
						this.cr,
						result.reviewStatusType,
						this.mq,
						process.env.VUE_APP_NAME,
						this.ca,
					);
				}
			}
		},
	},
	async created() {
		let smType = await this.getStoreDomain();
		this.menu = lnb(
			this.sct,
			this.st,
			this.sg,
			this.roleVal,
			this.cr,
			smType,
			this.mq,
			process.env.VUE_APP_NAME,
			this.ca,
		);

		// this.menu = lnb(this.sct, this.st);
		//await this.$store.dispatch('getBookmarkList'); // lnb-bookmark 중복호출
		// if (this.roleVal) {
		// 	let data = {
		// 		title: '공시지원금관리',
		// 		// icon: 'far fa-copyright',
		// 		icon: {
		// 			element: 'span',
		// 			class: 'lnb-icon icon-pnsupport',
		// 		},
		// 		child: [
		// 			{
		// 				href: '/pnsupportmoney-mgmt',
		// 				title: '공시지원금관리',
		// 			},
		// 			{
		// 				href: '/pnsupportmoneydata-mgmt',
		// 				title: '공시지원금데이터관리',
		// 			},
		// 		],
		// 	};
		// 	this.menu.push(data);
		// }
	},
};
</script>

<style>
.lnbWrapper {
	z-index: 36;
	display: inline-block;
	flex-direction: column;
	height: 100vh;
	width: 250px;
	overflow-x: hidden;
	overflow-y: scroll;
	background: #fff;
}
.lnbWrapper::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}
</style>
