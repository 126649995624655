import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {
	filterData,
	listData,
	pagingData,
} from '@/store/interface/supply-chain-mgmt/sell-status/sellStatusInterface';
import {
	getSellCurrentPage,
	updateDocTargetYn,
} from '../../../../api/supply-chain-mgmt/sell-status/SellStatusApi';
import { resultApiData } from '@/store/interface/warehouse-mgmt/interface';
// @ts-ignore
import { commonCodeEnumList } from '../../../../api/common/common';
import {
	getApplicationAlarm,
	getOpeningStoreList,
	getSaleStoreList,
	getSelOrgnztList,
	updateApplicationAlarm,
} from '../../../../api/supply-chain-mgmt/application-form-creation/ApplicationFromApi';
import { mappingCreditInquireId } from '../../../../api/credit-check-mgmt/credit-check/CreditCheckApi';

Vue.use(Vuex);

interface State {
	filterInitChk: boolean;
	quickOpeningDialog: boolean;
	cancelQuick: boolean;
	memoDialog: boolean;
	sellCurrentPageList: listData[];
	totalCnt: number;
	filterData: filterData;
	pagingData: pagingData;
	statusList: any;
	statusListCopy: any;
	saleStoreList: any;
	openingStoreList: any;
	dateResetData: any;
	checkListIdArray: any;
	allCheckedBox: any;
	numChangeCountDto: any;
	changeDvcCountDto: any;
	openingReqCountDto: any;
	selOrganizationList: any;
	resetData: boolean;
	dateFilter: any;
}

const state: State = {
	resetData: false,
	filterInitChk: false,
	allCheckedBox: '',
	checkListIdArray: [],
	quickOpeningDialog: false,
	cancelQuick: false,
	memoDialog: false,
	sellCurrentPageList: [],
	totalCnt: 0,
	filterData: {
		perPageCnt: 20,
		taskStatus: '',
		cusName: '',
		openingPhone: '',
		goodsName: '',
		capacity: '',
		colorName: '',
		OpeningTaskStatus: '',
		openingTaskStatusList: [],
		ConsultTaskStatus: '',
		LogisticsTaskStatus: '',
		saleStoreId: '',
		openingStoreId: '',
		beforeReserveYn: '',
		JoinType: '',
		telecom: '',
		existTelecom: '',
		applIds: '',
		giftEnclosedYn: '',
		orgId: '',
		deadLineYn: '',
		applDvcCheckYn: '',
	},
	numChangeCountDto: null,
	changeDvcCountDto: null,
	openingReqCountDto: null,
	statusList: [],
	statusListCopy: [],
	saleStoreList: [],
	openingStoreList: [],
	pagingData: {
		pageNo: 0,
		pageLastNo: 0,
		pageEndNo: 0,
		perPageCnt: 20,
		pageNumList: [],
	},
	dateResetData: false,
	selOrganizationList: [],
	dateFilter: 'consultRegiDateTime',
};
const getters = {};
const mutations = {
	filterInit(state: State) {
		for (const data in state.filterData) {
			// @ts-ignore
			state.filterData[data] = '';
		}
		state.filterData.openingTaskStatusList = [];
		state.filterData.perPageCnt = 20;
		state.filterData.pageNo = 1;
		state.dateResetData = !state.dateResetData;
		state.openingStoreList = [];
		state.filterData.deadLineYn = '';
	},
	setList(state: State, result: resultApiData) {
		state.sellCurrentPageList = result.resultList || [];
		state.totalCnt = result.totalCnt || 0;
		state.pagingData.perPageCnt = result.perPageCnt || 0;
		state.pagingData.pageNo = result.pageNo || 0;
		state.pagingData.pageNumList = result.pageNumList || [];
		state.pagingData.pageLastNo = result.pageLastNo || 0;
		state.pagingData.pageEndNo = result.pageEndNo || 0;
		state.numChangeCountDto = null;
		if (
			result.resultList.length > 0 &&
			result.resultList[0].numChangeCountDto !== null
		) {
			state.numChangeCountDto = result.resultList[0].numChangeCountDto;
		}
		state.changeDvcCountDto = null;
		if (
			result.resultList.length > 0 &&
			result.resultList[0].changeDvcCountDto !== null
		) {
			state.changeDvcCountDto = result.resultList[0].changeDvcCountDto;
		}
		state.openingReqCountDto = null;
		if (
			result.resultList.length > 0 &&
			result.resultList[0].openingReqCountDto !== null
		) {
			result.resultList[0].openingReqCountDto.map((item: any) => {
				item.changeDvcTypeMsg = item.changeDvcTypeMsg.replace('(개통)', '');
			});
			state.openingReqCountDto = result.resultList[0].openingReqCountDto;
		}
		// 상태값 필터(상담상태, 개통상태, 물류상태)에 카운트 추가 22.07.19
		// 조회된 목록이 있는경우
		if(result.resultList.length > 0) {
			let openReqAll = 0;
			state.sellCurrentPageList[0].statusCountList.forEach((item: any, index: number) => {
				let taskStatusCntArr: any;
				taskStatusCntArr = [];
				item.forEach((statusCntItem: any) => {
					let obj = {
						name: '',
						value: '',
					}
					if(statusCntItem.taskStatusCnt !== null && statusCntItem.taskStatusCnt !== undefined && statusCntItem.taskStatusCnt !== 0) {
						obj.name = '<span style="font-weight: 500;">' + statusCntItem.taskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + statusCntItem.taskStatusCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';					} else {
						obj.name = '<span style="font-weight: 500;">' + statusCntItem.taskStatusMsg + '</span>';
					}

					if(index === 0) {
						obj.value = statusCntItem.consultTaskStatus;
					} else if(index === 1) {
						obj.value = statusCntItem.openingTaskStatus;
						if(statusCntItem.openingTaskStatus === 'OPENING_DMND_OPENING'
							|| statusCntItem.openingTaskStatus === 'OPENING_DMND_PEND_OPENING'
							|| statusCntItem.openingTaskStatus === 'OPENING_PROG'
							|| statusCntItem.openingTaskStatus === 'BFR_AGREE_PROG'
							|| statusCntItem.openingTaskStatus === 'BFR_AGREE_COMPL'
							|| statusCntItem.openingTaskStatus === 'BFR_AGREE_WAIT') {
							openReqAll += statusCntItem.taskStatusCnt;
						}
					} else if(index === 2) {
						obj.value = statusCntItem.logisticsTaskStatus;
					}
					taskStatusCntArr.push(obj)
				})
				if(index === 0) {
					state.statusList.ConsultTaskStatus = taskStatusCntArr;
				} else if(index === 1) {
					state.statusList.OpeningTaskStatus = taskStatusCntArr;
				} else if(index === 2) {
					state.statusList.LogisticsTaskStatus = taskStatusCntArr;
				}
			})
			let openReqAllCntTxt = '';
			if(openReqAll !== 0) {
				openReqAllCntTxt = ' <span style="font-weight: 500; color:#007ef2">[' + openReqAll.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
			}
			state.statusList.OpeningTaskStatus.unshift({
				name: '<span style="font-weight: 500;">' + '개통요청(전체)</span>' + openReqAllCntTxt,
				value: 'OPENING_REQ_ALL',
			});
		} else {
			state.statusList.ConsultTaskStatus = state.statusListCopy.ConsultTaskStatus;
			state.statusList.OpeningTaskStatus = state.statusListCopy.OpeningTaskStatus;
			state.statusList.LogisticsTaskStatus = state.statusListCopy.LogisticsTaskStatus;
		}
	},
	setStatusList(state: State, result: any) {
		state.statusList = result || [];
		state.statusListCopy.ConsultTaskStatus = result.ConsultTaskStatus || [];
		state.statusListCopy.OpeningTaskStatus = result.OpeningTaskStatus || [];
		state.statusListCopy.LogisticsTaskStatus = result.LogisticsTaskStatus || [];
		if (result.DeliveryType && result.DeliveryType.length != 0) {
			result.DeliveryType.splice(0, 1);
		}
	},
	setSaleStoreList(state: State, result: any) {
		state.saleStoreList = result || [];
	},
	setOpeningStoreList(state: State, result: any) {
		state.openingStoreList = result || [];
	},
	setSelOrgnztList(state: State, result: any) {
		state.selOrganizationList = result.data.data.orgnztList;
	},
};
const actions = {
	async getSellCurrentPage(
		{ commit }: ActionContext<State, State>,
		data: filterData,
	) {
		try {
			const result = await getSellCurrentPage(data);
			if (result) {
				commit('setList', result.data.data);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getStatusList({ commit }: ActionContext<State, State>, data: any) {
		try {
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setStatusList', result.data.data);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getSaleStoreList({ commit }: ActionContext<State, State>, data: any) {
		try {
			const result = await getSaleStoreList(data);
			if (result) {
				commit('setSaleStoreList', result.data.data);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getOpeningStoreList(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getOpeningStoreList(data);
			if (result.data.status === 200) {
				if (result.data.resultCode !== '0000') {
					return false;
					//alert(result.data.resultMsg);
				} else {
					commit('setOpeningStoreList', result.data.data);
					return true;
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
	async updateDocTargetYn({ commit }: ActionContext<State, State>, data: any) {
		try {
			const result = await updateDocTargetYn(data);
			if (result.data.status === 200) {
				if (result.data.resultCode !== '0000') {
					return false;
				} else {
					return true;
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
	async mappingCreditInquireId(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await mappingCreditInquireId(data);
			if (result) {
				alert('요청완료하였습니다.');
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getSelOrgnztListFnc(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getSelOrgnztList(data);
			if (result) {
				commit('setSelOrgnztList', result);
			}
		} catch (e) {
			console.log(e);
		}
	},
	async getApplicationAlarm(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await getApplicationAlarm(data);
			if (result) {
				return result;
			}
		} catch (e) {
			console.log(e);
		}
	},
	async updateApplicationAlarm(
		{ commit }: ActionContext<State, State>,
		data: any,
	) {
		try {
			const result = await updateApplicationAlarm(data);
			if (result) {
				return result;
			}
		} catch (e) {
			console.log(e);
		}
	},
};
const SellStatusModule = {
	namespaced: true,
	state: state,
	getters: getters,
	mutations: mutations,
	actions: actions,
};
export default SellStatusModule;
