<template>
	<div style="width: 658px">
		<device-charge-amt-data></device-charge-amt-data>
		<discount-amt-data></discount-amt-data>
		<add-service-amt-data></add-service-amt-data>
	</div>
</template>

<script>
import DeviceChargeAmtData from './data-section/DeviceChargeAmtData.vue';
import AddServiceAmtData from './data-section/AddServiceAmtData.vue';
import DiscountAmtData from './data-section/DiscountAmtData.vue';

export default {
	name: 'CalculatorDataSection',
	components: { DeviceChargeAmtData, AddServiceAmtData, DiscountAmtData },
};
</script>

<style scoped></style>
