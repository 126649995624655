<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="720">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">템플릿관리</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad0 disFX tempPopCont">
						<div class="tempPopLCont">
							<div>
								<input
									type="radio"
									id="all"
									name="vocTypeTmp"
									v-model="vocScope"
									value=""
									@input="setVocScope('')"
								/>
								<label for="all"><em></em>전체</label>
								<input
									type="radio"
									id="foreignTmp"
									name="vocTypeTmp"
									v-model="vocScope"
									value="FOREIGN_COMPLAIN"
									@input="setVocScope('FOREIGN_COMPLAIN')"
								/>
								<label for="foreignTmp"><em></em>대외민원</label>
								<input
									type="radio"
									id="voiceTmp"
									name="vocTypeTmp"
									v-model="vocScope"
									value="VOICE_COMPLAIN"
									@input="setVocScope('VOICE_COMPLAIN')"
								/>
								<label for="voiceTmp"><em></em>VOC</label>
							</div>
							<div>
								<ul id="templateList" class="tempUList">
									<li
										:id="`tempLi${index}`"
										v-for="(item, index) in templateList"
										:class="{
											selectedTemplate: `tempLi${index}` === selectedTemplate,
										}"
										:key="index"
										@click="getDetailToEdit(index)"
									>
										<p class="fz500">{{ item.templateTitle }}</p>
									</li>
								</ul>
								<div class="addListBtn" @click="addNewTemp"><span>+</span></div>
							</div>
						</div>
						<div class="tempPopRCont">
							<template
								v-if="
									templateList.length === 0 && this.formStatus === 'disabled'
								"
							>
								<p style="text-align: center; width: 100%">
									등록된 템플릿이 없습니다.
								</p>
							</template>
							<template
								v-if="this.formStatus === 'new' || templateList.length > 0"
							>
								<table>
									<tr>
										<th>민원 종류</th>
										<td>
											<input
												type="radio"
												id="foreignTmp1"
												v-model="formData.templateType"
												value="FOREIGN_COMPLAIN"
												:disabled="formStatus === 'disabled'"
											/><label for="foreignTmp1"><em></em>대외민원</label>
											<input
												type="radio"
												id="voiceTmp1"
												v-model="formData.templateType"
												value="VOICE_COMPLAIN"
												:disabled="formStatus === 'disabled'"
											/><label for="voiceTmp1"><em></em>VOC</label>
										</td>
									</tr>
									<tr v-if="formStatus === 'edit'">
										<th>등록자</th>
										<td style="padding-right: 10px">
											<span>{{ formData.regiUserName }}</span>
										</td>
										<th>등록일자</th>
										<td>
											<span>{{
												formData.regiDateTime | moment('YYYY-MM-DD')
											}}</span>
										</td>
									</tr>
									<tr v-if="formStatus === 'edit'">
										<th>수정인</th>
										<td style="padding-right: 10px">
											<span>{{ formData.updUserName }}</span>
										</td>
										<th>수정일자</th>
										<td>
											<span>{{
												formData.updDateTime | moment('YYYY-MM-DD')
											}}</span>
										</td>
									</tr>
									<tr>
										<th>제목</th>
										<td colspan="3" :class="{ w84_5P: formStatus !== 'edit' }">
											<input
												type="text"
												style="width: 100%; font-size: 12px"
												v-model="formData.templateTitle"
												:disabled="formStatus === 'disabled'"
												placeholder="제목을 입력해 주세요."
											/>
										</td>
									</tr>
									<tr>
										<th>내용</th>
										<td colspan="3">
											<textarea
												v-model="formData.templateContents"
												style="margin-top: 4px; width: 100%; font-size: 12px"
												:disabled="formStatus === 'disabled'"
												placeholder="내용을 입력해 주세요."
											></textarea>
										</td>
									</tr>
								</table>
							</template>
						</div>

						<div class="tempPopButtonCont">
							<button
								v-if="formStatus === 'new'"
								class="backColorMint1"
								@click="submit('insert')"
							>
								등록
							</button>
							<button
								v-if="formStatus === 'edit'"
								class="backColorMint1"
								@click="submit('update')"
							>
								수정
							</button>

							<button
								v-if="formStatus === 'edit'"
								class="backColorRed"
								@click="submit('delete')"
							>
								삭제
							</button>
							<button class="backColorGray1" @click="dialog = false">
								닫기
							</button>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { nullValidation2 } from '../../../../common/common';
export default {
	name: 'TemplateMgmtPop',
	props: {
		popComplainType: { type: String, default: null },
	},
	data: () => ({
		dialog: true,
		menu1: false,
		menu2: false,
		formStatus: 'disabled',
		vocScope: '',
		selectedTemplateId: 0,
		selectedTemplate: '',
		formData: {
			complainTemplateId: 0,
			templateType: '',
			templateTitle: '',
			regiUserName: '',
			regiUserId: '',
			updUserName: '',
			updUserId: '',
			regiDatetime: '',
			updDatetime: '',
			templateContents: '',
		},
		templateList: [],
	}),
	computed: {},
	methods: {
		addNewTemp() {
			if (
				this.formStatus === 'new' &&
				(this.formData.templateContents.length > 0 ||
					this.formData.templateTitle.length > 0)
			) {
				if (!confirm('현재 작성중인 내용을 삭제하시겠습니까?')) {
					return;
				}
			}
			this.selectedTemplate = '';
			this.formStatus = 'new';
			this.formData = {
				complainTemplateId: 0,
				templateType: this.vocScope,
				templateTitle: '',
				templateContents: '',
			};
			if (this.vocScope === '') {
				this.formData.templateType = 'FOREIGN_COMPLAIN';
			}
		},
		todayDate() {
			let today = new Date();
			let year = today.getFullYear();
			let month = today.getMonth() + 1;
			let day = today.getDate();
			let format =
				year +
				'-' +
				('00' + month.toString()).slice(-2) +
				'-' +
				('00' + day.toString()).slice(-2);
			return format;
		},
		setVocScope(scope) {
			this.formStatus = 'disabled';
			this.formData = {
				complainTemplateId: 0,
				templateType: '',
				templateTitle: '',
				templateContents: '',
			};
			this.vocScope = scope;
			this.getComplainTemplateRecipList(scope);
		},
		async getDetailToEdit(index) {
			this.selectedTemplate = `tempLi${index}`;
			this.formStatus = 'edit';
			this.selectedTemplateId = this.templateList[index].complainTemplateId;
			let result = await this.getComplainRecipDetail(this.selectedTemplateId);
			if (result && '0000' === result.data.resultCode) {
				this.formData = result.data.data;
			}
		},
		// 템플릿 상세내역 조회
		async getComplainRecipDetail(id) {
			let data = {
				complainTemplateId: id,
			};
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainRecipDetail',
				data,
			);
			this.formData = {};
			if (result && '0000' === result.data.resultCode) {
				this.formData = result.data.data;
			}
			return result;
		},
		// 템플릿 list 조회
		async getComplainTemplateRecipList(type) {
			this.templateList = [];
			let data;
			if (type) {
				data = { templateType: type };
			}
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainTemplateRecipList',
				data,
			);
			this.templateList = [];
			if (result && '0000' === result.data.resultCode) {
				this.templateList = result.data.data;
			}
			if (this.templateList.length > 0) {
				this.getDetailToEdit(0);
			}
		},
		// selectTemplate(idx) {
		// 	this.templateList[idx]
		// },
		async submit(action) {
			let result;
			if (action === 'delete') {
				if (!confirm('삭제하시겠습니까?')) {
					return;
				}
				result = await this.deleteComplainTemplate();
			} else {
				if (
					!nullValidation2(this.formData, [
						'templateTitle',
						'templateContents',
						'templateType',
					])
				) {
					return;
				}
				let data = {
					templateContents: this.formData.templateContents,
					templateTitle: this.formData.templateTitle,
					templateType: this.formData.templateType,
				};
				if (action === 'insert') {
					if (!confirm('등록하시겠습니까?')) {
						return;
					}
					result = await this.insertComplainTemplate(data);
				} else if (action === 'update') {
					if (!confirm('수정하시겠습니까?')) {
						return;
					}
					data.complainTemplateId = this.selectedTemplateId;
					result = await this.updateComplainTemplate(data);
				}
			}
			if (result) {
				this.formData = {};
				this.formStatus = 'disabled';
			}
			await this.getComplainTemplateRecipList(this.vocScope);
		},
		//템플릿 등록
		async insertComplainTemplate(data) {
			return await this.$store.dispatch(
				'ComplainHndlgModule/insertComplainTemplate',
				data,
			);
		},
		//템플릿 수정
		async updateComplainTemplate(data) {
			return await this.$store.dispatch(
				'ComplainHndlgModule/updateComplainTemplate',
				data,
			);
		},
		//템플릿 삭제
		async deleteComplainTemplate() {
			let data = { complainTemplateId: this.selectedTemplateId };
			return await this.$store.dispatch(
				'ComplainHndlgModule/deleteComplainTemplate',
				data,
			);
		},
	},
	watch: {
		dialog: {
			deep: true,
			handler: function (newVal) {
				if (!newVal) {
					this.$emit('closeDialog', this.dialog, 'templateMgmtPop');
				}
			},
		},
	},
	async created() {
		await this.getComplainTemplateRecipList();
	},
	beforeDestroy() {
		// 민원등록 또는 상세에서 템플릿 팝업 닫을 시 22.07.25
		if (
			this.popComplainType === 'FOREIGN_COMPLAIN' ||
			this.popComplainType === 'VOICE_COMPLAIN'
		) {
			this.$emit('getComplainTemplateRecipList', this.popComplainType);
		}
	},
};
</script>

<style scoped>
.tempPopCont input::placeholder {
	font-size: 12px;
}
.tempPopLCont {
	display: flex;
	width: 240px;
	height: 480px;
	background: #fff;
	border-right: 1px solid #999;
	flex-direction: column;
}
.tempPopLCont label,
.tempPopRCont label {
	cursor: pointer;
	padding: 2px 8px;
}
.tempPopRCont th,
.tempPopRCont td,
.tempPopRCont label,
.tempPopLCont label {
	color: #000;
	font-size: 12px;
}
.tempPopLCont > div:first-child {
	display: block;
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #999;
	padding-top: 14px;
	text-align: center;
}
.tempUList li {
	cursor: pointer;
	height: 38px;
	border-bottom: 1px solid #eee;
	padding-left: 24px;
	overflow: hidden;
}
.tempUList p {
	line-height: 38px;
	white-space: nowrap;
	width: 190px;
	overflow: hidden;
	text-overflow: ellipsis;
}
#templateList {
	max-height: 390px;
	overflow-y: auto;
}
#templateList li p {
	font-size: 12px;
}
.addListBtn {
	cursor: pointer;
	display: block;
	text-align: center;
	padding: 0 !important;
	background-color: #fbfbfb;
	border-bottom: 1px solid #eee;
	height: 38px;
}
.addListBtn span {
	font-size: 34px;
	line-height: 34px;
	color: #000;
}
.tempPopRCont {
	width: 480px;
	padding: 20px 24px;
}
.tempPopRCont table {
	text-align: left;
}
.tempPopRCont table tr {
	height: 38px;
}
.tempPopRCont table th {
	width: 70px;
	font-weight: normal;
}
.tempPopRCont table input[type='radio'] .tempPopRCont table th,
.tempPopRCont table td {
	padding: 0;
}
.tempPopRCont span {
	display: inline-block;
	width: 135px;
	height: 30px;
	text-align: left;
	padding: 0 8px;
	line-height: 28px;
	border: 1px solid rgba(52, 52, 52, 0.3);
	border-radius: 4px;
}
.tempPopRCont table input[type='text'] {
	width: 135px;
	height: 32px;
}
.tempPopRCont .v-input {
	width: 135px !important;
	font-size: 14px !important;
}
.tempPopRCont table tr:last-child th {
	vertical-align: top;
	padding-top: 12px;
}
.tempPopRCont table input,
.tempPopRCont table textarea {
	border: 1px solid rgba(52, 52, 52, 0.3);
	border-radius: 4px;
	outline: none;
	padding: 0 8px;
}
.tempPopRCont table textarea {
	height: 225px;
	resize: none;
}
.tempPopButtonCont {
	position: absolute;
	bottom: 24px;
	right: 36px;
}
.tempPopButtonCont button {
	color: #fff;
	width: 72px;
	height: 35px;
	border-radius: 3px;
}
.tempPopButtonCont button:not(first-child) {
	margin-left: 8px;
}
.w84_5P {
	width: 84.5%;
}
.selectedTemplate p {
	color: #007ef2;
}
</style>
