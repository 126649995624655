import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = 'MallManagement/StoreDomainMgmt/';
const domainSale = 'MallManagement/SalesTermsMgmt/';
const domainInfo = 'MallManagement/StoreInfoMgmt/';

// sample
const getSample = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getSample${param}`);
	return response;
};

// 도메인 등록
const insertStoreDomain = async data => {
	const response = await instance.post(`${domain}insertStoreDomain`, data);
	return response;
};

// 도메인 수정
const updateMallDomain = async data => {
	const response = await instance.post(`${domain}updateMallDomain`, data);
	return response;
};

// 도메인 중복 검사
const checkDuplicationSubdomain = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}checkDuplicationSubdomain${param}`,
	);
	return response;
};

// 판매점 상세 조회
const getStoreDomain = async data => {
	const response = await instance.get(`${domain}getStoreDomain`);
	return response;
};

// 입점 목록 조회
const getStoreDomainList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getStoreDomainList${param}`);
	return response;
};

// 영업점 정보 상세조회
const getStoreInfo = async data => {
	const response = await instance.get(`${domainInfo}getStoreInfo`);
	return response;
};

// 입점관리 이용약관 조회
const getStoresTermsList = async data => {
	const response = await instance.get(`${domainSale}getStoresTermsList`);
	return response;
};

// 영업점 도메인 중복체크
const checkDuplicationStoreDomain = async data => {
	let param = '';
	if (data.param) param = jsonToQueryString(data.param);
	const response = await instance.get(
		`${domain}checkDuplicationStoreDomain/${data.type}${param}`,
		data,
	);
	return response;
};

// 영업점 입점신청 심사
const reviewStoreDomain = async data => {
	const response = await instance.post(`${domain}reviewStoreDomain`, data);
	return response;
};

// 영업점 사용여부 수정
const updateStoreDomainUse = async data => {
	const response = await instance.post(`${domain}updateStoreDomainUse`, data);
	return response;
};

// 입점 영업점 정보 수정
const updateStoreDomainInfo = async data => {
	const response = await instance.post(`${domain}updateStoreDomainInfo`, data);
	return response;
};

// 영업점 심사 반려 이력 조회
const getStoreDomainHistory = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}getStoreDomainHistory${param}`,
	);
	return response;
};

export {
	getSample,
	insertStoreDomain,
	updateMallDomain,
	checkDuplicationSubdomain,
	getStoreDomain,
	getStoreDomainList,
	getStoresTermsList,
	getStoreInfo,
	checkDuplicationStoreDomain,
	reviewStoreDomain,
	updateStoreDomainUse,
	getStoreDomainHistory,
	updateStoreDomainInfo,
};
