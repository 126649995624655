import Vue from 'vue';
import Vuex from 'vuex';
import { getList } from '../../../../api/supply-chain-mgmt/application-form-creation/RightExecutionTableApi.js';
import {
	getGoodsSelectCapacityList,
	getDeviceList,
	getOpeningStoreSelectList,
	getSaleStoreList,
} from '../../../../api/common/common.js';

Vue.use(Vuex);

const RightExeTableModule = {
	namespaced: true,
	state: {
		copyData: {},
		allCheckedBox: null,
		checkListIdArray: [],
		codeList: {
			rightProcStatusItems: [
				{ name: '요청', value: 'RIGHT_DMND' },
				{ name: '진행중', value: 'RIGHT_PROG' },
				{ name: '완료', value: 'RIGHT_COMPL' },
				{ name: '반려', value: 'RIGHT_REJECT' },
			],
			acceptanceProcStatusItems: [
				{ name: '미진행', value: 'NONE_PROG' },
				{ name: '진행(검수)', value: 'PROG_ACCEPT_Y' },
				{ name: '진행(미검수)', value: 'PROG_ACCEPT_N' },
				{ name: '진행불가', value: 'PROG_IMPOSSIBLE' },
			],
			rightReturnTypeItems: [
				{ name: '본사', value: 'HEAD_OFFICE' },
				{ name: '자가반납', value: 'SELF_RETURN' },
			],
			acceptanceYnItems: [
				{ name: '검수', value: 'Y' },
				{ name: '미검수', value: 'N' },
			],
			goodsItems: [],
			capacityItems: [],
			openStoreItems: [],
			saleStoreItems: [],
		},
		totalCnt: 0,
		filterData: {
			barcode: '',
			orderOpeningDate: '',
			rightProcStatuses: '',
			perPageCnt: 20,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		getListData: [],
		dateResetData: false,
		resetData: false,
	},
	mutations: {
		initSubFilterData(state) {
			state.filterData.acceptanceYn = '';
			state.filterData.applRegiUserName = '';
			state.filterData.cusName = '';
			state.filterData.cusPhone = '';
			state.filterData.listBarcode = '';
			state.filterData.regiMemberName = '';
			let arr = [
				'openStoreIds',
				'saleStoreIds',
				'goodsIds',
				'acceptanceProcStatuses',
				'rightReturnType',
			];
			for (let i in arr) {
				state.filterData[arr[i]] = [];
			}
		},
		setList(state, result) {
			state.getListData = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setGoodsNameList(state, result) {
			state.codeList.goodsItems = result.data.data;
		},
		setGoodsSelectCapacityList(state, result) {
			state.codeList.capacityItems = result.data.data;
			state.codeList.capacityItems.unshift({ capacity: '미선택' });
		},
		setOpeningStoreSelectList(state, result) {
			state.codeList.openStoreItems = result.data.data;
		},
		setSaleStoreList(state, result) {
			state.codeList.saleStoreItems = result.data.data;
		},
	},
	actions: {
		async getList({ commit }, data) {
			const result = await getList(data);
			if (result) {
				commit('setList', result);
			}
		},
		async getGoodsNameList({ commit }, data) {
			const result = await getDeviceList(data);
			if (result) {
				commit('setGoodsNameList', result);
			}
		},
		async getGoodsSelectCapacityList({ commit }, data) {
			const result = await getGoodsSelectCapacityList(data);
			if (result) {
				commit('setGoodsSelectCapacityList', result);
			}
		},
		async getOpeningStoreSelectList({ commit }, data) {
			const result = await getOpeningStoreSelectList(data);
			if (result) {
				commit('setOpeningStoreSelectList', result);
			}
		},
		async getSaleStoreList({ commit }, data) {
			const result = await getSaleStoreList(data);
			if (result) {
				commit('setSaleStoreList', result);
			}
		},
	},
};

export default RightExeTableModule;
