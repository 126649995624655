<template>
	<div class="toastArea" v-if="notiPopList.length > 0">
		<transition-group name="notiPop" v-if="notiPopList.length > 0">
			<div
				v-for="(item, index) in notiPopList"
				:key="`notiPop${index}`"
				class="toastPopup showNotiPop"
				:style="getNotiBgColor(item)"
			>
				<div class="toastLCont">
					<img src="../../assets/img/alert1.png" alt="알림" />
					<span v-if="notiPopList.length >= 2" class="notiTotalCnt">
						+ {{ notiPopList.length - 1 }}
					</span>
				</div>
				<div class="toastRCont">
					<template v-if="item.message === 'noti_resend'">
						<div class="cur_p" @click="focusOnRnb(index)">
							<h4>
								{{ item.title }}
							</h4>
							<p>{{ item.subTitle }}</p>
						</div>
					</template>
					<template
						v-else-if="
							item.notiType === 'CREDIT' ||
							item.notiSubType === 'APPLICATION_DEADLINE' ||
							item.notiSubType === 'APPLICATION_DEADLINE_MANAGER' ||
							item.notiType === 'CONSULT'
						"
					>
						<div class="cur_p" @click="goPage(item, index)">
							<h4>
								{{ item.title }}
								{{ item.subTitle }}
								<template v-if="item.notiSubType === 'CONSULT_REQ_WAIT'">
									{{ item.etc2 }}
								</template>
							</h4>
							<p v-html="item.contents"></p>
							<span
								>{{
									[item.regiDateTime, 'YYYYMMDDHHmmss']
										| moment('YYYY-MM-DD HH:mm:ss')
								}}
								{{ item.regiUserName !== null ? ' | ' + item.regiUserName : '' }}</span
							>
						</div>
					</template>
					<template v-else>
						<div class="cur_p" @click="focusOnRnb(index)">
							<h4>
								{{ item.title }}
								{{ item.subTitle }}
							</h4>
							<p>{{ item.contents }}</p>
							<span
								>{{
									[item.regiDateTime, 'YYYYMMDDHHmmss']
										| moment('YYYY-MM-DD HH:mm:ss')
								}}
								{{ item.regiUserName !== null ? ' | ' + item.regiUserName : '' }}</span
							>
						</div>
					</template>
					<span
						v-if="notiPopList.length >= 2"
						class="allCloseBtn"
						@click="clearNotiList"
						>모두닫기
					</span>
					<button class="closeBtn3" @click="closeNoti(index)"></button>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
import bus from '@/common/bus';

export default {
	name: 'ToastPopup',
	props: {
		bgColor: {
			type: String,
			default: '#007ef2',
		},
		alarmData: {
			type: Object,
		},
	},
	data: () => ({}),
	computed: {
		activeRnb: {
			get() {
				return this.$store.state.NoticeModule.activeRnb;
			},
			set(newValue) {
				this.$store.state.NoticeModule.activeRnb = newValue;
			},
		},
		notiRnbCnt() {
			return this.$store.state.NoticeModule.notiRnbCnt;
		},
		notiPopList: {
			get() {
				return this.$store.state.NoticeModule.notiPopList;
			},
			set(newValue) {
				this.$store.state.NoticeModule.notiPopList = newValue;
			},
		},
		cr() {
			return this.$store.state.cookies.cr;
		},
		ss() {
			return this.$store.state.cookies.ss;
		},
		ms() {
			return this.$store.state.cookies.ms;
		},
		sg() {
			return this.$store.state.cookies.sg;
		},
	},
	methods: {
		clearNotiList() {
			this.notiPopList = [];
		},
		// 단일 알림일 경우
		async focusOnRnb(index) {
			if (this.activeRnb === true) {
				await bus.$emit('getNotiList:rnb', index);
			} else {
				this.$store.state.NoticeModule.activeRnbIndex = 1;
				this.pageNo = 1;
				this.notiRnbResultList = [];
				let data = {
					pageNo: this.pageNo,
					notiTargetType: 'RNB',
				};
				let result = await this.$store.dispatch(
					'NoticeModule/getNotiRnbList',
					data,
				);
				await this.$store.commit('NoticeModule/focusRnbByIdx', index);
			}
		},
		goPage(obj, idx) {
			//obj.etc <<< srhKeyword
			if (obj.notiType === 'CREDIT') {
				// 신조 알림 페이지 이동
				let paramOnj = {};
				let pushName = '';
				if(obj.etc2 !== this.ss) { // 일치하지 않는 경우 관리점이라 판매관리-신용조회로 이동
					if (this.$route.path === '/credit-check' || this.$route.path === '/credit-check-request') {
						// 현재 신조페이지일 경우
						bus.$emit('socketSrcCre:msg', obj);
					} else {
						if (this.sg === 'StoreGrade_S') {
							pushName = 'credit-check-request';
						} else {
							pushName = 'credit-check';
						}
						paramOnj = { srhType: 'ALL', srhKeyword: obj.etc };
						this.$router.push({ name: pushName, params: paramOnj });
					}
				} else if(obj.etc2 === this.ss) {
					paramOnj = { srhCusName: obj.etc }
					// 22.11.08 영업관리 마이그레이션 이후 고객DB만 사용
					// 페이지 대상 분기
					// if(obj.etc4 === this.ms) {
					// 	if(obj.etc5 === null) {
					// 		pushName = 'consult-db'; // 신청서 아이디가없으면 상담DB
					// 	} else {
					// 		pushName = 'customer-db'; // 신청서 아이디가있으면 고객DB
					// 	}
					// } else if (obj.etc4 === null && obj.etc3 === this.ms) {
					// 	pushName = 'customer-consult'; // 2차상담원 정보가 없고 1차상담원 정보가 현재사용자와 같으면 상담관리
					// }
					pushName = 'customer-consult';
					// 페이지 이동 분기
					if (this.$route.name === pushName) {
						// 현재 페이지일 경우
						bus.$emit('todoNotiFilterSearch:msg', paramOnj);
					} else {
						this.$router.push({ name: pushName, params: paramOnj });
					}
				}
			} else if (
				obj.notiSubType === 'APPLICATION_DEADLINE' ||
				obj.notiSubType === 'APPLICATION_DEADLINE_MANAGER'
			) {
				// 신조 알림 페이지 이동
				if (this.$route.path === '/sell-status') {
					// 현재 신조페이지일 경우
					bus.$emit('socketDeadLine:msg', obj);
				} else {
					let paramOnj = { deadLineYn: 'Y' };
					if (this.sg === 'StoreGrade_S') {
						this.$router.push({ name: 'customer-consult', params: paramOnj });
					} else {
						this.$router.push({ name: 'sell-status', params: paramOnj });
					}
				}
			} else if (obj.notiType === 'CONSULT') {
				// 상담 To-Do 알림
				let paramOnj = {};
				let pushName = '';
				if (obj.notiSubType === 'CONSULT_TODO_CONSULT') {
					if (
						this.cr ===
						'61c366ce54117ac8c1ce25fe7fa885600d38ca9e7b236fc0748eea23907af848'
					) {
						pushName = 'customer-consult'; // 상담관리_1차상담원
					} else {
						pushName = 'consult-db'; // 상담관리_2차상담원, 매니저
					}
					paramOnj = { srhCusName: obj.etc2, srhCusPhone: obj.etc3 }
				} else if (obj.notiSubType === 'CONSULT_TODO_CUSTOMER') {
					pushName = 'customer-db'; // 고객관리_2차상담원, 매니저
					paramOnj = { srhCusName: obj.etc2, srhCusPhone: obj.etc3 }
				} else if (obj.notiSubType === 'CONSULT_SECOND_ASSIGN') { // 상담DB Push
					pushName = 'consult-db'; // 고객관리_2차상담원, 매니저
					paramOnj = { srhSecondConsultStatuses: [0] }
				} else if (obj.notiSubType === 'CONSULT_REQ_WAIT') { // 상담DB Push
					pushName = 'customer-db'; // 고객관리_2차상담원, 매니저
					paramOnj = { srhCusName: obj.etc2 }
				}
				if (this.$route.name === pushName) {
					// 현재 페이지일 경우
					bus.$emit('todoNotiFilterSearch:msg', paramOnj);
				} else {
					this.$router.push({ name: pushName, params: paramOnj });
				}
			}
			this.notiPopList.splice(idx, 1);
		},
		closeNoti(index) {
			this.notiPopList.splice(index, 1);
		},
		addPushMsg(objItem) {
			if (objItem) {
				// if (this.notiPopList.length === 0) this.notiPopList = [];
				// if (this.notiPopList.length > 2) this.notiPopList.shift();
				objItem.contents = objItem.contents
					.replace(/&amp;/g, '&')
					.replace(/&lt;/g, '<')
					.replace(/&gt;/g, '>')
					.replace(/&quot;/g, '"')
					.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '');
				this.notiPopList.push(objItem);
				if (this.activeRnb === false) {
					bus.$emit('getNotiList:rnb');
				} else if (
					this.$store.state.NoticeModule.notiRnbResultList.length === 0
				) {
					bus.$emit('getNotiList:rnb');
				}
			}
			if (this.activeRnb === false) {
				bus.$emit('getNotiList:rnb');
			}
			this.getNotiRnbCnt();
		},
		addPushMsgCredit(objItem) {
			let sendYn = false;
			if(objItem.etc2 !== this.ss) { // 관리점
				sendYn = true;
			} else if(objItem.etc2 === this.ss) { // 영업점
				if(objItem.etc4 === this.ms) {
					if(objItem.etc5 === null) {
						sendYn = true;
					} else {
						sendYn = true;
					}
				} else if (objItem.etc4 === null && objItem.etc3 === this.ms) {
					sendYn = true;
				} else {
					sendYn = false;
				}
			}

			if (sendYn) {
				bus.$emit('socketCreReqCnt:msg');
				// if (this.notiPopList.length === 0) this.notiPopList = [];
				// if (this.notiPopList.length > 2) this.notiPopList.shift();
				objItem.contents = objItem.contents
						.replace(/&amp;/g, '&')
						.replace(/&lt;/g, '<')
						.replace(/&gt;/g, '>')
						.replace(/&quot;/g, '"');
				this.notiPopList.push(objItem);
			}
		},
		addPushMsgApplDeadLine(objItem) {
			// console.log(objItem)
			if (
				(objItem.notiSubType === 'APPLICATION_DEADLINE_MANAGER' &&
					this.$store.state.cookies.role === 'M') ||
				(objItem.notiSubType === 'APPLICATION_DEADLINE' &&
					objItem.etc === this.$store.state.cookies.ms.toString())
			) {
				//  if (this.notiPopList.length === 0) this.notiPopList = [];
				// if (this.notiPopList.length > 2) this.notiPopList.shift();
				objItem.contents = objItem.contents
					.replace(/&amp;/g, '&')
					.replace(/&lt;/g, '<')
					.replace(/&gt;/g, '>')
					.replace(/&quot;/g, '"');
				this.notiPopList.push(objItem);
			}
		},
		addPushMsgConsultToDo(objItem) {
			// if (this.notiPopList.length === 0) this.notiPopList = [];
			// if (this.notiPopList.length > 2) this.notiPopList.shift();
			objItem.contents = objItem.contents
				.replace(/&amp;/g, '&')
				.replace(/&lt;/g, '<')
				.replace(/&gt;/g, '>')
				.replace(/&quot;/g, '"');
			this.notiPopList.push(objItem);
		},
		async getNotiRnbCnt() {
			await this.$store.dispatch('NoticeModule/getNotiRnbCnt');
		},
		getNotiBgColor(item) {
			let bgColor = this.bgColor;
			if (item.emergencyYn === 'Y') {
				bgColor = '#FF2E00';
			} else if (item.notiType === 'CREDIT') {
				bgColor = '#00b55e';
			} else if (item.notiType === 'CONSULT') {
				bgColor = '#00AAC1';
			}
			let colorObj = {
				background: bgColor,
			};
			return colorObj;
		},
	},
	created() {
		this.notiPopList = [];
		/*		let test = {
			message: 'credit',
			emergencyYn: 'N',
			title: '신용조회',
			subTitle: '[요청/완료/오류/취소]',
			contents: '요청 고객명 ? ? ? ?',
			regiDateTime: '20220222222222',
			regiUserName: '디오'
		}
		this.notiPopList.push(test);*/

		// (TEST)
		// setInterval(() => {
		// 	this.notiPopList.push({
		// 		contents: '666666',
		// 		emergencyYn: 'N',
		// 		etc: null,
		// 		etc2: null,
		// 		etc3: null,
		// 		message: 'noti',
		// 		notiId: 6,
		// 		notiSubType: 'COMPLAIN_REQ',
		// 		notiType: 'COMPLAIN',
		// 		regiDateTime: '20220711103520',
		// 		regiUserName: '관리자',
		// 		subTitle: '[VOC-요청] ',
		// 		subscribeLink: '/subscribe/notification/store/2',
		// 		title: '민원처리내역',
		// 	});
		// }, 5000);

		bus.$on('push:msg', this.addPushMsg);
		bus.$on('pushCredit:msg', this.addPushMsgCredit);
		bus.$on('pushApplDeadLine:msg', this.addPushMsgApplDeadLine);
		bus.$on('pushConsultToDo:msg', this.addPushMsgConsultToDo);
	},
	beforeDestroy() {
		bus.$off('push:msg', this.addPushMsg);
		bus.$off('pushCredit:msg', this.addPushMsgCredit);
		bus.$off('pushApplDeadLine:msg', this.addPushMsgApplDeadLine);
		bus.$off('pushConsultToDo:msg', this.addPushMsgConsultToDo);
	},
};
</script>

<style scoped>
.toastArea {
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
		0 1px 10px 0 rgb(0 0 0 / 12%);
	position: fixed !important;
	display: flex;
	flex-direction: column;
	border-top: 0px !important;
	width: 360px;
	height: 76px;
	background: transparent;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	left: 8px;
	z-index: 37;
}
.toastArea > span {
	display: flex;
	flex-direction: column-reverse;
	position: absolute;
	top: 0;
}
.toastPopup {
	display: flex;
	position: sticky;
	width: 360px;
	border-radius: 5px;
	z-index: 5;
	/*margin-top: 2px;*/
}
.notiPop-enter-active,
.notiPop-leave-active {
	/*max-height: 70px;*/
	opacity: 1;
	transition: opacity 0.35s;
}
.notiPop-enter,
.notiPop-leave-to {
	/*max-height: 0;*/
	opacity: 0;
}
.toastPopup > div {
	display: flex;
	position: relative;
}
.toastLCont {
	position: relative;
	width: 65px;
	background: rgba(0, 0, 0, 0.15);
	border-radius: 5px 0 0 0;
}
.toastLCont img {
	position: absolute;
	width: 24px;
	top: 24px;
	left: 22px;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-name: bellRing;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	transform-origin: top center;
}
.toastRCont {
	display: flex;
	width: 295px;
	color: #fff;
	padding: 10px;
}
.toastRCont div:first-child {
	width: 256px;
}
.toastRCont h4,
.toastRCont p,
.toastRCont span:last-child {
	width: 95%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.toastRCont h4 {
	font-size: 14px;
}
.toastRCont p {
	font-size: 12px;
	margin: 0;
}
.toastRCont span {
	font-size: 10px;
	color: rgba(255, 255, 255, 0.6);
}
.closeBtn3 {
	display: flex;
	width: 10px;
	height: 10px;
	background: url('../../assets/img/close_Btn1.png');
	background-size: contain;
	margin: 4px;
	opacity: 0.6;
}
.notiTotalCnt {
	position: absolute;
	bottom: 8px;
	top: 3px;
	right: 6px;
	font-size: 10px;
	color: rgba(255, 255, 255, 0.6);
	word-spacing: -1px;
	cursor: default;
}
.allCloseBtn {
	cursor: pointer;
	position: absolute;
	bottom: 8px;
	right: 14px;
	padding: 2px;
}
</style>
