import Vue from 'vue';
import Vuex from 'vuex';

import {
	insertMallReview,
	getMallReviewList,
	getMallReviewStatusCount,
	getProcReviewEventId,
	getMallReviewDetail,
	updateMallReview,
} from "../../../api/mall_mgmt/MallReviewMgmtApi";
import {commonCodeEnumList} from "../../../api/common/common";

Vue.use(Vuex);

const MallReviewMgmtModule = {
	namespaced: true,
	state: {
		filterData: {},
		filterInitChk: false,
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		mallReviewList: [],
		mallReviewStatusCount: [],
		reviewStatusItems: [],
		reviewDeliveryItems: [],
	},
	mutations: {
		setCommonEnumList(state, result) {
			state.reviewStatusItems = result.reviewType || [];
			state.reviewDeliveryItems = result.reviewDeliveryType || [];
		},
		setMallReviewListInit(state) {
			state.mallReviewList = [];
		},
		setMallReviewList(state, result) {
			state.mallReviewList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setMallReviewStatusCountInit(state) {
			state.mallReviewStatusCount = [];
		},
		setMallReviewStatusCount(state, result) {
			state.mallReviewStatusCount = result;
		},
	},
	actions: {
		async getCommonEnumList({ commit }) {
			const Enum = { initEnumData: ['reviewType', 'reviewDeliveryType'] };
			let result = await commonCodeEnumList(Enum);
			if (result) {
				commit('setCommonEnumList', result.data.data);
			}
		},
		async insertMallReview({ commit }, data) {
			try {
				const result = await insertMallReview(data);
				if (result && result.data.resultCode === '0000') {
					alert('저장되었습니다.');
					return true;
				} else if(result.data.resultCode === '4300') {
					alert(`URL 주소가 올바르지 않습니다.`);
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallReviewList({ commit }, data) {
			try {
				commit('setMallReviewListInit');
				const result = await getMallReviewList(data);
				if (result) {
					commit('setMallReviewList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallReviewStatusCount({ commit }, data) {
			try {
				commit('setMallReviewStatusCountInit');
				const result = await getMallReviewStatusCount(data);
				if (result) {
					commit('setMallReviewStatusCount', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getProcReviewEventId({ commit }) {
			try {
				const result = await getProcReviewEventId();
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallReviewDetail({ commit }, data) {
			try {
				const result = await getMallReviewDetail(data);
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallReview({ commit }, data) {
			try {
				const result = await updateMallReview(data);
				if (result && result.data.resultCode === '0000') {
					alert('수정되었습니다.');
					return true;
				} else if(result.data.resultCode === '4300') {
					alert(`URL 주소가 올바르지 않습니다.`);
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default MallReviewMgmtModule;
