import { instance, instanceNS } from "../../index.js";
import { jsonToQueryString } from "../../../common/common";

const domain = "/NotiManagement/NotiMgmt/";

// 알림 리스트 조회
const getNotiList = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instanceNS.get(`${domain}getNotiList${param}`);
  return response;
};

// 알림 카테고리 조회
const getNotiCategoryList = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instance.get(`${domain}getNotiCategoryList${param}`);
  return response;
};

// 읽지 않은 알림 카운트를 조회
const getNotiRnbCnt = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instanceNS.get(`${domain}getNoReadCount${param}`);
  return response;
};

// 알림 설정 조회
const getNotiSettingDetail = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instance.get(`${domain}getNotiSettingDetail${param}`);
  return response;
};

//읽지 않은 알림 카운트 조회
const getGroupNoReadCount = async (data) => {
  let param = "";
  if (data) param = jsonToQueryString(data);
  const response = await instance.get(`${domain}getGroupNoReadCount${param}`);
  return response;
};

// 알림 설정 등록
const insertNotiSetting = async (data) => {
  const response = await instance.post(`${domain}insertNotiSetting`, data);
  return response;
};

// 알림 상태 업데이트
const updateNotiReceiver = async (data) => {
  const response = await instance.post(`${domain}updateNotiReceiver`, data);
  return response;
};

// 알림 숨김 업데이트
const hideNotiReceiver = async (data) => {
  const response = await instance.post(`${domain}hideNotiReceiver`, data);
  return response;
};

export {
  getNotiList,
  getNotiSettingDetail,
  getNotiCategoryList,
  getGroupNoReadCount,
  getNotiRnbCnt,
  insertNotiSetting,
  updateNotiReceiver,
  hideNotiReceiver,
};
