import Vue from 'vue';
import Vuex from 'vuex';

import {
	insertMallTerms,
	insertStoresTerms,
	getMallTermsList,
	getMallTermsHistoryList,
	getMallTermsDetail,
	getStoresTermsList,
	getStoresTermsHistoryList,
	getStoresTermsDetail,
} from "../../../api/mall_mgmt/MallTermsMgmtApi";
import {commonCodeEnumList } from "@/api/common/common";

Vue.use(Vuex);

const MallProductMgmtModule = {
	namespaced: true,
	state: {
		filterData: {},
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		termsList: [],
		termsHistoryList: [],
		mallTermsTypeList: [],
		salesTermsTypeList: [],
		termsTarget: 'MALL',
		termsDetailData: null,
	},
	mutations: {
		setTermsTypeList(state, result) {
			state.mallTermsTypeList = result.mallJoinTermsType || [];
			state.salesTermsTypeList = result.storesTermsType || [];
		},
		setTermsListInit(state) {
			state.termsList = [];
		},
		setTermsList(state, result) {
			state.termsList = result;
		},
		setTermsHistoryListInit(state) {
			state.termsHistoryList = [];
		},
		setTermsHistoryList(state, result) {
			state.termsHistoryList = result;
		},
	},
	actions: {
		async insertMallTerms({ commit }, data) {
			try {
				const result = await insertMallTerms(data);
				if (result && result.data.resultCode === '0000') {
					alert('저장되었습니다.');
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertStoresTerms({ commit }, data) {
			try {
				const result = await insertStoresTerms(data);
				if (result && result.data.resultCode === '0000') {
					alert('저장되었습니다.');
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getTermsTypeList({ commit }) {
			const Enum = { initEnumData: ['storesTermsType', 'mallJoinTermsType'] };
			let result = await commonCodeEnumList(Enum);
			if (result) {
				commit('setTermsTypeList', result.data.data);
			}
		},
		async getMallTermsList({ commit }, data) {
			try {
				commit('setTermsListInit');
				const result = await getMallTermsList(data);
				if (result) {
					commit('setTermsList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallTermsHistoryList({ commit }, data) {
			try {
				commit('setTermsHistoryListInit');
				const result = await getMallTermsHistoryList(data);
				if (result) {
					commit('setTermsHistoryList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallTermsDetail({ commit }, data) {
			try {
				const result = await getMallTermsDetail(data);
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoresTermsList({ commit }, data) {
			try {
				commit('setTermsListInit');
				const result = await getStoresTermsList(data);
				if (result) {
					commit('setTermsList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoresTermsHistoryList({ commit }, data) {
			try {
				commit('setTermsHistoryListInit');
				const result = await getStoresTermsHistoryList(data);
				if (result) {
					commit('setTermsHistoryList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoresTermsDetail({ commit }, data) {
			try {
				const result = await getStoresTermsDetail(data);
				if (result) {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default MallProductMgmtModule;
