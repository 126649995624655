<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" content-class="vDialogHeight" width="1400" id="calcModal">
				<v-card class="borderRadiReset">
					<v-card-title
							class="headline backColorBlue3 disFx justify-lg-space-between"
							style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">요금 계산기</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad10">
						<div class="disFX w100P justify-center">
							<calculator-data-section></calculator-data-section>
							<calculator-save-section></calculator-save-section>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import CalculatorDataSection from './CalculatorDataSection.vue';
import CalculatorSaveSection from './CalculatorSaveSection.vue';

export default {
	name: 'ChargeCalculatorModal',
	components: { CalculatorDataSection, CalculatorSaveSection },
	computed: {
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.headerCalcDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.headerCalcDialog = newValue;
			},
		},
	}
};
</script>

<style>
.vDialogHeight {
	max-height: 98% !important;
}
</style>
