<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="700">
				<v-card
					class="borderRadiReset consultApplication"
					style="background: rgb(246, 246, 246)"
				>
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">입점신청 심사</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>
					<v-card-text>
						<div v-if="storeDomainData" style="padding: 20px 0 20px 20px">
							<table class="w100P lh32">
								<tr>
									<td class="w120 padW10 mainBlack">신청일</td>
									<td>
										<div
											class="borderContColor3 w90P borderRadi3Px padW10 mainBlack h32"
										>
											<template v-if="storeDomainData.applicationDatetime">
												{{
													storeDomainData.applicationDatetime
														| moment('YYYY-MM-DD HH:mm:ss')
												}}
											</template>
										</div>
										<!--<input
											type="text"
											class="borderContColor3 w90P borderRadi3Px padW10"
											v-model.trim="storeDomainData.updDatetime"
										/>-->
									</td>
									<td class="w120 padW10 mainBlack">사업자명</td>
									<td>
										<input
											type="text"
											class="borderContColor3 w90P borderRadi3Px padW10 cur_d"
											v-model.trim="storeDomainData.storeName"
											readonly
										/>
									</td>
								</tr>
								<tr>
									<td class="w120 padW10 mainBlack">온라인몰 명칭</td>
									<td>
										<input
											type="text"
											class="borderContColor3 w90P borderRadi3Px padW10 cur_d"
											v-model.trim="storeDomainData.mallTitle"
											readonly
										/>
									</td>
									<td class="w120 padW10 mainBlack">신청인</td>
									<td>
										<input
											type="text"
											class="borderContColor3 w90P borderRadi3Px padW10 cur_d"
											v-model.trim="storeDomainData.requestMemberName"
											readonly
										/>
									</td>
								</tr>
								<tr>
									<td></td>
									<td></td>
									<td class="w120 padW10 mainBlack">심사결과</td>
									<td>
										<select
											class="w90P borderContColor3 borderRadi3Px padW10"
											v-model.trim="storeDomainData.reviewStatusType"
											:disabled="st !== '22f353197e9b0c1cb58a11da8de7776a'"
										>
											<option
												v-for="(item, index) in reviewStatusTypeitems"
												:value="item.value"
												:key="index"
												:class="reviewStatusColor(item.value)"
											>
												{{ item.name }}
											</option>
										</select>
									</td>
								</tr>
							</table>
							<textarea
								v-if="storeDomainData.reviewStatusType === 'REJECT'"
								class="borderContColor3 mt10 narrowScroll1"
								maxlength="100"
								style="
									width: calc(100% - 20px);
									border-radius: 3px;
									padding: 2px 10px;
									resize: none;
									height: 140px;
									line-height: 18px;
								"
								v-model.trim="storeDomainData.rejectReason"
								placeholder="반려사유"
								:readonly="st !== '22f353197e9b0c1cb58a11da8de7776a'"
							></textarea>
							<div class="mt20 disFx justify-end mr20">
								<v-btn
									class="backColorMint1 mainWhite borderReset w80"
									@click="reviewStoreDomain(storeDomainData)"
									v-if="st === '22f353197e9b0c1cb58a11da8de7776a'"
								>
									<!--관리자권한-->
									저장
								</v-btn>
								<v-btn
									class="backColorMint1 mainWhite borderReset w80"
									@click="reStoreFnc"
									v-if="
										(storeDomainData.reviewStatusType === 'REJECT' &&
											st !== '22f353197e9b0c1cb58a11da8de7776a') ||
										st !== '22f353197e9b0c1cb58a11da8de7776a'
									"
								>
									<!--영업점권한-->
									재신청
								</v-btn>
								<v-btn
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
									@click="dialog = false"
								>
									닫기
								</v-btn>
							</div>
							<template
								v-if="
									rejectHistoryList.length > 0 &&
									st === '22f353197e9b0c1cb58a11da8de7776a'
								"
							>
								<h2 class="mainFontS boldWt mainBlack mt20 mb10">
									반려이력 정보
								</h2>
								<div class="newTableStyle">
									<table style="width: calc(100% - 20px)">
										<tr>
											<th class="w30P cur_d">반려일</th>
											<th class="cur_d">반려내용</th>
										</tr>
										<tr v-for="(item, index) in rejectHistoryList" :key="index">
											<td class="cur_d">
												<template v-if="item.applicationDatetime">
													{{
														item.applicationDatetime
															| moment('YYYY-MM-DD HH:mm:ss')
													}}
												</template>
											</td>
											<td class="cur_d">
												{{ item.rejectReason ? item.rejectReason : '-' }}
											</td>
										</tr>
									</table>
								</div>
							</template>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	props: ['storeDomainData'],
	name: 'MallAuditPop',
	computed: {
		st() {
			return this.$store.state.cookies.st;
		},
		dialog: {
			get() {
				return this.$store.state.MallDomainMgmtModule.mallAuditDialog;
			},
			set(newValue) {
				this.$store.state.MallDomainMgmtModule.mallAuditDialog = newValue;
			},
		},
		rejectHistoryList: {
			get() {
				return this.$store.state.MallDomainMgmtModule.rejectHistoryList;
			},
			set(newValue) {
				this.$store.state.MallDomainMgmtModule.rejectHistoryList = newValue;
			},
		},
	},
	data: () => ({
		reviewStatusTypeitems: [
			{ name: '진행중', value: 'PROGRESS' },
			{ name: '승인', value: 'ACCEPT' },
			{ name: '반려', value: 'REJECT' },
		],
	}),
	methods: {
		reStoreFnc() {
			this.dialog = false;
			if (location.pathname === '/mall-import') {
				window.location.replace(window.location.href);
			} else {
				this.$router.push({ name: 'mall-import' });
			}
		},
		async reviewStoreDomain(data) {
			if (data.reviewStatusType === 'REJECT') {
				if (!data.rejectReason) {
					return alert('반려사유를 입력해 주세요');
				}
			} else {
				data.rejectReason = null;
			}
			if (confirm('저장하시겠습니까?')) {
				const result = await this.$store.dispatch(
					'MallDomainMgmtModule/reviewStoreDomain',
					data,
				);
				if (result) {
					if (result.data.resultMsg) {
						return alert(`${result.data.resultMsg}`);
					} else {
						alert('저장되었습니다');
					}
					await this.getStoreDomainList();
					this.dialog = false;
				}
			}
		},
		async getStoreDomainList(data) {
			await this.$store.dispatch(
				'MallDomainMgmtModule/getStoreDomainList',
				data,
			);
		},
		async getStoreDomainHistory(storeDomainId) {
			let param = {
				storeDomainId: storeDomainId,
			};
			await this.$store.dispatch(
				'MallDomainMgmtModule/getStoreDomainHistory',
				param,
			);
		},
		reviewStatusColor(item) {
			if (item === 'REQUEST') {
				return 'mainMint1';
			} else if (item === 'PROGRESS') {
				return 'mainGreen1';
			} else if (item === 'ACCEPT') {
				return 'mainBlue2';
			} else if (item === 'REJECT') {
				return 'mainRed';
			}
		},
	},
	async mounted() {
		if (this.storeDomainData) {
			await this.getStoreDomainHistory(this.storeDomainData.storeDomainId);
		}
	},
};
</script>

<style scoped></style>
