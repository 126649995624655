<template>
	<div>
		<div class="text-center">
			<v-dialog v-model.trim="dialog"
								:width="processLevel.faultyCancel === 'VIEW' || processLevel.retractyCancel === 'VIEW' ? '25%' : '630'"
								@keydown.esc="exchangeFaultyCancel">
				<v-card class="borderRadiReset infoPopup" v-click-outside="exchangeFaultyCancel">
					<v-card-title
							class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">{{ workStatusData.value === 'EXCHANGE_RCPT_CONSULT' ? '교품접수 신청' : workStatusData.value === 'FAULTY_CANCEL' ? '불량취소 신청' : '철회요청 신청'}}</h2>
						<span class="closeBtn1 cur_p" @click="exchangeFaultyCancel"></span>
					</v-card-title>

					<v-card-text class="">
						<div class="new_popTable1 font-size-15 font-black">
							<div class="" v-if="processLevel.exchangeCancel === 'VIEW'">
								<span class="lh30 boldWt">
									교품접수 시  [<span class="mainMint1">교품접수(<span class="redfont">배송</span>)</span>] / [<span class="mainMint1">교품접수(<span class="redfont">반품</span>)</span>] 신청서가 자동 생성됩니다.
								</span>
								<span class=" ml10 mb20">
									<div class="radioStylePop ml20 mt15">
										<input
												type="radio"
												id="exchangeFaulty2"
												value="N"
												name="exchangeFaulty"
												:disabled="formData.basic.openingDate === null
												 || formData.basic.openingDate === undefined"
												v-model.trim="retractYn"
										/>
										<label for="exchangeFaulty2">
											<span class="mainBlue1 boldWt">교품 : 불량판정서 발급 후 AS기변</span>
										</label>
									</div>
									<div class="radioStylePop ml20"
											 v-if="formData.basic.openingDate !== null && formData.basic.openingDate !== undefined">
										<input
												type="radio"
												id="exchangeFaulty1"
												value="Y"
												name="exchangeFaulty"
												:disabled="formData.basic.openingDate === null
												 || formData.basic.openingDate === undefined"
												v-model.trim="retractYn"
										/>
										<label for="exchangeFaulty1">
											<span class="redfont boldWt">철회요청/불량취소</span>
										</label>
									</div>
								</span>
								<div class="lh30 boldWt mainBlue1 mt15" v-if="retractYn === 'N'">
									[<span class="mainMint1">교품접수(반품)</span>]는 [<span class="mainMint1">교품<span class="redfont">요청</span>(반품)</span>]으로 반드시 변경해야 합니다.
								</div>
							</div>


							<div class="disFX justify-lg-space-between padH5W10 boldWt"
									 v-if="retractYn === 'Y'">
								<div class="w50P h147" style="border: 1px solid #000; padding:10px;">
									<span class="redfont boldWt">철회요청</span> 사유를 선택해주세요.
									<div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="cancelTypeSample"
													value="SIMPLE_CHANGE_MIND"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="cancelTypeSample">단순 변심</label>
										</div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="cancelTypeETC"
													value="ETC"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="cancelTypeETC">기타</label>
											<input
													:disabled="reasonType !== 'ETC'"
													class="borderRadi3Px borderContColor2 w160 ml10 h36 padW10"
													type="text"
													v-model.trim="retractReasonDesc"
													maxlength="50"
											/>
										</div>
									</div>
								</div>
								<div class="w50P mb20 h147" style="border: 1px solid #000; padding:10px;border-left: 0px;">
									<span class="redfont boldWt">불량취소</span> 사유를 선택해주세요.
									<div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="cancelTypeDvcFaul"
													value="DVC_FAULTY"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="cancelTypeDvcFaul">기기불량</label>
										</div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="cancelTypeTelQualFaul"
													value="CALL_QUALITY_FAULTY"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="cancelTypeTelQualFaul">통화품질 불량</label>
										</div>

										<div class="radioStylePop ml20 ">
											<input
													type="radio"
													id="cancelTypeETC2"
													value="ETC2"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="cancelTypeETC2">기타</label>
											<input
													:disabled="reasonType !== 'ETC2'"
													class="borderRadi3Px borderContColor2 w160 ml10 h36 padW10"
													type="text"
													v-model.trim="faultReasonDesc"
													maxlength="50"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="" v-if="processLevel.faultyCancel === 'VIEW'">
								<div class="mb20 h147" style="border: 1px solid #000; padding:10px;">
									<span class="redfont boldWt">불량취소</span> 사유를 선택해주세요.
									<div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="faultyTypeDvcFaul"
													value="DVC_FAULTY"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="faultyTypeDvcFaul">기기불량</label>
										</div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="faultyTypeTelQualFaul"
													value="CALL_QUALITY_FAULTY"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="faultyTypeTelQualFaul">통화품질 불량</label>
										</div>

										<div class="radioStylePop ml20 ">
											<input
													type="radio"
													id="faultyTypeETC"
													value="ETC2"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="faultyTypeETC">기타</label>
											<input
													:disabled="reasonType !== 'ETC2'"
													class="borderRadi3Px borderContColor2 w160 ml10 h36 padW10"
													type="text"
													v-model.trim="faultReasonDesc"
													maxlength="50"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="" v-if="processLevel.retractyCancel === 'VIEW'">
								<div class="mb20 h147" style="border: 1px solid #000; padding:10px;">
									<span class="redfont boldWt">철회요청</span> 사유를 선택해주세요.
									<div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="retractyTypeSample"
													value="SIMPLE_CHANGE_MIND"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="retractyTypeSample">단순 변심</label>
										</div>
										<div class="radioStylePop ml20">
											<input
													type="radio"
													id="retractyTypeETC"
													value="ETC"
													name="cancelType"
													v-model.trim="reasonType"
											/>
											<label for="retractyTypeETC">기타</label>
											<input
													:disabled="reasonType !== 'ETC'"
													class="borderRadi3Px borderContColor2 w160 ml10 h36 padW10"
													type="text"
													v-model.trim="retractReasonDesc"
													maxlength="50"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="disFx justify-end mt10">
								<v-btn
										class="backColorMint1 mainWhite borderReset w80"
										text
										outlined
										@click="exchangeFaultySubmit"
								>
									<template v-if="retractYn === 'N'">
										교품접수
									</template>
									<template v-else-if="retractYn === 'Y' && (this.reasonType === 'ETC' || this.reasonType === 'SIMPLE_CHANGE_MIND')">
										철회요청
									</template>
									<template v-else-if="retractYn === 'Y'
									&& (this.reasonType === 'ETC2' || this.reasonType === 'DVC_FAULTY'
									 || this.reasonType === 'CALL_QUALITY_FAULTY')">
										불량취소
									</template>
									<template v-else-if="retractYn === 'Y'">
										확인
									</template>
									<template v-else>
										{{ workStatusData.value === 'EXCHANGE_RCPT_CONSULT' ? '교품접수' : workStatusData.value === 'FAULTY_CANCEL' ? '불량취소' : '철회요청' }}
									</template>
								</v-btn>
								<v-btn
										class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
										text
										@click="exchangeFaultyCancel"
										outlined
								>
									취소
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import bus from '@/common/bus';

export default {
	name: 'ExchangeFaultyPop',
	props: {
		workStatusData: {type: Object, required: true},
		formData: {type: Object, required: true},
	},
	data: () => ({
		templateTitle: '',
		processLevel: {
			exchangeCancel: 'NONE', // 신청서 철회
			faultyCancel: 'NONE', // 불량취소
			retractyCancel: 'NONE', // 철회요청
		},
		retractYn: null,
		reasonType: null,
		faultyReasonType: null,
		faultReasonDesc: null,
		retractReasonType: null,
		retractReasonDesc: null,
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.excFauDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.excFauDialog = newValue;
			},
		},
	},
	methods: {
		async exchangeFaultySubmit() {
			if(this.workStatusData.value === 'EXCHANGE_RCPT_CONSULT') {
				if(!this.retractYn || this.retractYn === '') {
					alert('교품방법을 선택해주세요.');
					return false;
				} else if (this.retractYn === 'Y' && this.reasonType === null) {
					alert('철회요청 또는 불량취소 사유를 선택해주세요.');
					return false;
				}
			} else if (this.workStatusData.value === 'FAULTY_CANCEL') {
				if (this.reasonType === null) {
					alert('불량취소 사유를 선택해주세요.');
					return false;
				}
			} else if (this.workStatusData.value === 'RETRACT_DMND_CONSULT') {
				if (this.reasonType === null) {
					alert('철회요청 사유를 선택해주세요.');
					return false;
				}
			}

			this.retractReasonType = null;
			this.faultyReasonType = null;
			if(this.reasonType !== null && (this.reasonType === 'ETC' || this.reasonType === 'SIMPLE_CHANGE_MIND')) {
				this.retractReasonType = this.reasonType;
			} else {
				if(this.reasonType === 'ETC2') {
					this.faultyReasonType = 'ETC';
				} else {
					this.faultyReasonType = this.reasonType;
				}
			}

			delete this.formData.basic.retractYn;
			delete this.formData.basic.retractReasonType;
			delete this.formData.basic.retractReasonDesc;
			delete this.formData.basic.faultYn;
			delete this.formData.basic.faultyReasonType;
			delete this.formData.basic.faultReasonDesc;

			if(this.retractYn === 'N') {
				this.formData.basic.retractYn = this.retractYn;
			} else if (this.retractYn === 'Y' && this.retractReasonType !== null) {
				this.formData.basic.retractYn = this.retractYn;
				this.formData.basic.retractReasonType = this.retractReasonType;
				this.formData.basic.retractReasonDesc = this.retractReasonDesc;
			} else if (this.retractYn === 'Y' && this.faultyReasonType !== null) {
				this.formData.basic.faultYn = 'Y';
				this.formData.basic.faultyReasonType = this.faultyReasonType;
				this.formData.basic.faultReasonDesc = this.faultReasonDesc;
			} else if (this.workStatusData.value === 'FAULTY_CANCEL' && this.faultyReasonType !== null) {
				this.formData.basic.faultYn = 'Y';
				this.formData.basic.faultyReasonType = this.faultyReasonType;
				this.formData.basic.faultReasonDesc = this.faultReasonDesc;
			} else if (this.workStatusData.value === 'RETRACT_DMND_CONSULT' && this.retractReasonType !== null) {
				this.formData.basic.retractYn = 'Y';
				this.formData.basic.retractReasonType = this.retractReasonType;
				this.formData.basic.retractReasonDesc = this.retractReasonDesc;
			}
			this.$emit('updateFalseFnc', this.workStatusData);
			// this.dialog = false
		},
		async exchangeFaultyCancel() {
			this.formData.basic[this.workStatusData.name] = this.workStatusData.beforeValue;
			this.dialog = false;
		},
	},
	async created() {
		this.processLevel = {
			exchangeCancel: 'NONE', // 신청서 철회
			faultyCancel: 'NONE', // 불량취소
			retractyCancel: 'NONE', // 철회요청
		}
		// FAULTY_CANCEL
		// EXCHANGE_RCPT_CONSULT
		if (this.workStatusData.value === 'EXCHANGE_RCPT_CONSULT') {
			if (this.formData.basic.openingDate === null
					|| this.formData.basic.openingDate === undefined) {
				this.retractYn = 'N';
			}
			this.processLevel.exchangeCancel = 'VIEW';
		} else if (this.workStatusData.value === 'FAULTY_CANCEL') {
			this.processLevel.faultyCancel = 'VIEW';
		} else if (this.workStatusData.value === 'RETRACT_DMND_CONSULT') {
			this.processLevel.retractyCancel = 'VIEW';
		}
	},
	watch: {
		reasonType() {
			this.retractReasonDesc = null;
			this.faultReasonDesc = null;
		},
		retractYn() {
			this.reasonType = null;
			this.retractReasonDesc = null;
			this.faultReasonDesc = null;
		},
	}
};
</script>

<style scoped></style>
