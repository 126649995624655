<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="820">
				<v-card class="borderRadiReset" style="display: inline-block">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt">민원요청</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>

					<v-card-text class="pad20 complainRegiPop posR">
						<h2 class="boldWt mainBlack2 mb10">
							민원요청 <span class="redfont">*</span>
						</h2>
						<div
							style="z-index: 2; position: absolute; top: 16px; right: 20px"
							class="checkStyleCont10"
						>
							<input
								type="checkbox"
								class="checkStyle"
								v-model="formData.complainDto.emergencyYn"
								id="emergency"
								:true-value="'Y'"
								:false-value="'N'"
							/>
							<label
								class="font-btn-color ml-2 cur_p font-size-13"
								for="emergency"
								>긴급요청</label
							>
						</div>
						<div class="new_popTable1">
							<table class="w100P complainTable">
								<tr>
									<th>민원 등록인</th>
									<td>
										<input
											type="text"
											style="border-color: #e0e0e0"
											v-model.trim="formData.complainDto.regiName"
										/>
									</td>
									<th>민원 등록일자</th>
									<td>
										<v-menu
											v-model.trim="menu1"
											:close-on-content-click="false"
											:nudge-left="40"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													outlined
													@click:append="on.click"
													append-icon="mdi-calendar"
													v-model.trim="formData.complainDto.regiDate"
													readonly
													v-bind="attrs"
													v-on="on"
													class="datePickerInput input_custom1"
												></v-text-field>
											</template>
											<v-date-picker
												@input="menu1 = false"
												v-model.trim="formData.complainDto.regiDate"
												:max="
													new Date(
														Date.now() - new Date().getTimezoneOffset() * 60000,
													)
														.toISOString()
														.substr(0, 10)
												"
												locale="ko-KR"
											></v-date-picker>
										</v-menu>
									</td>
								</tr>
								<tr>
									<th>민원 영업점</th>
									<td>
										<v-select
											outlined
											:items="saleStoreList"
											item-text="storeName"
											item-value="storeId"
											:attach="true"
											v-model.trim="formData.complainDto.saleStoreId"
											@input="initOnChange('saleStoreId')"
										>
										</v-select>
									</td>
									<th>민원 개통점</th>
									<td>
										<v-select
											outlined
											:items="openingStoreList"
											item-text="openStoreName"
											item-value="openStoreId"
											v-model.trim="selectedOpeningStore"
											return-object
											:attach="true"
											@click="checkStoreId"
											@input="setParentHierarchy"
										>
										</v-select>
									</td>
								</tr>
								<tr v-if="storeVal === 'StoreGrade_M'">
									<th>민원 경로</th>
									<td colspan="3">
										<input
											type="radio"
											id="rPathEmail"
											v-model="formData.complainDto.complainPath"
											value="EMAIL"
											name="compPath"
										/><label for="rPathEmail"><em></em>메일</label>
										<input
											type="radio"
											id="rPathPhone"
											v-model="formData.complainDto.complainPath"
											value="PHONE"
											name="compPath"
										/><label for="rPathPhone"><em></em>전화</label>
										<input
											type="radio"
											id="rPathKaKao"
											v-model="formData.complainDto.complainPath"
											value="KAKAO_TALK"
											name="compPath"
										/><label for="rPathKaKao"><em></em>카카오톡</label>
										<input
											type="radio"
											id="rPathNetwork"
											v-model="formData.complainDto.complainPath"
											value="NETWORK"
											name="compPath"
										/><label for="rPathNetwork"><em></em>전산</label>
										<input
											type="radio"
											id="rPathFmail"
											v-model="formData.complainDto.complainPath"
											value="FRIENDS_MAIL"
											name="compPath"
										/><label for="rPathFmail"><em></em>프렌즈메일</label>
									</td>
								</tr>
								<tr>
									<th>민원 종류</th>
									<td colspan="3">
										<input
											type="radio"
											id="foreign3"
											v-model="formData.complainDto.complainType"
											value="FOREIGN_COMPLAIN"
											name="vocType"
											@click="getComplainTemplateRecipList('FOREIGN_COMPLAIN')"
										/><label for="foreign3"><em></em>대외민원</label>
										<input
											type="radio"
											id="voice3"
											v-model="formData.complainDto.complainType"
											value="VOICE_COMPLAIN"
											name="vocType"
											@click="getComplainTemplateRecipList('VOICE_COMPLAIN')"
										/><label for="voice3"><em></em>VOC</label>
										<input
											type="radio"
											id="SR3"
											v-model="formData.complainDto.complainType"
											value="SR_COMPLAIN"
											name="vocType"
											@click="initStatusRelatedData('SR_COMPLAIN')"
										/><label for="SR3"><em></em>일반SR</label>
										<input
											type="radio"
											id="CALL3"
											v-model="formData.complainDto.complainType"
											value="CALL_COMPLAIN"
											name="vocType"
											@click="initStatusRelatedData('CALL_COMPLAIN')"
										/><label for="CALL3"><em></em>통화요청</label>
									</td>
								</tr>
								<tr>
									<th>민원 시작일시</th>
									<td>
										<div>
											<span
												style="
													display: inline-block;
													margin-right: 4px;
													width: 130px;
												"
											>
												<v-menu
													v-model.trim="menu2"
													:close-on-content-click="false"
													:nudge-right="40"
													transition="scale-transition"
													offset-y
													:attach="true"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															outlined
															v-model.trim="
																formData.complainDto.complainStartDate
															"
															v-bind="attrs"
															v-on="on"
															@click:append="on.click"
															@change="changeHndlgDate"
															append-icon="mdi-calendar"
															readonly
															class="w130"
														></v-text-field>
													</template>
													<v-date-picker
														v-model.trim="
															formData.complainDto.complainStartDate
														"
														@input="menu2 = false"
														@change="changeHndlgDate"
														locale="ko-KR"
													></v-date-picker>
												</v-menu>
											</span>
											<span>
												<vue-timepicker
													close-on-complete
													v-model.trim="formData.complainDto.complainStartTime"
													@change="changeHndlgTime"
												></vue-timepicker>
											</span>
										</div>
									</td>
									<th>민원 처리기한</th>
									<td>
										<span
											style="
												display: inline-block;
												margin-right: 4px;
												width: 140px;
											"
										>
											<v-menu
												v-model.trim="menu3"
												:close-on-content-click="false"
												:nudge-left="40"
												transition="scale-transition"
												offset-y
												:attach="true"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														outlined
														v-model.trim="
															formData.complainDto.complainHandlingEndDate
														"
														v-bind="attrs"
														v-on="on"
														@click:append="on.click"
														append-icon="mdi-calendar"
														readonly
														class="w130"
														:disabled="storeVal === 'StoreGrade_S'"
													></v-text-field>
												</template>
												<v-date-picker
													v-model.trim="
														formData.complainDto.complainHandlingEndDate
													"
													@input="menu3 = false"
													locale="ko-KR"
													:allowed-dates="getAllowedDates"
												></v-date-picker>
											</v-menu>
										</span>
										<span>
											<vue-timepicker
												close-on-complete
												v-model.trim="
													formData.complainDto.complainHandlingEndTime
												"
												:disabled="storeVal === 'StoreGrade_S'"
											></vue-timepicker>
										</span>
									</td>
								</tr>
							</table>
						</div>

						<h2 class="boldWt mainBlack2 mt20 mb10">민원고객</h2>
						<div class="new_popTable1">
							<table class="w100P complainTable">
								<tr>
									<th>고객명(법인명)<span class="redfont">*</span></th>
									<td>
										<input
											type="text"
											v-model.trim="formData.complainDto.cusName"
										/>
									</td>
									<th class="lh18">
										주민등록번호<span class="redfont">*</span><br />(앞 6자리)
									</th>
									<td>
										<input
											type="text"
											v-model="complainDto.cusBirthDate"
											oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
											maxlength="6"
										/>
									</td>
									<!-- 생년월일 => 주민등록번호 앞6자리로 변경 -->
									<!--									<th>생년월일<span class="redfont">*</span></th>
									<td>
										<v-menu
											v-model.trim="menu4"
											:close-on-content-click="false"
											:nudge-left="40"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													outlined
													@click:append="on.click"
													append-icon="mdi-calendar"
													v-model.trim="formData.complainDto.cusBirthDate"
													readonly
													v-bind="attrs"
													v-on="on"
													class="datePickerInput input_custom1"
												></v-text-field>
											</template>
											<v-date-picker
												@input="menu4 = false"
												v-model.trim="formData.complainDto.cusBirthDate"
												:max="
													new Date(
														Date.now() - new Date().getTimezoneOffset() * 60000,
													)
														.toISOString()
														.substr(0, 10)
												"
												locale="ko-KR"
											></v-date-picker>
										</v-menu>
									</td>-->
								</tr>
								<tr>
									<th>개통 휴대폰번호<span class="redfont">*</span></th>
									<td>
										<input
											type="text"
											v-model="formData.complainDto.openingPhone"
											oninput="this.value = this.value.replace(/[^0-9]/g, '');"
											@input="
												phoneNumSplitFnc(
													$event.target.value,
													'openingPhone',
													'complainDto',
												)
											"
										/>
									</td>
									<th>개통일자</th>
									<td>
										<v-menu
											v-model.trim="menu5"
											:close-on-content-click="false"
											:nudge-right="40"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													outlined
													@click:append="on.click"
													append-icon="mdi-calendar"
													v-model.trim="formData.complainDto.openingDate"
													readonly
													v-bind="attrs"
													v-on="on"
													class="datePickerInput input_custom1"
												></v-text-field>
											</template>
											<v-date-picker
												@input="menu5 = false"
												v-model.trim="formData.complainDto.openingDate"
												:max="
													new Date(
														Date.now() - new Date().getTimezoneOffset() * 60000,
													)
														.toISOString()
														.substr(0, 10)
												"
												locale="ko-KR"
											></v-date-picker>
										</v-menu>
									</td>
								</tr>
							</table>
						</div>

						<h2 class="boldWt mainBlack2 mt20 mb10">민원 내용</h2>
						<div class="new_popTable1">
							<table class="w100P complainTable">
								<tr>
									<th>민원 내용<span class="redfont">*</span></th>
									<td>
										<textarea
											class="narrowScroll"
											style="width: calc(100% - 40px)"
											v-model.trim="formData.complainDetailDto.complainContents"
										></textarea>
									</td>
								</tr>
								<tr>
									<th>민원 답변</th>
									<td>
										<div
											class="w90P tempSelect posRmt5"
											style="width: calc(100% - 40px)"
										>
											<v-select
												:disabled="
													this.formData.complainDto.complainType ===
														'SR_COMPLAIN' ||
													this.formData.complainDto.complainType ===
														'CALL_COMPLAIN'
												"
												label="템플릿선택"
												no-data-text="등록된 템플릿이 없습니다."
												outlined
												:items="templateList"
												item-text="templateTitle"
												item-value="complainTemplateId"
												return-object
												v-model.trim="selectedTemplate"
												class="floatL"
												@change="setTemplateData()"
											>
											</v-select>
											<button
												class="boldWt borderRadi3Px w60 lh30 mainWhite ml10"
												:class="{
													backColorMint1: selectedTemplate !== null,
													backColorGray1: selectedTemplate === null,
												}"
												@click="
													selectedTemplate = null;
													formData.complainDetailDto.complainAnswer = '';
												"
											>
												선택 취소
											</button>
											<button
												class="backColorBlue3 borderRadi3Px w100 lh30 mainWhite ml10 floatR"
												@click="openDialogFnc('templateMgmtPop')"
											>
												템플릿관리
											</button>
										</div>
										<textarea
											class="narrowScroll backColorGray4"
											style="width: calc(100% - 40px)"
											readonly
											v-model.trim="formData.complainDetailDto.complainAnswer"
										></textarea>
									</td>
								</tr>
								<tr>
									<th>첨부파일</th>
									<td>
										<FileUpload3
											fileInputId="fileInput2"
											@fileValueFnc="fileValueFnc"
										></FileUpload3>
									</td>
								</tr>
							</table>
						</div>

						<div class="buttonCont">
							<button class="backColorMint1" @click="submit">등록</button>
							<button class="backColorGray1" @click="dialog = false">
								닫기
							</button>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
		<template-mgmt-pop
			v-if="templateMgmtPop"
			:popComplainType="formData.complainDto.complainType"
			@getComplainTemplateRecipList="getComplainTemplateRecipList"
			@closeDialog="closeDialog"
		></template-mgmt-pop>
	</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker';
import FileUpload3 from '../../../../components/FileUpload3';
import numberSplit from '../../../../common/numberSplit';
import {
	nullValidation2,
	getToday,
	getTime,
	getBirthData,
	getDateFormat,
	getTimeFormat,
} from '../../../../common/common';
import TemplateMgmtPop from '../popup/TemplateMgmtPop';

export default {
	name: 'ComplainRegiPop',
	mixins: [numberSplit],
	props: {
		applId: { type: Number, default: 0 },
		loadFromAppl: { type: Boolean, default: false },
		propCustomerData: { type: Object },
		formData: {
			default: () => ({
				complainDetailDto: {
					complainContents: '',
					complainAnswer: '',
					complainTemplateId: 0,
					preComplainHandlingEndDate: '',
					preComplainHandlingEndTime: '',
				},
				complainDto: {
					applId: 0,
					emergencyYn: 'N',
					regiName: '',
					regiDate: '',
					saleStoreId: 0,
					openingStoreId: 0,
					complainPath: 'EMAIL',
					complainType: 'FOREIGN_COMPLAIN',
					complainStartDate: '',
					complainStartTime: '',
					complainHandlingEndDate: '',
					complainHandlingEndTime: '',
					openingStoreProcStatus: 'REQ',
					saleStoreProcStatus: 'REQ',
					cusName: '',
					cusBirthDate: '',
					openingPhone: '',
					openingPhone1: '',
					openingPhone2: '',
					openingPhone3: '',
					openingDate: '',
					parentHierarchy: '',
				},
			}),
		},
	},
	components: {
		FileUpload3,
		VueTimepicker,
		TemplateMgmtPop,
	},
	data: () => ({
		dialog: true,
		menu1: false,
		menu2: false,
		menu3: false,
		menu4: false,
		menu5: false,
		menu6: false,
		fileList: [],
		saleStoreList: [],
		openingStoreList: [],
		templateList: [],
		selectedTemplate: null,
		selectedOpeningStore: {},
		templateMgmtPop: false,
	}),
	computed: {
		storeVal() {
			return this.$store.state.cookies.sg;
		},
		name() {
			return this.$store.state.cookies.name;
		},
		ss() {
			return this.$store.state.cookies.ss;
		},
		filterData() {
			return this.$store.state.ComplainHndlgModule.filterData;
		},
		complainDto: {
			get() {
				return this.formData.complainDto;
			},
			set(newValue) {
				this.formData.complainDto = newValue;
			},
		},
	},
	methods: {
		fileValueFnc(data) {
			this.fileList = data.fileData;
		},
		checkStoreId() {
			if (this.formData.complainDto.saleStoreId === 0) {
				alert('영업점을 선택해주세요');
			}
		},
		initData() {
			this.complainDto.regiName = this.name;
			this.complainDto.regiDate = getToday();
			this.complainDto.complainStartDate = getToday();
			this.complainDto.complainStartTime = getTime();
			this.insertHndlgTime();
		},
		getAllowedDates(val) {
			return (
				val >=
				new Date(this.complainDto.complainStartDate).toISOString().substr(0, 10)
			);
		},
		getTommorowDate(day) {
			let date = new Date(day);
			date.setDate(date.getDate() + 1);
			return getDateFormat(date);
		},
		changeHndlgDate() {
			let hour = this.complainDto.complainStartTime.substring(0, 2);
			if (hour < 16) {
				this.complainDto.complainHandlingEndDate =
					this.complainDto.complainStartDate;
			} else {
				this.complainDto.complainHandlingEndDate = this.getTommorowDate(
					this.complainDto.complainStartDate,
				);
			}
		},
		changeHndlgTime(time) {
			// 민원처리기한 default :
			// 00:00 ~ 05:59 : 오늘 오전 11시
			// 06:00 ~ 15:59 : 현재시간 + 4시간
			// 16:00 ~ 23:59 : 다음날 오전 11시
			let hour = time.data.H;
			if (hour < 16) {
				if (hour < 6) {
					this.complainDto.complainHandlingEndTime = '11:00';
				} else {
					this.complainDto.complainHandlingEndTime = `${parseInt(hour) + 4}:${
						time.data.mm
					}`;
				}
				this.complainDto.complainHandlingEndDate =
					this.complainDto.complainStartDate;
			} else {
				let startDate = new Date(this.complainDto.complainStartDate);
				startDate.setDate(startDate.getDate() + 1);
				this.complainDto.complainHandlingEndDate = getDateFormat(startDate);
				this.complainDto.complainHandlingEndTime = '11:00';
			}
		},
		insertHndlgTime() {
			let date = new Date();
			// 민원처리기한 default :
			// 00:00 ~ 05:59 : 오늘 오전 11시
			// 06:00 ~ 15:59 : 현재시간 + 4시간
			// 16:00 ~ 23:59 : 다음날 오전 11시
			let time = new Date();
			if (time.getHours() < 16) {
				if (time.getHours() < 6) {
					time.setHours(11);
				} else {
					time.setHours(time.getHours() + 4);
				}
			} else {
				time.setDate(time.getDate() + 1);
				time.setHours(11);
			}
			this.complainDto.complainHandlingEndDate = getDateFormat(time);
			this.complainDto.complainHandlingEndTime = getTimeFormat(time);
		},
		initStatusRelatedData(name) {
			if (name === 'SR_COMPLAIN') {
				this.formData.complainDetailDto.complainTemplateId = 0;
				this.selectedTemplate = {};
			}
			if (name === 'CALL_COMPLAIN') {
				this.formData.complainDetailDto.complainTemplateId = 0;
				this.selectedTemplate = {};
			}
		},
		setParentHierarchy() {
			this.formData.complainDto.openingStoreId =
				this.selectedOpeningStore.openStoreId;
			this.formData.complainDto.parentHierarchy =
				this.selectedOpeningStore.parentHierarchy;
		},
		setTemplateData() {
			if (this.formData.complainDetailDto.complainAnswer.length > 0) {
				if (!confirm('기존 민원 답변을 지우시겠습니까?')) {
					return;
				}
			}
			this.formData.complainDetailDto.complainTemplateId =
				this.selectedTemplate.complainTemplateId;
			this.formData.complainDetailDto.complainAnswer =
				this.selectedTemplate.templateContents;
		},
		setCustomerData() {
			// this.formData.complainDto.regiName = this.propCustomerData.applRegiUserIdString; // 12/31이후 신청서의 담당자는 사용 불필요
			this.formData.complainDto.saleStoreId = this.propCustomerData.saleStoreId;
			this.selectedOpeningStore.openStoreId =
				this.propCustomerData.openingStoreId;
			this.formData.complainDto.openingStoreId =
				this.propCustomerData.openingStoreId;
			this.formData.complainDto.parentHierarchy =
				this.propCustomerData.parentHierarchy;
			this.formData.complainDto.cusName = this.propCustomerData.cusName;
			if (this.propCustomerData.bizName) {
				this.formData.complainDto.cusName = this.propCustomerData.bizName;
			}
			this.formData.complainDto.openingPhone =
				this.propCustomerData.openingPhone;
			this.formData.complainDto.openingPhone1 =
				this.propCustomerData.openingPhone1;
			this.formData.complainDto.openingPhone2 =
				this.propCustomerData.openingPhone2;
			this.formData.complainDto.openingPhone3 =
				this.propCustomerData.openingPhone3;
			this.formData.complainDto.openingDate =
				this.propCustomerData.openingDate || '';
			this.formData.complainDto.cusBirthDate =
				this.propCustomerData.cusRegiNum1;
			/*if (
				this.propCustomerData.cusRegiNum1 !== null &&
				this.propCustomerData.cusRegiNum2 !== null
			) {
				this.formData.complainDto.cusBirthDate = getBirthData(
					this.propCustomerData.cusRegiNum1,
					this.propCustomerData.cusRegiNum2,
				);
			}*/
		},
		async getSelectList() {
			let saleStoreList = await this.$store.dispatch(
				'ComplainHndlgModule/getSaleStoreList',
			);
			if (saleStoreList) {
				this.saleStoreList = saleStoreList;
				if (!this.loadFromAppl) {
					this.formData.complainDto.saleStoreId = saleStoreList[0].saleStoreId;
				}
			}
			if (this.loadFromAppl) {
				await this.getOpeningStoreList();
			}
		},
		initOnChange(changedData) {
			if (changedData === 'saleStoreId') {
				this.selectedOpeningStore = {};
				this.complainDto.openingStoreId = 0;
				this.getOpeningStoreList();
			}
		},
		async getOpeningStoreList() {
			let data = {
				storeId: this.formData.complainDto.saleStoreId,
			};
			this.openingStoreList = [];
			if (!this.loadFromAppl) {
				this.formData.complainDto.openingStoreId = 0;
			}
			let openingStoreList = await this.$store.dispatch(
				'ComplainHndlgModule/getOpeningStoreList',
				data,
			);
			if (openingStoreList) {
				this.openingStoreList = openingStoreList;
			}
		},
		validDateChk() {
			if (
				this.complainDto.complainStartTime.indexOf('HH') > -1 ||
				this.complainDto.complainStartTime.indexOf('mm') > -1
			) {
				alert('민원 시작일시를 다시 입력해주세요.');
				return false;
			}
			if (
				this.complainDto.complainHandlingEndTime.indexOf('HH') > -1 ||
				this.complainDto.complainHandlingEndTime.indexOf('mm') > -1
			) {
				alert('민원 처리기한을 다시 입력해주세요.');
				return false;
			}
			return true;
		},
		async submit() {
			if (!this.validDateChk()) {
				return;
			}
			if (
				!nullValidation2(this.formData.complainDto, [
					'regiName',
					'regiDate',
					'saleStoreId',
					'openingStoreId',
					'complainType',
					'complainStartDate',
					'complainStartTime',
					'complainHandlingEndDate',
					'complainHandlingEndTime',
					'cusName',
					'cusBirthDate',
					'openingPhone1',
					'openingPhone2',
					'openingPhone3',
				])
			) {
				return;
			}
			if (
				!nullValidation2(this.formData.complainDetailDto, ['complainContents'])
			) {
				return;
			}
			if (this.isEndTimeLaterThanStartTime()) {
				return;
			}
			if (!confirm('등록하시겠습니까?')) {
				return;
			}
			document.getElementById('focusTarget').focus();
			this.formData.complainDetailDto.preComplainHandlingEndDate =
				this.formData.complainDto.complainHandlingEndDate;
			this.formData.complainDetailDto.preComplainHandlingEndTime =
				this.formData.complainDto.complainHandlingEndTime;

			let formData = this.formData;
			let subId = await this.insertComplain(formData);
			if (subId) {
				console.log(this.fileList);
				if (this.fileList.length !== 0) {
					let result = await this.updateComplainAttachMgmt(subId);
					if (result && '0000' === result.data.resultCode) {
						if (!this.loadFromAppl) {
							await this.$store.dispatch(
								'ComplainHndlgModule/getComplainList',
								this.filterData,
							);
						} else {
							this.$emit('getApplicationDetail');
						}
						this.dialog = false;
					}
				} else {
					alert('등록되었습니다.');
					if (!this.loadFromAppl) {
						await this.$store.dispatch(
							'ComplainHndlgModule/getComplainList',
							this.filterData,
						);
					} else {
						this.$emit('getApplicationDetail');
					}
					this.dialog = false;
				}
			}
		},
		isEndTimeLaterThanStartTime() {
			let start = new Date(
				`${this.complainDto.complainStartDate} ${this.complainDto.complainStartTime}`,
			).getTime();
			let end = new Date(
				`${this.complainDto.complainHandlingEndDate} ${this.complainDto.complainHandlingEndTime}`,
			).getTime();
			if (start > end) {
				alert('민원 처리기한을 민원 시작일시 이후로 설정하시기 바랍니다.');
				return true;
			}
		},
		async insertComplain(data) {
			return await this.$store.dispatch(
				'ComplainHndlgModule/insertComplain',
				data,
			);
		},
		async updateComplainAttachMgmt(subId) {
			if (this.fileList) {
				if (this.fileList !== 0) {
					let formData = new FormData();
					formData.append('subId', subId);
					for (let i = 0; i < this.fileList.length; i++) {
						formData.append('attachFile', this.fileList[i]);
						formData.append('attachType', 'COMPLAIN_DETAIL');
					}
					// 파일 이력
					return await this.$store.dispatch(
						'ComplainHndlgModule/updateComplainAttachMgmt',
						formData,
					);
				}
			}
		},
		// 민원 템플릿 리스트 조회
		async getComplainTemplateRecipList(type) {
			let data;
			if (type) {
				data = { templateType: type };
			}
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainTemplateRecipList',
				data,
			);
			this.templateList = [];
			if (result && '0000' === result.data.resultCode) {
				this.templateList = result.data.data;
			}
		},
		// 민원 템플릿 상세 조회
		async getComplainRecipDetail(id) {
			let data = {
				complainTemplateId: id,
			};
			let result = await this.$store.dispatch(
				'ComplainHndlgModule/getComplainRecipDetail',
				data,
			);
			this.formData = {};
			if (result && '0000' === result.data.resultCode) {
				this.formData = result.data.data;
			}
			return result;
		},
		openDialogFnc(dialogName, id) {
			if (dialogName === 'complainInfoPop') {
				this.complainId = id;
			}
			this[dialogName] = true;
		},
		closeDialog(data, dialogName) {
			this.$set(this, dialogName, data);
		},
	},
	watch: {
		dialog: {
			deep: true,
			handler: function (newVal) {
				if (!newVal) {
					this.$emit('closeDialog', this.dialog, 'complainRegiPop');
				}
			},
		},
	},
	async created() {
		if (this.applId !== 0) {
			this.formData.complainDto.applId = this.applId;
			if (this.loadFromAppl) {
				await this.setCustomerData();
			}
		}
		await this.initData();
		await this.getSelectList();
		await this.getComplainTemplateRecipList('FOREIGN_COMPLAIN');
	},
};
</script>

<style scoped>
.complainRegiPop .checkStyleCont10 label {
	padding: 2px 0;
}
.complainRegiPop table td input[type='text'] {
	padding: 0 10px !important;
}
.complainRegiPop .tempSelect .v-input {
	width: 220px;
}
.complainRegiPop table input[type='text'] {
	width: 200px;
	height: 32px;
}
.complainRegiPop table tr {
	height: 40px;
}
.complainRegiPop table th {
	background-color: #fbfbfb;
	width: 130px;
}
.complainRegiPop .v-input {
	width: 200px;
	font-size: 13px !important;
}
.complainRegiPop table td {
	padding-left: 8px;
}
.complainRegiPop table input,
.complainRegiPop table textarea {
	border: 1px solid rgba(52, 52, 52, 0.3);
	border-radius: 4px;
	outline: none;
}
.complainRegiPop label,
.complainRegiPop label {
	cursor: pointer;
	padding: 2px 8px;
}
.complainRegiPop table textarea {
	height: 130px;
	width: 90%;
	resize: none;
	margin-top: 8px !important;
}
.buttonCont {
	float: right;
	margin: 20px 0;
}
.complainRegiPop .buttonCont button {
	color: #fff;
	width: 72px;
	height: 35px;
	border-radius: 3px;
}
.complainRegiPop .buttonCont button:not(first-child) {
	margin-left: 8px;
}
.opacityZero {
	opacity: 0;
}
.vue__time-picker input.display-time {
	width: 60px !important;
}
</style>
