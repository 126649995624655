import Vue from 'vue';
import Vuex from 'vuex';
import {
	getIndvOpeningCnt,
	getOrganizationComplCnt,
	getTodoReservationList,
	getCurrentMonthInfo,
} from '@/api/common/dashboard/dashboardApi';

Vue.use(Vuex);

const DashboardModule = {
	namespaced: true,
	state: {
		lineChartData: {
			datasets: [[], []],
		},
		currentMonthInfo: {
			consultCnt: 0,
			consultProgressCnt: 0,
			consultProspectCnt: 0,
			consultRcptComplCnt: 0,
			creditInquireCnt: 0,
			creditInquireComplCnt: 0,
			creditInquireErrCnt: 0,
			customerCnt: 0,
			customerConsultCnt: 0,
			customerConsultProgressCnt: 0,
			customerConsultProspectCnt: 0,
			customerOpeningComplCnt: 0,
		},
		barChartData: {
			searchResult: { rcptComplCnt: 0, openingComplCnt: 0, retractComplCnt: 0 },
			avgResult: { rcptComplCnt: 0, openingComplCnt: 0, retractComplCnt: 0 },
			maxResult: { rcptComplCnt: 0, openingComplCnt: 0, retractComplCnt: 0 },
		},

		todoReservationList: 0,
	},
	mutations: {
		setIndvOpeningCnt(state, result) {
			let today = new Date().getDate();
			let thisMonth = result.nowMonth.map(obj => {
				let spliceIdx = -2;
				if (obj.openingDate[8] === '0') {
					spliceIdx = -1;
				}
				return {
					openingDate: obj.openingDate.slice(spliceIdx),
					accrueCnt: obj.accrueCnt,
				};
			});
			for (let i = 0; i < today; i++) {
				state.lineChartData.datasets[0].push(thisMonth[i]);
			}
			state.lineChartData.datasets[1] = result.targetMonth.map(obj => {
				let spliceIdx = -2;
				if (obj.openingDate[8] === '0') {
					spliceIdx = -1;
				}
				return {
					openingDate: obj.openingDate.slice(spliceIdx),
					accrueCnt: obj.accrueCnt,
				};
			});
		},

		setOrganizationComplCnt(state, result) {
			state.barChartData.searchResult = result.searchResult;
			state.barChartData.avgResult = result.avgResult;
			state.barChartData.maxResult = result.maxResult;
		},
		initOrganizationComplCnt(state) {
			state.barChartData.searchResult = {
				openingComplCnt: 0,
				openingComplRate: 0,
				rcptComplCnt: 0,
				retractComplCnt: 0,
				targetName: '',
			};
			state.barChartData.avgResult = {
				openingComplCnt: 0,
				openingComplRate: 0,
				rcptComplCnt: 0,
				retractComplCnt: 0,
				targetName: '',
			};
			state.barChartData.maxResult = {
				openingComplCnt: 0,
				openingComplRate: 0,
				rcptComplCnt: 0,
				retractComplCnt: 0,
				targetName: '',
			};
		},
		setCurrentMonthInfo(state, result) {
			state.currentMonthInfo = result;
		},
		initCurrentMonthInfo(state) {
			for (let i in state.currentMonthInfo) {
				state.currentMonthInfo[i] = 0;
			}
		},
		initIndvOpeningCnt(state) {
			state.lineChartData.datasets[0] = [];
			state.lineChartData.datasets[1] = [];
		},
	},
	actions: {
		async getIndvOpeningCnt({ commit }, data) {
			try {
				commit('initIndvOpeningCnt');
				const result = await getIndvOpeningCnt(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setIndvOpeningCnt', result.data.data);
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getOrganizationComplCnt({ commit }, data) {
			try {
				commit('initOrganizationComplCnt');
				const result = await getOrganizationComplCnt(data);
				if (result && '0000' === result.data.resultCode) {
					// console.log(result.data.data);
					commit('setOrganizationComplCnt', result.data.data);
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getTodoReservationList({ commit }, data) {
			try {
				const result = await getTodoReservationList(data);
				if (result && '0000' === result.data.resultCode) {
					// return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getCurrentMonthInfo({ commit }, data) {
			try {
				const result = await getCurrentMonthInfo(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setCurrentMonthInfo', result.data.data);
				} else {
					commit('initCurrentMonthInfo');
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default DashboardModule;
