<template>
	<div
		class="backColorWhite mt5 borderRadi5Px borderContColor1 cal_main_cont2"
		style="padding: 5px 20px 5px 20px"
	>
		<h3
			class="mainBlue2 mainFontS lh32"
			style="border-bottom: 1px solid #007ef2"
		>
			할인 정보 (C)
		</h3>
		<table class="amt_table1 w100P mt5">
			<tr>
				<th
					style="
						border-right: 1px solid #fff;
						font-size: 11px;
						font-weight: normal;
					"
				>
					기기할인
				</th>
				<th style="font-size: 11px; font-weight: normal">요금할인</th>
			</tr>
		</table>
		<div class="disFx mt5">
			<table class="amt_table1 w50P">
				<tr>
					<td style="font-weight: bolder">할부개월</td>
					<td>
						<select
							class="borderContColor3 w160 h40 borderRadi3Px txAnR"
							style="padding-right: 25px !important; font-weight: bolder"
							v-model.trim="formData.instlPeriodType"
							:disabled="!formData.telecomId"
						>
							<option :value="null" hidden>선택해 주세요.</option>
							<option
								v-for="(item, index) in codeList.installmentsPeriodTypeItems"
								:key="index"
								:value="item.value"
							>
								{{ item.name }}
							</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>프리할부/선수납</td>
					<td>
						<div class="copyClass1">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox1 amtNum"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="formData.freeInstlAmt"
								:disabled="!formData.telecomId"
							/>
						</div>
					</td>
				</tr>
				<tr>
					<td>기타할인금</td>
					<td>
						<div class="copyClass1">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox1 amtNum"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="formData.etcDiscountAmt"
								:disabled="!formData.telecomId"
							/>
						</div>
					</td>
				</tr>
				<tr>
					<td>선할인카드</td>
					<td>
						<div class="copyClass4" @click="discountVal(formData.telecomId)">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox2 amtNum cur_p disGray"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@click="discountPopFnc('선할인카드 선택')"
								:disabled="!this.formData.telecomId"
								v-model.trim="formData.preCardAmt"
								readonly
							/>
							<span
								v-if="preInsertDto.cardItem"
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								{{ preInsertDto.cardItem.cardName }}/
								<span v-if="preInsertDto.cardFormData"
									>{{ preInsertDto.cardFormData.chargeDate }}개월
								</span>
							</span>
							<span
								v-else
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								선택해 주세요.
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td class="mainBlue2" style="font-weight: bolder">총 기기할인</td>
					<td>
						<div class="copyClass2">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox1 mainBlue2 amtNum cur_d"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="totalDvcAmt"
								readonly
							/>
						</div>
					</td>
				</tr>
			</table>
			<table class="amt_table1 w50P ml40">
				<tr>
					<td style="font-weight: bolder">약정기간</td>
					<td>
						<select
							class="borderContColor3 w160 h40 borderRadi3Px txAnR"
							style="padding-right: 25px !important; font-weight: bolder"
							v-model.trim="formData.agreementPeriodType"
							:disabled="!formData.telecomId"
						>
							<option :value="null" hidden>선택해 주세요.</option>
							<option
								v-for="(item, index) in codeList.agreementPeriodTypeItems"
								:key="index"
								:value="item.value"
							>
								{{ item.name }}
							</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>요금감면</td>
					<td>
						<div class="copyClass4" @click="discountVal(formData.telecomId)">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox2 amtNum cur_p disGray"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@click="discountPopFnc('요금감면 선택')"
								v-model.trim="chargeReductAmt"
								:disabled="!formData.telecomId"
								readonly
							/>
							<span
								v-if="formData.chargeReductType"
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								{{ chargeReductName }}
							</span>
							<span
								v-else
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								선택해 주세요.
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td>청구할인카드</td>
					<td>
						<div class="copyClass4" @click="discountVal(formData.telecomId)">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox2 amtNum cur_p disGray"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@click="discountPopFnc('청구할인카드 선택')"
								:disabled="!this.formData.telecomId"
								v-model.trim="formData.billCardAmt"
								readonly
							/>
							<span
								v-if="billInsertDto.cardItem"
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								{{ billInsertDto.cardItem.cardName }}
							</span>
							<span
								v-else
								style="
									position: absolute;
									width: 120px;
									bottom: 0px;
									right: 33px;
									font-size: 11px;
									text-align: right;
									color: #999;
									pointer-events: none;
								"
								class="ellipsisH"
							>
								선택해 주세요.
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2" style="height: 52px"></td>
					<!--					<td>결합할인</td>
					<td>
						<div class="copyClass1">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_p"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								:disabled="!this.formData.telecomId"
								readonly
							/>
						</div>
					</td>-->
				</tr>
				<tr>
					<td class="mainBlue2" style="font-weight: bolder">총 요금할인</td>
					<td>
						<div class="copyClass2">
							<input
								type="text"
								class="cal_table_input1 borderContColor3 amtInputBox1 mainBlue2 amtNum cur_d"
								maxlength="9"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="totalChargeAmt"
								readonly
							/>
						</div>
					</td>
				</tr>
			</table>
		</div>

		<discount-select-pop
			v-if="discountDialog"
			:title="discountTitle"
		></discount-select-pop>
	</div>
</template>

<script>
import NumRepMixin from '../../../../../../common/NumRepMixin.js';
import DiscountSelectPop from './popup/DiscountSelectPop.vue';

export default {
	name: 'TotalAmtData',
	components: {
		DiscountSelectPop,
	},
	mixins: [NumRepMixin],
	computed: {
		discountTitle: {
			get() {
				return this.$store.state.ChargeCalculatorModule.discountTitle;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.discountTitle = newValue;
			},
		},
		discountDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.discountDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.discountDialog = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		preInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preInsertDto = newValue;
			},
		},
		billInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.billInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.billInsertDto = newValue;
			},
		},
		totalDvcAmt() {
			return (
				Number(this.formData.freeInstlAmt) +
				Number(this.formData.etcDiscountAmt) +
				Number(this.formData.preCardAmt)
			);
		},
		totalChargeAmt() {
			return Number(this.formData.billCardAmt) + Number(this.chargeReductAmt);
		},
		chargeReductAmt() {
			if (!this.formData.chargeId) {
				return 0;
			} else {
				if (this.formData.chargeReductType === 'NONE') {
					return 0;
				} else if (this.formData.chargeReductType === 'DSBL') {
					return Math.round(Number(this.formData.monthBasicAmt) * 0.35) * -1;
				} else if (this.formData.chargeReductType === 'NTNL') {
					return Math.round(Number(this.formData.monthBasicAmt) * 0.35) * -1;
				} else if (this.formData.chargeReductType === 'BASE_RCP') {
					return -28600;
				} else if (this.formData.chargeReductType === 'PENSION_RCP') {
					return -12100;
				} else if (this.formData.chargeReductType === 'HIGHT') {
					return -12100;
				} else {
					return 0;
				}
			}
		},
		chargeReductName() {
			if (!this.formData.chargeReductType) {
				return null;
			} else {
				if (this.codeList.chargeReductTypeItemList) {
					let reduct = this.codeList.chargeReductTypeItemList.find(
						i => i.value === this.formData.chargeReductType,
					);
					return reduct.name;
				} else {
					return '정보없음';
				}
			}
		},
	},
	methods: {
		discountPopFnc(title) {
			this.discountDialog = true;
			this.discountTitle = title;
		},
		discountVal(telId) {
			if (!telId) {
				alert('통신사를 선택해 주세요.');
			}
		},
	},
	created() {
		this.discountDialog = false;
	},
	updated() {
		// 프리할부/선수납
		if (!this.formData.freeInstlAmt) {
			this.formData.freeInstlAmt = 0;
		} else {
			this.formData.freeInstlAmt = Math.abs(this.formData.freeInstlAmt) * -1;
		}
		// 기타할인금
		if (!this.formData.etcDiscountAmt) {
			this.formData.etcDiscountAmt = 0;
		} else {
			this.formData.etcDiscountAmt =
				Math.abs(this.formData.etcDiscountAmt) * -1;
		}
	},
};
</script>

<style scoped>
.cal_main_cont2 {
	position: relative;
}
.cal_main_cont2:before {
	position: absolute;
	content: '';
	display: block;
	width: 1px;
	height: 246px;
	top: 80px;
	left: 328px;
	background-color: #007ef2;
}
</style>
