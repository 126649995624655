<template>
	<div>
		<div style="position: absolute; top: -40px; right: 0">
			<button
				class="backColorBlue2 mainWhite borderRadi3Px lh30 w90"
				@click="formDataInit"
			>
				초기화
			</button>
			<button
				class="backColorBlue2 mainWhite borderRadi3Px lh30 ml10 w90"
				@click="copyTextFnc"
			>
				결과복사
			</button>
		</div>
		<div class="posR">
			<h3
				class="mainBlue2 mainFontS lh32"
				style="border-bottom: 1px solid #007ef2"
			>
				월 납부 요금 (A + B + D)
			</h3>
			<span
				v-if="formData.instlPeriodType"
				class="subFontS11 mainGray1"
				style="position: absolute; bottom: 2px; right: 0"
			>
				<template
					v-if="formData.instlPeriodType && formData.instlPeriodType !== 'CASH'"
				>
					할부기간 {{ instlPeriod(formData.instlPeriodType) }} 기준
				</template>
			</span>
		</div>
		<div class="disFx w100P">
			<div>
				<table class="txAnL" style="width: 125px; margin-top: 49px">
					<tr>
						<th style="height: 52px; padding-left: 30px">월 기기 할부금</th>
					</tr>
					<tr>
						<th style="height: 52px; padding-left: 30px">월 할부 이자</th>
					</tr>
					<tr>
						<th style="height: 52px; padding-left: 30px">월 기본료</th>
					</tr>
					<tr>
						<th style="height: 52px; padding-left: 30px">월 서비스 비용</th>
					</tr>
				</table>
			</div>
			<div class="cal_main_cont4" style="width: 374px">
				<table class="amt_table1 w100P mt15">
					<tr>
						<th
							@click="formData.agreementType = 'PUBNOTI_SUPAMT'"
							:class="agreeTypePubFnc(formData.agreementType)"
							class="backColorBlue2 cur_p"
							style="
								border-right: 1px solid #fff;
								font-size: 12px;
								font-weight: normal;
							"
						>
							공시지원할인
						</th>
						<th
							@click="formData.agreementType = 'CHOICE_AGRMN'"
							:class="agreeTypeChoiceFnc(formData.agreementType)"
							class="backColorBlue2 cur_p"
							style="font-size: 12px; font-weight: normal"
						>
							선택약정할인
						</th>
					</tr>
				</table>
				<div class="disFx mt10">
					<table class="w50P" :class="agreeTypePubFnc(formData.agreementType)">
						<tr>
							<td>
								<div class="copyClass1">
									<input
										type="text"
										class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
										maxlength="9"
										style="font-weight: bolder"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										:disabled="formData.agreementType === 'CHOICE_AGRMN'"
										v-model.trim="supMonthDeviceAmtView"
										readonly
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="copyClass1">
									<input
										type="text"
										class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
										maxlength="9"
										style="font-weight: bolder"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										:disabled="formData.agreementType === 'CHOICE_AGRMN'"
										v-model.trim="supMonthInterestAmtView"
										readonly
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="copyClass1">
									<input
										type="text"
										class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
										maxlength="9"
										style="font-weight: bolder"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										:disabled="formData.agreementType === 'CHOICE_AGRMN'"
										v-model.trim="supMonthChargeAmt"
										readonly
									/>
								</div>
							</td>
						</tr>
					</table>
					<table
						class="w50P ml40"
						:class="agreeTypeChoiceFnc(formData.agreementType)"
					>
						<tr>
							<td>
								<div class="copyClass1">
									<input
										type="text"
										class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
										style="font-weight: bolder"
										maxlength="9"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										:disabled="formData.agreementType === 'PUBNOTI_SUPAMT'"
										v-model.trim="choiceMonthDeviceAmtView"
										readonly
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="copyClass1">
									<input
										type="text"
										class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
										style="font-weight: bolder"
										maxlength="9"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										:disabled="formData.agreementType === 'PUBNOTI_SUPAMT'"
										v-model.trim="choiceMonthInterestAmtView"
										readonly
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="copyClass1">
									<input
										type="text"
										class="cal_table_input1 borderContColor3 amtInputBox1 amtNum cur_d"
										style="font-weight: bolder"
										maxlength="9"
										oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										:disabled="formData.agreementType === 'PUBNOTI_SUPAMT'"
										v-model.trim="choiceMonthChargeAmt"
										readonly
									/>
								</div>
							</td>
						</tr>
					</table>
				</div>
				<table class="w100P">
					<tr>
						<td>
							<div class="copyClass3 w100P borderContColor3 borderRadi3Px">
								<input
									type="text"
									class="w100P amtNum txAnC h40 cur_d"
									style="font-weight: bolder"
									maxlength="9"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									v-model.trim="addServiceTotalAmt"
									readonly
								/>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div
			class="disFx cal_main_cont5 font-size-18 mainRed"
			style="line-height: 55px; font-weight: bolder"
		>
			<h3 class="font-size-18" style="width: 125px; padding-left: 30px">
				월 청구금액
			</h3>
			<span class="w180 txAnC" :class="agreeTypePubFnc(formData.agreementType)"
				>{{
					supMonthTotalAmt
						? String(supMonthTotalAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						: 0
				}}
				원
				<span style="font-size: 13px">(VAT 포함)</span>
			</span>
			<span
				class="w180 txAnC"
				:class="agreeTypeChoiceFnc(formData.agreementType)"
				>{{
					choiceMonthTotalAmt
						? String(choiceMonthTotalAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						: 0
				}}
				원
				<span style="font-size: 13px">(VAT 포함)</span>
			</span>
		</div>
		<div
			class="txAnC lh40"
			style="background-color: #f5f5f5; font-weight: bolder"
		>
			<span class="mainBlue2">{{ compTotalTitle }}</span
			>이 월
			<span class="mainBlue2">{{
				String(compTotalAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			}}</span>
			원 / 연
			<span class="mainBlue2">{{
				String(compTotalAmt * 12).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			}}</span>
			원 이익
		</div>
	</div>
</template>

<script>
import NumRepMixin from '../../../../../../common/NumRepMixin';

export default {
	name: 'totalSaveAmtData',
	mixins: [NumRepMixin],
	data() {
		return {
			supMonthDeviceAmtView: 0,
			supMonthInterestAmtView: 0,
			choiceMonthDeviceAmtView: 0,
			choiceMonthInterestAmtView: 0,
		}
	},
	computed: {
		Interest: {
			get() {
				return this.$store.state.ChargeCalculatorModule.Interest;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.Interest = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		addSvcIdArray: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcIdArray;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcIdArray = newValue;
			},
		},
		codeList: {
			get() {
				return this.$store.state.ChargeCalculatorModule.codeList;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.codeList = newValue;
			},
		},
		chgChargeDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chgChargeDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chgChargeDto = newValue;
			},
		},
		preInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.preInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.preInsertDto = newValue;
			},
		},
		billInsertDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.billInsertDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.billInsertDto = newValue;
			},
		},
		changeDate: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDate;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDate = newValue;
			},
		},
		changeDateName: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDateName;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDateName = newValue;
			},
		},
		addServiceTotalAmt() {
			if (this.formData.insAmt || this.formData.addSvcAmt) {
				return this.formData.insAmt + this.formData.addSvcAmt;
			} else {
				return 0;
			}
		},
		chargeReductAmt() {
			// 요금감면
			if (!this.formData.chargeId) {
				return 0;
			} else {
				if (this.formData.chargeReductType === 'NONE') {
					return 0;
				} else if (this.formData.chargeReductType === 'DSBL') {
					return Math.round(Number(this.formData.monthBasicAmt) * 0.35) * -1;
				} else if (this.formData.chargeReductType === 'NTNL') {
					return Math.round(Number(this.formData.monthBasicAmt) * 0.35) * -1;
				} else if (this.formData.chargeReductType === 'BASE_RCP') {
					return -28600;
				} else if (this.formData.chargeReductType === 'PENSION_RCP') {
					return -12100;
				} else if (this.formData.chargeReductType === 'HIGHT') {
					return -12100;
				} else {
					return 0;
				}
			}
		},
		/*공식 적용 필요*/
		supMonthDeviceAmt() {
			// 공시지원할인 - 월기기할부금
			if (
				this.formData.instlPeriodType === 'CASH' ||
				!this.formData.instlPeriodType
			) {
				return 0;
			} else {
				let totalAmt =
					this.formData.releaseAmt +
					this.formData.pubNotiSupportAmt +
					this.formData.extraSupportAmt +
					this.formData.freeInstlAmt +
					this.formData.etcDiscountAmt +
					this.formData.preCardAmt;

				if (this.monthDvcAmtFnc(totalAmt, 'SUPPORT') < 0) {
					return 0;
				} else {
					return this.monthDvcAmtFnc(totalAmt, 'SUPPORT');
				}
			}
		},
		supMonthChargeAmt() {
			// 공시지원할인 - 월기본료
			if (
				Number(this.formData.monthBasicAmt) +
					Number(this.formData.billCardAmt) +
					Number(this.chargeReductAmt) <
				0
			) {
				return 0;
			} else {
				return Math.round(
					Number(this.formData.monthBasicAmt) +
						Number(this.formData.billCardAmt) +
						Number(this.chargeReductAmt),
				);
			}
		},
		supMonthTotalAmt() {
			// 공시지원할인 - 월청구금액
			const total =
				Number(this.supMonthDeviceAmt) +
				Number(this.supMonthChargeAmt) +
				Number(this.addServiceTotalAmt);
			return Number(total);
		},
		choiceMonthDeviceAmt() {
			// 선택약정할인 - 월기기할부금
			if (
				this.formData.instlPeriodType === 'CASH' ||
				!this.formData.instlPeriodType
			) {
				return 0;
			} else {
				let totalAmt =
					this.formData.releaseAmt +
					this.formData.freeInstlAmt +
					this.formData.etcDiscountAmt +
					this.formData.preCardAmt;

				if (this.monthDvcAmtFnc(totalAmt, 'CHOICE') < 0) {
					return 0;
				} else {
					return this.monthDvcAmtFnc(totalAmt, 'CHOICE');
				}
			}
		},
		choiceMonthChargeAmt() {
			// 선택약정할인 - 월기본료
			if (
				Number(this.formData.monthBasicAmt) * 0.75 +
					Number(this.formData.billCardAmt) +
					Number(this.chargeReductAmt) <
				0
			) {
				return 0;
			} else {
				return Math.round(
					Number(this.formData.monthBasicAmt) * 0.75 +
						Number(this.formData.billCardAmt) +
						Number(this.chargeReductAmt),
				);
			}
		},
		choiceMonthTotalAmt() {
			// 선택약정할인 - 월청구금액
			const total =
				Number(this.choiceMonthDeviceAmt) +
				Number(this.choiceMonthChargeAmt) +
				Number(this.addServiceTotalAmt);
			return Number(total);
		},
		compTotalTitle() {
			return this.choiceMonthTotalAmt >= this.supMonthTotalAmt
				? '공시지원금'
				: '선택약정';
		},
		compTotalAmt() {
			// 월 청구금액 이득 값
			const result = this.choiceMonthTotalAmt - this.supMonthTotalAmt;
			return result < 0 ? Math.abs(result) : result;
		},
	},
	methods: {
		agreeTypePubFnc(e) {
			if (e === 'CHOICE_AGRMN') {
				return 'opacity2';
			}
		},
		agreeTypeChoiceFnc(e) {
			if (e === 'PUBNOTI_SUPAMT') {
				return 'opacity2';
			}
		},
		instlPeriod() {
			if (this.formData.instlPeriodType === 'CASH') {
				return '';
			} else if (this.formData.instlPeriodType === 'MONTH_6') {
				return '6개월';
			} else if (this.formData.instlPeriodType === 'MONTH_12') {
				return '12개월';
			} else if (this.formData.instlPeriodType === 'MONTH_24') {
				return '24개월';
			} else if (this.formData.instlPeriodType === 'MONTH_30') {
				return '30개월';
			} else if (this.formData.instlPeriodType === 'MONTH_36') {
				return '36개월';
			} else if (this.formData.instlPeriodType === 'MONTH_48') {
				return '48개월';
			} else {
				return '';
			}
		},
		monthDvcAmtFnc(monthTotalAmt, amtType) {
			// 월 기기 할부금 공식
			let Int12 = this.Interest / 12;
			let insP;

			switch (this.formData.instlPeriodType) {
				case 'MONTH_6':
					insP = 6;
					break;
				case 'MONTH_12':
					insP = 12;
					break;
				case 'MONTH_24':
					insP = 24;
					break;
				case 'MONTH_30':
					insP = 30;
					break;
				case 'MONTH_36':
					insP = 36;
					break;
				case 'MONTH_48':
					insP = 48;
					break;
				case 'CASH':
					insP = 0;
					break;
				default:
					insP = 0;
			}

			if (insP !== 0) {
				let frontNum = monthTotalAmt * Int12 * Math.pow(1 + Int12, insP);
				if(amtType === 'SUPPORT') {
					this.supMonthDeviceAmtView = Math.round(monthTotalAmt/insP);
					this.supMonthInterestAmtView = Math.round(Math.round(frontNum / (Math.pow(1 + Int12, insP) - 1))-(monthTotalAmt/insP));
				} else {
					this.choiceMonthDeviceAmtView = Math.round(monthTotalAmt/insP);
					this.choiceMonthInterestAmtView = Math.round(Math.round(frontNum / (Math.pow(1 + Int12, insP) - 1))-(monthTotalAmt/insP));
				}
				// console.log('월 기기 할부금액 : ' + monthTotalAmt/insP )
				// console.log('월 할부 이자 : ' + (Math.round(frontNum / (Math.pow(1 + Int12, insP) - 1))-(monthTotalAmt/insP)))
				// console.log('합 : ' + (52250+3272))
				return Math.round(frontNum / (Math.pow(1 + Int12, insP) - 1));
			} else {
				if(amtType === 'SUPPORT') {
					this.supMonthDeviceAmtView = 0;
					this.supMonthInterestAmtView = 0;
				} else {
					this.choiceMonthDeviceAmtView = 0;
					this.choiceMonthInterestAmtView = 0;
				}
				return 0;
			}
		},
		formDataInit() {
			for (let data in this.formData) {
				this.formData[data] = null;
			}
			this.formData.maker = 0;
			this.formData.colorImgUrl =
				'https://www.sklmno.com/goods/upload/no_image_m.png';
			this.formData.agreementType = 'PUBNOTI_SUPAMT'; // 약정유형
			this.formData.instlPeriodType = 'MONTH_24'; // 약정유형
			this.formData.numChangeYn = 'N'; // 번호이동 Yn
			this.formData.releaseAmt = 0; // 출고가
			this.formData.pubNotiSupportAmt = 0; // 공시지원금
			this.formData.extraSupportAmt = 0; // 추가지원금
			this.formData.freeInstlAmt = 0; // 프리할부/선수납
			this.formData.etcDiscountAmt = 0; // 기타할인금
			this.formData.actualSellAmt = 0; // 실판매가
			this.formData.instlPrincipalAmt = 0; // 실판매가
			this.formData.monthDvcAmt = 0; // 월기기금액
			this.formData.monthBasicAmt = 0; // 월기본료
			this.formData.monthPaymentAmt = 0; // 월납부금액
			this.formData.addSvcAmt = 0; // 부가서비스
			this.formData.insAmt = 0; // 보험료
			this.formData.preCardAmt = 0; // 선할인카드
			this.formData.billCardAmt = 0; // 선할인카드
			this.codeList.goodsItemList = [];
			this.codeList.chargeItemList = [];
			this.addSvcIdArray = [];
			this.preInsertDto.cardFormData = null;
			this.preInsertDto.cardItem = null;
			this.billInsertDto.cardFormData = null;
			this.billInsertDto.cardItem = null;
			this.chgChargeDto = null;
			this.changeDateName = null;
			this.changeDate = null;
		},
		copyTextFnc() {
			let telecomName;
			switch (this.formData.telecomId) {
				case '5':
					telecomName = 'SKT';
					break;
				case '6':
					telecomName = 'KT';
					break;
				case '7':
					telecomName = 'LGU';
					break;
			}

			let instlType = this.codeList.installmentsPeriodTypeItems.find(
				i => i.value === this.formData.instlPeriodType,
			);

			let Text =
				`통신사 : ${telecomName ? telecomName : ''}\n` +
				`기기명 : ${this.formData.goodsName ? this.formData.goodsName : ''}\n` +
				`요금제 : ${
					this.formData.chargeName ? this.formData.chargeName : ''
				}\n` +
				`할부개월 : ${instlType.name}\n` +
				'\n' +
				`출고가 : ${String(this.formData.releaseAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n\n` +
				`월 서비스 비용 : ${String(this.addServiceTotalAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n` +
				'\n' +
				'## 공시지원\n' +
				'\n' +
				`공시지원 : ${String(this.formData.pubNotiSupportAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n` +
				`추가지원금  : ${String(this.formData.extraSupportAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n` +
				'\n' +
				`■ 월 기기할부금 : ${String(this.supMonthDeviceAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n` +
				'\n' +
				`■ 월 기본료 : ${String(this.supMonthChargeAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n` +
				'-------------------------------------\n' +
				`■ 월 총 납부금 : ${String(this.supMonthTotalAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원(VAT포함)\n` +
				'-------------------------------------\n' +
				'\n' +
				'## 선택약정\n' +
				'\n' +
				`선택약정 : ${String(
					Math.round(Number(this.formData.monthBasicAmt) * 0.25) * -1,
				).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원\n` +
				'\n' +
				`■ 월 기기할부금 : ${String(this.choiceMonthDeviceAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원)\n` +
				'\n' +
				`■ 월 기본료 : ${String(this.choiceMonthChargeAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원\n` +
				'-------------------------------------\n' +
				`■ 월 총 납부금 : ${String(this.choiceMonthTotalAmt).replace(
					/\B(?=(\d{3})+(?!\d))/g,
					',',
				)} 원(VAT포함)\n` +
				'-------------------------------------';

			const fakeTextArea = document.createElement('textarea');

			document.body.appendChild(fakeTextArea);

			fakeTextArea.value = Text;
			fakeTextArea.select();

			document.execCommand('copy');
			document.body.removeChild(fakeTextArea);
			alert('복사 되었습니다.');
		},
	},
};
</script>

<style scoped>
.cal_main_cont4 {
	position: relative;
}
.cal_main_cont4:before {
	position: absolute;
	content: '';
	display: block;
	width: 1px;
	height: 90px;
	top: 58px;
	left: 180px;
	background-color: #007ef2;
}
.cal_main_cont5 {
	position: relative;
}
.cal_main_cont5:before {
	position: absolute;
	content: '';
	display: block;
	width: 1px;
	height: 35px;
	top: 10px;
	left: 306px;
	background-color: #007ef2;
}
</style>
