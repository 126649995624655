import { instance } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/ManagerStoreRetentionManagement/ManagerStoreRetentionMgmt/';
const domainSales = '/SalesStoreRetentionManagement/SalesStoreRetentionMgmt/';

// [대리점] 리텐션 DB 목록
const getRetentionList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getRetentionList${param}`);
	return response;
};
// [대리점] 리텐션 DB 목록
const getRetentionCustomerProgressCount = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getRetentionCustomerProgressCount${param}`);
	return response;
};
// [판매점] 리텐션 DB 목록
const getSalesRetentionList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getRetentionList${param}`);
	return response;
};
// [판매점] 리텐션DB 고객DB 진행 상태 카운트
const getRetentionConsultProgressCount = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getRetentionConsultProgressCount${param}`);
	return response;
};
// [대리점] 리텐션DB 상세 팝업
const getRetentionDetailInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getRetentionDetailInfo${param}`);
	return response;
};
// [판매점] 리텐션DB 상세 팝업
const getSalesRetentionDetailInfo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getRetentionDetailInfo${param}`);
	return response;
};
// 리텐션 DB 판매점 배당
const allocationRetention = async data => {
	const response = await instance.post(
		`${domain}allocationRetention`,
		data,
	);
	return response;
};
// 리텐션 DB 상담원 배정
const assignRetentionConsult = async data => {
	const response = await instance.post(
		`${domainSales}assignRetentionConsult`,
		data,
	);
	return response;
};

// 리텐션DB 판매점별 배당 카운트
const getAllocationRetentionCount = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getAllocationRetentionCount${param}`);
	return response;
};

// 리텐션DB to 상담의 필터 그룹핑
const getRetentionConsultFilterGroup = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getRetentionConsultFilterGroup${param}`);
	return response;
};

// 리텐션DB 상담원 배당 목록 조회
const getRetentionConsultAssignList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domainSales}getRetentionConsultAssignList${param}`);
	return response;
};

export {
	getRetentionList,
	getRetentionCustomerProgressCount,
	getSalesRetentionList,
	getRetentionConsultProgressCount,
	getRetentionDetailInfo,
	getSalesRetentionDetailInfo,
	allocationRetention,
	assignRetentionConsult,
	getAllocationRetentionCount,
	getRetentionConsultFilterGroup,
	getRetentionConsultAssignList,
};
