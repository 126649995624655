<template>
	<div class="groupBtnCont1 disFX" style="z-index: 1">
		<div class="disFx">
			<span class="font-size-13 fw600">상담</span>
			<InputStatusSelect
				style="width: 180px"
				title="상담"
				:data="{
					name: 'consultTaskStatus',
					value: formData.basic.consultTaskStatus,
				}"
				@input="setNewData"
				:optionList="ConsultTaskStatusList"
				:formData="formData"
				:disableYN="disableConsult"
				:moduleName="moduleName"
			></InputStatusSelect>
		</div>
		<div
			class="disFx ml20"
			v-show="
				this.moduleName !== 'ConsultDbModule' ||
				this.moduleName !== 'ConsultDbModule' ||
				consultSwitchType
			"
		>
			<span class="font-size-13 fw600">개통</span>
			<InputStatusSelect
				style="width: 180px"
				title="개통"
				:formData="formData"
				:data="{
					name: 'openingTaskStatus',
					value: formData.basic.openingTaskStatus,
				}"
				@input="setNewData"
				:optionList="OpeningTaskStatusList"
				:titleWidth="40"
				:disableYN="disableOpening"
				:moduleName="moduleName"
			></InputStatusSelect>
		</div>
		<div
			class="disFx ml20"
			v-show="
				this.moduleName !== 'ConsultDbModule' ||
				this.moduleName !== 'ConsultDbModule' ||
				consultSwitchType
			"
		>
			<span class="font-size-13 fw600">물류</span>
			<InputStatusSelect
				style="width: 180px"
				title="물류"
				:data="{
					name: 'logisticsTaskStatus',
					value: formData.basic.logisticsTaskStatus,
				}"
				:formData="formData"
				@input="setNewData"
				:optionList="optionList.LogisticsTaskStatusList"
				:titleWidth="40"
				:disableYN="disableLogistics"
				:moduleName="moduleName"
			></InputStatusSelect>
		</div>
		<div
			v-if="
				formData.basic.returnStoreTypeMsg &&
				(formData.basic.logisticsTaskStatus === 'RETURN_DMND' ||
					formData.basic.logisticsTaskStatus === 'RETURN_COMPL' ||
					formData.basic.logisticsTaskStatus === 'RETURN_COMPL_RESELL' ||
					formData.basic.logisticsTaskStatus === 'STORE_RETURN')
			"
			class="ml10 mainBlack2 boldWt disFx lh32"
			style=""
		>
			반품처 : {{ formData.basic.returnStoreTypeMsg }}
		</div>
	</div>
</template>

<script>
import InputStatusSelect from '../../../../../components/input/InputStatusSelect';
import { nullValidation2 } from '../../../../../common/common';
import index from '@/store';
import bus from '@/common/bus';

export default {
	props: {
		// optionList
		// formData
		// disableConsult: 상담 disable 여부
		// disableOpening: 개통 disable 여부
		// disableLogistics: 물류 disable 여부
		optionList: { type: Object, required: true },
		formData: { type: Object, required: true },
		disableConsult: { type: Boolean, default: false },
		disableOpening: { type: Boolean, default: false },
		disableLogistics: { type: Boolean, default: false },
		moduleName: { type: String }, // 상담 신청서팝업 분기처리용
	},
	components: { InputStatusSelect },
	data: () => ({
		statusDialog: false,
	}),
	computed: {
		modifyState() {
			return this.$store.state.QuickOpeningPopModule.modifyState;
		},
		ConsultTaskStatusList() {
			const list = [...this.optionList.ConsultTaskStatusList];
			let data = [];
			for (let item in list) {
				data.push({
					name: list[item].name.replace('(상담)', ''),
					value: list[item].value,
				});
			}
			return data;
		},
		OpeningTaskStatusList() {
			const list = [...this.optionList.OpeningTaskStatusList];
			let data = [];
			for (let item in list) {
				data.push({
					name: list[item].name.replace('(개통)', ''),
					value: list[item].value,
				});
			}
			return data;
		},
		paymentCheckType: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.paymentCheckType;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.paymentCheckType =
					newValue;
			},
		},
		workStatusData: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.workStatusData;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.workStatusData =
					newValue;
			},
		},
		consultSwitchType: {
			get() {
				return this.$store.state.ApplicationFormCreationModule
					.consultSwitchType;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.consultSwitchType =
					newValue;
			},
		},
	},
	methods: {
		setNewData(name, value, beforeValue, itemName) {
			this.formData.basic[name] = value;

			// 22.06.24 교품접수 신청서(구신청서)에서는 접수취소 불가
			if (this.formData.basic.childApplId && value === 'RCPT_CANCEL') {
				alert(
					'교품접수 신청서입니다.\n신규 신청서에서 접수취소를 진행해 주세요.',
				);
				this.formData.basic[name] = beforeValue;
				return;
			}

			if (
				this.$route.name === 'consult-db' &&
				this.$store.state.cookies.cr !==
					'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4' &&
				this.$store.state.cookies.sg !== 'StoreGrade_M' &&
				!this.consultSwitchType
			) {
				if (value !== 'RCPT_CANCEL' && value !== 'RCPT_COMPL') {
					alert('상담DB 신청서에서는 접수취소와 접수완료만 변경 가능합니다.');
					this.formData.basic[name] = beforeValue;
					return;
				}
			}

			// 22.05.12 유심개통요청(상담)/유심개통완료(개통) 에서 다른 상태값 변형 X
			if (
				beforeValue === 'USIM_OPENING_DMND_CONSULT' ||
				beforeValue === 'USIM_OPENING_COMPL_OPENING'
			) {
				switch (beforeValue) {
					case 'USIM_OPENING_DMND_CONSULT':
						alert('유심개통요청 상태에서 다른상태로 변경 불가합니다.');
						break;
					default:
						alert('유심개통완료 상태에서 다른상태로 변경 불가합니다.');
				}
				this.formData.basic[name] = beforeValue;
				return;
			}
			// 22.05.12 유심개통요청(상담)/유심개통완료(개통) 상태로 변경 불가
			if (value === 'USIM_OPENING_DMND_CONSULT') {
				alert('유심개통요청 상태로 변경 불가합니다.');
				this.formData.basic[name] = beforeValue;
				return;
			}

			let data = {
				name: name,
				value: value,
				beforeValue: beforeValue,
				itemName: itemName,
			};
			if (
				value === 'OPENING_DMND_CONSULT' ||
				value === 'EXCHANGE_DMND_CONSULT' || // 2022-02-25 교품요청(상담) 추가
				value === 'OPENING_DMND_OPENING'
			) {
				let valJoin = [];
				let valPayment = [];
				valJoin = [
					'instlPeriodType', // 할부기간
					'agreementPeriodType', // 약정기간
					'releaseAmt', // 출고가
				]; // 22-01-07 상담/개통에서 상태값 "개통요청" 변경 시 요금제, 할부기간, 약정기간, 출고가 필수 확인(신청서가 모두 작성되어있는 상태)
				if (
					this.formData.join.chargeId === 1474 ||
					this.formData.join.chargeId === 1472 ||
					this.formData.join.chargeId === 1473
				) {
					alert('요금제를 선택해 주세요.');
					//chargeId
					index.state.validationFocus = 'chargeId';
					this.formData.basic[name] = beforeValue;
					return;
				}
				if (
					!nullValidation2(this.formData.join, valJoin) ||
					!nullValidation2(this.formData.payment, valPayment)
				) {
					this.formData.basic[name] = beforeValue;
					return;
				}
			}
			// 22.05.13 반품 도착지 선택 추가
			if (value === 'RETURN_DMND_CONSULT') {
				let workStatusData = data;
				this.workStatusData = workStatusData;
				setTimeout(() => {
					// ReturnTargetPop.vue의 v-click-outside 디렉티브가
					// 부모다이얼로그의 WorkStatus의 Select Input 이벤트에 영향을 받는 것으로 보임
					// 임시 조치로 input event의 propagation이 끝난 이후 다이얼로그를 여는 방식으로 수정함
					// 과거에는 문제없던 것이 vue 관련 업데이트 이후 동작방식의 변경 등이 발생한 것이 아닐까 추측함. 근거부족.
					this.$store.state.ApplicationFormCreationModule.returnDialog = true;
				}, 200);
				return;
			}
			this.$emit('changeWorkStatusFnc', data);
		},
		cancelApplReceipt() {
			this.setNewData(
				'consultTaskStatus',
				'RCPT_CANCEL',
				this.formData.basic.consultTaskStatus,
				'접수취소',
			);
		},
	},
	created() {
		bus.$on('cancelApplReceipt', this.cancelApplReceipt);
	},
	beforeDestroy() {
		bus.$off('cancelApplReceipt', this.cancelApplReceipt);
	},
};
</script>

<style scoped></style>
