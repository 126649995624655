<template>
	<div>
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="550">
				<v-card class="borderRadiReset infoPopup">
					<v-card-title
						class="headline backColorBlue3 popPad1 disFx justify-lg-space-between"
					>
						<h2 class="mainWhite mainFontS boldWt" v-if="this.reqYn === 'N'">
							요금 등록
						</h2>
						<h2 class="mainWhite mainFontS boldWt" v-else>요금 요청</h2>
						<span class="closeBtn1 cur_p" @click="dialog = !dialog"></span>
					</v-card-title>

					<v-card-text class="pad30">
						<p class="mainRed mj0">* 필수입력입니다</p>

						<div class="new_popTable1">
							<table class="w100P chargePop">
								<tr>
									<td>통신사<span class="redfont">*</span></td>
									<td>
										<v-select
											attach
											class="select_custom1"
											:items="codeData.telecom"
											item-text="codeNm"
											item-value="codeSeq"
											outlined
											v-model.trim="formData.telecom"
											name="division"
										>
										</v-select>
									</td>
								</tr>
								<tr>
									<td>통신망<span class="redfont">*</span></td>
									<td>
										<v-select
											attach
											class="select_custom1"
											:items="codeData.network"
											item-text="codeNm"
											item-value="codeSeq"
											outlined
											name="division"
											v-model.trim="formData.network"
										>
										</v-select>
									</td>
								</tr>
								<tr>
									<td>요금제명<span class="redfont">*</span></td>
									<td>
										<div class="td-wrap">
											<v-text-field
												class="input_custom1"
												outlined
												v-model.trim="formData.chargeName"
											></v-text-field>
											<button
												@click="onSearchPublicNoticeByName"
												class="button-search-public-notice"
											>
												공시검색
											</button>
										</div>
									</td>
								</tr>
								<tr>
									<td>카테고리<span class="redfont">*</span></td>
									<td>
										<v-select
											class="select_custom1"
											:items="chargeTypeItem"
											item-text="name"
											item-value="value"
											placeholder="카테고리를 선택해 주세요."
											outlined
											v-model.trim="formData.category"
											name="division"
										>
										</v-select>
									</td>
								</tr>
								<tr>
									<td>요금<span class="redfont">*</span></td>
									<td>
										<v-text-field
											class="input_custom1"
											outlined
											v-model.trim="formData.chargeAmt"
											placeholder="요금을 입력해 주세요."
											oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
										></v-text-field>
									</td>
								</tr>
								<tr>
									<td>요금코드<span class="redfont">*</span></td>
									<td>
										<v-text-field
											class="input_custom1"
											outlined
											v-model.trim="formData.chargeCode"
											placeholder="요금코드를 입력해 주세요."
										></v-text-field>
									</td>
								</tr>
							</table>
							<div class="disFx justify-end mt10">
								<v-btn
									v-if="this.reqYn === 'N'"
									class="backColorMint1 mainWhite borderReset w80"
									text
									@click="insertStoreFnc"
									outlined
								>
									등록
								</v-btn>
								<v-btn
									v-else
									class="backColorMint1 mainWhite borderReset w80"
									text
									@click="insertStoreFnc"
									outlined
								>
									요청
								</v-btn>
								<v-btn
									class="backColorGray1 mainWhite boldWt borderReset w80 ml10"
									text
									@click="dialog = !dialog"
									outlined
								>
									닫기
								</v-btn>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
			<raw-pub-noti-list-pop
				v-if="showRawPubNotiListPop"
				:telecom-name="selectedTelecomName"
				:search-keywords="chargeNameSelection"
				@close="showRawPubNotiListPop = false"
				@code-select="onChargeCodeSelected"
			/>
		</div>
	</div>
</template>

<script>
import { insertStore } from '../../../../api/common-mgmt/charge-mgmt/ChargeMgmtApi.js';
import { nullValidation } from '../../../../common/common.js';
import { commonCodeEnumList } from '@/api/common/common';
import RawPubNotiListPop from '@/views/common-mgmt/charge-mgmt/popup/RawPubNotiListPop.vue';

let _input = null;
function onSelectInputRange(evt) {
	// console.log(evt);
	const val = evt.target.value.slice(
		evt.target.selectionStart,
		evt.target.selectionEnd,
	);
	// console.log(val);
	this.chargeNameSelection = val?.trim();
}

export default {
	components: { RawPubNotiListPop },
	props: ['reqYn', 'codeData'],
	data() {
		return {
			telecomData: [
				{ name: 'SKT', value: 5 },
				{ name: 'KT', value: 6 },
				{ name: 'LGU', value: 7 },
			],
			chargeTypeItem: [],
			formData: {
				telecom: this.codeData.telecom[0].codeSeq,
				chargeName: '',
				chargeAmt: '',
				chargeCode: '',
				network: this.codeData.network[1],
				category: '',
			},
			chargeNameSelection: '',
			showRawPubNotiListPop: false,
		};
	},
	computed: {
		dialog: {
			get() {
				return this.$store.state.ChargeListModule.registDialog;
			},
			set(newValue) {
				this.$store.state.ChargeListModule.registDialog = newValue;
			},
		},
		getListData: {
			get() {
				return this.$store.state.ChargeListModule.getListData;
			},
			set(newValue) {
				this.$store.state.ChargeListModule.getListData = newValue;
			},
		},
		selectedTelecomName() {
			return this.telecomData.find((n) => n.value === this.formData.telecom)
				.name;
		},
	},
	mounted() {
		this.setSelectionEventChargeName();
	},
	beforeDestroy() {
		this.unsetSelectionEventChargeName();
	},
	methods: {
		setSelectionEventChargeName() {
			console.log('setSelectionEventChargeName()');
			_input = document.querySelector('.td-wrap .v-input input');
			_input.addEventListener('select', onSelectInputRange.bind(this));
		},
		unsetSelectionEventChargeName() {
			console.log('unsetSelectionEventChargeName()');
			_input.removeEventListener('select', onSelectInputRange.bind(this));
		},
		async commonCodeEnumList() {
			this.chargeTypeItem = [];
			const newEnum = {
				initEnumData: [
					'ChargeType', // 요금 카테고리
				],
			};
			const result = await commonCodeEnumList(newEnum);
			if (result) {
				this.chargeTypeItem = result.data.data.ChargeType;
			}
		},
		codeDataShiftFnc() {
			/* TODO 리펙토링 */
			const findItem2 = this.codeData.network.find((item) => {
				return item.codeSeq === 0;
			});
			if (findItem2 != undefined) {
				const idx = this.codeData.network.indexOf(findItem2);
				this.codeData.network.splice(idx, 1);
			}
			this.formData.network = this.codeData.network[0].codeSeq;
		},
		async getListFnc(data) {
			this.getListData = [];
			await this.$store.dispatch('ChargeListModule/getList', data);
		},
		async insertStoreFnc() {
			const data = {
				// category: this.formData.category,
				chargeId: 1,
				telecom: this.formData.telecom,
				network: this.formData.network,
				chargeName: this.formData.chargeName,
				useYn: 'N',
				delYn: 'N',
				matchingYn: 'N',
				category: this.formData.category,
				chargeAmt: this.formData.chargeAmt,
				reqYn: this.reqYn,
				chargeCode: this.formData.chargeCode,
			};
			if (!nullValidation(data)) {
				return;
			}
			const result = await insertStore(data);
			if (result.data.status === 200) {
				alert('저장되었습니다.');
				this.dialog = !this.dialog;
				this.getListFnc();
			} else {
				alert(`알 수 없는 오류가 발생했습니다.`);
			}
		},
		async onSearchPublicNoticeByName() {
			console.log('onSearchPublicNoticeByName', this.chargeNameSelection);
			if (this.formData.chargeName.length === 0) {
				alert('요금제명을 입력해주세요.');
				return;
			}
			if (this.chargeNameSelection.length === 0) {
				this.chargeNameSelection = this.formData.chargeName;
			}
			this.showRawPubNotiListPop = true;
		},
		onChargeCodeSelected(code) {
			console.log(`onChargeCodeSelected(${code})`);
			this.formData.chargeCode = code;
		},
	},
	async created() {
		await this.commonCodeEnumList();
		this.codeDataShiftFnc();
	},
};
</script>

<style scoped>
.td-wrap {
	display: flex;
}

.button-search-public-notice {
	flex: 1 1 auto;
	border: 1px solid #eeeeee;
	border-radius: 8px;
}
</style>
