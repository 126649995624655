import Vue from 'vue';
import Vuex from 'vuex';
import {
	getTodoList,
	getCurrentTaskList,
	getLogisticsTaskList,
	updateStoreReturn,
} from "@/api/common/rnb/saleRnb";
Vue.use(Vuex);

const TodoModule = {
	namespaced: true,
	state: {
		activeRnb: false,
		todoRnbCnt: 0,
		todoRnbList: [],
		currentTaskList: {
			consultCnt: 0,
			consultNoneAssignCnt: 0,
			consultNoneAssignList: [],
			consultNoneProgressCnt: 0,
			consultNoneProgressList: [],
			consultProcCnt: 0,
			consultProcList: [],
			creditInquireCnt: 0,
			creditInquireComplCnt: 0,
			creditInquireComplList: [],
			creditInquireErrCnt: 0,
			creditInquireErrList: [],
			customerCnt: 0,
			customerExchangeCnt: 0,
			customerExchangeList: [],
			customerRcptComplCnt: 0,
			customerRcptComplList: [],
			customerReqCnt: 0,
			customerReqList: [],
		},
		logisticsTaskList: {
			logisticsTaskCnt: 0,
			returnWaitCnt: 0,
			returnWaitList: [],
		},
		logisticsTaskReturnDialog: false,
	},
	mutations: {
		setTodoRnbList(state, result) {
			state.todoRnbList = result.data;
		},
		initTodoRnbList(state) {
			state.todoRnbList = [];
		},
		setCurrentTaskList(state, result) {
			state.currentTaskList = result.data;
		},
		initCurrentTaskList(state) {
			state.currentTaskList = {
				consultCnt: 0,
				consultNoneAssignCnt: 0,
				consultNoneAssignList: [],
				consultNoneProgressCnt: 0,
				consultNoneProgressList: [],
				consultProcCnt: 0,
				consultProcList: [],
				creditInquireCnt: 0,
				creditInquireComplCnt: 0,
				creditInquireComplList: [],
				creditInquireErrCnt: 0,
				creditInquireErrList: [],
				customerCnt: 0,
				customerExchangeCnt: 0,
				customerExchangeList: [],
				customerRcptComplCnt: 0,
				customerRcptComplList: [],
				customerReqCnt: 0,
				customerReqList: [],
			};
		},
		setLogisticsTaskList(state, result) {
			state.logisticsTaskList = result.data;
		},
		initLogisticsTaskList(state) {
			state.logisticsTaskList = {
				logisticsTaskCnt: 0,
				returnWaitCnt: 0,
				returnWaitList: [],
			}
		},
	},
	actions: {
		async getTodoRnbList({ commit }, data) {
			try {
				commit('initTodoRnbList');
				const result = await getTodoList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setTodoRnbList', result.data);
					return result.data.data;
				} else {
					commit('initTodoRnbList');
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getCurrentTaskList({ commit }, data) {
			try {
				if(data.path.indexOf('dashboard') === -1) { // 대시보드에선 초기화 X
					commit('initCurrentTaskList');
				}
				const result = await getCurrentTaskList();
				if (result && '0000' === result.data.resultCode) {
					commit('setCurrentTaskList', result.data);
					return result.data.data;
				} else {
					commit('initCurrentTaskList');
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getLogisticsTaskList({ commit }, data) {
			try {
				if(data.path.indexOf('dashboard') === -1) { // 대시보드에선 초기화 X
					commit('initLogisticsTaskList');
				}
				const result = await getLogisticsTaskList();
				if (result && '0000' === result.data.resultCode) {
					commit('setLogisticsTaskList', result.data);
					return result.data.data;
				} else {
					commit('initLogisticsTaskList');
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateStoreReturn({ commit }, data) {
			try {
				const result = await updateStoreReturn(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default TodoModule;
