import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/MallManagement/';

const getMallEvent = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MallEvent/getMallEvent${param}`,
	);
	return response;
};
const getSelectMallEventList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MallEvent/getSelectMallEventList${param}`,
	);
	return response;
};
const getMallLogo = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}MallLogo/getMallLogo${param}`);
	return response;
};
const insertMallLogo = async data => {
	const response = await instance.post(
		`${domain}MallLogo/insertMallLogo`,
		data,
	);
	return response;
};

const getMallBanner = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MallBanner/getMallBanner${param}`,
	);
	return response;
};

const insertMallBanner = async data => {
	const response = await instance.post(
		`${domain}MallBanner/insertMallBanner`,
		data,
	);
	return response;
};

const getMallConfig = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}MallConfigMgmt/getMallConfig${param}`,
	);
	return response;
};

const updateMallConfig = async data => {
	const response = await instance.post(
		`${domain}MallConfigMgmt/updateMallConfig`,
		data,
	);
	return response;
};

// 쇼핑몰 페이지 관리 사업자 정보 상세 조회
const getStoreDomainFooterInfo = async data => {
	const response = await instance.get(
		`${domain}StoreDomainMgmt/getStoreDomainFooterInfo`,
	);
	return response;
};

// 쇼핑몰 페이지 관리 사업자 정보 상세 수정
const updateStoreDomainFooterInfo = async data => {
	const response = await instance.post(
		`${domain}StoreDomainMgmt/updateStoreDomainFooterInfo`,
		data,
	);
	return response;
};

export {
	getMallEvent,
	getSelectMallEventList,
	getMallLogo,
	insertMallLogo,
	getMallBanner,
	insertMallBanner,
	getStoreDomainFooterInfo,
	updateStoreDomainFooterInfo,
	getMallConfig,
	updateMallConfig,
};
