<template>
	<div>
		<h3
			class="mainBlue2 mainFontS lh32 mt10"
			style="border-bottom: 1px solid #007ef2"
		>
			요금제 변경 시 예상요금
		</h3>
		<table class="w100P charge_change_table1 mt10">
			<tr>
				<td class="w40P">변경 요금제</td>
				<td>
					<div
						class="pop_active_input1"
						@click="deviceInforVal(formData.telecomId, '변경 요금제 선택')"
					>
						<input
							type="text"
							class="cal_table_input1 cur_p txAnR disGray w100P"
							v-model.trim="chgChargeName"
							@click="amtSelectPopFnc('변경 요금제 선택')"
							readonly
							:disabled="!formData.telecomId || !formData.goodsId"
						/>
					</div>
				</td>
			</tr>
			<tr>
				<td>변경 가능일자</td>
				<td>
					<div
						class="pop_active_input2"
						@click="deviceInforVal(formData.telecomId, '변경 가능일자 선택')"
					>
						<input
							class="borderContColor3 h40 borderRadi3Px w100P cur_p txAnR disGray"
							@click="amtSelectPopFnc('변경 가능일자 선택')"
							v-model.trim="changeDateName"
							type="text"
							readonly
							:disabled="
								!formData.telecomId || !formData.goodsId || !chgChargeDto
							"
						/>
					</div>
				</td>
			</tr>
			<tr>
				<td>공시차액정산금</td>
				<td>
					<div class="copyClass1">
						<input
							type="text"
							class="cal_table_input1 borderContColor3 amtInputBox1 amtNum w100P cur_d"
							maxlength="9"
							v-model.trim="settlementAmt"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							readonly
						/>
					</div>
				</td>
			</tr>
			<tr>
				<td>변경 월 청구금액</td>
				<td>
					<div class="copyClass1">
						<input
							type="text"
							class="cal_table_input1 borderContColor3 amtInputBox1 amtNum w100P cur_d"
							maxlength="9"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							v-model.trim="totalChgChargeAmt"
							readonly
						/>
					</div>
				</td>
			</tr>
		</table>
		<charge-board-pop
			v-if="chargeBoardPopDialog"
			:title="chargeBoardPopTitle"
		></charge-board-pop>
	</div>
</template>

<script>
import chargeBoardPop from './popup/chargeBoardPop.vue';
import { getFormatDate } from '@/common/common';
import NumRepMixin from '../../../../../../common/NumRepMixin';

export default {
	name: 'chargeBoardData',
	components: {
		chargeBoardPop,
	},
	mixins: [NumRepMixin],
	computed: {
		Interest: {
			get() {
				return this.$store.state.ChargeCalculatorModule.Interest;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.Interest = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		changeDate: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDate;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDate = newValue;
			},
		},
		changeDateName: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDateName;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDateName = newValue;
			},
		},
		chargeBoardPopTitle: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chargeBoardPopTitle;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chargeBoardPopTitle = newValue;
			},
		},
		chargeBoardPopDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chargeBoardPopDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chargeBoardPopDialog = newValue;
			},
		},
		chgChargeDto: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chgChargeDto;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chgChargeDto = newValue;
			},
		},
		chgChargeName() {
			if (!this.chgChargeDto) {
				return '';
			} else {
				return this.chgChargeDto.chargeName;
			}
		},

		chgChargeAmt() {
			if (!this.chgChargeDto) {
				return 0;
			} else {
				return Number(this.chgChargeDto.chargeAmt);
			}
		},

		chargeReductAmt() {
			// 요금감면
			if (!this.formData.chargeId) {
				return 0;
			} else {
				if (this.formData.chargeReductType === 'NONE') {
					return 0;
				} else if (this.formData.chargeReductType === 'DSBL') {
					return Math.round(Number(this.formData.monthBasicAmt) * 0.35) * -1;
				} else if (this.formData.chargeReductType === 'NTNL') {
					return Math.round(Number(this.formData.monthBasicAmt) * 0.35) * -1;
				} else if (this.formData.chargeReductType === 'BASE_RCP') {
					return -28600;
				} else if (this.formData.chargeReductType === 'PENSION_RCP') {
					return -12100;
				} else if (this.formData.chargeReductType === 'HIGHT') {
					return -12100;
				} else {
					return 0;
				}
			}
		},

		addServiceTotalAmt() {
			if (this.formData.insAmt || this.formData.addSvcAmt) {
				return this.formData.insAmt + this.formData.addSvcAmt;
			} else {
				return 0;
			}
		},

		supMonthDeviceAmt() {
			// 공시지원할인 - 월기기할부금
			if (!this.chgChargeDto) {
				return 0;
			} else {
				if (
					this.formData.instlPeriodType === 'CASH' ||
					!this.formData.instlPeriodType
				) {
					return 0;
				} else {
					let totalAmt =
						this.formData.releaseAmt +
						this.chgChargeDto.supportAmt * -1 +
						this.formData.extraSupportAmt +
						this.formData.freeInstlAmt +
						this.formData.etcDiscountAmt +
						this.formData.preCardAmt;

					if (this.monthDvcAmtFnc(totalAmt) < 0) {
						return 0;
					} else {
						return this.monthDvcAmtFnc(totalAmt);
					}
				}
			}
		},
		supMonthChargeAmt() {
			// 공시지원할인 - 월기본료
			if (
				Number(this.chgChargeAmt) +
					Number(this.formData.billCardAmt) +
					Number(this.chargeReductAmt) <
				0
			) {
				return 0;
			} else {
				return (
					Number(this.chgChargeAmt) +
					Number(this.formData.billCardAmt) +
					Number(this.chargeReductAmt)
				);
			}
		},
		supMonthTotalAmt() {
			// 공시지원할인 - 월청구금액
			const total =
				Number(this.supMonthDeviceAmt) +
				Number(this.supMonthChargeAmt) +
				Number(this.addServiceTotalAmt);
			return Number(total);
		},

		choiceMonthDeviceAmt() {
			// 선택약정할인 - 월기기할부금
			if (
				this.formData.instlPeriodType === 'CASH' ||
				!this.formData.instlPeriodType
			) {
				return 0;
			} else {
				let totalAmt =
					this.formData.releaseAmt +
					this.formData.freeInstlAmt +
					this.formData.etcDiscountAmt +
					this.formData.preCardAmt;

				if (this.monthDvcAmtFnc(totalAmt) < 0) {
					return 0;
				} else {
					return this.monthDvcAmtFnc(totalAmt);
				}
			}
		},
		choiceMonthChargeAmt() {
			// 선택약정할인 - 월기본료
			if (
				Number(this.chgChargeAmt) * 0.75 +
					Number(this.formData.billCardAmt) +
					Number(this.chargeReductAmt) <
				0
			) {
				return 0;
			} else {
				return (
					Number(this.chgChargeAmt) * 0.75 +
					Number(this.formData.billCardAmt) +
					Number(this.chargeReductAmt)
				);
			}
		},
		choiceMonthTotalAmt() {
			// 선택약정할인 - 월청구금액
			const total =
				Number(this.choiceMonthDeviceAmt) +
				Number(this.choiceMonthChargeAmt) +
				Number(this.addServiceTotalAmt);
			return Number(total);
		},

		totalChgChargeAmt() {
			if (this.chgChargeAmt) {
				if (this.formData.agreementType === 'PUBNOTI_SUPAMT') {
					return String(Math.round(this.supMonthTotalAmt)).replace(
						/\B(?=(\d{3})+(?!\d))/g,
						',',
					);
				} else {
					return String(Math.round(this.choiceMonthTotalAmt)).replace(
						/\B(?=(\d{3})+(?!\d))/g,
						',',
					);
				}
			} else {
				return 0;
			}
		},
		settlementAmt() {
			if (this.formData.agreementType === 'PUBNOTI_SUPAMT') {
				if (this.changeDate === 1 || this.changeDate === 3) {
					return 0;
				} else if (!this.changeDate) {
					return 0;
				} else {
					const minusDay = this.getDateDiff(
						getFormatDate(new Date()),
						this.changeDateName,
					);

					let network = this.chgChargeDto.network;
					let telPrice5g;
					let telPriceLte;
					if (this.formData.monthBasicAmt < this.chgChargeDto.chargeAmt) {
						return 0;
					}
					if (this.formData.telecomId === '5') {
						if (network === 14) {
							telPrice5g = 45000;
							if (this.chgChargeDto.chargeAmt >= telPrice5g) {
								return 0;
							}
						} else if (network === 15) {
							telPriceLte = 20000;
							if (this.chgChargeDto.chargeAmt >= telPriceLte) {
								return 0;
							}
						} else {
							return 0;
						}
					} else if (this.formData.telecomId === '6') {
						if (network === 14) {
							telPrice5g = 47000;
							if (this.chgChargeDto.chargeAmt >= telPrice5g) {
								return 0;
							}
						} else if (network === 15) {
							telPriceLte = 20000;
							if (this.chgChargeDto.chargeAmt >= telPriceLte) {
								return 0;
							}
						} else if (network === 16) {
							telPriceLte = 20000;
							if (this.chgChargeDto.chargeAmt >= telPriceLte) {
								return 0;
							}
						} else {
							return 0;
						}
					} else if (this.formData.telecomId === '7') {
						if (network === 14) {
							return 0;
						} else if (network === 15) {
							telPriceLte = 24000;
							if (this.chgChargeDto.chargeAmt >= telPriceLte) {
								return 0;
							}
						}
					}

					if (minusDay < 180) {
						return Math.abs(
							this.formData.pubNotiSupportAmt + this.chgChargeDto.supportAmt,
						);
					} else {
						let ssAmt = Math.round(
							(Math.abs(
								this.formData.pubNotiSupportAmt + this.chgChargeDto.supportAmt,
							) *
								(730 - minusDay)) /
								550,
						);

						return ssAmt < 0 ? 0 : ssAmt;
					}
				}
			} else {
				return 0;
			}
		},
	},
	methods: {
		deviceInforVal(telId, name) {
			if (!telId) {
				return alert('통신사를 선택해 주세요.');
			}
			if (name === '변경 요금제 선택') {
				if (!this.formData.goodsId) {
					alert('기기를 선택해 주세요.');
				}
			} else if (name === '변경 가능일자 선택') {
				if (!this.chgChargeDto) {
					alert('변경 요금제를 선택해 주세요.');
				}
			}
		},
		amtSelectPopFnc(title) {
			this.chargeBoardPopDialog = true;
			this.chargeBoardPopTitle = title;
		},
		monthDvcAmtFnc(monthTotalAmt) {
			// 월 기기 할부금 공식
			let Int12 = this.Interest / 12;
			let insP;

			switch (this.formData.instlPeriodType) {
				case 'MONTH_6':
					insP = 6;
					break;
				case 'MONTH_12':
					insP = 12;
					break;
				case 'MONTH_24':
					insP = 24;
					break;
				case 'MONTH_30':
					insP = 30;
					break;
				case 'MONTH_36':
					insP = 36;
					break;
				case 'MONTH_48':
					insP = 48;
					break;
				case 'CASH':
					insP = 0;
					break;
				default:
					insP = 0;
			}

			if (insP !== 0) {
				let frontNum = monthTotalAmt * Int12 * Math.pow(1 + Int12, insP);
				return Math.round(frontNum / (Math.pow(1 + Int12, insP) - 1));
			} else {
				return 0;
			}
		},
		getDateDiff(d1, d2) {
			const date1 = new Date(d1);
			const date2 = new Date(d2);

			const diffDate = date1.getTime() - date2.getTime();

			return Math.abs(diffDate / (1000 * 60 * 60 * 24));
		},
	},
	created() {
		this.chargeBoardPopDialog = false;
	},
};
</script>

<style scoped>
.charge_change_table1 > tr > td:first-child {
	padding: 0 30px;
}
.charge_change_table1 > tr > td input[type='text'] {
	font-weight: bolder;
}
</style>
