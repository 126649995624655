import Vue from 'vue';
import Vuex from 'vuex';
import {
	getInStockGroupList,
	getInStockList,
	updateInStock,
	deleteInStock,
	getInStockHistoryList,
	updateDeviceProvider,
} from '../../../../api/warehouse-mgmt/device-mgmt/InStockStatusApi';
import {
	getDeviceColorList,
	getDeviceList,
	getDeviceMemoList,
	getGoodsSelectCapacityList,
	getRetrieveDeviceHistory,
	getRetrieveDeviceOpenHistory,
	getRetrieveDeviceStatusInfo,
	getSessOpeningStoreList,
	insertDeviceMemo,
	selectCodeList,
	retrieveDeviceOwnerHierarchyStoreName,
} from '../../../../api/common/common';
import { commonCodeListFnc } from '../../../../common/common';
import { excelDownLoad } from '../../../../api/common/common.js';
import { commonCodeEnumList } from '../../../../api/warehouse-mgmt/device-mgmt/NormalInStockApi.js';

Vue.use(Vuex);

const InStockMgmtModule = {
	namespaced: true,
	state: {
		copyData: {},
		cntFlag: false,
		allCnt: 0,
		trnsCnt: 0,
		normalCnt: 0,
		returnCnt: 0,
		resetData: false,
		infoDialog: false,
		deleteDialog: false,
		regiDialog: false,
		getInStockListData: [],
		inStockWaitList: [], // 입고현황 유형별 입력정보
		listGroupDataView: [],
		listGroupDataViewCnt: 1,
		allCheckedBoxInput: '',
		checkListIdArray: [],
		detailHistory: [],
		detailData: [],
		detailMemoList: [],
		deviceStatusDetail: [],
		deviceOwnerHierarchyStoreList: [],
		totalCnt: 0,
		formData: {},
		filterData: {
			perPageCnt: 20,
			telecoms: '',
			provIds: '',
			stockIds: '',
			productFaultyYn: '',
			extrrStatuses: '',
			inStockTypes: '',
			goodsId: '',
			makers: '',
			barcode: '',
			goodsType: '',
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		codeList: {
			telecom: [],
			prov: [],
			maker: [],
			stock: [],
			openingStore: [],
			goodsTypeItems: [],
			deviceExtrrStatusItems: [
				{ name: '상', value: 'T' },
				{ name: '중', value: 'M' },
				{ name: '하', value: 'B' },
			],
			inStockTypeItems: [],
			capacity: [],
		},
		deviceItems: [],
		colorItems: [],
		capacityItems: [],
	},
	getters: {},
	mutations: {
		deviceSet(state, data) {
			state.deviceItems = [];
			state.deviceItems = data.data.data;
			if (state.deviceItems !== undefined) {
				state.deviceItems.unshift({ modelName: '전체', goodsId: '' });
			}
		},
		capacitySet(state, data) {
			state.capacityItems = data.data.data;
		},
		selectListSet(state, data) {
			state.codeList.stock = data.data.data;
			state.filterData.stockIds = '';
		},
		setCommonCodeList(state, data) {
			state.codeList.telecom = data.codeList.TELECOM;
			state.codeList.capacity = data.codeList.CAPACITY;
			state.codeList.maker = data.codeList.MAKER;
			state.codeList.prov = data.provList;
		},
		closeDialog(state, name) {
			if (name === 'infoDialog') {
				state.infoDialog = false;
			} else if (name === 'regiDialog') state.regiDialog = false;
		},
		getInStockListSet(state, result) {
			state.getInStockListData = Object.freeze(result.data.data.resultList);
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		filterInit(state) {
			state.deviceItems = [];
			state.deviceItems.unshift({ modelName: '전체', goodsId: '' });
			state.capacityItems = [];
			state.capacityItems.unshift({ capacity: '전체', value: '' });
			state.colorItems = [];
			state.colorItems.unshift({ colorName: '전체', value: '' });
			for (let data in state.filterData) {
				if (
					state.filterData.inStockRegiDate ||
					state.filterData.inStockEndDate ||
					state.filterData.orderDate
				) {
					continue;
				}
				state.filterData[data] = '';
			}
			state.filterData.perPageCnt = 20;
		},
		colorSet(state, data) {
			state.colorItems = [];
			state.colorItems = data.data.data;
			state.colorItems.unshift({ colorName: '전체', value: '' });
		},
		setUsimTypeItems(state, data) {
			state.codeList.goodsTypeItems = [];
			state.codeList.inStockTypeItems = [];
			state.codeList.goodsTypeItems = data.data.data.GoodsType;
			state.codeList.inStockTypeItems = data.data.data.InStockType;
			state.codeList.goodsTypeItems.unshift({ name: '전체', value: '' });
		},
		setDeviceHistory(state, result) {
			state.detailHistory = [];
			state.detailHistory = result.data.data;
		},
		setRetrieveDeviceOpenHistory(state, result) {
			state.detailData = [];
			if (result.data.resultCode === '0000') {
				state.detailData = result.data.data;
			} else {
				state.detailData = [];
			}
		},
		setCommonCodeNewList(state, result) {
			state.codeList.inStockTypeItems = [];
			state.codeList.inStockTypeItems = result;
		},
		setInStockGroupList(state, result) {
			state.inStockWaitList = [];
			state.allCnt = 0;
			state.trnsCnt = 0;
			state.normalCnt = 0;
			state.returnCnt = 0;
			state.inStockWaitList = Object.freeze(result.inStockGroupDtos);
			// 유형별 입력정보 타이틀 카운팅
			const data = Object.freeze(result.inStockGroupDtos);
			// 유형별 테이블 스크롤 페이징
			document.getElementById(`listGroupBox`).scroll(0, 0)
			state.listGroupDataViewCnt = 1;
			state.listGroupDataView = state.inStockWaitList.slice(0, state.listGroupDataViewCnt*100);
			for (let item in data) {
				// 전체
				state.allCnt = state.allCnt + data[item].goodsCount;
				switch (data[item].inStockTypeMsg) {
					case '정상입고': // 정상카운트
						state.normalCnt = state.normalCnt + data[item].goodsCount;
						break;
					case '이관입고': // 이관카운트
						state.trnsCnt = state.trnsCnt + data[item].goodsCount;
						break;
					case '반품입고': // 반품카운트
						state.returnCnt = state.returnCnt + data[item].goodsCount;
						break;
				}
			}
		},
		setSessOpeningStoreList(state, result) {
			state.codeList.openingStore = [];
			state.codeList.openingStore = result;
		},
		setDeviceMemoList(state, result) {
			state.detailMemoList = [];
			state.detailMemoList = result;
		},
		setDeviceStatusInfo(state, data) {
			state.deviceStatusDetail = [];
			state.deviceStatusDetail = data.data.data;
		},
		setOwnerHierarchyStoreName(state, data) {
			state.deviceOwnerHierarchyStoreList = [];
			state.deviceOwnerHierarchyStoreList = data.data.data;
		},
		initOwnerHierarchyStoreName(state, data) {
			state.deviceOwnerHierarchyStoreList = [];
		},
	},
	actions: {
		async commonCodeEnumList({ commit }) {
			let data = { initEnumData: ['GoodsType', 'InStockType'] };
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setUsimTypeItems', result);
			}
		},
		// 코드리스트 조회
		async getCommonCodeList({ commit }) {
			try {
				let data = {
					code: ['TELECOM', 'MAKER', 'CAPACITY'],
					initData: ['provList'],
				};
				const result = await commonCodeListFnc(data);
				if (result) {
					commit('setCommonCodeList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 입고현황 조회
		async getInStockList({ commit }, data) {
			let result = await getInStockList(data);
			if (result) {
				commit('getInStockListSet', result);
			}
		},
		// 입고이력현황 조회
		async getInStockHistoryList({ commit }, data) {
			let result = await getInStockHistoryList(data);
			if (result) {
				commit('getInStockListSet', result);
			}
		},
		// 입고현황 수정
		async updateInStock({ commit }, data) {
			try {
				const result = await updateInStock(data);
				if (result.data.status === 200) {
					alert('수정되었습니다.');
					commit('closeDialog', 'infoDialog');
					return true;
				} else {
					alert(`알 수 없는 오류가 발생했습니다.`);
					return false;
				}
				// null 체크
			} catch (e) {
				console.log(e);
			}
		},
		// 셀렉트 코드리스트콜
		async selectCodeList({ commit }, data) {
			const result = await selectCodeList(data);
			if (result) {
				commit('selectListSet', result);
			}
		},
		// 용량목록 코드리스트 콜
		async getCapacityList({ commit }, data) {
			const result = await getGoodsSelectCapacityList(data);
			if (result) {
				commit('capacitySet', result);
			}
		},
		// 기기목록 리스트콜
		async getDeviceList({ commit }, data) {
			const result = await getDeviceList(data);
			if (result) {
				commit('deviceSet', result);
			}
		},
		// 기기 색상 리스트콜
		async getDeviceColorList({ commit }, data) {
			const result = await getDeviceColorList(data);
			if (result) {
				commit('colorSet', result);
			}
		},
		// 공통 엑셀 다운로드
		async excelDownLoad({ commit }, data) {
			await excelDownLoad(data);
		},
		// 기기이력 콜
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceHistory(data);
				if (result) {
					commit('setDeviceHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 개통이력기기 콜
		async getRetrieveDeviceOpenHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceOpenHistory(data);
				if (result) {
					commit('setRetrieveDeviceOpenHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 유형별 입고리스트 조회
		async getInStockGroupList({ commit }, data) {
			try {
				let result = await getInStockGroupList(data);
				if (result) {
					commit('setInStockGroupList', result.data.data);
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 개통점 목록조회
		async getSessOpeningStoreList({ commit }, data) {
			try {
				let result = await getSessOpeningStoreList(data);
				if (result) {
					commit('setSessOpeningStoreList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getDeviceMemoList({ commit }, data) {
			try {
				let result = await getDeviceMemoList(data);
				if (result) {
					commit('setDeviceMemoList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertDeviceMemo({ commit }, data) {
			try {
				let result = await insertDeviceMemo(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetrieveDeviceStatusInfo({ commit }, data) {
			const result = await getRetrieveDeviceStatusInfo(data);
			if (result) {
				commit('setDeviceStatusInfo', result);
			}
		},
		async deleteInStock({ commit }, data) {
			try {
				let result = await deleteInStock(data);
				if (result && '0000' === result.data.resultCode) {
					alert('삭제되었습니다.');
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateDeviceProvider({ commit }, data) {
			try {
				let result = await updateDeviceProvider(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async retrieveDeviceOwnerHierarchyStoreName({ commit }, data) {
			commit('initOwnerHierarchyStoreName');
			const result = await retrieveDeviceOwnerHierarchyStoreName(data);
			if (result) {
				commit('setOwnerHierarchyStoreName', result);
			}
		},
	},
};

export default InStockMgmtModule;
