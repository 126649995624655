<template>
	<div>
		<div class="mt-6">
			<h2 class="mainFontS boldWt font-cre-blue text-center">이용약관 동의</h2>
		</div>
		<div class="disFx flex-column align-center pl-3">
			<div
				v-for="(item, index) in termsList"
				:key="`terms${index}`"
				class="mt-4 disFx justify-space-between w80P"
			>
				<div class="checkStyleCont2">
					<input
            v-if="item.storesTermsDto.termsRequiredYn == 'Y'"
						:id="`terms_${item.storesTermsType}`"
						class="checkStyle"
						type="checkbox"
						v-model="terms[item.storesTermsType]"
					/>
          <input
              v-if="item.storesTermsDto.termsRequiredYn == 'N'"
              :id="`terms_${item.storesTermsType}`"
              class="checkStyle"
              type="checkbox"
              v-model="optionalTerms[item.storesTermsType]"
          />
					<label
						class="pl-2"
						:for="`terms_${item.storesTermsType}`"
						style="cursor: pointer"
						@click="openTerms($event, item)"
					>
						{{ item.storesTermsTypeMsg }}
						<span
							v-if="item.storesTermsDto.termsRequiredYn === 'Y'"
							class="font-cre-blue"
							>[필수]</span
						>
						<span
							v-if="item.storesTermsDto.termsRequiredYn === 'N'"
							class="font-gray"
							>[선택]</span
						>
					</label>
				</div>
				<a>
					<span class="lookAtTermsBtn" @click="seeTerms(item)">보기 </span>
					&gt;
				</a>
			</div>
		</div>
		<terms-check-pop
			v-if="termsCheckDialog"
			:termsTitle="termsTitle"
			:termsType="termsType"
			:termsContents="termsContents"
			:readMode="readMode"
			:termsChecked="termsChecked"
			@agreeTerms="agreeTerms"
		></terms-check-pop>
	</div>
</template>

<script>
import TermsCheckPop from './popup/TermsCheckPop';
import { getStoresTermsList } from '../api/mall_mgmt/MallTermsMgmtApi';
export default {
	name: 'TermsTemplate',
	components: { TermsCheckPop },
	data: () => ({
		termsList: [],
		terms: {},
    optionalTerms: {},
		termsTitle: '',
		termsType: '',
		termsContents: '',
		readMode: false,
		termsChecked: false,
		needToCheckList: [],
		agreeIdList: [],
	}),
	props: {
		preAgreeIdList: { default: () => [] },
	},
	computed: {
		termsCheckDialog: {
			get() {
				return this.$store.state.termsCheckDialog;
			},
			set(newValue) {
				this.$store.state.termsCheckDialog = newValue;
			},
		},
	},
	methods: {
		setTermsInfo(item) {
			this.termsTitle = item.storesTermsTypeMsg;
			this.termsContents = item.storesTermsDto.termsContents;
		},
		openTerms(e, item) {
      let termsArr = {}
      if(this.needToCheckList.includes(item.storesTermsType)) {
        termsArr = this.terms;
      } else {
        termsArr = this.optionalTerms;
      }

      if (termsArr[item.storesTermsType]) {
        document.getElementById(
            `terms_${item.storesTermsType}`,
        ).checked = false;
        termsArr[item.storesTermsType] = false;
        if(this.needToCheckList.includes(item.storesTermsType)) {
          this.checkIfAllChecked();
        }
        const idx = this.agreeIdList.indexOf(item.storesTermsDto.storesTermsId);
        this.agreeIdList.splice(idx, 1);
        this.emitCheckedData();
        e.preventDefault();
      } else {
        this.termsCheckDialog = true;
        this.termsType = item.storesTermsType;
        this.termsChecked = false;
        this.readMode = false;
        this.setTermsInfo(item);
        e.preventDefault();
      }

      // if(this.needToCheckList.includes(item.storesTermsType)) {
      //   if (this.terms[item.storesTermsType]) {
      //     document.getElementById(
      //         `terms_${item.storesTermsType}`,
      //     ).checked = false;
      //     this.terms[item.storesTermsType] = false;
      //     this.checkIfAllChecked();
      //     const idx = this.agreeIdList.indexOf(item.storesTermsDto.storesTermsId);
      //     this.agreeIdList.splice(idx, 1);
      //     this.emitCheckedData();
      //     e.preventDefault();
      //   } else {
      //     this.termsCheckDialog = true;
      //     this.termsType = item.storesTermsType;
      //     this.termsChecked = false;
      //     this.readMode = false;
      //     this.setTermsInfo(item);
      //     e.preventDefault();
      //   }
      // } else {
      //   if (this.optionalTerms[item.storesTermsType]) {
      //     document.getElementById(
      //         `terms_${item.storesTermsType}`,
      //     ).checked = false;
      //     this.optionalTerms[item.storesTermsType] = false;
      //     const idx = this.agreeIdList.indexOf(item.storesTermsDto.storesTermsId);
      //     this.agreeIdList.splice(idx, 1);
      //     this.emitCheckedData();
      //     e.preventDefault();
      //   } else {
      //     this.termsCheckDialog = true;
      //     this.termsType = item.storesTermsType;
      //     this.termsChecked = false;
      //     this.readMode = false;
      //     this.setTermsInfo(item);
      //     e.preventDefault();
      //   }
      // }
		},
		seeTerms(item) {
			this.termsCheckDialog = true;
			this.termsType = item.storesTermsType;
			this.termsChecked = this.terms[item.storesTermsType];
			this.readMode = true;
			this.setTermsInfo(item);
		},
		emitCheckedData() {
			this.$emit('getCheckedTermData', this.agreeIdList);
		},
		checkIfAllChecked() {
			if (this.needToCheckList.length > 0) {
				let notChecked = this.needToCheckList.find(item => {
					if (this.terms[item] === false) {
						return true;
					}
				});
				notChecked
					? this.$emit('checkAllChecked', false)
					: this.$emit('checkAllChecked', true);
			}
		},
		agreeTerms(storesTermsType) {
      console.log(storesTermsType);
      if(this.needToCheckList.includes(storesTermsType)) {
        this.terms[storesTermsType] = true;
        this.agreeIdList.push(
            {
              storeTermsId: this.termsList.find(item => item.storesTermsType === storesTermsType)
                  .storesTermsDto.storesTermsId,
              required: true
            }
        );
      } else {
        this.optionalTerms[storesTermsType] = true;
        this.agreeIdList.push(
            {
              storeTermsId: this.termsList.find(item => item.storesTermsType === storesTermsType)
                  .storesTermsDto.storesTermsId,
              required: false
            }

        );
      }
			this.checkIfAllChecked();
			this.emitCheckedData();
		},
		async getStoresTermsList() {
			const result = await getStoresTermsList();
			if (result && result.data.resultCode === '0000') {
				this.termsList = result.data.data;
				this.termsList.map(item => {
					if (
						this.preAgreeIdList.length > 0 &&
						this.preAgreeIdList.includes(item.storesTermsDto.storesTermsId)
					) {
            if(item.storesTermsDto.termsRequiredYn == 'Y') {
						  this.terms[item.storesTermsType] = true;
            } else {
              this.optionalTerms[item.storesTermsType] = true;
            }

						this.agreeIdList.push({
              storeTermsId: item.storesTermsDto.storesTermsId,
              required: item.storesTermsDto.termsRequiredYn === 'Y' ? true: false
            })


					} else {
            if(item.storesTermsDto.termsRequiredYn == 'Y') {
              this.terms[item.storesTermsType] = false;
            } else {
              this.optionalTerms[item.storesTermsType] = false;
            }
					}

					if (item.storesTermsDto.termsRequiredYn === 'Y') {
						this.needToCheckList.push(item.storesTermsType);
					}
				});
			} else {
				this.terms = {};
        this.optionalTerms = {};
			}
		},
	},
	async created() {
		await this.getStoresTermsList();
	},
};
</script>

<style scoped>
.checkStyleCont2 label:before {
	top: 2px !important;
}
</style>
