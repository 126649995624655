import Vue from 'vue';
import Vuex from 'vuex';
import {
	getBondMgmtList,
	getBondCompensationList,
	getBondMgmtSummary,
	getBondMgmtGroupSummary,
	getPubInfoData,
	getPubInfoDataWms,
	updateBondCompe,
	updateBondInComplYn,
	updateBondOutComplYn,
	updateBondHoldOver,
	updateBulkBondCompe,
	updateBulkBondInComplYn,
	updateBulkBondOutComplYn,
	updateBulkBondInOutComplYn,
	saveMonthAsset,
	getBondMonthSummary,
	getBondMonthGroupSummary,
} from '@/api/settlement-mgmt/SktBondMgmtApi';
import { commonCodeEnumList } from '@/api/warehouse-mgmt/device-mgmt/NormalInStockApi';
import { getRetrieveDeviceHistory } from '@/api/common/common';
import { getDateFormat } from '@/common/common';

Vue.use(Vuex);

const SktBondMgmtModule = {
	namespaced: true,
	state: {
		compeDialog: false,
		infoDialog: false,
		totalCnt: 0,
		selectedMonth: 0,
		selectedYear: 0,
		selectedOpeningStoreIds: [],
		// selectedStockYn: 'Y',
		assetAmt: 0,
		statusList: [],
		detailHistory: [],
		bondMgmtList: [],
		bondMgmtSummary: {
			prevMonthSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
			nowMonthSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
			accrueSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
			holdOverOpeningSummaryList: {
				unlockY: {}, // 자가유통
				unlockN: {}, // 통신사향
			},
		},
		bondMgmtGroupSummary: {
			prevMonthSummaryList: {
				device: [],
				usim: [],
			},
			nowMonthSummaryList: {
				device: [],
				usim: [],
			},
		},
		compeComplList: [],
		noneComplList: [],
		bondCompensationList: [],
		bondMonthSummaryList: [],
		tblArr: [],
		summaryGroupType: 'NORMAL',
		filterData: {
			barcode: '',
			goodsName: '',
			stockIds: '',
			provIds: 0,
			openingHistoryStoreIds: [],
			orderDate: '',
			orderEndDate: '',
			inStockRegiDate: '',
			inStockEndDate: '',
			openingDate: '',
			openingEndDate: '',
			goodsType: '',
			maker: '',
			unLockYn: '',
			compensationTargetYn: '',
			compensationYn: '',
			retractYn: '',
			stockYn: '',
			srhYear: 0,
			telecom: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		filterDataCompe: {
			compensationDate: '',
			compensationEndDate: '',
			openingHistoryStoreIds: [],
			goodsName: '',
			maker: '',
			unLockYn: '',
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		enumCodeList: {},
		monthFilterData: {
			openingHistoryStoreIds: [],
			srhYear: 0,
			telecom: 6,
			// perPageCnt: 20,
			// pageNo: 1,
		},
	},
	mutations: {
		initCompeComplList(state) {
			state.compeComplList = [];
			state.noneComplList = [];
			state.bondCompensationList = [];
		},
		insertToFilterData(state) {
			state.filterData.srhYear = state.selectedYear;
			state.filterData.srhMonth = state.selectedMonth;
			state.filterData.openingHistoryStoreIds = state.selectedOpeningStoreIds;
			// state.filterData.stockYn = state.selectedStockYn;
		},
		filterInit(state) {
			for (const data in state.filterData) {
				state.filterData[data] = '';
			}
			state.filterData.srhYear = state.selectedMonth;
			state.filterData.srhMonth = state.selectedYear;
			state.filterData.openingHistoryStoreIds = state.selectedOpeningStoreIds;
			// state.filterData.stockYn = state.selectedStockYn;
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
			state.filterData.goodsName = '전체';
		},
		initPagingData(state) {
			for (const data in state.pagingData) {
				state.pagingData[data] = 0;
			}
			state.pagingData.perPageCnt = 20;
			state.pagingData.pageNumList = [];
		},
		setBondMgmtList(state, result) {
			state.bondMgmtList = result.resultList;
			state.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setBondMgmtSummary(state, result) {
			for (let i in result) {
				if (result[i].length > 0) {
					for (let j = 0; j < result[i].length; j++) {
						// 자가유통
						if (result[i][j].unLockYn === 'Y') {
							state.bondMgmtSummary[i].unlockY = result[i][j];
						} else if (result[i][j].unLockYn === 'N') {
							state.bondMgmtSummary[i].unlockN = result[i][j];
						}
					}
				}
			}
		},
		initBondMgmtSummary(state) {
			state.bondMgmtSummary = {
				prevMonthSummaryList: {
					unlockY: {},
					unlockN: {},
				},
				nowMonthSummaryList: {
					unlockY: {},
					unlockN: {},
				},
				accrueSummaryList: {
					unlockY: {},
					unlockN: {},
				},
				holdOverOpeningSummaryList: {
					unlockY: {},
					unlockN: {},
				},
			};
		},
		setBondCompensationList(state, result) {
			state.bondCompensationList = result.data;
			state.compeComplList = result.data.complList;
			state.noneComplList = result.data.noneComplList;
		},
		setUsimTypeItems(state, data) {
			state.enumCodeList.goodsTypeItems = [];
			state.enumCodeList.goodsTypeItems = data.data.data.GoodsType;
			state.enumCodeList.goodsTypeItems.unshift({ name: '전체', value: '' });
		},
		setDeviceHistory(state, result) {
			state.deviceHistory = result;
		},
		initDeviceHistory(state) {
			state.deviceHistory = [];
		},
		initBondMonthSummary(state) {
			state.bondMonthSummaryList = [
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
			];
			state.tblArr = [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			];
			state.bondMonthGroupSummary = [
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
				{ nowMonthSummaryList: [] },
			];
		},
		setBondMonthSummary(state, result) {
			state.bondMonthSummaryList = result;
			// for (let i in result) {
			// 	state.bondMonthSummaryList.push(result[i].nowMonthSummaryList);
			// }
			console.log(state.bondMonthSummaryList);
		},
		setDefaultOrderDate(state) {
			state.filterData.orderDate = getDateFormat(
				new Date(state.selectedYear, state.selectedMonth - 1, 1),
			);
			state.filterData.orderEndDate = getDateFormat(
				new Date(state.selectedYear, state.selectedMonth, 0),
			);
		},
		initBondMgmtGroupSummary(state) {
			if (state.summaryGroupType === 'NORMAL') {
				state.bondMgmtGroupSummary.prevMonthSummaryList = [];
				state.bondMgmtGroupSummary.nowMonthSummaryList = [];
			} else {
				state.bondMgmtGroupSummary = {
					prevMonthSummaryList: {
						device: [],
						usim: [],
					},
					nowMonthSummaryList: {
						device: [],
						usim: [],
					},
				};
			}
		},
		setBondMgmtGroupSummary(state, result) {
			if (state.summaryGroupType === 'NORMAL') {
				state.bondMgmtGroupSummary.prevMonthSummaryList =
					result.prevMonthSummaryList || [];
				state.bondMgmtGroupSummary.nowMonthSummaryList =
					result.nowMonthSummaryList || [];
			} else {
				for (let i in result) {
					if (result[i] !== null && result[i].length > 0) {
						for (let j = 0; j < result[i].length; j++) {
							if (result[i][j].goodsType === 'DVC') {
								state.bondMgmtGroupSummary[i].device.push(result[i][j]);
							} else if (result[i][j].goodsType === 'USIM') {
								state.bondMgmtGroupSummary[i].usim.push(result[i][j]);
							}
						}
					}
				}
			}
		},
	},
	actions: {
		async getBondMgmtList({ commit }, data) {
			try {
				if (data && data.goodsName === '전체') {
					data.goodsName = '';
				}
				commit('insertToFilterData');
				const result = await getBondMgmtList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondMgmtList', result.data.data);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondCompensationList({ commit }, data) {
			try {
				if (data && data.goodsName === '전체') {
					data.goodsName = '';
				}
				const result = await getBondCompensationList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondCompensationList', result.data);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMgmtSummary({ commit }, data) {
			try {
				commit('initBondMgmtSummary');
				const result = await getBondMgmtSummary(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondMgmtSummary', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMgmtGroupSummary({ commit }, data) {
			try {
				commit('initBondMgmtGroupSummary');
				const result = await getBondMgmtGroupSummary(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setBondMgmtGroupSummary', result.data.data);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		//기기 공시지원금조회 (CWS)
		async getPubInfoData({ commit }, data) {
			try {
				const result = await getPubInfoData(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		//기기 공시지원금조회 (WMS)
		async getPubInfoDataWms({ commit }, data) {
			try {
				const result = await getPubInfoDataWms(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
					// commit('setBondMgmtList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondInComplYn({ commit }, data) {
			try {
				const result = await updateBondInComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondOutComplYn({ commit }, data) {
			try {
				const result = await updateBondOutComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondHoldOver({ commit }, data) {
			try {
				const result = await updateBondHoldOver(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBondCompe({ commit }, data) {
			try {
				const result = await updateBondCompe(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBulkBondCompe({ commit }, data) {
			try {
				const result = await updateBulkBondCompe(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBulkBondInComplYn({ commit }, data) {
			try {
				const result = await updateBulkBondInComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBulkBondOutComplYn({ commit }, data) {
			try {
				const result = await updateBulkBondOutComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateBulkBondInOutComplYn({ commit }, data) {
			try {
				const result = await updateBulkBondInOutComplYn(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async saveMonthAsset({ commit }, data) {
			try {
				const result = await saveMonthAsset(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async commonCodeEnumList({ commit }) {
			const data = { initEnumData: ['GoodsType'] };
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setUsimTypeItems', result);
			}
		},
		async getEnumCommonCodeList({ commit }) {
			try {
				/* 신규 ENUM 데이터 */
				const newEnum = {
					initEnumData: ['RetractType'],
				};
				const result = await commonCodeEnumList(newEnum);
				if (result) {
					commit('setCommonCodeNewList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 이력 리스트 조회
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				const result = await getRetrieveDeviceHistory(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMonthSummary({ commit }, data) {
			try {
				commit('initBondMonthSummary');
				const result = await getBondMonthSummary(data);
				if (result && result.data.resultCode === '0000') {
					commit('setBondMonthSummary', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getBondMonthGroupSummary({ commit }, data) {
			try {
				const result = await getBondMonthGroupSummary(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

function dvc_usim_chk(item) {
	if (item.DVC_N === undefined) {
		item['DVC_N'] = {};
	}
	if (item.DVC_Y === undefined) {
		item['DVC_Y'] = {};
	}
	if (item.USIM_N === undefined) {
		item['USIM_N'] = {};
	}
	if (item.USIM_Y === undefined) {
		item['USIM_Y'] = {};
	}
	let amtTypeArr = ['DVC_N', 'DVC_Y', 'USIM_N', 'USIM_Y'];
	amtTypeArr.forEach(atTxt => {
		amtTypeChk(item[atTxt]);
	});
}
function amtTypeFnc(item) {
	let resType = '';
	if (item.agreementTypeMsg === '' && item.instlPeriodTypeMsg === '') {
		resType = 'AT_0';
	} else if (
		item.agreementTypeMsg === '선택약정' &&
		item.instlPeriodTypeMsg === '현금'
	) {
		resType = 'AT_1';
	} else if (
		item.agreementTypeMsg === '선택약정' &&
		item.instlPeriodTypeMsg === '할부'
	) {
		resType = 'AT_2';
	} else if (
		item.agreementTypeMsg === '공시지원금' &&
		item.instlPeriodTypeMsg === '현금'
	) {
		resType = 'AT_3';
	} else if (
		item.agreementTypeMsg === '공시지원금' &&
		item.instlPeriodTypeMsg === '할부'
	) {
		resType = 'AT_4';
	}
	return resType;
}
function amtTypeChk(item) {
	if (item.AT_0 === undefined)
		item['AT_0'] = {
			inAmt: 0,
			outAmt: 0,
			reInAmt: 0,
			reOutAmt: 0,
			supportAmt: 0,
			retirementAmt: 0,
			totCnt: 0,
			totInAmt: 0,
		};
	if (item.AT_1 === undefined)
		item['AT_1'] = {
			inAmt: 0,
			outAmt: 0,
			reInAmt: 0,
			reOutAmt: 0,
			supportAmt: 0,
			retirementAmt: 0,
			totCnt: 0,
			totInAmt: 0,
		};
	if (item.AT_2 === undefined)
		item['AT_2'] = {
			inAmt: 0,
			outAmt: 0,
			reInAmt: 0,
			reOutAmt: 0,
			supportAmt: 0,
			retirementAmt: 0,
			totCnt: 0,
			totInAmt: 0,
		};
	if (item.AT_3 === undefined)
		item['AT_3'] = {
			inAmt: 0,
			outAmt: 0,
			reInAmt: 0,
			reOutAmt: 0,
			supportAmt: 0,
			retirementAmt: 0,
			totCnt: 0,
			totInAmt: 0,
		};
	if (item.AT_4 === undefined)
		item['AT_4'] = {
			inAmt: 0,
			outAmt: 0,
			reInAmt: 0,
			reOutAmt: 0,
			supportAmt: 0,
			retirementAmt: 0,
			totCnt: 0,
			totInAmt: 0,
		};
}
export default SktBondMgmtModule;
