<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" width="500">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 disFx justify-lg-space-between"
						style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">월 사용액 선택</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad10">
						<div
							class="device_table_style1 mt15 narrowScroll posR"
							style="
								max-height: 500px !important;
								height: auto;
								margin-bottom: 15px;
							"
						>
							<table class="w100P">
								<tr v-if="monthAmtItem.length === 0" style="cursor: default">
									<td class="txAnC" colspan="20">데이터가 없습니다.</td>
								</tr>
								<tr
									class="cur_p"
									v-else
									v-for="(item, index) in monthAmtItem"
									:key="index"
									@click="monthAmtFnc(item)"
								>
									<td class="mainBlack">
										<span v-if="item.disType === 'PROMOTION'">[프로모션]</span>
										{{ item.disCondition }}, 월
										{{
											String(item.disMoney).replace(
												/\B(?=(\d{3})+(?!\d))/g,
												',',
											)
										}}
										원 할인
										{{ item.contents ? item.contents : '' }}
									</td>
								</tr>
							</table>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BillCardConditionPop',
	computed: {
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.billCardConditionDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.billCardConditionDialog = newValue;
			},
		},
		monthAmtItem: {
			get() {
				return this.$store.state.ChargeCalculatorModule.billCardItem
					.monthAmtItem;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.billCardItem.monthAmtItem = newValue;
			},
		},
		billPopFormData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.billPopFormData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.billPopFormData = newValue;
			},
		},
	},
	methods: {
		monthAmtFnc(item) {
			this.billPopFormData.monthAmt = item;
			this.dialog = false;
		},
	},
};
</script>

<style scoped></style>
