
import Vue from 'vue';
import PostApi from '../../../components/PostApi.vue';
import {
	customerFormData,
	joinFormData,
	paymentFormData,
} from '../../../store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import focusMixin from '../../../common/focusMixin.js';

interface dataType {
	prevApplId: number;
	postDialog: boolean;
	menu1: boolean;
}

export default Vue.extend({
	name: 'PaymentInformation',
	components: { PostApi },
	mixins: [focusMixin],
	data: (): dataType => ({
		prevApplId: 28177,
		postDialog: false,
		menu1: false,
	}),
	computed: {
		AppFlag: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.AppFlag;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.AppFlag = newValue;
			},
		},
		paymentCheckType: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.paymentCheckType;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.paymentCheckType =
					newValue;
			},
		},
		paymentData: {
			get(): paymentFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.payment;
			},
			set(newValue: paymentFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.payment =
					newValue;
			},
		},
		basicData: {
			get(): customerFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.basic;
			},
			set(newValue: customerFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.basic =
					newValue;
			},
		},
		customerData: {
			get(): customerFormData {
				return this.$store.state.ApplicationFormCreationModule.formData
					.customer;
			},
			set(newValue: customerFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.customer =
					newValue;
			},
		},
		addrDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.dialogData
					.paymentAddrDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.dialogData.paymentAddrDialog =
					newValue;
			},
		},
		joinData: {
			get(): joinFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.join;
			},
			set(newValue: joinFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.join =
					newValue;
			},
		},
		billTypeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.billTypeItems;
		},
		bankCodeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.bankCodeItems;
		},
		cardCompanyCodeItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.cardCompanyCodeItems;
		},
	},
	methods: {
		addressData(data: any) {
			this.paymentData.giroZipCode = data.zonecode;
			this.paymentData.giroAddr = data.address;
		},
		dialogClose(data: any) {
			this.postDialog = data;
		},
		addressFnc() {
			if (this.addrDialog) {
				this.paymentData.giroZipCode = this.customerData.cusZipCode;
				this.paymentData.giroAddr = this.customerData.cusAddr;
				this.paymentData.giroAddrDetail = this.customerData.cusAddrDetail;
			} else {
				this.paymentData.giroZipCode = '';
				this.paymentData.giroAddr = '';
				this.paymentData.giroAddrDetail = '';
			}
		},
		paymentTypeFnc() {
			this.paymentData.billType = null;
			this.resetPaymentTypeFnc();
			this.paymentData.billType = null;
			// @ts-ignore
			this.paymentData.paymentIdentifiedYn = null;
			if (this.paymentCheckType) {
				return (this.paymentData.paymentType = 'EXIST_SAME');
			} else {
				this.paymentData.paymentIdentifiedYn = 'Y';
				this.paymentData.paymentType = 'ACC_TRNS';
				return;
			}
		},
		resetPaymentTypeFnc() {
			if (!this.paymentData.paymentIdentifiedYn) {
				this.paymentData.paymentIdentifiedYn = 'Y';
			}
			this.paymentData.bankCode = null; // 은행정보
			this.paymentData.accountNum = null; // 계좌번호
			this.paymentData.accountHolderName = null; // 예금주
			this.paymentData.accountHolderBirth = null; // 생년월일
			this.paymentData.accountHolderRelation = null; // 관계
			this.paymentData.cardCompanyCode = null; // 카드정보
			this.paymentData.cardNum1 = null; // 카드번호
			this.paymentData.cardNum2 = null; // 카드번호
			this.paymentData.cardNum3 = null; // 카드번호
			this.paymentData.cardNum4 = null; // 카드번호
			this.paymentData.cardHolderName = null; // 카드주
			this.paymentData.cardHolderRegiNum1 = null; // 주민등록번호
			this.paymentData.cardHolderRegiNum2 = null; // 주민등록번호
			this.paymentData.cardValidityMm = null; // 유효기간MM
			this.paymentData.cardValidityYy = null; // 유효기간YY
			this.paymentData.giroZipCode = null; // 우편번호
			this.paymentData.giroAddr = null; // 기본주소
			this.paymentData.giroAddrDetail = null; // 상세주소
			this.addrDialog = false; // 고객주소와 동일
		},
	},
});
