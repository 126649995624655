<template>
	<div class="receiveTableCont1">
		<ReceiveRequestDetail v-if="!firstFlag"></ReceiveRequestDetail>
		<template v-if="(addRequest && !recipData) || firstFlag">
			<h2 class="mb20 mt10">
				<template v-if="!firstFlag">
					<template v-if="listNum">
						<span>{{ listNum.length + 1 }}</span
						>차수납
					</template>
					<template v-if="!listNum"> <span>2</span>차수납 </template>
				</template>
				<template v-if="firstFlag"><span>1</span>차수납 </template>
			</h2>
			<div class="new_popTable1 mt15">
				<table class="w100P mainBlack">
					<tr>
						<td rowspan="4" class="w140">
							수납금액<span class="redfont">*</span>
						</td>
						<td>
							<div class="disFx">
								<div class="checkStyleCont4 lh36 floatL w50 ml20">
									<input
										type="checkbox"
										id="NONPAYMENT1"
										class="checkStyle"
										name="NONPAYMENT1"
										:true-value="'Y'"
										:false-value="'N'"
										v-model.trim="nonPaymentAmtDialog"
									/>
									<label for="NONPAYMENT1">미납금</label>
								</div>
								<input
									class="borderRadi3Px borderContColor3 w150 h36 padW10 ml80 disGray txAnR"
									v-model.trim="formData.nonPaymentAmt"
									type="text"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									:disabled="nonPaymentAmtDialog === 'N'"
									maxlength="7"
									@input="totalAmtFnc"
								/>
								<span class="lh36 ml10">원</span>
							</div>
						</td>
					</tr>
					<tr>
						<td class="backColorWhite">
							<div class="disFx">
								<div class="checkStyleCont4 lh36 floatL w50 ml20">
									<input
										type="checkbox"
										id="instlPeriod1"
										class="checkStyle"
										name="instlPeriod1"
										:true-value="'Y'"
										:false-value="'N'"
										v-model.trim="instlAmtDialog"
									/>
									<label for="instlPeriod1">할부금</label>
								</div>
								<input
									class="borderRadi3Px borderContColor3 w150 h36 padW10 ml80 disGray txAnR"
									type="text"
									v-model.trim="formData.instlAmt"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									:disabled="instlAmtDialog === 'N'"
									maxlength="7"
									@input="totalAmtFnc"
								/>
								<span class="lh36 ml10">원</span>
							</div>
						</td>
					</tr>
					<tr>
						<td class="backColorWhite">
							<div class="disFx">
								<div class="checkStyleCont4 lh36 floatL w50 ml20">
									<input
										type="checkbox"
										id="chargeChk1"
										class="checkStyle"
										name="chargeChk1"
										:true-value="'Y'"
										:false-value="'N'"
										v-model.trim="recipAmtDialog"
									/>
									<label for="chargeChk1">요금수납</label>
								</div>
								<input
									class="borderRadi3Px borderContColor3 w150 h36 padW10 ml80 disGray txAnR"
									type="text"
									v-model.trim="formData.recipAmt"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									:disabled="recipAmtDialog === 'N'"
									maxlength="7"
									@input="totalAmtFnc"
								/>
								<span class="lh36 ml10">원</span>
							</div>
						</td>
					</tr>
					<tr>
						<td class="backColorWhite txAnR">
							<span class="disBl boldWt">
								선택금액 합계<span class="titleFontS mainBlue2 ml10">{{
									totalAmt
								}}</span
								>원
							</span>
							<span class="mainBlack3">
								( 미납금
								<span>{{
									formData.nonPaymentAmt
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}}</span
								>원, 할부금
								<span>{{
									formData.instlAmt
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}}</span
								>원, 요금수납
								<span>{{
									formData.recipAmt
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}}</span
								>원 )
							</span>
							<span class="redfont boldWt ml5"
								>*각항목별 개별 결제만 가능합니다.</span
							>
						</td>
					</tr>
					<tr>
						<td rowspan="2">권리실행포인트</td>
						<td>
							<div class="disFx">
								<div class="checkStyleCont4 lh36 floatL w60 ml20">
									<input
										type="checkbox"
										id="pointChk1"
										class="checkStyle"
										name="pointChk1"
										:true-value="'Y'"
										:false-value="'N'"
										v-model.trim="recipPoint"
										@change="formData.recipPoint = 0"
									/>
									<label for="pointChk1">포인트수납</label>
								</div>
								<input
									class="borderRadi3Px borderContColor3 w150 h36 padW10 ml70 disGray txAnR"
									type="text"
									oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
									v-model.trim="formData.recipPoint"
									:disabled="recipPoint === 'N'"
									maxlength="6"
								/>
								<span class="lh36 ml10">원</span>
							</div>
						</td>
					</tr>
					<tr>
						<td class="backColorWhite txAnR boldWt">
							<span>포인트 사용</span>
							<span class="titleFontS mainBlue2 ml10">{{
								String(formData.recipPoint).replace(
									/\B(?=(\d{3})+(?!\d))/g,
									',',
								)
							}}</span
							>원
						</td>
					</tr>
					<tr>
						<template v-if="formData.paymentType !== 'ADJUST_DDCT'">
							<td>결제수단<span class="redfont">*</span></td>
						</template>
						<template v-if="formData.paymentType === 'ADJUST_DDCT'">
							<td rowspan="2">결제수단<span class="redfont">*</span></td>
						</template>
						<td>
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="cardInfo1"
										name="cardInfo1"
										value="CUSTOMER_INDV"
										v-model.trim="formData.paymentType"
										@change="paymentTypeFnc()"
									/><label class="ml-1 cur_p" for="cardInfo1"
										>고객카드(개인)</label
									>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="cardInfo2"
										name="cardInfo1"
										value="CUSTOMER_CORP"
										v-model.trim="formData.paymentType"
										@change="paymentTypeFnc()"
									/><label class="ml-1 cur_p" for="cardInfo2"
										>고객카드(법인)</label
									>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="cardInfo3"
										name="cardInfo1"
										value="STORE_INDV"
										v-model.trim="formData.paymentType"
										@change="paymentTypeFnc()"
									/><label class="ml-1 cur_p" for="cardInfo3"
										>영업점카드(개인)</label
									>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="cardInfo4"
										name="cardInfo1"
										value="STORE_CORP"
										v-model.trim="formData.paymentType"
										@change="paymentTypeFnc()"
									/><label class="ml-1 cur_p" for="cardInfo4"
										>영업점카드(법인)</label
									>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="cardInfo5"
										name="cardInfo1"
										value="ADJUST_DDCT"
										v-model.trim="formData.paymentType"
										@change="paymentTypeFnc()"
									/><label class="ml-1 cur_p" for="cardInfo5">정산차감</label>
								</div>
								<div
									class="radioStyle h36 lh36 ml40"
									style="color: gray !important"
								>
									<input
										type="radio"
										id="cardInfo6"
										name="cardInfo1"
										value="CASH"
										disabled
										v-model.trim="formData.paymentType"
										@change="paymentTypeFnc()"
									/><label class="ml-1 cur_p" for="cardInfo6">현금입금</label>
								</div>
							</div>
						</td>
					</tr>
					<tr v-if="formData.paymentType === 'ADJUST_DDCT'">
						<td class="backColorWhite">
							<textarea
								class="receiveMemo1"
								placeholder="메모를 입력해주세요"
								v-model.trim="formData.adjustDdctMemo"
							></textarea>
						</td>
					</tr>
					<tr>
						<td rowspan="4" v-if="formData.paymentType !== 'ADJUST_DDCT'">
							카드결제<span class="redfont">*</span>
						</td>
						<td v-if="formData.paymentType !== 'ADJUST_DDCT'">
							<template
								v-if="
									formData.paymentType === 'STORE_INDV' ||
									formData.paymentType === 'STORE_CORP'
								"
							>
								<div style="width: 900px">
									<template>
										<v-slide-group
											class="pa-4"
											center-active
											show-arrows
											v-model.trim="formData.recipCardId"
										>
											<v-card
												height="110"
												width="180"
												class="cur_p"
												style="margin: 3px 10px 3px 0; border: 1px dashed #999"
												@click="cardInsertFnc"
											>
												<v-row align="center" justify="center">
													<v-card-title
														style="
															font-size: 13px;
															font-weight: bold;
															margin-top: 20px;
															position: relative;
														"
													>
														<span
															class="plusImg1 disIn none none none none none"
														></span>
														카드등록
													</v-card-title>
													<v-card-text
														style="
															text-align: center !important;
															padding-top: 0 !important;
														"
														><span
															class="disIn"
															style="font-size: 11px; line-height: 14px"
															>빠르고 간편한 결제를 위해<br />카드를
															등록해주세요</span
														></v-card-text
													>
												</v-row>
											</v-card>
											<v-slide-item
												v-for="item in cardSaveData"
												:key="item.index"
												:value="item.recipCardId"
												v-slot="{ active, toggle }"
											>
												<v-card
													height="110"
													width="180"
													@click="toggle"
													style="margin: 3px 10px; color: #e0e0e0"
													class="cardSelectStyle"
													:class="active ? 'borderCardColor1' : 'none'"
												>
													<v-row
														class="fill-height"
														align="center"
														@click="cardChangeFnc(item)"
													>
														<v-card-title
															class="boldWt"
															style="
																margin: 10px 0 0 10px !important;
																font-size: 13px;
															"
															>{{ item.recipCardName }}</v-card-title
														>
														<v-card-text>
															<div class="boldWt" style="font-size: 0.95em">
																{{ item.cardNum1 }} - **** - **** -
																{{ item.cardNum4 }}
															</div>
															<div
																class="disFx justify-lg-space-between"
																style="font-size: 0.8em"
															>
																<div>
																	{{ item.cardHolderName }}
																</div>
																<div style="margin-right: 3px">
																	{{ item.cardValidityMm }}/{{
																		item.cardValidityYy
																	}}
																</div>
															</div>
														</v-card-text>
														<!--													<v-scale-transition>
															<v-icon
																v-if="active"
																color="white"
																size="48"
																v-text="'mdi-close-circle-outline'"
															></v-icon>
														</v-scale-transition>-->
													</v-row>
												</v-card>
											</v-slide-item>
										</v-slide-group>
									</template>
								</div>
							</template>
						</td>
					</tr>
					<tr>
						<td
							class="backColorWhite"
							v-if="formData.paymentType !== 'ADJUST_DDCT'"
						>
							<div>
								<template
									v-if="
										formData.paymentType === 'CUSTOMER_INDV' ||
										formData.paymentType === 'STORE_INDV'
									"
								>
									<span class="disIn w50">별칭</span>
									<input
										class="borderRadi3Px borderContColor3 w220 h36 padW10 ml30"
										type="text"
										v-model.trim="formData.recipCardName"
										maxlength="8"
									/>
									<span class="disIn w100 ml30">이름</span>
									<input
										class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30"
										v-model.trim="formData.cardHolderName"
										type="text"
										maxlength="15"
									/>
									<span class="disIn ml30 w100">생년월일</span>
									<span class="posR datePickerStyle1 w140 font-size-12 ml30 disIn">
										<v-menu
											v-model.trim="menu1"
											:close-on-content-click="false"
											:nudge-right="140"
											:nudge-top="200"
											transition="scale-transition"
											offset-y
											:attach="true"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													class="w140"
													style="font-size: 12px"
													outlined
													readonly
													v-bind="attrs"
													v-on="on"
													append-icon="mdi-calendar"
													v-model.trim="formData.cardHolderDateBirth"
												></v-text-field>
											</template>
											<v-date-picker
												@input="menu1 = false"
												locale="ko-KR"
												v-model.trim="formData.cardHolderDateBirth"
											></v-date-picker>
										</v-menu>
									</span>
								</template>
							</div>
							<template
								v-if="
									formData.paymentType === 'CUSTOMER_CORP' ||
									formData.paymentType === 'STORE_CORP'
								"
							>
								<div>
									<span class="disIn w50">별칭</span>
									<input
										class="borderRadi3Px borderContColor3 w220 h36 padW10 ml30"
										type="text"
										maxlength="8"
										v-model.trim="formData.recipCardName"
									/>
									<span class="disIn w100 ml30">법인명</span>
									<input
										class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30"
										type="text"
										maxlength="15"
										v-model.trim="formData.cardHolderName"
									/>
									<span class="disIn w100 ml30">사업자번호</span>
									<input
										class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30"
										type="text"
										v-model.trim="formData.bizNum"
										maxlength="10"
										@input="
											bizNumSplitFnc($event.target.value, 'bizNum', 'formData')
										"
									/>
								</div>
							</template>
						</td>
					</tr>
					<tr>
						<td
							class="backColorWhite"
							v-if="formData.paymentType !== 'ADJUST_DDCT'"
						>
							<span class="disIn w50">카드번호</span>
							<input
								class="borderRadi3Px borderContColor3 w220 h36 padW10 ml30"
								type="text"
								maxlength="16"
								v-model.trim="formData.cardNum"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								@input="
									cardNumSplitFnc($event.target.value, 'cardNum', 'formData')
								"
							/>
							<span class="disIn ml30 w100">유효기간(MM/YY)</span>
							<input
								class="borderRadi3Px borderContColor3 w70 h36 padW10 ml30"
								type="text"
								maxlength="2"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="formData.cardValidityMm"
								placeholder="MM"
							/>
							<input
								class="borderRadi3Px borderContColor3 w70 h36 padW10"
								type="text"
								maxlength="2"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="formData.cardValidityYy"
								placeholder="YY"
							/>
							<span class="disIn ml30 w100">카드 비밀번호</span>
							<input
								class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30"
								type="text"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								v-model.trim="formData.cardPassword"
								maxlength="2"
							/>
						</td>
					</tr>
					<tr>
						<td
							class="backColorWhite"
							v-if="formData.paymentType !== 'ADJUST_DDCT'"
						>
							<div class="floatR">
								<template v-if="!formData.recipCardId">
									<template
										v-if="
											formData.paymentType === 'STORE_INDV' ||
											formData.paymentType === 'STORE_CORP'
										"
									>
										<button
											class="lh36 backColorBlue3 mainWhite borderRadi3Px w70"
											@click="insertRecipCard"
										>
											카드저장
										</button>
									</template>
								</template>
								<template v-if="formData.recipCardId">
									<button
										class="lh36 backColorBlue3 mainWhite borderRadi3Px w70"
										@click="updateRecipCard"
									>
										카드수정
									</button>
								</template>
								<template v-if="formData.recipCardId">
									<button
										class="lh36 ml5 borderRadi3Px borderContColor3 w70"
										@click="deleteCardFnc"
									>
										삭제
									</button>
								</template>
							</div>
						</td>
					</tr>
					<tr>
						<td>첨부파일</td>
						<td>
							<receive-req-file-upload
								:applId="Number(applId)"
								:modifyState="true"
								@fileValueFnc="fileValueFnc"
							></receive-req-file-upload>
						</td>
					</tr>
					<tr>
						<td>영업점<br />수납요청상태<span class="redfont">*</span></td>
						<td>
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="payRequest1"
										name="payRequest1"
										value="RECIP_REQ"
										v-model.trim="formData.storeRecipReqStatus"
									/>
									<label class="ml-1 cur_p" for="payRequest1"> 수납요청 </label>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="payRequest2"
										name="payRequest1"
										value="RECIP_REQ_CANCEL"
										v-model.trim="formData.storeRecipReqStatus"
									/>
									<label class="ml-1 cur_p" for="payRequest2">
										수납요청취소
									</label>
								</div>
								<div class="radioStyle h36 lh36 ml40">
									<input
										type="radio"
										id="payRequest3"
										name="payRequest1"
										value="SETTLE_CANCEL_REQ"
										v-model.trim="formData.storeRecipReqStatus"
									/>
									<label class="ml-1 cur_p" for="payRequest3">
										결제취소요청
									</label>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>개통점<br />수납요청상태<span class="redfont">*</span></td>
						<td>
							<div class="disFx mb10">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="storeRecipReqStatus1"
										name="storeRecipReqStatus1"
										value="RECIP_COMPL"
										v-model.trim="formData.openingStoreRecipReqStatus"
										@change="openStoreRecReqFnc"
									/>
									<label class="ml-1 cur_p" for="storeRecipReqStatus1">
										수납완료
									</label>
								</div>
								<div class="posR datePickerStyle1 w140 font-size-12  ml20">
									<v-menu
										v-model.trim="menu2"
										:close-on-content-click="false"
										:nudge-right="140"
										:nudge-top="200"
										transition="scale-transition"
										offset-y
										:attach="true"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												class="w140"
												style="font-size: 12px"
												outlined
												readonly
												v-bind="attrs"
												v-on="on"
												append-icon="mdi-calendar"
												v-model.trim="formData.recipComplDate"
												:disabled="
													formData.openingStoreRecipReqStatus !== 'RECIP_COMPL'
												"
											></v-text-field>
										</template>
										<v-date-picker
											@input="menu2 = false"
											locale="ko-KR"
											v-model.trim="formData.recipComplDate"
										></v-date-picker>
									</v-menu>
								</div>
							</div>
							<div class="disFx">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="storeRecipReqStatus2"
										name="storeRecipReqStatus1"
										value="RECIP_PEND"
										v-model.trim="formData.openingStoreRecipReqStatus"
										@change="openStoreRecReqFnc"
									/>
									<label class="ml-1 cur_p" for="storeRecipReqStatus2">
										수납보류
									</label>
								</div>
								<input
									type="text"
									placeholder="사유를 입력해주세요"
									class="borderRadi3Px borderContColor3 lh36 padW10 w70P ml20"
									v-model.trim="formData.recipPendReason"
									:disabled="
										formData.openingStoreRecipReqStatus !== 'RECIP_PEND'
									"
								/>
							</div>
							<div class="radioStyle h36 lh36 ml20">
								<input
									type="radio"
									id="storeRecipReqStatus3"
									name="storeRecipReqStatus1"
									value="RECIP_CANCEL_COMPL"
									v-model.trim="formData.openingStoreRecipReqStatus"
									@change="openStoreRecReqFnc"
								/>
								<label class="ml-1 cur_p" for="storeRecipReqStatus3">
									수납취소완료
								</label>
							</div>
							<div class="radioStyle h36 lh36 ml20">
								<input
									type="radio"
									id="storeRecipReqStatus4"
									name="storeRecipReqStatus1"
									value="SETTLE_CANCEL_COMPL"
									v-model.trim="formData.openingStoreRecipReqStatus"
									@change="openStoreRecReqFnc"
								/>
								<label class="ml-1 cur_p" for="storeRecipReqStatus4">
									결제취소완료
								</label>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="disFx justify-end mt20">
				<button
					@click="insertData"
					class="backColorBlue2 mainWhite borderRadi3Px w120 lh40"
				>
					저장
				</button>
				<button
					class="backColorGray5 mainWhite boldWt borderRadi3Px w120 lh40 ml10"
					@click="cancelFnc"
				>
					취소
				</button>
			</div>
		</template>
		<button
			class="w100P lh40 borderSubColor1 borderRadi3Px backColorBlue1 mainBlue2 boldWt font-size-12 mt30"
			@click="addRequestFnc"
		>
			+ 추가수납
		</button>
	</div>
</template>

<script>
import ReceiveRequestDetail from '../appl-tab-mgmt/ReceiveRequestDetail.vue';
import numberSplit from '../../../common/numberSplit.js';
import ReceiveReqFileUpload from '../../../components/ReceiveReqFileUpload.vue';
import { htmlParse, nullValidation2 } from '@/common/common';
import bus from '@/common/bus';
import { applRecipSub } from '@/common/socketUtil';

export default {
	name: 'ReceiveRequest',
	components: {
		ReceiveRequestDetail,
		ReceiveReqFileUpload,
	},
	mixins: [numberSplit],
	computed: {
		openingStoreRecipReqStatus: {
			get() {
				return this.$store.state.ReceiveRequestModule
					.openingStoreRecipReqStatus;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.openingStoreRecipReqStatus = newValue;
			},
		},
		saleStoreId: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.formData.basic
					.saleStoreId;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.formData.basic.saleStoreId = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ReceiveRequestModule.formData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.formData = newValue;
			},
		},
		applId() {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		recipData: {
			get() {
				return this.$store.state.ReceiveRequestModule.recipData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.recipData = newValue;
			},
		},
		listNum: {
			get() {
				return this.$store.state.ReceiveRequestModule.listNum;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.listNum = newValue;
			},
		},
		firstFlag: {
			get() {
				return this.$store.state.ReceiveRequestModule.firstFlag;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.firstFlag = newValue;
			},
		},
		cardSaveData: {
			get() {
				return this.$store.state.ReceiveRequestModule.cardSaveData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.cardSaveData = newValue;
			},
		},
		propsFileList: {
			get() {
				return this.$store.state.ReceiveRequestModule.propsFileList;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.propsFileList = newValue;
			},
		},
		tabDialog: {
			get() {
				return this.$store.state.ApplicationFormCreationModule.tabDialog;
			},
			set(newValue) {
				this.$store.state.ApplicationFormCreationModule.tabDialog = newValue;
			},
		},
		fileData: {
			get() {
				return this.$store.state.ReceiveRequestModule.fileData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.fileData = newValue;
			},
		},
	},
	data: () => ({
		menu1: false,
		menu2: false,
		addRequest: false,
		nonPaymentAmtDialog: 'N',
		instlAmtDialog: 'N',
		recipAmtDialog: 'N',
		recipPoint: 'N',
		totalAmt: '0',
	}),
	methods: {
		totalAmtFnc() {
			let Amt =
				Number(this.formData.nonPaymentAmt) +
				Number(this.formData.instlAmt) +
				Number(this.formData.recipAmt);
			this.totalAmt = String(Amt).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		validationRuleFnc() {
			if (
				this.formData.paymentType === 'CUSTOMER_INDV' ||
				this.formData.paymentType === 'STORE_INDV'
			) {
				if (
					!nullValidation2(this.formData, [
						'recipCardName',
						'cardHolderName',
						'cardHolderDateBirth',
						'cardNum1',
						'cardNum2',
						'cardNum3',
						'cardNum4',
						'cardValidityMm',
						'cardValidityYy',
						'cardPassword',
					])
				) {
					return true;
				}
			}
			if (
				this.formData.paymentType === 'CUSTOMER_CORP' ||
				this.formData.paymentType === 'STORE_CORP'
			) {
				if (
					!nullValidation2(this.formData, [
						'recipCardName',
						'cardHolderName',
						'bizNum',
						'cardNum1',
						'cardNum2',
						'cardNum3',
						'cardNum4',
						'cardValidityMm',
						'cardValidityYy',
						'cardPassword',
					])
				) {
					return true;
				}
			}
			if (this.formData.openingStoreRecipReqStatus === 'RECIP_COMPL') {
				if (!nullValidation2(this.formData, ['recipComplDate'])) {
					return true;
				}
			}
			if (this.formData.openingStoreRecipReqStatus === 'RECIP_PEND') {
				if (!nullValidation2(this.formData, ['recipPendReason'])) {
					return true;
				}
			}
		},
		openStoreRecReqFnc() {
			this.formData.recipPendReason = null;
			this.formData.recipComplDate = null;
		},
		cardInsertFnc() {
			this.formData.recipCardId = null;
			this.formData.bizNum = null;
			this.formData.cardHolderName = null;
			this.formData.cardHolderDateBirth = null;
			this.formData.cardNum = null;
			this.formData.cardNum1 = null;
			this.formData.cardNum2 = null;
			this.formData.cardNum3 = null;
			this.formData.cardNum4 = null;
			this.formData.cardPassword = null;
			this.formData.recipCardName = null;
			this.formData.cardValidityMm = null;
			this.formData.cardValidityYy = null;
		},
		paymentTypeFnc() {
			this.formData.bizNum = null;
			this.formData.cardHolderName = null;
			this.formData.cardHolderDateBirth = null;
			this.formData.cardNum = null;
			this.formData.cardNum1 = null;
			this.formData.cardNum2 = null;
			this.formData.cardNum3 = null;
			this.formData.cardNum4 = null;
			this.formData.cardPassword = null;
			this.formData.recipCardName = null;
			this.formData.cardValidityMm = null;
			this.formData.cardValidityYy = null;
			this.formData.recipCardId = null;
		},
		cancelFnc() {
			this.formDataInit();
			this.addRequest = false;
		},
		formDataInit() {
			for (let data in this.formData) {
				this.formData[data] = '';
			}
			this.formData.applId = this.applId;
			this.nonPaymentAmtDialog = 'N';
			this.instlAmtDialog = 'N';
			this.recipAmtDialog = 'N';
			this.recipPoint = 'N';
			this.formData.nonPaymentAmt = 0;
			this.formData.instlAmt = 0;
			this.formData.recipAmt = 0;
			this.formData.recipPoint = 0;
			this.formData.paymentType = 'CUSTOMER_INDV';
			this.formData.storeRecipReqStatus = 'RECIP_REQ';
			this.formData.openingStoreRecipReqStatus = 'RECIP_COMPL';
		},
		async insertData() {
			if (this.validationRuleFnc()) return;
			for (let data in this.formData) {
				if (this.formData[data] === '') {
					this.formData[data] = null;
				}
			}
			const result = await this.$store.dispatch(
				'ReceiveRequestModule/insertData',
				this.formData,
			);
			if (result) {
				await this.fileUploadFnc(result);
				this.listNum = 0;
				await this.getApplicationRecipDetail();
				await this.formDataInit();
				this.firstFlag = false;
				this.fileData = [];
			}
		},
		async reloadReceive() {
			// 소켓 페이지 재조회
			this.listNum = 0;
			await this.getApplicationRecipDetail();
			await this.formDataInit();
			this.firstFlag = false;
			this.fileData = [];
		},
		async getApplicationRecipDetail() {
			// 디테일콜
			let data = {
				applId: this.applId,
			};
			const result = await this.$store.dispatch(
				'ReceiveRequestModule/getApplicationRecipDetail',
				data,
			);
			if (result) {
				await this.getApplicationAttachList(result.recip.applRecipId);
			}
		},
		async getRecipCardList() {
			let data = {
				applId: this.applId,
			};
			await this.$store.dispatch('ReceiveRequestModule/getRecipCardList', data);
		},
		async getHistoryList() {
			// 업무/수정 히스토리 콜
			let data = {
				applId: this.formData.applId,
			};
			await this.$store.dispatch('TaskHistoryModule/getHistoryList', data);
		},
		renderingFnc() {
			this.fileData = [];
			this.propsFileList = [];
			this.recipData = {};
			this.listNum = 0;
			this.firstFlag = false;
			this.formDataInit();
			this.totalAmtFnc();
		},
		addRequestFnc() {
			if (this.openingStoreRecipReqStatus !== 'RECIP_COMPL') {
				return alert(
					'이전 차수 개통점 수납 요청 상태가 수납 완료 상태 일 때 추가 수납 요청이 가능합니다.',
				);
			}
			this.recipData = null;
			this.addRequest = true;
			this.fileData = [];
			this.propsFileList = [];
		},
		async insertRecipCard() {
			let data = {
				applId: this.formData.applId,
				bizNum: this.formData.bizNum,
				cardHolderName: this.formData.cardHolderName,
				cardHolderDateBirth: this.formData.cardHolderDateBirth,
				cardNum: this.formData.cardNum,
				cardNum1: this.formData.cardNum1,
				cardNum2: this.formData.cardNum2,
				cardNum3: this.formData.cardNum3,
				cardNum4: this.formData.cardNum4,
				cardPassword: this.formData.cardPassword,
				cardValidityMm: this.formData.cardValidityMm,
				cardValidityYy: this.formData.cardValidityYy,
				paymentType: this.formData.paymentType,
				recipCardName: this.formData.recipCardName,
				saleStoreId: this.saleStoreId,
			};
			for (let del in data) {
				if (data[del] === '') {
					data[del] = null;
				}
			}
			const result = await this.$store.dispatch(
				'ReceiveRequestModule/insertRecipCard',
				data,
			);
			await this.getRecipCardList();
		},
		cardChangeFnc(item) {
			this.formData.cardHolderName = item.cardHolderName;
			this.formData.cardHolderDateBirth = item.cardHolderDateBirth;
			this.formData.cardNum = item.cardNum;
			this.formData.cardNum1 = item.cardNum1;
			this.formData.cardNum2 = item.cardNum2;
			this.formData.cardNum3 = item.cardNum3;
			this.formData.cardNum4 = item.cardNum4;
			this.formData.cardPassword = item.cardPassword;
			this.formData.cardValidityMm = item.cardValidityMm;
			this.formData.cardValidityYy = item.cardValidityYy;
			this.formData.recipCardName = item.recipCardName;
		},
		async updateRecipCard() {
			let data = {
				applId: this.formData.applId,
				bizNum: this.formData.bizNum,
				cardHolderName: this.formData.cardHolderName,
				cardHolderDateBirth: this.formData.cardHolderDateBirth,
				cardNum: this.formData.cardNum,
				cardNum1: this.formData.cardNum1,
				cardNum2: this.formData.cardNum2,
				cardNum3: this.formData.cardNum3,
				cardNum4: this.formData.cardNum4,
				cardPassword: this.formData.cardPassword,
				cardValidityMm: this.formData.cardValidityMm,
				cardValidityYy: this.formData.cardValidityYy,
				paymentType: this.formData.paymentType,
				recipCardName: this.formData.recipCardName,
				recipCardId: this.formData.recipCardId,
				saleStoreId: this.saleStoreId,
			};
			for (let del in data) {
				if (data[del] === '') {
					data[del] = null;
				}
			}
			await this.$store.dispatch('ReceiveRequestModule/updateRecipCard', data);
			await this.getRecipCardList();
		},
		fileValueFnc(value) {
			this.formData.attachList = value;
		},
		async fileUploadFnc(data) {
			if (this.formData.attachList) {
				if (this.formData.attachList.length !== 0) {
					let formData = new FormData();
					formData.append('applId', this.applId);
					for (let i = 0; i < this.formData.attachList.length; i++) {
						if (this.formData.attachList[i].applAttachId) {
							return;
						}
						formData.append('attachFile', this.formData.attachList[i]);
						let fileHtml = []; // 파일 이력
						let fileObj = {
							title: '첨부파일',
							// @ts-ignore
							data: this.formData.attachList[i].name,
							changeData: '추가',
						};
						fileHtml.push(fileObj);
						let fileHtmlString = htmlParse(fileHtml);
						formData.append('memoContents', fileHtmlString);
						formData.append('attachType', 'RECIP');
						formData.append('subId', data);
					}
					// 파일 이력
					await this.$store.dispatch(
						'RightExecutionModule/fileUpload',
						formData,
					);
				}
			}
		},
		async getApplicationAttachList(subId) {
			// 파일리스트 콜
			let data = {
				applId: this.applId,
				attachType: 'RECIP',
				subId: subId,
			};
			if (this.applId) {
				const result = await this.$store.dispatch(
					'RightExecutionModule/getApplicationAttachList',
					data,
				);
				if (result) {
					this.propsFileList = result;
				}
			}
		},
		async deleteCardFnc() {
			if (!confirm('삭제하시겠습니까')) {
				return;
			}
			await this.deleteRecipCard();
			await this.getRecipCardList();
		},
		async deleteRecipCard() {
			let data = {
				recipCardId: this.formData.recipCardId,
			};
			await this.$store.dispatch('ReceiveRequestModule/deleteRecipCard', data);
		},
	},
	async created() {
		await this.renderingFnc();
		await this.getRecipCardList();

		bus.$on('reloadReceive:appl', this.reloadReceive);
	},
	beforeDestroy() {
		bus.$off('reloadReceive:appl', this.reloadReceive);
	},
	async mounted() {
		await this.getApplicationRecipDetail();
		// 수납요청을 통해서 들어온 신청서 번호
		if (this.$route.query.applId !== undefined) {
			applRecipSub(this.$route.query.applId);
		}
	},
};
</script>

<style scoped>
.receiveTableCont1 {
	background-color: #fff;
	width: 1200px;
	border-top: 2px solid #007ef2;
	border-bottom: 1px solid #eee;
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
	padding: 20px 30px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.receiveMemo1 {
	border: 1px solid #999;
	border-radius: 3px;
	resize: none;
	width: 100%;
	height: 94px;
	padding: 10px;
}
.plusImg1 {
	background-image: url('../../../assets/img/cardAddBtn1.png');
	width: 30px;
	height: 30px;
	display: inline-block;
}
</style>
