<template>
	<div class="popUpCustom">
		<div class="text-center">
			<v-dialog v-model.trim="dialog" :width="popWidth">
				<v-card class="borderRadiReset">
					<v-card-title
						class="headline backColorBlue3 disFx justify-lg-space-between"
						style="padding: 15px 20px !important"
					>
						<h2 class="mainWhite mainFontS boldWt">{{ title }}</h2>
						<span class="closeBtn1 cur_p" @click="dialog = false"></span>
					</v-card-title>
					<v-card-text class="pad10">
						<template v-if="title === '부가서비스 선택'">
							<add-svc-select></add-svc-select>
						</template>
						<template v-if="title === '보험 선택'">
							<insurance-select></insurance-select>
						</template>
						<template v-if="title === '유심 선택'">
							<usim-select></usim-select>
						</template>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import AddSvcSelect from './pop-template/AddSvcSelect.vue';
import InsuranceSelect from './pop-template/InsuranceSelect.vue';
import UsimSelect from './pop-template/UsimSelect.vue';

export default {
	name: 'AddServicePop',
	props: ['title'],
	components: {
		AddSvcSelect,
		InsuranceSelect,
		UsimSelect,
	},
	data: () => ({
		dataWidth: 500,
	}),
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.addSvcDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.addSvcDialog = newValue;
			},
		},
		popWidth() {
			if (this.title === '유심 선택') {
				return 300;
			} else {
				return 700;
			}
		},
	},
	methods: {},
};
</script>

<style scoped></style>
