import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {
	getConsultNoticeList,
	getConsultNoticeStatusCount,
	getConsultNoticeRegiMemberInfo,
	consultPolicyList,
	getConsultNoticeAttachList,
	insertConsultNotice,
	insertConsultPolicy,
	updateConsultNotice,
	uploadConsultNoticeAttach,
	deleteConsultNotice,
	deleteConsultNoticeAttach,
} from '@/api/sales-mgmt/ConsultNoticeApi';
import { findAExtractBfromC } from '@/common/common';
import { getOpeningStoreList } from '@/api/supply-chain-mgmt/application-form-creation/ApplicationFromApi';
import { getGoodsSelectList, getTelecomChargeList } from '@/api/common/common';

Vue.use(Vuex);

const ConsultNoticeModule = {
	namespaced: true,
	state: {
		dateResetData: false,
		noticeDialog: false,
		policyDialog: false,
		totalCnt: 0,
		noticeList: [],
		policyNoticeList: [],
		openingStoreList: [],
		NOTICE_IMPORTANT: 0,
		NOTICE_DAILY: 0,
		NOTICE_POLICY: 0,
		filterData: {
			consultNoticeSearchType: 'ALL',
			consultNoticeType: '',
			searchContents: '',
			dateTimeOrder: '',
			srhStartDate: '',
			srhEndDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
	},
	mutations: {
		orderInit(state) {
			state.filterData.dateTimeOrder = '';
		},
		initNoticeList(state) {
			state.noticeList = [];
			state.totalCnt = 0;
		},
		initNoticeCnt(state) {
			state.NOTICE_IMPORTANT = 0;
			state.NOTICE_DAILY = 0;
			state.NOTICE_POLICY = 0;
		},
		topFilterInit(state) {
			state.dateResetData = true;
			state.filterData.consultNoticeSearchType = 'ALL';
			state.filterData.searchContents = '';
			state.filterData.srhStartDate = '';
			state.filterData.srhEndDate = '';
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
		setNoticeList(state, result) {
			state.noticeList = result.data.data.resultList || [];
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setNoticeCnt(state, result) {
			state.NOTICE_IMPORTANT = findAExtractBfromC(
				'consultNoticeType',
				'NOTICE_IMPORTANT',
				'consultNoticeTypeCnt',
				result.data.data.consultNoticeTypeGroupDtoList,
			);
			state.NOTICE_DAILY = findAExtractBfromC(
				'consultNoticeType',
				'NOTICE_DAILY',
				'consultNoticeTypeCnt',
				result.data.data.consultNoticeTypeGroupDtoList,
			);
			state.NOTICE_POLICY = findAExtractBfromC(
				'consultNoticeType',
				'NOTICE_POLICY',
				'consultNoticeTypeCnt',
				result.data.data.consultNoticeTypeGroupDtoList,
			);
		},
		initPolicyNotice(state) {
			state.policyNoticeList = [];
		},
		setPolicyNotice(state, result) {
			state.policyNoticeList = result.data.data || [];
			for (let i in state.policyNoticeList) {
				if (state.policyNoticeList[i].policyDetailList.length === 0) {
					state.policyNoticeList[i].policyDetailList.push({
						goodsInfoDtoList: [],
						agreementType: '',
						consultPolicyChargeDtoList: [{ chargeId: 0 }],
					});
				}
			}
			function lastIdx(arr) {
				if (arr.length > 0) {
					return arr.length - 1;
				}
			}
			for (let i in state.policyNoticeList) {
				let lastPolicy =
					state.policyNoticeList[i].policyDetailList[
						lastIdx(state.policyNoticeList[i].policyDetailList)
					];
				let lastPolicyLastCharge =
					lastPolicy.consultPolicyChargeDtoList[
						lastIdx(lastPolicy.consultPolicyChargeDtoList)
					];
				if (lastPolicyLastCharge.chargeId === null) {
					lastPolicy.consultPolicyChargeDtoList.pop();
				}
			}
		},
	},
	actions: {
		async getConsultNoticeList({ commit }, data) {
			try {
				commit('initNoticeList');
				const result = await getConsultNoticeList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNoticeList', result);
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultNoticeStatusCount({ commit }, data) {
			try {
				commit('initNoticeCnt');
				const result = await getConsultNoticeStatusCount(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setNoticeCnt', result);
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultNoticeRegiMemberInfo({ commit }, data) {
			try {
				const result = await getConsultNoticeRegiMemberInfo(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultPolicyListAndSetNotice({ commit }, data) {
			try {
				commit('initPolicyNotice');
				const result = await consultPolicyList(data);
				if (result && '0000' === result.data.resultCode) {
					commit('setPolicyNotice', result);
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultPolicyList({ commit }, data) {
			try {
				const result = await consultPolicyList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getConsultNoticeAttachList({ commit }, data) {
			try {
				const result = await getConsultNoticeAttachList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultNotice({ commit }, data) {
			try {
				const result = await insertConsultNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultPolicy({ commit }, data) {
			try {
				const result = await insertConsultPolicy(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateConsultNotice({ commit }, data) {
			try {
				const result = await updateConsultNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async uploadConsultNoticeAttach({ commit }, data) {
			try {
				const result = await uploadConsultNoticeAttach(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteConsultNotice({ commit }, data) {
			try {
				const result = await deleteConsultNotice(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteConsultNoticeAttach({ commit }, data) {
			try {
				const result = await deleteConsultNoticeAttach(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getOpeningStoreList({ commit }, data) {
			try {
				const result = await getOpeningStoreList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 기기명
		async getGoodsSelectList({ commit }, data) {
			try {
				const result = await getGoodsSelectList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 요금제조회
		async getTelecomChargeList({ commit }, data) {
			try {
				const result = await getTelecomChargeList(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default ConsultNoticeModule;
