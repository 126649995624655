<template>
	<div>
		<div
			class="device_table_style1 mt15 narrowScroll posR new-mainCont"
			style="
				height: auto !important;
				border: 0 !important;
				padding: 0 !important;
			"
		>
			<table class="w100P mainBlack">
				<tr :class="{ backColorBlue6: selectValue === 1 }" @click="dateFnc(1)">
					<td>해당없음</td>
				</tr>
				<tr :class="{ backColorBlue6: selectValue === 2 }" @click="dateFnc(2)">
					<td class="disFx justify-center overflow-hidden">
						<v-menu
							v-model.trim="menu1"
							:close-on-content-click="false"
							:nudge-left="0"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									append-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
									class="w200"
									style="border: 0 !important"
									v-model.trim="datePicker"
								></v-text-field>
							</template>
							<v-date-picker
								@input="menu1 = false"
								v-model.trim="datePicker"
								locale="ko-KR"
								@change="dateFnc(2)"
							></v-date-picker>
						</v-menu>
					</td>
				</tr>
				<tr
					:class="{ backColorBlue6: selectValue === item.value }"
					@click="dateFnc(item.value)"
					v-for="(item, index) in dateItemList"
					:key="index"
				>
					<td>{{ item.name }}</td>
				</tr>
			</table>
		</div>
		<div class="disFx justify-end mt10">
		</div>
	</div>
</template>

<script>
import { getFormatDate } from '@/common/common';

export default {
	name: 'ChangeDateSelect',
	computed: {
		formData: {
			get() {
				return this.$store.state.ChargeCalculatorModule.formData;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.formData = newValue;
			},
		},
		changeDate: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDate;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDate = newValue;
			},
		},
		changeDateName: {
			get() {
				return this.$store.state.ChargeCalculatorModule.changeDateName;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.changeDateName = newValue;
			},
		},
		dialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.chargeBoardPopDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.chargeBoardPopDialog = newValue;
			},
		},
	},
	data: () => ({
		menu1: false,
		datePicker: '',
		selectValue: null,
		selectName: null,
		dateItemList: [
			{ name: '서비스 유지', value: 3 },
			{ name: '31일', value: 31 },
			{ name: '91일', value: 91 },
			{ name: '93일', value: 93 },
			{ name: '95일(당일포함)', value: 95 },
			{ name: '125일', value: 125 },
			{ name: '181일', value: 181 },
		],
	}),
	methods: {
		dateFnc(day) {
			if (day === 1) {
				this.selectName = '해당없음';
				this.selectValue = day;
			} else if (day === 2) {
				this.selectName = this.datePicker;
				this.selectValue = day;
			} else if (day === 3) {
				this.selectName = '서비스 유지';
				this.selectValue = day;
			} else {
				let selectDay = new Date(
					new Date().setDate(new Date().getDate() + day),
				);
				this.selectName = getFormatDate(selectDay);
				this.selectValue = day;
			}
			this.insertFnc();
		},
		insertFnc() {
			this.changeDateName = this.selectName;
			this.changeDate = this.selectValue;
			this.dialog = false;
		},
	},
	created() {
		this.datePicker = getFormatDate(new Date());
		if (this.changeDate) {
			this.selectValue = this.changeDate;
			this.selectName = this.changeDateName;
		}
		if (this.changeDate === 2) {
			this.datePicker = this.changeDateName;
		}
	},
};
</script>

<style scoped>
table tr {
	cursor: pointer;
}
.input_custom1 .v-input__slot {
	min-height: 40px !important;
}
</style>
