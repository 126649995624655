import Vue from 'vue';
import Vuex from 'vuex';
import {
	getMoveStatusList,
	getMoveMgmtGroupList,
	getMoveHistoryList,
} from '../../../../api/warehouse-mgmt/device-mgmt/MoveStatusApi.js';
import {
	getDeviceList,
	getRetrieveDeviceHistory,
} from '../../../../api/common/common';
import { excelDownLoad } from '../../../../api/common/common.js';
import { commonCodeEnumList } from '@/api/warehouse-mgmt/device-mgmt/NormalInStockApi';

Vue.use(Vuex);

const MoveStatusModule = {
	namespaced: true,
	state: {
		repealDialog: false,
		copyData: {},
		count: {
			allCnt: 0,
			sellMoveCnt: 0,
			stockMoveCnt: 0,
			stockTrnsCnt: 0,
			faultyTrnsCnt: 0,
			sellTrnsCnt: 0,
			returnTrnsCnt: 0,
		},
		infoDialog: false,
		totalCnt: 0,
		// allCheckedBox: '',
		// checkListIdArray: [],
		moveStatusList: [],
		moveStatusGroupList: [],
		listGroupDataView: [],
		listGroupDataViewCnt: 1,
		deviceHistory: [],
		deviceOpenHistory: [],
		resetData: false,
		filterData: {
			orderOrderDate: '',
			orderInStockRegiDate: '',
			orderMoveDate: '',
			perPageCnt: 20,
			orderDate: '',
			barcode: '',
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		enumCodeList: {
			telecom: [],
		},
	},
	getters: {},
	mutations: {
		filterInit(state) {
			for (let data in state.filterData) {
				if (
					!(
						data === 'orderOrderDate' ||
						data === 'orderInStockRegiDate' ||
						data === 'orderMoveDate'
					)
				) {
					state.filterData[data] = '';
				}
			}
			state.filterData.perPageCnt = 20;
			state.filterData.pageNo = 1;
		},
		setList(state, result) {
			state.moveStatusList = Object.freeze(result.data.data.resultList);
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setMoveMgmtGroupList(state, result) {
			state.moveStatusGroupList = [];
			state.moveStatusGroupList = Object.freeze(
				result.data.data.moveOutStockGroupDtos,
			);
			// 유형별 테이블 스크롤 페이징
			document.getElementById(`listGroupBox`).scroll(0, 0);
			state.listGroupDataViewCnt = 1;
			state.listGroupDataView = state.moveStatusGroupList.slice(
				0,
				state.listGroupDataViewCnt * 100,
			);

			for (let item in state.count) {
				state.count[item] = 0;
			}
			const data = Object.freeze(result.data.data.moveOutStockGroupDtos);
			for (let item in data) {
				// 전체
				state.count.allCnt = state.count.allCnt + data[item].goodsCount;
				switch (data[item].stockType) {
					case 'SELL_MOVE': // 판매이동
						state.count.sellMoveCnt =
							state.count.sellMoveCnt + data[item].goodsCount;
						break;
					case 'STOCK_MOVE': // 이동재고
						state.count.stockMoveCnt =
							state.count.stockMoveCnt + data[item].goodsCount;
						break;
					case 'STOCK_TRNS': // 재고이관
						state.count.stockTrnsCnt =
							state.count.stockTrnsCnt + data[item].goodsCount;
						break;
					case 'FAULTY_TRNS': // 착하
						state.count.faultyTrnsCnt =
							state.count.faultyTrnsCnt + data[item].goodsCount;
						break;
					case 'SELL_TRNS': // 판매이관
						state.count.sellTrnsCnt =
							state.count.sellTrnsCnt + data[item].goodsCount;
						break;
					case 'RETURN_TRNS': // 반품이관
						state.count.returnTrnsCnt =
							state.count.returnTrnsCnt + data[item].goodsCount;
						break;
				}
			}
		},
		setDeviceHistory(state, data) {
			state.deviceHistory = data.data.data;
		},
		setUsimTypeItems(state, data) {
			state.enumCodeList.deliveryTypeItems = [];
			state.enumCodeList.goodsTypeItems = [];
			state.enumCodeList.inStockTypeItems = [];
			state.enumCodeList.goodsTypeItems = data.data.data.GoodsType;
			state.enumCodeList.inStockTypeItems = data.data.data.InStockType;
			state.enumCodeList.deliveryTypeItems = data.data.data.DeliveryType;
			state.enumCodeList.goodsTypeItems.unshift({ name: '전체', value: '' });
		},
	},
	actions: {
		async getList({ commit }, data) {
			try {
				const result = await getMoveStatusList(data);
				if (result) {
					commit('setList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getHistoryList({ commit }, data) {
			try {
				const result = await getMoveHistoryList(data);
				if (result) {
					commit('setList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMoveMgmtGroupList({ commit }, data) {
			try {
				let result = await getMoveMgmtGroupList(data);
				if (result) {
					commit('setMoveMgmtGroupList', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 이력 리스트 조회
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceHistory(data);
				if (result) {
					commit('setDeviceHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async excelDownLoad({ commit }, data) {
			await excelDownLoad(data);
		},
		async commonCodeEnumList({ commit }) {
			let data = { initEnumData: ['GoodsType', 'InStockType', 'DeliveryType'] };
			const result = await commonCodeEnumList(data);
			if (result) {
				commit('setUsimTypeItems', result);
			}
		},
		async getDeviceList({ commit }, data) {},
	},
};
export default MoveStatusModule;
