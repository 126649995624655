import Vue from 'vue';
import Vuex from 'vuex';
import {
	consultTodoGroupCnt,
	consultTodoList,
	consultTodoMemberList,
} from '@/api/sales-mgmt/ConsultDbApi';
import {
	customerList,
	customerMemberGroupList,
	customerStatusGroupList,
	customerOpeningGroupList,
	customerOrganizationGroupList,
	customerChargeGroupList,
	customerStatusGroupCountList,
	consultTodoChargeGroup,
	insertConsultAssign,
} from '@/api/sales-mgmt/CustomerDbApi';
import {
	consultTodoStatusGroup,
	deleteConsultTodo,
} from '@/api/sales-mgmt/CustomerConsultApi';
import { commonCodeEnumList, getChargeList } from '@/api/common/common';
Vue.use(Vuex);

const CustomerDbModule = {
	namespaced: true,
	state: {
		allCheckedBox: false,
		allTodoCheckedBox: false,
		assignDialog: false,
		resetData: false,
		todoResetData: false,
		consultDialog: false,
		memoDialog: false,
		applDialog: false,
		dateResetData: false,
		totalCnt: 0,
		filterData: {
			srhStartDate: '',
			srhEndDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		todoList: [],
		mainList: [],
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		memoTabValue: 'ALL',
		historyList: [],
		checkListTodoIdArray: [],
		todoFilterData: {
			dateTimeOrder: '',
			srhStartDate: '',
			srhEndDate: '',
		},
		codeList: {
			filteredExistTelList: [],
			customerTypeItems: [],
			joinTypeItems: [],
			procStatusItems: [],
			searchTypeItems: [],
			nationalItems: [],
			stayCodeItems: [],
			existTelecomList: [],
			openStoreList: [],
			chargeList: [],
			todoChargeList: [],
			deviceList: [],
			hopeDeviceList: [],
			capacityList: [],
			colorList: [],
			openingTaskStatusList: [],
			consultTaskStatusList: [],
			logisticsTaskStatusList: [],
			mainApplStatusList: [],
			todoApplStatusList: [],
			mainMemberList: [],
			todoMemberList: [],
		},
		todoTitleCntDto: [],
		mainTitleCntDto: [],
		checkListArray: [],
		checkListArray1: [],
		consultTodoDto: {
			toDoContents: null,
			reservationDateTime: null,
			notiYn: 'N',
			time: null,
		},
		consultMemoDto: {
			memoContents: null,
		},
	},
	mutations: {
		setFilterDataInit(state) {
			state.filterData = {
				srhStartDate: '',
				srhEndDate: '',
				perPageCnt: 20,
				pageNo: 1,
			};
		},
		setTodoList(state, result) {
			state.todoList = result || [];
		},
		setTodoStatusCntList(state, result) {
			state.todoTitleCntDto = result || [];
		},
		setCommonCodeEnumList(state, result) {
			state.codeList.openingTaskStatusList = result.OpeningTaskStatus || [];
			state.codeList.consultTaskStatusList = result.ConsultTaskStatus || [];
			state.codeList.logisticsTaskStatusList = result.LogisticsTaskStatus || [];
		},
		setChargeList(state, result) {
			state.codeList.chargeList = result || [];
		},
		setCustomerList(state, result) {
			/*const newArr = result.resultList;

			newArr.forEach((i, idx) => {
				// dup - 구분자 cnsltId 중복값 (교품건 - 중복신청서)
				i.dup = 'N';
				if (idx !== 0) {
					if (i.cnsltId === newArr[idx - 1].cnsltId) {
						newArr[idx - 1].dup = 'Y_Parent';
						i.dup = 'Y';
					}
				}
			});*/
			/*result.resultList.splice(1, 0, result.resultList[1]);*/

			state.mainList = result.resultList || [];
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
		},
		setCustomerMemberGroupList(state, result) {
			result.secondConsultMemberList = result.secondConsultMemberList.filter(
				i => i.memberId,
			);
			state.codeList.mainMemberList = result || [];
		},
		setCustomerStatusGroupList(state, result) {
			let resultCustomArr = {};
			for (const [key, value] of Object.entries(result)) {
				let resultSubCustomArr = [];
				value.forEach(subItem => {
					if (subItem.groupCnt !== null && subItem.groupCnt !== undefined && subItem.groupCnt !== 0) {
						if (key === 'customerStatusList') {
							subItem.consultTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.consultTaskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + subItem.groupCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
						} else if (key === 'openingStatusList') {
							subItem.openingTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.openingTaskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + subItem.groupCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
						} else if(key === 'logisticsStatusList') {
							subItem.logisticsTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.logisticsTaskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + subItem.groupCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
						}
					} else {
						if (key === 'customerStatusList') {
							subItem.consultTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.consultTaskStatusMsg + '</span>]</span>';
						} else if (key === 'openingStatusList') {
							subItem.openingTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.openingTaskStatusMsg + '</span>' + ']</span>';
						} else if(key === 'logisticsStatusList') {
							subItem.logisticsTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.logisticsTaskStatusMsg + '</span>';
						}
					}
					resultSubCustomArr.push(subItem);
				})
				resultCustomArr[key] = resultSubCustomArr;
			}
			result.logisticsStatusList = result.logisticsStatusList.filter(i => i);
			state.codeList.mainApplStatusList = resultCustomArr || [];
		},
		setCustomerOpeningGroupList(state, result) {
			state.codeList.openingStoreDtoList = result.openingStoreDtoList || [];
		},
		setCustomerOrganizationGroupList(state, result) {
			state.codeList.organizationList = result.organizationList || [];
		},
		setCustomerChargeGroupList(state, result) {
			state.codeList.chargeDtoList = result.chargeDtoList || [];
		},
		setStatusList(state, result) {
			state.codeList.joinTypeItems = result.JoinType || [];
		},
		setConsultTodoMemberList(state, result) {
			result.secondConsultMemberList = result.secondConsultMemberList.filter(
				i => i.memberId,
			);
			state.codeList.todoMemberList = result;
		},
		setConsultTodoStatusGroup(state, result) {
			let resultCustomArr = {};
			for (const [key, value] of Object.entries(result)) {
				let resultSubCustomArr = [];
				value.forEach(subItem => {
					if (subItem.groupCnt !== null && subItem.groupCnt !== undefined && subItem.groupCnt !== 0) {
						if (key === 'customerStatusList') {
							subItem.consultTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.consultTaskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + subItem.groupCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
						} else if (key === 'openingStatusList') {
							subItem.openingTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.openingTaskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + subItem.groupCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
						} else if(key === 'logisticsStatusList') {
							subItem.logisticsTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.logisticsTaskStatusMsg + '</span>' + ' <span style="font-weight: 500; color:#007ef2">[' + subItem.groupCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ']</span>';
						}
					} else {
						if (key === 'customerStatusList') {
							subItem.consultTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.consultTaskStatusMsg + '</span>]</span>';
						} else if (key === 'openingStatusList') {
							subItem.openingTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.openingTaskStatusMsg + '</span>' + ']</span>';
						} else if(key === 'logisticsStatusList') {
							subItem.logisticsTaskStatusMsg = '<span style="font-weight: 500;">' + subItem.logisticsTaskStatusMsg + '</span>';
						}
					}
					resultSubCustomArr.push(subItem);
				})
				resultCustomArr[key] = resultSubCustomArr;
			}
			state.codeList.todoApplStatusList = resultCustomArr || [];
		},
		setCustomerStatusGroupCountList(state, result) {
			state.mainTitleCntDto = result || [];
		},
		setConsultTodoChargeGroup(state, result) {
			state.codeList.todoChargeList = result.chargeDtoList || [];
		},
	},
	actions: {
		async consultTodoList({ commit }, data) {
			try {
				let param = {
					consultType: 'CUSTOMER',
					param: data,
				};
				const result = await consultTodoList(param);
				if (result) {
					commit('setTodoList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoGroupCnt({ commit }, data) {
			try {
				let param = {
					consultType: 'CUSTOMER',
					param: data,
				};
				const result = await consultTodoGroupCnt(param);
				if (result) {
					commit('setTodoStatusCntList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async deleteConsultTodo({ commit }, data) {
			try {
				const result = await deleteConsultTodo(data);
				if (result) {
					return result;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async commonCodeEnumList({ commit }) {
			try {
				let data = {
					initEnumData: [
						'OpeningTaskStatus',
						'ConsultTaskStatus',
						'LogisticsTaskStatus',
					],
				};
				const result = await commonCodeEnumList(data);
				if (result) {
					commit('setCommonCodeEnumList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getChargeList({ commit }, data) {
			try {
				const result = await getChargeList(data);
				if (result) {
					commit('setChargeList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerList({ commit }, data) {
			try {
				const result = await customerList(data);
				if (result) {
					commit('setCustomerList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerMemberGroupList({ commit }, data) {
			try {
				const result = await customerMemberGroupList(data);
				if (result) {
					commit('setCustomerMemberGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerStatusGroupList({ commit }, data) {
			try {
				const result = await customerStatusGroupList(data);
				if (result) {
					commit('setCustomerStatusGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerOpeningGroupList({ commit }, data) {
			try {
				const result = await customerOpeningGroupList(data);
				if (result) {
					commit('setCustomerOpeningGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerOrganizationGroupList({ commit }, data) {
			try {
				const result = await customerOrganizationGroupList(data);
				if (result) {
					commit('setCustomerOrganizationGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerChargeGroupList({ commit }, data) {
			try {
				const result = await customerChargeGroupList(data);
				if (result) {
					commit('setCustomerChargeGroupList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStatusList({ commit }, data) {
			try {
				const result = await commonCodeEnumList(data);
				if (result) {
					commit('setStatusList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoMemberList({ commit }) {
			try {
				let param = {
					consultType: 'CUSTOMER',
				};
				const result = await consultTodoMemberList(param);
				if (result) {
					commit('setConsultTodoMemberList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoStatusGroup({ commit }, param) {
			try {
				let data = {
					consultType: 'CUSTOMER',
					param: param,
				};
				const result = await consultTodoStatusGroup(data);
				if (result) {
					commit('setConsultTodoStatusGroup', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async consultTodoChargeGroup({ commit }) {
			try {
				let data = {
					consultType: 'CUSTOMER',
				};
				const result = await consultTodoChargeGroup(data);
				if (result) {
					commit('setConsultTodoChargeGroup', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async customerStatusGroupCountList({ commit }, data) {
			try {
				const result = await customerStatusGroupCountList(data);
				if (result) {
					commit('setCustomerStatusGroupCountList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertConsultAssign({ commit }, data) {
			try {
				const result = await insertConsultAssign(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default CustomerDbModule;
