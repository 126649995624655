<template>
	<div>
		<template v-if="!dashboardAuth">
			<div class="commingSoonStyle1 h730">업데이트 예정</div>
		</template>
		<template v-else>
			<details class="details-custom-1" :open="true">
				<summary
					class="posR font-size-16 mainWhite backColorBlue2 fw700"
					style="z-index: 2; border: 1px solid #0071da"
					@click="toggleAccordion($event.target, 1)"
				>
					TO-DO 예약
					<!--									<div
						class="disIn posR hoverIconNoti1 cur_p"
						style="transform: translateY(-1px)"
					>
						<v-icon
							class="posR mainWhite"
							style="
								font-size: 14px;
								margin-bottom: 1px;
								margin-left: 2px;
							"
							>fas fa-exclamation-circle
						</v-icon>
						<div class="hoverNoti1" style="width: 330px">
							<span class="mainGray2"
								>당일 처리 예정인 To-Do 업무만 표시됩니다.<br />알림을
								삭제 하시려면 상세 팝업에서 체크박스를
								해제해주세요.</span
							>
						</div>
					</div>-->
					<span class="ml-1">{{ todoRnbList.length > 0 ? todoRnbList.length : 0 }} 건</span>
					<i class="accordionIcon3"></i>
				</summary>
				<div
					class="disFx flex-column narrowScroll todoListCont"
					style="
						height: 140px;
						overflow-y: auto;
						overflow-x: hidden;
						border-right: 1px solid #dadada;
						border-left: 1px solid #dadada;
					"
				>
					<template v-if="todoRnbList.length > 0">
						<div class="padH6W16 cur_p pl-5"
								 v-for="(item, index) in todoRnbList"
								 @click="todoClickFnc(item)"
						>
							<div class="disFx">
								<h5 class="mr10 mainRed fw500">[Todo]</h5>
								<span class="mr-2 fw500 textEllipsis" style="max-width: 70px"
								>{{ item.userName }}</span
								>
								<span class="mr-2 fw500">{{ item.userRegiNum1 !== "null" ? item.userRegiNum1 : '-' }} </span>
								<span class="fw500">{{ item.userPhone.replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ) }}</span>
							</div>
							<span class="font-size-13 mainOrange fw500 ml-14">
								<template v-if="item.diffRegiDays === 0">
									금일작성
								</template>
								<template v-else>
									{{ item.diffRegiDays > 0 ? item.diffRegiDays + '일후 작성' : Math.abs(item.diffRegiDays) + '일전 작성'}}
								</template>
								{{item.regiDateTime | moment('YYYY-MM-DD HH:mm:ss')}}
							</span>
						</div>
					</template>
					<template v-else>
						<span class="pl-5 boldWt font-size-18"
						style="text-align: center; line-height: 110px; padding-left: 0px !important;">
							등록된 TO-DO 예약이 없습니다.
						</span>
					</template>
				</div>
			</details>
			<details class="mt-4 details-custom-1" :open="true">
				<summary
					class="posR font-size-16 mainWhite backColorOrange fw700"
					style="z-index: 2; border: 1px solid #e56e00"
					@click="toggleAccordion($event.target, 2)"
				>
					물류 업무
					<span class="ml-1">{{ logisticsTaskList.logisticsTaskCnt > 0 ? logisticsTaskList.logisticsTaskCnt : 0 }} 건</span>
					<i class="accordionIcon3"></i>
				</summary>
				<div
					class="disFx flex-column narrowScroll todoListCont"
					style="
						height: 140px;
						overflow-y: auto;
						overflow-x: hidden;
						border-right: 1px solid #dadada;
						border-left: 1px solid #dadada;
					"
				>
					<template
						v-if="logisticsTaskList.returnWaitList.length > 0">
						<div class="padH6W16 cur_p pl-6"
							v-for="item in logisticsTaskList.returnWaitList"
							@click="returnCheck(item)">
							<div class="disFx">
								<h5 class="mr10 mainRed fw500">{{ item.logisTicsTaskStatus === 'RETURN_WAIT' ? '반품대기' : '-' }}</h5>
								<span
										class="mr-2 fw500 textEllipsis"
										:class="parent === 'dashboard' ? 'maxW130' : 'maxW70'"
								>{{ item.userName }}</span
								>
								<span class="mr-2 fw500">{{ item.userRegiNum1 !== "null" ? item.userRegiNum1 : '-' }}  </span>
								<span class="fw500">{{ item.userPhone.replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ) }}</span>
							</div>
							<span class="font-size-13 mainOrange fw500 ml-14">
								<template v-if="item.diffConsultRegiDays === 0">
									금일작성
								</template>
								<template v-else>
									{{ item.diffConsultRegiDays > 0 ? item.diffConsultRegiDays + '일후 작성' : Math.abs(item.diffConsultRegiDays) + '일전 작성'}}
								</template>
								{{item.consultRegiDateTime | moment('YYYY-MM-DD HH:mm:ss')}}
							</span>
						</div>
					</template>
					<template v-else>
						<span class="pl-5 boldWt font-size-18"
									style="text-align: center; line-height: 110px; padding-left: 0px !important;">
							처리 대기중인 물류 업무가 없습니다.
						</span>
					</template>
				</div>
			</details>
			<details class="mt-4 details-custom-1" :open="true">
				<summary
					class="posR font-size-16 fw700 mainWhite backColorMint1"
					style="border: #0099ae; pointer-events: none"
				>
					진행 업무
					<span class="ml-1">
						<template v-if="cr === '61c366ce54117ac8c1ce25fe7fa885600d38ca9e7b236fc0748eea23907af848'">
							{{ currentTaskList.creditInquireCnt }}
						</template>
						<template v-else>
							{{ currentTaskList.customerCnt
								- (cr === 'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd' ? currentTaskList.consultNoneAssignCnt : 0)
							}}
						</template>
						건</span>
				</summary>
				<div
					class="disFx flex-column narrowScroll"
					:class="
						parent === 'dashboard' ? 'dynamicAccordion' : 'dynamicAccordion1'
					"
					style="
						overflow-y: auto;
						overflow-x: hidden;
						border-right: 1px solid #ccc;
						border-left: 1px solid #ccc;
					"
				>
					<details :open="true" class="borderBottom1Gray"
						v-if="cr === 'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd'
						 || cr === '7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4'">
						<summary
							style="background: #ededed"
							class="posR pl-5 borderBottom1Gray fw600"
							@click="toggleAccordion($event.target)"
						>
							고객DB
							<b
								class="posR mainMint1 ml-2 txUnL fw600"
								style="position: absolute; left: 82px"
								@click.prevent="goPageMove('customer', 'ALL')"
								>{{ currentTaskList.customerCnt }} 건</b
							>
							<i class="accordionIcon"></i>
						</summary>
						<details class="posR font-size-13 backColorGray3" :open="true"
										 v-if="currentTaskList.customerRcptComplCnt !== 0">
							<summary
								class="pl-8 rotateIcon"
								:class="{ cur_d: parent === 'dashboard' }"
								@click="toggleAccordion($event.target)"
							>
								접수완료<b
									style="position: absolute; left: 82px"
									class="mainGray2 ml-2 txUnL fw600 cur_p"
									@click.prevent="goPageMove('customer', 'RcptCompl')"
									>{{ currentTaskList.customerRcptComplCnt }} 건</b
								>
								<i v-if="parent !== 'dashboard'" class="accordionIcon2"></i>
							</summary>
							<div v-if="parent !== 'dashboard'">
								<div
									class="disFx posR font-size-13 backColorGray3 pl-8 fw500 borderTop1Gray cur_p"
									style="padding: 8px 0"
									v-for="item in currentTaskList.customerRcptComplList"
									@click="currentTaskItemClickFnc(item, 'CUSTOMER')"
								>
									<span class="mr-3 mainMint1">[접수완료]</span>
									<div class="disFx flex-column">
										<div class="disFx">
											<span
												class="mr-2 fw500 textEllipsis"
												style="max-width: 70px"
											>{{ item.userName }}</span
											>
											<span class="mr-2 fw500">{{ item.userRegiNum1 !== "null" ? item.userRegiNum1 : '-' }} </span>
											<span class="fw500">{{ item.userPhone.replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ).replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ) }}</span>
										</div>
										<p class="font-size-13 mainOrange fw500">
											<template v-if="item.diffApplRegiDays === 0">
												금일작성
											</template>
											<template v-else>
												{{ item.diffApplRegiDays > 0 ? item.diffApplRegiDays + '일후 작성' : Math.abs(item.diffApplRegiDays) + '일전 작성'}}
											</template>
											{{item.applRegiDateTime | moment('YYYY-MM-DD HH:mm:ss')}}
										</p>
									</div>
								</div>
							</div>
						</details>
						<details class="posR font-size-13 backColorGray3" :open="true"
										 v-if="currentTaskList.customerReqCnt !== 0">
							<summary
								class="pl-8 rotateIcon"
								:class="{ cur_d: parent === 'dashboard' }"
								@click="toggleAccordion($event.target)"
							>
								요청필요<b
									style="position: absolute; left: 82px"
									class="mainGray2 ml-2 txUnL fw600 cur_p"
									@click.prevent="goPageMove('customer', 'Req')"
									>{{ currentTaskList.customerReqCnt }} 건</b
								>
								<i v-if="parent !== 'dashboard'" class="accordionIcon2"></i>
							</summary>
							<div v-if="parent !== 'dashboard'">
								<div
									class="disFx posR font-size-13 backColorGray3 pl-8 borderTop1Gray fw500 cur_p"
									style="padding: 8px 0"
									v-for="item in currentTaskList.customerReqList"
									@click="currentTaskItemClickFnc(item, 'CUSTOMER')"
								>
									<span class="mr-3 mainMint1">[요청필요]</span>
									<div class="disFx flex-column">
										<div class="disFx">
											<span
												class="mr-2 fw500 textEllipsis"
												style="max-width: 70px"
											>{{ item.userName }}</span
											>
											<span class="mr-2 fw500">{{ item.userRegiNum1 !== "null" ? item.userRegiNum1 : '-' }} </span>
											<span class="fw500">{{ item.userPhone.replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ) }}</span>
										</div>
										<p class="font-size-13 mainOrange fw500">
											<template v-if="item.diffApplRegiDays === 0">
												금일작성
											</template>
											<template v-else>
												{{ item.diffApplRegiDays > 0 ? item.diffApplRegiDays + '일후 작성' : Math.abs(item.diffApplRegiDays) + '일전 작성'}}
											</template>
											{{item.applRegiDateTime | moment('YYYY-MM-DD HH:mm:ss')}}
										</p>
									</div>
								</div>
							</div>
						</details>
						<details class="posR font-size-13 backColorGray3" :open="true"
										 v-if="currentTaskList.customerExchangeCnt !== 0">
							<summary
								class="pl-8 rotateIcon"
								:class="{ cur_d: parent === 'dashboard' }"
								@click="toggleAccordion($event.target)"
							>
								교품접수<b
									style="position: absolute; left: 82px"
									class="mainGray2 ml-2 txUnL fw600 cur_p"
									@click.prevent="goPageMove('customer', 'Exchange')"
									>{{ currentTaskList.customerExchangeCnt }} 건</b
								>
								<i v-if="parent !== 'dashboard'" class="accordionIcon2"></i>
							</summary>
							<div v-if="parent !== 'dashboard'">
								<div
									class="disFx posR font-size-13 backColorGray3 pl-8 borderTop1Gray fw500 cur_p"
									style="padding: 8px 0"
									v-for="item in currentTaskList.customerExchangeList"
									@click="currentTaskItemClickFnc(item, 'CUSTOMER')"
								>
									<span class="mr-3 mainMint1">[교품접수]</span>
									<div class="disFx flex-column">
										<div class="disFx">
											<span
												class="mr-2 fw500 textEllipsis"
												style="max-width: 70px"
											>{{ item.userName }}</span
											>
											<span class="mr-2 fw500">{{ item.userRegiNum1 !== "null" ? item.userRegiNum1 : '-' }} </span>
											<span class="fw500">{{ item.userPhone.replace(/([0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3" ) }}</span>
										</div>
										<p class="font-size-13 mainOrange fw500">
											<template v-if="item.diffApplRegiDays === 0">
												금일작성
											</template>
											<template v-else>
												{{ item.diffApplRegiDays > 0 ? item.diffApplRegiDays + '일후 작성' : Math.abs(item.diffApplRegiDays) + '일전 작성'}}
											</template>
											{{item.applRegiDateTime | moment('YYYY-MM-DD HH:mm:ss')}}
										</p>
									</div>
								</div>
							</div>
						</details>
					</details>
				</div>
			</details>
		</template>
	</div>
</template>

<script>
import bus from '@/common/bus';

export default {
	name: 'DashboardTodo',
	components: {
	},
	data: () => ({
	}),
	props: {
		parent: {
			default: 'dashboard',
		},
	},
	computed: {
		todoRnbList() {
			return this.$store.state.TodoModule.todoRnbList;
		},
		currentTaskList() {
			return this.$store.state.TodoModule.currentTaskList;
		},
		logisticsTaskList() {
			return this.$store.state.TodoModule.logisticsTaskList;
		},
		ms() {
			return this.$store.state.cookies.ms;
		},
		oi() {
			return this.$store.state.cookies.oi;
		},
		cr() {
			return this.$store.state.cookies.cr;
		},
		dashboardAuth() {
			if (
					//1차
					this.cr ===
					'61c366ce54117ac8c1ce25fe7fa885600d38ca9e7b236fc0748eea23907af848'
			) {
				return 'cr1';
			} else if (
					//2차
					this.cr ===
					'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd'
			) {
				return 'cr2';
			} else if (
					//매니저
					this.cr ===
					'7f3f2d7de4065dac070f7f2c51030d329214e7243660b785dbefd4c7136838b4'
			) {
				return 'crM';
			} else if (
					this.cr === null ||
					this.cr === '4785c867cdf74b4bd9a903bbb3e5d619'
			) {
				// 없음 or null
				return false;
			}
		},
	},
	methods: {
		toggleAccordion(target, idx) {
			target.classList.toggle('rotateIcon');
			if (idx !== undefined) {
				let accordion;
				if (this.parent === 'dashboard') {
					accordion = document.querySelector('.dynamicAccordion');
				} else if (this.parent === 'rnb') {
					accordion = document.querySelector('.dynamicAccordion1');
				}
				if (idx === 1) {
					accordion.classList.toggle('close-1');
				} else if (idx === 2) {
					accordion.classList.toggle('close-2');
				}
			}
		},
		getTodoList() {
			this.$store.dispatch(
					'TodoModule/getTodoRnbList'
			);
		},
		getCurrentTaskList() {
			let obj = {
				path: this.$route.path,
			}
			this.$store.dispatch(
					'TodoModule/getCurrentTaskList', obj,
			);
		},
		getLogisticsTaskList() {
			let obj = {
				path: this.$route.path,
			}
			this.$store.dispatch(
					'TodoModule/getLogisticsTaskList', obj,
			);
		},
		async todoClickFnc(item) {
			bus.$emit('todoDialogBus', item);
		},
		async currentTaskItemClickFnc(item, openTarget) {
			item.consultType = openTarget;
			if(openTarget === 'CUSTOMER') {
				item.dialogTypeText = '상세';
			}
			bus.$emit('todoDialogBus', item);
		},
		goPageMove(targetRoute, filterType) {
			let pushName = '';
			let paramOnj = {};
			// 신용조회
			if (targetRoute === 'credit') {
				if(this.cr === '61c366ce54117ac8c1ce25fe7fa885600d38ca9e7b236fc0748eea23907af848') {
					pushName = 'customer-consult'; // 상담관리_1차상담원
				} else {
					pushName = 'consult-db'; // 상담관리_2차상담원, 매니저
				}
			// 상담DB
			} else if (targetRoute === 'consult') {
				if(filterType) {
					// 미진행 / 접수중 / 미배정
					let statuses = [];
					if (this.currentTaskList.consultNoneProgressCnt > 0
							&& (filterType === 'ALL' || filterType === 'NoneProgress')) {
						statuses.push(0); // 미진행
					}
					if (this.currentTaskList.consultProcCnt > 0
							&& (filterType === 'ALL' || filterType === 'Proc')) {
						statuses.push(this.currentTaskList.secondConsultStatusId); // 접수중
					}
					if(statuses.length > 0) {
						paramOnj.srhSecondConsultStatuses = statuses; // 상태필터
					}
					if (this.currentTaskList.consultNoneAssignCnt > 0
							&& (filterType === 'ALL' || filterType === 'NoneAssign')
							&& this.cr !== 'f90f1e2b98f28d5133eda26ec2573c74481a0ad801bc5fae75acf49decf992fd') {
						paramOnj.srhFirstConsultProcessType = 'CONSULT_PROGRESS'; // 1차상담 진행중 카테고리 필터
						paramOnj.srhSecondMemberIds = 0; // 미배정
					}
				}
				pushName = 'consult-db'; // 상담관리_2차상담원, 매니저
			// 고객DB
			} else if (targetRoute === 'customer') {
				if(filterType) {
					// 접수완료[RCPT_COMPL] 요청필요
					// 교품접수[EXCHANGE_RCPT_DLVR_CONSULT,EXCHANGE_RCPT_CONSULT]
					let applStatuses = [];

					if (this.currentTaskList.customerRcptComplCnt > 0
							&& (filterType === 'ALL' || filterType === 'RcptCompl')) {
						applStatuses.push('RCPT_COMPL'); // 접수완료
					}
					if (this.currentTaskList.customerReqCnt > 0
							&& (filterType === 'ALL' || filterType === 'Req')) {
						applStatuses.push('RETRACT_DMND_CONSULT'); // 요청필요
						applStatuses.push('RETURN_DMND_CONSULT'); // 요청필요
					}
					if (this.currentTaskList.customerExchangeCnt > 0
							&& (filterType === 'ALL' || filterType === 'Exchange')) {
						applStatuses.push('EXCHANGE_RCPT_CONSULT'); // 교품접수
					}
					if(applStatuses.length > 0) {
						paramOnj.srhConsultTaskStatuses = applStatuses; // 상태필터
					}
				}
				pushName = 'customer-db'; // 고객관리_2차상담원, 매니저
			}
			// pushName = 'customer-consult'; // 상담관리_1차상담원
			// pushName = 'consult-db'; // 상담관리_2차상담원, 매니저
			// pushName = 'customer-db'; // 고객관리_2차상담원, 매니저

			// 고객DB 마이그레이션으로 인한 전체 신청서가 조회됨, 카운트는 개별카운트임으로 필터에 등록자 추가필요 2022.11.07
			paramOnj.applRegiUserName = this.$store.state.cookies.name;
			if(this.$route.name !== pushName) {
				this.$router.push({name: pushName, params: paramOnj});
			} else {
				bus.$emit('todoNotiFilterSearch:msg', paramOnj)
			}
		},
		async currentTaskClickFnc(item) {
			bus.$emit('todoDialogBus', item);
		},
		returnCheck(item){
			bus.$emit('returnCheckBus', item);
		},
	},
	created() {
		this.getTodoList();
		this.getCurrentTaskList();
		this.getLogisticsTaskList();
	},
};
</script>

<style scoped>
summary {
	cursor: pointer;
	outline: none;
}
details > summary {
	list-style: none;
}
details > summary::-webkit-details-marker {
	display: none;
}
details > p {
	margin-bottom: 0 !important;
	font-size: 13px;
}
.details-custom-1 summary {
	padding: 8px 16px;
}

.details-custom-1 {
	border-bottom: 1px solid #dadada;
}

.padH6W16 {
	padding: 6px 16px;
}
.padH8W40 {
	padding: 8px 40px !important;
}
.padH8W10 {
	padding: 8px 10px;
}
.accordionIcon,
.accordionIcon2 {
	pointer-events: none;
}
.accordionIcon:before {
	position: absolute;
	right: 0;
	content: url('../../../src/assets/img/down_arrow5.png');
}
.accordionIcon2:before {
	position: absolute;
	right: 0;
	content: url('../../../src/assets/img/down_arrow5_small.png');
}
.details-custom-1 .accordionIcon:before {
	right: 12px;
}
.details-custom-1 .accordionIcon2:before {
	right: 15px;
}
.todoListCont > div:first-of-type {
	border-top: 0;
}
.todoListCont > div {
	border-bottom: 1px solid #eee;
}
.accordionIcon3 {
	pointer-events: none;
}
.accordionIcon3:before {
	position: absolute;
	right: 16px;
	content: url('../../../src/assets/img/down_arrow6.png');
}
.rotateIcon .accordionIcon:before,
.rotateIcon .accordionIcon2:before,
.rotateIcon .accordionIcon3:before {
	transform: rotate(180deg);
}
.borderTop1Gray {
	border-top: 1px solid #e1e1e1;
}
.borderBottom1Gray {
	border-bottom: 1px solid #e1e1e1;
}
.borderBottom1Gray > .backColorGray3 {
	border-bottom: 1px solid #e1e1e1;
}
.borderBottom1Gray > .backColorGray3:last-of-type {
	border-bottom: 0;
}
.dynamicAccordion1.close-1,
.dynamicAccordion1.close-2 {
	height: 546px !important;
}
.dynamicAccordion1.close-1.close-2 {
	height: 686px !important;
}
.dynamicAccordion1 p {
	margin: 0;
}
.dynamicAccordion1 {
	height: 406px;
}
.dynamicAccordion {
	height: 305px;
}
.dynamicAccordion.close-1,
.dynamicAccordion.close-2 {
	height: 445px !important;
}
.dynamicAccordion.close-1.close-2 {
	height: 585px !important;
}
.noEvent {
	pointer-events: none;
}

.maxW70 {
	max-width: 70px;
}
.maxW130 {
	max-width: 130px;
}

.commingSoonStyle1 {
	margin-top: 10px;
	width: 100%;
	height: 870px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: #666;
	font-weight: 500;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
}
</style>
