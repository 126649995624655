import Vue from 'vue';
import Vuex from 'vuex';
import { commonCodeEnumList, getChargeList } from '@/api/common/common';
import {
	getRetentionList,
	getRetentionCustomerProgressCount,
	getRetentionDetailInfo,
	getSalesRetentionList,
	getRetentionConsultProgressCount,
	getSalesRetentionDetailInfo,
	allocationRetention,
	assignRetentionConsult,
	getAllocationRetentionCount,
	getRetentionConsultFilterGroup,
	getRetentionConsultAssignList,
} from "@/api/sales-mgmt/RetentionDbApi";

Vue.use(Vuex);

const RetentionDbModule = {
	namespaced: true,
	state: {
		allCheckedBox: false,
		assignDialog: false,
		resetData: false,
		retentionDialog: false,
		complainInfoPop: false,
		dateResetData: false,
		agreementPeriodStartResult: 18,
		totalCnt: 0,
		filterData: {
			assignYn: 'N',
			allocationYn: 'N',
			agreementPeriodStart: 18,
			agreementPeriodEnd: 36,
			cancelFeeYn: 'Y',
			perPageCnt: 20,
			pageNo: 1,
			orderAgreementPeriod: '',
			orderCancelFeeAmt: '',
			orderCancelTotalAmt: '',
			orderMonthPaymentAmt: '',
			orderResidueInstlAmt: ''
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		codeList: {
			joinTypeItems: [], // 가입유형
			openStoreList: [],
			chargeList: [], // 요금제
			chargeReductTypeItems: [], // 요금감면
			installmentsPeriodTypeItems: [], // 할부기간
			agreementPeriodEndItems: [], // 약정기간
			agreementPeriodStartItems: [], // 약정 시작
			secondConsultInfoList: [], // 2차 상담원 필터 목록
			secondStatusInfoList: [], // 상담상태
			openingTaskStatusList: [], // 개통상태
		},
		checkListArray: [],
		mainList: [],
		retentionCustomerProgressCount: [],
		retentionConsultProgressCount: {
			consultSecondGroupList: [],
			customerStatusGroupCountList: [],
		},
		historyList: [], // 상담이력 목록
		retentionDetailInfo: null, // 리텐션 DB 상세정보
		cancelFeeYnChk: false,
		listAllocationYn: 'N', // [대리점] 전체 DB 배당 구분값
		listAssignYn: 'N', // [판매점] 전체 DB 배정 구분값
		filterDataTemp: null,
	},
	mutations: {
		setCommonCodeEnumList(state, result) {
			state.codeList.joinTypeItems = result.JoinType || []; // 가입유형
			state.codeList.chargeReductTypeItems = result.ChargeReductType || []; // 요금감면
			state.codeList.installmentsPeriodTypeItems =
				result.InstallmentsPeriodType || []; // 할부기간

			// result.AgreementPeriodType.map(item=>{
			// 	item.name = item.name + '까지';
			// })

			let agreementPeriodEndArr = [];
			for (let i = 1; i <= 3; i++) {
				let resultMonth = i*12;
				agreementPeriodEndArr.push({name: resultMonth+'개월 경과까지', value: resultMonth})
			}
			state.codeList.agreementPeriodEndItems = agreementPeriodEndArr; // 약정기간

			let agreementPeriodStartArr = [];
			for (let i = 6; i <= 24; i++) {
				agreementPeriodStartArr.push({name: i+'개월 경과', value: i})
			}
			state.codeList.agreementPeriodStartItems = agreementPeriodStartArr;

			state.codeList.openingTaskStatusList = result.OpeningTaskStatus;
			state.codeList.openingTaskStatusList.unshift({
				name: '개통요청(전체)',
				value: 'OPENING_REQ_ALL',
			});
			if (
				state.codeList.openingTaskStatusList !== null ||
				state.codeList.openingTaskStatusList !== undefined
			) {
				state.codeList.openingTaskStatusList.map(item => {
					item.name =
						item.name !== null ? item.name.replace('(개통)', '') : null;
					return item;
				});
				/*this.statusList.OpeningTaskStatus.splice(2, 0, { divider: true });*/
			}
		},
		setChargeList(state, result) {
			state.codeList.chargeList = result || [];
		},
		setRetentionList(state, result) {
			let resultMainList = [];
			result.resultList.forEach(item=>{
				if(state.filterData.cancelFeeYn === 'Y') {
					state.cancelFeeYnChk = true;
					item.cancelTotalAmtResult = item.cancelTotalAmt;
				} else {
					item.cancelTotalAmtResult = item.cancelTotalAmtExcludeCancelFeeAmt;
					state.cancelFeeYnChk = false;
				}
				resultMainList.push(item);
			})
			//filterData.cancelFeeYn === 'Y' ? commaFnc(item.cancelTotalAmt) :
			state.mainList = resultMainList || [];
			state.pagingData.totalCnt = result.totalCnt;
			state.pagingData.perPageCnt = result.perPageCnt;
			state.pagingData.pageNo = result.pageNo;
			state.pagingData.pageNumList = result.pageNumList;
			state.pagingData.pageLastNo = result.pageLastNo;
			state.pagingData.pageEndNo = result.pageEndNo;
			state.agreementPeriodStartResult = state.filterData.agreementPeriodStart;
		},
		setRetentionCustomerProgressCount(state, result) {
			state.retentionCustomerProgressCount = result || [];
		},
		setRetentionConsultProgressCount(state, result) {
			state.retentionConsultProgressCount = result || [];
		},
		setRetentionDetailInfo(state, result) {
			state.retentionDetailInfo = result;
		},
		initMainList(state) {
			state.mainList = [];
			state.totalCnt = 0;
			state.checkListArray = [];
			state.filterDataTemp = {...state.filterData};
		},
		setRetentionConsultFilterGroup(state, result) {
			state.codeList.secondConsultInfoList = result.secondConsultInfoList || []; // 2차 상담원 필터 목록
			state.codeList.secondStatusInfoList = result.secondStatusInfoList || []; // 상담상태
		},
	},
	actions: {
		async commonCodeEnumList({ commit }) {
			try {
				let data = {
					initEnumData: [
						'JoinType', // 가입유형
						'ChargeReductType', // 요금감면
						'InstallmentsPeriodType', // 할부기간
						'OpeningTaskStatus', // 개통상태
					],
				};
				const result = await commonCodeEnumList(data);
				if (result) {
					commit('setCommonCodeEnumList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getChargeList({ commit }, data) {
			try {
				const result = await getChargeList(data);
				if (result) {
					commit('setChargeList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetentionList({ commit }, data) {
			try {
				const result = await getRetentionList(data);
				commit('initMainList', result);
				if (result) {
					commit('setRetentionList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetentionCustomerProgressCount({ commit }, data) {
			try {
				let paramData = {...data};
				delete paramData.customerProgressType;
				const result = await getRetentionCustomerProgressCount(paramData);
				if (result) {
					commit('setRetentionCustomerProgressCount', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getSalesRetentionList({ commit }, data) {
			try {
				const result = await getSalesRetentionList(data);
				commit('initMainList', result);
				if (result) {
					commit('setRetentionList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetentionConsultProgressCount({ commit }, data) {
			try {
				let paramData = {...data};
				delete paramData.customerProgressType;
				delete paramData.secondConsultProcessType;
				const result = await getRetentionConsultProgressCount(paramData);
				if (result) {
					commit('setRetentionConsultProgressCount', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetentionDetailInfo({ commit }, data) {
			try {
				const result = await getRetentionDetailInfo(data);
				if (result) {
					commit('setRetentionDetailInfo', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getSalesRetentionDetailInfo({ commit }, data) {
			try {
				const result = await getSalesRetentionDetailInfo(data);
				if (result) {
					commit('setRetentionDetailInfo', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async allocationRetention({ commit }, data) {
			try {
				const result = await allocationRetention(data);
				if (result) {
					return result.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async assignRetentionConsult({ commit }, data) {
			try {
				const result = await assignRetentionConsult(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getAllocationRetentionCount({ commit }, data) {
			try {
				const result = await getAllocationRetentionCount(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data.allocationSalesList;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetentionConsultFilterGroup({ commit }, data) {
			try {
				const result = await getRetentionConsultFilterGroup(data);
				if (result) {
					commit('setRetentionConsultFilterGroup', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetentionConsultAssignList({ commit }, data) {
			try {
				const result = await getRetentionConsultAssignList(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				} else {
					return [];
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default RetentionDbModule;
