import Vue from 'vue';
import Vuex from 'vuex';
import {
	getMgmtTargetDevicePage,
	getMgmtTargetDeviceGroupList,
	insertMediationOut,
	apprMediationOut,
	rejectMediationOut,
} from '../../../../api/warehouse-mgmt/stock-mgmt/MgmtTargetDeviceApi.js';
import { getRetrieveDeviceHistory } from '../../../../api/common/common.js';
import { commonCodeEnumList } from '@/api/warehouse-mgmt/stock-mgmt/StockStatusApi';
import { updateJudgementStatus } from '@/api/warehouse-mgmt/stock-mgmt/FaultyDeviceStatusApi';
import { getComplainStatusCnt } from '@/api/comm-mgmt/complain-hndlg/ComplainHndlgApi';

Vue.use(Vuex);

const MgmtTargetDeviceModule = {
	namespaced: true,
	state: {
		notiDialog: false,
		detailDialog: false,
		mediationDialog: false,
		modifyDialog: false,
		resetData: false,
		dataResetData: false,
		totalCnt: 0,
		listType: 0,
		listData: [],
		listGroupCntData: [
			{
				stockStatStrMsg: '매장재고',
				stockStatStrCnt: 0,
			},
			{
				stockStatStrMsg: '이동재고',
				stockStatStrCnt: 0,
			},
		],
		listGroupData: [],
		listGroupDataView: [],
		listGroupDataViewCnt: 1,
		detailData: [],
		detailHistory: [],
		targetDeviceGroupList: [],
		mediationFormData: {
			dvcId: '',
			mediationOutDate: '',
			mediationOutMemo: '',
			mediationOutMemoPrev: '',
			mediationOutRequestType: 'DEDUCT',
			lostDeviceYn: 'N',
		},
		statusList: {
			goodsTypeItems: [],
			inStockTypeItems: [],
		},
		cloneData: {
			orderDate: '',
			orderEndDate: '',
			inStockRegiDate: '',
			inStockEndDate: '',
		},
		isCloneUsed: false,
		filterData: {
			telecom: '',
			inStockRegiDate: '',
			inStockEndDate: '',
			orderDate: '',
			orderEndDate: '',
			inStockStratRegiDate: '',
			inStockEndRegiDate: '',
			stockType: '',
			makerId: '',
			barcode: '',
			listBarcode: '',
			openingHistYn: '',
			productFaultyYn: '',
			eosYn: '',
			statusStr: '',
			orderOrderDate: '',
			orderInStockRegiDate: '',
			orderMoveDate: '',
			prevOpeningDeviceYn: '',
			listInStockStatus: '',
			listProductFaultyYn: '',
			listPrevOpeningDeviceYn: '',
			perPageCnt: 20,
			inStockTypes: [],
			inStockStatus: '',
			stockTypes: [],
			judgementStatuses: [],
			provIds: [],
			telecoms: [],
			makers: [],
			goodsIds: [],
			capacitys: [],
			colorNames: [],
			unLockYn: [],
			stockIds: [],
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
	},
	mutations: {
		filterInit(state) {
			for (let data in state.filterData) {
				if (
					data !== 'orderOrderDate' &&
					data !== 'orderInStockRegiDate' &&
					data !== 'orderMoveDate'
				)
					state.filterData[data] = '';
			}
			state.filterData.perPageCnt = 20;
		},
		subFilterInit(state, initOrder) {
			if (!initOrder) {
				state.filterData.orderOrderDate = '';
				state.filterData.orderInStockRegiDate = '';
				state.filterData.orderMoveDate = '';
			}
			state.filterData.listInStockStatus = '';
			state.filterData.listProductFaultyYn = '';
			state.filterData.listPrevOpeningDeviceYn = '';
			state.filterData.listBarcode = '';

			let arr = [
				'inStockTypes',
				'stockTypes',
				'judgementStatuses',
				'provIds',
				'telecoms',
				'makers',
				'goodsIds',
				'capacitys',
				'colorNames',
				'unLockYn',
				'stockIds',
			];
			for (let i in arr) {
				state.filterData[arr[i]] = [];
			}
		},
		initListGroupData(state) {
			state.listGroupCntData[0].stockStatStrCnt = 0;
			state.listGroupCntData[1].stockStatStrCnt = 0;
		},
		setGetListData(state, result) {
			state.listData = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setListGroupData(state, result) {
			let stockIdx = result.data.data.mgmtTargetDeviceGroupCnts.findIndex(
				obj => obj.stockStatStrMsg === '매장재고',
			);
			let moveIdx = result.data.data.mgmtTargetDeviceGroupCnts.findIndex(
				obj => obj.stockStatStrMsg === '이동재고',
			);

			if (stockIdx > -1) {
				state.listGroupCntData[0].stockStatStrCnt =
					result.data.data.mgmtTargetDeviceGroupCnts[stockIdx]
						.stockStatStrCnt || 0;
			}
			if (moveIdx > -1) {
				state.listGroupCntData[1].stockStatStrCnt =
					result.data.data.mgmtTargetDeviceGroupCnts[moveIdx].stockStatStrCnt ||
					0;
			}

			state.listGroupData = Object.freeze(
				result.data.data.mgmtTargetDeviceGroupDtos,
			);
			// 유형별 테이블 스크롤 페이징
			document.getElementById(`listGroupBox`).scroll(0, 0);
			state.listGroupDataViewCnt = 1;
			state.listGroupDataView = state.listGroupData.slice(
				0,
				state.listGroupDataViewCnt * 100,
			);
		},
		setDeviceHistory(state, result) {
			state.detailHistory = [];
			state.detailHistory = result.data.data;
		},
		setTargetDeviceGroupList(state, result) {
			state.targetDeviceGroupList = result.data.data;
		},
		initListData(state) {
			state.listData = [];
			state.totalCnt = 0;
		},
		setCommonCodeNewList(state, data) {
			state.statusList.goodsTypeItems = [];
			state.statusList.goodsTypeItems = data.GoodsType;
			state.statusList.inStockTypeItems = [];
			state.statusList.inStockTypeItems = data.InStockType;
		},
	},
	actions: {
		async getMgmtTargetDevicePage({ commit }, data) {
			commit('initListData');
			// commit('initListGroupData');
			try {
				if (data && data.goodsName === '전체') {
					data.goodsName = '';
				}
				if (data && data.capacity === '전체') {
					data.capacity = '';
				}
				if (data && data.colorName === '전체') {
					data.colorName = '';
				}
				const result = await getMgmtTargetDevicePage(data);
				if (result.data.resultCode === '0000') {
					commit('setGetListData', result);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
					if (data && data.capacity === '') {
						data.capacity = '전체';
					}
					if (data && data.colorName === '') {
						data.colorName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMgmtTargetDeviceGroupList({ commit }, data) {
			commit('initListGroupData');
			try {
				if (data && data.goodsName === '전체') {
					data.goodsName = '';
				}
				if (data && data.capacity === '전체') {
					data.capacity = '';
				}
				if (data && data.colorName === '전체') {
					data.colorName = '';
				}
				const result = await getMgmtTargetDeviceGroupList(data);
				if (result.data.resultCode === '0000') {
					commit('setListGroupData', result);
					if (data && data.goodsName === '') {
						data.goodsName = '전체';
					}
					if (data && data.capacity === '') {
						data.capacity = '전체';
					}
					if (data && data.colorName === '') {
						data.colorName = '전체';
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getEnumCommonCodeList({ commit }) {
			try {
				/* 신규 ENUM 데이터 */
				const newEnum = {
					initEnumData: [
						'stockCheckType',
						// 'stockManualCheckStatus',
						'GoodsType',
						'InStockType',
					],
				};
				const result = await commonCodeEnumList(newEnum);
				if (result) {
					commit('setCommonCodeNewList', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getRetrieveDeviceHistory({ commit }, data) {
			try {
				let result = await getRetrieveDeviceHistory(data);
				if (result) {
					commit('setDeviceHistory', result);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateJudgementStatus({ commit }, data) {
			try {
				let result = await updateJudgementStatus(data);
				if (result.data.resultCode === '0000') {
					alert('변경되었습니다.');
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMediationOut({ commit }, data) {
			try {
				let result = await insertMediationOut(data);
				if (result.data.resultCode === '0000') {
					alert('저장되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
				return false;
			}
		},
		async apprMediationOut({ commit }, data) {
			try {
				let result = await apprMediationOut(data);
				if (result.data.resultCode === '0000') {
					alert('승인 처리되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				getComplainStatusCnt();
				console.log(e);
				return false;
			}
		},
		async rejectMediationOut({ commit }, data) {
			try {
				let result = await rejectMediationOut(data);
				if (result.data.resultCode === '0000') {
					alert('취소 처리되었습니다.');
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
				return false;
			}
		},
	},
};

export default MgmtTargetDeviceModule;
