import { instance, instanceNS } from '../index.js';
import { jsonToQueryString } from '../../common/common';

const domain = '/StatisticsManagement/StatisticsMgmt/';

// 상담상세 조회
const getOrganizationCompl = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(`${domain}getOrganizationCompl${param}`);
	return response;
};

export {
	getOrganizationCompl,
};
