<template>
	<div class="commonInput wm680">
		<div>
			<input
				:id="fileInputId"
				type="file"
				multiple
				style="display: none"
				:disabled="disableYN"
				@change="handleFileChange"
			/>
			<label
				class="uploadBtn disIN mt5"
				style="
					width: 80px;
					height: 30px;
					line-height: 30px;
					margin: 5px 0 4px 10px !important;
				"
				:for="fileInputId"
				>파일 추가</label
			>
		</div>
		<div
			v-if="fileData.length !== 0 || parentFileData.length !== 0"
			class="fileListBox mt-1"
			style="margin: 0 10px 6px 10px"
		>
			<div
				v-for="(item, index) in parentFileData"
				:key="item.id"
				class="alignBox"
			>
				<div class="left">
					<span>
						{{ item.regiDatetime | moment('YYYY-MM-DD HH:mm:ss') }}
					</span>
				</div>
				<div class="right">
					<span
						class="ellipsisH display_in"
						style="padding-top: 0; cursor: pointer"
						@click="fileDownload(item)"
					>
						{{ item.attachName }}.{{ item.attachExt }}
					</span>
					<span
						class="display_in"
						style="vertical-align: super; padding-top: 0"
					>
						<span
							v-if="
								item.attachExt !== 'pdf' &&
								item.attachExt !== 'audio' &&
								item.attachExt !== 'wav' &&
								item.attachExt !== 'mp3'
							"
							@click="filePreview(item)"
							style="margin: 0 5px"
						>
							<v-icon style="color: #7e7e7e; font-size: 12px; cursor: pointer"
								>fas fa-search</v-icon
							>
						</span>
						<v-icon
							style="
								color: #7e7e7e;
								background: none;
								margin: 0 5px;
								font-size: 12px;
								padding: 0;
							"
							@click="fileDownload(item)"
							>fas fa-download</v-icon
						>
						<v-icon
							style="
								color: #7e7e7e;
								background: none;
								margin: 0 5px;
								font-size: 12px;
								padding: 0;
							"
							@click="deleteAttach(index)"
							>fas fa-trash</v-icon
						>
					</span>
				</div>
			</div>

			<div v-for="item in fileData" :key="item.id" class="alignBox">
				<div class="left">
					<span>
						{{ todayDate() | moment('YYYY-MM-DD HH:mm:ss') }}
					</span>
				</div>
				<div class="right">
					<span class="ellipsisH display_in" style="padding-top: 0">
						{{ item.name }}
					</span>
					<span
						class="display_in"
						style="vertical-align: super; padding-top: 0"
					>
						<v-icon
							style="
								color: #7e7e7e;
								background: none;
								margin: 0 5px;
								font-size: 12px;
								padding: 0;
							"
							@click="deleteFile(item.name, item.size)"
							>fas fa-trash</v-icon
						>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { htmlParse } from '@/common/common';

export default Vue.extend({
	props: {
		titleWidth: {
			type: Number,
			default: 120,
		},
		disableYN: {
			type: Boolean,
		},
		necessary: {
			type: Boolean,
		},
		applId: {
			type: Number,
		},
		fileInputId: {
			type: String,
			required: true,
		},
		parentFileData: {
			type: Array,
			default: () => [],
		},
		modifyState: {
			type: Boolean,
		},
		resetFileData: {
			type: Boolean,
		},
	},
	data: () => ({
		dialog: true,
		propsFileList: [],
		fileData: [],
		url: '',
	}),
	computed: {
		beforeAdmin() {
			// TODO 배포시 주석해제
			/*return this.applId <= 28177;*/
			return this.applId <= 1;
		},
	},
	methods: {
		filePreview(item) {
			let url = `${this.url}download/file/applicationAttachMgmt/${
				item.applId || 0
			}/${item.applAttachId}`;
			// console.log(url);

			let OpenWindow = window.open(
				'',
				'_blank',
				'width = 700,height=800, menubars=no, scrollbars=auto',
			);
			let img = new Image();
			img.src = url;
			OpenWindow.document.write(
				"<style>body{margin:0px;}</style><img src='" +
					url +
					"' width= 100%'" +
					"'>",
			);
		},
		async fileDownload(item) {
			console.log('downloading...');

			fetch(item.logicalPath, {
				method: 'GET',
			})
				.then((resp) => resp.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = item.attachName;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				});
			// if (this.beforeAdmin) {
			// 	url = item.logicalPath;
			// } else {
			// 	url =
			// 		process.env['VUE_APP_SERVER_AUTH'] +

			// 		`download/file/applicationAttachMgmt/${item.applId || 0}/${
			// 			item.applAttachId
			// 		}`;
			// }

			// window.open(url, '', '');4

		},
		// 파일 추가 Fnc
		handleFileChange(e) {
			// 벨리데이션
			let fileType = [
				'jpeg',
				'jpg',
				'gif',
				'pdf',
				'png',
				'wav',
				'audio',
				'mpeg',
			];
			for (let i = 0; i < e.target.files.length; i++) {
				let data = e.target.files[i].type.split('/');
				let fileTypeChk = false;
				if (e.target.files[i].size > 10485760) {
					alert(`파일용량이 초과했습니다. \n(한파일당 최대 : 10MB)`);
					break;
				}
				for (let j = 0; j < fileType.length; j++) {
					if (fileType[j] === data[1]) {
						fileTypeChk = true;
						break;
					}
				}
				if (!fileTypeChk) {
					alert(
						`파일형식이 다릅니다. \n(JPEG / JPG / GIF / PDF / PNG / AUDIO / WAV 형식만 가능)`,
					);
					return;
				}
				// 파일 추가
				if (fileTypeChk) {
					this.fileData.push(e.target.files[i]);
				}
			}
		},
		// 파일 삭제
		deleteFile(name, size) {
			let fileCopy = [];
			this.fileData.forEach(i => {
				// 기존에 들어있는 fileData 안의 값과
				// 받아온 값이 다른 데이터만 새로운 배열에 넣는다
				if (i['name'] !== name && i['size'] !== size) fileCopy.push(i);
			});
			// 값 바꾸기
			this.fileData = fileCopy;
		},
		// 현재 날짜
		todayDate() {
			let today = new Date();
			return today.toISOString();
		},
		// 파일 삭제
		async deleteAttach(index) {
			let data;
			if (this.fileInputId === 'fileInput3') {
				data = { fileList: 'propsComplainFileList', index: index };
			} else if (this.fileInputId === 'fileInput4') {
				data = { fileList: 'propsPronounceFileList', index: index };
			} else {
				data = { fileList: 'fileList', index: index };
			}
			this.$emit('deletePropFile', data);
		},
	},
	watch: {
		resetFileData: {
			deep: true,
			handler: function (newVal) {
				this.fileData = [];
			},
		},
		fileData: {
			handler: function () {
				let data;
				if (this.fileInputId === 'fileInput3') {
					data = {
						name: 'complainFileList',
						fileData: this.fileData,
						yn: 'complainNewFileYn',
					};
				} else if (this.fileInputId === 'fileInput4') {
					data = {
						name: 'pronounceFileList',
						fileData: this.fileData,
						yn: 'pronounceNewFileYn',
					};
				} else {
					data = {
						name: 'fileData',
						fileData: this.fileData,
					};
				}
				this.$emit('fileValueFnc', data);
			},
		},
	},
	created() {
		this.url = process.env['VUE_APP_SERVER_AUTH'];
	},
});
</script>

<style scoped>
.fileLabelBox {
	border: 1px solid #ddd;
	width: 80%;
	height: 30px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}
.fileListBox {
	background-color: #dedede;
	padding: 10px;
	overflow-y: scroll;
	max-height: 120px;
}
.uploadBtn {
	background-color: #0037a1;
	color: #fff;
	border-radius: 3px;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
}

.ml5 {
	margin-left: 5px;
}
.tableCont table tbody tr {
	cursor: auto;
}
.display_in {
	display: inline-block;
}
.leftBox {
	vertical-align: top;
}
.alignBox {
	display: table;
	width: 100%;
	padding: 0 10px;
}
.alignBox .left {
	float: left;
}
.alignBox .right {
	float: right;
}
input:disabled + label {
	background-color: #bdbdbd !important;
	cursor: default;
}
</style>
