import { render, staticRenderFns } from "./LogisticsTaskReturnPop.vue?vue&type=template&id=20c1581e&scoped=true&"
import script from "./LogisticsTaskReturnPop.vue?vue&type=script&lang=js&"
export * from "./LogisticsTaskReturnPop.vue?vue&type=script&lang=js&"
import style0 from "./LogisticsTaskReturnPop.vue?vue&type=style&index=0&id=20c1581e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c1581e",
  null
  
)

export default component.exports