import { instance } from '../../index.js';
import { jsonToQueryString } from '../../../common/common';

const domain = 'DeviceManagement/';

const getInStockList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}InStockMgmt/getInStockList${param}`,
	);
	return response;
};

const updateInStock = async data => {
	const response = await instance.post(
		`${domain}InStockMgmt/updateWaitInStock`,
		data,
	);
	return response;
};

// 유형별 입고 목록 조회
const getInStockGroupList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}InStockMgmt/getInStockGroupList${param}`,
	);
	return response;
};

const deleteInStock = async data => {
	const response = await instance.post(
		`${domain}InStockMgmt/deleteInStock`,
		data,
	);
	return response;
};

const getInStockHistoryList = async data => {
	let param = '';
	if (data) param = jsonToQueryString(data);
	const response = await instance.get(
		`${domain}InStockMgmt/getInStockHistoryList${param}`,
	);
	return response;
};

const updateDeviceProvider = async data => {
	const response = await instance.post(
		`${domain}InStockMgmt/updateDeviceProvider`,
		data,
	);
	return response;
};

export { getInStockList, updateInStock, getInStockGroupList, deleteInStock, getInStockHistoryList, updateDeviceProvider };
