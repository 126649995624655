
import Vue from 'vue';
import {
	basicFormData,
	etcFormData,
	memoFormData,
} from '../../../store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import FileUpload from '../../../components/FileUpload.vue';

export default Vue.extend({
	name: 'EtcInformation',
	components: { FileUpload },
	data: () => ({
		tabValue: '',
	}),
	computed: {
		applId(): string {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		etcData: {
			get(): etcFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.etc;
			},
			set(newValue: etcFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.etc = newValue;
			},
		},
		memoData: {
			get(): memoFormData {
				return this.$store.state.ApplicationFormCreationModule.memoData;
			},
			set(newValue: memoFormData) {
				this.$store.state.ApplicationFormCreationModule.memoData = newValue;
			},
		},
		consultUserData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.formData
					.consultList;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.formData.consultList =
					newValue;
			},
		},
		counselorItems(): number {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.counselorItems;
		},
		counselorItemAlls(): number {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.counselorItemsAll;
		},
		crListFlag: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.crListFlag;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.crListFlag = newValue;
			},
		},
		crData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.crData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.crData = newValue;
			},
		},
		basic: {
			get(): basicFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.basic;
			},
			set(newValue: basicFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.basic =
					newValue;
			},
		},
		applRegiUserId: {
			get(): basicFormData {
				return this.$store.state.ApplicationFormCreationModule.formData.basic
					.applRegiUserId;
			},
			set(newValue: basicFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.basic.applRegiUserId =
					newValue;
			},
		},
		ms(): any {
			return this.$store.state.cookies.ms;
		},
	},
	methods: {
		giftFnc() {
			this.etcData.giftList[0].giftName = null;
		},
		usedFnc() {
			this.etcData.usedDvcModelName = null;
			this.etcData.usedDvcCompe = null;
		},
		fileValueFnc(value: any) {
			this.etcData.attachList = value;
		},
		counselorItemCheckFnc(userId: any) {
			if (
				this.crListFlag ||
				this.crData.creditInquireId ||
				this.basic.creditInquireId
			) {
				if (userId === 0) {
					return this.counselorItems;
				}
				let findUser = false;
				for (let [key, value] of Object.entries(this.counselorItems)) {
					if (value.seq === userId) {
						findUser = true;
					}
				}
				if (findUser) {
					return this.counselorItems;
				} else {
					return this.counselorItemAlls;
				}
			} else {
				return this.counselorItems;
			}
		},
	},
	watch: {
		applRegiUserId() {
			if (
				(this.crListFlag ||
					this.crData.creditInquireId ||
					this.basic.creditInquireId) &&
				this.$route.query.applId === undefined
			) {
				this.consultUserData[1].consultUserId = this.basic.applRegiUserId;
			}
		},
	},
});
