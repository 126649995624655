import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const EnvSettingsModule = {
	namespaced: true,
	state: {
		noticeDialog: false,
		totalCnt: 0,
		filterData: {
			perPageCnt: 20,
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
	},
	mutations: {},
	actions: {},
};
export default EnvSettingsModule;
