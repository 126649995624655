import Vue from 'vue';
import Vuex from 'vuex';
import {
	getSignatrueList,
	insertSignatrue,
	updateSignatrue,
	retrieveEncId,
	getSignTemplateList,
} from '../../../../api/supply-chain-mgmt/signatrue-mgmt/SignatrueMgmtApi';
Vue.use(Vuex);

const SignatrueMgmtModule = {
	namespaced: true,
	state: {
		signDetailIdx: null,
		signatrueDto: {
			sign: {
				signIssDate: '',
				bizNum: '',
				issStoreName: '',
				issStoreName2: '',
				chargerName: '',
				cusName: '',
				cusPhone: '',
				cusPhone1: '',
				cusPhone2: '',
				cusPhone3: '',
				cusEmail: '',
				telecomName: '',
				billAmtKo: '',
				billAmtNum: '',
				openingDate: '',
				signTempId: null,
				signStatus: 'REQ',
				signType: 'MARKETING',
				signAgreementId1: 1,
				signAgreementId2: 2,
				signAgreementId3: null,
				signAgreementId4: null,
			},
		},
		signTemplateList: [],
		signatureList: [],
		filterData: {
			srhStartDate: '',
			srhEndDate: '',
			openingStoreProcStatus: [''],
			saleStoreProcStatus: [''],
			regiName: '',
			startRegiDate: '',
			endRegiDate: '',
			openingStoreId: '',
			saleStoreId: '',
			complainPath: '',
			complainType: '',
			startComplainStartDate: '',
			endComplainStartDate: '',
			startComplainHandlingEndDate: '',
			endComplainHandlingEndDate: '',
			cusName: '',
			cusPhone: '',
			startComplDate: '',
			endComplDate: '',
			startPronounceDate: '',
			endPronounceDate: '',
			perPageCnt: 20,
			pageNo: 1,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		totalCnt: 0,
		infoDialog: false,
		regiDialog: false,
	},
	getters: {},
	mutations: {
		initSignatureList(state) {
			state.signatureList = [];
			state.totalCnt = 0;
		},
		setSignatrueList(state, result) {
			state.signatureList = result.resultList;
			state.totalCnt = result.totalCnt || 0;
			state.pagingData.perPageCnt = result.perPageCnt || 0;
			state.pagingData.pageNo = result.pageNo || 0;
			state.pagingData.pageNumList = result.pageNumList || [];
			state.pagingData.pageLastNo = result.pageLastNo || 0;
			state.pagingData.pageEndNo = result.pageEndNo || 0;
		},
		setSignTemplateList(state, result) {
			state.signTemplateList = result;
		},
	},
	actions: {
		// 1. 서명내역 리스트 조회
		async getSignatrueList({ commit }, data) {
			commit('initSignatureList');
			try {
				const result = await getSignatrueList(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.data.data.resultList.length !== 0) {
						commit('setSignatrueList', result.data.data);
					}
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 3. 서명내역 등록
		async insertSignatrue({ commit }, data) {
			try {
				const result = await insertSignatrue(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					// alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 4. 서명내역 수정
		async updateSignatrue({ commit }, data) {
			try {
				const result = await updateSignatrue(data);
				if (result && '0000' === result.data.resultCode) {
					return true;
				} else {
					// alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 5. 서명 Seq 암호화
		async getRetrieveEncId({ commit }, data) {
			try {
				const result = await retrieveEncId(data);
				if (result && '0000' === result.data.resultCode) {
					return result.data.data;
				} else {
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		// 6. 서명 템플릿 리스트 조회
		async getSignTemplateList({ commit }, data) {
			try {
				const result = await getSignTemplateList(data);
				if (result && '0000' === result.data.resultCode) {
					if (result.data.data.length !== 0) {
						commit('setSignTemplateList', result.data.data);
					}
				} else {
					alert(result.data.resultMsg);
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};

export default SignatrueMgmtModule;
