import Vue from 'vue';
import Vuex from 'vuex';
import { getList } from '../../../../api/supply-chain-mgmt/application-form-creation/ReceiveRequestApi.js';

Vue.use(Vuex);

const ReceiveReqTableModule = {
	namespaced: true,
	state: {
		allCheckedBox: null,
		dateResetData: false,
		resetData: false,
		checkListIdArray: [],
		totalCnt: 0,
		filterData: {
			recipStartDate: '',
			recipEndDate: '',
			recipAmtType: '',
			srhStartAmt: '',
			srhEndAmt: '',
			perPageCnt: 20,
		},
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
		getListData: [],
		codeList: {
			saleStoreRecipReqStatus: [
				{ name: '수납요청', value: 'RECIP_REQ' },
				{ name: '수납요청취소', value: 'RECIP_REQ_CANCEL' },
				{ name: '결제취소요청', value: 'SETTLE_CANCEL_REQ' },
			],
			openingStoreRecipReqStatus: [
				{ name: '수납완료', value: 'RECIP_COMPL' },
				{ name: '수납보류', value: 'RECIP_PEND' },
				{ name: '수납취소완료', value: 'RECIP_CANCEL_COMPL' },
				{ name: '결제취소완료', value: 'SETTLE_CANCEL_COMPL' },
			],
			amtType: [
				{ name: '미납금', value: 'NONPAYMENT_AMT' },
				{ name: '할부금', value: 'INSTL_AMT' },
				{ name: '요금수납', value: 'RECIP_AMT' },
				{ name: '권리실행포인트', value: 'RECIP_POINT' },
			],
			paymentType: [
				{ name: '고객카드(개인)', value: 'CUSTOMER_INDV' },
				{ name: '고객카드(법인)', value: 'CUSTOMER_CORP' },
				{ name: '영업점카드(개인)', value: 'STORE_INDV' },
				{ name: '영업점카드(법인)', value: 'STORE_CORP' },
				{ name: '정산차감', value: 'ADJUST_DDCT' },
			],
			saleStoreItems: [],
			openStoreItems: [],
		},
	},
	mutations: {
		setList(state, result) {
			state.getListData = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
	},
	actions: {
		async getList({ commit }, data) {
			const result = await getList(data);
			if (result) {
				commit('setList', result);
			}
		},
	},
};

export default ReceiveReqTableModule;
