<template>
	<v-app-bar
		clipped-left
		app
		height="50"
		class="backColorBlue3"
		style="border: 0; z-index: 40"
	>
		<input id="newWindowDrawerYn" style="position: absolute; left: -9999px" />
		<button
			@click="drawer()"
			class="leftBtn"
			:class="{ drawerBtnMove: !drawerVal }"
		>
			<i
				class="fa fa-chevron-left slide arrowBtn"
				aria-hidden="true"
				:class="{ transbtn: drawerBtn }"
			>
			</i>
		</button>
		<div
			class="header"
			:class="
				process === 'production' || process === 'development'
					? ''
					: 'localBgColor'
			"
		>
			<div class="left">
				<router-link
					v-if="process === 'production'"
					class="logoWhite mainWhite"
					to="/dashboard"
				></router-link>
				<router-link v-else class="logoFake" to="/dashboard"
					>테스트</router-link
				>
				<button id="focusTarget"></button>
				<div
					class="floatR lh36 mainWhite boldWt h36 posA mainBlack"
					style="left: 250px; top: 6px"
					@click="
						openSysNotiDialog(
							topNotice.sysNoticeId,
							topNotice.systemNoticeTargetType,
						)
					"
				>
					<template v-if="topNotice">
						<span
							class="cur_p"
							:class="{
								mainWhite: process !== 'local',
								mainBlack: process === 'local',
							}"
						>
						</span>
						<span
							class="mainWhite txAnC borderRadi3Px disIN cur_p"
							:class="{
								backColorOrange: topNotice.systemNoticeTargetType === 'COMMON',
								backColorMint1: topNotice.systemNoticeTargetType === 'SALES',
								backColorBlue2: topNotice.systemNoticeTargetType === 'OPENING',
							}"
							style="padding: 0 8px; line-height: 19px"
						>
							{{ topNotice.systemNoticeTargetTypeMsg }}
						</span>
						<span
							class="txUnL ml5 cur_p"
							:class="{
								mainWhite: process !== 'local',
								mainBlack: process === 'local',
							}"
							>{{ toHtml(topNotice.sysNoticeTitle) }}
						</span>
					</template>
				</div>
			</div>
			<div class="right">
				<ul>
					<li class="posR mr30">
						<template
							v-if="st !== '22f353197e9b0c1cb58a11da8de7776a' && role === 'M'"
						>
							<div
								class="backColorBlue2 padW10 lh26 mainWhite boldWt borderRadi3Px mr30 cur_p"
								@click="linkToMallImport"
								v-if="initDomainStatusType !== 'ACCEPT'"
							>
								온라인 몰 입점
							</div>
						</template>
					</li>
					<li class="posR mr30">
						<div
							class="backColorBlue2 padW10 lh26 mainWhite boldWt borderRadi3Px mr30 cur_p"
							@click="openCalculator"
						>
							요금계산기
						</div>
					</li>
					<li class="posR mr30">
						<div
							class="backColorBlue2 padW10 lh26 mainWhite boldWt borderRadi3Px mr30 cur_p"
							@click="linkToCredit"
						>
							신용조회 요청 :
							<a class="reqLink" v-if="creditInquireReqCountData" v-cloak
								>{{ creditInquireReqCountData.creditProcStatusCnt }}
							</a>
						</div>
					</li>
					<li>
						<!-- [KT-CB] 신조개통점 권한 예외처리 -->
						<button
							v-if="ss !== '140' && ss !== '141'"
							class="locateBtn disFX backColorBlue2 padW10 lh26 mainWhite boldWt borderRadi3Px mr30"
							@click="linkFnc"
						>
							구전산 바로가기
							<em class="movingArrow"></em>
						</button>
					</li>
					<li class="mr10 fakeLine4">
						<div style="display: flex; align-items: center">
							<router-link class="myInfo" to="/change-user-info">
								<span class="userName mainWhite">
									{{ name }}
								</span>
							</router-link>
							<span v-if="role === 'A'" class="adminName">관리자</span>
							<span v-if="role === 'M'" class="adminName">매니저</span>
						</div>
					</li>
					<li>
						<button
							class="padW10 lh26 mainWhite borderContColor1 borderRadi3Px"
							@click="logoutFnc"
							style="background-color: #004ec3"
						>
							로그아웃
						</button>
					</li>
				</ul>
			</div>
		</div>
		<system-notice-list-pop
			v-if="noticeListDialog"
			:targetNoticeId="targetSysNoticeId"
			:targetSysNoticeType="targetSysNoticeType"
			@resetTargetNoticeId="resetTargetNoticeId"
		></system-notice-list-pop>
		<charge-calculator-pop v-if="headerCalcDialog"></charge-calculator-pop>
		<mall-audit-pop
			v-if="mallAuditDialog"
			:storeDomainData="storeDomainData"
			:dialog="mallAuditDialog"
		></mall-audit-pop>
	</v-app-bar>
</template>

<script>
import { notiUnSub, socketCheck } from '@/common/socketUtil';
import bus from '@/common/bus';
import { toHtml } from '../common/common';
import systemNoticeListPop from '../views/common-mgmt/system-mgmt/popup/SystemNoticeListPop';
import MallAuditPop from '../views/mall_mgmt/popup/MallAuditPop.vue';
import chargeCalculatorPop from '../views/sales-mgmt/consult-mgmt/charge-calculator/calculator-section-modal/ChargeCalculatorPop';

export default {
	components: { systemNoticeListPop, MallAuditPop, chargeCalculatorPop },
	data() {
		return {
			drawerBtn: false,
			process: '',
			targetSysNoticeId: 0,
			targetSysNoticeType: '',
			storeDomainData: null,
		};
	},
	computed: {
		sg() {
			return this.$store.state.cookies.sg;
		},
		name() {
			return this.$store.state.cookies.name;
		},
		role() {
			return this.$store.state.cookies.role;
		},
		st() {
			return this.$store.state.cookies.st;
		},
		sct() {
			return this.$store.state.cookies.sct;
		},
		initDomainStatusType() {
			return this.$store.state.initDomainStatusType;
		},
		drawerVal() {
			return this.$store.state.drawer;
		},
		creditInquireReqCountData() {
			return this.$store.state.CreditCheckModule.creditInquireReqCountData;
		},
		ss() {
			return this.$store.state.cookies.ss; // [KT-CB] 신조개통점 권한 예외처리
		},
		topNotice() {
			return this.$store.state.SystemMgmtModule.topNotice; // 공지사항 상단 출력 1건
		},
		noticeListDialog: {
			get() {
				return this.$store.state.SystemMgmtModule.noticeListDialog;
			},
			set(newValue) {
				this.$store.state.SystemMgmtModule.noticeListDialog = newValue;
			},
		},
		mallAuditDialog: {
			get() {
				return this.$store.state.MallDomainMgmtModule.mallAuditDialog;
			},
			set(newValue) {
				this.$store.state.MallDomainMgmtModule.mallAuditDialog = newValue;
			},
		},
		headerCalcDialog: {
			get() {
				return this.$store.state.ChargeCalculatorModule.headerCalcDialog;
			},
			set(newValue) {
				this.$store.state.ChargeCalculatorModule.headerCalcDialog = newValue;
			},
		},
	},
	methods: {
		linkFnc() {
			alert('SKLMNO 홈페이지 링크입니다.\n이력 확인용으로 사용해주세요.');
			window.open('https://sklmno.kr/', '_blank');
		},
		linkToCredit() {
			if (this.sg === 'StoreGrade_S') {
				if (location.pathname === '/credit-check-request') {
					window.location.replace(window.location.href);
				} else {
					this.$router.push({ name: 'credit-check-request' });
				}
			} else {
				if (location.pathname === '/credit-check') {
					window.location.replace(window.location.href);
				} else {
					this.$router.push({ name: 'credit-check' });
				}
			}
		},
		async getStoreDomain() {
			// 판매점 상세 조회
			const result = await this.$store.dispatch(
				'MallDomainMgmtModule/getStoreDomain',
			);
			if (result) {
				return result;
			}
		},
		async linkToMallImport() {
			const result = await this.getStoreDomain();
			if (result) {
				if (
					result.reviewStatusType === 'PROGRESS' ||
					result.reviewStatusType === 'REJECT'
				) {
					this.storeDomainData = result;
					return (this.mallAuditDialog = true);
				}
			}
			if (location.pathname === '/mall-import') {
				window.location.replace(window.location.href);
			} else {
				await this.$router.push({ name: 'mall-import' });
			}
		},
		async logoutFnc() {
			// document.location.href = '/login';
			await notiUnSub();
			await this.$router.push('/login').catch(() => {});
			// await this.$store.dispatch('LOGOUT', 'Y');
		},
		drawer() {
			this.drawerBtn = !this.drawerBtn;
			this.$store.state.drawer = !this.$store.state.drawer;
		},
		async getCreditInquireReqCount() {
			await this.$store.dispatch('CreditCheckModule/getCreditInquireReqCount');
		},
		toHtml(data) {
			if (data !== null && data !== undefined) {
				return toHtml(data);
			}
		},
		async getTopSysNotice() {
			await this.$store.dispatch('SystemMgmtModule/getTopSysNotice');
		},
		openSysNotiDialog(SysNoticeId, SysNoticeType) {
			if (SysNoticeId) {
				this.targetSysNoticeId = SysNoticeId;
				this.targetSysNoticeType = SysNoticeType;
				this.noticeListDialog = true;
			}
		},
		resetTargetNoticeId() {
			this.targetNoticeId = 0;
		},
		// 새창 popup 띄울때 drawer 닫을지 chk
		closeDrawer() {
			if (window.opener) {
				document.getElementById('newWindowDrawerYn').value =
					window.opener.document.getElementById('newWindowDrawerYn').value;
				if (document.getElementById('newWindowDrawerYn').value !== '') {
					this.$store.commit('closeDrawer');
					this.drawerBtn = true;
				}
			}
		},
		connectCheck() {
			socketCheck();
		},
		openCalculator() {
			if (this.$route.path === '/customer-mgmt/charge-calculator') {
				return false;
			} else {
				this.headerCalcDialog = true;
			}
		},
	},
	async created() {
		this.process = process.env.VUE_APP_NAME;
		await this.getCreditInquireReqCount();
		this.closeDrawer();
		bus.$on('socketCreReqCnt:msg', this.getCreditInquireReqCount);
	},
	beforeDestroy() {
		//window.localStorage.clear();
		window.localStorage.removeItem('mp');
		window.localStorage.removeItem('lastVersion');
		window.localStorage.removeItem('newVersion');
		bus.$off('socketCreReqCnt:msg', this.getCreditInquireReqCount);
	},
	mounted() {
		// 브라우저 종료시 컨펌 로직
		if (this.AppFormCreFlag) {
			window.addEventListener('beforeunload', event => {
				// 명세에 따라 preventDefault는 호출해야하며, 기본 동작을 방지합니다.
				// 표준에 따라 기본 동작 방지
				// window.localStorage.clear();
				window.localStorage.removeItem('mp');
				window.localStorage.removeItem('lastVersion');
				window.localStorage.removeItem('newVersion');
				if (this.AppFormCreFlag) {
					notiUnSub();
				}
			});
		}
	},
	async beforeRouteLeave(to, from, next) {
		notiUnSub();
	},
	watch: {
		$route(to, from) {
			// 페이지이동/뒤로가기 시 팝업강제 닫힘처리
			this.$store.state.SystemMgmtModule.noticeListDialog = false; // 영업관리 공지사항 목록팝업
			this.getTopSysNotice();
		},
	},
};
</script>
<style scoped>
.localBgColor {
	background-color: gold;
}
.reqLink {
	color: #ffffff;
	text-decoration: underline;
	font-weight: 500;
	padding: 2px 4px;
}
.myInfo {
	color: #5e5e5e;
	margin: 0;
	font-weight: bold;
	text-decoration-color: #ffffff;
}
.myInfo:hover {
	text-decoration: underline;
	text-underline-position: under;
	text-decoration-color: #ffffff;
}
.locateBtn {
	z-index: 1;
	-webkit-transition: all 0.25s;
	-moz-transition: all 0.25s;
	-ms-transition: all 0.25s;
	-o-transition: all 0.25s;
	transition: all 0.25s;
	color: #fff;
	position: relative;
}
.locateBtn:after {
	content: '';
	width: 0%;
	height: 100%;
	top: 0;
	position: absolute;
	left: 0;
	-webkit-transition: all 0.25s;
	-moz-transition: all 0.25s;
	-ms-transition: all 0.25s;
	-o-transition: all 0.25s;
	transition: all 0.25s;
	background: rgb(29, 142, 255);
	border-radius: 3px;
	z-index: -1;
}
.locateBtn:hover {
	color: #fff !important;
}
.locateBtn:hover:after {
	width: 100%;
	border-radius: 3px;
	z-index: -1;
}

.movingArrow {
	width: 12px;
	height: 12px;
	display: block;
	margin-top: 6px;
	margin-left: 4px;
	padding-right: 8px;
	background: url('../assets/img/doubleArrow.svg');
	background-size: contain;
	transition: all 0.35s;
}
.locateBtn:hover .movingArrow {
	transform: translateX(2px);
}
@keyframes movingArrow {
	0% {
		transform: translateX(0px);
	}

	100% {
		transform: translateX(3px);
	}
}

.chargeToggleBtn2 {
	border-radius: 20px 0 0 20px;
	transition: all 0.2s ease;
	cursor: pointer;
}
.chargeToggleBtn2:focus {
	outline: none;
}
</style>
