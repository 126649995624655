// 신청서에서만 쓰이고있음
export default {
	methods: {
		async getGoodsSelectListFnc() {
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getGoodsSelectList',
				this.joinData.openingTelecomCodeId,
			);
		},
		async deviceData(value) {
			if (value) {
				this.joinData.goodsId = value;
				this.capacityItems = [];
				this.colorItems = [];
				await this.getCapacityListFnc();
				if (this.capacityItems && this.capacityItems.length > 0) {
					this.joinData.capacity = this.capacityItems[0].capacity;
				}
				this.joinData.color = null;
				await this.getColorListFnc();
				await this.resetPriceFnc();
				await this.networkChangeFnc(); // 기기변경에 따라 요금제 목록 재조회
				//await this.setNetwork(); // 기기변경시 기기에 따른 통신망 선택처리
				if (this.joinData.openingTelecomName === 'SKT') {
					this.joinData.chargeId = 1474;
				}
				if (this.joinData.openingTelecomName === 'KT') {
					this.joinData.chargeId = 1472;
				}
				if (this.joinData.openingTelecomName === 'LGU') {
					this.joinData.chargeId = 1473;
				}
			}
		},
		async getCapacityListFnc() {
			this.colorItems = [];
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getCapacitySelectList',
				this.joinData.goodsId,
			);
		},
		async getColorListFnc() {
			let data = {
				goodsId: this.joinData.goodsId,
				capacity: this.joinData.capacity,
			};
			await this.$store.dispatch(
				'ApplicationFormCreationModule/getColorSelectList',
				data,
			);
		},
		async setNetwork() {
			// 선택된 기기에 있는 networkId 를 통신망 에 SET
			for (let i = 0; i < this.goodsItems.length; i++) {
				if (this.goodsItems[i].goodsId === this.joinData.goodsId) {
					this.joinData.networkId = this.goodsItems[i].network;
					await this.networkChangeFnc();
					break;
				}
			}
		},
	},
};
