import { focusVal } from '@/common/common';

export default {
	computed: {
		validationFocus: {
			get() {
				return this.$store.state.validationFocus;
			},
			set(newValue) {
				this.$store.state.validationFocus = newValue;
			},
		},
	},
	watch: {
		validationFocus() {
			const vm = this;
			focusVal(vm);
		},
	},
};
