
import Vue from 'vue';
import PostApi from '../../../components/PostApi.vue';
import {
	codeListData,
	customerFormData,
	dialogData,
} from '../../../store/interface/supply-chain-mgmt/application-form-creation/AppFormCreationInterface';
import numberSplit from '../../../common/numberSplit';
import BlackListTablePop from '@/views/supply-chain-mgmt/black-list/popup/BlackListTablePop.vue';
import NumRepMixin from '../../../common/NumRepMixin.js';
import bus from '@/common/bus';
import focusMixin from '../../../common/focusMixin.js';

interface dataType {
	postDialog: boolean;
	menu1: boolean;
	menu2: boolean;
	blackListTablePop: boolean;
	blackListMsg: any;
	duplApplMsg: string;
	blackListData: any;
	cusAflag: boolean;
	cusPflag: boolean;
}

export default Vue.extend({
	name: 'CustomerInformation',
	mixins: [numberSplit, NumRepMixin, focusMixin],
	components: { BlackListTablePop, PostApi },
	data: (): dataType => ({
		postDialog: false,
		menu1: false,
		menu2: false,
		blackListTablePop: false,
		blackListMsg: '',
		duplApplMsg: '',
		blackListData: {},
		cusAflag: false,
		cusPflag: false,
	}),
	computed: {
		blackNDupleMsg(): string {
			let resultMsg;
			if (!this.blackListMsg && !this.duplApplMsg) {
				resultMsg = '';
			} else {
				resultMsg = `블랙리스트: ${this.blackListMsg || '0건'} / 중복신청: ${
					this.duplApplMsg || '0건'
				}`;
			}
			return resultMsg;
		},
		cusAddr(): any {
			return this.customerData.cusAddr;
		},
		cusPhone(): any {
			return this.customerData.cusPhone;
		},
		customerDialog: {
			get(): boolean {
				return this.$store.state.ApplicationFormCreationModule.customerDialog;
			},
			set(newValue: boolean) {
				this.$store.state.ApplicationFormCreationModule.customerDialog =
					newValue;
			},
		},
		openingStoreData(): number {
			return this.$store.state.ApplicationFormCreationModule.formData.basic
				.openingStoreId;
		},
		customerData: {
			get(): customerFormData {
				return this.$store.state.ApplicationFormCreationModule.formData
					.customer;
			},
			set(newValue: customerFormData) {
				this.$store.state.ApplicationFormCreationModule.formData.customer =
					newValue;
			},
		},
		selectItems(): codeListData {
			return this.$store.state.ApplicationFormCreationModule.codeList;
		},
		addrDialog: {
			get(): dialogData {
				return this.$store.state.ApplicationFormCreationModule.dialogData;
			},
			set(newValue: dialogData) {
				this.$store.state.ApplicationFormCreationModule.dialogData = newValue;
			},
		},
		openingStoreTelecom(): any {
			return this.$store.state.ApplicationFormCreationModule
				.openingStoreTelecom;
		},
		nationItems(): Object {
			return this.$store.state.ApplicationFormCreationModule.codeList
				.nationalCodeItems;
		},
		AppFormCreFlag(): string {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		crData: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.crData;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.crData = newValue;
			},
		},
		basic: {
			get(): any {
				return this.$store.state.ApplicationFormCreationModule.formData.basic;
			},
			set(newValue: any) {
				this.$store.state.ApplicationFormCreationModule.formData.basic =
					newValue;
			},
		},
	},
	methods: {
		addressData(data: any) {
			this.customerData.cusAddr = data.address;
			this.customerData.cusZipCode = data.zonecode;
			this.checkBlackNDuplFnc();
			this.resetAddressFnc();
		},
		dialogClose(data: any) {
			this.postDialog = data;
		},
		customerDialogFnc() {
			let openingStore = false;
			if (this.openingStoreTelecom === 5 || this.openingStoreTelecom === 7) {
				openingStore = true;
			}
			if (openingStore && this.customerData.cusType === 'INDV_BSNM') {
				this.customerDialog = true;
			} else {
				this.customerDialog = false;
			}
			if (this.customerData.cusType === 'FOREIGN') {
				this.customerData.licenseAuthType = 'FOREIGN_RGSTR_CARD';
			} else {
				this.customerData.licenseAuthType = null;
			}
			this.resetCusTypeFnc();
		},
		resetAddressFnc() {
			this.addrDialog.deliveryAddrDialog = false;
			this.addrDialog.paymentAddrDialog = false;
		},
		resetCusTypeFnc() {
			this.customerData.courtProctorName = null; // 법정대리인명
			this.customerData.courtProctorRegiNumConcat = null; // 법정대리인주민등록번호
			this.customerData.courtProctorRegiNum1 = null; // 법정대리인주민등록번호
			this.customerData.courtProctorRegiNum2 = null; // 법정대리인주민등록번호
			this.customerData.courtProctorPhone = null; // 법정대리인연락처
			this.customerData.courtProctorPhone1 = null; // 법정대리인연락처
			this.customerData.courtProctorPhone2 = null; // 법정대리인연락처
			this.customerData.courtProctorPhone3 = null; // 법정대리인연락처
			this.customerData.courtProctorRelation = null; // 법정대리인관계
			this.customerData.bizName = null; // 사업자명
			this.customerData.bizNum = null; // 사업자번호
			this.customerData.bizNum1 = null; // 사업자번호
			this.customerData.bizNum2 = null; // 사업자번호
			this.customerData.bizNum3 = null; // 사업자번호
			this.customerData.bizPhone = null; // 사업자연락처
			this.customerData.bizPhone1 = null; // 사업자연락처
			this.customerData.bizPhone2 = null; // 사업자연락처
			this.customerData.bizPhone3 = null; // 사업자연락처
			this.customerData.bizRegiNum1 = null; // 법인등록번호
			this.customerData.bizRegiNum2 = null; // 법인등록번호
			this.customerData.cusName = null; //
			this.customerData.cusRegiNumConcat = null; //
			this.customerData.cusRegiNum1 = null; //
			this.customerData.cusRegiNum2 = null; //
			this.customerData.licenseNum1 = null;
			this.customerData.licenseNum2 = null;
			this.customerData.licenseNum3 = null;
			this.customerData.licenseNumConcat = null;
		},
		resetLicenseAuthTypeFnc() {
			this.customerData.licenseIssueDate = null; // 발급일자
			this.customerData.licenseExpiredDate = null; // 만료일자
			this.customerData.licenseIssueArea = null; // 만료일자
			this.customerData.licenseNum1 = null; // 면허번호
			this.customerData.licenseNum2 = null; // 면허번호
			this.customerData.licenseNum3 = null; // 면허번호
			this.customerData.stayCode = null; // 체류코드
			this.customerData.ntnlCode = null; // 국가
		},
		async checkBlackNDuplFnc(click?: string) {
			if (!click) {
				if (
					this.cusPhone != null &&
					this.cusPhone.length < 11 &&
					this.cusAddr != null &&
					this.cusAddr.length > 0
				) {
					this.blackListMsg = '';
					this.duplApplMsg = '';
				} else if (
					this.customerData.cusPhone1 != null &&
					this.customerData.cusPhone1.length > 0 &&
					this.customerData.cusPhone2 != null &&
					this.customerData.cusPhone2.length > 0 &&
					this.customerData.cusPhone3 != null &&
					this.customerData.cusPhone3.length > 0 &&
					this.customerData.cusAddr != null &&
					this.customerData.cusAddr.length > 0
				) {
					await this.getBlackDetailList();
					await this.checkDuplicationApplication();
				}
			} else {
				if (
					this.customerData.cusPhone1 == null ||
					this.customerData.cusPhone1 == '' ||
					this.customerData.cusPhone2 == null ||
					this.customerData.cusPhone2 == '' ||
					this.customerData.cusPhone3 == null ||
					this.customerData.cusPhone3 == ''
				) {
					// return alert('휴대폰번호를 입력해주세요.');
					return;
				}
				if (
					this.customerData.cusAddr === null ||
					this.customerData.cusAddr === ''
				) {
					// return alert('주소를 입력해주세요.');
					return;
				} else {
					await this.getBlackDetailList();
					await this.checkDuplicationApplication();
				}
			}
		},
		async getBlackDetailList() {
			if (
				this.customerData.cusPhone1 !== null &&
				this.customerData.cusPhone2 !== null &&
				this.customerData.cusPhone3 !== null &&
				this.customerData.cusAddr !== null
			) {
				let data = {
					blackAddr: this.customerData.cusAddr,
					blackPhone:
						this.customerData.cusPhone1 +
						this.customerData.cusPhone2 +
						this.customerData.cusPhone3,
				};
				const result = await this.$store.dispatch(
					'ApplicationFormCreationModule/getDetailList',
					data,
				);
				if ('0000' === result.data.resultCode) {
					this.blackListTablePop = true;
					this.blackListMsg = `${result.data.data.length}건`;
					this.blackListData = result.data.data;
				} else {
					this.blackListData = {};
					this.blackListMsg = '';
					this.blinkText();
				}
			}
		},
		async checkDuplicationApplication() {
			if (
				this.customerData.cusPhone1 !== null &&
				this.customerData.cusPhone2 !== null &&
				this.customerData.cusPhone3 !== null &&
				this.customerData.cusAddr !== null
			) {
				let applFormId;
				applFormId = this.AppFormCreFlag;
				if (!applFormId) {
					applFormId = null;
				}
				let data = {
					applId: applFormId,
					cusAddr: this.customerData.cusAddr,
					cusPhone:
						this.customerData.cusPhone1 +
						this.customerData.cusPhone2 +
						this.customerData.cusPhone3,
				};
				const result = await this.$store.dispatch(
					'ApplicationFormCreationModule/checkDuplicationApplication',
					data,
				);
				if ('0000' === result.data.resultCode && result.data.data >= 0) {
					this.duplApplMsg = result.data.data + `건`;
				} else {
					this.duplApplMsg = 'error';
				}
			}
		},
		// delay를 줌으로 가시적으로 조회되는 듯한 느낌을 주기 위함 FNC
		blinkText() {
			bus.$emit('start:spinner');
			setTimeout(() => bus.$emit('end:spinner'), 400);
		},
		blackBtnFnc(blackTypeMsg: any) {
			this.blackListMsg = blackTypeMsg;
		},
		closeDialog(value: any) {
			this.blackListTablePop = value;
		},
		phoneChangeFnc(value: any) {
			value = value.replace('-', '');
			if (value != null && value.length === 11) {
				this.checkBlackNDuplFnc();
			} else {
				this.duplApplMsg = '';
				this.blackListMsg = '';
			}
		},
	},
	watch: {
		customerData() {
			if (this.AppFormCreFlag) {
				this.checkBlackNDuplFnc();
			}
		},
	},
});
