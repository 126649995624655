import Vue from 'vue';
import Vuex from 'vuex';
import {
	getMallEvent,
	getSelectMallEventList,
	getMallLogo,
	insertMallLogo,
	getMallBanner,
	insertMallBanner,
	getStoreDomainFooterInfo,
	updateStoreDomainFooterInfo,
	getMallConfig,
	updateMallConfig,
} from '../../../api/mall_mgmt/MallPageMgmtApi';
import bus from '@/common/bus';
Vue.use(Vuex);

const MallPageMgmtModule = {
	namespaced: true,
	state: {
		activePreviewTab: 0,
		eventDialog: false,
		businessData: {
			storeName: '주식회사 폰시티',
			ceoName: '송대호',
			대표번호: '1833-9413',
			storeEmail: '1000limam@gmail.com',
			bizNum: '501-88-01494',
			통신판매업신고: '2020-경기시흥-2777',
			주소: '경기도 시흥시서울대학로 278번길 19-8 401호',
		},
		bannerUseYn: 'N', // 배너 사용여부
		bannerlistYn: false, // 배너 사용여부
		reviewUseYn: 'N', // 리뷰 사용여부
		reviewAutoSelectionYn: 'N', // 리뷰 자동 채택
		mallConfigId: 0,
		targetBanner: {
			desktopImagePath: null,
			mobileImagePath: null,
			eventImagePath: null,
		}, // 선택된 배너(왼쪽 preview시킬 배너)
		changeTargetIdx: 0, // 수정할 배너 idx
		preImageLogoName: '', // 조회해온 로고 파일명
		logoAttachFile: [],
		stateUpdated: false,
		logoData: {
			mallName: '',
			logoImageName: '',
			logoImagePath: '',
			domainUrl: '',
		},
		bannerData: [],
		copiedLogoData: {
			mallName: '',
			logoImageName: '',
			logoImagePath: '',
			domainUrl: '',
		},
		copiedBannerData: [],
		storeDomainDto: null,
		storeInfoDto: null,
	},
	mutations: {
		setLogo(state, result) {
			state.logoData = result;
			state.logoData.logoImagePath = result.logoImagePath || '';
			state.preImageLogoName = state.logoData.logoImageName || '';
			state.copiedLogoData = JSON.parse(JSON.stringify(state.logoData));
		},
		setBanner(state, result) {
			state.bannerData = result;
			state.copiedBannerData = [];
			for (let i in state.bannerData) {
				state.copiedBannerData.push(state.bannerData[i]);
			}
			if (state.bannerData.length > 0) {
				state.targetBanner = state.bannerData[0];
			}
		},
		setBannerUseYn(state, result) {
			state.mallConfigId = result.mallConfigId;
			state.bannerUseYn = result.bannerUseYn;
			state.reviewUseYn = result.reviewUseYn;
			state.reviewAutoSelectionYn = result.reviewAutoSelectionYn;
		},
		checkUpdated(state, data) {
			state.stateUpdated =
				JSON.stringify(state[data[0]]) !== JSON.stringify(state[data[1]]);
		},
		setPrevData(state, data) {
			state[data[0]] = JSON.parse(JSON.stringify(state[data[1]]));
			if (data[0] === 'logoData') {
				state.preImageLogoName = state.copiedLogoData.logoImageName;
			}
			if (data[0] === 'bannerData') {
				if (state.bannerData.length > 0) {
					state.targetBanner = state.bannerData[0];
				}
			}
		},
		initLogo(state) {
			state.logoData = {
				mallName: '',
				logoImageName: '',
				logoImagePath: '',
			};
			state.copiedLogoData = {
				mallName: '',
				logoImageName: '',
				logoImagePath: '',
			};
			state.preImageLogoName = '';
			state.logoAttachFile = [];
			bus.$emit('imgUpload2:init');
		},
		setStoreDomainFooterInfo(state, result) {
			state.storeDomainDto = result.storeDomainDto;
			if(state.storeDomainDto.ictmarketPopUrl) {
				state.storeDomainDto.ictmarketPopUrl = state.storeDomainDto.ictmarketPopUrl.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, "\"").replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '');
			}
			state.storeInfoDto = result.storeInfoDto;
			state.storeInfoDto.fullAddr =
				state.storeInfoDto.storeZipCode +
				' ' +
				state.storeInfoDto.storeAddr +
				' ' +
				state.storeInfoDto.storeAddrDetail;
		},
	},
	actions: {
		async getMallEvent({ commit }, data) {
			try {
				const result = await getMallEvent(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getSelectMallEventList({ commit }, data) {
			try {
				const result = await getSelectMallEventList(data);
				if (result && result.data.resultCode === '0000') {
					return result.data.data;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallLogo({ commit }, data) {
			try {
				commit('initLogo');
				const result = await getMallLogo(data);
				if (result && result.data.resultCode === '0000') {
					commit('setLogo', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallLogo({ commit }, data) {
			try {
				const result = await insertMallLogo(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallBanner({ commit }, data) {
			try {
				const result = await getMallBanner(data);
				if (result && result.data.resultCode === '0000') {
					commit('setBanner', result.data.data);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async insertMallBanner({ commit }, data) {
			try {
				const result = await insertMallBanner(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getMallConfig({ commit }, data) {
			try {
				const result = await getMallConfig(data);
				if (result && result.data.resultCode === '0000') {
					commit('setBannerUseYn', result.data.data);
				}
				if (result && result.data.resultCode === '9000') {
					commit('setBannerUseYn', {
						bannerUseYn: 'N',
						reviewUseYn: 'N',
						reviewAutoSelectionYn: 'N',
						mallConfigId: null,
					});
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateMallConfig({ commit }, data) {
			try {
				const result = await updateMallConfig(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					alert(result.data.resultMsg);
					return false;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async getStoreDomainFooterInfo({ commit }, data) {
			try {
				const result = await getStoreDomainFooterInfo(data);
				if (result && result.data.resultCode === '0000') {
					commit('setStoreDomainFooterInfo', result.data.data);
					return true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		async updateStoreDomainFooterInfo({ commit }, data) {
			try {
				const result = await updateStoreDomainFooterInfo(data);
				if (result && result.data.resultCode === '0000') {
					return true;
				} else {
					if (result.data.resultMsg) {
						return alert(result.data.resultMsg);
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
};
export default MallPageMgmtModule;
