const dataCodeName = Object.freeze({
	mediationOutDate: '조정출고일자',
	consultTaskStatus: '상담-업무진행상태',
	openingTaskStatus: '개통-업무진행상태',
	logisticsTaskStatus: '물류-업무진행상태',
	reserveNum: '사전 예약번호',
	docTargetYn: '서류심사대상',
	openingPriorityTargetYn: '우선순위대상(빠른개통)',
	openStoreName: '개통점명',
	bizNo: '사업자번호',
	bizNoFront: '사업자번호 앞자리',
	bizNoMiddle: '사업자번호 중간자리',
	bizNoBack: '사업자번호 뒷자리',
	chargerPhone: '담당자(관리자) 연락처',
	returnZipCode: '우편번호',
	returnAddr: '기본주소',
	returnAddrDetail: '상세주소',
	joinStoreType: '지정 정보',
	username: '계정명',
	password: '비밀번호',
	oriPw: '현재비밀번호',
	newPw1: '새 비밀번호',
	newPw2: '새 비밀번호 확인',
	currentPwd: '현재 비밀번호',
	phone: '연락처',
	phone1: '연락처 앞자리',
	phone2: '연락처 중간자리',
	phone3: '연락처 뒷자리',
	roleIds: '역할',
	name: '사용자명',
	email: '이메일',
	saleStoreName: '영업점명',
	goodsName: '상품명',
	modelName: '모델명',
	network: '통신망',
	maker: '제조사',
	capacity: '용량',
	chargeAmt: '요금',
	chargeName: '요금제명',
	category: '카테고리',
	addSvcCharge: '요금',
	addSvcName: '부가서비스명',
	addSvcMemo: '메모',
	ceoName: '대표자명',
	chargeCode: '요금코드',
	chargerName: '담당자(관리자)명',
	chargerEmail: '담당자(관리자) 이메일',
	chargeId: '요금제',
	goodsId: '기기',
	releaseAmt: '출고가',
	supportAmt: '공시지원금',
	releaseDate: '공시일자',
	provName: '공급처명',
	stockName: '보유처',
	provId: '공급처',
	stockId: '보유처',
	telecom: '통신사',
	addDdctAmt: '추가차감금액',
	ddctAmt: '차감비',
	missProduct: '누락제품',
	productFaultyYn: '제품상태',
	productMissYn: '구성품누락',
	extrrStatus: '외장상태',
	inStockStatus: '입고상태',
	returnStockAmt: '반품비',
	returnStockMemo: '수정메모',
	inStockRegiDate: '입고일',
	orderDate: '주문일',
	returnStockRegiDate: '출고일',
	statusStr: '재고구분',
	makerName: '제조사',
	goodsOptionId: '색상',
	colorName: '색상',
	barcode: '기기 일련번호',
	prevStockId: '이전 보유처',
	nextStockId: '이동처',
	cusName: '고객명',
	cusPhone: '고객 전화번호',
	usimFullBarcode: '유심 일련번호',
	zipCode: '우편번호',
	addr2: '상세주소2',
	courier: '택배사',
	invoiceNo: '송장번호',
	transStoreId: '이관처',
	prov: '공급처',
	stock: '보유처',
	stockType: '재고구분',
	holdPlc: '보유처',
	cusPhone1: '휴대폰번호 또는 연락처 앞자리',
	cusPhone2: '휴대폰번호 또는 연락처 중간자리',
	cusPhone3: '휴대폰번호 또는 연락처 뒷자리',
	chargerPhone1: '연락처 앞자리',
	chargerPhone2: '연락처 중간자리',
	chargerPhone3: '연락처 뒷자리',
	unLockYn: '통신사향',
	blackType: '블랙리스트 유형',
	blackName: '블랙리스트 성명',
	blackDateBirth: '블랙리스트 생년월일',
	blackPhone1: '블랙리스트 연락처 앞자리',
	blackPhone2: '블랙리스트 연락처 중간자리',
	blackPhone3: '블랙리스트 연락처 뒷자리',
	memoContents: '사유',
	fileData: '첨부파일',
	taskStatus: '업무상태',
	/*신청서 code*/
	// 기본정보
	beforeOpeningType: '개통유형',
	saleStoreId: '영업점',
	openingStoreId: '개통점',
	applRegiUserId: '등록자',
	delYn: '삭제여부',
	parentApplId: '부모신청서아이디',
	priorityTargetYn: '우선대상여부',
	regiDatetime: '등록일시',
	updDateTime: '수정일시',
	parentSaleStoreId: '상위 영업 관리점 아이디',
	// 고객정보
	cusRegiNum1: '주민등록번호 앞자리',
	cusRegiNum2: '주민등록번호 뒷자리',
	chargeReductType: '요금감면',
	cusZipCode: '고객우편번호',
	cusAddr: '고객기본주소',
	cusAddrDetail: '고객상세주소',
	cusType: '고객유형',
	courtProctorName: '법정대리인 성명',
	courtProctorPhone1: '법정대리인 연락처 앞자리',
	courtProctorPhone2: '법정대리인 연락처 중간자리',
	courtProctorPhone3: '법정대리인 연락처 뒷자리',
	courtProctorRegiNum1: '법정대리인 주민등록번호 앞자리',
	courtProctorRegiNum2: '법정대리인 주민등록번호 뒷자리',
	courtProctorRelation: '법정대리인 관계',
	licenseAuthType: '신분증진위',
	bizName: '사업자명 or 법인명',
	bizNum: '사업자번호',
	bizNum1: '사업자번호',
	bizNum2: '사업자번호',
	bizNum3: '사업자번호',
	bizPhone1: '사업자 연락처 앞자리',
	bizPhone2: '사업자 연락처 중간자리',
	bizPhone3: '사업자 연락처 뒷자리',
	bizRegiNum1: '법인등록번호 앞자리',
	bizRegiNum2: '법인등록번호 뒷자리',
	licenseIssueDate: '신분증 발급일자',
	licenseIssueArea: '운전면허 지역코드',
	licenseNum1: '신분증진위 면허 또는 외국인등록번호(외 기타 등록번호)',
	licenseNum2: '신분증진위 면허 또는 외국인등록번호(외 기타 등록번호)',
	licenseNum3: '신분증진위 면허 또는 외국인등록번호(외 기타 등록번호)',
	licenseExpiredDate: '만료일자',
	stayCode: '체류코드',
	ntnlCode: '국가',
	// 납부정보
	paymentType: '납부방법',
	billType: '청구서종류',
	bankCode: '은행정보',
	accountNum: '계좌번호',
	accountHolderName: '예금주',
	accountHolderBirth: '생년월일',
	accountHolderRelation: '납부 관계',
	cardCompanyCode: '카드정보',
	cardNum1: '카드번호',
	cardNum2: '카드번호',
	cardNum3: '카드번호',
	cardNum4: '카드번호',
	cardHolderName: '카드보유자(이름)',
	cardHolderRegiNum1: '카드보유자 주민등록번호',
	cardHolderRegiNum2: '카드보유자 주민등록번호',
	cardValidityMm: '카드유효기간 MM',
	cardValidityYy: '카드유효기간 YY',
	giroZipCode: '지로 우편번호',
	giroAddr: '지로 기본주소',
	giroAddrDetail: '지로 상세주소',
	// 가입정보
	openingPhone1: '개통 휴대폰번호',
	openingPhone2: '개통 휴대폰번호',
	openingPhone3: '개통 휴대폰번호',
	openingHopeNum: '개통희망번호',
	openingTelecomCodeId: '개통통신사',
	existTelecomCodeId: '기존 통신사',
	joinType: '가입유형',
	numChangeAuthType: '인증방식',
	numChangeAuthTypeNum: '인증번호',
	usimPaymentType: '유심',
	usimRawBarcode: '유심 일련번호',
	agreementType: '약정유형',
	agreementPeriodType: '약정기간',
	instlPeriodType: '할부기간',
	monthDvcAmt: '월기기금액',
	monthBasicAmt: '월기본료',
	monthPaymentAmt: '월납부금액',
	color: '색상',
	pubNotiSupportAmt: '공시지원금',
	extraSupportAmt: '추가지원금',
	freeInstlAmt: '프리할부/선수납',
	etcDiscountAmt: '기타할인금',
	actualSellAmt: '실판매가',
	depositYn: '입금여부',
	// 배송정보
	deliveryType: '배송구분',
	courierCodeId: '택배사',
	invoiceNum: '송장번호',
	deliveryZipCode: '배송 우편번호',
	deliveryAddr: '배송 기본주소',
	deliveryAddrDetail: '배송 상세주소',
	deviceRawBarcode: '기기 일련번호',
	// 상담원
	consultUserId: '상담원',
	parentHierarchy: 'data: 상위계층',
	giftEnclosedYn: '사은품동봉여부',
	giftName: '사은품',
	openingTelecomName: '개통통신사',
	releaseAmtDate: '출고가일자',
	pubNotiId: '공시알림',
	openingDate: '개통일자',
	cancelDate: '철회일자',
	depositAmt: '입금받을금액',
	cusEmail: '이메일',
	emgPhone1: '비상연락망 앞자리',
	emgPhone2: '비상연락망 중간자리',
	emgPhone3: '비상연락망 뒷자리',
	usedDvcReclaimYn: '중고폰 회수여부',
	usimName: '유심명',
	usimType: '유심종류',
	usimBarcode: '유심 일련번호',
	// 민원요청내역
	updUserId: '수정인',
	templateTitle: '제목',
	templateContents: '내용',
	templateType: '민원 종류',
	regiName: '민원 등록인',
	regiDate: '민원 등록일자',
	complDate: '종결 일자',
	complTime: '종결 시간',
	rejectDate: '반려 일자',
	rejectTime: '반려 시간',
	complainType: '민월종류',
	complainStartDate: '민원 시작일',
	complainStartTime: '민원 시작시간',
	complainHandlingEndDate: '민원 처리기한',
	complainHandlingEndTime: '민원 처리시간',
	cusBirthDate: '생년월일',
	complainContents: '민원내용',
	preComplainHandlingEndDate: '민원 처리일자',
	preComplainHandlingEndTime: '민원 처리시간',
	pronounceDate: '선고 일자',
	pronounceTime: '선고 시간',
	complainProcAmt: '민원처리금액',
	saleStoreProcStatus: '영업점 진행상태',
	openingStoreProcStatus: '개통점 진행상태',
	saleStoreResponsAmt: '영업점 부담금',
	openingStoreResponsAmt: '개통점 부담금',
	complSaleStoreResponsAmt: '민원처리금액',
	recipCardName: '별칭',
	cardHolderDateBirth: '카드주 생년월일',
	cardPassword: '카드 비밀번호',
	recipComplDate: '수납완료 일자',
	recipPendReason: '수납보류 사유',
	expectRewardAmt: '보상예상금',
	lastRightAmt: '최종권리실행금',
	delayPeriod: '연장일',
	reclaimDate: '회수일자',
	signIssDate: '서명 발행 일자',
	issStoreName: '업체명',
	judgeStatus: '판정상태',
	judgeMemo: '판정사유',
	consultStatus: '통화결과',
	cnsltNoticeTitle: '제목',
	cnsltNoticeContents: '내용',
	bizPhone: '휴대폰번호',
	sysNoticeTitle: '제목',
	sysNoticeContents: '내용',
	title: '제목',
	startDate: '시작 날짜',
	endDate: '종료 날짜',
	desktopImagePath: '데스크탑 배너 이미지',
	mobileImagePath: '모바일 배너 이미지',
	eventImagePath: '이벤트 이미지',
	// 입점
	storeName: '사업자명',
	storePhone: '지점 대표번호',
	storePhone1: '지점 대표번호 앞자리',
	storePhone2: '지점 대표번호 중간자리',
	storePhone3: '지점 대표번호 뒷자리',
	storeEmail: 'E-mail',
	domainUrl: '온라인몰 URL',
	mallSubdomain: '온라인몰 URL',
	mallTitle: '온라인몰 명칭',
	mallDescription: '온라인몰 소개 글',
	telecomSalesNum: '통신판매업신고',
	customer_service_tel: '고객센터 대표번호',
	ictmarketPopUrl: '사전게시 소스',
	ictmarketPageUrl: '게시 URL',
	loginId: '이메일(ID)',
	memberPhone1: '연락처 앞자리',
	memberPhone2: '연락처 중간자리',
	memberPhone3: '연락처 뒷자리',
	memberName: '이름',
	memberBirth: '생년월일',
	memberAddr: '기본주소',
	memberAddrDetail: '상세주소',
	memberZipcode: '우편번호',
	blogUrl: '후기링크',
	// 판매 정책
	groupName: '판매점 분류 명',
	ruleTitle: '정책 제목',
	goodsGroupId: '상품',
	sktBasicChargeId: 'SKT 기본 요금제',
	ktBasicChargeId: 'KT 기본 요금제',
	lguBasicChargeId: 'LGU 기본 요금제',
	// 상품 상세
	sktGoodsDetail: 'SKT 상품 상세정보',
	ktGoodsDetail: 'KT 상품 상세정보',
	lguGoodsDetail: 'LGU 상품 상세정보',
	techInquiryNoticeTitle: '제목',
	techInquiryNoticeContents: '문의내용',
});
let mallDataCodeName = Object.freeze({
	goodsName: '상품명',
});
let redStatus = Object.freeze({
	RETRACT_COMPL: '철회완료',
	RETRACT_DMND_COMPL_CONSULT: '철회완료',
	EXCHANGE_COMPL: '교품완료(반품)',
	EXCHANGE_DMND_COMPL_CONSULT: '교품완료(반품)',
	FAULTY_CANCEL_COMPL: '불량취소완료',
	RCPT_CANCEL_COMPL: '접수취소완료',
	RETURN_DMND_COMPL_CONSULT: '반품완료',
	RETURN_COMPL: '반품완료',
	RETURN_COMPL_RESELL: '반품완료(재판)',
	STORE_RETURN: '반품완료(영업점)',
	OPENING_CANCEL: '개통전취소',
	DLVR_PREV_CANCEL: '배송전취소',
});
let blueStatus = Object.freeze({
	OPENING_COMPL: '개통완료',
	OPENING_COMPL_CONSULT: '개통완료',
	EXCHANGE_DLVR_COMPL_CONSULT: '교품완료(배송)',
	USIM_OPENING_COMPL_OPENING: '유심개통완료',
	USIM_OPENING_COMPL_CONSULT: '유심개통완료',
	DLVR_COMPL: '배송완료',
});
let greenStatus = Object.freeze({
	RCPT_COMPL: '접수완료',
	EXCHANGE_RCPT_DLVR_CONSULT: '교품접수(배송)',
	OPENING_DMND_CONSULT: '개통요청(상담)',
	EXCHANGE_RCPT_CONSULT: '교품접수(반품)',
	USIM_OPENING_DMND_CONSULT: '유심개통요청',
	EXCHANGE_DMND_OPENING: '교품요청(반품)',
	EXCHANGE_DMND_CONSULT: '교품요청(반품)',
	RCPT_CANCEL: '접수취소',
	FAULTY_CANCEL: '불량취소',
	RETRACT_DMND_CONSULT: '철회요청',
	OPENING_DMND_PEND_CONSULT: '개통요청보류(상담)',
	RETURN_DMND_CONSULT: '반품요청(상담)',
	DLVR_PEND: '배송보류',
	RETURN_DMND: '반품요청',
	DLVR_PROG: '배송중',
	DLVR_READY: '배송준비',
	DLVR_ASSIGNMENT_REQUEST: '배정요청',
	EXCHANGE_RCPT_OPENING: '교품접수(반품)',
	OPENING_DMND_PEND_OPENING: '개통요청보류(개통)',
	OPENING_PEND: '개통보류',

	RETRACT_DMND: '철회요청',
	EXCHANGE_RCPT_DLVR_OPENING: '교품접수(배송)',
	DLVR_DMND: '배송요청',
	OPENING_DMND_OPENING: '개통요청(개통)',
	USIM_CHANGE_DMND_OPENING: '유심변경요청(개통)',

	USIM_CHANGE_DMND_CONSULT: '유심변경요청(상담)',
	DLVR_PREV_PROG: '집하전(송장등록완료)',

	OPENING_PROG: '개통중',
	BFR_AGREE_PROG: '사동진행중',
	BFR_AGREE_COMPL: '사동완료',
	BFR_AGREE_WAIT: '사동대기',
});

const joinConfirmationPopupMode = {
	AGREEMENT: 'agreement',
	SEND: 'send',
	VIEWER: 'viewer',
};

export {
	dataCodeName,
	redStatus,
	blueStatus,
	greenStatus,
	mallDataCodeName,
	joinConfirmationPopupMode,
};
