<template>
	<div class="posR">
		<template v-if="listNum">
			<h2
				class="cur_p mt10"
				v-for="(item, index) in listNum"
				:key="index"
				@click="idxFnc(item, index)"
				v-if="titleIdx > index"
			>
				<span class="receiveIdxStyle1 disFx w100P justify-md-space-between">
					<span
						><span>{{ index + 1 }}</span
						>차수납</span
					>
					<template v-if="titleIdx - 1 > index">
						<span class="upArrow2 mr10"></span>
					</template>
					<template v-if="titleIdx - 1 === index">
						<span class="downArrow2 mr10"></span>
					</template>
				</span>
			</h2>
		</template>
		<template v-if="!listNum">
			<h2 class="mb20 cur_p">1차수납</h2>
		</template>
		<div class="new_popTable1 mt15 posR" v-if="recipData">
			<div
				class="w100P h100P listBlur1"
				v-if="titleIdx !== listNum.length"
			></div>
			<div
				class="w100P h100P listBlur1"
				v-if="openingStoreRecipReqStatus === 'RECIP_COMPL'"
			></div>
			<table class="w100P mainBlack">
				<tr>
					<td rowspan="4" class="w140">
						수납금액<span class="redfont">*</span>
					</td>
					<td class="lh36">
						<div class="disFx">
							<span class="w50 disIn">미납금</span>
							<input
								class="borderRadi3Px borderContColor3 w150 h36 padW10 ml80 disGray txAnR"
								v-model.trim="recipData.nonPaymentAmt"
								type="text"
								maxlength="7"
								@input="totalAmtFnc"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								:disabled="
									recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
								"
							/>
							<span class="lh36 ml10">원</span>
						</div>
					</td>
				</tr>
				<tr>
					<td class="backColorWhite lh36">
						<div class="disFx">
							<span class="w50 disIn">할부금</span>
							<input
								class="borderRadi3Px borderContColor3 w150 h36 padW10 ml80 disGray txAnR"
								v-model.trim="recipData.instlAmt"
								type="text"
								maxlength="7"
								@input="totalAmtFnc"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								:disabled="
									recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
								"
							/>
							<span class="lh36 ml10">원</span>
						</div>
					</td>
				</tr>
				<tr>
					<td class="backColorWhite lh36">
						<div class="disFx">
							<span class="w50 disIn">요금수납</span>
							<input
								class="borderRadi3Px borderContColor3 w150 h36 padW10 ml80 disGray txAnR"
								v-model.trim="recipData.recipAmt"
								type="text"
								maxlength="7"
								@input="totalAmtFnc"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								:disabled="
									recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
								"
							/>
							<span class="lh36 ml10">원</span>
						</div>
					</td>
				</tr>
				<tr>
					<td class="backColorWhite txAnR">
						<span class="disBl boldWt">
							금액 합계<span class="titleFontS mainBlue2 ml10">{{
								totalAmt
							}}</span
							>원
						</span>
						<span class="mainBlack3">
							( 미납금
							<span>{{
								String(recipData.nonPaymentAmt).replace(
									/\B(?=(\d{3})+(?!\d))/g,
									',',
								)
							}}</span
							>원, 할부금
							<span>{{
								String(recipData.instlAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							}}</span
							>원, 요금수납
							<span>{{
								String(recipData.recipAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							}}</span
							>원 )
						</span>
						<span class="redfont boldWt ml5"
							>*각항목별 개별 결제만 가능합니다.</span
						>
					</td>
				</tr>
				<tr>
					<td rowspan="2">권리실행포인트</td>
					<td class="lh36">
						<div class="disFx">
							<span class="w60 disIn">포인트수납</span>
							<input
								class="borderRadi3Px borderContColor3 w150 h36 padW10 ml70 disGray txAnR"
								v-model.trim="recipData.recipPoint"
								type="text"
								oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
								maxlength="7"
								:disabled="
									recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
								"
							/>
							<span class="lh36 ml10">원</span>
						</div>
					</td>
				</tr>
				<tr>
					<td class="backColorWhite txAnR boldWt">
						<span>포인트 사용</span>
						<span class="titleFontS mainBlue2 ml10">{{
							String(recipData.recipPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}}</span
						>원
					</td>
				</tr>
				<tr>
					<template v-if="recipData.paymentType !== 'ADJUST_DDCT'">
						<td>결제수단<span class="redfont">*</span></td>
					</template>
					<template v-if="recipData.paymentType === 'ADJUST_DDCT'">
						<td rowspan="2">결제수단<span class="redfont">*</span></td>
					</template>
					<td>
						<div class="disFx">
							<div class="radioStyle h36 lh36 ml20">
								<input
									type="radio"
									id="cardInfoItem1"
									name="cardInfo1"
									value="CUSTOMER_INDV"
									v-model.trim="recipData.paymentType"
									@change="paymentTypeFnc()"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/><label class="ml-1 cur_p" for="cardInfoItem1"
									>고객카드(개인)</label
								>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="cardInfoItem2"
									name="cardInfoItem1"
									value="CUSTOMER_CORP"
									v-model.trim="recipData.paymentType"
									@change="paymentTypeFnc()"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/><label class="ml-1 cur_p" for="cardInfoItem2"
									>고객카드(법인)</label
								>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="cardInfoItem3"
									name="cardInfoItem1"
									value="STORE_INDV"
									v-model.trim="recipData.paymentType"
									@change="paymentTypeFnc()"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/><label class="ml-1 cur_p" for="cardInfoItem3"
									>영업점카드(개인)</label
								>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="cardInfoItem4"
									name="cardInfoItem1"
									value="STORE_CORP"
									v-model.trim="recipData.paymentType"
									@change="paymentTypeFnc()"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/><label class="ml-1 cur_p" for="cardInfoItem4"
									>영업점카드(법인)</label
								>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="cardInfoItem5"
									name="cardInfoItem1"
									value="ADJUST_DDCT"
									v-model.trim="recipData.paymentType"
									@change="paymentTypeFnc()"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/><label class="ml-1 cur_p" for="cardInfoItem5">정산차감</label>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="cardInfoItem6"
									name="cardInfoItem1"
									value="CASH"
									v-model.trim="recipData.paymentType"
									@change="paymentTypeFnc()"
									disabled
								/><label class="ml-1 cur_p" for="cardInfoItem6">현금입금</label>
							</div>
						</div>
					</td>
				</tr>
				<tr v-if="recipData.paymentType === 'ADJUST_DDCT'">
					<td class="backColorWhite">
						<textarea
							class="receiveMemo1 disGray"
							placeholder="메모를 입력해주세요"
							v-model.trim="recipData.adjustDdctMemo"
							:disabled="recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'"
						></textarea>
					</td>
				</tr>
				<tr>
					<td rowspan="4" v-if="recipData.paymentType !== 'ADJUST_DDCT'">
						카드결제<span class="redfont">*</span>
					</td>
					<td v-if="recipData.paymentType !== 'ADJUST_DDCT'">
						<template
							v-if="
								recipData.paymentType === 'STORE_INDV' ||
								recipData.paymentType === 'STORE_CORP'
							"
						>
							<div style="width: 900px">
								<template>
									<v-slide-group
										class="pa-4"
										center-active
										show-arrows
										v-model.trim="recipData.recipCardId"
										:disabled="
											recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
										"
									>
										<v-card
											height="110"
											width="180"
											class="cur_p"
											style="margin: 3px 10px 3px 0; border: 1px dashed #999"
											@click="cardInsertFnc"
											:disabled="
												recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
											"
										>
											<v-row align="center" justify="center">
												<v-card-title
													style="
														font-size: 13px;
														font-weight: bold;
														margin-top: 20px;
														position: relative;
													"
												>
													<span
														class="plusImg1 disIn none none none none none"
													></span>
													카드등록
												</v-card-title>
												<v-card-text
													style="
														text-align: center !important;
														padding-top: 0 !important;
													"
													><span
														class="disIn"
														style="font-size: 11px; line-height: 14px"
														>빠르고 간편한 결제를 위해<br />카드를
														등록해주세요</span
													></v-card-text
												>
											</v-row>
										</v-card>
										<v-slide-item
											v-for="item in cardSaveData"
											:key="item.index"
											:value="item.recipCardId"
											v-slot="{ active, toggle }"
										>
											<v-card
												height="110"
												width="180"
												@click="toggle"
												style="margin: 3px 10px; color: #e0e0e0"
												class="cardSelectStyle"
												:class="active ? 'borderCardColor1' : 'none'"
												:disabled="
													recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
												"
											>
												<v-row
													class="fill-height"
													align="center"
													@click="cardChangeFnc(item)"
												>
													<v-card-title
														class="boldWt"
														style="margin: 10px 0 0 10px !important"
														>{{ item.recipCardName }}
													</v-card-title>
													<v-card-text>
														<div class="boldWt" style="font-size: 0.95em">
															{{ item.cardNum1 }} - **** - **** -
															{{ item.cardNum4 }}
														</div>
														<div
															class="disFx justify-lg-space-between"
															style="font-size: 0.8em"
														>
															<div>
																{{ item.cardHolderName }}
															</div>
															<div style="margin-right: 3px">
																{{ item.cardValidityMm }}/{{
																	item.cardValidityYy
																}}
															</div>
														</div>
													</v-card-text>
													<!--													<v-scale-transition>
														<v-icon
															v-if="active"
															color="white"
															size="48"
															v-text="'mdi-close-circle-outline'"
														></v-icon>
													</v-scale-transition>-->
												</v-row>
											</v-card>
										</v-slide-item>
									</v-slide-group>
								</template>
							</div>
						</template>
					</td>
				</tr>
				<tr>
					<td
						class="backColorWhite"
						v-if="recipData.paymentType !== 'ADJUST_DDCT'"
					>
						<div>
							<template
								v-if="
									recipData.paymentType === 'CUSTOMER_INDV' ||
									recipData.paymentType === 'STORE_INDV'
								"
							>
								<span class="disIn w50">별칭</span>
								<input
									class="borderRadi3Px borderContColor3 w220 h36 padW10 ml30 disGray"
									v-model.trim="recipData.recipCardName"
									type="text"
									maxlength="8"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/>
								<span class="disIn w100 ml30">이름</span>
								<input
									class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30 disGray"
									v-model.trim="recipData.cardHolderName"
									type="text"
									maxlength='15'
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/>
								<span class="disIn ml30 w100">생년월일</span>
								<span class="posR datePickerStyle1 w140 font-size-12 ml30 disIn">
									<v-menu
										v-model.trim="menu1"
										:close-on-content-click="false"
										:nudge-right="140"
										:nudge-top="200"
										transition="scale-transition"
										offset-y
										:attach="true"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												class="w140"
												style="font-size: 12px"
												outlined
												readonly
												v-bind="attrs"
												v-on="on"
												append-icon="mdi-calendar"
												v-model.trim="recipData.cardHolderDateBirth"
												:disabled="
													recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
												"
											></v-text-field>
										</template>
										<v-date-picker
											@input="menu1 = false"
											locale="ko-KR"
											v-model.trim="recipData.cardHolderDateBirth"
											:disabled="
												recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
											"
										></v-date-picker>
									</v-menu>
								</span>
							</template>
						</div>
						<template
							v-if="
								recipData.paymentType === 'CUSTOMER_CORP' ||
								recipData.paymentType === 'STORE_CORP'
							"
						>
							<div>
								<span class="disIn w50">별칭</span>
								<input
									class="borderRadi3Px borderContColor3 w220 h36 padW10 ml30 disGray"
									type="text"
									maxlength='8'
									v-model.trim="recipData.recipCardName"
								/>
								<span class="disIn w100 ml30">법인명</span>
								<input
									class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30 disGray"
									type="text"
									maxlength='15'
									v-model.trim="recipData.cardHolderName"
								/>
								<span class="disIn w100 ml30">사업자번호</span>
								<input
									class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30 disGray"
									type="text"
									v-model.trim="recipData.bizNum"
									maxlength="10"
									@input="
										bizNumSplitFnc($event.target.value, 'bizNum', 'formData')
									"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/>
							</div>
						</template>
					</td>
				</tr>
				<tr>
					<td
						class="backColorWhite"
						v-if="recipData.paymentType !== 'ADJUST_DDCT'"
					>
						<span class="disIn w50">카드번호</span>
						<input
							class="borderRadi3Px borderContColor3 w220 h36 padW10 ml30 disGray"
							v-model.trim="recipData.cardNum"
							type="text"
							maxlength="16"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							@input="
								cardNumSplitFnc($event.target.value, 'cardNum', 'recipData')
							"
							:disabled="recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'"
						/>
						<span class="disIn ml30 w100">유효기간(MM/YY)</span>
						<input
							class="borderRadi3Px borderContColor3 w70 h36 padW10 ml30 disGray"
							type="text"
							maxlength="2"
							v-model.trim="recipData.cardValidityMm"
							placeholder="MM"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							:disabled="recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'"
						/>
						<input
							class="borderRadi3Px borderContColor3 w70 h36 padW10 disGray"
							type="text"
							maxlength="2"
							v-model.trim="recipData.cardValidityYy"
							placeholder="YY"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							:disabled="recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'"
						/>
						<span class="disIn ml30 w100">카드 비밀번호</span>
						<input
							class="borderRadi3Px borderContColor3 w140 h36 padW10 ml30 disGray"
							type="text"
							v-model.trim="recipData.cardPassword"
							oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
							:disabled="recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'"
							maxlength="2"
						/>
					</td>
				</tr>
				<tr>
					<td
						class="backColorWhite"
						v-if="recipData.paymentType !== 'ADJUST_DDCT'"
					>
						<div class="floatR">
							<template
								v-if="
									recipData.paymentType === 'STORE_INDV' ||
									recipData.paymentType === 'STORE_CORP'
								"
							>
								<template v-if="!recipData.recipCardId">
									<button
										class="lh36 backColorBlue3 mainWhite borderRadi3Px w70"
										@click="insertRecipCard"
										:disabled="
											recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
										"
									>
										카드저장
									</button>
								</template>
								<template v-if="recipData.recipCardId">
									<button
										class="lh36 backColorBlue3 mainWhite borderRadi3Px w70"
										@click="updateRecipCard"
										:disabled="
											recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
										"
									>
										카드수정
									</button>
								</template>
								<template v-if="recipData.recipCardId">
									<button
										class="lh36 ml5 borderRadi3Px borderContColor3 w70"
										:disabled="
											recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
										"
										@click="deleteCardFnc"
									>
										삭제
									</button>
								</template>
							</template>
						</div>
					</td>
				</tr>
				<tr>
					<td class="h40">첨부파일</td>
					<td>
						<receive-req-detail-file-upload
							:applId="Number(applId)"
							:propsFileList="recipData.attachList"
							:modifyState="true"
							@fileValueFnc="fileValueFnc"
						></receive-req-detail-file-upload>
					</td>
				</tr>
				<tr>
					<td>영업점<br />수납요청상태<span class="redfont">*</span></td>
					<td>
						<div class="disFx">
							<div class="radioStyle h36 lh36 ml20">
								<input
									type="radio"
									id="payRequestItem1"
									name="payRequestItem1"
									value="RECIP_REQ"
									v-model.trim="recipData.storeRecipReqStatus"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/>
								<label class="ml-1 cur_p" for="payRequestItem1">
									수납요청
								</label>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="payRequestItem2"
									name="payRequestItem1"
									value="RECIP_REQ_CANCEL"
									v-model.trim="recipData.storeRecipReqStatus"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/>
								<label class="ml-1 cur_p" for="payRequestItem2">
									수납요청취소
								</label>
							</div>
							<div class="radioStyle h36 lh36 ml40">
								<input
									type="radio"
									id="payRequestItem3"
									name="payRequestItem1"
									value="SETTLE_CANCEL_REQ"
									v-model.trim="recipData.storeRecipReqStatus"
									:disabled="
										recipData.openingStoreRecipReqStatus === 'RECIP_COMPL'
									"
								/>
								<label class="ml-1 cur_p" for="payRequestItem3">
									결제취소요청
								</label>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>개통점<br />수납요청상태<span class="redfont">*</span></td>
					<td>
						<div>
							<div class="disFx mb10">
								<div class="radioStyle h36 lh36 ml20">
									<input
										type="radio"
										id="storeRecipReqStatus1"
										name="storeRecipReqStatus1"
										value="RECIP_COMPL"
										v-model.trim="recipData.openingStoreRecipReqStatus"
										@change="openStoreRecReqFnc"
									/>
									<label class="ml-1 cur_p" for="storeRecipReqStatus1">
										수납완료
									</label>
								</div>
								<div class="posR datePickerStyle1 w140 font-size-12  ml20">
									<v-menu
										v-model.trim="menu2"
										:close-on-content-click="false"
										:nudge-right="140"
										:nudge-top="200"
										transition="scale-transition"
										offset-y
										:attach="true"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												class="w140"
												style="font-size: 12px"
												outlined
												readonly
												v-bind="attrs"
												v-on="on"
												append-icon="mdi-calendar"
												v-model.trim="recipData.recipComplDate"
												:disabled="
													recipData.openingStoreRecipReqStatus !== 'RECIP_COMPL'
												"
											></v-text-field>
										</template>
										<v-date-picker
											@input="menu2 = false"
											locale="ko-KR"
											v-model.trim="recipData.recipComplDate"
										></v-date-picker>
									</v-menu>
								</div>
							</div>
						</div>
						<div class="disFx">
							<div class="radioStyle h36 lh36 ml20 disGray">
								<input
									type="radio"
									id="storeRecipReqStatus2"
									name="storeRecipReqStatus1"
									value="RECIP_PEND"
									v-model.trim="recipData.openingStoreRecipReqStatus"
									@change="openStoreRecReqFnc"
								/>
								<label class="ml-1 cur_p" for="storeRecipReqStatus2">
									수납보류
								</label>
							</div>
							<input
								type="text"
								placeholder="사유를 입력해주세요"
								class="borderRadi3Px borderContColor3 lh36 padW10 w70P ml20 disGray"
								v-model.trim="recipData.recipPendReason"
								:disabled="
									recipData.openingStoreRecipReqStatus !== 'RECIP_PEND'
								"
							/>
						</div>
						<div class="radioStyle h36 lh36 ml20">
							<input
								type="radio"
								id="storeRecipReqStatusItem3"
								name="storeRecipReqStatusItem1"
								value="RECIP_CANCEL_COMPL"
								v-model.trim="recipData.openingStoreRecipReqStatus"
								@change="openStoreRecReqFnc"
							/>
							<label class="ml-1 cur_p" for="storeRecipReqStatusItem3">
								수납취소완료
							</label>
						</div>
						<div class="radioStyle h36 lh36 ml20">
							<input
								type="radio"
								id="storeRecipReqStatusItem4"
								name="storeRecipReqStatusItem1"
								value="SETTLE_CANCEL_COMPL"
								v-model.trim="recipData.openingStoreRecipReqStatus"
								@change="openStoreRecReqFnc"
							/>
							<label class="ml-1 cur_p" for="storeRecipReqStatusItem4">
								결제취소완료
							</label>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<template
			v-if="
				openingStoreRecipReqStatus !== 'RECIP_COMPL' &&
				titleIdx === listNum.length
			"
		>
			<div class="disFx justify-end mt20">
				<button
					@click="upDateFnc"
					class="backColorBlue2 mainWhite borderRadi3Px w120 lh40"
				>
					수정
				</button>
			</div>
		</template>
		<h2
			class="mt10 cur_p"
			v-for="(item, index) in listNum"
			:key="item"
			@click="idxFnc(item, index)"
			v-if="titleIdx <= index"
		>
			<span class="receiveIdxStyle1 disFx w100P justify-md-space-between">
				<span
					><span>{{ index + 1 }}</span
					>차수납</span
				>
				<template v-if="titleIdx <= index">
					<span class="upArrow2 mr10"></span>
				</template>
			</span>
		</h2>
	</div>
</template>

<script>
import numberSplit from '../../../common/numberSplit.js';
import ReceiveReqDetailFileUpload from '../../../components/ReceiveReqDetailFileUpload.vue';
import { htmlParse, nullValidation2 } from '@/common/common';

export default {
	name: 'ReceiveRequestDetail',
	components: {
		ReceiveReqDetailFileUpload,
	},
	mixins: [numberSplit],
	computed: {
		recipData: {
			get() {
				return this.$store.state.ReceiveRequestModule.recipData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.recipData = newValue;
			},
		},
		listNum: {
			get() {
				return this.$store.state.ReceiveRequestModule.listNum;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.listNum = newValue;
			},
		},
		applId() {
			return this.$store.state.ApplicationFormCreationModule.AppFormCreFlag;
		},
		titleIdx: {
			get() {
				return this.$store.state.ReceiveRequestModule.titleIdx;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.titleIdx = newValue;
			},
		},
		formData: {
			get() {
				return this.$store.state.ReceiveRequestModule.formData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.formData = newValue;
			},
		},
		cardSaveData: {
			get() {
				return this.$store.state.ReceiveRequestModule.cardSaveData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.cardSaveData = newValue;
			},
		},
		openingStoreRecipReqStatus: {
			get() {
				return this.$store.state.ReceiveRequestModule
					.openingStoreRecipReqStatus;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.openingStoreRecipReqStatus = newValue;
			},
		},
		propsFileList: {
			get() {
				return this.$store.state.ReceiveRequestModule.propsFileList;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.propsFileList = newValue;
			},
		},
		fileData: {
			get() {
				return this.$store.state.ReceiveRequestModule.fileData;
			},
			set(newValue) {
				this.$store.state.ReceiveRequestModule.fileData = newValue;
			},
		},
	},
	data: () => ({
		menu1: false,
		menu2: false,
		totalAmt: '0',
	}),
	methods: {
		totalAmtFnc() {
			if (this.recipData) {
				let Amt =
					Number(this.recipData.nonPaymentAmt) +
					Number(this.recipData.instlAmt) +
					Number(this.recipData.recipAmt);
				this.totalAmt = String(Amt).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
		},
		fileValueFnc(value) {
			this.recipData.attachList = value;
		},
		async insertRecipCard() {
			if (!confirm('저장하시겠습니까')) {
				return;
			}
			let data = {
				applId: this.recipData.applId,
				bizNum: this.recipData.bizNum,
				cardHolderName: this.recipData.cardHolderName,
				cardHolderDateBirth: this.recipData.cardHolderDateBirth,
				cardNum: this.recipData.cardNum,
				cardNum1: this.recipData.cardNum1,
				cardNum2: this.recipData.cardNum2,
				cardNum3: this.recipData.cardNum3,
				cardNum4: this.recipData.cardNum4,
				cardPassword: this.recipData.cardPassword,
				cardValidityMm: this.recipData.cardValidityMm,
				cardValidityYy: this.recipData.cardValidityYy,
				paymentType: this.recipData.paymentType,
				recipCardName: this.recipData.recipCardName,
				saleStoreId: this.saleStoreId,
			};
			for (let del in data) {
				if (data[del] === '') {
					data[del] = null;
				}
			}
			const result = await this.$store.dispatch(
				'ReceiveRequestModule/insertRecipCard',
				data,
			);
			await this.getRecipCardList();
		},
		async updateRecipCard() {
			if (!confirm('수정하시겠습니까')) {
				return;
			}
			let data = {
				applId: this.recipData.applId,
				bizNum: this.recipData.bizNum,
				cardHolderName: this.recipData.cardHolderName,
				cardHolderDateBirth: this.recipData.cardHolderDateBirth,
				cardNum: this.recipData.cardNum,
				cardNum1: this.recipData.cardNum1,
				cardNum2: this.recipData.cardNum2,
				cardNum3: this.recipData.cardNum3,
				cardNum4: this.recipData.cardNum4,
				cardPassword: this.recipData.cardPassword,
				cardValidityMm: this.recipData.cardValidityMm,
				cardValidityYy: this.recipData.cardValidityYy,
				paymentType: this.recipData.paymentType,
				recipCardName: this.recipData.recipCardName,
				recipCardId: this.recipData.recipCardId,
				saleStoreId: this.saleStoreId,
			};
			for (let del in data) {
				if (data[del] === '') {
					data[del] = null;
				}
			}
			await this.$store.dispatch('ReceiveRequestModule/updateRecipCard', data);
			await this.getRecipCardList();
		},
		openStoreRecReqFnc() {
			this.recipData.recipPendReason = null;
			this.recipData.recipComplDate = null;
		},
		cardInsertFnc() {
			this.recipData.recipCardId = null;
			this.recipData.bizNum = null;
			this.recipData.cardHolderName = null;
			this.recipData.cardHolderDateBirth = null;
			this.recipData.cardNum = null;
			this.recipData.cardNum1 = null;
			this.recipData.cardNum2 = null;
			this.recipData.cardNum3 = null;
			this.recipData.cardNum4 = null;
			this.recipData.cardPassword = null;
			this.recipData.recipCardName = null;
			this.recipData.cardValidityMm = null;
			this.recipData.cardValidityYy = null;
		},
		cardChangeFnc(item) {
			this.recipData.cardHolderName = item.cardHolderName;
			this.recipData.cardHolderDateBirth = item.cardHolderDateBirth;
			this.recipData.cardNum = item.cardNum;
			this.recipData.cardNum1 = item.cardNum1;
			this.recipData.cardNum2 = item.cardNum2;
			this.recipData.cardNum3 = item.cardNum3;
			this.recipData.cardNum4 = item.cardNum4;
			this.recipData.cardPassword = item.cardPassword;
			this.recipData.cardValidityMm = item.cardValidityMm;
			this.recipData.cardValidityYy = item.cardValidityYy;
			this.recipData.recipCardName = item.recipCardName;
		},
		formDataInit() {
			for (let data in this.formData) {
				this.formData[data] = '';
			}
			this.formData.applId = this.applId;
			this.nonPaymentAmtDialog = 'N';
			this.instlAmtDialog = 'N';
			this.recipAmtDialog = 'N';
			this.recipPoint = 'N';
			this.formData.nonPaymentAmt = 0;
			this.formData.instlAmt = 0;
			this.formData.recipAmt = 0;
			this.formData.recipPoint = 0;
			this.formData.paymentType = 'CUSTOMER_INDV';
			this.formData.storeRecipReqStatus = 'RECIP_REQ';
			this.formData.openingStoreRecipReqStatus = 'RECIP_COMPL';
		},
		async idxFnc(idx, idx1) {
			this.titleIdx = idx1 + 1;
			await this.getRecipCardList();
			await this.getApplicationRecipDetail(idx);
			await this.getApplicationAttachList(idx);
			await this.formDataInit();
		},
		async getRecipCardList() {
			let data = {
				applId: this.applId,
			};
			await this.$store.dispatch('ReceiveRequestModule/getRecipCardList', data);
		},
		getApplicationRecipDetail(idx) {
			// 디테일콜
			let data = {
				applId: this.applId,
				applRecipId: idx,
			};
			this.$store.dispatch(
				'ReceiveRequestModule/getApplicationRecipDetail',
				data,
			);
		},
		async updateApplicationRecip() {
			const result = await this.$store.dispatch(
				'ReceiveRequestModule/updateApplicationRecip',
				this.recipData,
			);
			await this.getApplicationAttachList(this.recipData.applRecipId);
		},
		paymentTypeFnc() {
			this.recipData.bizNum = null;
			this.recipData.cardHolderName = null;
			this.recipData.cardHolderDateBirth = null;
			this.recipData.cardNum = null;
			this.recipData.cardNum1 = null;
			this.recipData.cardNum2 = null;
			this.recipData.cardNum3 = null;
			this.recipData.cardNum4 = null;
			this.recipData.cardPassword = null;
			this.recipData.recipCardName = null;
			this.recipData.cardValidityMm = null;
			this.recipData.cardValidityYy = null;
			this.recipData.recipCardId = null;
		},
		validationRuleFnc() {
			if (
				this.recipData.paymentType === 'CUSTOMER_INDV' ||
				this.recipData.paymentType === 'STORE_INDV'
			) {
				if (
					!nullValidation2(this.recipData, [
						'recipCardName',
						'cardHolderName',
						'cardHolderDateBirth',
						'cardNum1',
						'cardNum2',
						'cardNum3',
						'cardNum4',
						'cardValidityMm',
						'cardValidityYy',
						'cardPassword',
					])
				) {
					return true;
				}
			}
			if (
				this.recipData.paymentType === 'CUSTOMER_CORP' ||
				this.recipData.paymentType === 'STORE_CORP'
			) {
				if (
					!nullValidation2(this.recipData, [
						'recipCardName',
						'cardHolderName',
						'bizNum',
						'cardNum1',
						'cardNum2',
						'cardNum3',
						'cardNum4',
						'cardValidityMm',
						'cardValidityYy',
						'cardPassword',
					])
				) {
					return true;
				}
			}
			if (this.recipData.openingStoreRecipReqStatus === 'RECIP_COMPL') {
				if (!nullValidation2(this.recipData, ['recipComplDate'])) {
					return true;
				}
			}
			if (this.recipData.openingStoreRecipReqStatus === 'RECIP_PEND') {
				if (!nullValidation2(this.recipData, ['recipPendReason'])) {
					return true;
				}
			}
		},
		async upDateFnc() {
			if (this.validationRuleFnc()) return;
			if (!confirm('수정하시겠습니까')) {
				return;
			}
			await this.fileUploadFnc(this.recipData.applRecipId);
			await this.updateApplicationRecip();
			this.listNum = 0;
			await this.setApplicationRecipDetail();
			this.fileData = [];
		},
		async setApplicationRecipDetail() {
			// 디테일콜
			let data = {
				applId: this.applId,
			};
			const result = await this.$store.dispatch(
				'ReceiveRequestModule/getApplicationRecipDetail',
				data,
			);
		},
		async deleteCardFnc() {
			if (!confirm('삭제하시겠습니까')) {
				return;
			}
			await this.deleteRecipCard();
		},
		async deleteRecipCard() {
			let data = {
				recipCardId: this.recipData.recipCardId,
			};
			await this.$store.dispatch('ReceiveRequestModule/deleteRecipCard', data);
		},
		async getApplicationAttachList(idx) {
			// 파일리스트 콜
			let data = {
				applId: this.applId,
				attachType: 'RECIP',
				subId: idx,
			};
			if (this.applId) {
				const result = await this.$store.dispatch(
					'RightExecutionModule/getApplicationAttachList',
					data,
				);
				if (result) {
					this.propsFileList = result;
				}
			}
		},
		async fileUploadFnc(data) {
			if (this.recipData.attachList) {
				if (this.recipData.attachList.length !== 0) {
					let formData = new FormData();
					formData.append('applId', this.applId);
					for (let i = 0; i < this.recipData.attachList.length; i++) {
						if (this.recipData.attachList[i].applAttachId) {
							return;
						}
						formData.append('attachFile', this.recipData.attachList[i]);
						let fileHtml = []; // 파일 이력
						let fileObj = {
							title: '첨부파일',
							// @ts-ignore
							data: this.recipData.attachList[i].name,
							changeData: '추가',
						};
						fileHtml.push(fileObj);
						let fileHtmlString = htmlParse(fileHtml);
						formData.append('memoContents', fileHtmlString);
						formData.append('attachType', 'RECIP');
						formData.append('subId', data);
					}
					// 파일 이력
					await this.$store.dispatch(
						'RightExecutionModule/fileUpload',
						formData,
					);
				}
			}
		},
	},
	async updated() {
		await this.totalAmtFnc();
	},
};
</script>

<style scoped>
.receiveTableCont1 {
	background-color: #fff;
	width: 1200px;
	border-top: 2px solid #007ef2;
	border-bottom: 1px solid #eee;
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
	padding: 20px 30px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.receiveMemo1 {
	border: 1px solid #999;
	border-radius: 3px;
	resize: none;
	width: 100%;
	height: 94px;
	padding: 10px;
}
.receiveIdxStyle1 {
	border-bottom: 1px solid #ccc;
	padding-bottom: 20px;
}
.upArrow2 {
	display: inline-block;
	background-image: url('../../../assets/img/up_arrow2.png');
	width: 29px;
	height: 17px;
	background-repeat: no-repeat;
	margin-top: 3px;
}
.downArrow2 {
	display: inline-block;
	background-image: url('../../../assets/img/down_arrow2.png');
	width: 29px;
	height: 17px;
	background-repeat: no-repeat;
	margin-top: 3px;
}
.plusImg1 {
	background-image: url('../../../assets/img/cardAddBtn1.png');
	width: 30px;
	height: 30px;
	display: inline-block;
}
.listBlur1 {
	position: absolute;
	z-index: 10;
}
</style>
