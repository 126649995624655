import Vue from 'vue';
import Vuex from 'vuex';
import {
	getList,
	getGroupList,
} from '../../../../api/common-mgmt/goods-mgmt/MobilePhoneGroupListApi.js';

Vue.use(Vuex);

const MobilephoneListModule = {
	namespaced: true,
	state: {
		dateResetData: false,
		// storeSelect: [],
		getListData: [],
		getGroupListData: [],
		infoDialog: false,
		registDialog: false,
		codeList: {
			network: [],
			telecom: [],
			maker: [],
			useYn: [
				{ name: '전체', value: 'all' },
				{ name: 'Y', value: 'Y' },
				{ name: 'N', value: 'N' },
			],
		},
		filterData: {
		},
		totalCnt: 0,
		pagingData: {
			pageNo: 0,
			pageLastNo: 0,
			pageEndNo: 0,
			perPageCnt: 20,
			pageNumList: [],
		},
	},
	mutations: {
		filterInit(state) {
			state.dateResetData = !state.dateResetData;
		},
		setList(state, result) {
			state.getListData = result.data.data.resultList;
			state.totalCnt = result.data.data.totalCnt;
			state.pagingData.perPageCnt = result.data.data.perPageCnt;
			state.pagingData.pageNo = result.data.data.pageNo;
			state.pagingData.pageNumList = result.data.data.pageNumList;
			state.pagingData.pageLastNo = result.data.data.pageLastNo;
			state.pagingData.pageEndNo = result.data.data.pageEndNo;
		},
		setGroupList(state, result) {
			state.getGroupListData = result.data.data.resultList;
		},
	},
	actions: {
		async getList({ commit }, data) {
			try {
				const result = await getList(data);
				if (result) {
					commit('setList', result);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getGroupList({ commit }, data) {
			try {
				const result = await getGroupList(data);
				if (result) {
					commit('setGroupList', result);
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};

export default MobilephoneListModule;
